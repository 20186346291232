import { useSearchParams } from "../shared/use-search-params";
import { BugReport } from "./bug-report";
import { useKeyboard } from "./use-keyboard";

export const AppModals = () => {
  const searchParams = useSearchParams();
  const appModal = searchParams?.params?.appModal || "";

  const onClose = () => searchParams.removeParam("appModal");

  const handleKeydown = (captured) => {
    if (captured === "ctrl+shift+KeyX") {
      searchParams.updateParams({ appModal: "bug-report" });
    }
  };

  useKeyboard({ keydown: handleKeydown, options: { useCapture: true } });

  if (appModal === "bug-report") {
    return <BugReport display={appModal === "bug-report"} onClose={onClose} />;
  }

  return null;
};
