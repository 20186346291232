import { get } from "lodash";
import { getProductPremium } from "../../../products/field-components/field-utils";

const currencyFormatter = new Intl.NumberFormat('en-US', {
  style: 'currency',
  currency: 'USD',
  maximumFractionDigits: 0,
});

const currencyFormatterCents = new Intl.NumberFormat('en-US', {
  style: 'currency',
  currency: 'USD',
  maximumFractionDigits: 2,
});

const formatTypes = ['text', '$', '%', 'yes/no'];

// deprecated
export const getPremium = ({ plan, key, interval = 12 }) => {
  return getProductPremium({cost: plan, key, interval});
}

export const getDisplayValue = ({ value, format, plan }) => {

  let formatType = format;
  if (!formatTypes.includes(format)) {
    formatType = get(plan, format, null);
    if (formatType === null) {
      return '-';
    }
  }

  if (value === -1) {
    return '-';
  }

  if (formatType === 'text') {
    return value;
  } else if (formatType === '$') {
    value = Math.max(0, value);
    return currencyFormatter.format(value);
  } else if (formatType === '%') {
    value = Math.min(Math.max(0, value), 100);
    value = value <= 1 ? value * 100 : value;
    return `${value}%`;
  } else if (formatType === 'yes/no') {
    return value ? 'Yes' : 'No';
  }
}

export const getProfessionalServicesDisplayValue = ({ value, format, plan }) => {
  const type = get(plan, `InNetworkProfessionalServices.${format}.ADOrCopay`, 'AD');
  const formatType = get(plan, `InNetworkProfessionalServices.${format}.PercentOrDollar`, '$');
  if (type === 'AD') {
    return [`${getDisplayValue({ value, format: formatType, plan })} AD`]
  } else if (type === 'COPAY') {
    return [`${getDisplayValue({ value, format: formatType, plan })} Copay`]
  } else if (type === 'COPAY + %') {
    const specialCopay = get(plan, `InNetworkProfessionalServices.${format}.SpecialCopay`, null);
    if (specialCopay !== null) {
      return [
        getDisplayValue({ value: specialCopay, format: '$', plan }),
        getDisplayValue({ value, format: formatType, plan })
      ]
    } else {
      return [getDisplayValue({ value, format: formatType, plan })]
    }

  } else {
    return ['-'];
  }
}

export const getSurestProfessionalServicesDisplayValue = ({ plan, key }) => {

  const lower = get(plan, `${key}Lower`);
  const upper = get(plan, `${key}Upper`, null);
  const type = upper === "" || upper == null || upper > 0 ? 'Copay Range' : 'Copay';

  if (type === 'Copay') {
    const lowerValue = getDisplayValue({ value: lower, format: '$', plan });
    return `${lowerValue} Copay`;
  } else {
    const lowerValue = getDisplayValue({ value: lower, format: '$', plan });
    const upperValue = getDisplayValue({ value: upper, format: '$', plan });
    return `${lowerValue} - ${upperValue} Copay Range`;
  }
}

export const getPrescriptionDrugsDisplayValue = ({ value, format, plan }) => {
  if (formatTypes.includes(format)) {
    return [getDisplayValue({ value, format, plan })];
  }
  const type = get(plan, `PrescriptionDrugs.${format}.ADOrCopay`, 'AD');
  const formatType = get(plan, `PrescriptionDrugs.${format}.PercentOrDollar`, '$');
  if (type === 'AD') {
    return [`${getDisplayValue({ value, format: formatType, plan })} AD`]
  } else if (type === 'COPAY') {
    return [getDisplayValue({ value, format: formatType, plan })]
  } else if (type === 'COPAY + %') {
    const specialCopay = get(plan, `PrescriptionDrugs.${format}.SpecialCopay`, null);
    if (specialCopay !== null) {
      return [
        getDisplayValue({ value: specialCopay, format: '$', plan }),
        getDisplayValue({ value, format: formatType, plan })
      ]
    } else {
      return [getDisplayValue({ value, format: formatType, plan })]
    }

  } else {
    return ['-'];
  }

}

export const surestSummaryDefaults = {
  professionalServices: [
    {
      key: 'SurestCopays.PrimaryCare',
      label: 'Primary Care Physician',
      format: 'PrimaryCare',
    },
    {
      key: 'SurestCopays.Specialist',
      label: 'Specialist',
      format: 'Specialist'
    },
    {
      key: 'SurestCopays.TeleMedicine',
      label: 'Telehealth Visit',
      format: 'TeleMedicine'
    },
    {
      key: 'SurestCopays.InpatientHospital',
      label: 'In-Patient Hospital',
      format: 'InpatientHospital'
    },
    {
      key: 'SurestCopays.OutpatientSurgery',
      label: 'Outpatient Surgery',
      format: 'OutpatientSurgery'
    },
    {
      key: 'SurestCopays.MentalHealth',
      label: 'Mental Health Visit',
      format: 'MentalHealth',
    }, {
      key: 'SurestCopays.Baby',
      label: 'Maternity',
      format: 'Baby',
    }, {
      key: 'SurestCopays.UrgentCare',
      label: 'Urgent Care',
      format: 'UrgentCare',
    }, {
      key: 'SurestCopays.EmergencyRoom',
      label: 'Emergency Room',
      format: 'EmergencyRoom',
    }, {
      key: 'SurestCopays.PhysicalTherapy',
      label: 'Physical Therapy',
      format: 'PhysicalTherapy',
    }, {
      key: 'SurestCopays.Virtual',
      label: 'Virtual',
      format: 'Virtual',
    },
  ],
  prescriptionDrugs: [
    {
      key: 'SurestCopays.Tier1',
      label: 'Tier',
      format: 'SurestCopays.Tier1.PercentOrDollar'
    },
  ]
};

export const medicalSummaryDefaults = {
  planDetails: [
    {
      key: 'InNetworkPlanDesign.SingleDeductible',
      label: 'Deductible - Individual',
      format: '$',
    },
    {
      key: 'InNetworkPlanDesign.Deductible',
      label: 'Deductible - Family',
      format: '$',
    },
    {
      key: 'InNetworkPlanDesign.SingleOOPM',
      label: 'Out of Pocket Max - Individual',
      format: '$',
    },
    {
      key: 'InNetworkPlanDesign.OOPM',
      label: 'Out of Pocket Max - Family',
      format: '$',
    },
    {
      key: 'InNetworkPlanDesign.Coinsurance',
      label: 'Co-Insurance',
      format: '%'
    },
  ],
  professionalServices: [
    {
      key: 'InNetworkProfessionalServices.PrimaryCarePhysician.Value',
      label: 'Primary Care Physician',
      format: 'PrimaryCarePhysician',
    },
    {
      key: 'InNetworkProfessionalServices.Specialist.Value',
      label: 'Specialist',
      format: 'Specialist'
    },
    {
      key: 'InNetworkProfessionalServices.TeleMedicine.Value',
      label: 'Telehealth Visit',
      format: 'TeleMedicine'
    },
    {
      key: 'InNetworkProfessionalServices.MentalHealthOutpatient.Value',
      label: 'Mental Health Visit',
      format: 'MentalHealthOutpatient'
    },
    {
      key: 'InNetworkProfessionalServices.InPatientHospital.Value',
      label: 'Inpatient Hospital',
      format: 'InPatientHospital'
    },
    {
      key: 'InNetworkProfessionalServices.OutPatientSurgery.Value',
      label: 'Outpatient Surgery',
      format: 'OutPatientSurgery'
    },
    {
      key: 'InNetworkProfessionalServices.EmergencyRoom.Value',
      label: 'Emergency Room',
      format: 'EmergencyRoom'
    },
    {
      key: 'InNetworkProfessionalServices.UrgentCare.Value',
      label: 'Urgent Care',
      format: 'UrgentCare'
    },
  ],
  prescriptionDrugs: [
    {
      key: 'PrescriptionDrugs.MedicalDeductibleApplies.Value',
      label: 'Rx Deductible',
      format: '$'
    },
    {
      key: 'PrescriptionDrugs.Tier1.Value',
      label: 'Tier',
      format: 'PrescriptionDrugs.Tier1.PercentOrDollar'
    },
    {
      key: 'PrescriptionDrugs.MailOrder',
      label: 'Mail Order',
      format: 'text',
    }
  ]

}


export const dentalSummaryDefaults = {
  planDetails: [
    {
      key: 'RawData.AnnualMaximum',
      label: 'Annual Maximum',
      format: '$',
    },
    {
      key: 'RawData.IndividualDeductible',
      label: 'Deductible - Individual',
      format: '$',
    },
    {
      key: 'RawData.FamilyDeductible',
      label: 'Deductible - Family',
      format: '$',
    },
    {
      key: 'RawData.PreventativeServices',
      label: 'Preventative Services',
      format: '%',
    },
    {
      key: 'RawData.BasicServices',
      label: 'Basic Services',
      format: '%',
    },
    {
      key: 'RawData.MajorServices',
      label: 'Major Services',
      format: '%'
    },
  ],

}

export const visionSummaryDefaults = {
  planDetails: [
    {
      key: 'RawData.EyeExams',
      label: 'Eye Exams',
      format: 'text',
    },
    {
      key: 'RawData.LensesBenefit',
      label: 'Lenses Benefit',
      format: 'text',
    },
    {
      key: 'RawData.ContactLenses',
      label: 'Contact Lenses',
      format: 'text',
    },
    {
      key: 'RawData.Frames',
      label: 'Frames',
      format: 'text',
    },
  ],

}