import { getGenericQueryProps, getUrl } from ".";

const url = "/v2/business/:businessId/users";

export const subscribableUsers = {
  url,
  key: "subscribable-users",
  getResourceOptions: (resourceProps) => {
    const resourceUrl = getUrl(url, resourceProps);
    return {
      url: resourceUrl,
      queryProps: {
        initialData: [],
        ...getGenericQueryProps(resourceUrl, resourceProps),
      },
    };
  },
  utils: {},
};
