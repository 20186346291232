import { css } from "emotion";
import { X } from "phosphor-react";
import { useEffect, useState } from "react";
import { toast } from "react-toastify";
import { Button, Div, Input, Modal, Text } from "../shared/components";
import { flex, px } from "../shared/shared-styles";

export const NewDesignStylesModal = ({ courseName = '', createNewDesignStyle, ...modalProps }) => {

  const [name, setName] = useState(courseName);
  
  useEffect(() => {
    if (modalProps.display) {
      if (courseName) {
        setName(`${courseName} Design Styles`);
      } else {
        setName(`New Design Style`);
      }
    }
  }, [modalProps.display]);

  const handleCreateNew = async () => {
    try {
      const newName = name;
      modalProps.onClose();
      await createNewDesignStyle(newName);
    } catch (err) {
      toast.error(`Failed to create new design style.`);
    }
  }

  return (
    <Modal {...modalProps} >
      <Div css={css`padding: ${px.xl}; width: 500px;`}>
        <Div css={css`${flex('jcsb aic')}`}>
          <Text styles="h2">Create New Design Style</Text>
          <Button styles="icon" onClick={modalProps.onClose}><X /></Button>
        </Div>
        <Text styles="label mt">Name</Text>
        <Input
          value={name}
          onChange={(e) => setName(e.target.value)}
          className={css`width: 100%;`}
        />
        <Div css={css`margin-top: ${px.xl}; ${flex('jcr aic')}`}>
          <Button styles="secondary mr" onClick={modalProps.onClose}>Cancel</Button>
          <Button onClick={handleCreateNew}>Save</Button>
        </Div>
      </Div>
    </Modal>
  );

}