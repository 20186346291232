import { Collapse } from "@material-ui/core";
import { css } from "emotion";
import { CaretDown, CaretUp, Sparkle } from "phosphor-react";
import { useState } from "react";
import { Div, Text } from "../../shared/components";
import { flex } from "../../shared/shared-styles";
import { sectionContainer } from "../product-section";
import { CustomDetails } from "./custom-details";
import { FlagsBadge } from "../flags/flags-badge";

export const CustomDetailSectionWrapper = ({ state }) => {
  const [open, setOpen] = useState(false);

  return (
    <Div css={sectionContainer(open)} onClick={() => !open && setOpen(!open)}>
      <Div
        css={css`
          ${flex("jcsb aic")} cursor: pointer;
          ${open && `padding-bottom: 16px;`}
        `}
        onClick={() => setOpen(!open)}
      >
        <Div
          css={css`
            ${flex("aic")}
          `}
        >
          <Div
            css={css`
              ${flex("aic")} min-width: 32px;
              margin-right: 8px;
            `}
          >
            {<Sparkle />}
          </Div>
          <Text styles="h3">Custom Details</Text>
        </Div>
        <Div
          css={css`
            ${flex("right")}
            > svg {
              margin-left: 8px;
            }
          `}
        >
          <FlagsBadge
            properties={["custom-details"]}
            flags={state?.flags?.query?.data}
          />
          {open ? <CaretUp /> : <CaretDown />}
        </Div>
      </Div>

      <Collapse in={open}>
        <CustomDetails state={state} />
      </Collapse>
    </Div>
  );
};
