import { useEffect, useState } from "react";
import { useDebounce } from "../shared/use-debounce";
import { productUtils } from "./configs/product-utils";

// To be used in combination with useProductData "state"
export const useAutosave = (state) => {
  const [isAutoSaving, setIsAutoSaving] = useState(false);

  const debounced_save = useDebounce(async () => {
    setIsAutoSaving(true);
    try {
      await productUtils.saveProduct(state, { shouldRefetch: false });
    } catch (err) {
      console.log(err);
    } finally {
      setIsAutoSaving(false);
    }
  }, 250);

  useEffect(() => {
    if (
      state?.save?.status === "save" &&
      state.hasChanges &&
      state.isValid &&
      !state.isLoading
    ) {
      debounced_save();
    }
  }, [
    state.hasChanges,
    state.isLoading,
    state.isValid,
    state?.save?.updatedAt,
  ]);

  return isAutoSaving;
};
