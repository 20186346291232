import { mergeWith } from "lodash";
import { useFeatureFlagPayload } from "posthog-js/react";
import React, { useEffect, useReducer } from "react";
import { removeItem } from "./utils";

export const defaultLayout = {
  root: {
    style: {
      minWidth: "350px",
      width: "100%",
      maxWidth: "700px",
    },
  },
  rows: [],
};

export const initialState = {
  viewMode: "desktop",
  layout: defaultLayout,
  multiSelect: [],
  styles: null,
  editorType: "page",
};

const specialMerge = (obj, src) => {
  if (Array.isArray(src)) {
    return src;
  }
};

export const clearNonRenderableRows = (layout) => {
  const rows = layout?.rows?.filter?.(({ rowId = null }) => rowId);
  return { ...layout, rows };
};

// "location" is an array of indices [rowIdx, columnIdx, *listIdx]
// *listIdx is optional

// "path" is the string representation of the location:
// ie, path = "rows[rowIdx].columns[columnIdx].list[listIdx]"

// "updates" is always an object respresentation of the data that you're updating.

const saveUpdateTypes = [
  "set-layout-content",
  "merge-layout-content",
  "remove-layout-content",
];

const reducer = (nextState, action) => {
  const selectionChange = action?.options?.resetSelection
    ? {
        selection: null,
        multiSelect: null,
      }
    : {};

  const recentUpdate = {
    type: action?.type,
    key: action?.key,
    updatedAt: new Date().toISOString(),
    location: action?.location || [],
    options: action?.options || {},
  };

  const saveChanges =
    (saveUpdateTypes.includes(recentUpdate?.type) &&
      !recentUpdate?.options?.disableSave) ||
    recentUpdate?.options?.forceSave
      ? {
          saveStatus: {
            action: "save-changes",
            updatedAt: new Date().toISOString(),
          },
        }
      : {};

  const syncRenderState = action?.options?.syncRenderState
    ? new Date().toISOString()
    : nextState?.syncRenderState;

  let state = {
    ...nextState,
    ...selectionChange,
    ...saveChanges,
    syncRenderState,
    recentUpdate,
  };

  switch (action.type) {
    case "feature-flag": {
      return {
        ...state,
        flags: {
          ...(state?.flags || {}),
          [action.flag]: action.value,
        },
      };
    }

    case "complete-save-changes": {
      return {
        ...state,
        saveStatus: {
          ...state?.saveStatus,
          action: "saved",
          updatedAt: new Date().toISOString(),
        },
      };
    }

    case "set-layout-content": {
      const { location, updates } = action;
      let nextRows = [...state?.layout?.rows];
      if (!location?.length) {
        return {
          ...state,
          layout: {
            ...state?.layout,
            rows: [...updates],
          },
        };
      } else {
        const [rowIdx, colIdx, listIdx] = location;
        if (location?.length === 1) {
          nextRows[rowIdx] = updates;
        } else if (location?.length === 2) {
          nextRows[rowIdx].columns[colIdx] = updates;
        } else if (location?.length === 3) {
          nextRows[rowIdx].columns[colIdx].list[listIdx] = updates;
        }
        return {
          ...state,
          layout: {
            ...state?.layout,
            rows: nextRows,
          },
        };
      }
    }

    case "merge-layout-content": {
      const { location, updates } = action;
      let nextRows = [...state?.layout?.rows];
      const [rowIdx, colIdx, listIdx] = location;
      if (location?.length === 1) {
        const item = state?.layout?.rows?.[rowIdx] || {};
        const nextItem = mergeWith({}, item, updates, specialMerge);
        nextRows[rowIdx] = nextItem;
      } else if (location?.length === 2) {
        const item = state?.layout?.rows?.[rowIdx]?.columns?.[colIdx] || {};
        const nextItem = mergeWith({}, item, updates, specialMerge);
        nextRows[rowIdx].columns[colIdx] = nextItem;
      } else if (location?.length === 3) {
        const item =
          state?.layout?.rows?.[rowIdx]?.columns?.[colIdx]?.list?.[listIdx];
        const nextItem = mergeWith({}, item, updates, specialMerge);
        nextRows[rowIdx].columns[colIdx].list[listIdx] = nextItem;
      }
      return {
        ...state,
        layout: {
          ...state?.layout,
          rows: nextRows,
        },
      };
    }

    case "remove-layout-content": {
      const { location } = action;
      const rows = removeItem(state?.layout, location);
      return {
        ...state,
        layout: {
          ...state?.layout,
          rows,
        },
        selection: [],
      };
    }

    case "initialize-content": {
      return {
        ...state,
        layout: { ...action?.layout },
      };
    }

    case "set-property": {
      return {
        ...state,
        [action?.key]: action?.value,
      };
    }

    default:
      return state;
  }
};

export const EditorStoreContext = React.createContext();

export const EditorStoreProvider = (props) => {
  const textConversionFF = useFeatureFlagPayload("text-conversion-new");
  const textConversion = textConversionFF?.value;
  const [state, dispatch] = useReducer(reducer, {
    ...initialState,
    flags: {
      textConversion,
    },
  });

  useEffect(() => {
    dispatch({
      type: "feature-flag",
      flag: "textConversion",
      value: textConversion,
    });
  }, [textConversionFF]);

  return (
    <EditorStoreContext.Provider value={{ state, dispatch }}>
      {props.children}
    </EditorStoreContext.Provider>
  );
};
