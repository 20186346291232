import { css } from "emotion";
import { File } from "phosphor-react";
import { ExtensionIconMapper } from "../../Documents/ExtensionIconMapper";
import { Div, Text } from "../../shared/components";
import { flex } from "../../shared/shared-styles";
import { colors } from "../../shared/styles";

const textareaStyle = css`
  width: 100%;
  input {
    display: inline;
    outline: none;
    border: none;
    background: none;
    appearance: none;
    padding: 0;
    margin: 0;
    overflow: hidden;
    resize: none;
    margin-left: 16px;
    font-size: 18px;
    color: ${colors.black};
  }
`;

export const Document = ({ element }) => {
  const { document = null } = element;
  return (
    <div className={textareaStyle}>
      {!document ? (
        <Div
          data-component={true}
          className={css`
            ${flex("jcc aic column")}
            background-color: ${colors.gray[100]};
            border-radius: 8px;
            width: 100%;
            min-height: 100px;
          `}
        >
          <File
            size={48}
            className={css`
              pointer-events: none;
            `}
          />
          <Text
            styles="bold label"
            className={css`
              pointer-events: none;
            `}
          >
            Document
          </Text>
        </Div>
      ) : (
        <Div
          data-component={true}
          className={css`
            ${flex("jcl aic")}
            background-color: ${colors.gray[100]};
            border-radius: 8px;
            width: 100%;
            height: 64px;
            padding: 0 32px;
          `}
        >
          <ExtensionIconMapper
            fileType={document?.FileType}
            className={css`
              pointer-events: none;
            `}
          />
          <Text
            label
            css={`
              margin-left: 16px;
            `}
          >
            {document?.Name}
          </Text>
        </Div>
      )}
    </div>
  );
};
