import React from "react";
import { css } from "emotion";
import { CheckBox } from "grommet";
import { connect } from "react-redux";
import { updateCourseChanges } from "../redux/actions/courses";
import { CustomAxios } from "../redux/axios/axios";
import CircularProgress from "@material-ui/core/CircularProgress";
import IconButton from "@material-ui/core/IconButton";
import Tooltip from "@material-ui/core/Tooltip";
import HelpOutlineIcon from "@material-ui/icons/HelpOutline";
import { useQueryAPI } from "../react-query";
import { Button, Div, Text } from "../shared/components";
import { flex } from "../shared/shared-styles";
import { colors } from "../shared/styles";
import { history } from "../history";

export const DecisionToolBuilder = (props) => {
  const onLoad = async () => {
    const { data: course } = await CustomAxios.get(
      `/v1/course/${props.course.ID}`
    );
    const { data } = await CustomAxios.get(
      `/v1/benefitspackage/${course.BenefitsPackageID}`
    );
    return data;
  };

  const { data: benefitsPackage, isLoading } = useQueryAPI({
    urlKey: [`course-benefits`, props.course.ID],
    queryFn: onLoad,
    enabled: !!props.course.ID,
    defaultValue: {},
  });

  if (props.courseIsSaving) {
    return (
      <div
        className={css`
          display: flex;
          justify-content: center;
          margin-left: 20px;
          width: 100%;
          border: 1px solid #dedede;
          border-radius: 5px;
          padding: 24px;
        `}
      >
        {" "}
        <CircularProgress />
      </div>
    );
  }

  return (
    <div
      id="builder"
      className={css`
        overflow: auto;
        border: 1px solid #dedede;
        border-radius: 5px;
        height: ${props.viewHeight};
        padding: 24px;
        width: 100%;
      `}
    >
      <div>
        <div
          className={css`
            font-weight: 400;
            font-size: 26px;
            line-height: 28px;
            color: #797979;
            padding: 16px;
          `}
        >
          Decision Tool
          <Tooltip title="Toggle on each benefit that this company offers. The employee will be asked a series of questions and a benefit package recommendation will be provided to them.">
            <IconButton aria-label="help">
              <HelpOutlineIcon />
            </IconButton>
          </Tooltip>
        </div>
        <div
          className={css`
            padding: 24px 16px;
          `}
        >
          <div
            className={css`
              width: 764px;
              font-weight: 400;
              font-size: 16px;
              line-height: 20px;
              color: #25282d;
              font-weight: bold;
              margin-top: 8px;
            `}
          >
            Medical Recommendation Settings
          </div>

          <div
            className={css`
              margin-top: 14px;
            `}
          >
            <CheckBox
              label="Applies to new hires only"
              toggle
              checked={props.course.Type === "newhire"}
              onChange={(toggleNewHire) => {
                var course = { ...props.course };

                if (toggleNewHire.target.checked) {
                  course.Type = "newhire";
                } else {
                  course.Type = "openenrollment";
                }
                props.updateCourseChanges(course);
              }}
            />
          </div>

          <div
            className={css`
              margin-top: 14px;
            `}
          >
            <CheckBox
              label="Display plan costs as monthly costs"
              toggle
              checked={props.course.ViewTotalsMonthly}
              onChange={(toggleNewHire) => {
                var course = { ...props.course };
                course.ViewTotalsMonthly = toggleNewHire.target.checked;
                props.updateCourseChanges(course);
              }}
            />
          </div>
        </div>
        <div>
          <div
            className={css`
              display: flex;
              justify-content: center;
              align-items: center;
              margin-top: 24px;
            `}
          >
            {isLoading ? (
              <CircularProgress />
            ) : (
              benefitsPackage.ID && (
                <Div
                  css={css`
                    margin-right: auto;
                    width: 100%;
                    max-width: 700px;
                    padding: 32px;
                    border: 1px solid ${colors.gray[300]};
                    border-radius: 8px;
                    p {
                      margin-bottom: 16px;
                    }
                  `}
                >
                  <Text label>
                    <strong>Editing the Benefits Package</strong> from within
                    the guide editor{" "}
                    <strong
                      className={css`
                        color: ${colors.purple};
                      `}
                    >
                      requires Auto Save
                    </strong>{" "}
                    to be turned on. <br />
                    <br />
                  </Text>
                  <Div
                    css={css`
                      ${flex("right")}
                      span {
                        margin-right: 32px;
                      }
                    `}
                  >
                    <Text as="span">Go to settings to turn on Auto Save.</Text>
                    <Button
                      secondary
                      css={`
                        margin-right: 16px;
                      `}
                      onClick={() =>
                        history.push(
                          `/${props?.course?.BusinessID}/packages/${props?.course?.BenefitsPackageID}`
                        )
                      }
                    >
                      Go To Benefits Package
                    </Button>
                  </Div>
                </Div>
              )
            )}
          </div>
        </div>
      </div>
    </div>
  );
};

export const DecisionToolBuilderContainer = connect(
  (state, ownProps) => ({
    updateCourseChanges: ownProps.updateCourseChanges,
    course: ownProps.course,
    selectedPage: ownProps.selectedPage,
    viewHeight: ownProps.viewHeight,
    courseIsSaving: state.courses.saving,
  }),
  (dispatch) => {
    return {
      updateCourseChanges: (course) => {
        dispatch(updateCourseChanges(course));
      },
    };
  }
)(DecisionToolBuilder);
