import React, { useState, useEffect } from "react";
import { css } from "emotion";
import CircularProgress from "@material-ui/core/CircularProgress";
import { makeStyles } from "@material-ui/core/styles";
import { CustomAxios } from "../../redux/axios/axios";
import List from "@material-ui/core/List";
import ListItem from "@material-ui/core/ListItem";
import ListItemText from "@material-ui/core/ListItemText";
import Divider from "@material-ui/core/Divider";

const useStyles = makeStyles((theme) => ({
  list: {
    margin: `0 auto`,
    width: 700,
    color: "#25282D",
    fontWeight: 500,
  },
}));

export const LoadDesignStyle = (props) => {
  const classes = useStyles();
  const [loading, setLoading] = useState(false);
  const [designStyles, setDesignStyles] = useState([]);

  useEffect(() => {
    fetchDesigns();
  }, []);

  const fetchDesigns = async () => {
    setLoading(true);
    let themesTemp = [];
    try {
      const resp = await CustomAxios.get(`/v1/themes`);
      themesTemp = resp.data;
    } catch (e) {
      themesTemp = [];
    }

    setDesignStyles(themesTemp);
    setLoading(false);
  };

  if (loading) {
    return (
      <div
        className={css`
          display: flex;
          justify-content: center;
          margin: 48px;
        `}
      >
        <CircularProgress />
      </div>
    );
  }

  return (
    <div
      className={css`
        margin-bottom: 32px;
        overflow: auto;
        max-height: 500px;
      `}
    >
      <div className={classes.list}>
        <List>
          {designStyles.map((designStyle) => {
            return (
              <>
                <ListItem
                  button
                  onClick={() => {
                    props.setTheme(designStyle.Theme);
                    props.setThemeID(designStyle.ID);
                    props.setThemeName(designStyle.Name);
                    props.close("");
                  }}
                >
                  <ListItemText primary={designStyle.Name} />
                </ListItem>
                <Divider />
              </>
            );
          })}
        </List>
      </div>
    </div>
  );
};
