import { useEffect } from "react";
import { css, cx } from "emotion";
import { FirstAidKit, Warning } from "phosphor-react";
import { useDeepCompareMemo } from "use-deep-compare";
import { PRODUCT_HEADERS } from "../../../benefit-package/constants";
import { Button, Div, Text } from "../../../shared/components";
import { flex } from "../../../shared/shared-styles";
import { colors } from "../../../shared/styles";
import { useStore } from "../../../store-provider/use-store";
import { modifyContent } from "../../provider/utils";
import { useEditorResource } from "../../use-editor-resource";
import { AddBenefitsModal, sortMap } from "./add-benefit";
import { BenefitSummary } from "./benefit-summary";

const emptyContainer = css`
  position: relative;
  width: 100%;
  background-color: ${colors.gray[100]};
  min-height: 300px;
  height: 100%;
  border-radius: 8px;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
`;

const errorContainer = css`
  position: relative;
  width: 100%;
  max-width: 500px;
  border-radius: 8px;
  border: 1px solid ${colors.gray[400]};
  margin: 8px auto;
  padding: 32px;
  display: flex;
  flex-direction: column;
`;

export const Benefits = (props) => {
  const {
    element,
    location,
    editor,
    //
    isSelected,
  } = props;

  const {
    data: { devMode },
  } = useStore();
  const { data: styles } = useEditorResource("styles");
  const { data: products, refetch } = useEditorResource("products", {
    select: (data) =>
      data?.sort(
        (a, b) =>
          a?.MultiNetworkID?.localeCompare?.(b?.MultiNetworkID) ||
          sortMap?.[a?.MultiNetworkCategory]?.localeCompare?.(
            sortMap[b?.MultiNetworkCategory]
          )
      ),
  });

  useEffect(() => {
    if (isSelected && !element?.data?.id) {
      refetch();
    }
  }, [isSelected]);

  const isIDSmartField =
    element?.data?.id?.startsWith("{{") && element?.data?.id?.endsWith("}}");
  const productExists =
    isIDSmartField ||
    products.findIndex(({ ID }) => ID === element?.data?.id) > -1;
  const benefitProps = {
    ...props,
    styles,
    packageId: editor?.state?.course?.BenefitsPackageID,
  };

  const SummaryComponent = useDeepCompareMemo(() => {
    return <BenefitSummary {...benefitProps} products={products} />;
  }, [
    isSelected,
    element.type,
    element.data,
    devMode,
    editor?.state?.viewMode,
    editor?.state?.course?.BenefitsPackageID,
  ]);

  const removeBenefit = () => {
    if (isSelected) {
      modifyContent.set(editor, location, {
        ...element,
        data: {},
      });
    }
  };

  if (
    editor?.state?.editorType === "page" && !devMode
      ? !element?.data?.id
      : !element?.type
  ) {
    return (
      <>
        <Div className={emptyContainer}>
          <FirstAidKit size={48} weight="light" />
          <Text styles="bold label padt-sm">
            {props?.element?.component === "benefits"
              ? "Benefit Summary"
              : props?.element?.component === "pricing"
              ? "Pricing Summary"
              : props?.element?.component === "network"
              ? "Network Search"
              : ""}
          </Text>
          <Button
            styles="secondary"
            className={css`
              margin-top: 16px;
            `}
          >
            Add Benefit
          </Button>
        </Div>
        {isSelected && (
          <AddBenefitsModal
            {...props}
            products={products}
            editorType={editor?.state?.editorType}
          />
        )}
      </>
    );
  } else if (
    productExists ||
    (element?.type && (editor?.state?.editorType !== "page" || devMode))
  ) {
    return SummaryComponent;
  } else {
    return (
      <Div
        className={cx(
          errorContainer,
          css`
            ${flex("center column")}
            height: 300px;
            background-color: ${colors.red[100]}22;
          `
        )}
      >
        <Div
          css={css`
            ${flex("jcsa aic column")} margin-bottom: 32px;
          `}
        >
          <Warning color={colors.red[100]} />
          <Text
            styles="h4"
            className={css`
              margin-top: 16px;
            `}
          >
            Error Loading {PRODUCT_HEADERS?.[element?.type]} Summary
          </Text>
        </Div>
        <Button styles="secondary" onClick={removeBenefit}>
          Add {PRODUCT_HEADERS?.[element?.type]} Summary
        </Button>
      </Div>
    );
  }
};
