import { css } from "emotion";
import { merge } from "lodash";
import { X } from "phosphor-react";
import { CustomAxios } from "../redux/axios/axios";
import { Button, Div, Modal, Text } from "../shared/components";
import { flex } from "../shared/shared-styles";
import { productModifier } from "./configs/product-utils";

export const ProductDevSettings = ({ state, ...modalProps }) => {
  const { product, layout } = state;

  const mergeDefaultLayout = async () => {
    try {
      const response = await CustomAxios.get(
        `v1/product-layout/${product?.Type}`
      );
      const layoutSections = merge(
        response?.data?.Layout?.Sections,
        layout?.Layout?.Sections
      );
      productModifier.setLayout(state, {
        ...layout,
        Layout: {
          ...layout?.Layout,
          Sections: layoutSections,
        },
      });
    } catch (err) {
      console.warn(err);
    }
  };

  const resetDefaultLayout = async () => {
    try {
      const response = await CustomAxios.get(
        `v1/product-layout/${product?.Type}`
      );
      const layoutChanges = {
        ...layout,
        Layout: {
          ...layout?.Layout,
          Sections: response?.data?.Layout?.Sections,
        },
      };
      productModifier.setLayout(state, layoutChanges);
    } catch (err) {
      console.warn(err);
    }
  };

  return (
    <Modal {...modalProps}>
      <Div
        css={css`
          width: 500px;
        `}
      >
        <Div
          css={css`
            ${flex("space-between")}
            padding: 32px;
          `}
        >
          <Text h1>Dev Settings</Text>
          <Button styles="icon" onClick={modalProps.onClose}>
            <X />
          </Button>
        </Div>
        <Div
          css={css`
            padding: 32px;
            padding-top: 0;
            > div {
              padding: 8px 0;
            }
          `}
        >
          <Div
            css={css`
              ${flex("space-between")}
            `}
          >
            <Text label>Reset to Default Layout Sections</Text>
            <Button onClick={resetDefaultLayout}>Reset</Button>
          </Div>
          <Div
            css={css`
              ${flex("space-between")}
            `}
          >
            <Text label>Merge Default Layout Sections</Text>
            <Button onClick={mergeDefaultLayout}>Merge</Button>
          </Div>

          <Div
            css={css`
              ${flex("space-between")}
            `}
          >
            <Text label>Log Current Layout</Text>
            <Button onClick={() => console.log(layout)}>Log</Button>
          </Div>
        </Div>
      </Div>
    </Modal>
  );
};
