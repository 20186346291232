import { cloneDeep, get } from "lodash";
import posthog from "posthog-js";
import { useFeatureFlagPayload } from "posthog-js/react";
import { useContext, useEffect, useRef, useState } from "react";
import { toast } from "react-toastify";
import { isRedoCode, isUndoCode } from "../Content/Builder-v2/course-viewer";
import { useKeyboard } from "../Router/use-keyboard";
import { extractDOMRect, useBounds } from "../shared/use-bounds";
import { useDebounce } from "../shared/use-debounce";
import { useSearchParams } from "../shared/use-search-params";
import { matchingComponents } from "./find-and-replace";
import { EditorDetailsContext } from "./provider/editor-detail-provider";
import { defaultLayout } from "./provider/provider";
import { useEditor } from "./provider/use-editor-store";
import {
  editorUtils,
  copyLayoutColumn,
  copyLayoutListItem,
  copyLayoutRow,
  copyMultipleLayoutRows,
  getLocationPath,
  modifyContent,
  iterateLayout,
  getNextLocation,
  getLastIndices,
} from "./provider/utils";
import {
  useCacheEditorResources,
  useEditorResource,
} from "./use-editor-resource";
import { needsAttention, useResource } from "../react-query";
import { useStore } from "../store-provider/use-store";

const userInputDOMElements = ["INPUT", "TEXTAREA"];
const noop = () => {};

export const countEvaluationErrors = (data) => {
  const entries = Object.entries(data ?? {});
  const evaluations = entries.reduce(
    (prev, [key, value]) =>
      key?.startsWith("{{") ? prev + (value?.Success ? 0 : 1) : prev,
    0
  );
  return evaluations;
};

export const useBriteEditor = ({
  saveCourseRecord = noop,
  query = {},
  versionId = "",
  savePage = noop,
  course = {},
  editorType = "page",
  updateSmartFieldEvaluations,
  pageLease,
}) => {
  const {
    data: { devMode },
  } = useStore();
  const collaborationFF = useFeatureFlagPayload("editor-collaboration")?.value;
  const { params, updateParams } = useSearchParams();
  const { data, cache } = query;

  const { data: smartTextEvaluation, refetch: refetchSmartFieldEvaluation } =
    useEditorResource("liveSmartFields", {
      select: (data) => data?.Data,
      defaultValue: 0,
    });

  const evaluationErrorCount = countEvaluationErrors(smartTextEvaluation);

  useEffect(() => {
    updateSmartFieldEvaluations &&
      updateSmartFieldEvaluations(evaluationErrorCount);
  }, [evaluationErrorCount]);

  const { editorDetails, dispatch, setProperties } =
    useContext(EditorDetailsContext);

  const { data: styles } = useEditorResource("styles");

  const needsAttentionResource = useResource(needsAttention, {
    enabled: !!collaborationFF,
    search: {
      business_id: `eq.${course?.BusinessID}`,
      resource_id: `eq.${course?.ID}`,
    },
  });

  const commentsData = needsAttentionResource?.data;
  const dataUpdatedAt = needsAttentionResource?.query?.dataUpdatedAt;

  const comments = commentsData || [];

  useEffect(() => {
    const commentByComponent = comments?.reduce((prev, item) => {
      const {
        metadata: { componentId, pageId, threadId },
        resolved,
        id,
      } = item;
      const list = prev?.[componentId] || [];
      const properId = threadId || id;
      if (
        list?.includes(properId) ||
        (!componentId && pageId === data?.ID) ||
        resolved
      ) {
        return prev;
      }
      return {
        ...prev,
        [componentId]: [...list, properId],
      };
    }, {});
    dispatch({
      type: "SET",
      key: "commentByComponent",
      payload: commentByComponent,
    });
  }, [dataUpdatedAt, comments]);

  const editor = useEditor();
  const {
    state: { layout, viewMode, selection, multiSelect, saveStatus },
  } = editor;

  const editorRef = useRef(null);

  // ********************************************
  // -->> Handle saving layout changes
  // ********************************************

  const debouncedSavePage = useDebounce(async (layout) => {
    // const html = generateHTML(layout);
    posthog.capture("CourseSave");
    const html = "";
    await savePage({
      Content: layout,
      HTMLContent: html,
    });
    editorUtils?.completeSave(editor);
    refetchSmartFieldEvaluation();
  }, 500);

  const debouncePosthogBriteEditCaptures = useDebounce(() => {
    posthog.capture("CourseEdit", {
      actionType: "brite:page-edit",
    });
  }, 200);

  useEffect(() => {
    if (
      saveStatus?.action === "save-changes" &&
      editorDetails?.toolbarType !== "history"
    ) {
      debouncePosthogBriteEditCaptures();
      debouncedSavePage(layout);
      cache.setData({ ...data, Content: layout });
    }
  }, [saveStatus?.updatedAt]);

  const [readyId, setReadyId] = useState("");
  const [shiftKeyIsPressed, setShiftKeyIsPressed] = useState(false);

  // ********************************************
  // -->> Handle Keyboard Shortcuts
  // ********************************************

  const copyLayout = () => {
    if (selection?.length) {
      const [rowIdx] = selection;
      if (layout.rows[rowIdx]?.columns?.length === 1) {
        // COPY ROW
        const rows = copyLayoutRow(layout, rowIdx);
        modifyContent?.set(editor, [], rows);
      } else if (selection.length === 2) {
        // COPY COLUMN
        const rows = copyLayoutColumn(layout, selection);
        modifyContent?.set(editor, [], rows);
      } else if (selection.length === 3) {
        // COPY LIST-ITEM
        const rows = copyLayoutListItem(layout, selection);
        modifyContent?.set(editor, [], rows);
      }
    } else if (multiSelect?.isSelecting) {
      // COPY MULTIPLE ROWS
      const rows = copyMultipleLayoutRows(
        layout,
        multiSelect.rows,
        multiSelect.end
      );
      modifyContent?.set(editor, [], rows);
    }
  };

  const handleKeydown = (captured, event) => {
    const HTMLElementType = document.activeElement.tagName;
    const isAvailableForShortkey =
      !userInputDOMElements.includes(HTMLElementType) &&
      !document.activeElement?.getAttribute("data-slate-editor") &&
      window?.getSelection()?.isCollapsed;

    if (isAvailableForShortkey && captured === "shift") {
      setShiftKeyIsPressed(true);
    }
    const selectedContent = editorUtils.getSelection(editor);
    if (isUndoCode(captured) || isRedoCode(captured)) {
      if (selectedContent?.component === "text") {
        event.stopPropagation();
      }
    }

    const canUseSingleCharShortkey =
      isAvailableForShortkey && !selection?.length;

    if (
      isAvailableForShortkey &&
      (captured === "+Tab" || captured === "shift+Tab")
    ) {
      event.preventDefault();
      const direction = captured === "+Tab" ? "forward" : "backward";
      const nextLocation = selection?.length
        ? getNextLocation(editor, direction, selection)
        : captured === "+Tab"
        ? [0, 0]
        : getLastIndices(editor);
      editorUtils.setProperty(editor, "selection", nextLocation);
    } else if (captured === "meta+KeyK") {
      if (selection?.length) {
        // log the layout for debugging
        console.log(selection);
        const path = getLocationPath(selection);
        const data = get(layout, path);
        console.log(data);
      }
    } else if (captured === "meta+KeyU") {
    } else if (
      !userInputDOMElements.includes(HTMLElementType) &&
      selectedContent?.component !== "text" &&
      selectedContent?.component !== "textV2" &&
      (captured === "meta+KeyA" || captured === "ctrl+KeyA")
    ) {
      event.preventDefault();
      selectAllRows();
    } else if (
      !userInputDOMElements.includes(HTMLElementType) &&
      (captured === "meta+KeyC" || captured === "ctrl+KeyC")
    ) {
      const canCopyLayout = window?.getSelection()?.isCollapsed;
      if (canCopyLayout) {
        copyLayout();
      }
    } else if (captured === "ctrl+KeyF" && !!selection?.length) {
      if (
        Object.keys(matchingComponents).includes(selectedContent?.component)
      ) {
        updateParams({ courseModal: "find-and-replace" });
      } else {
        toast.info(`This component doesn't support find & replace`);
      }
    } else if (canUseSingleCharShortkey && captured === "+KeyC") {
      if (pageLease?.hasLease) {
        updateParams({
          mode: params.mode === "collaborating" ? "editing" : "collaborating",
        });
      } else {
        toast.error(`You do not have access to switch out of comment mode.`);
      }
    } else if (canUseSingleCharShortkey && captured === "+KeyT") {
      if (editorDetails?.toolbarType !== "components") {
        setProperties({
          toolbarType: "components",
          componentToolbar: true,
        });
      } else {
        setProperties({
          componentToolbar: !editorDetails?.componentToolbar,
          pagesToolbar: !editorDetails?.componentToolbar,
        });
      }
    } else if (canUseSingleCharShortkey && captured === "+KeyP") {
      setProperties({
        pagesToolbar: !editorDetails?.pagesToolbar,
      });
    } else if (canUseSingleCharShortkey && captured === "+KeyG" && devMode) {
      setProperties({
        componentToolbar: true,
        toolbarType: "history",
      });
    }
  };

  const handleKeyup = () => setShiftKeyIsPressed(false);

  useKeyboard({
    keydown: handleKeydown,
    keyup: handleKeyup,
    options: { useCapture: true },
  });

  // ********************************************
  // -->> Pre-Fetch all resources
  // ********************************************
  // useQueryAPI provides a store so these don't need to be added
  // to the editor-details-context and can be accessed via the useEditorResource hook.

  const isLoadingResources = useCacheEditorResources({
    page: data,
    course,
  });

  // ********************************************
  // -->> Handle Editor Effects
  // ********************************************

  // Prepare inline color map used in the TEXT component
  useEffect(() => {
    const designStyleEntries = Object.keys(styles?.general?.colorPalette || {});

    const designStyleColorMap = designStyleEntries.reduce(
      (prev, key) => ({
        ...prev,
        [`color-var(--${key})`]: { color: `var(--${key})` },
      }),
      {}
    );

    const courseColorMap = course?.ColorPickerPresets?.reduce(
      (prev, value) => ({ ...prev, [`color-${value}`]: { color: value } }),
      {}
    );

    editorUtils?.setProperty(editor, "inlineColorMap", {
      ...designStyleColorMap,
      ...courseColorMap,
    });
  }, [course?.ColorPickerPresets?.length, styles?.general?.colorPalette]);

  // Some (TEXT) components are dumb and need to be updated when design styles change
  useEffect(() => {
    if (!isLoadingResources) {
      editorUtils?.setProperty(editor, "update-type", "design-styles");
    }
  }, [isLoadingResources]);

  // Handle Page Changes
  useEffect(() => {
    setProperties({ pageId: data?.ID });
    if (data?.ID && data?.ID !== readyId) {
      setTimeout(() => {
        editorUtils?.initializeContent(editor, data?.Content, {
          disableSave: false,
        });
        setReadyId(data?.ID);
      }, 0);
    }
    dispatch({ type: "SET", key: "commenting", payload: {} });
  }, [data?.ID]);

  // Handle version(history) changes (undo/redo)
  useEffect(() => {
    editorUtils?.setProperty(editor, "versionId", versionId);
    if (readyId && (versionId || editor.state.versionId)) {
      editorUtils?.setProperty(editor, "selection", null);
      const layout =
        cache?.data?.Content === null
          ? cloneDeep(defaultLayout)
          : cloneDeep(cache?.data?.Content);
      editorUtils?.initializeContent(editor, layout);
    }
  }, [versionId]);

  // Handle course changes
  useEffect(() => {
    editorUtils?.setProperty(editor, "course", course);
    editorUtils?.setProperty(editor, "saveCourseRecord", saveCourseRecord);
  }, [course.ID, course.ModifiedAt, course?.ColorPickerPresets?.toString()]);

  // Use full editor functionality (templates don't have full functionality)
  useEffect(() => {
    editorUtils?.setProperty(editor, "editorType", editorType);
  }, [editorType]);

  const setViewMode = (value) =>
    editorUtils?.setProperty(editor, "viewMode", value);

  // Handle editor DOM element bounds
  const containerBounds = useBounds(
    editorRef,
    [viewMode, editorRef?.current, selection?.toString()],
    10,
    {
      disableScroll: true,
    }
  );
  useEffect(() => {
    const scrollTop = editorRef?.current?.scrollTop;
    const editor = extractDOMRect(
      editorRef?.current?.querySelector("[data-editor=true]")
    );
    dispatch({
      payload: { ...containerBounds, scrollTop, editor },
      type: "SET",
      key: "containerBounds",
    });
  }, [containerBounds.lastUpdate]);

  const selectAllRows = () => {
    editorUtils?.setProperty(editor, "selection", null);
    editorUtils?.setProperty(editor, "multiSelect", {
      start: 0,
      end: layout.rows.length - 1,
      rows: layout.rows,
      isSelecting: true,
    });
  };

  const isLoading = isLoadingResources;

  return {
    isLoading,
    setViewMode,
    editorRef,
    readyId,
    editor: { ...editor, shiftKeyIsPressed },
  };
};
