import { css, cx } from "emotion";
import { Layer } from "grommet";
import {
  ArrowLeft,
  ArrowsClockwise,
  ArrowUUpLeft,
  ArrowUUpRight,
  CloudCheck,
  CloudSlash,
  CloudSun,
  Plus,
  WarningCircle,
} from "phosphor-react";
import React, { useCallback, useEffect, useRef, useState } from "react";
import { useParams } from "react-router";
import { history } from "../../history";
import { useQueryAPI, useResource } from "../../react-query";
import {
  Button,
  ConfirmationModal,
  Div,
  DropMenu,
  Text,
} from "../../shared/components";
import { textTheme } from "../../shared/components/text";
import { useStateSync } from "../../shared/use-state-sync";
import { colors } from "../../shared/styles";
import { toast } from "react-toastify";
import { PageList } from "./page-list/page-list";
import { Editor } from "./pages/editor";
import { useSearchParams } from "../../shared/use-search-params";
import { useAutoSave } from "./use-auto-save";
import { LockedPage } from "./pages/locked-page";
import { DecisionTool } from "./pages/decision-tool";
import { EnrollmentFeedback } from "./pages/enrollment-feedback";
import { MultipleChoice } from "./pages/multiple-choice";
import { useLease } from "./use-lease";
import { CircularProgress, Collapse } from "@material-ui/core";
import { v4 as uuidv4 } from "uuid";
import { CourseHeaderSettings } from "./pages/course-header-settings";
import { usePageVersions } from "./use-page-versions";
import { usePages } from "./page-list/use-pages";
import { EditorProvider } from "../../BriteEditor/editor-provider";
import { useDebounce } from "../../shared/use-debounce";
import { useKeyboard } from "../../Router/use-keyboard";
import { flex, px } from "../../shared/shared-styles";
import { useQueryClient } from "react-query";
import { useStore } from "../../store-provider/use-store";
import { CollectGuideType } from "../collect-guide-type";
import { countEvaluationErrors } from "../../BriteEditor/use-brite-editor";
import { useFeatureFlagPayload } from "posthog-js/react";
import { BriteLoader } from "../../shared/components/brite-loader";
import { EditorDetailsProvider } from "../../BriteEditor/provider/editor-detail-provider";
import { useRequests } from "../../react-query/use-resource";
import { guide } from "../../react-query/resources/guide";
import { usePosthogRegister } from "../../shared/usePosthogRegister";
import { Subscribers } from "../../shared/components/Subscribers";

const header = css`
  height: 100px;
  min-height: 100px;
  padding: 0 32px;
`;

const courseNameStyle = css`
  border-radius: 8px;
  border 1px solid transparent;
  outline: none;
  padding: 8px;
  ${textTheme.h2}
  ${textTheme.ellipsis}
  :focus,
  :hover {
    border: 1px solid ${colors.gray[400]};
  }
  :disabled {
    border: 1px solid transparent;
  }
`;

export const isUndoCode = (captured) =>
  captured === "meta+KeyZ" || captured === "ctrl+KeyZ";
export const isRedoCode = (captured) =>
  captured === "meta+shift+KeyZ" || captured === "ctrl+shift+KeyZ";

export const CourseViewer = () => {
  const { setStore } = useStore();

  const collaborationFF = useFeatureFlagPayload("editor-collaboration")?.value;

  const containerRef = useRef();
  const editorRef = useRef();
  const queryClient = useQueryClient();

  useEffect(() => {
    // hack to hide the hubspot chat for this page
    if (
      document &&
      document.getElementById("hubspot-messages-iframe-container")
    ) {
      document
        .getElementById("hubspot-messages-iframe-container")
        .setAttribute("style", "display:none !important;");
    }

    return () => {
      if (
        document &&
        document.getElementById("hubspot-messages-iframe-container")
      ) {
        document
          .getElementById("hubspot-messages-iframe-container")
          .setAttribute("style", "width: 100px; height: 96px;");
      }
    };
  }, []);

  const handleKeydown = (captured) => {
    if (isUndoCode(captured) && version.canUndo) {
      version.undo();
    } else if (isRedoCode(captured) && version.canRedo) {
      version.redo();
    } else if (isUndoCode(captured) || isRedoCode(captured)) {
      showVersionChangeToast(isUndoCode(captured), isRedoCode(captured));
    }
  };

  useKeyboard({ keydown: handleKeydown });

  const [exitLoader, setExitLoader] = useState(false);
  const { courseId, businessId } = useParams();
  const { params, updateParams, removeParam } = useSearchParams();

  // Two states for pageId allows us to make sure changes to a page
  // are saved before the actual page is changed.
  const { pageId: paramsPageId } = params;
  const setPageId = (pageId) => {
    setEnrollmentPage("");
    updateParams({ pageId });
  };
  const [pageId, setSyncPageId] = useState(paramsPageId);
  // ***** ***** ***** ***** ***** ***** ***** ***** ***** ***** ***** *****

  const isVendor = Object.keys(params || {}).find(
    (key) => key.toLowerCase() === "vendor"
  );

  const courseLease = useLease({ type: "course", id: courseId });
  const { loading, deleteLease } = courseLease;

  const guideResource = useResource(guide);

  const {
    isLoading,
    data: course,
    refetch: refetchCourse,
  } = guideResource?.query;

  const guideRequests = useRequests(guideResource);

  const { data: theme, refetch: refetchTheme } = useQueryAPI({
    cacheKey: ["editor"],
    url: `v1/themes/${course.ThemeID}`,
    enabled: !!course.ThemeID,
    retry: 1,
  });

  useEffect(() => {
    setStore("lastGuideId", courseId);
  }, [courseId]);

  useEffect(() => {
    if (
      course?.BenefitsPackageID &&
      course?.BenefitsPackageID !== "00000000-0000-0000-0000-000000000000"
    ) {
      updateParams({ packageId: course?.BenefitsPackageID });
    } else {
      removeParam("packageId");
    }
  }, [course?.BenefitsPackageID]);

  usePosthogRegister(
    {
      courseID: courseId,
      courseName: course.Name,
      courseMode: "read-write",
      courseEditSessionId: uuidv4(),
      courseGeneratedById: course.GeneratedBy,
      courseThemeId: course.ThemeID, // TODO: if this gets changed while the editor is opening we need to register this
      courseIsPublished: course.IsPublished,
    },
    [course, isLoading]
  );

  const [courseName, setCourseName] = useStateSync(course?.Name, [
    course?.Name,
  ]);
  const [resetSeed, setResetSeed] = useState("");
  const [saveBeforeQuit, setSaveBeforeQuit] = useState(false);
  const [saveBeforePage, setSaveBeforePage] = useState("");
  const [enrollmentPage, setEnrollmentPage] = useState("");

  const pageQuery = useQueryAPI({
    cacheKey: ["editor"],
    url: `v2/course/${courseId}/pages/${pageId}`,
    enabled: !!pageId,
    retry: 1,
  });

  const { data: page } = pageQuery;

  const pageLease = useLease(
    { type: "page", id: pageId, resourceId: courseId },
    [pageId]
  );
  useEffect(() => {
    if (!pageLease?.hasLease && !pageLease?.loading && !!collaborationFF) {
      updateParams({ mode: "collaborating" });
    } else if (params.mode === undefined) {
      updateParams({ mode: "editing" });
    }
  }, [pageLease?.loading]);

  const pagesProps = usePages(pageQuery, setPageId);

  const version = usePageVersions(pageQuery, pagesProps.query);

  const showVersionChangeToast = useDebounce(
    (undoCode, redoCode) => {
      const message = undoCode
        ? "Undo is unavailable"
        : redoCode && "Redo is unavailable";
      toast.warning(message);
    },
    1000,
    { immediateFirst: true }
  );

  const [pageIdx] = useStateSync(
    () => pagesProps?.query?.data?.findIndex(({ ID }) => ID === pageId),
    [pageId, pagesProps?.query?.data?.length]
  );

  const {
    hasLease: hasPageLease,
    loading: loadingPageLease,
    deleteLease: deletePageLease,
  } = pageLease;

  const {
    savePage,
    saveStatus,
    refreshTheme,
    setSaveStatus,
    friendlySaveStatus,
  } = useAutoSave(
    pageQuery,
    version,
    hasPageLease,
    !!course?.ThemeID &&
      course?.ThemeID !== "00000000-0000-0000-0000-000000000000"
  );

  useEffect(() => {
    if (saveStatus !== "saving" && paramsPageId !== pageId) {
      setSyncPageId(paramsPageId);
    }
  }, [paramsPageId, saveStatus]);

  const refetchCourseAndTheme = async () => {
    await refetchCourse();
    await refetchTheme();
  };

  const saveCurrentPageChanges = async (pageId = null) => {
    if (window.unlayer && pageQuery.data?.Type === "regular") {
      window.unlayer.exportHtml(
        async (data) => {
          await savePage(
            { Content: data.design, HTMLContent: data.html },
            pageId
          );
        },
        (e) => console.log("e", e)
      );
    } else if (pageQuery?.data?.Type === "brite-editor") {
      const data = pageQuery?.cache?.data;
      await savePage(
        {
          Content: data?.Content,
          HTMLContent: data?.HTMLContent,
        },
        pageId
      );
      version.query.refetch();
      version.setCurrentIdx(null);
    }
  };

  const exitCourse = async (overrideChangeCheck = false) => {
    if (!overrideChangeCheck && version.canRedo) {
      setSaveBeforeQuit(true);
      return;
    }
    setExitLoader(true);
    saveCurrentPageChanges(pageId);
    await Promise.all([deleteLease(), deletePageLease()]);
    history.replace(`/${businessId}/courses`);
  };

  useEffect(() => {
    if (pageLease.hasExpired || courseLease.hasExpired) {
      exitCourse(true);
    }
  }, [pageLease.hasExpired, courseLease.hasExpired]);

  const saveCourseRecord = useCallback(
    async (updates) => {
      try {
        const putGuide = guide?.utils?.getPut(courseId, {
          ...guideResource?.query?.data,
          ...updates,
        });
        await guideRequests.put(putGuide);
      } catch (err) {
        toast.error(`Error saving guide record...`);
      }
    },
    [course]
  );

  const handlePageIdChange = (value, skipSave = false) => {
    if (version.canRedo) {
      setSaveBeforePage(value);
      return;
    } else {
      if (!skipSave) {
        saveCurrentPageChanges(value);
      }
      setPageId(value);
    }
  };

  const isEditable =
    (!page?.IsLocked || isVendor) &&
    !pageLease?.loading &&
    (collaborationFF || hasPageLease);

  const refetchAll = async (reset = false) => {
    await queryClient.refetchQueries({ queryKey: ["editor"], type: "active" });
    if (reset) {
      setResetSeed(new Date().toISOString());
    }
  };

  const updateSmartFieldEvaluations = (value) => {
    const currentEvaluationPage = pagesProps?.query?.data?.find(
      ({ ID }) => ID === page?.ID
    );
    const currentEvaluationErrors = countEvaluationErrors(
      currentEvaluationPage?.RenderMetadata?.EvaluatedMergeTags
    );
    if (currentEvaluationErrors !== value) {
      pagesProps?.query?.refetch();
    }
  };

  const pageType = enrollmentPage || page?.Type || "brite-editor";

  return (
    <EditorDetailsProvider>
      <Layer
        onClickOutside={() => exitCourse()}
        onEsc={() => exitCourse()}
        modal={true}
        full
      >
        <CollectGuideType course={course} refetchCourse={refetchCourse} />
        <Div
          css={cx(
            css`
              ${flex("space-between")}
              border-bottom: 1px solid ${colors.gray[200]};
            `,
            header
          )}
        >
          <Div
            css={css`
              ${flex("space-between")}
            `}
          >
            <Div
              css={css`
                flex-grow: 1;
              `}
            >
              {exitLoader ? (
                <CircularProgress />
              ) : (
                <Button styles="icon mr" onClick={() => exitCourse()}>
                  <ArrowLeft />
                </Button>
              )}
            </Div>
            <input
              className={courseNameStyle}
              value={courseName}
              onChange={(e) => setCourseName(e.target.value)}
              onBlur={() => saveCourseRecord({ Name: courseName })}
              disabled={!courseLease?.hasLease}
            />
          </Div>

          <Div
            css={css`
            ${flex("space-between grow")}
            margin-left: ${px.md};
            min-width: 0;
            }
          `}
          >
            {!loading && !courseLease?.loading ? (
              <CourseHeaderSettings
                page={page}
                pageIdx={pageIdx}
                savePage={savePage}
                course={course}
                saveCourse={saveCourseRecord}
                refetch={refetchCourseAndTheme}
                theme={theme}
                saveStatus={saveStatus}
                courseLease={courseLease}
                pageLease={pageLease}
              />
            ) : (
              <div
                className={css`
                  flex-grow: 1;
                  ${flex("center")}
                `}
              >
                <BriteLoader overlay={false} size={40} weight={12} />
              </div>
            )}
          </Div>

          <Div
            css={css`
              ${flex("right grow")}
              max-width: 250px;
            `}
          >
            {(pageType === "brite-editor" || pageType === "regular") && (
              <Div
                css={css`
                  ${flex("right")}
                  margin: 0 ${px.md};
                `}
              >
                <Div
                  css={css`
                    ${flex("left")}
                  `}
                >
                  {collaborationFF ? (
                    <Subscribers resourceType="guide" resourceId={courseId} />
                  ) : null}
                  <Button
                    styles="icon mh-xs"
                    css={`
                      margin-left: 16px;
                    `}
                    onClick={() => version.undo()}
                    disabled={
                      !version.canUndo ||
                      version.query.isLoading ||
                      !pageLease.hasLease
                    }
                    hoverLabel="Undo"
                  >
                    <ArrowUUpLeft />
                  </Button>
                  <Button
                    styles="icon mh-xs"
                    onClick={() => version.redo()}
                    disabled={!version.canRedo || !pageLease.hasLease}
                    hoverLabel="Redo"
                  >
                    <ArrowUUpRight />
                  </Button>
                </Div>
                <Div
                  css={css`
                    ${flex("aic")} margin: 0;
                    padding: 0;
                  `}
                >
                  <Text
                    styles="label right ellipsis bold"
                    className={css`
                      width: 70px;
                    `}
                  >
                    {friendlySaveStatus}
                  </Text>
                  <Div
                    className={css`
                      ${flex("jcc")} width: 48px;
                    `}
                  >
                    {saveStatus === "saving" && <ArrowsClockwise />}
                    {saveStatus === "saved" && (
                      <Button
                        styles="icon"
                        onClick={() => saveCurrentPageChanges()}
                        hoverLabel="Save"
                      >
                        <CloudCheck />
                      </Button>
                    )}
                    {saveStatus === "initial" && <CloudSun />}
                    {saveStatus === "" && (
                      <Button
                        styles="icon"
                        onClick={() => saveCurrentPageChanges()}
                        hoverLabel="Save from here"
                      >
                        <CloudSlash />
                      </Button>
                    )}
                    {saveStatus === "error" && (
                      <Button
                        styles="icon"
                        onClick={() => saveCurrentPageChanges()}
                        hoverLabel="Try saving again"
                      >
                        <CloudSlash color={colors.red[100]} />
                      </Button>
                    )}
                  </Div>
                </Div>
              </Div>
            )}
          </Div>
        </Div>

        <Collapse in={params?.mode === "collaborating"}>
          <div
            className={css`
              ${flex("center")}
              width: 100%;
              min-height: 40px;
              background-color: ${colors.purple};
            `}
          >
            <div
              className={css`
                ${flex("center")}
              `}
            >
              <Text color="white">You are now in Comment mode.</Text>
              {pageLease?.hasLease ? (
                <Text
                  css={`
                    text-decoration: underline;
                    color: white;
                    margin-left: 8px;
                    cursor: pointer;
                  `}
                  onClick={() => updateParams({ mode: "editing" })}
                >
                  Close
                </Text>
              ) : null}
            </div>
          </div>
        </Collapse>

        <Div
          css={css`
            display: flex;
            min-height: calc(100% - 140px);
            max-height: calc(
              100% - ${params?.mode === "collaborating" ? "140px" : "100px"}
            );
            transition: max-height 0.4s ease;
          `}
          ref={containerRef}
        >
          <PageList
            pagesProps={pagesProps}
            pageId={paramsPageId}
            setPageId={handlePageIdChange}
            isVendor={isVendor}
            courseLease={courseLease}
            course={course}
            saveCourse={saveCourseRecord}
            setEnrollmentPage={setEnrollmentPage}
            enrollmentPage={enrollmentPage}
          />

          <Div
            className={css`
              position: relative;
              width: 100%;
              height: 100%;
              overflow-y: auto;
            `}
          >
            {!hasPageLease && !loadingPageLease && (
              <Div
                className={css`
                  position: absolute;
                  bottom: 32px;
                  right: 32px;
                  z-index: 1000000;
                  max-width: 50vw;
                `}
              >
                <Div
                  css={css`
                    ${flex("left")}
                    overflow: hidden;
                    background-color: ${colors.purple}DD;
                    padding: 8px;
                    border-radius: 16px;
                    svg {
                      min-width: 32px;
                      margin-right: 8px;
                    }
                  `}
                >
                  <WarningCircle color="white" />
                  <Text
                    css={`
                      color: white;
                    `}
                    ellipsis
                  >
                    {pageLease?.reason}
                  </Text>
                </Div>
              </Div>
            )}

            {pageType === "regular" ? (
              isEditable ? (
                <Editor
                  ref={editorRef}
                  page={page}
                  theme={theme}
                  pageLease={pageLease}
                  setSaveStatus={setSaveStatus}
                  refreshTheme={refreshTheme}
                />
              ) : !loadingPageLease ? (
                <LockedPage
                  page={page}
                  hideVendorMessage={hasPageLease}
                  course={course}
                />
              ) : null
            ) : pageType === "brite-editor" ? (
              <Div
                css={css`
                  position: relative;
                  height: 100%;
                `}
              >
                <EditorProvider
                  key={resetSeed}
                  query={pageQuery}
                  course={course}
                  saveCourseRecord={saveCourseRecord}
                  savePage={savePage}
                  versionId={version.versionId}
                  pagesQuery={pagesProps?.query}
                  refetchAll={refetchAll}
                  isEditable={isEditable}
                  updateSmartFieldEvaluations={updateSmartFieldEvaluations}
                  pageLease={pageLease}
                />
              </Div>
            ) : pageType === "enroll" ? (
              <EnrollmentFeedback
                pagesProps={pagesProps}
                page={page}
                savePage={savePage}
                course={course}
                updateCourse={saveCourseRecord}
                pageLease={pageLease}
                saveCourse={saveCourseRecord}
              />
            ) : page?.Type === "decision" ? (
              <DecisionTool
                page={page}
                savePage={savePage}
                course={course}
                updateCourse={saveCourseRecord}
                pageLease={pageLease}
              />
            ) : page?.Type === "question" ? (
              <MultipleChoice
                page={page}
                savePage={savePage}
                pageLease={pageLease}
              />
            ) : null}
          </Div>
        </Div>

        <ConfirmationModal
          display={saveBeforeQuit}
          title="You have unsaved changes! Would you like to save before leaving?"
          onConfirm={async () => {
            await saveCurrentPageChanges();
            exitCourse(true);
          }}
          onClose={() => exitCourse(true)}
          onExit={() => setSaveBeforeQuit(false)}
          closeText="Discard"
          confirmText="Save Changes"
        />

        <ConfirmationModal
          display={saveBeforePage}
          title="You have unsaved changes! Would you like to save before changing pages?"
          onConfirm={async () => {
            await saveCurrentPageChanges();
            setPageId(saveBeforePage);
            setSaveBeforePage("");
          }}
          onClose={() => {
            setSaveBeforePage("");
            setPageId(saveBeforePage);
          }}
          onExit={() => setSaveBeforePage("")}
          closeText="Discard"
          confirmText="Save Changes"
        />
      </Layer>
    </EditorDetailsProvider>
  );
};
