import React, { useMemo, useState } from 'react';
import { Layer } from "grommet"
import { css } from 'emotion';
import { Button, MenuItem, Typography, Select as SelectMUI, TextField, CircularProgress } from '@material-ui/core';
import { useStore } from '../store-provider/use-store';
import { Autocomplete } from '@material-ui/lab';
import { CustomAxios } from '../redux/axios/axios';
import { toast } from 'react-toastify';

const emptyState = { Name: 'Select Company', ID: '' };

export const AssignCompany = ({ display, onClose, member }) => {

  const { data: { companies, acls } } = useStore();
  const [loading, setLoading] = useState(false);
  const [business, setBusiness] = useState(emptyState);
  const [roleId, setRoleId] = useState('admin');

  const resourceIds = Object.keys(acls);

  const accessibleCompanies = useMemo(() => {
    const list = companies.filter(({ ID, ParentID }) => {
      const id = ID in resourceIds ? ID : ParentID;
      if (id in acls) {
        return acls[id].Acls.includes('write:business_access');
      }
      return false;
    });
    return list;
  }, [resourceIds.length]);

  const assignAccess = async () => {
    if (accessibleCompanies.length) {
      try {
        setLoading(true);
        CustomAxios.setHeader('BusinessID', business.ID);
        await CustomAxios.post(`v1/user/${member.UserID}/access/${business.ID}/role/${roleId}`);
        setBusiness(emptyState);
        toast.success('Successfully added access!');
        setLoading(false);
        onClose(true);
      } catch (err) {
        console.warn(err);
        toast.error('Failed to add access.');
      } finally {
        setLoading(false);
      }
    } else {
      toast.error('Member already has access!');
    }
  }

  if (!display) {
    return null;
  }

  return (
    <Layer
      className={css`width: 400px;`}
      onClickOutside={() => onClose(false)}
      onEsc={() => onClose(false)}
      modal={true}
    >
      <div className={css`display: flex; flex-direction: column; justify-content: center; margin: 32px;`}>
        <Typography style={{ fontWeight: 'bold', fontSize: '18px' }}>Add Company Access</Typography>
        <br />
        <Autocomplete
          disableClearable
          id="company_select"
          noOptionsText={"Not Found."}
          options={accessibleCompanies}
          getOptionLabel={(option) => option.Name}
          onChange={(_, value) => setBusiness(value)}
          value={business}
          renderInput={(params) => (<TextField {...params} variant="outlined" />)}
        />
        <br />
        <SelectMUI
          value={roleId}
          onChange={(e) => setRoleId(e.target.value)}
          variant="outlined"
        >
          <MenuItem value="admin">Admin</MenuItem>
          <MenuItem value="editor">Can Edit</MenuItem>
          <MenuItem value="viewer">View Only</MenuItem>
        </SelectMUI>

        <div className={css`margin-top: 32px; width: 100%; display: flex; justify-content: right;`}>
          <Button
            color="primary"
            onClick={() => onClose(false)}
          >
            Cancel
          </Button>
          <Button
            style={{ margin: '0 16px', width: 'initial' }}
            variant="contained"
            color="primary"
            onClick={assignAccess}
            disabled={!business.ID || !roleId || loading}
          >
            {loading ? <CircularProgress /> : 'Submit'}
          </Button>
        </div>
      </div>

    </Layer>
  )
}