import { CaretDown, CaretUp } from "phosphor-react";
import { useRequests, useResource } from "../../react-query";
import { knockSubscriptions } from "../../react-query/resources/knock-subscriptions";
import { Button, Modal, Text, Toggle } from "../../shared/components";
import { Box } from "../../shared/components/box";
import { colors } from "../../shared/styles";
import { Collapse } from "@material-ui/core";
import { useStore } from "../../store-provider/use-store";
import { toast } from "react-toastify";
import { knockUtils } from "../useKnock";
import { useStateSync } from "../../shared/use-state-sync";

export const NotificationSettingsModal = ({ knock, ...modalProps }) => {
  const {
    data: { user },
  } = useStore();

  const subscriptionsResource = useResource(knockSubscriptions, {
    enabled: modalProps?.display,
    transform: (data) => data?.Data,
  });

  const subscriptionsRequests = useRequests(subscriptionsResource);

  const [manageSubscriptions, setManageSubscriptions] = useStateSync(false, [
    modalProps?.display,
  ]);

  const unsubscribe = async (sub) => {
    const unsubscribeDelete = knockSubscriptions.utils.getUnsubscribeDelete(
      user?.ID,
      sub
    );
    try {
      await subscriptionsRequests.delete(unsubscribeDelete);
      toast.success("Unsubscribed");
    } catch (err) {
      toast.error("Failed to unsubscribed");
    }
  };

  const { state } = knock?.reducer;
  const preferences = Object.entries(state?.preferences?.categories || {});

  const hasEmailPreference = preferences?.some(
    ([_, value]) => value?.channel_types?.email
  );

  return (
    <Modal {...modalProps}>
      <Modal.Paper>
        <Modal.Header
          title="Notification Settings"
          onClose={modalProps.onClose}
        />
        <Modal.Body>
          <Box
            flex="space-between start"
            css={`
              margin: 16px 0;
            `}
          >
            <Box>
              <Text label>Email</Text>
              <Text>
                Receive a daily email with all unread notifications grouped.
              </Text>
            </Box>
            <Toggle
              checked={hasEmailPreference}
              onChange={() =>
                knockUtils.toggleAllCategoriesByChannelType(
                  knock,
                  "email",
                  !hasEmailPreference
                )
              }
            />
          </Box>
          <Box
            flex="space-between"
            onClick={() => setManageSubscriptions(!manageSubscriptions)}
            hover
            css={`
              cursor: pointer;
              padding: 8px;
              margin: 0 -8px;
              border-radius: 8px;
            `}
          >
            <Text h4>
              Subscribed To {subscriptionsResource?.data?.length} Guides
            </Text>
            <Box flex="right">
              <Text
                label
                css={`
                  margin-right: 8px;
                `}
              >
                Manage
              </Text>
              {manageSubscriptions ? (
                <CaretUp size={24} />
              ) : (
                <CaretDown size={24} />
              )}
            </Box>
          </Box>
          <Collapse in={manageSubscriptions}>
            <Text
              css={`
                padding: 8px 0;
              `}
            >
              You will receive updates for any edits or comments made on guides
              you are subscribed to.
            </Text>
            <Box
              css={`
                max-height: 25vh;
                overflow-y: auto;
              `}
              scrollbar
            >
              {subscriptionsResource?.data?.map((sub) => (
                <Box
                  key={sub?.ID}
                  flex="space-between"
                  css={`
                    padding: 16px;
                    border-bottom: 1px solid ${colors.gray[300]};
                  `}
                >
                  <Text label>{sub?.ResourceName}</Text>
                  <Button styles="naked" onClick={() => unsubscribe(sub)}>
                    Unsubscribe
                  </Button>
                </Box>
              ))}
            </Box>
          </Collapse>
        </Modal.Body>
      </Modal.Paper>
    </Modal>
  );
};
