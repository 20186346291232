import { Profiler } from "react";
import { useStore } from "../../store-provider/use-store";

// profilerProps = { id, onRender }
export const DevProfiler = ({
  children,
  threshold = 0,
  logProfile = true,
  ...profilerProps
}) => {
  const {
    data: { profiler },
  } = useStore();

  const onRenderProfiler = (...props) => {
    const [profilerId, mode, actualTime] = props;
    if (logProfile && actualTime > threshold) {
      console.log(profilerId, mode, actualTime);
    }
    if (profilerProps?.onRender) {
      profilerProps?.onRender(...props);
    }
  };

  if (profiler) {
    return (
      <Profiler {...profilerProps} onRender={onRenderProfiler}>
        {children}
      </Profiler>
    );
  } else {
    return children;
  }
};
