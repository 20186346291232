import React, { useEffect, useState } from "react";
import { css } from "emotion";
import CircularProgress from "@material-ui/core/CircularProgress";
import { CustomAxios } from "../redux/axios/axios";
import { Radio, TextField, Select, MenuItem } from "@material-ui/core";
import { toast } from "react-toastify";
import { CompanyLanguage } from "./company-language";
import { useFeatureFlagPayload } from "posthog-js/react";

const CustomLabel = ({
  setSettings,
  propertyToUpdate,
  settings,
  updateSettings,
}) => {
  const [open, setOpen] = useState(false);

  useEffect(() => {
    if (settings[propertyToUpdate]) {
      setOpen(true);
    }
  }, [settings]);

  if (!open) {
    return (
      <div
        onClick={() => {
          setOpen(!open);
        }}
        className={css`
          font-family: "Roboto";
          font-style: normal;
          font-weight: 700;
          font-size: 14px;
          line-height: 24px;
          /* identical to box height, or 171% */
          cursor: pointer;
          letter-spacing: 0.15px;
          margin-top: 4px;
          color: #6d44fb;
        `}
      >
        Customize Label
      </div>
    );
  }

  return (
    <div>
      <div
        className={css`
          font-family: "Roboto";
          font-style: normal;
          font-weight: 700;
          font-size: 14px;
          line-height: 24px;
          /* identical to box height, or 171% */

          letter-spacing: 0.15px;
          margin-top: 8px;
          color: #25282d;
        `}
      >
        Custom label
      </div>

      <TextField
        variant="outlined"
        style={{ width: "300px" }}
        value={settings[propertyToUpdate]}
        onChange={(e) => {
          const newVal = e.target.value;
          setSettings((old) => {
            let copy = { ...old };

            copy[propertyToUpdate] = newVal;
            updateSettings(copy);
            return copy;
          });
        }}
      />

      <div
        onClick={() => {
          setSettings((old) => {
            let copy = { ...old };
            copy[propertyToUpdate] = null;
            updateSettings(copy);
            return copy;
          });

          setOpen(false);
        }}
        className={css`
          font-family: "Roboto";
          font-style: normal;
          font-weight: 400;
          font-size: 14px;
          line-height: 24px;
          /* identical to box height, or 171% */
          cursor: pointer;
          letter-spacing: 0.15px;

          color: #66737f;
        `}
      >
        Remove custom label
      </div>
    </div>
  );
};

export const BenefitsSettings = (props) => {
  const costTiersFF = useFeatureFlagPayload("tier-structure");
  const [loading, setLoading] = useState(false);
  const [settings, setSettings] = useState({
    id: props.ID,
    premium_intervals_per_year: 12,
    custom_label_employee: null,
    custom_label_employee_spouse: null,
    custom_label_employee_children: null,
    custom_label_family: null,
  });
  const [saving, setSaving] = useState(false);

  useEffect(() => {
    const fetchBenefitSettings = async () => {
      try {
        setLoading(true);

        let response = await CustomAxios.get(
          `/public/v1/bp/display_settings?id=eq.${props.ID}`,
          { headers: { "Accept-Profile": "brite_public" } }
        );

        if (response && response.data && response.data.length > 0) {
          setSettings(response.data[0]);
        } else {
          await CustomAxios.put(
            `/v1/bp/display_settings?id=eq.${props.ID}`,
            {
              id: props.ID,
              premium_intervals_per_year: 12,
              custom_label_employee: null,
              custom_label_employee_spouse: null,
              custom_label_employee_children: null,
              custom_label_family: null,
            },
            {
              headers: { "Content-Profile": "brite_public" },
            }
          );

          let res = await CustomAxios.get(
            `/public/v1/bp/display_settings?id=eq.${props.ID}`,
            {
              headers: { "Accept-Profile": "brite_public" },
            }
          );
          if (res && res.data && res.data.length > 0) {
            setSettings(res.data[0]);
          }
        }

        setLoading(false);
      } catch (e) {
        setLoading(false);
      }
    };
    fetchBenefitSettings();
  }, [props.ID]);

  const updateSettings = async (copySettings) => {
    try {
      setSaving(true);
      await CustomAxios.put(
        `/v1/bp/display_settings?id=eq.${props.ID}`,
        copySettings,
        {
          headers: { "Content-Profile": "brite_public" },
        }
      );
      window?.ChurnZero?.push([
        "trackEvent",
        "Business Details",
        "This page was clicked on and any amount of information was input",
        1,
      ]);
      setSaving(false);
    } catch (e) {
      setSaving(false);
      toast.error("Error saving");
    }
  };

  if (loading) {
    return (
      <div
        className={css`
          margin: 0 auto;
        `}
      >
        <CircularProgress />
      </div>
    );
  }

  return (
    <div
      className={css`
        margin-top: 32px;
        max-width: 800px;
      `}
    >
      <hr
        className={css`
          border: 0;
          height: 1px;
          background: #d1dae3;

          margin-bottom: 40px;
          margin-top: 40px;
        `}
      />
      <div
        className={css`
          display: flex;
          gap: 56px;
        `}
      >
        <div
          className={css`
            font-family: "Roboto";
            font-style: normal;
            font-weight: 700;
            font-size: 18px;
            line-height: 24px;
            /* identical to box height, or 133% */

            letter-spacing: 0.15px;

            color: #25282d;
          `}
        >
          Premiums and Contributions
        </div>
        <div>
          <div
            className={css`
              font-family: "Roboto";
              font-style: normal;
              font-weight: 400;
              font-size: 16px;
              line-height: 24px;
              /* identical to box height, or 150% */

              letter-spacing: 0.15px;

              color: #66737f;
            `}
          >
            How would you like your employees premiums displayed?
          </div>
          <div
            className={css`
              cursor: pointer;
              display: flex;
              align-items: center;
              gap: 8px;
              margin-top: 14px;
            `}
            onClick={() => {
              setSettings((old) => {
                let copy = { ...old };
                copy.premium_intervals_per_year = 12;
                updateSettings(copy);
                return copy;
              });
            }}
          >
            <Radio
              checked={settings.premium_intervals_per_year === 12}
              color="primary"
              style={{ paddingLeft: "0" }}
            />
            <div
              className={css`
                font-family: "Roboto";
                font-style: normal;
                font-weight: 400;
                font-size: 16px;
                line-height: 24px;

                letter-spacing: 0.15px;

                color: #25282d;
              `}
            >
              Monthly
            </div>
          </div>
          <div
            className={css`
              cursor: pointer;
              display: flex;
              align-items: center;
              gap: 8px;
            `}
            onClick={() => {
              setSettings((old) => {
                let copy = { ...old };
                copy.premium_intervals_per_year = 24;
                updateSettings(copy);
                return copy;
              });
            }}
          >
            <Radio
              checked={settings.premium_intervals_per_year !== 12}
              color="primary"
              style={{ paddingLeft: "0" }}
            />
            <div
              className={css`
                font-family: "Roboto";
                font-style: normal;
                font-weight: 400;
                font-size: 16px;
                line-height: 24px;

                letter-spacing: 0.15px;

                color: #25282d;
              `}
            >
              Impact to paycheck
            </div>
          </div>
          <Select
            style={{ width: "300px" }}
            disabled={settings.premium_intervals_per_year === 12}
            labelId="paycheck-impact"
            id="paycheck-impact-id"
            value={settings.premium_intervals_per_year}
            variant="outlined"
            onChange={(e) => {
              const newVal = e.target.value;
              setSettings((old) => {
                let copy = { ...old };
                copy.premium_intervals_per_year = newVal;
                updateSettings(copy);
                return copy;
              });
            }}
          >
            <MenuItem value={24}>Semi-Monthly</MenuItem>
            <MenuItem value={26}>Bi-Weekly</MenuItem>
            <MenuItem value={52}>Weekly</MenuItem>
          </Select>
        </div>
      </div>

      {costTiersFF?.value ? (
        <CompanyLanguage businessId={props?.ID} />
      ) : (
        <div
          className={css`
            display: flex;
            gap: 56px;
            padding-top: 56px;
            margin-top: 56px;
            border-top: 1px solid #d1dae3;
          `}
        >
          <div
            className={css`
              font-family: "Roboto";
              font-style: normal;
              font-weight: 700;
              font-size: 18px;
              line-height: 24px;
              /* identical to box height, or 133% */

              letter-spacing: 0.15px;

              color: #25282d;
              width: 385px;
            `}
          >
            Customize Labels
          </div>
          <div>
            <div
              className={css`
                font-family: "Roboto";
                font-style: normal;
                font-weight: 400;
                font-size: 16px;
                line-height: 24px;
                /* identical to box height, or 150% */

                letter-spacing: 0.15px;

                color: #66737f;
              `}
            >
              You can change the labels for certain fields on your benefits.
              These changes will be reflected on all company guides
            </div>
            <div
              className={css`
                font-family: "Roboto";
                font-style: normal;
                font-weight: 400;
                font-size: 16px;
                line-height: 175%;
                /* identical to box height, or 28px */

                letter-spacing: 0.15px;

                color: #25282d;
                margin-top: 24px;
              `}
            >
              Employee Only
            </div>
            <CustomLabel
              setSettings={setSettings}
              settings={settings}
              propertyToUpdate={"custom_label_employee"}
              updateSettings={updateSettings}
            />
            <div
              className={css`
                font-family: "Roboto";
                font-style: normal;
                font-weight: 400;
                font-size: 16px;
                line-height: 175%;
                /* identical to box height, or 28px */

                letter-spacing: 0.15px;

                color: #25282d;
                margin-top: 24px;
              `}
            >
              Employee + Spouse
            </div>
            <CustomLabel
              setSettings={setSettings}
              settings={settings}
              propertyToUpdate={"custom_label_employee_spouse"}
              updateSettings={updateSettings}
            />

            <div
              className={css`
                font-family: "Roboto";
                font-style: normal;
                font-weight: 400;
                font-size: 16px;
                line-height: 175%;
                /* identical to box height, or 28px */

                letter-spacing: 0.15px;

                color: #25282d;
                margin-top: 24px;
              `}
            >
              Employee + Child(ren)
            </div>
            <CustomLabel
              setSettings={setSettings}
              settings={settings}
              propertyToUpdate={"custom_label_employee_children"}
              updateSettings={updateSettings}
            />

            <div
              className={css`
                font-family: "Roboto";
                font-style: normal;
                font-weight: 400;
                font-size: 16px;
                line-height: 175%;
                /* identical to box height, or 28px */

                letter-spacing: 0.15px;

                color: #25282d;
                margin-top: 24px;
              `}
            >
              Family
            </div>
            <CustomLabel
              setSettings={setSettings}
              settings={settings}
              propertyToUpdate={"custom_label_family"}
              updateSettings={updateSettings}
            />
          </div>
        </div>
      )}
    </div>
  );
};
