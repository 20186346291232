import React, { useState } from "react";
import { css } from "emotion";
import { Layer } from "grommet";
import {
  Browser,
  Chats,
  Eye,
  FirstAidKit,
  GearSix,
  Palette,
  WarningCircle,
} from "phosphor-react";
import { useEffect } from "react";
import { CourseSettings } from "../../../Courses/CourseSettings";
import { needsAttention, useQueryAPI, useResource } from "../../../react-query";
import { Button, Div, Text } from "../../../shared/components";
import { animation, container, flex } from "../../../shared/shared-styles";
import { colors } from "../../../shared/styles";
import { useSearchParams } from "../../../shared/use-search-params";
import { DesignStylesModalV2 } from "../modals/design-styles-modal";
import { SavePageTemplate } from "../modals/save-page-template";
import { SyncBenefitPackage } from "./sync-benefit-package";
import { Handout } from "../../../Messaging/Handout";
import HandoutIcon from "../../../Messaging/handout-icon-editor.png";
import { useStore } from "../../../store-provider/use-store";
import { Dot } from "../../../shared/components/dot";
import { useFeatureFlagPayload } from "posthog-js/react";

export const CourseHeaderSettings = ({
  course,
  page,
  savePage,
  saveCourse,
  refetch,
  theme,
  courseLease,
  pageLease,
}) => {
  const {
    data: { isBriteUser, devMode },
  } = useStore();

  const { params, updateParams, removeParam } = useSearchParams();

  const [showHandout, setShowHandout] = useState(false);

  const collaborationFF = useFeatureFlagPayload("editor-collaboration")?.value;

  const needsAttentionResource = useResource(needsAttention, {
    enabled: false,
    search: {
      business_id: `eq.${course?.BusinessID}`,
      resource_id: `eq.${course?.ID}`,
    },
  });
  const comments = needsAttentionResource?.data || [];

  const validComments = comments?.filter(({ resolved }) => !resolved);

  const { data: benefitPackage } = useQueryAPI({
    url: `v1/benefitspackage/${course?.BenefitsPackageID}`,
    enabled: !!course?.BenefitsPackageID,
  });

  useEffect(() => {
    removeParam("courseModal");
  }, []);

  const closeDesignStyles = async () => {
    removeParam("courseModal");
    await refetch();
  };

  const routeToCourse = () => {
    const pathParam = course.VanityURL || course.ID;
    const evDomain = window.location.origin?.includes("admin-frontend-staging")
      ? "employee-view-staging-tqtafkpv4q-uw.a.run.app"
      : "britehr.app";

    const url = !devMode
      ? `https://${evDomain}/${pathParam}/${page?.ID}?lt=preview`
      : `http://localhost:3001/${pathParam}/${page?.ID}}?lt=preview`;
    window.open(url, "_blank");
  };

  const hasBenefitPackage =
    !course?.BenefitsPackageID ||
    course?.BenefitsPackageID !== "00000000-0000-0000-0000-000000000000";

  return (
    <Div
      css={css`
        position: absolute;
        top: 0;
        left: 0;
        right: 0;
        height: 100px;
        ${flex("center")}
        pointer-events: none;
        .course-header-settings {
          pointer-events: all;
        }
      `}
    >
      <Div
        css={css`
          min-width: 375px;
          background: transparent;
          pointer-events: none;
        `}
      />
      <Div
        className="course-header-settings"
        css={css`
          ${flex("center grow")}
          padding: 8px;
          overflow-y: visible;
          overflow-x: auto;
        `}
      >
        {!courseLease?.hasLease ? (
          <Div
            css={css`
              ${flex("right")}
              ${animation("fadeIn", ".1s ease")}
                z-index: 10000;
              :hover {
                min-width: max-content;
                position: absolute;
              }
              overflow: hidden;
              background-color: ${colors.purple}DD;
              padding: 8px;
              border-radius: 16px;
              svg {
                min-width: 32px;
                margin-right: 8px;
              }
            `}
          >
            <WarningCircle color="white" />
            <Text
              css={`
                color: white;
              `}
              ellipsis
            >
              {courseLease?.reason}
            </Text>
          </Div>
        ) : null}
        {courseLease?.hasLease || isBriteUser ? (
          <>
            <Button
              styles="icon mh-xs"
              onClick={() => {
                updateParams({ courseModal: "settings" });
              }}
              hoverLabel="Settings"
            >
              <GearSix />
            </Button>

            {!theme?.ID ? (
              <Button
                styles="icon mh-xs"
                onClick={() => updateParams({ courseModal: "design-styles" })}
                hoverLabel="Design Styles"
              >
                <Palette />
              </Button>
            ) : (
              <Button
                hoverLabel="Design Styles"
                styles="naked mh-xs ai:c ml"
                onClick={() => updateParams({ courseModal: "design-styles" })}
                className={css`
                  min-width: 60px;
                  padding: 8px 0;
                  padding-right: 16px;
                  margin: 0 8px;
                  border-radius: 16px;
                  background-color: ${colors.gray[100]};
                  :hover {
                    background-color: ${colors.gray[200]};
                  }
                  svg {
                    min-width: 32px;
                  }
                `}
              >
                <Text
                  styles="ml ellipsis"
                  className={css`
                    ${flex("left")}
                    max-width: 250px;
                    svg {
                      min-width: 32px;
                      margin: 0;
                      margin-right: 8px;
                    }
                  `}
                >
                  <Palette />
                  <Text as="span" label>
                    {theme?.Name}
                  </Text>
                </Text>
              </Button>
            )}
            {!hasBenefitPackage ? (
              <Button
                styles="icon mh-xs"
                onClick={() => updateParams({ courseModal: "benefit-package" })}
                hoverLabel="Benefit Package"
              >
                <FirstAidKit />
              </Button>
            ) : (
              <Button
                hoverLabel="Benefit Package"
                styles="naked mh-xs ai:c ml"
                onClick={() => updateParams({ courseModal: "benefit-package" })}
                className={css`
                  min-width: 60px;
                  padding: 8px 0;
                  padding-right: 16px;
                  margin: 0 8px;
                  border-radius: 16px;
                  background-color: ${colors.gray[100]};
                  :hover {
                    background-color: ${colors.gray[200]};
                  }
                `}
              >
                <Text
                  styles="ml ellipsis"
                  className={css`
                    ${flex("left")}
                    max-width: 250px;
                    svg {
                      min-width: 32px;
                      margin: 0;
                      margin-right: 8px;
                    }
                  `}
                >
                  <FirstAidKit />
                  <Text as="span" label>
                    {benefitPackage?.Name}
                  </Text>
                </Text>
              </Button>
            )}
            <Button
              styles="icon mh-xs"
              onClick={routeToCourse}
              hoverLabel="View Guide"
            >
              <Eye />
            </Button>
            <Button
              styles="icon mh-xs"
              onClick={() =>
                updateParams({ courseModal: "save-page-template" })
              }
              hoverLabel="Save Page Template"
            >
              <Browser />
            </Button>
            <Button
              styles="icon mh-xs"
              onClick={() => setShowHandout(true)}
              hoverLabel="Download Brite Handout"
            >
              <img src={HandoutIcon} width="32px" height="32px" />
            </Button>
          </>
        ) : null}
        {collaborationFF ? (
          <Dot
            display={!!validComments?.length}
            color={colors.purple}
            spacing={3}
          >
            <Button
              disabled={!pageLease?.hasLease}
              styles="icon"
              onClick={() => {
                const mode =
                  params?.mode === "collaborating"
                    ? "editing"
                    : "collaborating";
                updateParams({ mode });
              }}
              hoverLabel={
                <Text
                  css={`
                    color: white;
                    strong {
                      margin-left: 16px;
                    }
                  `}
                >
                  Comment Mode <strong>C</strong>
                </Text>
              }
            >
              <Chats
                color={
                  params.mode === "collaborating" ? colors.purple : colors.black
                }
              />
            </Button>
          </Dot>
        ) : null}

        <SavePageTemplate
          display={params?.courseModal === "save-page-template"}
          close={() => removeParam("courseModal")}
          page={page}
        />
        <DesignStylesModalV2
          display={params?.courseModal === "design-styles"}
          onClose={closeDesignStyles}
          saveCourse={saveCourse}
          refetch={refetch}
          course={course}
        />
        {params?.courseModal === "settings" && (
          <Layer
            full
            modal={true}
            onEsc={() => removeParam("courseModal")}
            onClickOutside={() => removeParam("courseModal")}
          >
            <CourseSettings
              course={course}
              updateCourseChanges={saveCourse}
              close={() => removeParam("courseModal")}
              page={page}
            />
          </Layer>
        )}
        {params?.courseModal === "benefit-package" && (
          <SyncBenefitPackage
            display={params?.courseModal === "benefit-package"}
            onClose={() => removeParam("courseModal")}
            course={course}
            saveCourse={saveCourse}
            savePage={savePage}
            page={page}
          />
        )}
        {showHandout && (
          <Handout
            setShowBriteGuideHandout={setShowHandout}
            guideURL={`https://britehr.app/${course.VanityURL}`}
            guideID={course.ID}
            selectedCourse={course}
          />
        )}
      </Div>
      <Div
        css={css`
          min-width: 264px;
          background: transparent;
          pointer-events: none;
        `}
      />
    </Div>
  );
};

const guideDropdownItem = `
  padding: 8px;
  ${container.hover}
  svg {
    margin-right: 16px;
  }
  .svg-placeholder {
    margin-right: 16px;
    width: 24px;
  }
  width: 300px;
`;
