// EMPLOYEE-VIEW
import { css } from "emotion";
import { Div, Text } from "../../shared/components";
import { flex } from "../../shared/shared-styles";
import { getDisplayValue, getFieldValue } from "./field-utils";

// This component is only used for presentational fields and non-editable types
export const FieldComponent = ({ product, field, comparisonView = false }) => {
  const value = getFieldValue(product, field);

  if (
    ((comparisonView && field?.State === "hide") || (!value && value !== 0)) &&
    field?.Type !== "label"
  ) {
    return <Text>{getDisplayValue(value, field)}</Text>;
  }

  const fieldTypeOverride =
    value.PercentOrDollar === "$" ? "dollar" : "percent";

  return (
    <Div
      css={css`
        flex-grow: 1;
        ${field.RecDisplayValue || field.DisplayValue
          ? "min-width: 50%;"
          : "width: 100%;"}
      `}
    >
      <Div
        css={css`
          ${flex("right wrap")}
        `}
      >
        {field.Pre && (
          <Text
            className={css`
              flex-grow: 1;
              text-align: right;
            `}
          >
            {field.Pre}
          </Text>
        )}
        <Div
          css={css`
            padding-left: 8px;
            ${flex(comparisonView ? "left" : "right")}
          `}
        >
          {field.Type === "dollar" ? (
            // ----> DOLLAR
            <Text styles="label bold">{getDisplayValue(value, field)}</Text>
          ) : field.Type === "percent" ||
            field?.Type === "percentOfEmployeeElectedAmount" ? (
            // ----> PERCENT
            <Text
              styles="label bold"
              css={`
                text-align: right;
              `}
            >
              {getDisplayValue(value, field)}
              {field?.Type === "percentOfEmployeeElectedAmount" ? (
                <Text
                  as="span"
                  css={`
                    font-weight: normal;
                    font-size: 0.9em;
                  `}
                >
                  {" "}
                  of employee elected amount
                </Text>
              ) : (
                ""
              )}
            </Text>
          ) : field.Type === "text-input" || field.Type === "number" ? (
            <Text
              styles="label bold"
              className={css`
                text-align: right;
              `}
            >
              {value}
            </Text>
          ) : field.Type === "multipleOfSalary" ? (
            // ----> DOLLAR
            <Text styles="label bold">
              {getDisplayValue(value, field)}X Salary
            </Text>
          ) : field.Type === "text-area" ? (
            <Text
              className={css`
                text-align: right;
                width: 100%;
                margin: 8px;
              `}
              label
              bold
            >
              {value}
            </Text>
          ) : field.Type === "visionFrequency" ? (
            <Text styles="label bold">{value.inputValue}</Text>
          ) : field.Type === "advancedInputField" ? (
            <Div
              css={css`
                ${flex("right")}
              `}
            >
              {value.ADOrCopay === "COPAY + %" && (
                <Text
                  styles="label bold"
                  className={css`
                    margin-right: 4px;
                    min-width: max-content;
                  `}
                >
                  {getDisplayValue(value?.SpecialCopay || "", field, "dollar")}{" "}
                  Copay plus
                </Text>
              )}
              {value.ADOrCopay != "Not Covered" &&
                value.ADOrCopay != "100% Covered" && (
                  <Text
                    styles="label bold"
                    className={css`
                      margin-right: 4px;
                      min-width: max-content;
                    `}
                  >
                    {getDisplayValue(value.Value, field, fieldTypeOverride)}
                  </Text>
                )}
              {value?.ADOrCopay === "COPAY" && value?.MaxValue !== null ? (
                <Text
                  styles="label bold"
                  className={css`
                    margin-right: 4px;
                    min-width: max-content;
                  `}
                >
                  - {getDisplayValue(value.MaxValue, field, fieldTypeOverride)}
                </Text>
              ) : null}
              <Text styles="label bold">
                {value.ADOrCopay === "COPAY"
                  ? value?.PercentOrDollar === "$"
                    ? value?.MaxValue !== null
                      ? "Copay Range"
                      : "Copay"
                    : "Coinsurance"
                  : value.ADOrCopay === "COPAY + %"
                  ? "AD"
                  : value.ADOrCopay === "AD"
                  ? "AD"
                  : value.ADOrCopay === "100% Covered"
                  ? "100% Covered"
                  : value.ADOrCopay === "Not Covered"
                  ? "Not Covered"
                  : ""}
              </Text>
            </Div>
          ) : field.Type === "surestInputField" ? (
            <Div
              css={css`
                ${flex("right")}
              `}
            >
              {value.lower > 0 && (
                <Text
                  styles="label bold"
                  className={css`
                    margin-right: 8px;
                  `}
                >
                  {getDisplayValue(value?.lower || "", field)} -
                </Text>
              )}
              <Text
                styles="label bold"
                className={css`
                  margin-right: 8px;
                `}
              >
                {getDisplayValue(value?.upper || "", field)}
              </Text>
              <Text styles="label bold">
                {value.upper > 0 ? "Copay Range" : "Copay"}
              </Text>
            </Div>
          ) : field.Type === "prescriptionDrugDeductible" ? (
            <Text label bold>
              {value?.Value === -1
                ? "Medical Deductible Applies"
                : value?.Value === -2
                ? "None"
                : value?.Value >= 0
                ? getDisplayValue(value?.Value || "", field, "dollar")
                : null}
            </Text>
          ) : null}
        </Div>
      </Div>
      {field.Post && (
        <Text
          className={css`
            text-align: right;
            flex-grow: 1;
          `}
        >
          {field.Post}
        </Text>
      )}
    </Div>
  );
};
