import React, { useEffect, useState } from "react";
import { Autocomplete } from "@material-ui/lab";
import { TextField } from "@material-ui/core";
import { useQueryAPI } from "../react-query";
import { Div } from "../shared/components";
import { css } from "emotion";
import { useStateSync } from "../shared/use-state-sync";
import { compareTwoStrings } from "./auto-paster-v2/paste-utils";

export const emptyValue = {
  ID: "00000000-0000-0000-0000-000000000000",
  Name: "Custom Carrier",
};

export const handleCarriers = (data) => {
  const filteredData = data.filter(({ Name }) => Name);
  let list = [emptyValue, ...filteredData];
  return list;
};

export const SelectCarrier = (props) => {
  const {
    emptyName = "Custom Carrier",
    carrierId,
    setCarrierId,
    fieldStyle: props_fieldStyle = {},
    selectProps,
    searchTerm = "",
    disabled = false,
  } = props;

  const fieldStyle = {
    style: {
      border: "none",
      ...props_fieldStyle,
      padding: 0,
      margin: 0,
    },
  };

  const { data: carriers = [] } = useQueryAPI({
    url: `/v1/carriers`,
    defaultValue: [emptyValue],
    select: handleCarriers,
    enabled: false,
  });

  const [search, setSearch] = useStateSync(searchTerm, [searchTerm]);
  const [open, setOpen] = useState(false);
  const [value, setValue] = useState(carriers[0]);

  useEffect(() => {
    if (searchTerm) {
      setOpen(true);
    }
  }, [searchTerm]);

  const handleChange = (value) => {
    if (value && value.ID) {
      setCarrierId(value.ID, value.LogoURL, value.Name);
    }
  };

  useEffect(() => {
    const carrier = carriers.find((option) => option.ID === carrierId);
    if (carrier) {
      setValue(carrier);
    } else {
      setValue(carriers[0]);
    }
    setOpen(false);
    setSearch("");
  }, [carrierId, carriers.length]);

  return (
    <Autocomplete
      disableClearable
      disabled={disabled}
      id="carrier_select"
      noOptionsText="Not Found."
      options={carriers}
      getOptionLabel={(option) => option.Name}
      onChange={(_, value) => handleChange(value)}
      value={value}
      style={{ width: "100%", padding: 0, margin: 0 }}
      inputValue={search}
      onInputChange={(e, value) => setSearch(value)}
      blurOnSelect={true}
      open={open}
      onOpen={() => setOpen(true)}
      onClose={() => setOpen(false)}
      filterOptions={(list, state) => {
        return list.filter((item) => {
          const option = state?.getOptionLabel(item)?.toLowerCase();
          const value = state?.inputValue?.toLowerCase();

          if (!value || option === "custom carrier") {
            return true;
          }
          const isValid =
            option?.includes(value) ||
            value?.includes(option) ||
            compareTwoStrings(option, value) >= 0.6;
          return isValid;
        });
      }}
      renderInput={(params) => (
        <Div
          css={css`
            position: relative;
            width: 100%;
            overflow: hidden;
            padding: 0;
            margin: 0;
            .MuiOutlinedInput-root {
              border-radius: 8px;
              height: 43px;
            }
          `}
        >
          <TextField
            disabled={disabled}
            {...params}
            inputProps={{
              ...params.inputProps,
              ...fieldStyle,
              placeholder: "Custom Carrier",
            }}
            style={{ border: "none" }}
            variant="outlined"
          />
        </Div>
      )}
      {...selectProps}
    />
  );
};
