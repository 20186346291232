import { getGenericQueryProps, getUrl } from ".";

const url = "/v1/bp/autofill_suggestion";

export const aiSuggestions = {
  url,
  key: "ai-suggestions",
  getResourceOptions: (resourceProps) => {
    const resourceUrl = getUrl(url, resourceProps);
    return {
      url: resourceUrl,
      queryProps: {
        initialData: [],
        ...getGenericQueryProps(resourceUrl, resourceProps, {
          headers: {
            "Accept-Profile": "brite",
          },
        }),
      },
    };
  },
  utils: {
    getPut: (id, body) => ({
      url: `/v1/bp/autofill_suggestion?id=eq.${id}`,
      body,
      config: {
        headers: {
          "Content-Profile": "brite",
        },
      },
      optimstic: (old) => old.map((item) => (item?.id === id ? body : item)),
    }),
  },
};
