import { css } from "emotion";
import {
  File,
  FileCsv,
  FileDoc,
  FileImage,
  FileJpg,
  FilePdf,
  FilePng,
  FileXls,
  Gif,
} from "phosphor-react";
import React from "react";

const iconMap = {
  "": File,
  [".csv"]: FileCsv,
  [".plain"]: FileDoc,
  [".pdf"]: FilePdf,
  [".sheet"]: FileXls,
  [".jpeg"]: FileJpg,
  [".png"]: FilePng,
  [".gif"]: Gif,
  [".svg+xml"]: FileImage,
  [".xlsx"]: FileXls,
};

export const ExtensionIconMapper = ({ fileType, size = 40 }) => {
  if (!fileType) {
    return null;
  }

  const extensionMatch = fileType.match(/\.[0-9a-z+]+$/i);
  const extension =
    typeof extensionMap === "string" ? extensionMatch : extensionMatch?.[0];
  const Icon = extension in iconMap ? iconMap[extension] : File;
  return (
    <Icon
      className={css`
        min-width: max-content;
      `}
      size={size}
    />
  );
};
