import React from "react";
import { css } from "emotion";
import { X } from "phosphor-react";
import { Button, Div, Modal, Text } from "../../../shared/components";
import { Input } from "../../../shared/components/input";
import { flex } from "../../../shared/shared-styles";
import { useStateSync } from "../../../shared/use-state-sync";

export const PagesModal = ({
  title,
  data = null,
  confirmText,
  display,
  onClose,
  onConfirm,
}) => {
  const [name, setName] = useStateSync(data || "", [data]);

  const handleSave = () => {
    onConfirm(name);
    setName("");
    onClose();
  };

  const handleClose = () => {
    setName("");
    onClose();
  };

  return (
    <Modal display={display} onClose={handleClose}>
      <Div
        className={css`
          width: 500px;
        `}
      >
        <Div
          css={css`
            ${flex("jcsb aic")} padding: 32px;
          `}
        >
          <Text styles="h3">{title}</Text>
          <Button styles="icon" onClick={handleClose}>
            <X />
          </Button>
        </Div>
        <Div
          css={css`
            padding: 0 32px;
          `}
        >
          <Text label>Page Name</Text>
          <Input value={name} onChange={(e) => setName(e.target.value)} />
        </Div>
        <Div
          css={css`
            ${flex("right")} padding: 32px;
          `}
        >
          <Button
            secondary
            onClick={handleClose}
            css={`
              margin-right: 16px;
            `}
          >
            Cancel
          </Button>
          <Button disabled={!name} onClick={handleSave}>
            {confirmText}
          </Button>
        </Div>
      </Div>
    </Modal>
  );
};
