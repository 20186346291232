import { createAction, handleActions } from "redux-actions";
import { takeEvery, all, call, put, select } from "redux-saga/effects";

import { CustomAxios } from "../axios/axios";
import { sortBy, groupBy, cloneDeep } from "lodash";
import { history } from "../../history";
import { toast } from "react-toastify";
import uuid from "uuid";

export const duplicateCourse = createAction("DUPLICATE_COURSE");

export const updateUnsavedChanges = createAction("UPDATE_UNSAVED_CHANGES");

export const updateUnsavedChangesOff = createAction(
  "UPDATE_UNSAVED_CHANGES_OFF"
);

export const assignCourseToCompany = createAction("ASSIGN_COURSE_TO_COMPANY");
export const assignCourseToCompanyV2 = createAction(
  "ASSIGN_COURSE_TO_COMPANY_V2"
);

export const getCourses = createAction("GET_COURSES");
export const getCoursesSuccess = createAction("GET_COURSES_SUCCESS");
export const getCoursesFailure = createAction("GET_COURSES_FAILURE");

export const setVanityUrl = createAction("SET_VANITY_URL");
export const setVanityUrlSuccess = createAction("SET_VANITY_URL_SUCCESS");
export const setVanityUrlFailure = createAction("SET_VANITY_URL_FAILURE");

export const deletePage = createAction("DELETE_PAGE");
export const deleteInsurancePlan = createAction("DELETE_INSURANCE_PLAN");
export const deleteCourse = createAction("DELETE_COURSE");
export const deleteCourseSuccess = createAction("DELETE_COURSE_SUCCESS");
export const deleteCourseFailure = createAction("DELETE_COURSE_FAILURE");

export const getCourse = createAction("GET_COURSE");
export const getCourseSuccess = createAction("GET_COURSE_SUCCESS");
export const getCourseFailure = createAction("GET_COURSE_FAILURE");

export const updateCoursePagesOrder = createAction("UDPATE_COURSE_PAGES_ORDER");
export const updateCoursePagesOrderSuccess = createAction(
  "UDPATE_COURSE_PAGES_ORDER_SUCCESS"
);

export const updateCourse = createAction("UPDATE_COURSE");
export const updateCourseSuccess = createAction("UPDATE_COURSE_SUCCESS");
export const updateCourseFailure = createAction("UPDATE_COURSE_FAILURE");

export const updateCourseChanges = createAction("UPDATE_COURSE_CHANGES");

export const copyCourseFn = async (courseId, updates = {}) => {
  const url = `/v1/course/${courseId}/copies`;
  const copyResp = await CustomAxios.post(url, {
    Name: updates.Name,
    ConvertToBriteEditor: updates.ConvertToBriteEditor,
    CourseFolderID: null,
  });
  const response = await CustomAxios.get(
    `/v1/course/${copyResp.data.CourseID}`
  );

  return response.data;
};

// will be removed when all companies are using benefits-packages
const assignCourseToCompanySaga = function* () {
  const handleAssignCourseToCompany = function* (action) {
    try {
      const response = yield call(
        CustomAxios.get,
        `/v1/course/${action.payload.courseId}`
      );
      let course = response.data;

      let copyCourse = {
        Type: course.Type || "openenrollment",
        Name: action.payload.courseName,
        IsPublished: false,
        BusinessID: action.payload.businessId,
        Views: 0,
        Pages: [],
      };

      for (let page of course.Pages) {
        let copyPage = {
          Name: page.Name,
          Type: page.Type,
          Order: page.Order,
          FrontendID: "_" + Math.random().toString(36).substr(2, 9),
          IsLocked: page.IsLocked || false,
          TemplateID: page.TemplateID || "",
        };
        if (page.Type === "regular" || page.Type === "brite-editor") {
          copyPage.Content = page.Content;
          if (page.HTMLContent) {
            copyPage.HTMLContent = page.HTMLContent;
          }
        } else if (page.Type === "decision") {
          copyPage.InsurancePlans = [];
          if (page.HTMLContent) {
            copyPage.HTMLContent = page.HTMLContent;
          }
        } else if (page.Type === "question") {
          copyPage.Question = {
            Text: page.Question.Text,
            Type: page.Question.Type,
            Explanation: page.Question.Explanation,
            Answers: [
              {
                Text: "",
                OrderNumber: 1,
                IsCorrect: false,
              },
              {
                Text: "",
                OrderNumber: 2,
                IsCorrect: false,
              },
              {
                Text: "",
                OrderNumber: 3,
                IsCorrect: false,
              },
              {
                Text: "",
                OrderNumber: 4,
                IsCorrect: false,
              },
            ],
          };
          if (page.Question.Answers) {
            let answerCopy = [];
            for (let answer of page.Question.Answers) {
              answerCopy.push({
                Text: answer.Text,
                OrderNumber: answer.OrderNumber,
                IsCorrect: answer.IsCorrect,
              });
            }
            copyPage.Question.Answers = answerCopy;
          }
        }
        copyCourse.Pages.push(copyPage);
      }
      CustomAxios.setHeader("BusinessID", action.payload.businessId);
      const createCourse = yield call(
        CustomAxios.post,
        `/v1/course`,
        copyCourse
      );
      const state = yield select();
      CustomAxios.setHeader("BusinessID", state.business.ID);

      toast.success(`Guide assigned - '${createCourse.data.Name}'`);
    } catch (error) {
      toast.error("Did not assign guide");
    }
  };

  yield takeEvery(
    assignCourseToCompany.toString(),
    handleAssignCourseToCompany
  );
};

const assignCourseToCompanyV2Saga = function* () {
  const handleAssignCourseToCompanyV2 = function* (action) {
    try {
      yield call(
        CustomAxios.post,
        `/v1/course/${action.payload.courseId}/copies/${action.payload.businessId}`,
        {
          Name: action.payload.courseName,
          CourseFolderID: null,
        }
      );

      toast.success(`Guide assigned - '${action.payload.courseName}'`);
    } catch (error) {
      toast.error("Did not assign guide");
    }
  };

  yield takeEvery(
    assignCourseToCompanyV2.toString(),
    handleAssignCourseToCompanyV2
  );
};

const deletePageSaga = function* () {
  const handleDeletePage = function* (action) {
    try {
      yield call(CustomAxios.delete, `/v1/pages/${action.payload}`);
    } catch (error) {
      toast.error("Did not delete page");
    }
  };

  yield takeEvery(deletePage.toString(), handleDeletePage);
};

const duplicateCourseSaga = function* () {
  const handleDuplicateCourse = function* (action) {
    try {
      const createCourse = yield call(copyCourseFn, action.payload);

      yield put(getCourses());

      toast.success(`Guide duplicated as '${createCourse.data.Name}'`);
    } catch (error) {
      toast.error("Did not duplicate guide");
    }
  };

  yield takeEvery(duplicateCourse.toString(), handleDuplicateCourse);
};

const deleteInsurancePlanSaga = function* () {
  const handleDeleteInsurancePlan = function* (action) {
    try {
      yield call(CustomAxios.delete, `/v1/plans/${action.payload}`);
    } catch (error) {
      toast.error("Did not delete plan");
    }
  };

  yield takeEvery(deleteInsurancePlan.toString(), handleDeleteInsurancePlan);
};

const deleteCourseSaga = function* () {
  const handleDeleteCourse = function* (action) {
    try {
      yield call(CustomAxios.delete, `/v1/course/${action.payload}`);

      yield put(getCourses());
    } catch (error) {
      toast.error("Did not delete guide");
      yield put(getCourses());
    }
  };

  yield takeEvery(deleteCourse.toString(), handleDeleteCourse);
};

const getCoursesSaga = function* () {
  const handleGetCourses = function* (action) {
    try {
      const response = yield call(CustomAxios.get, `/v1/course`);

      let courses = response.data;

      courses = courses.filter((course) => course.Type !== "survey");
      courses = courses.filter((course) => course.Type !== "vendor");

      courses = sortBy(courses, (o) => o.Name.toLowerCase());

      yield put(getCoursesSuccess({ courses }));
    } catch (error) {
      yield put(getCoursesFailure());
      toast.error("Courses failed to load");
    }
  };

  yield takeEvery(getCourses.toString(), handleGetCourses);
};

const planNetworkDefault = {
  show: false,
  Name: "",
  PlanType: "",
  ApplicableStates: [],
  ApplicableZipCodes: [],
  EmployeeContributionType: "%",
  HSACompatible: false,
  HSAContributionType: "HSA - No Employer Contribution",
  HideEmployerPremiumContributions: false,
  TotalMonthlyPremiums: {
    EmployeeOnly: 0,
    EmployeeSpouse: 0,
    EmployeeChildren: 0,
    Family: 0,
  },
  EmployeeContributions: {
    EmployeeOnly: 0,
    EmployeeSpouse: 0,
    EmployeeChildren: 0,
    Family: 0,
  },
  HSAContributions: {
    EmployeeOnly: 0,
    EmployeeSpouse: 0,
    EmployeeChildren: 0,
    Family: 0,
    EmployeeOnlyEmployerMonthlyMatch: 0,
    EmployeeSpouseEmployerMonthlyMatch: 0,
    EmployeeChildrenEmployerMonthlyMatch: 0,
    FamilyEmployerMonthlyMatch: 0,
    EmployeeOnlyEmployerMonthlyMatchMax: 0,
    EmployeeSpouseEmployerMonthlyMatchMax: 0,
    EmployeeChildrenEmployerMonthlyMatchMax: 0,
    FamilyEmployerMonthlyMatchMax: 0,
  },
};

export const groupMultiNetworkPlans = (grouped) => {
  let plans = [];
  for (const key of Object.keys(grouped)) {
    let groupedPlans = grouped[key];
    if (key === "") {
      for (let plan of groupedPlans) {
        plan.buyUpNetwork = planNetworkDefault;
        plan.narrowNetwork = planNetworkDefault;
        plan.grouped = true;
        plans.push(plan);
      }
    } else {
      let groupedPlans = grouped[key];
      let newPlan = {};

      for (let plan of groupedPlans) {
        if (plan.MultiNetworkCategory === "core") {
          newPlan = cloneDeep(plan);
        }
      }
      for (let plan of groupedPlans) {
        if (plan.MultiNetworkCategory === "buyup") {
          newPlan.buyUpNetwork = {
            show: true,
            ID: plan.ID,
            Name: plan.Name,
            PlanType: plan.PlanType,
            ApplicableStates: plan.ApplicableStates,
            ApplicableZipCodes: plan.ApplicableZipCodes,
            EmployeeContributionType: plan.EmployeeContributionType,
            HSACompatible: plan.HSACompatible,
            HSAContributionType: plan.HSAContributionType,
            HideEmployerPremiumContributions:
              plan.HideEmployerPremiumContributions,
            TotalMonthlyPremiums: {
              EmployeeOnly: plan.TotalMonthlyPremiums.EmployeeOnly,
              EmployeeSpouse: plan.TotalMonthlyPremiums.EmployeeSpouse,
              EmployeeChildren: plan.TotalMonthlyPremiums.EmployeeChildren,
              Family: plan.TotalMonthlyPremiums.Family,
            },
            EmployeeContributions: {
              EmployeeOnly: plan.EmployeeContributions.EmployeeOnly,
              EmployeeSpouse: plan.EmployeeContributions.EmployeeSpouse,
              EmployeeChildren: plan.EmployeeContributions.EmployeeChildren,
              Family: plan.EmployeeContributions.Family,
            },
            HSAContributions: {
              EmployeeOnly: plan.HSAContributions.EmployeeOnly,
              EmployeeSpouse: plan.HSAContributions.EmployeeSpouse,
              EmployeeChildren: plan.HSAContributions.EmployeeChildren,
              Family: plan.HSAContributions.Family,
              EmployeeOnlyEmployerMonthlyMatch:
                plan.HSAContributions.EmployeeOnlyEmployerMonthlyMatch,
              EmployeeSpouseEmployerMonthlyMatch:
                plan.HSAContributions.EmployeeSpouseEmployerMonthlyMatch,
              EmployeeChildrenEmployerMonthlyMatch:
                plan.HSAContributions.EmployeeChildrenEmployerMonthlyMatch,
              FamilyEmployerMonthlyMatch:
                plan.HSAContributions.FamilyEmployerMonthlyMatch,
              EmployeeOnlyEmployerMonthlyMatchMax:
                plan.HSAContributions.EmployeeOnlyEmployerMonthlyMatchMax,
              EmployeeSpouseEmployerMonthlyMatchMax:
                plan.HSAContributions.EmployeeSpouseEmployerMonthlyMatchMax,
              EmployeeChildrenEmployerMonthlyMatchMax:
                plan.HSAContributions.EmployeeChildrenEmployerMonthlyMatchMax,
              FamilyEmployerMonthlyMatchMax:
                plan.HSAContributions.FamilyEmployerMonthlyMatchMax,
            },
          };
        } else if (plan.MultiNetworkCategory === "narrow") {
          newPlan.narrowNetwork = {
            show: true,
            ID: plan.ID,
            Name: plan.Name,
            PlanType: plan.PlanType,
            ApplicableStates: plan.ApplicableStates,
            ApplicableZipCodes: plan.ApplicableZipCodes,
            EmployeeContributionType: plan.EmployeeContributionType,
            HSACompatible: plan.HSACompatible,
            HSAContributionType: plan.HSAContributionType,
            HideEmployerPremiumContributions:
              plan.HideEmployerPremiumContributions,
            TotalMonthlyPremiums: {
              EmployeeOnly: plan.TotalMonthlyPremiums.EmployeeOnly,
              EmployeeSpouse: plan.TotalMonthlyPremiums.EmployeeSpouse,
              EmployeeChildren: plan.TotalMonthlyPremiums.EmployeeChildren,
              Family: plan.TotalMonthlyPremiums.Family,
            },
            EmployeeContributions: {
              EmployeeOnly: plan.EmployeeContributions.EmployeeOnly,
              EmployeeSpouse: plan.EmployeeContributions.EmployeeSpouse,
              EmployeeChildren: plan.EmployeeContributions.EmployeeChildren,
              Family: plan.EmployeeContributions.Family,
            },
            HSAContributions: {
              EmployeeOnly: plan.HSAContributions.EmployeeOnly,
              EmployeeSpouse: plan.HSAContributions.EmployeeSpouse,
              EmployeeChildren: plan.HSAContributions.EmployeeChildren,
              Family: plan.HSAContributions.Family,
              EmployeeOnlyEmployerMonthlyMatch:
                plan.HSAContributions.EmployeeOnlyEmployerMonthlyMatch,
              EmployeeSpouseEmployerMonthlyMatch:
                plan.HSAContributions.EmployeeSpouseEmployerMonthlyMatch,
              EmployeeChildrenEmployerMonthlyMatch:
                plan.HSAContributions.EmployeeChildrenEmployerMonthlyMatch,
              FamilyEmployerMonthlyMatch:
                plan.HSAContributions.FamilyEmployerMonthlyMatch,
              EmployeeOnlyEmployerMonthlyMatchMax:
                plan.HSAContributions.EmployeeOnlyEmployerMonthlyMatchMax,
              EmployeeSpouseEmployerMonthlyMatchMax:
                plan.HSAContributions.EmployeeSpouseEmployerMonthlyMatchMax,
              EmployeeChildrenEmployerMonthlyMatchMax:
                plan.HSAContributions.EmployeeChildrenEmployerMonthlyMatchMax,
              FamilyEmployerMonthlyMatchMax:
                plan.HSAContributions.FamilyEmployerMonthlyMatchMax,
            },
          };
        }
      }

      if (!newPlan.buyUpNetwork) {
        newPlan.buyUpNetwork = planNetworkDefault;
      }

      if (!newPlan.narrowNetwork) {
        newPlan.narrowNetwork = planNetworkDefault;
      }
      newPlan.grouped = true;
      plans.push(newPlan);
    }
  }

  return plans;
};

export const shapeInsurancePlansFromBackend = (courseFromBackend) => {
  for (let page of courseFromBackend.Pages) {
    if (page.InsurancePlans) {
      for (let plan of page.InsurancePlans) {
        if (plan.InNetworkPlanDesign.EmbeddedDeductibleOOPM) {
          plan.InNetworkPlanDesign.EmbeddedDeductibleOOPM = "Yes";
        } else {
          plan.InNetworkPlanDesign.EmbeddedDeductibleOOPM = "No";
        }
        plan.InNetworkPlanDesign.Coinsurance =
          plan.InNetworkPlanDesign.Coinsurance * 100.0;

        if (plan.EffectiveDate === "0001-01-01T00:00:00Z") {
          plan.EffectiveDate = null;
        }
      }
    } else {
      page.InsurancePlans = [];
    }
    let grouped = groupBy(page.InsurancePlans, "MultiNetworkID");

    page.InsurancePlans = groupMultiNetworkPlans(grouped);
  }
};

const getCourseSaga = function* () {
  const handleGetCourse = function* (action) {
    try {
      const response = yield call(
        CustomAxios.get,
        `/v1/course/${action.payload}`
      );
      let course = response.data;
      if (course.Pages && course.Pages.length > 0) {
        course.Pages = sortBy(course.Pages, ["Order"]);
      } else {
        course.Pages = [
          {
            Type: "regular",
            Content: null,
            Name: "Welcome",
            Order: 1,
            FrontendID: "_" + Math.random().toString(36).substr(2, 9),
            InsurancePlans: [],
            Question: null,
            IsLocked: false,
            TemplateID: "00000000-0000-0000-0000-000000000000",
          },
        ];
      }

      shapeInsurancePlansFromBackend(course);

      yield put(getCourseSuccess({ course }));
    } catch (error) {
      yield put(getCourseFailure());
      toast.error("Course failed to load");
    }
  };

  yield takeEvery(getCourse.toString(), handleGetCourse);
};

const updateCoursePagesOrderSaga = function* () {
  const handleUpdateCoursePagesOrder = function* (action) {
    const reorder = (list, startIndex, endIndex) => {
      const result = Array.from(list);
      const [removed] = result.splice(startIndex, 1);
      result.splice(endIndex, 0, removed);

      return result;
    };

    try {
      const state = yield select();

      const pages = reorder(
        state.courses.course.Pages,
        action.payload.result.source.index,
        action.payload.result.destination.index
      );

      var course = { ...state.courses.course };
      course.Pages = pages;

      yield put(updateCoursePagesOrderSuccess({ course }));
    } catch (error) {
      const state = yield select();
      yield put(
        updateCoursePagesOrderSuccess({ course: state.courses.course })
      );
    }
  };

  yield takeEvery(
    updateCoursePagesOrder.toString(),
    handleUpdateCoursePagesOrder
  );
};

export const setUpNewPlan = (newplan, planType, plan) => {
  newplan.TotalMonthlyPremiums = {
    EmployeeOnly: plan[planType].TotalMonthlyPremiums.EmployeeOnly,
    EmployeeSpouse: plan[planType].TotalMonthlyPremiums.EmployeeSpouse,
    EmployeeChildren: plan[planType].TotalMonthlyPremiums.EmployeeChildren,
    Family: plan[planType].TotalMonthlyPremiums.Family,
  };
  newplan.EmployeeContributions = {
    EmployeeOnly: plan[planType].EmployeeContributions.EmployeeOnly,
    EmployeeSpouse: plan[planType].EmployeeContributions.EmployeeSpouse,
    EmployeeChildren: plan[planType].EmployeeContributions.EmployeeChildren,
    Family: plan[planType].EmployeeContributions.Family,
  };
  newplan.HSAContributions = {
    EmployeeOnly: plan[planType].HSAContributions.EmployeeOnly,
    EmployeeSpouse: plan[planType].HSAContributions.EmployeeSpouse,
    EmployeeChildren: plan[planType].HSAContributions.EmployeeChildren,
    Family: plan[planType].HSAContributions.Family,
    EmployeeOnlyEmployerMonthlyMatch:
      plan[planType].HSAContributions.EmployeeOnlyEmployerMonthlyMatch,
    EmployeeSpouseEmployerMonthlyMatch:
      plan[planType].HSAContributions.EmployeeSpouseEmployerMonthlyMatch,
    EmployeeChildrenEmployerMonthlyMatch:
      plan[planType].HSAContributions.EmployeeChildrenEmployerMonthlyMatch,
    FamilyEmployerMonthlyMatch:
      plan[planType].HSAContributions.FamilyEmployerMonthlyMatch,
    EmployeeOnlyEmployerMonthlyMatchMax:
      plan[planType].HSAContributions.EmployeeOnlyEmployerMonthlyMatchMax,
    EmployeeSpouseEmployerMonthlyMatchMax:
      plan[planType].HSAContributions.EmployeeSpouseEmployerMonthlyMatchMax,
    EmployeeChildrenEmployerMonthlyMatchMax:
      plan[planType].HSAContributions.EmployeeChildrenEmployerMonthlyMatchMax,
    FamilyEmployerMonthlyMatchMax:
      plan[planType].HSAContributions.FamilyEmployerMonthlyMatchMax,
  };

  newplan.Name = plan[planType].Name;
  if (plan[planType].ID) {
    newplan.ID = plan[planType].ID;
  } else {
    delete newplan.ID;
  }
  newplan.PlanType = plan.PlanType;
  newplan.ApplicableStates = plan.ApplicableStates;
  newplan.ApplicableZipCodes = plan.ApplicableZipCodes;
  newplan.EmployeeContributionType = plan[planType].EmployeeContributionType;
  newplan.HSACompatible = plan[planType].HSACompatible;
  newplan.HSAContributionType = plan[planType].HSAContributionType;
  newplan.HideEmployerPremiumContributions =
    plan[planType].HideEmployerPremiumContributions;
  delete newplan.buyUpNetwork;
  delete newplan.narrowNetwork;
  return newplan;
};

const updateCourseSaga = function* () {
  const handleUpdateCourse = function* (action) {
    try {
      let course = action.payload.course;
      let teleMedicineInfo = action.payload.teleMedicineInfo || {};
      let planNameForTelemedicineMapping =
        action.payload.planNameForTelemedicineMapping || "";
      let count = 1;
      for (let page of course.Pages) {
        page.Order = count;
        count += 1;

        if (page.InsurancePlans) {
          let plans = [];

          for (let plan of page.InsurancePlans) {
            let MultiNetworkID = "";
            if (plan.buyUpNetwork.show || plan.narrowNetwork.show) {
              plan.MultiNetworkCategory = "core";
              MultiNetworkID = uuid.v4();
              plan.MultiNetworkID = MultiNetworkID;
            } else {
              plan.MultiNetworkCategory = "default";
              plan.MultiNetworkID = MultiNetworkID;
            }

            if (plan.buyUpNetwork.show) {
              let newplan = cloneDeep(plan);
              setUpNewPlan(newplan, "buyUpNetwork", plan);
              newplan.MultiNetworkID = MultiNetworkID;
              newplan.MultiNetworkCategory = "buyup";
              plans.push(newplan);
            } else if (plan.buyUpNetwork.ID) {
              yield put(deleteInsurancePlan(plan.buyUpNetwork.ID));
            }

            if (plan.narrowNetwork.show) {
              let newplan = cloneDeep(plan);
              setUpNewPlan(newplan, "narrowNetwork", plan);
              newplan.MultiNetworkCategory = "narrow";
              newplan.MultiNetworkID = MultiNetworkID;
              plans.push(newplan);
            } else if (plan.narrowNetwork.ID) {
              yield put(deleteInsurancePlan(plan.narrowNetwork.ID));
            }
            delete plan.buyUpNetwork;
            delete plan.narrowNetwork;
            plans.push(plan);
          }

          for (let plan of plans) {
            if (plan.InNetworkPlanDesign.EmbeddedDeductibleOOPM === "No") {
              plan.InNetworkPlanDesign.EmbeddedDeductibleOOPM = false;
            } else {
              plan.InNetworkPlanDesign.EmbeddedDeductibleOOPM = true;
            }

            if (
              plan.InNetworkPlanDesign.Coinsurance &&
              plan.InNetworkPlanDesign.Coinsurance !== 0
            ) {
              plan.InNetworkPlanDesign.Coinsurance =
                plan.InNetworkPlanDesign.Coinsurance / 100.0;
            }
          }
          page.InsurancePlans = plans;
        }
      }

      const response = yield call(
        CustomAxios.put,
        `/v1/course/${action.payload.course.ID}`,
        course
      );

      const courseResp = yield call(
        CustomAxios.get,
        `/v1/course/${action.payload.course.ID}`
      );

      let updatedCourse = courseResp.data;

      updatedCourse.Pages = sortBy(updatedCourse.Pages, ["Order"]);

      shapeInsurancePlansFromBackend(updatedCourse);

      for (let page of updatedCourse.Pages) {
        if (page.InsurancePlans) {
          for (let plan of page.InsurancePlans) {
            if (plan.Name === planNameForTelemedicineMapping) {
              teleMedicineInfo.PlanID = plan.ID;
            }
          }
        }
      }

      if (
        teleMedicineInfo.PlanID &&
        (teleMedicineInfo.CarrierName ||
          (teleMedicineInfo.CarrierID &&
            teleMedicineInfo.CarrierID !==
              "00000000-0000-0000-0000-000000000000"))
      ) {
        if (teleMedicineInfo.ID) {
          try {
            yield call(CustomAxios.put, `/v1/telemedicine`, teleMedicineInfo);
          } catch (e) {
            console.log("did not update telemedicine");
          }
        } else {
          try {
            yield call(CustomAxios.post, `/v1/telemedicine`, teleMedicineInfo);
          } catch (e) {
            console.log("did not create telemedicine");
          }
        }
      }
      yield put(updateCourseSuccess({ course: updatedCourse }));
      toast.success("Course saved!");
      if (action.payload.shouldExit) {
        yield call(
          history.push,
          `/${action.payload.course.BusinessID}/courses`
        );
      }
    } catch (error) {
      console.log("error", error);
      yield put(
        updateCourseFailure({
          shouldExitOnError: action.payload.shouldExitOnError,
        })
      );
      toast.error("Guide did not save");
      if (action.payload.shouldExitOnError) {
        yield call(
          history.push,
          `/${action.payload.course.BusinessID}/courses`
        );
      }
    }
  };

  yield takeEvery(updateCourse.toString(), handleUpdateCourse);
};

const vanityURLSaga = function* () {
  const handleVanityURL = function* (action) {
    try {
      yield call(CustomAxios.put, `/v1/course/${action.payload.id}/vanityurl`, {
        URL: action.payload.url,
      });
      yield put(setVanityUrlSuccess());
    } catch (error) {
      yield put(setVanityUrlFailure());
    }
  };

  yield takeEvery(setVanityUrl.toString(), handleVanityURL);
};

const coursesDefaultState = {
  hasUnsavedCourseChanges: false,
  selectedPageIndex: 0,
  courses: [],
  loading: false,
  saving: false,
  courseLoaded: false,
  vanityUrlLoading: false,
  vanityUrlTaken: false,
  course: {
    Name: "Untitled Guide",
    Views: 0,
    Type: "openenrollment",
    IsPublished: false,
    Pages: [
      {
        Type: "regular",
        Content: null,
        Name: "Welcome",
        Order: 1,
        FrontendID: "_" + Math.random().toString(36).substr(2, 9),
        InsurancePlans: [],
        Question: null,
        IsLocked: false,
        TemplateID: "00000000-0000-0000-0000-000000000000",
      },
    ],
  },
};

export const coursesReducer = handleActions(
  {
    [setVanityUrl]: (state, action) => {
      return Object.assign({}, state, {
        vanityUrlLoading: true,
        vanityUrlTaken: false,
      });
    },

    [setVanityUrlSuccess]: (state, action) => {
      return Object.assign({}, state, {
        vanityUrlLoading: false,
        vanityUrlTaken: false,
      });
    },

    [setVanityUrlFailure]: (state, action) => {
      return Object.assign({}, state, {
        vanityUrlLoading: false,
        vanityUrlTaken: true,
      });
    },
    [getCoursesSuccess]: (state, action) => {
      return Object.assign({}, state, {
        courses: action.payload.courses,
        loading: false,
      });
    },
    [getCourses]: (state, action) => {
      return Object.assign({}, state, { loading: true, courseLoaded: false });
    },
    [duplicateCourse]: (state, action) => {
      return Object.assign({}, state, { loading: true });
    },
    [deleteCourse]: (state, action) => {
      return Object.assign({}, state, { loading: true });
    },
    [getCoursesFailure]: () => coursesDefaultState,
    [getCourseSuccess]: (state, action) => {
      return Object.assign({}, state, {
        course: action.payload.course,
        loading: false,
        courseLoaded: true,
      });
    },
    [updateCourseChanges]: (state, action) => {
      return Object.assign({}, state, {
        course: action.payload,
        hasUnsavedCourseChanges: true,
      });
    },
    [updateUnsavedChangesOff]: (state, action) => {
      return Object.assign({}, state, { hasUnsavedCourseChanges: false });
    },
    [updateUnsavedChanges]: (state, action) => {
      return Object.assign({}, state, { hasUnsavedCourseChanges: true });
    },

    [getCourse]: (state, action) => {
      return Object.assign({}, state, {
        hasUnsavedCourseChanges: false,
        loading: true,
        courseLoaded: false,
      });
    },
    [updateCourse]: (state, action) => {
      return Object.assign({}, state, { saving: true });
    },
    [updateCourseSuccess]: (state, action) => {
      return Object.assign({}, state, {
        saving: false,
        course: action.payload.course,
        hasUnsavedCourseChanges: false,
      });
    },
    [updateCourseFailure]: (state, action) => {
      return Object.assign({}, state, {
        saving: false,
        hasUnsavedCourseChanges: !action.payload.shouldExitOnError,
      });
    },
    [updateCoursePagesOrderSuccess]: (state, action) => {
      return Object.assign({}, state, {
        course: action.payload.course,
        hasUnsavedCourseChanges: true,
      });
    },
    [getCourseFailure]: (state, action) =>
      Object.assign({}, state, {
        course: {
          Name: "Untitled Guide",
          Views: 0,
          Type: "openenrollment",
          IsPublished: false,
          Pages: [
            {
              Type: "regular",
              Content: null,
              Name: "Welcome",
              Order: 1,
              FrontendID: "_" + Math.random().toString(36).substr(2, 9),
              InsurancePlans: [],
              Question: null,
              IsLocked: false,
              TemplateID: "00000000-0000-0000-0000-000000000000",
            },
          ],
        },
        loading: false,
        courseLoaded: true,
      }),
  },
  coursesDefaultState
);

export const coursesSagas = function* () {
  yield all([
    getCoursesSaga(),
    getCourseSaga(),
    updateCoursePagesOrderSaga(),
    updateCourseSaga(),
    deletePageSaga(),
    deleteCourseSaga(),
    deleteInsurancePlanSaga(),
    duplicateCourseSaga(),
    vanityURLSaga(),
    assignCourseToCompanySaga(),
    assignCourseToCompanyV2Saga(),
  ]);
};
