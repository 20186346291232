import React, { Component, useState, useEffect } from "react";
import { css } from "emotion";
import { Button, Text, TextInput, Select } from "grommet";
import { withFormik } from "formik";
import { compose } from "lodash/fp";
import { connect } from "react-redux";
import CircularProgress from "@material-ui/core/CircularProgress";

import {
  updateCourseChanges,
  deleteInsurancePlan,
} from "../redux/actions/courses";
import { toast } from "react-toastify";

const newQuestion = {
  Text: "",
  Type: "multipleChoice",
  PageID: "",
  Explanation: "",
  Answers: [
    {
      Text: "",
      OrderNumber: 1,
      IsCorrect: false,
    },
    {
      Text: "",
      OrderNumber: 2,
      IsCorrect: false,
    },
    {
      Text: "",
      OrderNumber: 3,
      IsCorrect: false,
    },
    {
      Text: "",
      OrderNumber: 4,
      IsCorrect: false,
    },
  ],
};

const QuestionForm = (props) => {
  const {
    values,
    touched,
    errors,
    handleChange,
    handleBlur,
    handleSubmit,
    setFieldValue,
  } = props;

  const [selectedAnswer, setSelectedAnswer] = useState("");

  const [saving, setSaving] = useState(false);

  useEffect(() => {
    setSelectedAnswer("");
  }, [values.Text]);

  const checkAnswer = (answer) => {
    setSelectedAnswer(answer.Text);
  };

  const resetAnswer = () => {
    setSelectedAnswer("");
  };

  const anyCorrect = () => {
    for (let answer of values.Answers) {
      if (answer.IsCorrect) {
        return true;
      }
    }
    return false;
  };

  const submitFormWithLoading = (e) => {
    e.preventDefault();
    setSaving(true);
    handleSubmit();

    setTimeout(() => {
      setSaving(false);
    }, 1000);
  };

  if (props.loading) {
    return (
      <div
        className={css`
          display: flex;
          justify-content: center;
          margin-top: 80px;
        `}
      >
        <CircularProgress />
      </div>
    );
  }
  return (
    <div
      className={css`
        display: flex;
      `}
    >
      <form
        onSubmit={submitFormWithLoading}
        className={css`
          max-width: 350px;
          width: 100%;
          margin-left: 20px;
        `}
      >
        <div
          className={css`
            overflow: auto;
            height: 100%;
          `}
        >
          <div
            className={css`
              margin-bottom: 10px;
              margin-top: 20px;
            `}
          >
            <Text
              className={css`
                width: 160px;
              `}
              size="16px"
            >
              Question{" "}
            </Text>
          </div>
          <TextInput
            onBlur={handleBlur}
            onChange={handleChange}
            value={values.Text}
            placeholder="Enter a question"
            name="Text"
            className={css`
              height: 40px;
              font-weight: 400;
            `}
          />
          <div
            className={css`
              margin-bottom: 10px;
              margin-top: 10px;
            `}
          >
            <Text
              className={css`
                width: 160px;
              `}
              size="16px"
            >
              Answer 1{" "}
            </Text>
          </div>
          <TextInput
            onBlur={handleBlur}
            onChange={handleChange}
            value={values.Answers[0].Text}
            placeholder="Enter possible answer"
            name="Answers[0].Text"
            className={css`
              height: 40px;
              font-weight: 400;
            `}
          />
          <div
            className={css`
              margin-bottom: 10px;
              margin-top: 10px;
            `}
          >
            <Text
              className={css`
                width: 160px;
              `}
              size="16px"
            >
              Answer 2{" "}
            </Text>
          </div>
          <TextInput
            onBlur={handleBlur}
            onChange={handleChange}
            value={values.Answers[1].Text}
            placeholder="Enter possible answer"
            name="Answers[1].Text"
            className={css`
              height: 40px;
              font-weight: 400;
            `}
          />
          <div
            className={css`
              margin-bottom: 10px;
              margin-top: 10px;
            `}
          >
            <Text
              className={css`
                width: 160px;
              `}
              size="16px"
            >
              Answer 3{" "}
            </Text>
          </div>
          <TextInput
            onBlur={handleBlur}
            onChange={handleChange}
            value={values.Answers[2].Text}
            placeholder="Enter possible answer"
            name="Answers[2].Text"
            className={css`
              height: 40px;
              font-weight: 400;
            `}
          />
          <div
            className={css`
              margin-bottom: 10px;
              margin-top: 10px;
            `}
          >
            <Text
              className={css`
                width: 160px;
              `}
              size="16px"
            >
              Answer 4{" "}
            </Text>
          </div>
          <TextInput
            onBlur={handleBlur}
            onChange={handleChange}
            value={values.Answers[3].Text}
            placeholder="Enter possible answer"
            name="Answers[3].Text"
            className={css`
              height: 40px;
              font-weight: 400;
            `}
          />
          <div
            className={css`
              margin-bottom: 10px;
              margin-top: 10px;
            `}
          >
            <Text
              className={css`
                width: 160px;
              `}
              size="16px"
            >
              What is the correct answer?{" "}
            </Text>
          </div>{" "}
          <Select
            options={["None", "Answer 1", "Answer 2", "Answer 3", "Answer 4"]}
            value={
              values.Answers[0].IsCorrect
                ? "Answer 1"
                : values.Answers[1].IsCorrect
                ? "Answer 2"
                : values.Answers[2].IsCorrect
                ? "Answer 3"
                : values.Answers[3].IsCorrect
                ? "Answer 4"
                : "None"
            }
            onChange={({ option }) => {
              if (option === "None") {
                setFieldValue("Answers[0].IsCorrect", false);
                setFieldValue("Answers[1].IsCorrect", false);
                setFieldValue("Answers[2].IsCorrect", false);
                setFieldValue("Answers[3].IsCorrect", false);
              }
              if (option === "Answer 1") {
                setFieldValue("Answers[0].IsCorrect", true);
                setFieldValue("Answers[1].IsCorrect", false);
                setFieldValue("Answers[2].IsCorrect", false);
                setFieldValue("Answers[3].IsCorrect", false);
              }
              if (option === "Answer 2") {
                setFieldValue("Answers[0].IsCorrect", false);
                setFieldValue("Answers[1].IsCorrect", true);
                setFieldValue("Answers[2].IsCorrect", false);
                setFieldValue("Answers[3].IsCorrect", false);
              }
              if (option === "Answer 3") {
                setFieldValue("Answers[0].IsCorrect", false);
                setFieldValue("Answers[1].IsCorrect", false);
                setFieldValue("Answers[2].IsCorrect", true);
                setFieldValue("Answers[3].IsCorrect", false);
              }
              if (option === "Answer 4") {
                setFieldValue("Answers[0].IsCorrect", false);
                setFieldValue("Answers[1].IsCorrect", false);
                setFieldValue("Answers[2].IsCorrect", false);
                setFieldValue("Answers[3].IsCorrect", true);
              }
            }}
          />
          <div
            className={css`
              margin-bottom: 10px;
              margin-top: 10px;
            `}
          >
            <Text
              className={css`
                width: 160px;
              `}
              size="16px"
            >
              Answer Explanation{" "}
            </Text>
          </div>
          <TextInput
            onBlur={handleBlur}
            onChange={handleChange}
            value={values.Explanation}
            placeholder="Enter an explanation"
            name="Explanation"
            className={css`
              height: 40px;
              font-weight: 400;
            `}
          />
          <div
            className={css`
              display: flex;
              align-content: center;
              justify-content: center;
              padding: 20px;
            `}
          >
            <Button
              className={css`
                color: white;
              `}
              primary
              fill="horizontal"
              label={saving ? "Saving..." : "Save"}
              type="submit"
              disabled={saving}
            />
          </div>
        </div>
      </form>
      <div
        className={css`
          margin-left: 80px;
          width: 350px;
          margin-top: 20px;
          border-left: 1px solid #ededed;
          padding-left: 80px;
        `}
      >
        <div
          className={css`
            display: flex;
            justify-content: space-between;
          `}
        >
          <Text size="16px">Preview </Text>{" "}
          <div>
            {" "}
            <Button
              color="brand"
              plain
              label={"Reset"}
              onClick={() => {
                resetAnswer();
              }}
            />
          </div>
        </div>
        <div
          className={css`
            width: 100%;
            height: ${parseFloat(props.height) - 130}px;
            border: 1px solid #dedede;
            overflow: auto;
            border-radius: 5px;
            margin-top: 10px;
            padding: 10px;
            button {
              max-width: 260px;
            }
          `}
        >
          {!values.Text ? (
            "Fill out the form to the left to generate preview."
          ) : (
            <div
              className={css`
                text-align: center;
                padding: 30px;
                margin-bottom: 50px;
              `}
            >
              <h1
                className={css`
                  color: black;
                  font-size: 23px;
                  line-height: 32px;
                `}
              >
                {" "}
                {values.Text}{" "}
              </h1>

              {values.Answers.map((answer) => {
                if (!answer.Text) {
                  return null;
                }
                return (
                  <div>
                    <Button
                      color={"light-2"}
                      fill
                      primary
                      label={answer.Text}
                      className={css`
                        margin-top: 25px;
                        box-shadow: 0px 3px 5px -1px rgba(148, 148, 148, 0.75);
                        ${answer.Text === selectedAnswer
                          ? answer.IsCorrect || !anyCorrect()
                            ? "color: green; border-color: green;"
                            : "color: red; border-color: red;"
                          : ""}
                      `}
                      onClick={() => {
                        checkAnswer(answer);
                      }}
                    />{" "}
                  </div>
                );
              })}

              {selectedAnswer && values.Explanation && (
                <div
                  className={css`
                    border: 1px solid #dedede;
                    border-radius: 5px;
                    padding: 10px;
                    margin-top: 15px;
                  `}
                >
                  {values.Explanation}{" "}
                </div>
              )}
            </div>
          )}
        </div>
      </div>
    </div>
  );
};

const QuestionContainer = compose(
  connect(
    (state, ownProps) => ({
      course: state.courses.course,
      page: ownProps.page,
      height: ownProps.height,
    }),
    (dispatch, ownProps) => ({
      updateCourseChanges: (course) => {
        dispatch(updateCourseChanges(course));
      },
    })
  ),
  withFormik({
    mapPropsToValues: (props) => {
      let { question } = props;
      if (question && question.Answers && question.Answers.length === 0) {
        question.Answers = newQuestion.Answers;
        question.Type = newQuestion.Type;
      }
      return question;
    },

    // Custom sync validation
    validate: (values) => {
      const errors = {};

      return errors;
    },

    handleSubmit: (values, { props }) => {
      if (!values.Text) {
        toast.error("Question is required.");
      } else if (!values.Answers[0].Text) {
        toast.error("Answer 1 is required.");
      } else {
        if (!values.PageID) {
          delete values.PageID;
        }

        var course = { ...props.course };
        for (let page of course.Pages) {
          if (page.FrontendID === props.page.FrontendID) {
            page.Question = values;
          }
        }

        props.updateCourseChanges(course);
      }
    },

    displayName: "multipleChoiceForm",
    enableReinitialize: true,
  })
)(QuestionForm);

export class MultipleChoiceBuilder extends Component {
  constructor(props) {
    super(props);
    this.state = {};
  }

  componentWillReceiveProps(nextProps) {
    if (
      nextProps.selectedPage.FrontendID !== this.props.selectedPage.FrontendID
    ) {
      this.onLoad();
    }
  }

  onLoad = () => {
    console.log("load");
  };

  render() {
    return (
      <div
        id="builder"
        className={css`
          border: 1px solid #dedede;
          border-radius: 5px;
          margin-left: 20px;
          display: flex;
          flex-direction: column;
          height: ${this.props.viewHeight};
          flex: 1;
          overflow: auto;
        `}
      >
        <div
          className={css`
            flex: 1;
            padding: 20px;
          `}
        >
          <div
            className={css`
              display: flex;
              justify-content: space-between;
              align-items: center;
            `}
          >
            <div
              className={css`
                font-weight: 400;
                font-size: 26px;
                line-height: 28px;
                color: #797979;
              `}
            >
              Multiple Choice Question Builder
            </div>
          </div>
          <QuestionContainer
            page={this.props.selectedPage}
            question={
              this.props.selectedPage.Question
                ? this.props.selectedPage.Question
                : newQuestion
            }
            height={this.props.viewHeight}
          />
        </div>
      </div>
    );
  }
}

export const MultipleChoiceBuilderContainer = connect(
  (state, ownProps) => ({
    updateCourseChanges: ownProps.updateCourseChanges,
    course: ownProps.course,
    selectedPage: ownProps.selectedPage,
    viewHeight: ownProps.viewHeight,
  }),
  (dispatch) => {
    return {
      deleteInsurancePlan: (id) => {
        dispatch(deleteInsurancePlan(id));
      },
    };
  }
)(MultipleChoiceBuilder);
