import {
  ContentState,
  EditorState,
  RichUtils,
  convertToRaw,
  convertFromRaw,
  convertFromHTML,
  Modifier,
  getDefaultKeyBinding,
} from "draft-js";
import { isEqual } from "lodash";
import { useContext, useEffect, useMemo, useRef, useState } from "react";
import { EditorDetailsContext } from "../../provider/editor-detail-provider";
import { modifyContent } from "../../provider/utils";
import { useEditorResource } from "../../use-editor-resource";
import {
  decorators,
  replaceText,
  blockStyleFn,
  inlineStyles,
  getSelection,
  getHtmlContent,
  edgeDecorations,
  clearInlineStyles,
  iterateDecorations,
} from "./text-utils";

export const useTextEditor = (props) => {
  const { editor, element, location } = props;
  const { editorDetails, dispatch } = useContext(EditorDetailsContext);

  const ref = useRef(null);
  const [readOnly, setReadOnly] = useState(false);
  const [editorReady, setEditorReady] = useState(false);
  const {
    state: { versionId, inlineColorMap },
  } = editor;

  const { data: styles } = useEditorResource("styles");

  const syncState = () => {
    const keys = Object.keys(element?.rawState || {}).length;
    let state = {};
    if (!keys) {
      let content;
      const convertedHTML = convertFromHTML(element.content);
      if (convertedHTML?.contentBlocks) {
        content = ContentState.createFromBlockArray(
          convertedHTML.contentBlocks,
          convertedHTML.entityMap
        );
      } else {
        content =
          typeof element.content === "string"
            ? ContentState.createFromText(element.content)
            : EditorState.createEmpty();
      }
      state = EditorState.createWithContent(content, decorators);
    } else {
      const rawState = convertFromRaw(element.rawState);
      state = EditorState.createWithContent(rawState, decorators);
    }
    return state;
  };

  const [editorState, _setEditorState] = useState({});

  const setEditorState = (state, dangerouslySetState = false) => {
    if (dangerouslySetState) {
      return _setEditorState(state);
    }

    const changeType = state?.getLastChangeType();
    state = edgeDecorations(state);

    const selection = state?.getSelection();

    const lastRange = editorDetails?.selection || [];
    if (
      lastRange?.length &&
      (changeType === "backspace-character" ||
        changeType === "delete-character")
    ) {
      const [blockKey, start, end] = lastRange;
      const currentKey = selection?.anchorKey;
      const offset = selection?.anchorOffset;
      if (blockKey === currentKey && offset < end && offset >= start) {
        if (offset < end && changeType === "backspace-character") {
          const selection = getSelection([blockKey, start], [blockKey, offset]);
          const { nextState } = replaceText(state, selection, "");
          state = nextState;
        } else if (offset >= start && changeType === "delete-character") {
          const selection = getSelection(
            [blockKey, start],
            [blockKey, end - 1]
          );
          const { nextState } = replaceText(state, selection, "");
          state = nextState;
        }
        const forcedSelection = getSelection([blockKey, start]);
        state = EditorState?.forceSelection(state, forcedSelection);
      }
    }

    const range = iterateDecorations(
      (start, end, selection) =>
        selection?.anchorOffset >= start && selection?.anchorOffset <= end,
      state
    );

    const [blockKey, start, end] = range;
    let payload = [];

    if (
      blockKey === selection?.anchorKey &&
      (start === selection?.anchorOffset || end === selection?.anchorOffset)
    ) {
      payload = range;
    }

    dispatch({
      payload,
      type: "SET",
      key: "selection",
    });

    _setEditorState(state);
  };

  useEffect(() => {
    if (props?.isSelected && editorDetails?.deleteTag?.length) {
      const [key, start, end] = editorDetails?.deleteTag;
      const selection = getSelection([key, start], [key, end]);
      let { nextState } = replaceText(editorState, selection, "");
      const nextSelection = getSelection([key, start]);
      nextState = EditorState?.forceSelection(nextState, nextSelection);
      _setEditorState(nextState);
      dispatch({
        type: "SET",
        key: "deleteTag",
        payload: [],
      });
    }
  }, [editorDetails?.deleteTag?.toString()]);

  useEffect(() => {
    setEditorReady(true);
  }, []);

  useEffect(() => {
    _setEditorState(syncState());
  }, [versionId, editor?.state?.updateType]);

  const saveContent = (state = null) => {
    if (state) {
      const currentContent = state.getCurrentContent();
      const content = getHtmlContent(currentContent, inlineColorMap);
      const rawState = convertToRaw(currentContent);
      if (!isEqual(content, element.content)) {
        modifyContent.merge(editor, location, { content, rawState });
      }
    }
  };

  const onChange = (state) => {
    if (props?.isSelected) {
      setEditorState(state);
      saveContent(state);
    }
  };

  const toggleInline = (type) => {
    setReadOnly(false);
    ref.current.focus();
    let state = editorState;
    if (type.startsWith("color-")) {
      state = clearInlineStyles(state, (style) => style.startsWith("color-"));
    }
    state = RichUtils.toggleInlineStyle(state, type);
    onChange(state);
  };

  const toggleBlock = (type) => {
    const state = RichUtils.toggleBlockType(editorState, type);
    onChange(state);
  };

  const cursorStyles = useMemo(() => {
    if (editorReady) {
      const currentSelection = editorState.getSelection();
      const blockKey = currentSelection.getStartKey();
      const blockType = editorState
        .getCurrentContent()
        .getBlockForKey(blockKey)
        .getType();
      return [...editorState?.getCurrentInlineStyle?.()?.toArray(), blockType];
    }
    return [];
  }, [editorReady, editorState]);

  const onTab = (event) => {
    const selection = editorState.getSelection();

    const blockType = editorState
      .getCurrentContent()
      .getBlockForKey(selection.getStartKey())
      .getType();

    if (blockType === "unordered-list-item") {
      const nextState = RichUtils.onTab(event, editorState, 6);
      onChange(nextState);
    } else {
      const newContentState = Modifier.replaceText(
        editorState.getCurrentContent(),
        editorState.getSelection(),
        "\t"
      );
      const nextState = EditorState.push(
        editorState,
        newContentState,
        "insert-characters"
      );
      onChange(nextState);
    }

    event.preventDefault();
  };

  const handleKeyCommand = (type) => {
    if (type === "bold") {
      toggleInline("BOLD");
    } else if (type === "underline") {
      toggleInline("UNDERLINE");
    } else if (type === "italic") {
      toggleInline("ITALIC");
    }
  };

  const keyBindingFn = (event) => {
    if (event.keyCode === 9) {
      onTab(event);
      return "";
    } else {
      return getDefaultKeyBinding(event);
    }
  };

  useEffect(() => {
    if (props.isSelected) {
      setReadOnly(false);
    } else {
      dispatch({ type: "SET", payload: undefined, key: "entityKey" });
    }
  }, [props.isSelected]);

  useEffect(() => {
    if (!readOnly) {
      ref?.current?.focus();
    }
  }, [readOnly]);

  const handlers = {
    setState: onChange,
    setReadOnly,
    toggleInline,
    toggleBlock,
    cursorStyles,
    focusEditor: ref?.current?.focus,
  };

  const customStyleMap = {
    ...inlineStyles,
    ...inlineColorMap,
  };

  return {
    ref,
    styles,
    editorReady,
    handlers,
    editorProps: {
      readOnly,
      onChange,
      editorState,
      blockStyleFn,
      keyBindingFn,
      customStyleMap,
      handleKeyCommand,
      onBlur: () => setReadOnly(true),
    },
  };
};
