import { surestPlanType } from "../Content/decision-tool-constants";
import { getSectionConfig } from "./configs/config-utils";
import { summarySectionConfigs } from "./configs/product-summary.config";

export const advancedInputFields = [
  "Details.InNetworkProfessionalServices.PrimaryCarePhysician",
  "Details.InNetworkProfessionalServices.Specialist",
  "Details.InNetworkProfessionalServices.PreventativeCare",
  "Details.InNetworkProfessionalServices.TeleMedicine",
  "Details.InNetworkProfessionalServices.InPatientHospital",
  "Details.InNetworkProfessionalServices.OutPatientSurgery",
  "Details.InNetworkProfessionalServices.MentalHealthOutpatient",
  "Details.InNetworkProfessionalServices.UrgentCare",
  "Details.InNetworkProfessionalServices.EmergencyRoom",
  "Details.InNetworkProfessionalServices.PhysicalTherapy",
  "Details.InNetworkProfessionalServices.VirtualPrimary",
  "Details.InNetworkProfessionalServices.VirtualSpecialty",
];

export const surestInputFields = [
  "Details.SurestProfessionalServices.PrimaryCare",
  "Details.SurestProfessionalServices.Specialist",
  "Details.SurestProfessionalServices.TeleMedicine",
  "Details.SurestProfessionalServices.InpatientHospital",
  "Details.SurestProfessionalServices.OutpatientSurgery",
  "Details.SurestProfessionalServices.MentalHealth",
  "Details.SurestProfessionalServices.Baby",
  "Details.SurestProfessionalServices.UrgentCare",
  "Details.SurestProfessionalServices.EmergencyRoom",
  "Details.SurestProfessionalServices.PhysicalTherapy",
  "Details.SurestProfessionalServices.Virtual",
  "Details.SurestProfessionalServices.VirtualSpecialty",
];

const planSummaryDependencies = {
  insurance_plan: {
    "Label.InNetworkPlanDetails": [
      "Details.InNetworkPlanDesign.SingleDeductible",
      "Details.InNetworkPlanDesign.Deductible",
      "Details.InNetworkPlanDesign.SingleOOPM",
      "Details.InNetworkPlanDesign.OOPM",
      "Details.InNetworkPlanDesign.Coinsurance",
    ],
    "Label.PrescriptionDrugs": [
      "Details.InNetworkPrescriptionDrugs.MedicalDeductibleApplies",
      "Details.InNetworkPrescriptionDrugs.Tier1",
      "Details.InNetworkPrescriptionDrugs.Tier2",
      "Details.InNetworkPrescriptionDrugs.Tier3",
      "Details.InNetworkPrescriptionDrugs.Tier4",
      "Details.InNetworkPrescriptionDrugs.Tier5",
      "Details.InNetworkPrescriptionDrugs.Tier6",
      "Details.InNetworkPrescriptionDrugs.MailOrder",
    ],
  },
};

// Some Fields ^^^ (planSummaryDependencies) are simply labels and shouldn't be displayed
// if several others are hidden.
export const getConditionalOverrides = (product, sections) => {
  const sectionObject = sections.reduce(
    (prev, item) =>
      item?.Fields?.reduce(
        (p, i) => ({
          ...p,
          [i?.PropertyChain]: i,
        }),
        prev
      ),
    {}
  );
  let dependencies = planSummaryDependencies?.[product?.Type];
  if (product?.Type === "insurance_plan") {
    if (product?.Details?.PlanType?.toUpperCase() === surestPlanType) {
      dependencies = {
        ...dependencies,
        "Label.InNetworkProfessionalServices": surestInputFields,
      };
    } else {
      dependencies = {
        ...dependencies,
        "Label.InNetworkProfessionalServices": advancedInputFields,
      };
    }
  }
  return Object.entries(dependencies || {}).reduce((prev, [key, value]) => {
    const isHidden = value?.every(
      (field) => sectionObject[field]?.State === "hide"
    );
    const state = isHidden ? "hide" : "show";
    return { ...prev, [key]: state };
  }, {});
};

export const flattenSummaryFields = (product, layout) => {
  const { Sections } = layout;

  const conditionalOverrides = getConditionalOverrides(product, Sections);

  const getField = (field, sectionIdx) => {
    const State = conditionalOverrides?.[field?.PropertyChain] || field?.State;
    return {
      ...field,
      State,
      sectionIdx,
    };
  };

  return Sections?.reduce((prev, section, sectionIdx) => {
    const { DisplayValue, Fields } = section;
    const sectionConfig = getSectionConfig(summarySectionConfigs, section);
    if (
      !sectionConfig?.hideSection &&
      DisplayValue !== "Premiums & Contributions"
    ) {
      let list = [];
      if (sectionIdx === 0) {
        list.push({ sectionTitle: "Plan Details" });
      }

      return [...prev, ...list, ...Fields.map(getField)];
    }
    return prev;
  }, []);
};

export const defaultCost = {
  TotalMonthlyPremiums: {
    EmployeeOnly: 0,
    EmployeeSpouse: 0,
    EmployeeChildren: 0,
    Family: 0,
  },
  Contributions: {
    MonthlyContributions: {
      EmployeeOnly: 0,
      EmployeeSpouse: 0,
      EmployeeChildren: 0,
      Family: 0,
    },
    ContributionType: "$",
  },
};

export const defaultHSAContributions = {
  EmployeeOnly: 0,
  EmployeeSpouse: 0,
  EmployeeChildren: 0,
  Family: 0,
  EmployeeOnlyEmployerMonthlyMatch: 0,
  EmployeeSpouseEmployerMonthlyMatch: 0,
  EmployeeChildrenEmployerMonthlyMatch: 0,
  FamilyEmployerMonthlyMatch: 0,
  EmployeeOnlyEmployerMonthlyMatchMax: 0,
  EmployeeSpouseEmployerMonthlyMatchMax: 0,
  EmployeeChildrenEmployerMonthlyMatchMax: 0,
  FamilyEmployerMonthlyMatchMax: 0,
};
