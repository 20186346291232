import React, { useState } from "react";
import { css } from "emotion";
import { CircularProgress } from "@material-ui/core";
import { ContactInfo } from "./ContactInfo";
import { WarningCircle, X } from "phosphor-react";
import { useStateSync } from "../shared/use-state-sync";
import { useFeatureFlag } from "../shared/use-feature-flag";
import { Div, Text, Toggle, Button, Input } from "../shared/components";
import { history } from "../history";
import { useParams } from "react-router";
import { toast } from "react-toastify";
import { flex } from "../shared/shared-styles";
import { Select, ConfirmationModal } from "../shared/components";
import MenuItem from "@material-ui/core/MenuItem";
import { useStore } from "../store-provider/use-store";
import { CustomAxios } from "../redux/axios/axios";
import { useFeatureFlagPayload } from "posthog-js/react";
import { colors } from "../shared/styles";

const urlRegExp = /^[a-zA-Z0-9-_]+$/;
const briteMasterBusinessID = "b155ac0b-5ec2-4082-8b31-bae8e19e6363";

export const CourseSettings = (props) => {
  const { businessId, courseId, id } = useParams();

  const autoSaveFF = useFeatureFlag("course-saving-v2");
  const showPDFDownloadFF = useFeatureFlagPayload("show-pdf-download");
  const [autoSaveChanged, setAutoSaveChanged] = useState(autoSaveFF.value);
  const {
    data: { selectedBusiness, user, devMode },
  } = useStore();

  const [enrollment, setEnrollment, isEnrollmentSynced] = useStateSync(
    props.course.EnrollmentURL || "",
    [props.course.EnrollmentURL]
  );

  const [isPublished, setIsPublished, isIsPublishedSynced] = useStateSync(
    props.course.IsPublished || false,
    [props.course.IsPublished]
  );

  const [type, setType, isTypeSynced] = useStateSync(
    props.course.Type || "openenrollment",
    [props.course.Type]
  );

  const [passcode, setPasscode, isPasscodeSynced] = useStateSync(
    props.course.Passcode || "",
    [props.course.Passcode]
  );
  const [courseLink, setCourseLink, isCourseLinkSynced] = useStateSync(
    props.course.VanityURL || "",
    [props.course.VanityURL]
  );
  const [showGuideName, setShowGuideName, isShowGuideNameSynced] = useStateSync(
    props.course.ShowGuideName || false,
    [props.course.ShowGuideName]
  );
  const [hideNavigation, setHideNavigation, isHideNavigationSynced] =
    useStateSync(props.course.HideNavigation || false, [
      props.course.HideNavigation,
    ]);
  const [courseLinkErrorMsg, setCourseLinkErrorMsg] = useState("");
  const [showTypeSwitchWarning, setShowTypeSwitchWarning] = useState("");

  const downloadPDF = async () => {
    // Check if guide has brite editor pages. If any page is the brite editor, use the new pdf generation endpoint.
    const hasBriteEditor = props.course.Pages.some((page) =>
      page.Type === "brite-editor"
    );

    if (hasBriteEditor) {
      try {
        let url =  `/v1/guide/${props.course.ID}/pdf`
        if (props.course.Passcode) {
          url += "?passcode=" + props.course.Passcode
        }
        const pdfPromise = CustomAxios.postWithTimeout(
          url,
          {},
          {responseType: 'arraybuffer', timeout: 300000}, // This timeout won't work till we upgrade axios versions, so we reference postWithTimeout instead.
          300000 // 5 minutes max
        );
        toast.promise(pdfPromise, {
          pending: "Generating PDF...",
          success: "PDF generated successfully.\nCheck your downloads.",
          error: "Unable to generate PDF. Please try again later."
          }, {
            position: "bottom-center"
        });
        pdfPromise.then(res => {
          const url = window.URL.createObjectURL(new Blob([res.data], {type: 'application/pdf'}));
          const link = document.createElement('a');
          link.href = url;
          link.setAttribute(
            'download',
            `${props.course.Name ? props.course.Name : props.course.ID}.pdf`,
          );
          document.body.appendChild(link);
          link.click();
          link.parentNode.removeChild(link);
        })
      } catch (e) {
        toast.error("Unable to generate full PDF. Please try again later.");
        console.error(e);
      }
    } else {
    try {
      // Legacy PDF generation for unlayer guides
      await CustomAxios.post(
        `/v1/course/${props.course.ID}/download/${user.ID}`,
        {},
        {}
      );
      toast.success(
        "PDF is being generating, you will be emailed when it is complete."
      );
      } catch (e) {
        toast.error("Unable to generate PDF. Please try again later.");
        console.error(e);
      }
    }
  };

  const handleUpdate = (key, value, isSynced) => {
    if (!isSynced) {
      const payload = { ...props.course, [key]: value };
      props.updateCourseChanges(payload);
    }
  };

  const handleFlag = async () => {
    try {
      setAutoSaveChanged(true);
      await autoSaveFF.toggleFlag();
    } catch {
      setAutoSaveChanged(false);
    }
  };

  const handleClose = () => {
    if (!autoSaveChanged) {
      props.close();
    } else {
      const value = id || courseId;
      if (autoSaveFF.value) {
        history.replace(
          `/${businessId}/courses/${value}?pageId=${props.page?.ID}`
        );
      } else {
        history.replace(`/${businessId}/courses/${value}/builder`);
      }
    }
  };

  const handleCourseLink = (e) => {
    const val = e.target.value;
    const next = val?.replace(/[^a-zA-Z0-9-_]/g, "");
    setCourseLink(next);
  };

  return (
    <div
      className={css`
        height: 100%;
        overflow: auto;
      `}
    >
      <div
        className={css`
          display: flex;
          justify-content: space-between;
          align-items: center;
          padding: 28px;
          border-bottom: 1px solid #d1dae3;
          margin-bottom: 40px;
        `}
      >
        <Text h1>Guide Settings</Text>

        <Button styles="icon" onClick={handleClose}>
          <X />
        </Button>
      </div>

      {!("ID" in props.course) ? (
        <div
          className={css`
            display: flex;
            justify-content: center;
          `}
        >
          <CircularProgress />
        </div>
      ) : (
        <div
          className={css`
            width: 100%;
            max-width: 700px;
            margin: 0 auto;
            margin-bottom: 180px;
          `}
        >
          <div
            className={css`
              margin: 48px 0;
              padding-bottom: 40px;
              border-bottom: 1px solid ${colors.gray[300]};
            `}
          >
            <Text h4>Guide Link</Text>

            <Text
              css={`
                margin-top: 8px;
              `}
            >
              Before you can share a guide, you need to create a custom URL.
              This link will be shared with your employees to view the guide.
            </Text>
            <div
              className={css`
                border-radius: 8px;
                background-color: ${colors.gray[100]};
                padding: 16px;
                margin: 24px 0;
                ${flex("left")}
                svg {
                  margin-right: 16px;
                }
              `}
            >
              <WarningCircle color={colors.orange[100]} weight="fill" />
              <Text label>
                Changing this field will break any guide links you may have
                already sent out.
              </Text>
            </div>

            <Text
              label
              css={`
                margin-top: 16px;
                margin-bottom: 8px;
              `}
            >
              Guide Link
            </Text>
            <div
              className={css`
                border-radius: 8px;
                border: 1px solid ${colors.gray[300]};
                ${flex("left")}
              `}
            >
              <Text
                css={`
                  padding: 12px 16px;
                  border-right: 1px solid ${colors.gray[300]};
                `}
              >
                https://britehr.app/
              </Text>
              <Input
                css={`
                  flex-grow: 1;
                  border: none;
                  :hover {
                    border: none;
                  }
                `}
                value={courseLink}
                onChange={handleCourseLink}
                onBlur={() => {
                  if (courseLinkErrorMsg) {
                    return;
                  }
                  handleUpdate("VanityURL", courseLink, isCourseLinkSynced);
                }}
              />
            </div>
          </div>
          {props.course.Type !== "vendor" && (
            <div
              className={css`
                margin: 48px 0;
                padding-bottom: 40px;
                border-bottom: 1px solid ${colors.gray[300]};
              `}
            >
              <Text h4>Guide Type</Text>
              <Text
                css={`
                  margin-top: 8px;
                `}
              >
                We use this info to customize our tools for you and give you
                more accurate analytics.
              </Text>

              <Text
                label
                css={`
                  margin-top: 16px;
                  margin-bottom: 8px;
                `}
              >
                Guide Type
              </Text>
              <Select
                css={`
                  width: 100%;
                `}
                placeholder="Select a Type"
                value={type}
                onChange={(e) => {
                  if (type === "openenrollment" || type === "newhire") {
                    if (
                      e.target.value === "openenrollment" ||
                      e.target.value === "newhire"
                    ) {
                      setType(e.target.value);
                    } else {
                      setShowTypeSwitchWarning(e.target.value);
                    }
                  } else {
                    setType(e.target.value);
                  }
                }}
                onBlur={(e) => {
                  handleUpdate("Type", type, isTypeSynced);
                }}
              >
                <MenuItem value={"openenrollment"}>Open Enrollment</MenuItem>
                <MenuItem value={"newhire"}>New Hire</MenuItem>
                <MenuItem value={"recruiting"}>Recruiting</MenuItem>
                <MenuItem value={"other"}>Other</MenuItem>
              </Select>
            </div>
          )}

          <div
            className={css`
              margin: 48px 0;
              padding-bottom: 40px;
              border-bottom: 1px solid ${colors.gray[300]};
            `}
          >
            <Text h4>Add Enrollment URL</Text>
            <Text
              css={`
                margin: 8px 0;
              `}
            >
              An enrollment URL is used to generate components that help
              employees access your company's enrollment software.
            </Text>
            <Text
              label
              css={`
                margin: 8px 0;
                margin-top: 16px;
              `}
            >
              Enrollment URL
            </Text>
            <Input
              value={enrollment}
              onChange={(e) => setEnrollment(e.target.value)}
              onBlur={() =>
                handleUpdate("EnrollmentURL", enrollment, isEnrollmentSynced)
              }
            />
            <Text>
              Adding an Enrollment URL will add an additional enrollment page to
              the end of your guide.
            </Text>
          </div>

          <div
            className={css`
              margin: 48px 0;
              padding-bottom: 40px;
              border-bottom: 1px solid ${colors.gray[300]};
            `}
          >
            <Text h4>Add Passcode</Text>
            <Text
              css={`
                margin-top: 8px;
                margin-bottom: 16px;
              `}
            >
              If you set a passcode for this guide, only those with the code can
              view it.
            </Text>

            <Text
              label
              css={`
                margin-bottom: 8px;
              `}
            >
              Passcode
            </Text>
            <Input
              value={passcode}
              onChange={(e) => setPasscode(e.target.value)}
              onBlur={() =>
                handleUpdate("Passcode", passcode, isPasscodeSynced)
              }
            />
          </div>

          <ContactInfo
            course={props.course}
            updateCourse={props.updateCourseChanges}
          />

          {showPDFDownloadFF?.value && (
            <div
              className={css`
                padding-top: 40px;
                padding-bottom: 48px;
                margin: 48px 0;
                border-bottom: 1px solid #d1dae3;
              `}
            >
              <div
                className={css`
                  color: #25282d;
                  font-size: 18px;
                  margin-bottom: 16px;
                  font-weight: bold;
                `}
              >
                Internal Review PDF
              </div>

              <Button color="primary" variant="outlined" onClick={downloadPDF}>
                Generate PDF
              </Button>
            </div>
          )}

          {/* special-case here for brite master business, which is not a multi type business. Someday we might convert it to one, but I want to make sure that wouldn't break anything else before we do. */}
          {(selectedBusiness.Type === "multi" ||
            selectedBusiness.ID === briteMasterBusinessID) && (
            <div
              className={css`
                border-bottom: 1px solid ${colors.gray[300]};

                font-size: 12px;
                line-height: 18px;
                padding-top: 24px;
                padding-bottom: 24px;
                margin-bottom: 60px;
              `}
            >
              <Div
                css={css`
                  ${flex("space-between start")}
                `}
              >
                <div>
                  <Text h4>Publish to Base Guides Catalog</Text>
                  <Text
                    css={`
                      margin-top: 8px;
                      margin-bottom: 16px;
                    `}
                  >
                    Share this guide with your client business accounts. Makes
                    it show as an option when generating a guide from a benefits
                    package.
                  </Text>
                </div>
                <Toggle
                  checked={isPublished}
                  onChange={(e) => {
                    setIsPublished(e.target.checked);
                  }}
                  onBlur={() =>
                    handleUpdate(
                      "IsPublished",
                      isPublished,
                      isIsPublishedSynced
                    )
                  }
                />
              </Div>
            </div>
          )}

          {autoSaveFF.isUserRoleValid && (
            <div
              className={css`
                border-bottom: 1px solid #d1dae3;
                margin-bottom: 40px;
                margin-top: 8px;
                padding-bottom: 40px;
              `}
            >
              <Div
                css={css`
                  ${flex("space-between start")}
                `}
              >
                <div>
                  <Text h4>Auto Save</Text>
                  <Text
                    css={`
                      margin-top: 8px;
                    `}
                  >
                    Turn on guide auto save to make sure none of your hard work
                    goes to waste.
                  </Text>
                </div>
                <Toggle checked={autoSaveFF.value} onChange={handleFlag} />
              </Div>
            </div>
          )}

          <div
            className={css`
              border-bottom: 1px solid #d1dae3;
              margin-bottom: 40px;
              margin-top: 8px;
              padding-bottom: 40px;
            `}
          >
            <Div
              css={css`
                ${flex("space-between start")}
              `}
            >
              <div>
                <Text h4>Show Guide Name in Page Menu</Text>
                <Text
                  css={`
                    margin-top: 8px;
                  `}
                >
                  If enabled, the guide name will be displayed at the top of the
                  page menu.
                </Text>
              </div>
              <Toggle
                checked={showGuideName}
                onChange={(e) => setShowGuideName(e.target.checked)}
                onBlur={handleUpdate(
                  "ShowGuideName",
                  showGuideName,
                  isShowGuideNameSynced
                )}
              />
            </Div>
          </div>

          {devMode && (
            <div
              className={css`
                border-bottom: 1px solid #d1dae3;
                margin-bottom: 40px;
                margin-top: 8px;
                padding-bottom: 40px;
              `}
            >
              <Div
                css={css`
                  ${flex("space-between start")}
                `}
              >
                <div>
                  <Text h4>Hide Navigation Buttons</Text>
                  <Text
                    css={`
                      margin-top: 8px;
                    `}
                  >
                    Turn on to hide the next and previous buttons in the guide.
                  </Text>
                </div>
                <Toggle
                  checked={hideNavigation}
                  onChange={(e) => setHideNavigation(e.target.checked)}
                  onBlur={handleUpdate(
                    "HideNavigation",
                    hideNavigation,
                    isHideNavigationSynced
                  )}
                />
              </Div>
            </div>
          )}

          <Text>
            These settings are specific to this guide. They will not be
            reflected on any other guide that you have.
          </Text>
        </div>
      )}

      <ConfirmationModal
        title="This change will result in the decision tool feature being removed from this guide."
        confirmText="Confirm"
        display={!!showTypeSwitchWarning}
        onClose={() => setShowTypeSwitchWarning("")}
        onConfirm={async () => {
          setType(showTypeSwitchWarning);
          handleUpdate("Type", showTypeSwitchWarning, false);
          setShowTypeSwitchWarning("");
        }}
      />
    </div>
  );
};
