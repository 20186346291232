import React, { useState, useEffect } from "react";
import { css } from "emotion";

export const ImportedPageEditor = (props) => {
  return (
    <div
      key={props.selectedPage.ID}
      id="builder"
      className={css`
        border: 1px solid #dedede;
        border-radius: 5px;
        margin-left: 20px;
        display: flex;
        flex-direction: column;
        height: ${props.viewHeight};
        flex: 1;
        overflow: auto;
      `}
    >
      <div
        className={css`
          flex: 1;
          padding: 20px;
        `}
      >
        {!props.hide && (
          <div
            className={css`
              display: flex;
              justify-content: space-between;
              align-items: center;
              margin-bottom: 48px;
            `}
          >
            <div
              className={css`
                font-weight: 400;
                font-size: 26px;
                line-height: 28px;
                color: #797979;
              `}
            >
              Vendor Imported Page
            </div>
            <div>
              {props.selectedPage.IsLocked
                ? "Page is locked and cannot be edited."
                : "Page is not locked."}
            </div>
          </div>
        )}
        <div
          dangerouslySetInnerHTML={{ __html: props.selectedPage.HTMLContent }}
        ></div>
      </div>
    </div>
  );
};
