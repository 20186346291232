import { MenuItem } from "@material-ui/core";
import { css } from "emotion";
import { Check, X } from "phosphor-react";
import { useEffect, useState } from "react";
import { toast } from "react-toastify";
import {
  Button,
  Select,
  Div,
  Input,
  Modal,
  Text,
} from "../../../shared/components";
import { flex, px } from "../../../shared/shared-styles";
import { colors } from "../../../shared/styles";
import { useStateSync } from "../../../shared/use-state-sync";
import { MultipleChoicePreview } from "./multiple-choice-preview";

const emptyState = {
  Text: "",
  Type: "multipleChoice",
  PageID: "",
  Explanation: "",
  Answers: [
    {
      Text: "",
      OrderNumber: 1,
      IsCorrect: false,
    },
    {
      Text: "",
      OrderNumber: 2,
      IsCorrect: false,
    },
    {
      Text: "",
      OrderNumber: 3,
      IsCorrect: false,
    },
    {
      Text: "",
      OrderNumber: 4,
      IsCorrect: false,
    },
  ],
};

export const MultipleChoice = ({ page, savePage, pageLease }) => {
  const { Question = {} } = page;

  const data = {
    ...emptyState,
    ...Question,
    Type: Question?.Type || emptyState.Type,
    Answers: Question?.Answers?.length ? Question.Answers : emptyState.Answers,
  };

  const [question, setQuestion, isSynced] = useStateSync(data, [page?.ID]);
  const [modal, setModal] = useState(false);

  const defaultAnswersCount = Math.max(
    1,
    question?.Answers?.filter(({ Text }) => !!Text)?.length - 1
  );
  const [viewableAnswers, setViewableAnswers] = useState(defaultAnswersCount);

  useEffect(() => {
    setViewableAnswers(defaultAnswersCount);
  }, [defaultAnswersCount]);

  const handleCorrectAnswer = (event) => {
    const Answers = question.Answers.map((item, idx) => {
      const IsCorrect =
        event.target.value === "none"
          ? false
          : idx === Number(event.target.value);
      return { ...item, IsCorrect };
    });
    setQuestion((q) => ({ ...q, Answers }));
  };

  const updateAnswer = (value, index) => {
    const Answers = question.Answers.map((item, idx) => {
      if (idx === index) {
        return { ...item, Text: value };
      }
      return item;
    });
    setQuestion((q) => ({ ...q, Answers }));
  };

  const saveChanges = () => {
    setModal(false);
    if (pageLease.hasLease) {
      savePage({ Question: question });
    } else {
      setQuestion(data);
      toast.error(`${pageLease.leasedToEmail} is currently editing this page.`);
    }
  };

  const updateValue = (value, key) => {
    setQuestion((q) => {
      return { ...q, [key]: value || q[key] };
    });
  };

  const correctIndex = question?.Answers?.findIndex(
    ({ IsCorrect }) => IsCorrect
  );
  const correctAnswer = correctIndex > -1 ? correctIndex.toString() : "none";

  const removeAnswer = (index) => {
    let Answers = emptyState.Answers;
    for (let idx = 0; idx < question?.Answers?.length; idx++) {
      if (idx > 0 && index !== idx) {
        Answers[idx - 1] = { ...question?.Answers?.[idx], OrderNumber: idx };
      } else if (index !== idx) {
        Answers[idx] = { ...question?.Answers?.[idx], OrderNumber: idx + 1 };
      }
    }
    setQuestion((q) => ({ ...q, Answers }));
  };

  return (
    <Div>
      <Div
        css={css`
          ${flex("space-between")} padding: 32px;
        `}
      >
        <Text h1>Multiple Choice Page</Text>
        <Button onClick={() => setModal(true)}>Edit Form</Button>
      </Div>

      <Modal display={modal} onClose={() => setModal(false)}>
        <Div
          css={css`
            width: 500px;
          `}
        >
          <Div
            css={css`
              ${flex("space-between")} padding: 32px;
            `}
          >
            <Text h2>Edit Multiple Choice Form</Text>
            <Button styles="icon" onClick={() => setModal(false)}>
              <X />
            </Button>
          </Div>

          <Div
            css={css`
              padding: 0 32px;
            `}
          >
            <Text label bold>
              Question
            </Text>
            <Input
              rows={3}
              value={question?.Text || ""}
              onChange={(e) => updateValue(e.target.value, "Text")}
              css={`
                margin-bottom: 24px;
              `}
            />

            {question?.Answers?.map((answer, idx) =>
              idx <= viewableAnswers ? (
                <Div
                  css={css`
                    ${flex("left")} margin: 16px 0;
                  `}
                >
                  <Input
                    css={`
                      flex-grow: 1;
                    `}
                    value={answer.Text || ""}
                    onChange={(e) => updateAnswer(e.target.value, idx)}
                    endIcon={
                      <Check
                        size={24}
                        color={
                          correctIndex === idx
                            ? colors.green[100]
                            : colors.gray[300]
                        }
                      />
                    }
                  />
                  <Button
                    tabIndex={-1}
                    onClick={() => removeAnswer(idx)}
                    styles="icon sm"
                  >
                    <X />
                  </Button>
                </Div>
              ) : null
            )}

            {viewableAnswers < 3 && (
              <Button
                link
                css={`
                  color: ${colors.purple};
                `}
                onClick={() => setViewableAnswers(viewableAnswers + 1)}
              >
                + Add Option
              </Button>
            )}

            <Text label bold>
              Correct Answer
            </Text>
            <Select value={correctAnswer} onChange={handleCorrectAnswer}>
              <MenuItem value="none">None</MenuItem>
              <MenuItem value="0">Answer 1</MenuItem>
              <MenuItem value="1">Answer 2</MenuItem>
              <MenuItem value="2">Answer 3</MenuItem>
              <MenuItem value="3">Answer 4</MenuItem>
            </Select>

            <Text
              label
              bold
              css={`
                margin-top: 16px;
              `}
            >
              Answer Details
            </Text>
            <Input
              value={question?.Explanation || ""}
              onChange={(e) => updateValue(e.target.value, "Explanation")}
            />
          </Div>

          <Div
            css={css`
              ${flex("right")} padding: 32px;
            `}
          >
            <Button
              secondary
              css={`
                margin-right: 16px;
              `}
            >
              Cancel
            </Button>
            <Button disabled={isSynced} onClick={saveChanges}>
              Save Changes
            </Button>
          </Div>
        </Div>
      </Modal>

      <Div
        css={css`
          margin-left: ${px.xl};
          width: 100%;
          ${flex("center start")}
        `}
      >
        <MultipleChoicePreview question={data} />
      </Div>
    </Div>
  );
};
