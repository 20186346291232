import { all } from 'redux-saga/effects';
import { authSaga } from './actions/auth';
import { coursesSagas } from './actions/courses';
import { businessSaga } from './actions/business';
import { analyticsSaga} from './actions/analytics';

export function* rootSaga(store) {
    yield all([
        authSaga(store),
        coursesSagas(),
        businessSaga(store),
        analyticsSaga()
    ]);
  }