import { MenuItem } from "@material-ui/core";
import { css } from "emotion";
import { get, set } from "lodash";
import { useMemo } from "react";
import { ChromePicker } from "react-color";
import {
  Button,
  Div,
  DropMenu,
  Input,
  Select,
  Text,
} from "../../shared/components";
import { flex, px } from "../../shared/shared-styles";
import { colors, mediaQueryFn } from "../../shared/styles";
import { colorContainer } from "../design-styles";
import DesignStyles from "../design-styles.png";
import { fonts, fontSizes, fontTypesMap, previewFontStyle } from "../utils";
import { EditButton } from "./edit-button";
import { EditBackground } from "./edit-background";
import { EditAccent } from "./edit-accent";
import { EditLine } from "./edit-line";

export const colorStyle = (color) => css`
  position: absolute;
  top: 8px;
  right: 8px;
  ${colorContainer(color)}
`;

export const EditStyle = (props) => {
  const { designStyleProps } = props;
  const { current, setCurrent, selected } = designStyleProps || {};
  const isFont = selected in fontTypesMap;
  const fontProps = fontTypesMap?.[selected];
  const fontKey = selected === "Body" ? "Font" : "FontOverride";
  const typography = current?.Theme?.[selected];
  const headingFallback = current?.Theme?.Heading?.FontOverride?.Value;

  const colorKey = useMemo(() => {
    if (selected === "Body") {
      return `${selected}.FontColor`;
    } else if (isFont) {
      return `${selected}.FontColorOverride`;
    }
  }, [selected, isFont]);

  const color = get(current, `Theme.${colorKey}`, "#000000");

  const updateCurrent = (key, value) => {
    let designStyle = { ...current };
    const path = `Theme.${key}`;
    designStyle = set(designStyle, path, value);
    setCurrent(designStyle);
  };

  const handleFontChange = (e) => {
    const font = fonts.find(({ Value }) => Value === e.target.value);
    updateCurrent(`${selected}.${fontKey}`, font);
  };

  return (
    <Div
      className={css`
        width: 100%;
        max-width: 500px;
        padding: 32px;
        background-color: ${colors.gray[100]};
        height: max-content;
        border-radius: 16px;
        margin: 16px;
        ${mediaQueryFn("less-than", "900px")} {
          max-width: 400px;
        }
      `}
    >
      {!selected ? (
        <Div
          className={css`
            margin: 0 auto;
          `}
        >
          {current?.ID ? (
            <>
              <Text styles="h2 padb-xl">
                You can edit the style of each component by clicking on them
              </Text>
              <Text>
                When you edit your design styles they will be reflected
                throughout any guide it is attached to.
              </Text>
            </>
          ) : (
            <>
              <img
                src={DesignStyles}
                className={css`
                  width: 74px;
                `}
              />
              <Text styles="h2 mt">How Design Styles Work</Text>
              <Text styles="mt">
                When you attach a design style to a guide Any edits you make to
                that Design Style will be reflected on every guide it is
                attached to.
              </Text>
            </>
          )}
        </Div>
      ) : (
        <Div
          className={css`
            width: 100%;
            margin: 0 auto;
          `}
        >
          <Text styles="h2">Edit Style</Text>
          {isFont && (
            <Div
              className={css`
                margin-top: 32px;
              `}
            >
              <Div
                className={css`
                  height: 40px;
                `}
              >
                <span className={previewFontStyle(current?.Theme, fontProps)}>
                  {fontProps?.preview}
                </span>
              </Div>

              <Div
                css={css`
                  margin: ${px.xl} 0;
                `}
              >
                <Text styles="mb-sm" label bold>
                  Font
                </Text>
                <Select
                  className={css`
                    width: 100%;
                    background-color: white;
                  `}
                  onChange={handleFontChange}
                  value={typography?.[fontKey]?.Value || headingFallback || ""}
                >
                  {fonts.map(({ Label, Value }) => (
                    <MenuItem key={Value} value={Value}>
                      {Label}
                    </MenuItem>
                  ))}
                </Select>
              </Div>

              <Div
                css={css`
                  margin: ${px.xl} 0;
                `}
              >
                <Text styles="mb-sm" label bold>
                  Color
                </Text>
                <Div
                  className={css`
                    ${flex("jcsb aic")} position: relative;
                  `}
                >
                  <Input
                    className={css`
                      width: 100%;
                    `}
                    value={color}
                    onChange={(e) => updateCurrent(colorKey, e.target.value)}
                  />
                  <DropMenu
                    button={
                      <Button styles="naked" className={colorStyle(color)} />
                    }
                    transformOrigin={{ horizontal: "right" }}
                  >
                    <Div onClick={(e) => e.stopPropagation()}>
                      <ChromePicker
                        color={color}
                        onChange={(color) => updateCurrent(colorKey, color.hex)}
                        disableAlpha
                      />
                    </Div>
                  </DropMenu>
                </Div>
              </Div>

              <Div
                css={css`
                  margin: ${px.xl} 0;
                `}
              >
                <Text styles="mb-sm" label bold>
                  Font Size
                </Text>
                <Select
                  className={css`
                    width: 100%;
                    background-color: white;
                  `}
                  onChange={(e) =>
                    updateCurrent(`${selected}.FontSize`, e.target.value)
                  }
                  value={
                    typography?.FontSize || fontProps?.fontSizeFallback || ""
                  }
                >
                  {fontSizes.map((size) => (
                    <MenuItem key={size} value={size}>
                      {size}px
                    </MenuItem>
                  ))}
                </Select>
              </Div>
            </Div>
          )}

          {selected === "button" ? (
            <EditButton current={current} updateCurrent={updateCurrent} />
          ) : selected === "line" ? (
            <EditLine current={current} updateCurrent={updateCurrent} />
          ) : selected === "backgroundColor" ? (
            <EditBackground current={current} updateCurrent={updateCurrent} />
          ) : selected === "accent" ? (
            <EditAccent current={current} updateCurrent={updateCurrent} />
          ) : null}
        </Div>
      )}
    </Div>
  );
};
