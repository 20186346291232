import React, { useState } from "react";
import { css } from "emotion";
import { Layer, Image } from "grommet";
import { useAuth0 } from "../Auth/react-auth0-wrapper";
import logo from "../images/brite-logo-b.png";
import Button from "@material-ui/core/Button";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import Checkbox from "@material-ui/core/Checkbox";
import { makeStyles } from "@material-ui/core/styles";
import CircularProgress from "@material-ui/core/CircularProgress";
import { CustomAxios } from "../redux/axios/axios";
import { toast } from "react-toastify";

const useStyles = makeStyles((theme) => ({
  formControl: {
    minWidth: 300,
  },
}));

export const CollectTermAgreementModal = (props) => {
  const classes = useStyles();
  const { setShowTermsPopup, logout } = useAuth0();
  const [agree, setAgree] = useState(false);
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(false);

  const submit = async () => {
    if (agree) {
      setLoading(true);
      try {
        let response = await CustomAxios.post(`/v1/terms`, {
          BusinessID: props.businessId,
          UserID: props.userId,
        });
        setLoading(false);
        setShowTermsPopup(false);
        setError(false);
      } catch (e) {
        setLoading(false);
        toast.error("Error saving. Please try submitting again.");
      }
    } else {
      setLoading(false);
      setError(true);
    }
  };

  return (
    <React.Fragment>
      {props.showTermsPopup && (
        <Layer
          margin={{ left: "5px", top: "5px", right: "5px", bottom: "5px" }}
          full
          className={css`
            font-weight: 400;
            color: #222021;
            background: white;
            overflow: auto;
          `}
        >
          <div
            className={css`
              display: flex;
              justify-content: space-between;
              margin: 12px;
            `}
          >
            <Image
              src={logo}
              className={css`
                width: 83px;
                height: 35px;
              `}
            />

            <Button
              variant="outlined"
              onClick={() => {
                logout({ returnTo: window.location.origin });
              }}
            >
              Logout
            </Button>
          </div>

          <React.Fragment>
            <div
              className={css`
                font-weight: 400;
                font-size: 30px;
                text-align: center;
                line-height: 28px;
                margin: 20px;
                margin-top: 50px;
                color: #222021;
                margin-bottom: 5px;
              `}
            >
              Finish setting up your account
            </div>
            <div
              className={css`
                padding: 12px;
                margin: 0 auto;
              `}
            ></div>
          </React.Fragment>

          {loading && (
            <div
              className={css`
                margin: 5px auto;
              `}
            >
              <CircularProgress />
            </div>
          )}

          {!loading && (
            <React.Fragment>
              <div
                className={css`
                  font-weight: 400;
                  font-size: 20px;
                  text-align: center;
                  line-height: 28px;

                  color: #222021;
                  margin-bottom: 5px;
                `}
              >
                Review the terms
              </div>

              <div
                className={css`
                  margin: 28px auto;
                  max-width: 300px;
                `}
              >
                <FormControlLabel
                  control={
                    <Checkbox
                      checked={agree}
                      color="default"
                      onChange={(e) => {
                        setAgree(e.target.checked);
                      }}
                      name="agree"
                    />
                  }
                  label={
                    <span>
                      I agree to the{" "}
                      <a
                        className={css`
                          color: blue;
                          text-decoration: none;
                          &:hover {
                            color: black;
                            text-decoration: none;
                          }
                          &:visited {
                            color: blue;
                            text-decoration: none;
                          }
                        `}
                        href="https://share.hsforms.com/18ZIPbaK4QQGU3xaJ_0N2MA3ihp9"
                        target="_blank"
                      >
                        Terms of Use{" "}
                      </a>
                      I understand the{" "}
                      <a
                        className={css`
                          color: blue;
                          text-decoration: none;
                          &:hover {
                            color: black;
                            text-decoration: none;
                          }
                          &:visited {
                            color: blue;
                            text-decoration: none;
                          }
                        `}
                        href="https://share.hsforms.com/1UQbu6cvVR5-AJ09KpbxSjA3ihp9"
                        target="_blank"
                      >
                        Privacy Policy
                      </a>
                      .
                    </span>
                  }
                />
              </div>
              <div
                className={css`
                  margin: 14px auto;
                  max-width: 300px;
                `}
              >
                <Button
                  className={classes.formControl}
                  variant="contained"
                  color="primary"
                  onClick={() => {
                    submit();
                  }}
                >
                  Submit
                </Button>

                {error && (
                  <div
                    className={css`
                      margin-top: 14px;
                      color: red;
                    `}
                  >
                    Must agree to continue.
                  </div>
                )}
              </div>
            </React.Fragment>
          )}
        </Layer>
      )}
    </React.Fragment>
  );
};
