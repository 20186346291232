import React, { useState, useEffect } from "react";
import { Layer } from "grommet";
import { css } from "emotion";
import { CircularProgress, Typography } from "@material-ui/core";
import { history } from "../../history";
import { useParams } from "react-router-dom";
import { PlanType } from "./plan-type";
import {
  createProduct,
  getNewPlan,
  getRawData,
  submitMedicalPlan,
} from "./utils";
import { toast } from "react-toastify";
import { ImportIchraPlan } from "./import-ichra-plan";
import { ImportAcaPlan } from "./import-aca-plan";
import { acaSmallGroupType } from "../../Content/decision-tool-constants";
import posthog from "posthog-js";
import { Button } from "../../shared/components";
import { Badge } from "../../shared/components/badge";
import { ReviewAcaPlans } from "./review-aca-plans";
import { CustomAxios } from "../../redux/axios/axios";
import { usePosthogRegister } from "../../shared/usePosthogRegister";

const headerStyle = css`
  position: sticky;
  top: 0;
  width: 100%;
  height: 95px;
  min-height: 95px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  border-bottom: 1px solid #d1dae3;
  background-color: white;
  z-index: 100000;
  button {
    margin: 0 8px;
    :last-child {
      margin-right: 32px;
    }
  }
`;

const container = css`
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  flex-grow: 1;
  padding-bottom: 100px;
`;

const btnContainer = css`
  position: relative;
  margin-top: 24px;
  display: flex;
  align-items: center;
  .tooltip-question {
    position: absolute;
    right: calc(50% - 132px);
  }
`;

export const emptyBenefit = {
  ProviderID: "00000000-0000-0000-0000-000000000000",
  ProviderName: "",
  Title: "",
  Subtitle: "",
  Description: "",
  TitleDescriptions: [{ Title: "", Description: "" }],
  Disclaimer: "",
  BusinessID: "",
  LogoURL: "",
  CallToActionText: "",
  CallToActionLink: "",
  Type: "",
  IsBuyupAvailable: false,
  ApplicableStates: [],
  RawData: {},
};

export const getBenefit = (type) => {
  // All non-default types
  const RawData = getRawData(type);
  let data = {
    ...emptyBenefit,
    Type: type,
    RawData,
  };

  if (type === "custom") {
    data.Title = "Your Custom Benefit";
  }

  return data;
};

const stepFns = {
  "benefit-type": {
    validator: ({ typeList }) => !!typeList.length,
    next: () => {
      return "create-plan";
    },
  },
  "medical-plan-type": {
    validator: ({ PlanType }) => !!PlanType,
    next: ({ PlanType }) => {
      if (PlanType === "ICHRA") {
        return "ichra";
      } else if (PlanType === acaSmallGroupType) {
        return "aca";
      }
      return "dates";
    },
  },
  dates: {
    validator: () => true,
    next: () => "name-medical",
  },
  "name-medical": {
    validator: ({ Name }) => !!Name,
    next: () => "create-plan",
  },
  ichra: {
    validator: () => true,
    next: () => "import-ichra",
  },
  aca: {
    validator: ({ selected }) => selected && selected.length > 0,
    next: () => "review-aca",
  },
  "review-aca": {
    validator: ({ selected }) => selected?.length,
    next: () => "import-aca",
  },
};

export const CreationFlow = ({ modalProps = {} }) => {
  const [loading, setLoading] = useState(false);

  const params = useParams();
  const fullParams = { ...params, ...modalProps };
  const { businessId, packageId, step } = fullParams;

  const [typeList, setTypeList] = useState([]);
  const [selected, setSelected] = useState([]);

  const isValid =
    "validator" in stepFns[step]
      ? stepFns?.[step]?.validator({ selected, typeList })
      : true;
  const next =
    "validator" in stepFns[step] ? stepFns?.[step]?.next({ typeList }) : true;

  usePosthogRegister(
    {
      benefitsPackageID: packageId,
      editSessionId: crypto.randomUUID(),
    },
    []
  );

  useEffect(() => {
    posthog.capture("BenefitsPackageEdit", {
      actionType: "brite:choose_new_benefit",
    });
  }, []);

  const exitFlow = (planId = null, planType = null) => {
    const planIsString = typeof planId === "string";
    if (modalProps.exitFlow) {
      if (planIsString) {
        modalProps.exitFlow({ planId, planType });
      } else {
        modalProps.exitFlow();
      }
    } else {
      if (planIsString) {
        history.push(
          `/${businessId}/packages/${packageId}/${planType}/${planId}`
        );
      } else {
        history.push(`/${businessId}/packages/${packageId}`);
      }
    }
  };

  const routeToStep = (value = null) => {
    const nextValue = value || next;
    if (modalProps.setStep) {
      modalProps.setStep(nextValue);
    } else {
      history.push(nextValue);
    }
  };

  useEffect(() => {
    // Reset flow if benefit-type hasn't been selected
    if (step !== "benefit-type" && !typeList.length) {
      if (modalProps.setStep) {
        modalProps.setStep("benefit-type");
      } else {
        history.push("benefit-type");
      }
    }
  }, []);

  const createNonMedicalPlans = async () => {
    const types = typeList.filter((item) => item !== "insurance_plan");
    if (types.length) {
      const productList = types.map((type) => getBenefit(type));
      for (const type of types) {
        posthog.capture("BenefitsPackageSave", {
          actionType: "brite:create_benefit:" + type,
        });
      }
      await Promise.allSettled(
        productList.map((product) =>
          createProduct(product, { businessId, packageId })
        )
      );
    }
  };

  const createMedicalPlans = async () => {
    const types = typeList.filter((item) => item === "insurance_plan");
    if (types.length) {
      const productList = types.map(() => ({
        ...getNewPlan(),
        MultiNetworkCategory: "default",
        PlanType: "PPO",
      }));
      for (let idx = 0; idx < types.length; idx++) {
        posthog.capture("BenefitsPackageSave", {
          actionType: "brite:create_benefit:" + "insurance_plan",
        });
      }
      await Promise.allSettled(
        productList.map((product) =>
          submitMedicalPlan(product, { businessId, packageId })
        )
      );
    }
  };

  const createPlans = async () => {
    try {
      setLoading(true);
      await createNonMedicalPlans();
      await createMedicalPlans();
    } catch (err) {
      console.log(err);
      toast.error(
        `There was an error creating all your plans.. please review and try again.`
      );
    } finally {
      setLoading(false);
      exitFlow();
    }
  };

  const importZywavePlan = async (plan) => {
    try {
      const { data: zywaveIntegratedPlan } = await CustomAxios.get(
        `/v1/zywave/quotes/${plan.QuoteID}/plans/${plan.PlanID}/parsed`
      );
      return submitMedicalPlan(
        zywaveIntegratedPlan,
        { businessId, packageId },
        true
      );
    } catch (err) {
      throw err;
    }
  };

  const importACABenefit = async () => {
    try {
      setLoading(true);
      await Promise.allSettled(selected.map(importZywavePlan));
    } catch (err) {
      console.log(err);
      toast.error(
        `There was an error importing one or more of your plan(s).. please try again.`
      );
    } finally {
      setLoading(false);
      exitFlow();
    }
  };

  const ButtonControl = () => {
    if (next === "import-ichra") {
      return null;
    }
    if (step?.includes("aca") || step === "benefit-type") {
      const action =
        step === "aca"
          ? routeToStep
          : step === "review-aca"
          ? importACABenefit
          : createPlans;
      return (
        <Button
          onClick={() => action()}
          disabled={!isValid}
          css={`
            position: relative;
          `}
        >
          {(step === "benefit-type" && typeList.length > 0) ||
          (step?.includes("aca") && selected?.length > 0) ? (
            <Badge>
              {step === "benefit-type" ? typeList?.length : selected?.length}
            </Badge>
          ) : null}
          {step === "aca"
            ? "Review"
            : step === "review-aca"
            ? "Import"
            : typeList.length === 1
            ? "Create Plan"
            : "Create Plans"}
        </Button>
      );
    }
    return (
      <Button
        color="primary"
        variant="contained"
        disabled={!isValid}
        onClick={() => routeToStep()}
      >
        Next
      </Button>
    );
  };

  return (
    <Layer
      className={css`
        transform: none !important; // This fixes any sticky positioning for it's children === bueno
        overflow: auto;
        height: 100vh;
      `}
      onClickOutside={exitFlow}
      onEsc={exitFlow}
      modal={true}
      full
    >
      <div className={headerStyle}>
        <div
          className={css`
            font-weight: bold;
            font-size: 34px;
            line-height: 42px;
            color: #25282d;
            margin-right: 12px;
            margin-left: 32px;
          `}
        >
          Add Benefits
        </div>
        <div
          className={css`
            display: flex;
            align-items: center;
          `}
        >
          <Button
            color="primary"
            onClick={
              step === "review-aca" ? () => routeToStep("aca") : exitFlow
            }
            secondary
          >
            {step === "review-aca" ? "Back" : "Cancel"}
          </Button>
          <ButtonControl />
        </div>
      </div>

      <div className={container}>
        {loading ? (
          <>
            <Typography
              color="primary"
              style={{ fontWeight: "bold", fontSize: "24px", margin: "40px 0" }}
            >
              Creating Benefit {typeList.length === 1 ? "Plan" : "Plans"}...
            </Typography>
            <CircularProgress style={{ margin: "auto" }} />
          </>
        ) : (
          <>
            {step === "benefit-type" ? (
              <PlanType
                {...{
                  typeList,
                  setTypeList,
                  routeAca: () => routeToStep("aca"),
                }}
              />
            ) : step === "ichra" ? (
              <ImportIchraPlan packageId={packageId} close={exitFlow} />
            ) : step === "aca" ? (
              <ImportAcaPlan
                selectedPlans={selected}
                addSelectedPlan={(p) => {
                  setSelected([...selected, p]);
                }}
                reset={() => {
                  setSelected([]);
                }}
                removePlanFromSelected={(p) => {
                  setSelected(selected.filter((plan) => plan.ID !== p.ID));
                }}
              />
            ) : step === "review-aca" ? (
              <ReviewAcaPlans
                selectedPlans={selected}
                removePlanFromSelected={(p) => {
                  setSelected(selected.filter((plan) => plan.ID !== p.ID));
                }}
              />
            ) : null}
          </>
        )}
      </div>
    </Layer>
  );
};
