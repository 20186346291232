import { css } from "emotion";
import { flex } from "../shared-styles";
import { colors } from "../styles";
import { Button } from "./button";

// tabs = {
// tab1: 'label1'
// tab2: 'label2'
// }
//
// current = tab1

export const Tabs = ({
  tabs,
  current,
  setCurrent,
  css: cssString = "",
  secondary = false,
}) => {
  const cssStyle = css`
    width: 100%;
    border-bottom: 1px solid ${colors.gray[300]};
    ${secondary
      ? flex("space-between")
      : `
      ${flex("left")}
      .tab-button {
        max-width: max-content;
        margin-right: 24px;
        text-transform: none;
        font-size: 18px;
        padding: 16px 0;
        padding-bottom: 12px;
      }
    `}
    ${cssString}
  `;

  return (
    <div
      className={css`
        ${cssStyle}
      `}
    >
      {Object.entries(tabs || {}).map(([key, value]) => (
        <Button
          className="tab-button"
          tab-active={key === current}
          tab-inactive={key !== current}
          onClick={() => setCurrent(key)}
        >
          {value}
        </Button>
      ))}
    </div>
  );
};
