import React, { useState, useEffect } from "react";
import { css } from "emotion";
import CircularProgress from "@material-ui/core/CircularProgress";
import { makeStyles } from "@material-ui/core/styles";
import { CustomAxios } from "../../redux/axios/axios";
import { X } from "phosphor-react";
import InputLabel from "@material-ui/core/InputLabel";
import MenuItem from "@material-ui/core/MenuItem";
import { ColorPicker } from "material-ui-color";
import FormControl from "@material-ui/core/FormControl";
import Select from "@material-ui/core/Select";
import OutlinedInput from "@material-ui/core/OutlinedInput";
import { toast } from "react-toastify";
import { fonts } from "../../Courses/CustomizeTheme";
import Button from "@material-ui/core/Button";
import { Layer } from "grommet";
import IconButton from "@material-ui/core/IconButton";
import { LoadDesignStyle } from "./LoadDesignStyle";
import TextField from "@material-ui/core/TextField";
import { cloneDeep } from "lodash";
import { useHistory } from "react-router-dom";
import { useStore } from "../../store-provider/use-store";

const useStyles = makeStyles((theme) => ({
  formControl: {
    margin: theme.spacing(1),
    minWidth: 120,
  },
  selectEmpty: {
    marginTop: theme.spacing(2),
  },
  input: {
    minWidth: 300,
  },
  list: {
    width: "100%",
    maxWidth: 600,
  },
  listItem: {
    border: "1px solid black",
  },
  nested: {
    paddingLeft: theme.spacing(4),
    backgroundColor: "#f7f7f7",
  },
}));

export const formatThemeColors = (themeObj) => {
  let theme = cloneDeep(themeObj);
  if (theme.Body) {
    theme.Body.FontColor = theme.Body.FontColor.hex
      ? "#" + theme.Body.FontColor.hex
      : theme.Body.FontColor;
    theme.Body.BackgroundColor = theme.Body.BackgroundColor.hex
      ? "#" + theme.Body.BackgroundColor.hex
      : theme.Body.BackgroundColor;
    theme.Body.AccentColor = theme.Body.AccentColor.hex
      ? "#" + theme.Body.AccentColor.hex
      : theme.Body.AccentColor;
    theme.Body.ButtonColor = theme.Body.ButtonColor.hex
      ? "#" + theme.Body.ButtonColor.hex
      : theme.Body.ButtonColor;
  }
  if (theme.Heading) {
    theme.Heading.FontColorOverride = theme.Heading.FontColorOverride.hex
      ? "#" + theme.Heading.FontColorOverride.hex
      : theme.Heading.FontColorOverride;
  }

  return theme;
};

export const ThemeModal = (props) => {
  const classes = useStyles();
  const [loading, setLoading] = useState(false);
  const [theme, setTheme] = useState(null);
  const [currentModal, setCurrentModal] = useState("");
  const [newStyleName, setNewStyleName] = useState("");
  const [themeID, setThemeID] = useState(props.themeID);
  const [themeName, setThemeName] = useState("");
  const [showUpdateStylesConfirmation, setShowUpdateStylesConfirmation] =
    useState(false);
  const history = useHistory();

  const {
    data: { isBriteUser },
  } = useStore();

  useEffect(() => {
    fetchTheme();
  }, []);

  useEffect(() => {
    setThemeID(props.themeID);
  }, [props.themeID]);

  const fetchTheme = async () => {
    setLoading(true);
    let themeTemp;
    let themeNameTemp = "";
    try {
      const resp = await CustomAxios.get(`/v1/themes/${props.themeID}`);
      themeTemp = resp.data.Theme;
      themeNameTemp = resp.data.Name;
    } catch (e) {
      themeTemp = {
        Body: {
          Font: {
            Value: "'Montserrat',sans-serif",
            Label: "Montserrat",
          },
          FontColor: "#000000",
          BackgroundColor: "#ffffff",
        },
        ColorPickerPresets: [],
      };
    }

    if (!themeTemp.Heading) {
      themeTemp.Heading = {
        FontOverride: {},
      };
    }

    if (!themeTemp.Body.ButtonColor) {
      themeTemp.Body.ButtonColor = themeTemp.Body.FontColor;
    }
    if (!themeTemp.Body.AccentColor) {
      themeTemp.Body.AccentColor = themeTemp.Body.FontColor;
    }

    if (
      !themeTemp.Heading.FontOverride ||
      !themeTemp.Heading.FontOverride.Label
    ) {
      themeTemp.Heading.FontOverride = themeTemp.Body.Font;
    }

    if (!themeTemp.Heading.FontColorOverride) {
      themeTemp.Heading.FontColorOverride = themeTemp.Body.FontColor;
    }

    setTheme(themeTemp);
    setThemeName(themeNameTemp);
    setLoading(false);
  };

  const updateStyles = async () => {
    setLoading(true);

    try {
      let resp;

      let body = {
        Name: themeName || props.course.Name + " Theme",
        Theme: formatThemeColors(theme),
      };
      if (themeID && themeID !== "00000000-0000-0000-0000-000000000000") {
        resp = await CustomAxios.put(`/v1/themes/${themeID}`, body);
      } else {
        resp = await CustomAxios.post(`/v1/themes`, body);
      }

      await CustomAxios.put(`/v1/course/${props.course.ID}/themeid`, {
        ThemeID: resp.data.ID,
      });
      let cloned = cloneDeep(props.course);
      cloned.ThemeID = resp.data.ID;
      props.updateCourseChanges(cloned);
      props.setTheme(formatThemeColors(theme));
    } catch (e) {}

    setLoading(false);
    props.close();
  };

  const saveNewDesignStyle = async () => {
    setLoading(true);
    setCurrentModal("");

    try {
      let body = {
        Name: newStyleName,
        Theme: formatThemeColors(theme),
      };
      const resp = await CustomAxios.post(`/v1/themes`, body);
      setThemeID(resp.data.ID);
      setThemeName(resp.data.Name);
    } catch {
      toast.error("Did not save design style.");
    }

    setLoading(false);

    setNewStyleName("");
  };

  const removeDesignStyle = async () => {
    setLoading(true);

    let cloned = cloneDeep(props.course);
    cloned.ThemeID = "00000000-0000-0000-0000-000000000000";
    props.updateCourseChanges(cloned);
    let themeTemp = {
      Body: {
        Font: {
          Value: "'Montserrat',sans-serif",
          Label: "Montserrat",
        },
        FontColor: "#000000",
        BackgroundColor: "#ffffff",
      },
      ColorPickerPresets: [],
    };

    if (!themeTemp.Heading) {
      themeTemp.Heading = {
        FontOverride: {},
      };
    }

    if (!themeTemp.Body.ButtonColor) {
      themeTemp.Body.ButtonColor = themeTemp.Body.FontColor;
    }
    if (!themeTemp.Body.AccentColor) {
      themeTemp.Body.AccentColor = themeTemp.Body.FontColor;
    }

    if (
      !themeTemp.Heading.FontOverride ||
      !themeTemp.Heading.FontOverride.Label
    ) {
      themeTemp.Heading.FontOverride = themeTemp.Body.Font;
    }

    if (!themeTemp.Heading.FontColorOverride) {
      themeTemp.Heading.FontColorOverride = themeTemp.Body.FontColor;
    }
    props.setTheme(formatThemeColors(themeTemp));

    setCurrentModal("");
    setThemeID("00000000-0000-0000-0000-000000000000");
    setThemeName("");
    setTheme(themeTemp);

    setNewStyleName("");

    await CustomAxios.put(`/v1/course/${props.course.ID}/themeid`, {
      ThemeID: "00000000-0000-0000-0000-000000000000",
    });

    setLoading(false);
    //history.push(`/${props.course.BusinessID}/courses`);
    props.close();
  };

  if (loading || !theme) {
    return (
      <div
        className={css`
          display: flex;
          justify-content: center;
          margin: 48px;
        `}
      >
        <CircularProgress />
      </div>
    );
  }

  return (
    <>
      <div
        className={css`
          display: flex;
          justify-content: space-between;
          align-items: center;
          padding: 32px;
          border-bottom: 1px solid #d1dae3;
        `}
      >
        <div
          className={css`
            display: flex;
            align-items: center;
          `}
        >
          <div
            className={css`
              font-size: 34px;
              font-weight: bold;
              color: #25282d;
            `}
          >
            Edit Design Styles
          </div>
          <div
            className={css`
              margin-right: 18px;
              margin-left: 50px;
            `}
          >
            <Button
              onClick={() => setCurrentModal("loadModal")}
              color="primary"
            >
              Load Style
            </Button>
          </div>
          <div>
            <Button
              onClick={() => setCurrentModal("saveModal")}
              color="primary"
            >
              Save Style
            </Button>
          </div>

          <div
            className={css`
              margin-left: 18px;
            `}
          >
            <Button
              onClick={() => setCurrentModal("removeModal")}
              color="primary"
            >
              Remove Style
            </Button>
          </div>
        </div>
        <div
          className={css`
            display: flex;
          `}
        >
          <div
            className={css`
              margin-right: 18px;
            `}
          >
            <Button onClick={() => props.close()} color="primary">
              Cancel
            </Button>
          </div>
          <div>
            <Button
              color="primary"
              variant="contained"
              onClick={() => {
                setShowUpdateStylesConfirmation(true);
              }}
            >
              Update Styles
            </Button>
          </div>
        </div>
      </div>

      <div
        className={css`
          width: 700px;
          margin: 40px auto;
        `}
      >
        <div
          className={css`
            color: #25282d;
            font-size: 24px;
            line-height: 33px;
            padding-bottom: 32px;
            border-bottom: 1px solid #d1dae3;
            margin-bottom: 32px;
          `}
        >
          {themeName && (
            <>
              Currently making edits to the design{" "}
              <span
                className={css`
                  font-weight: bold;
                `}
              >
                {themeName}
              </span>
              .
            </>
          )}{" "}
          Any edits you make to the design will be reflected throughout your
          entire guide{" "}
          {themeName && (
            <>
              and any guide that uses this design.
              <div
                className={css`
                  display: flex;
                  align-items: center;
                  font-size: 14px;
                  margin-top: 16px;
                `}
              >
                <div
                  className={css`
                    margin-right: 22px;
                  `}
                >
                  Do you only want to edit this guide's design?
                </div>
                <Button
                  color="primary"
                  onClick={() => setCurrentModal("saveModal")}
                >
                  Duplicate
                </Button>
              </div>
            </>
          )}
        </div>

        <div>
          <div>
            <div
              className={css`
                display: flex;
                justify-content: space-between;
                align-items: center;
                margin: 12px;
              `}
            >
              <div className={css``}>Body Font Color</div>
              <ColorPicker
                disablePlainColor={true} // this makes it so we always send the color's hex code
                disableAlpha={true}
                value={theme.Body.FontColor}
                onChange={(val) => {
                  setTheme({
                    ...theme,
                    Body: {
                      ...theme.Body,
                      FontColor: val,
                    },
                  });
                }}
              />
            </div>

            <div
              className={css`
                display: flex;
                justify-content: space-between;
                align-items: center;
                margin: 12px;
              `}
            >
              <div className={css``}>Page Background Color</div>
              <ColorPicker
                disablePlainColor={true}
                disableAlpha={true}
                value={theme.Body.BackgroundColor}
                onChange={(val) => {
                  setTheme({
                    ...theme,
                    Body: {
                      ...theme.Body,
                      BackgroundColor: val,
                    },
                  });
                }}
              />
            </div>
            <div
              className={css`
                display: flex;
                justify-content: space-between;
                align-items: center;
                margin: 12px;
              `}
            >
              <div className={css``}>Accent Color</div>
              <ColorPicker
                disablePlainColor={true}
                disableAlpha={true}
                value={theme.Body.AccentColor}
                onChange={(val) => {
                  setTheme({
                    ...theme,
                    Body: {
                      ...theme.Body,
                      AccentColor: val,
                    },
                  });
                }}
              />
            </div>
            <div
              className={css`
                display: flex;
                justify-content: space-between;
                align-items: center;
                margin: 12px;
              `}
            >
              <div className={css``}>Button Color</div>
              <ColorPicker
                disablePlainColor={true}
                disableAlpha={true}
                value={theme.Body.ButtonColor}
                onChange={(val) => {
                  setTheme({
                    ...theme,
                    Body: {
                      ...theme.Body,
                      ButtonColor: val,
                    },
                  });
                }}
              />
            </div>
            <div
              className={css`
                display: flex;
                justify-content: space-between;
                align-items: center;
                margin: 12px;
              `}
            >
              <div className={css``}>Body Font</div>
              <div>
                <FormControl variant="outlined" className={classes.formControl}>
                  <InputLabel id="font">Font</InputLabel>
                  <Select
                    labelId="font"
                    id="font-type"
                    value={theme.Body.Font.Value}
                    onChange={(e) => {
                      let label = "";
                      let fontUrl = "";
                      for (let font of fonts) {
                        if (font.Value === e.target.value) {
                          label = font.Label;
                          fontUrl = font.URL;
                        }
                      }
                      setTheme({
                        ...theme,
                        Body: {
                          ...theme.Body,
                          Font: {
                            Value: e.target.value,
                            Label: label,
                            URL: fontUrl,
                          },
                        },
                      });
                    }}
                    input={<OutlinedInput label="Font" />}
                  >
                    {fonts.map((font) => {
                      return (
                        <MenuItem key={font.Value} value={font.Value}>
                          {font.Label}
                        </MenuItem>
                      );
                    })}
                  </Select>
                </FormControl>
              </div>
            </div>

            <div
              className={css`
                display: flex;
                justify-content: space-between;
                align-items: center;
                margin: 12px;
              `}
            >
              <div className={css``}>Header Font</div>
              <div>
                <FormControl variant="outlined" className={classes.formControl}>
                  <InputLabel id="font">Header Font</InputLabel>
                  <Select
                    labelId="font"
                    id="font-type"
                    value={theme.Heading.FontOverride.Value}
                    onChange={(e) => {
                      let label = "";
                      let fontUrl = "";
                      for (let font of fonts) {
                        if (font.Value === e.target.value) {
                          label = font.Label;
                          fontUrl = font.URL;
                        }
                      }
                      setTheme({
                        ...theme,
                        Heading: {
                          ...theme.Heading,
                          FontOverride: {
                            Value: e.target.value,
                            Label: label,
                            URL: fontUrl,
                          },
                        },
                      });
                    }}
                    label="Select Font"
                  >
                    {fonts.map((font) => {
                      return (
                        <MenuItem key={font.Value} value={font.Value}>
                          {font.Label}
                        </MenuItem>
                      );
                    })}
                  </Select>
                </FormControl>
              </div>
            </div>

            <div
              className={css`
                display: flex;
                justify-content: space-between;
                align-items: center;
                margin: 12px;
              `}
            >
              <div className={css``}>Header Font Color</div>
              <ColorPicker
                disablePlainColor={true}
                disableAlpha={true}
                value={theme.Heading.FontColorOverride}
                onChange={(val) => {
                  setTheme({
                    ...theme,
                    Heading: {
                      ...theme.Heading,
                      FontColorOverride: val,
                    },
                  });
                }}
              />
            </div>
          </div>
        </div>
      </div>

      {showUpdateStylesConfirmation && (
        <Layer
          margin={"medium"}
          modal={true}
          onEsc={() => setShowUpdateStylesConfirmation(false)}
          onClickOutside={() => setShowUpdateStylesConfirmation(false)}
        >
          <div
            className={css`
              display: flex;
              justify-content: space-between;
              align-content: center;
              margin: 32px;
              margin-bottom: 0px;
              padding-bottom: 24px;
              width: 700px;
              border-bottom: 1px solid #d1dae3;
            `}
          >
            <div
              className={css`
                font-weight: bold;
                font-size: 24px;
                color: #25282d;
                line-height: 38px;
              `}
            >
              Confirmation
            </div>
            <div>
              <IconButton
                className={css`
                  width: 48px;
                  height: 48px;
                `}
                onClick={() => setShowUpdateStylesConfirmation(false)}
              >
                <X color="#66737F" />
              </IconButton>
            </div>
          </div>
          <div
            className={css`
              margin: 32px;
              font-size: 22px;
              line-height: 30px;
            `}
          >
            Updating this design style will update the style for any guide that
            uses it.
          </div>
          <div
            className={css`
              display: flex;
              justify-content: flex-end;
              margin: 32px;
            `}
          >
            <div
              className={css`
                margin-right: 18px;
              `}
            >
              <Button
                onClick={() => {
                  setShowUpdateStylesConfirmation(false);
                  setCurrentModal("saveModal");
                }}
                color="primary"
              >
                Create Duplicate
              </Button>
            </div>
            <div>
              <Button
                color="primary"
                variant="contained"
                onClick={() => {
                  updateStyles();
                  setShowUpdateStylesConfirmation(false);
                }}
              >
                Confirm Update Styles
              </Button>
            </div>
          </div>
        </Layer>
      )}

      {currentModal === "removeModal" && (
        <Layer
          margin={"medium"}
          modal={true}
          onEsc={() => setCurrentModal("")}
          onClickOutside={() => setCurrentModal("")}
        >
          <div
            className={css`
              display: flex;
              justify-content: space-between;
              align-content: center;
              margin: 32px;
              margin-bottom: 0px;
              padding-bottom: 24px;
              width: 700px;
              border-bottom: 1px solid #d1dae3;
            `}
          >
            <div
              className={css`
                font-weight: bold;
                font-size: 24px;
                color: #25282d;
                line-height: 38px;
              `}
            >
              Confirmation
            </div>
            <div>
              <IconButton
                className={css`
                  width: 48px;
                  height: 48px;
                `}
                onClick={() => setCurrentModal("")}
              >
                <X color="#66737F" />
              </IconButton>
            </div>
          </div>
          <div
            className={css`
              margin: 32px;
              font-size: 22px;
              line-height: 30px;
            `}
          >
            Are you sure you want to remove this style from the guide?
          </div>
          <div
            className={css`
              display: flex;
              justify-content: flex-end;
              margin: 32px;
            `}
          >
            <div
              className={css`
                margin-right: 18px;
              `}
            >
              <Button
                onClick={() => {
                  setCurrentModal("");
                }}
                color="primary"
              >
                Cancel
              </Button>
            </div>
            <div>
              <Button
                color="primary"
                variant="contained"
                onClick={() => {
                  removeDesignStyle();
                }}
              >
                Confirm Remove Style
              </Button>
            </div>
          </div>
        </Layer>
      )}

      {currentModal === "loadModal" && (
        <Layer
          margin={"medium"}
          modal={true}
          onEsc={() => setCurrentModal("")}
          onClickOutside={() => setCurrentModal("")}
        >
          <div
            className={css`
              display: flex;
              justify-content: space-between;
              align-content: center;
              margin: 32px;
              margin-bottom: 0px;
              padding-bottom: 24px;
              width: 700px;
              border-bottom: 1px solid #d1dae3;
            `}
          >
            <div
              className={css`
                font-weight: bold;
                font-size: 24px;
                color: #25282d;
                line-height: 38px;
              `}
            >
              Load Design Style
            </div>
            <div>
              <IconButton
                className={css`
                  width: 48px;
                  height: 48px;
                `}
                onClick={() => setCurrentModal("")}
              >
                <X color="#66737F" />
              </IconButton>
            </div>
          </div>

          <LoadDesignStyle
            setThemeID={setThemeID}
            setThemeName={setThemeName}
            setTheme={setTheme}
            close={setCurrentModal}
          />
        </Layer>
      )}

      {currentModal === "saveModal" && (
        <Layer
          margin={"medium"}
          modal={true}
          onEsc={() => setCurrentModal("")}
          onClickOutside={() => setCurrentModal("")}
        >
          <>
            <div
              className={css`
                display: flex;
                justify-content: space-between;
                align-content: center;
                margin: 32px;
                margin-bottom: 0px;

                width: 700px;
              `}
            >
              <div
                className={css`
                  font-weight: bold;
                  font-size: 24px;
                  color: #25282d;
                  line-height: 38px;
                `}
              >
                Save New Design Style
              </div>
              <div>
                <IconButton
                  className={css`
                    width: 48px;
                    height: 48px;
                  `}
                  onClick={() => setCurrentModal("")}
                >
                  <X color="#66737F" />
                </IconButton>
              </div>
            </div>
            <TextField
              variant="outlined"
              placeholder="Style Name"
              style={{ width: "700px", margin: "0 auto" }}
              value={newStyleName}
              onChange={(e) => setNewStyleName(e.target.value)}
            />

            <div
              className={css`
                display: flex;
                justify-content: flex-end;
                margin: 32px;
              `}
            >
              <div
                className={css`
                  margin-right: 18px;
                `}
              >
                <Button onClick={() => setCurrentModal("")} color="primary">
                  Cancel
                </Button>
              </div>
              <div>
                <Button
                  color="primary"
                  variant="contained"
                  onClick={() => saveNewDesignStyle()}
                >
                  Save Style
                </Button>
              </div>
            </div>
          </>
        </Layer>
      )}
    </>
  );
};
