import React, { useEffect, useState } from "react";

export const RedirectToCourseViewer = () => {
  const [redirectUrl, setRedirectUrl] = useState("");

  useEffect(() => {
    let path = window.location.pathname;
    let paths = path.split("/");
    let extension = "";
    if (paths[2]) {
      extension = paths[2];
    }
    let url = `https://britehr.app/${extension}`;
    setRedirectUrl(url);
    window.location.replace(url);
  }, []);

  return (
    <p>
      This page has been moved. If you aren't automatically redirected,{" "}
      <a href={redirectUrl}>click here</a>
    </p>
  );
};
