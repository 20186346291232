import { Collapse } from "@material-ui/core";
import { css } from "emotion";
import { StarFour, X } from "phosphor-react";
import { useEffect, useRef } from "react";
import {
  autoPastePropertyChains,
  autoPasteTypes,
} from "../../benefit-package/auto-paster-v2/paste-utils";
import { useRequests } from "../../react-query";
import { Button, Copy, Text } from "../../shared/components";
import { flex } from "../../shared/shared-styles";
import { colors } from "../../shared/styles";
import { productUtils } from "../configs/product-utils";
import { getStoredValue } from "../field-components/field-input";
import { getFieldProps } from "../field-components/field-utils";
import { aiSuggestions } from "../../react-query/resources/ai-suggestions";
import { useSearchParams } from "../../shared/use-search-params";

export const AutoFill = ({ state, autoFillProps, onClose }) => {
  const {
    filteredSuggestions: suggestions,
    layout,
    fieldConfigs,
  } = autoFillProps;
  const aiSuggestionsRequests = useRequests(state?.aiSuggestions);
  const fieldHistoryRequests = useRequests(state?.fieldHistory);

  const { updateParams } = useSearchParams();

  const scrollRef = useRef();

  const { suggestion } = state;

  useEffect(() => {
    if (suggestions?.length === 0) {
      onClose();
    }
  }, [suggestions?.length]);

  const select = (propertyChain) => {
    productUtils.selectSuggestion(state, "suggestion", propertyChain);
  };

  useEffect(() => {
    if (suggestion?.source === "field") {
      scrollRef?.current?.scrollIntoView({ behavior: "smooth" });
    }
  }, [suggestion?.updatedAt]);

  const getNextSuggestion = (item) => {
    const current = suggestions.findIndex(
      ({ property_chain }) => item?.property_chain === property_chain
    );
    const next = suggestions?.[current + 1];
    if (next?.property_chain) {
      select(next?.property_chain);
    }
  };

  const acceptSuggestion = (item) => {
    const property = item?.property_chain;
    const config = fieldConfigs?.[property];
    const field = layout?.[property];
    const fieldProps = getFieldProps(state, field, config);
    const data = item?.suggested_value;
    if (property in autoPastePropertyChains) {
      autoPastePropertyChains[property](state, data, fieldProps);
    } else if (field?.Type in autoPasteTypes) {
      autoPasteTypes[field?.Type](data, fieldProps);
    } else {
      const value = getStoredValue(field, config, data);
      fieldProps?.updateProperty(property, value);
    }

    state?.dispatch({
      type: "set-search-term",
      data: {
        value: item?.suggested_value,
        propertyChain: item?.property_chain,
      },
    });

    productUtils.updateHistoryFromSuggestion(state, item, {
      fieldHistory: fieldHistoryRequests,
      aiSuggestions: aiSuggestionsRequests,
    });
    getNextSuggestion(item);
    productUtils.initiateSave(state);
  };

  const dismissSuggestion = async (suggestion) => {
    try {
      const putSuggestions = aiSuggestions.utils.getPut(suggestion?.id, {
        ...suggestion,
        status: "dismissed",
      });
      await aiSuggestionsRequests.put({
        ...putSuggestions,
        optimstic: (old) => old?.filter((item) => item?.id !== suggestion?.id),
      });
    } catch (err) {
      console.log(err);
    }
  };

  const openDocument = (suggestion) => {
    const mediaId = suggestion?.original_file_id;
    updateParams({ mediaId });
  };

  return (
    <div>
      <div
        className={css`
          ${flex("space-between")}
        `}
      >
        <Text
          h2
          css={`
            position: relative;
          `}
        >
          <StarFour
            size={16}
            color={colors.purple}
            weight="fill"
            className={css`
              position: absolute;
              top: 0px;
              left: -16px;
            `}
          />
          Auto-Fill
        </Text>
        <Button styles="icon" onClick={onClose}>
          <X />
        </Button>
      </div>

      <div
        className={css`
          margin-top: 16px;
        `}
      >
        <Text
          css={`
            margin-bottom: 16px;
          `}
        >
          {suggestions?.length} Suggestions
        </Text>
        <div className={css``}>
          {suggestions?.map((item) =>
            layout?.[item?.property_chain] ? (
              <Copy
                value={item?.suggested_value}
                disableCopy={
                  item?.property_chain !== suggestion?.data?.property_chain
                }
              >
                <div
                  className={css`
                    scroll-margin-top: 35vh;
                    border-radius: 8px;
                    padding: 16px;
                    margin-bottom: 16px;
                    cursor: pointer;
                    ${suggestion?.data?.id === item?.id
                      ? `
                    outline: 3px solid ${colors.black};
                    border: 1px solid transparent;
                  `
                      : `
                  border: 1px solid ${colors.gray[300]};
                  :hover {
                    border: 1px solid transparent;
                    outline: 1px solid ${colors.black};
                  }
                `}
                  `}
                  onClick={() => select(item?.property_chain)}
                  ref={suggestion?.data?.id === item?.id ? scrollRef : null}
                >
                  <Text label bold>
                    {layout?.[item?.property_chain]?.DisplayValue}
                  </Text>
                  <Text label>{item?.suggested_value}</Text>
                  <Collapse in={suggestion?.data?.id === item?.id}>
                    <div
                      className={css`
                        ${flex("left")}
                        margin: 8px 0;
                        width: max-content;
                      `}
                      onClick={(e) => e.stopPropagation()}
                    >
                      <Button
                        small
                        css={`
                          background: ${colors.briteGradient};
                          text-transform: none;
                        `}
                        onClick={() => acceptSuggestion(item)}
                      >
                        Accept
                      </Button>
                      <Button
                        secondary
                        small
                        css={`
                          text-transform: none;
                          margin-left: 16px;
                        `}
                        onClick={() => dismissSuggestion(item)}
                      >
                        Dismiss
                      </Button>
                      <Button
                        secondary
                        small
                        css={`
                          text-transform: none;
                          margin-left: 16px;
                        `}
                        onClick={() => openDocument(item)}
                      >
                        View Source
                      </Button>
                    </div>
                  </Collapse>
                </div>
              </Copy>
            ) : null
          )}
        </div>
      </div>
    </div>
  );
};
