import { css } from "emotion";
import { Modal, Text } from "../../shared/components";
import { UploadSimple } from "phosphor-react";
import { colors } from "../../shared/styles";
import { container, flex } from "../../shared/shared-styles";
import { useForm } from "../../shared/use-form";
import { BriteLoader } from "../../shared/components/brite-loader";
import { useUploadDoc } from "./use-upload-doc";
import { useState } from "react";
import { ReactComponent as AiFileGradient } from "../../images/svg/ai-file-gradient.svg";

export const DocumentToBP = ({ startFromScratch, ...modalProps }) => {
  const benefitPkgForm = useForm({ Name: "Package Name" }, [
    modalProps?.display,
  ]);
  const [loading, setLoading] = useState(false);
  const { getRootProps, getInputProps, isDragActive } = useUploadDoc({
    benefitPkg: benefitPkgForm?.values,
    setLoading,
  });

  return (
    <Modal {...modalProps}>
      <Modal.Paper width="600px">
        <Modal.Header
          onClose={modalProps.onClose}
          title="New Benefit Package"
        />
        <Modal.Body
          css={`
            position: relative;
            min-height: 200px;
          `}
        >
          {loading ? (
            <BriteLoader />
          ) : (
            <div className={uploadContainer} {...getRootProps()}>
              <input {...getInputProps()} />
              {!isDragActive ? (
                <>
                  <AiFileGradient />
                  <Text
                    label
                    bold
                    css={`
                      margin: 8px 0;
                    `}
                  >
                    Smart Import From Document
                  </Text>
                  <Text>Drag and drop or click to browse</Text>
                </>
              ) : (
                <UploadSimple size={64} />
              )}
            </div>
          )}
          <div className={itemContainer} onClick={startFromScratch}>
            <div className={selectionIcon} />
            <Text label bold>
              Start From Scratch
            </Text>
          </div>
        </Modal.Body>
      </Modal.Paper>
    </Modal>
  );
};

const selectionIcon = css`
  position: relative;
  ${flex("center")}
  padding: 0;
  width: 31px;
  height: 31px;
  border: 2px dashed ${colors.black};
  border-radius: 4px;
  margin-right: 16px;
  ::after {
    position: absolute;
    content: "";
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    margin: 3px;
    background-color: ${colors.gray[200]};
    border-radius: 4px;
  }
`;

const uploadContainer = css`
  position: relative;
  border-radius: 8px;
  border: 2px dashed ${colors.purple};
  padding: 32px;
  min-height: 180px;
  ${flex("center column")}
  cursor: pointer;
  ${container.hover}
  * {
    pointer-events: none;
  }
`;

const itemContainer = css`
  position: relative;
  border-radius: 8px;
  padding: 16px 24px;
  background: white;
  border: 1px solid ${colors.gray[300]};
  margin-top: 16px;
  ${flex("left")}
  svg {
    margin-right: 16px;
  }
  :hover {
    border: 1px solid transparent;
  }
  ${container?.hoverOutline}
`;
