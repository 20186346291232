import { css } from "emotion";
import { get } from "lodash";
import { CaretDown, CaretUp, StarFour } from "phosphor-react";
import { useEffect, useMemo, useRef, useState } from "react";
import { toast } from "react-toastify";
import {
  tierStructures,
  useDisplaySettings,
} from "../../BriteEditor/editor-components/benefits/plan-comparisons/use-display-settings";
import { getFieldConfigs } from "../../products/configs/config-utils";
import { formFieldConfigs } from "../../products/configs/form.config";
import {
  productModifier,
  productUtils,
} from "../../products/configs/product-utils";
import { getStoredValue } from "../../products/field-components/field-input";
import { FieldTypes } from "../../products/field-components/field-types";
import { getFieldProps } from "../../products/field-components/field-utils";
import { CustomAxios } from "../../redux/axios/axios";
import { useKeyboard } from "../../Router/use-keyboard";
import { Button, Div, Text, Select } from "../../shared/components";
import { MenuItem, Tooltip } from "@material-ui/core";
import {
  animation,
  container,
  flex,
  scrollbar,
  shadows,
} from "../../shared/shared-styles";
import { colors } from "../../shared/styles";
import { useOutsideClick } from "../../shared/use-outside-click";
import { useStateSync } from "../../shared/use-state-sync";
import { useStore } from "../../store-provider/use-store";
import { decode, expandCostDetails, mediaModifiers } from "./media-utils";
import {
  autoPastePropertyChains,
  autoPasteTypes,
  disabledTypes,
} from "./paste-utils";

import { useFeatureFlagPayload } from "posthog-js/react";
import { useAISuggestions } from "./use-ai-suggestions";
import { BriteLoader } from "../../shared/components/brite-loader";

const useTriggerAnimation = (style, timeout, deps) => {
  const ref = useRef(null);
  const [animation, setAnimation] = useState("");

  useEffect(() => {
    setAnimation(style);
    if (!ref.current) {
      ref.current = setTimeout(() => {
        ref.current = null;
        setAnimation("");
      }, timeout);
    }
  }, [...deps]);

  return animation;
};

export const ProductWizard = (props) => {
  const { state, media, displayAutoFill } = props;
  const { businessId, packageId } = state;
  const costTierFF = useFeatureFlagPayload("tier-structure");

  const { historyQuery } = media;
  const {
    data: { user = {} },
  } = useStore();
  const { displaySettings } = useDisplaySettings(businessId, packageId);

  const [searchTerm, setSearchTerm] = useStateSync("", [
    media?.state?.fieldIdx,
  ]);
  const [fieldMenu, setFieldMenu] = useState(false);
  const ref = useOutsideClick(() => {
    setFieldMenu(false);
  });

  // ****************************************************
  // ** Manage Fields in wizard
  // ****************************************************

  const fieldsConfig = getFieldConfigs(formFieldConfigs, {
    product: state.product,
    fieldsObject: state.fieldsObject,
  });
  const fieldsList = useMemo(() => {
    let index = -1;
    return state?.layout?.Layout?.Sections?.reduce((prev, item) => {
      return item?.Fields?.reduce((p, field) => {
        if ("PropertyChain" in field && field?.Type !== "label") {
          const config = fieldsConfig?.[field?.["PropertyChain"]];
          if (config?.hideField || config?.disabled) {
            return p;
          }

          let fieldState;
          if (field?.PropertyChain === "Cost") {
            fieldState = expandCostDetails({
              cost: state?.product?.Cost,
              displaySettings,
              field,
              config,
              index,
              featureFlag: costTierFF?.value,
            });
          } else {
            fieldState = [
              {
                index: index + 1,
                field,
                config,
              },
            ];
          }
          index = fieldState?.at(-1)?.index;
          return [...p, ...fieldState];
        }
        return p;
      }, prev);
    }, []);
  }, [
    costTierFF?.value,
    state?.product?.Cost?.TotalMonthlyPremiums?.Tiers,
    state?.product?.Cost?.Contributions?.ContributionType,
    state?.productId,
    media?.state?.fieldIdx,
    state?.layout?.Layout?.Sections,
  ]);

  useEffect(() => {
    mediaModifiers.setMaxFieldIndex(media, fieldsList?.length - 1);
  }, [fieldsList?.length]);

  const [fieldIdx, _, isFieldIdxSynced] = useStateSync(
    () => Math.min(media?.state?.fieldIdx || 0, fieldsList?.length - 1),
    [media?.state?.fieldIdx, fieldsList?.length, state?.productId]
  );

  useEffect(() => {
    if (
      !media?.state?.focus?.action &&
      state?.suggestion?.data?.property_chain
    ) {
      const idx = fieldsList?.findIndex(
        ({ field }) =>
          state?.suggestion?.data?.property_chain === field?.PropertyChain
      );
      if (idx > -1) {
        mediaModifiers.setFieldIdx(media, idx);
      }
    }
  }, [fieldsList?.length, state?.suggestion?.data?.property_chain]);

  const current = fieldsList?.[fieldIdx] || {};
  const fieldProps = getFieldProps(state, current?.field, current?.config);
  const { updateProperty } = fieldProps;
  const [flagModal, setFlagModal] = useState(false);

  // ****************************************************
  // ** Toggle Overlay
  // ****************************************************

  useEffect(() => {
    if (current?.field?.Type === "toggle") {
      mediaModifiers.turnOnOverlay(media, "");
    } else {
      mediaModifiers.turnOffOverlay(media, false);
    }

    mediaModifiers.setCurrentPropertyChain(
      media,
      current?.field?.PropertyChain
    );
  }, [current?.field?.PropertyChain]);

  // ****************************************************
  // ** Handle product property updates on cell click
  // ****************************************************

  const mediaUpdate =
    media?.state?.update?.type === "select-cell" ||
    media?.state?.update?.type === "select-text"
      ? media?.state?.update?.modifiedAt
      : "";

  const handleSelected = (fieldType) => {
    if (isFieldIdxSynced) {
      const fieldTypeProperties = {
        "select-cell": "selectedCell",
        "select-text": "selectedText",
      };
      const property = fieldTypeProperties[fieldType];
      if (current?.field?.Type in disabledTypes) {
        toast.warn(disabledTypes[current?.field?.Type]);
      } else {
        let data = media?.state?.[property]?.value;
        // Trim leading and trailing whitespace.
        if (typeof data === "string") {
          data = data.trim();
        }
        if (current?.field?.PropertyChain in autoPastePropertyChains) {
          autoPastePropertyChains[current?.field?.PropertyChain](
            state,
            data,
            fieldProps
          );
        } else if (current?.field?.Type in autoPasteTypes) {
          autoPasteTypes[current?.field?.Type](data, fieldProps, {
            setSearchTerm,
          });
        } else {
          const value = getStoredValue(current?.field, current?.config, data);
          updateProperty(current?.field?.PropertyChain, value);
        }
      }
    }
  };

  useEffect(() => {
    if (
      media?.state?.update?.type === "select-cell" ||
      media?.state?.update?.type === "select-text"
    ) {
      handleSelected(media?.state?.update?.type);
    }
  }, [mediaUpdate]);

  const animatedInputTriggered = useTriggerAnimation(
    `background-color: ${colors.purple}44;`,
    100,
    [mediaUpdate]
  );

  const animatedInput =
    fieldIdx === media?.state?.update?.lastFieldIdx
      ? animatedInputTriggered
      : "";

  // ****************************************************
  // ** Handle auto-saving field history
  // ****************************************************
  const initialLoad = useRef(true);
  useEffect(() => {
    initialLoad.current = true;
  }, [media?.state?.activeSheet]);

  const [fieldHistory, setFieldHistory] = useStateSync(historyQuery.data, [
    historyQuery.dataUpdatedAt,
    state?.productId,
  ]);

  const aiSuggestions = useAISuggestions(props, {
    currentPropertyChain: current?.field?.PropertyChain,
    displaySettings,
    fieldsConfig,
    fieldHistory,
  });

  const lastCellRef = useMemo(() => {
    return media?.state?.selectedCell?.ref;
  }, [fieldIdx]);

  const [lastSuggestionsId, setLastSuggestionId] = useStateSync("", [
    current?.field?.PropertyChain,
  ]);

  const handleSuggestion = (goToNextField) => {
    mediaModifiers.acceptSuggestion(
      media,
      aiSuggestions?.current,
      goToNextField
    );
    setLastSuggestionId(aiSuggestions?.current?.id);
  };

  const getCurrentFieldUpdates = () => {
    let body = {};
    try {
      const propertyChain = current?.field?.PropertyChain;
      const value = get(state?.product, propertyChain);
      switch (media?.state?.fileType) {
        case "xlsx":
          const cell = media?.state?.selectedCell;
          const position = decode(cell?.ref);
          const ref_cell_col = position?.c + 1;
          const ref_cell_row = position?.r + 1;
          const colEnd = ref_cell_col + cell?.merge?.c;
          const rowEnd = ref_cell_row + cell?.merge?.r;
          const endIndices = cell?.isMerged
            ? {
                ref_cell_col_end: colEnd === 0 ? null : colEnd,
                ref_cell_row_end: rowEnd === 0 ? null : rowEnd,
              }
            : {};
          body = {
            value,
            source: "autopaster@v1",
            product_id: state.productId,
            created_by_user_id: user?.ID,
            property_chain: propertyChain,
            business_id: state.businessId,
            source_metadata: {
              ref_file_id: media?.state?.mediaId,
              ref_sheet: media?.state?.activeSheet,
              ref_file_type: media?.state?.fileType,
              ref_cell_col,
              ref_cell_row,
              ...endIndices,
            },
          };
          break;
        case "pdf":
          body = {
            value,
            source: "pdfviewer@v1",
            product_id: state.productId,
            created_by_user_id: user?.ID,
            property_chain: propertyChain,
            business_id: state.businessId,
            source_metadata: {
              ref_file_id: media?.state?.mediaId,
              ref_file_type: media?.state?.fileType,
              ref_page_number: media?.state?.selectedText?.pageNumber,
              ref_position_top: media?.state?.selectedText?.locationOnPage?.top,
              ref_position_left:
                media?.state?.selectedText?.locationOnPage?.left,
              ref_position_height:
                media?.state?.selectedText?.locationOnPage?.height,
              ref_position_width:
                media?.state?.selectedText?.locationOnPage?.width,
              ref_page_height: media?.state?.selectedText?.originalPageHeight,
              ref_page_width: media?.state?.selectedText?.originalPageWidth,
            },
          };
          break;
      }

      const nextFieldHistory = [body, ...fieldHistory];
      setFieldHistory(nextFieldHistory);
      return body;
    } catch (err) {
      return console.log("err:", err);
    }
  };

  const setTierIndex = (idx) => {
    productModifier?.setProduct(state, (p) => ({
      ...p,
      Cost: {
        ...p?.Cost,
        Contributions: {
          ...p?.Cost?.Contributions,
          MonthlyContributions: {
            ...p?.Cost?.Contributions?.MonthlyContributions,
            Tiers: [...tierStructures[idx - 2]],
          },
        },
        TotalMonthlyPremiums: {
          ...p?.Cost?.TotalMonthlyPremiums,
          Tiers: [...tierStructures[idx - 2]],
        },
      },
    }));
  };

  // ****************************************************
  // ** Handle auto-saving product
  // ****************************************************

  const saveProduct = async () => {
    try {
      const suggestion = aiSuggestions?.lookup(
        current?.field?.PropertyChain,
        true
      );
      getCurrentFieldUpdates();
      await productUtils.saveProduct(state);
      saveFieldHistory(suggestion);
    } catch (err) {
      console.error(err);
      toast.error(`Failed to save benefit`);
    }
  };

  const saveFieldHistory = async (suggestion = null) => {
    try {
      const body = getCurrentFieldUpdates();
      if (body === null) {
        return;
      }
      const resp = await CustomAxios.post(
        `/v1/bp/product_field_history`,
        body,
        {
          headers: {
            "Content-Profile": "brite",
            Prefer: "return=representation",
          },
        }
      );

      // if we have a suggestion, we should set the product_field_history_id on the suggestion
      if (
        suggestion &&
        suggestion.status === "completed" &&
        resp?.data?.length > 0
      ) {
        if (suggestion.product_field_history_id) {
          return;
        }
        await CustomAxios.put(
          `/v1/bp/autofill_suggestion?id=eq.${suggestion.id}`,
          {
            ...suggestion,
            product_field_history_id: resp.data[0].id,
          },
          {
            headers: {
              "Content-Profile": "brite",
            },
          }
        );
      }

      historyQuery.refetch();
    } catch (err) {
      console.warn(err);
    }
  };

  useEffect(() => {
    if (state?.hasChanges) {
      saveProduct();
    } else if (
      !!media?.state?.selectedCell &&
      lastCellRef !== media?.state?.selectedCell?.ref
    ) {
      saveFieldHistory();
    }
  }, [media?.state?.fieldIdx]);

  useEffect(() => {
    mediaModifiers.setFieldHistory(media, {
      fieldHistory,
      productId: state?.productId,
      mediaId: media?.state?.mediaId,
      property: current?.field?.PropertyChain,
    });
  }, [fieldHistory?.length, state?.productId]);

  // ****************************************************
  // ** Keyboard events
  // ****************************************************

  const keydown = (captured, event) => {
    if (flagModal) {
      return;
    }
    if (!document.activeElement.hasAttribute("data-field")) {
      if (captured === "+ArrowRight" || captured === "+Tab") {
        event.preventDefault();
        mediaModifiers.setFieldIdx(media, fieldIdx + 1);
      } else if (captured === "+ArrowLeft" || captured === "shift+Tab") {
        event.preventDefault();
        mediaModifiers.setFieldIdx(media, fieldIdx - 1);
      }
    }
  };

  useKeyboard({ keydown });

  const hasLocationFocus = !!Object.keys(media?.state?.focus?.location || {})
    ?.length;

  const scrollToCell = () => {
    if (hasLocationFocus) {
      mediaModifiers.mergeFocus(media, { action: "set" });
    }
  };

  const currentSuggestion = useMemo(() => {
    return aiSuggestions?.lookup(current?.field?.PropertyChain);
  }, [current?.field?.PropertyChain]);

  return (
    <Div
      onDoubleClick={(e) => e.stopPropagation()}
      css={css`
        position: fixed;
        bottom: 0;
        left: 0;
        right: 0;
        padding: 96px 32px;
        padding-bottom: 48px;
        z-index: 100000000000;
        pointer-events: none;
        use-select: none;
        ${flex("center")}
        cursor: pointer;
      `}
    >
      <Div
        css={css`
          ${animation("fadeIn", ".35s ease")}
          position: relative;
          width: 1000px;
          background-color: white;
          border-radius: 16px;
          padding: 16px 24px;
          pointer-events: auto;
          ${media.state?.overlay?.display
            ? `
            outline: 4px solid ${colors.purple};
          `
            : ""}
          ${shadows.lg}
        `}
      >
        {media?.state?.fileType === "xlsx" ? (
          <Tooltip title="Scroll To Cell">
            <div
              onClick={scrollToCell}
              className={css`
                position: absolute;
                top: 16px;
                left: 32px;
                width: 32px;
                height: 12px;
                ${hasLocationFocus
                  ? `background-color: ${colors.purple};`
                  : `background-color: ${colors.gray[300]};`}
                border-radius: 4px;
              `}
            />
          </Tooltip>
        ) : null}
        <Div
          css={css`
            position: absolute;
            bottom: 0;
            left: 4px;
            right: 4px;
            height: 6px;
            border-bottom-left-radius: 1000px;
            border-bottom-right-radius: 1000px;
            overflow: hidden;
            ::after {
              content: "div";
              position: absolute;
              bottom: 0;
              left: 0;
              right: 0;
              height: 100%;
              width: ${(100 * (fieldIdx + 1)) / fieldsList?.length}%;
              transition: width 0.25s linear;
              background-color: ${colors.purple};
            }
          `}
        />
        <Div
          css={css`
            ${flex("left start")}
            width: 100%;
          `}
        >
          <Div
            css={css`
              width: calc((100% - 400px) / 2);
              min-height: 64px;
              padding-top: 16px;
            `}
          >
            <Div
              css={css`
                ${container.hover}
                ${flex("space-between start")}
                position: relative;
                padding: 16px;
                border-radius: 8px;
                width: 100%;
                svg {
                  margin-top: 2px;
                }
              `}
              onClick={() => setFieldMenu(!fieldMenu)}
              ref={ref}
            >
              <Text h4>
                {fieldsList?.[media?.state?.fieldIdx]?.field?.DisplayValue}
              </Text>
              {fieldMenu ? (
                <CaretUp color={colors.black} weight="bold" size={20} />
              ) : (
                <CaretDown color={colors.black} weight="bold" size={20} />
              )}
              <Div
                css={css`
                  position: absolute;
                  bottom: calc(100% + 16px);
                  left: 0;
                  max-height: 50vh;
                  overflow: auto;
                  ${scrollbar.style}
                  ${container.box}
                  padding: 8px;
                  padding-top: 8px;
                  transition: all 0.2s ease;
                  ${fieldMenu
                    ? `
                      ${animation("fadeIn", ".2s ease forwards")}
                    `
                    : `
                      opacity: 0;
                      display: none;
                    `}
                `}
              >
                {fieldsList?.map((item, idx) => {
                  const itemSuggestion = aiSuggestions?.lookup(
                    item?.field?.PropertyChain
                  );
                  return (
                    <div
                      className={css`
                        padding: 8px 16px;
                        ${flex("left")}
                        border-radius: 8px;
                        * {
                          pointer-events: none;
                        }
                        ${container.hover}
                        ${item?.field?.PropertyChain ===
                        current?.field?.PropertyChain
                          ? `
                            background-color: ${colors.gray[200]};
                          `
                          : ""}
                      `}
                      onClick={() => mediaModifiers.setFieldIdx(media, idx)}
                    >
                      <Text
                        label
                        css={`
                          width: 100%;
                          margin-right: 16px;
                          min-width: max-content;
                          flex-grow: 1;
                          ${flex("space-between")}
                        `}
                        ellipsis
                      >
                        {item?.field?.DisplayValue}{" "}
                      </Text>
                      <SuggestionStatusIcon suggestion={itemSuggestion} />
                    </div>
                  );
                })}
              </Div>
            </Div>
            <Text
              css={`
                margin-left: 16px;
              `}
            >
              {fieldIdx + 1} / {fieldsList?.length}
            </Text>
          </Div>

          <Div
            css={css`
              width: 400px;
              padding: 16px;
              ${flex("left start")}
              .suggestion-style {
                min-width: 32px;
                margin-top: 16px;
              }
              .layout-item {
                background-color: transparent;
                ${isFieldIdxSynced
                  ? animatedInput || `transition: background-color .2s ease;`
                  : ""}
              }
            `}
          >
            <SuggestionStatusIcon
              suggestion={currentSuggestion}
              css={`
                margin-top: 24px;
                margin-right: 8px;
              `}
            />
            {current?.field?.Type === "auto-fill-tier-structure" ? (
              <Select
                css={`width: 50%:`}
                value={fieldProps?.value?.length}
                onChange={(e) => setTierIndex(e.target.value)}
              >
                {Array.apply(null, Array(4))?.map((_, index) => (
                  <MenuItem value={index + 2}>
                    {index + 2} Tier{index === 2 ? " (Standard)" : ""}
                  </MenuItem>
                ))}
              </Select>
            ) : (
              <FieldTypes
                state={state}
                fieldProps={fieldProps}
                searchTerm={searchTerm}
              />
            )}
          </Div>

          <Div
            css={css`
              ${flex("space-around")}
              width: calc((100% - 400px) / 2);
              padding-top: 16px;
              button {
                width: 45%;
              }
            `}
          >
            <Button
              secondary
              disabled={fieldIdx === 0}
              onClick={() => {
                mediaModifiers.setFieldIdx(media, fieldIdx - 1);
              }}
            >
              Back
            </Button>
            <Button
              purple
              onClick={() => {
                if (fieldIdx === media?.state?.maxFieldIdx) {
                  saveProduct();
                  mediaModifiers.setComplete(media, true);
                } else {
                  mediaModifiers.setFieldIdx(media, fieldIdx + 1);
                }
              }}
            >
              Next
            </Button>
          </Div>
        </Div>
      </Div>
    </Div>
  );
};

const SuggestionStatusIcon = ({ css: cssString = "", suggestion }) => {
  return (
    <span
      className={css`
        ${flex("center")}
        z-index: 10000;
        ${cssString}
      `}
    >
      {suggestion?.status === "completed" ? (
        <Tooltip title="Suggestion" placement="top">
          <StarFour size={14} color={colors.purple} weight="fill" />
        </Tooltip>
      ) : suggestion?.status === "pending" ? (
        <Tooltip title="Search for a suggestion..." placement="top">
          <span>
            <BriteLoader size={24} weight={12} overlay={false} />
          </span>
        </Tooltip>
      ) : null}
    </span>
  );
};
