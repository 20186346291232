import { MenuItem } from "@material-ui/core";
import { css } from "emotion";
import { CaretDown, CreditCard } from "phosphor-react";
import { useFeatureFlagPayload } from "posthog-js/react";
import { useEffect, useMemo, useRef, useState } from "react";
import { PRODUCT_HEADERS } from "../../../../benefit-package/constants";
import {
  getProductPremium,
  currencyFormatterCents,
} from "../../../../products/field-components/field-utils";
import { useQueryAPI } from "../../../../react-query";
import { Button, Div, DropMenu, Text } from "../../../../shared/components";
import { container, flex } from "../../../../shared/shared-styles";
import { colors } from "../../../../shared/styles";
import { useEditorResource } from "../../../use-editor-resource";
import {
  getTierStructure,
  useDisplaySettings,
} from "../plan-comparisons/use-display-settings";

const toTitleCase = (value) => {
  return value
    .split(" ")
    ?.map((word) => {
      const char = word.at(0)?.toUpperCase();
      return char + word.slice(1);
    })
    .join(" ");
};

export const contributionKeys = [
  "EmployeeOnly",
  "EmployeeSpouse",
  "EmployeeChildren",
  "Family",
];

const displayIntervals = {
  PPP: "Pay Period",
  MO: "Monthly",
};

export const CostSummary = ({ type, state, summary, viewMode }) => {
  const { variantStyle = "" } = summary;
  const { product, network, businessId, packageId } = state;

  const containerRef = useRef();
  const costTiersFF = useFeatureFlagPayload("tier-structure");
  const { displaySettings } = useDisplaySettings(businessId, packageId);
  const tiers = getTierStructure({
    featureFlag: costTiersFF?.value,
    cost: product?.Cost,
    displaySettings,
  });

  const [width, setWidth] = useState(700);

  const isProgramSummary = type === "hsa_buyup";

  const programQuery = useQueryAPI({
    url: `public/v1/business/${businessId}/programs`,
    enabled: product?.ID && isProgramSummary,
  });

  const { data: smartFields } = useEditorResource("liveSmartFields");

  const planId = useMemo(() => {
    if (
      smartFields?.[summary?.id]?.Success &&
      smartFields?.[summary?.id]?.Value
    ) {
      return smartFields?.[summary?.id]?.Value;
    }
    return summary?.id;
  }, [summary?.id, smartFields]);

  const programDependency = programQuery?.data?.map(({ ID }) => ID);
  const program = useMemo(() => {
    return (
      programQuery?.data?.filter(
        ({ ProgramType, PlanID }) =>
          ProgramType === "health_savings_account" && PlanID === planId
      )?.[0] || {}
    );
  }, [programDependency?.toString(), product, planId, isProgramSummary]);

  const [tab, setTab] = useState("interval");

  const coreData =
    product?.Type === "insurance_plan"
      ? network.getProductByCategory("core")
      : product;
  const productName =
    coreData?.ProductName || `${PRODUCT_HEADERS?.[product?.Type] || ""}`;

  const getContributionStyle = (idx) => {
    if (!variantStyle || variantStyle === "clean") {
      return css`
        border: 1px solid ${colors.gray[300]};
        .display-name {
          color: ${colors.black};
        }
        .modifier {
          color: ${colors.black};
        }
        .interval {
          color: ${colors.black};
        }
        .price {
          color: ${colors.black};
        }
      `;
    } else if (variantStyle === "bold") {
      const color = idx > 2 ? "var(--accent-color-0)" : "white";
      return css`
        background-color: var(--accent-color-${idx});
        .display-name {
          color: ${color};
        }
        .modifier {
          color: ${color};
        }
        .interval {
          color: ${color};
        }
        .price {
          color: ${color};
        }
      `;
    }
  };

  const isEmployerMatch =
    isProgramSummary &&
    program?.RawData?.Details?.ContributionType === "HSA - Employer Match";
  const widgetDescription = isProgramSummary
    ? isEmployerMatch
      ? "Employer Match"
      : "Employer Contributions"
    : "Pricing";

  const adjustedInterval = isEmployerMatch
    ? 12
    : displaySettings?.premiumInterval;
  const tabInterval = tab === "interval" ? adjustedInterval : 1;
  const premiumIntervalDisplay = adjustedInterval === 12 ? "MO" : "PPP";
  const intervalDisplay = tabInterval === 1 ? "YR" : premiumIntervalDisplay;

  useEffect(() => {
    setWidth(containerRef?.current?.clientWidth || 0);
  }, [viewMode]);

  useEffect(() => {
    window.addEventListener("resize", (e) =>
      setWidth(containerRef?.current?.clientWidth || 0)
    );
    return () =>
      window.removeEventListener("resize", (e) =>
        setWidth(containerRef?.current?.clientWidth || 0)
      );
  }, []);

  const getPrice = (key) => {
    if (!isProgramSummary) {
      return getProductPremium({
        cost: product?.Cost,
        key,
        interval: tabInterval,
      });
    } else if (!isEmployerMatch) {
      let contribution = program?.RawData?.Details?.[`${key}`] || 0;
      contribution *= 12 / tabInterval;
      return currencyFormatterCents.format(contribution);
    } else if (isEmployerMatch) {
      let contribution =
        program?.RawData?.Details?.[`${key}EmployerMonthlyMatchMax`] || 0;
      contribution *= 12 / tabInterval;
      return currencyFormatterCents.format(contribution);
    }
  };

  const upToModifier = isEmployerMatch ? "Up To " : "";

  const HSAHelperText = () => {
    if (isEmployerMatch) {
      let contributions = {};
      tiers?.keys?.map((key) => {
        const contribution =
          program?.RawData?.Details?.[`${key}EmployerMonthlyMatch`] || 0;
        contributions = {
          ...contributions,
          [contribution]: [...(contributions?.[contribution] || []), key],
        };
      });
      const values = Object.entries(contributions);
      if (values.length > 1) {
        return (
          <Div css={css`margin: 8px; 0;`}>
            {values.map(([contribution, keys]) => (
              <Div
                css={css`
                  margin-bottom: 8px;
                `}
              >
                <Text bold>
                  {keys?.map((key) => tiers?.labels?.[key] || key).join(", ")}
                </Text>
                <Text>
                  For every $1 you contribute your employer will match $
                  {contribution}
                </Text>
              </Div>
            ))}
          </Div>
        );
      } else if (values.length === 1 && Number(values?.[0]?.[0] || 0) !== 1) {
        return (
          <Text
            css={`
              margin-top: 8px;
            `}
          >
            For every $1 you contribute your employer will match $
            {values?.[0]?.[0] || 0}
          </Text>
        );
      }
      return null;
    } else if (isProgramSummary) {
      return (
        <Text
          css={`
            margin: 8px 0;
          `}
        >
          A health savings account is a tax-advantaged medical savings account
          available to taxpayers in the United States who are enrolled in a
          high-deductible health plan.
        </Text>
      );
    }
    return null;
  };

  return (
    <Div
      ref={containerRef}
      css={css`
        ${container.box}
        width: 100%;
        padding: 24px;
      `}
    >
      <Div
        css={css`
          ${flex("space-between")}
          gap: 16px;
        `}
      >
        <Text
          css={`
            width: max-content;
            border-radius: 30px;
            background-color: var(--accent-color-4);
            color: var(--accent-color-1);
            padding: 4px 16px;
            font-weight: bold;
          `}
        >
          {widgetDescription}
        </Text>
        <DropMenu
          button={
            <Button
              secondary
              css={`
                text-transform: none;
                min-width: 120px;
                padding: 4px 8px;
              `}
            >
              {tab === "interval"
                ? displayIntervals[premiumIntervalDisplay]
                : "Yearly"}
              <CaretDown size={24} />
            </Button>
          }
        >
          {tab === "interval" ? (
            <MenuItem onClick={() => setTab("yearly")}>Yearly</MenuItem>
          ) : (
            <MenuItem onClick={() => setTab("interval")}>
              {displayIntervals[premiumIntervalDisplay]}
            </MenuItem>
          )}
        </DropMenu>
      </Div>
      <Div
        css={css`
          ${flex("left")}
        `}
      >
        {isProgramSummary ? (
          <>
            <CreditCard
              className={css`
                min-width: 32px;
                margin-right: 16px;
              `}
            />
            <Text
              h2
              css={`
                margin-right: 8px;
              `}
            >
              HSA -{" "}
            </Text>
          </>
        ) : null}
        <Text
          h2
          css={`
            color: ${colors.black};
            margin: 16px 0;
          `}
        >
          {productName}
        </Text>
      </Div>

      <Div
        css={css`
          padding: 16px 0;
          padding-bottom: 0;
          border-top: 1px solid ${colors.gray[300]};
          > div {
            flex: 1;
          }
          .contribution-container {
            ${flex("space-between")}
            padding: 16px 24px;
            border-radius: 8px;
            ${width < 500
              ? `
                min-width: 100%;
                margin-bottom: 8px;
              `
              : `
                min-width: calc(50% - 40px);
                margin-bottom: 8px;
              `}
          }
          .display-name {
            flex-grow: 1;
            padding-right: 16px;
          }
          .modifier {
            margin-right: 8px;
            font-size: 0.83em;
          }
          .interval {
            font-size: 0.83em;
            margin-left: 8px;
            min-width: max-content;
          }
        `}
      >
        {tiers?.keys?.map((key, idx) => (
          <Div
            key={key}
            className="contribution-container"
            css={getContributionStyle(idx)}
          >
            <Text label className="display-name">
              {tiers?.labels?.[key]}
            </Text>

            <Div
              css={css`
                ${flex("right")}
                width: max-content;
              `}
            >
              {upToModifier ? (
                <Text subtitle className="modifier">
                  {upToModifier}
                </Text>
              ) : null}
              <Text h2 className="price">
                {getPrice(key)}
              </Text>
              <Text subtitle className="interval">
                /{toTitleCase(intervalDisplay)}
              </Text>
            </Div>
          </Div>
        ))}
      </Div>

      <HSAHelperText />
    </Div>
  );
};
