import {
  InputAdornment,
  MenuItem,
  TextField,
  Tooltip,
} from "@material-ui/core";
import { css } from "emotion";
import { CaretDown } from "phosphor-react";
import { Div, DropMenu, Text, Slider } from "../../shared/components";
import { px, flex } from "../../shared/shared-styles";
import { colors } from "../../shared/styles";
import { useDebounce } from "../../shared/use-debounce";
import { useStateSync } from "../../shared/use-state-sync";
import { modifyContent } from "../provider/utils";
import { useEditorResource } from "../use-editor-resource";
import { ColorPicker } from "./menu-components";

const lineStyles = ["dotted", "dashed", "solid"];

export const LineToolbar = ({ editor, element, location }) => {
  const onUpdate = (updates) => modifyContent.merge(editor, location, updates);

  const { data: styles } = useEditorResource("styles");

  const styleObject = {
    ...styles?.[element?.component],
    ...element?.attributes?.style,
  };

  const rawWidth = styleObject?.width || "";
  const [width, setWidth] = useStateSync(
    Number(rawWidth.replace(/\D/g, "") || 0),
    [rawWidth]
  );

  const updateWidth = useDebounce((value) => {
    onUpdate({ attributes: { style: { width: `${value}%` } } });
  }, 25);

  const changeWidth = (value) => {
    setWidth(value);
    updateWidth(value);
  };

  const weight = (styleObject?.borderTopWidth || "").replace(/\D/g, "") || 0;
  const lineStyle = styleObject?.borderTopStyle;

  const changeLineStyle = (value) => {
    let style = { borderTopStyle: value };
    if (weight === "0") {
      style.borderTopWidth = "0";
    }
    onUpdate({ attributes: { style } });
  };

  const changeLineWidth = (event) => {
    event.stopPropagation();
    const value = event.target.value;
    let style = { borderTopWidth: `${Number(value)}px` };
    if (!value) {
      style.borderTopStyle = "solid";
    } else if (lineStyle === "none") {
      style.borderTopStyle = "none";
    }
    onUpdate({ attributes: { style } });
  };

  return (
    <Div
      css={css`
        ${flex("jcl aic")}
      `}
      onMouseDown={(e) => e.stopPropagation()}
    >
      <Div
        css={css`
          margin-right: ${px.md};
        `}
      >
        <Tooltip title="Weight" placement="top">
          <TextField
            type="number"
            InputProps={{ endAdornment: <InputAdornment>px</InputAdornment> }}
            style={{
              width: "100px",
              height: "40px",
              padding: 0,
              backgroundColor: "white",
            }}
            inputProps={{ style: { padding: "11px" } }}
            variant="outlined"
            value={weight}
            onChange={changeLineWidth}
          />
        </Tooltip>
      </Div>

      <Div
        css={css`
          ${flex("aic")} margin-right: ${px.md};
        `}
      >
        <DropMenu
          title="Line Style"
          button={
            <Div
              css={css`
                ${flex("jcsb aic")} text-transform: capitalize;
                width: 110px;
                outline: 1px solid ${colors.gray[300]};
                padding: 8px;
                border-radius: 8px;
              `}
            >
              <Text styles="label">{lineStyle}</Text>
              <CaretDown size={16} />
            </Div>
          }
        >
          {lineStyles.map((item) => (
            <MenuItem key={item} onClick={() => changeLineStyle(item)}>
              {item}
            </MenuItem>
          ))}
        </DropMenu>
      </Div>
      <Div
        css={css`
          margin-left: 8px;
        `}
      />
      <ColorPicker
        item={element}
        title="Line Color"
        updateColor={(borderTopColor) =>
          onUpdate({ attributes: { style: { borderTopColor } } })
        }
        currentColor={styleObject?.borderTopColor}
      />

      <Div
        css={css`
          margin-right: 8px;
        `}
      />

      <Slider
        disabled={weight === 0}
        aria-label="Width"
        min={0}
        max={100}
        step={1}
        value={width || 0}
        style={{ width: "120px" }}
        valueLabelDisplay="auto"
        onChange={(e, value) => changeWidth(value)}
      />
    </Div>
  );
};
