import { Tooltip } from "@material-ui/core";
import { css, cx } from "emotion";
import React, { forwardRef, useMemo } from "react";
import { container, flex } from "../shared-styles";
import { buildStyles, colors } from "../styles";

const disabled = `
  background: #D1DAE3;
  border-radius: 8px;
  border-color: transparent;
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 8px;
  color: #9AA7B5;
  cursor: default;
`;

const sharedStyle = `
  ${flex("center")}
  span {
    ${flex("center")}
  }
  padding: 8px 22px;
  gap: 8px;
  border-radius: 8px;
  font-family: 'Roboto';
  font-style: normal;
  font-weight: 700;
  font-size: 15px;
  line-height: 26px;
  letter-spacing: 0.46px;
  text-transform: uppercase;
  box-sizing: border-box;
  cursor: pointer;
  outline: none;
  min-width: max-content;
`;

const iconStyles = `
  ${flex("center")}
  span { ${flex("center")} }
  outline: none;
  border-radius: 50%;
  width: 48px;
  height: 48px;
  font-size: 32px;
  border: none;
  background: none;
  transition: background-color .2s ease;
  :hover { background-color: ${colors.gray[200]}; }
  :disabled { background: none; cursor: default; opacity: 30%; }
  cursor: pointer;
`;

export const buttonStyles = {
  primary: `
    ${sharedStyle}
    background: #25282D;
    border: none;
    color: #FFFFFF;
    :disabled { ${disabled} }
  `,
  secondary: `
    ${sharedStyle}
    border: 1px solid ${colors.gray[300]};
    background: white;
    color: #25282D;
    ${container.hover}
    :disabled { ${disabled} }

  `,
  secondaryLight: `
  ${sharedStyle}
    border: 1px solid transparent;
    background-color: ${colors.gray[100]};
    color: ${colors.black};
    ${container.hover}
    :hover {
      background-color: ${colors.gray[200]};
    }
  `,
  text: `
    ${sharedStyle}
    border-radius: 8px;
    color: #25282D;
    background: none;
    border: none;
    cursor: pointer;
    :hover { background-color: ${colors.gray[200]}; }
    :disabled { ${disabled} }
  `,
  light: `
    background-color: ${colors.gray[100]};
    color: ${colors.black};
    font-weight: normal;
    text-transform: none;
  `,
  purple: `
    background-color: ${colors.purple};
  `,
  red: `
    border: 1px ${colors.red[100]} solid;
    color: ${colors.red[100]};
    background-color: white;
    ${container.hover}
  `,
  redBg: `
    background-color: ${colors.red[100]};
  `,
  "tab-active": `
    ${sharedStyle}
    flex-grow: 1;
    border-radius: 0;
    color: #25282D;
    background: none;
    border: 1px solid transparent;
    border-bottom: 4px solid ${colors.black};
    cursor: pointer;
    border-radius: 8px;
    border-bottom-left-radius: 0;
    border-bottom-right-radius: 0;
    :hover { background-color: none; }
    :disabled { ${disabled} }
  `,
  "tab-inactive": `
    ${sharedStyle}
    flex-grow: 1;
    border-radius: 0;
    color: ${colors.gray[400]};
    background: none;
    border: 1px solid transparent;
    border-bottom: 4px solid transparent;
    cursor: pointer;
    border-radius: 8px;
    border-bottom-left-radius: 0;
    border-bottom-right-radius: 0;
    :hover {
      background-color: none;
      color: ${colors.gray[500]};
      border-bottom: 4px solid ${colors.gray[300]}
    }
    :disabled { ${disabled} }
  `,
  icon: iconStyles,
  "icon-active": `
    ${iconStyles}
    background-color: ${colors.gray[100]};
    border: 1px solid ${colors.gray[200]};
    :hover { background: ${colors.gray[300]}; }
  `,
  sm: `
    width: 32px;
    height: 32px;
    font-size: 24px;
    padding: 0;
    margin: 0;
    svg { padding: 0; margin: 0; } 
  `,
  "icon-dark": `
    outline: none;
    border-radius: 50%;
    width: 16px;
    height: 16px;
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: 16px;
    border: none;
    background: ${colors.gray[500]};
    :hover { background-color: ${colors.black}; }
    :disabled { background: none; cursor: default; }
    cursor: pointer;
  `,
  link: `
  ${sharedStyle}
  padding: 0;
  color: ${colors.purple};
  background: none;
  text-align: left;
  border: none;
  text-transform: none;
  :hover {
    background: none;
    text-decoration: underline;
  }
`,
  "pill-active": `
    padding: 4px 16px;
    color: ${colors.black};
    background-color: ${colors.gray[100]};
    border-radius: 8px;
    text-transform: none;
    font-weight: normal;
  `,
  "pill-inactive": `
    padding: 4px 16px;
    color: ${colors.black};
    background-color: transparent;
    border-radius: 8px;
    text-transform: none;
    font-weight: normal;
    :hover { background-color: ${colors.gray[100]}; }
  `,
  naked: `
    span { ${flex("center")} }
    color: #25282D;
    border: none;
    background: none;
    outline: none;
    font-size: 1em;
    text-align: center;
    cursor: pointer;

    :disabled { background: none; cursor: default; }
  `,
  large: `padding: 16px 32px;`,
  small: `padding: 4px 8px;`,
};

const allAttributes = Object.keys(buttonStyles);
const fallbackFromList = allAttributes;
const checkForFallback = (styles) => {
  const list = fallbackFromList.filter((attr) => styles.includes(attr));
  if (!list.length) {
    return `primary ${styles}`;
  }
  return styles;
};

export const Button = forwardRef(
  (
    {
      styles = "primary",
      className = "",
      css: cssStyles = "",
      children,
      color,
      bgColor,
      hoverLabel = "",
      hoverPlacement = "top",
      conditionalStyle = [],
      ...rest
    } = {},
    ref
  ) => {
    const keys = Object.entries(rest || {});
    const { restProps, styleProps } = useMemo(() => {
      const filtered = keys.reduce(
        (prev, entry) => {
          const [attr, value] = entry;
          const property =
            allAttributes.includes(attr) && value ? "styleProps" : "restProps";
          return { ...prev, [property]: [...prev?.[property], entry] };
        },
        { restProps: [], styleProps: [] }
      );
      return {
        restProps: Object.fromEntries(filtered.restProps),
        styleProps: filtered.styleProps,
      };
    });

    const styleProperty = useMemo(() => {
      const list = styleProps.map(([attr]) => attr);
      let extraStyles = styles + " " + list.join(" ");
      if (conditionalStyle.length) {
        const [value, check, style] = conditionalStyle;
        extraStyles +=
          ` ${style}` + (value === check ? "-active" : "-inactive");
      }
      const taggedStyle = checkForFallback(extraStyles);
      return cx(
        css`
          ${buildStyles(buttonStyles, taggedStyle)}
          ${color ? `color: ${color}` : ""}
          ${bgColor ? `background-color: ${bgColor}` : ""}
        `,
        css`
          ${cssStyles}
        `,
        className
      );
    }, [
      styles,
      cssStyles,
      styleProps,
      className,
      color,
      bgColor,
      conditionalStyle.toString(),
    ]);

    const props = {
      type: "button",
      className: styleProperty,
      ...restProps,
      ref,
    };

    if (hoverLabel) {
      return (
        <button {...props}>
          <Tooltip title={hoverLabel} placement={hoverPlacement}>
            {children}
          </Tooltip>
        </button>
      );
    } else {
      return <button {...props}>{children}</button>;
    }
  }
);
