import React, { useEffect } from "react";
import { useLocation } from "react-router-dom";
import posthog from "../lib/analytics/posthog";

export const PageViewAnalytics = () => {
    const location = useLocation();
    useEffect(() => {
        if (!location) {
            return;
        }
        posthog.capture('$pageview');   
    }, [location]);

    return <></>;
}