import { useEffect, useState } from "react";
import { history } from "../history";

export const getParams = (location) => {
  return Object.fromEntries(new URLSearchParams(location?.search).entries());
};

export const setParams = (params) => {
  const update = new URLSearchParams(params);
  history.replace({ search: update.toString() });
  return params;
};

export const updateParams = (location, params) => {
  const current = getParams(location);
  const updates = { ...current, ...params };
  setParams(updates);
  return updates;
};

export const useSearchParams = () => {
  const location = window.location;
  const [params, setParamsObj] = useState(getParams(location));

  const setParamsIntermediate = (params) => {
    const newParams = setParams(params);
    setParamsObj(newParams);
  };

  const updateParamsIntermediate = (params) => {
    const newParams = updateParams(location, params);
    setParamsObj(newParams);
  };

  const removeParam = (...param) => {
    let next = { ...params };
    for (const item of param) {
      delete next[item];
    }
    setParams(next);
    setParamsObj(next);
  };

  useEffect(() => {
    const current = getParams(location);
    setParamsObj(current);
  }, [location.search]);

  return {
    params,
    removeParam,
    setParams: setParamsIntermediate,
    updateParams: updateParamsIntermediate,
  };
};
