import {
  CompositeDecorator,
  EditorState,
  Modifier,
  SelectionState,
} from "draft-js";
import Immutable from "immutable";
import { Link } from "./decorators/link";
import { Tag } from "./decorators/tag";

import { convertToHTML } from "draft-convert";

// Custom Inline Styles
export const inlineStyles = {
  HIGHLIGHT: {
    backgroundColor: "rgba(0, 0, 0, .1)",
  },
  STRIKETHROUGH: {
    textDecoration: 'line-through',
  },
  SUPERSCRIPT: {
    fontSize: ".83em",
    verticalAlign: "super",
  },
  SUBSCRIPT: {
    fontSize: ".83em",
    verticalAlign: "sub",
  },
};

// Custom Block Styles
export const blockStyles = {
  "CENTER-ALIGN": `text-align: center; .public-DraftStyleDefault-block { text-align: center; }`,
  "LEFT-ALIGN": `text-align: left; .public-DraftStyleDefault-block { text-align: left; }`,
  "RIGHT-ALIGN": `text-align: right; .public-DraftStyleDefault-block { text-align: right; }`,
};

export function blockStyleFn(contentBlock) {
  const type = contentBlock.getType();
  if (type in blockStyles) {
    console.log(type);
    return type;
  }
}

// Entity Decorators

const findEntityByName = (entity) => {
  return function (contentBlock, callback, contentState) {
    contentBlock.findEntityRanges((character) => {
      const entityKey = character.getEntity();
      return (
        entityKey !== null &&
        contentState.getEntity(entityKey).getType() === entity
      );
    }, callback);
  };
};

const findEntityByRegex = (regex) => {
  return function (contentBlock, callback) {
    const text = contentBlock?.getText();
    let index = 0;
    const matches = text.match(regex) || [];
    for (const match of matches) {
      try {
        const start = text.indexOf(match, index);
        const end = start + match.length;
        callback(start, end);
        index = end;
      } catch (err) {
        console.log(err);
        index += match?.length;
      }
    }
  };
};

export const patterns = {
  tags: /#\[(.*?)\]#/g,
};

const decoratorList = [
  [findEntityByName("LINK"), Link],
  [findEntityByRegex(patterns.tags), Tag],
].map(([strategy, component]) => ({ strategy, component }));

export const decorators = new CompositeDecorator(decoratorList);

// RAW DATA draft-js instance TO HTML: options

const unorderListStyles = [
  "disc",
  "circle",
  "square",
  "disc",
  "circle",
  "square",
];
const orderListStyles = [
  "gregorian",
  "lower-latin",
  "lower-roman",
  "gregorian",
  "lower-latin",
  "lower-roman",
];

export const getHtmlContent = (contentState, customInlineStyles) => {
  const inlineStylesValues = { ...inlineStyles, ...customInlineStyles };
  const content = convertToHTML({
    styleToHTML: (style) => {
      if (style in inlineStylesValues) {
        return <span style={inlineStylesValues[style]} />;
      }
    },
    blockToHTML: (block) => {
      const { type, depth } = block;

      if (type?.includes("ALIGN")) {
        const [value] = type.split("-");
        const textAlign = value.toLowerCase();
        return <p style={{ textAlign }} />;
      } else if (type === "unordered-list-item") {
        const listStyleType = unorderListStyles[depth];
        return {
          start: `<li style="margin-left: "${
            depth + 1
          }em"; list-style-type: ${listStyleType}; ">`,
          end: "</li>",
          nest: "<ul>",
          nestStart: "<ul>",
          nestEnd: "</ul>",
        };
      } else if (type === "ordered-list-item") {
        const listStyleType = orderListStyles[depth];
        return {
          start: `<li style="margin-left: "${
            depth + 1
          }em"; list-style-type: ${listStyleType}; ">`,
          end: "</li>",
          nest: "<ol>",
          nestStart: "<ol>",
          nestEnd: "</ol>",
        };
      }
    },
    entityToHTML: (entity, originalText) => {
      if (entity.type === "LINK") {
        const { linkText, ...attributes } = entity.data;
        return <a {...attributes}>{originalText}</a>;
      }
      return originalText;
    },
  })(contentState);

  const data = content.replace(patterns.tags, "$1");
  return data;
};

export const selectAll = (editorState) => {
  const currentContent = editorState.getCurrentContent();
  const firstBlock = currentContent.getBlockMap().first();
  const lastBlock = currentContent.getBlockMap().last();
  const firstBlockKey = firstBlock.getKey();
  const lastBlockKey = lastBlock.getKey();
  const lengthOfLastBlock = lastBlock.getLength();

  const selection = new SelectionState({
    anchorKey: firstBlockKey,
    anchorOffset: 0,
    focusKey: lastBlockKey,
    focusOffset: lengthOfLastBlock,
  });

  return EditorState.forceSelection(editorState, selection);
};

export const clearInlineStyles = (editorState, styleMatchFn) => {
  const selectionState = editorState.getSelection();
  const styles = selectionState.isCollapsed()
    ? editorState.getCurrentInlineStyle()
    : getSelectionInlineStyle(editorState);
  let contentState = editorState.getCurrentContent();
  for (const style of styles.toArray()) {
    const isMatch = styleMatchFn(style);
    if (isMatch) {
      contentState = Modifier.removeInlineStyle(
        contentState,
        selectionState,
        style
      );
    }
  }
  return EditorState.push(editorState, contentState, "change-inline-style");
};

export const getSelectionInlineStyle = (editorState) => {
  const currentSelection = editorState.getSelection();

  if (currentSelection.isCollapsed()) {
    return editorState.getCurrentInlineStyle();
  }

  const start = currentSelection.getStartOffset();
  const end = currentSelection.getEndOffset();
  const selectedBlocks = getSelectedBlocksMap(editorState).toList();

  let set = Immutable.OrderedSet();

  if (selectedBlocks.size > 0) {
    for (let i = 0; i < selectedBlocks.size; i += 1) {
      let blockStart = i === 0 ? start : 0;
      let blockEnd =
        i === selectedBlocks.size - 1
          ? end
          : selectedBlocks.get(i).getText().length;
      if (blockStart === blockEnd && blockStart === 0) {
        blockStart = 1;
        blockEnd = 2;
      } else if (blockStart === blockEnd) {
        blockStart -= 1;
      }
      for (let j = blockStart; j < blockEnd; j += 1) {
        set = set.merge(selectedBlocks.get(i).getInlineStyleAt(j));
      }
    }
  }

  return set;
};

const getSelectedBlocksMap = (editorState) => {
  const selectionState = editorState.getSelection();
  const contentState = editorState.getCurrentContent();
  const startKey = selectionState.getStartKey();
  const endKey = selectionState.getEndKey();
  const blockMap = contentState.getBlockMap();
  return blockMap
    .toSeq()
    .skipUntil((_, k) => k === startKey)
    .takeUntil((_, k) => k === endKey)
    .concat([[endKey, blockMap.get(endKey)]]);
};

export function getEntityRange(block, entityKey) {
  let entityRange;
  block.findEntityRanges(
    (value) => value.get("entity") === entityKey,
    (start, end) => {
      entityRange = {
        start,
        end,
        text: block.get("text").slice(start, end),
      };
    }
  );
  return entityRange;
}

export const iterateDecorations = (callback, state) => {
  const content = state?.getCurrentContent();
  const selection = state?.getSelection();
  const contentBlock = content?.getBlockForKey(selection?.anchorKey);
  const text = contentBlock?.getText();
  let index = 0;
  const matches = text.match(patterns.tags) || [];
  for (const match of matches) {
    try {
      const start = text.indexOf(match, index);
      const end = start + match.length;
      const shouldReturn = callback(start, end, selection);
      if (shouldReturn) {
        return [selection?.anchorKey, start, end];
      }
      index = end;
    } catch (err) {
      console.log(err);
      index += match?.length;
    }
  }
  return [];
};

export const getSelection = (anchor, focus = []) => {
  const [anchorKey, anchorOffset] = anchor;
  const [focusKey = anchorKey, focusOffset = anchorOffset] = focus;
  const selection = SelectionState?.createEmpty();
  return selection.merge({
    anchorKey,
    focusKey,
    anchorOffset,
    focusOffset,
  });
};

export const insertText = (state, selection, text) => {
  let content = state?.getCurrentContent();
  const nextContent = Modifier.insertText(content, selection, text);
  const nextState = EditorState.push(state, nextContent, "insert-text");
  return {
    nextState,
    nextContent,
  };
};

export const replaceText = (state, selection, text) => {
  let content = state?.getCurrentContent();
  const nextContent = Modifier.replaceText(content, selection, text);
  const nextState = EditorState.push(state, nextContent, "replace-text");
  return {
    nextState,
    nextContent,
  };
};

export const hasEdgeDecoration = (block, regex) => {
  const text = block.getText();
  const matches = text.match(regex) || [];

  let obj = { start: false, end: false };

  if (!matches.length) {
    return obj;
  }

  const [firstMatch = null] = matches;
  if (firstMatch !== null) {
    const start = text.indexOf(firstMatch, 0);
    if (start === 0) {
      obj.start = true;
    }
  }
  const lastMatch = matches.at(-1) || null;
  if (lastMatch !== null) {
    const end = text.lastIndexOf(lastMatch) + lastMatch.length;
    if (end === text.length) {
      obj.end = true;
    }
  }
  return obj;
};

export const edgeDecorations = (state) => {
  const lastSelection = state?.getSelection();
  const content = state?.getCurrentContent();
  const blocks = content?.getBlockMap();

  blocks.forEach((block) => {
    const { start, end } = hasEdgeDecoration(block, patterns.tags);
    const key = block.getKey();

    if (start) {
      const selection = getSelection([key, 0]);
      const { nextState } = insertText(state, selection, "\u200A");
      state = nextState;
    }

    if (end) {
      const selection = getSelection([key, block?.getLength() + 1]);
      const { nextState } = insertText(state, selection, " ");
      state = nextState;
      state = EditorState.forceSelection(state, lastSelection);
    }
  });

  return state;
};
