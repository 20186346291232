import { css } from "emotion";
import { Bug } from "phosphor-react";
import { useEffect, useState } from "react";
import { toast } from "react-toastify";
import { history } from "./history";
import { CustomAxios } from "./redux/axios/axios";
import { Button, Div, Loader, Text } from "./shared/components";
import { flex, px } from "./shared/shared-styles";
import { colors } from "./shared/styles";

const container = css`
  ${flex("ais jcc")}
  width: max-content;
  height: auto;
  margin-bottom: 10vh;
  border-radius: 16px;
  padding: 32px;
  background-color: white;
`;

export const ErrorFallback = ({ error, resetErrorBoundary }) => {
  const [loading, setLoading] = useState(false);
  const [randStr, setRandStr] = useState("");

  useEffect(() => {
    const rand = Math.random().toString(36).substring(7);
    setRandStr(rand);

    // always report uncaught error to stackdriver, add a random string so we can connect it to a user report if necessary
    if (error?.message) {
      error.message += ` | (${rand})`;
    } else {
      error += ` | (${rand})`;
    }

    console.error(error);
  }, []);

  const refresh = () => {
    setRandStr("");
    history.push("/");
    resetErrorBoundary();
  };

  const submitTicket = async () => {
    let logOutput = console?.allLogs?.toReversed().reduce((acc, item) => {
      if (!item || !item?.value || !item?.value?.[0]) {
        return acc;
      }

      let msg = item.value[0];

      // if item is an object, capture the value in a string
      if (typeof item.value[0] !== "string") {
        if (item.value[0].message) {
          msg = item.value[0].message;
        }

        if (item.type === "error" && item.value[0].stack) {
          msg += "\n" + item.value[0].stack.replace("\n", "\t\n");
        }
      }
      msg = msg.replace("\n", "\t\t\n");

      return acc + `${item.datetime}  ${item.type}  ${msg}` + "\n";
    }, "Logs (descending) (" + randStr + "): \n\n");

    const requestErrors = [...console.requestErrors].map((item) => {
      const url = item?.config?.url;
      const { Authorization, ...headers } = item?.config?.headers || {};
      return {
        message: item?.message,
        method: item.config?.method,
        headers,
        url,
        response: item?.response?.data,
      };
    });

    let errorStr = error?.message || error;
    const BrowserContext = {
      error: errorStr,
      requestErrors,
      timestamp: new Date().toISOString(),
      pathname: `https://app.be-brite.com${history?.location?.pathname}`,
    };

    try {
      setLoading(true);
      const data = {
        UserTitle: error?.message || error,
        ErrorLogURL: `https://console.cloud.google.com/errors;filter=%5B%22${randStr}%22%5D?project=be-brite`,
        LogOutput: logOutput,
        BrowserContext,
        TicketType: "bug",
      };
      await CustomAxios.post(`v1/app-feedback/tickets`, data);
      toast.success("Thanks for reporting the issue");
      refresh();
    } catch (err) {
      toast.error("Error sending ticket...");
    } finally {
      setRandStr("");
      setLoading(false);
    }
  };

  return (
    <Div
      className={css`
        ${flex("jcc aic")}width: 100vw;
        height: 100vh;
        background-color: ${colors.gray[100]};
      `}
    >
      <Div css={container}>
        {loading ? (
          <Loader />
        ) : (
          <>
            <Bug size={64} />
            <Div
              css={css`
                margin-left: ${px.md};
              `}
            >
              <Text h1>Uh oh, something went wrong!</Text>
              <Div
                css={css`
                  ${flex("aic jcr")} margin-top: ${px.xl};
                `}
              >
                <Button styles="secondary mr-xl" onClick={submitTicket}>
                  Report
                </Button>
                <Button onClick={refresh}>Refresh App</Button>
              </Div>
            </Div>
          </>
        )}
      </Div>
    </Div>
  );
};
