import { Typography, IconButton, MenuItem, TextField } from "@material-ui/core";
import { css } from "emotion";
import { DotsThree } from "phosphor-react";
import React, { useEffect, useMemo, useState } from "react";
import { history } from "../history";
import { DropMenu } from "../shared/components/dropdown-menu";
import { useStore } from "../store-provider/use-store";
import { AddMember } from "./add-member";
import { useBusinessAuth } from "./use-business-auth";
import { Div } from "../shared/components/div";
import { container } from "../shared/shared-styles";

const companyContainer = css`
  display: flex;
  justify-content: space-between;
  align-items: center;
  height: 56px;
  padding: 32px 16px;
  padding-right: 0;
  cursor: pointer;
  border-radius: 4px;
  :hover {
    background-color: #f5f7fa;
  }
  .name {
    flex-grow: 1;
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding-right: 32px 0;
  }
  .btn {
    display: flex;
    justify-content: right;
    width: 90px;
  }
  img {
    max-width: 100px;
    max-height: 40px;
  }
`;

export const Dashboard = () => {
  const [filter, setFilter] = useState("");
  const [addMember, setAddMember] = useState("");
  const {
    data: { companies, acls },
    setStore,
  } = useStore();
  const { setBusiness, fetchBusinesses } = useBusinessAuth();

  useEffect(() => {
    fetchBusinesses();
    setStore("selectedBusiness", {});
  }, []);

  const handleBusinessChange = (business, path = "") => {
    setBusiness(business);
    if (!path) {
      history.push(`/${business.ID}/courses`);
    } else {
      history.push(path);
    }
  };

  const handleSettings = (business) => {
    handleBusinessChange(business, `/${business.ID}/settings`);
  };

  const companiesByGroup = useMemo(() => {
    let list = companies;
    if (filter) {
      list = companies.filter(({ Name }) =>
        Name.toLowerCase().includes(filter.toLowerCase())
      );
    }

    list = list.sort((a, b) => {
      return a?.Name?.localeCompare(b?.Name);
    });

    return list.reduce((prev, item) => {
      const data = prev[item.ParentID] || {};
      if (item.ParentID === item.ID) {
        return {
          ...prev,
          [item.ParentID]: { ...data, parent: item },
        };
      }
      return {
        ...prev,
        [item.ParentID]: {
          ...(prev[item.ParentID] || {}),
          children: [...(data.children || []), item],
        },
      };
    }, {});
  }, [filter, companies.length]);

  const checkAccess = (business) => {
    const id = business.ID in acls ? business.ID : business.ParentID;
    if (id in acls) {
      return acls[id].Acls.includes("write:business_access");
    }
  };

  return (
    <div>
      <Div
        css={css`
          ${container.header}
        `}
      >
        <h1>My Dashboard</h1>
        <div
          className={css`
            display: flex;
            justify-content: space-between;
            align-items: center;
          `}
        >
          <TextField
            onChange={(e) => setFilter(e.target.value)}
            value={filter}
            placeholder="Search"
            style={{ margin: "0 16px", width: "250px", borderRadius: "16px" }}
            inputProps={{ style: { padding: "16px" } }}
            variant="outlined"
          />
        </div>
      </Div>

      <div
        className={css`
          margin: 32px 0;
          max-width: 1000px;
          padding-bottom: 100px;
        `}
      >
        <div
          className={css`
            display: flex;
            justify-content: space-between;
            border-bottom: 1px solid #d1dae3;
            padding: 8px 16px;
          `}
        >
          <Typography style={{ fontSize: "16px", color: "#66737F" }}>
            Company
          </Typography>
          <Typography style={{ fontSize: "16px", color: "#66737F" }}>
            Options
          </Typography>
        </div>

        {Object.entries(companiesByGroup).map(
          ([parentID, { parent, children }]) => (
            <div
              key={parentID}
              className={css`
                border-bottom: 1px solid #d1dae3;
                padding: 8px 0;
              `}
            >
              {parent && (
                <div
                  className={companyContainer}
                  onClick={() => handleBusinessChange(parent)}
                >
                  <div className="name">
                    <Typography
                      style={{ fontWeight: "bold", fontSize: "18px" }}
                    >
                      {parent.Name}
                    </Typography>
                    {parent.LogoURL && <img src={parent.LogoURL} />}
                  </div>
                  <div className="btn">
                    <DropMenu
                      title="Options"
                      button={
                        <IconButton>
                          <DotsThree />
                        </IconButton>
                      }
                    >
                      <MenuItem
                        onClick={() => setAddMember(parent)}
                        disabled={!checkAccess(parent)}
                      >
                        Add Member
                      </MenuItem>
                      <MenuItem onClick={() => handleSettings(parent)}>
                        Settings
                      </MenuItem>
                    </DropMenu>
                  </div>
                </div>
              )}
              {children &&
                children.length &&
                children.map((item) => (
                  <div
                    className={companyContainer}
                    key={item.ID}
                    onClick={() => handleBusinessChange(item)}
                  >
                    <div className="name">
                      <Typography
                        style={{ color: "#66737F", fontSize: "18px" }}
                      >
                        {item.Name}
                      </Typography>
                      {item.LogoURL && <img src={item.LogoURL} />}
                    </div>
                    <div className="btn">
                      <DropMenu
                        title="Options"
                        button={
                          <IconButton>
                            <DotsThree />
                          </IconButton>
                        }
                      >
                        <MenuItem
                          onClick={() => setAddMember(item)}
                          disabled={!checkAccess(item)}
                        >
                          Add Member
                        </MenuItem>
                        <MenuItem onClick={() => handleSettings(item)}>
                          Settings
                        </MenuItem>
                      </DropMenu>
                    </div>
                  </div>
                ))}
            </div>
          )
        )}
      </div>

      <AddMember
        display={!!addMember.ID}
        defaultBusiness={addMember}
        onClose={() => setAddMember({})}
      />
    </div>
  );
};
