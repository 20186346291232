import { addYears } from "date-fns";

export const surestPlanType = "SUREST (ON DEMAND)";
export const acaSmallGroupHDHPType = "ACAPLANS-HDHP";
export const acaSmallGroupPPOType = "ACAPLANS-PPO";
export const acaSmallGroupEPOType = "ACAPLANS-EPO";
export const acaSmallGroupHMOType = "ACAPLANS-HMO";
export const acaSmallGroupType = "ACAPLANS";
export const ichraPPOType = "ICHRA-PPO";
export const ichraHDHPType = "ICHRA-HDHP";
export const ichraEPOType = "ICHRA-EPO";
export const ichraHMOType = "ICHRA-HMO";
export const ichraType = "ICHRA";

// not exported
const bindDeprecatedPlanType = "BIND (ON DEMAND)";

export const isSurestPlan = (planType) => {
  if (!planType) {
    return false;
  }
  return (
    planType.toUpperCase() === bindDeprecatedPlanType ||
    planType.toUpperCase() === surestPlanType
  );
};

export const isSmallGroupPlan = (planType) => {
  return planType.toUpperCase().startsWith(acaSmallGroupType);
};

export const isIchraPlan = (planType) => {
  return planType.toUpperCase().startsWith(ichraType);
};

export const isPPOPlan = (planType) => {
  // EPO and HMO are treated as PPO plans for now
  if (
    planType.toUpperCase() === acaSmallGroupPPOType ||
    planType.toUpperCase() === "PPO" ||
    planType.toUpperCase() === "EPO" ||
    planType.toUpperCase() === "HMO"
  ) {
    return true;
  }
  return false;
};

export const newPlan = {
  Name: "",
  CarrierID: "00000000-0000-0000-0000-000000000000",
  MultiNetworkID: "", // group the plans by this if Type is not default.
  MultiNetworkCategory: "default", // buyup, core, narrow, default,
  PlanType: "",
  ApplicableStates: ["ALL"],
  ApplicableZipCodes: [],
  EmployeeContributionType: "%",
  EffectiveDate: new Date().toISOString(),
  DeductibleResetDate: addYears(new Date(), 1).toISOString(),
  BusinessID: "",
  PageID: "00000000-0000-0000-0000-000000000000",
  FieldsThatNeedAttention: [],
  HSACompatible: false,
  HSAContributionType: "HSA - No Employer Contribution",
  HideEmployerPremiumContributions: false,
  NetworkInformation: {
    Name: "",
    ProviderSearchURL: "",
    Description: "",
  },
  InNetworkPlanDesign: {
    IsSingle: false,
    SingleDeductible: 0,
    SingleOOPM: 0,
    Deductible: 0,
    OOPM: 0,
    Coinsurance: 1,
    EmbeddedDeductibleOOPM: "No",
  },
  InNetworkProfessionalServices: {
    InPatientHospital: {
      Value: 0,
      PercentOrDollar: "%",
      ADOrCopay: "AD",
      SpecialCopay: 0,
    },
    OutPatientSurgery: {
      Value: 0,
      PercentOrDollar: "%",
      ADOrCopay: "AD",
      SpecialCopay: 0,
    },
    PrimaryCarePhysician: {
      Value: 0,
      PercentOrDollar: "%",
      ADOrCopay: "AD",
      SpecialCopay: 0,
    },
    Specialist: {
      Value: 0,
      PercentOrDollar: "%",
      ADOrCopay: "AD",
      SpecialCopay: 0,
    },
    PreventativeCare: {
      Value: 0,
      PercentOrDollar: "",
      ADOrCopay: "100% Covered",
      SpecialCopay: 0,
    },
    MentalHealthOutpatient: {
      Value: 0,
      PercentOrDollar: "%",
      ADOrCopay: "AD",
      SpecialCopay: 0,
    },
    TeleMedicine: {
      Value: 0,
      PercentOrDollar: "%",
      ADOrCopay: "AD",
      SpecialCopay: 0,
    },
    UrgentCare: {
      Value: 0,
      PercentOrDollar: "%",
      ADOrCopay: "AD",
      SpecialCopay: 0,
    },
    EmergencyRoom: {
      Value: 0,
      PercentOrDollar: "%",
      ADOrCopay: "AD",
      SpecialCopay: 0,
    },
  },
  PrescriptionDrugs: {
    PrescriptionDrugTier: "",
    MailOrder: "",
    Tier1: {
      Value: 0,
      PercentOrDollar: "%",
      ADOrCopay: "AD",
      SpecialCopay: 0,
    },
    Tier2: {
      Value: 0,
      PercentOrDollar: "%",
      ADOrCopay: "AD",
      SpecialCopay: 0,
    },
    Tier3: {
      Value: 0,
      PercentOrDollar: "%",
      ADOrCopay: "AD",
      SpecialCopay: 0,
    },
    Tier4: {
      Value: 0,
      PercentOrDollar: "%",
      ADOrCopay: "AD",
      SpecialCopay: 0,
    },
    Tier5: {
      Value: 0,
      PercentOrDollar: "%",
      ADOrCopay: "AD",
      SpecialCopay: 0,
    },
    Tier6: {
      Value: 0,
      PercentOrDollar: "%",
      ADOrCopay: "AD",
      SpecialCopay: 0,
    },
    MedicalDeductibleApplies: {
      Value: -1,
      PercentOrDollar: "$",
      ADOrCopay: "AD",
      SpecialCopay: 0,
    },
  },
  TotalMonthlyPremiums: {
    EmployeeOnly: 0,
    EmployeeSpouse: 0,
    EmployeeChildren: 0,
    Family: 0,
  },
  EmployeeContributions: {
    EmployeeOnly: 0,
    EmployeeSpouse: 0,
    EmployeeChildren: 0,
    Family: 0,
  },
  HSAContributions: {
    EmployeeOnly: 0,
    EmployeeSpouse: 0,
    EmployeeChildren: 0,
    Family: 0,
    EmployeeOnlyEmployerMonthlyMatch: 0,
    EmployeeSpouseEmployerMonthlyMatch: 0,
    EmployeeChildrenEmployerMonthlyMatch: 0,
    FamilyEmployerMonthlyMatch: 0,
    EmployeeOnlyEmployerMonthlyMatchMax: 0,
    EmployeeSpouseEmployerMonthlyMatchMax: 0,
    EmployeeChildrenEmployerMonthlyMatchMax: 0,
    FamilyEmployerMonthlyMatchMax: 0,
  },
  SurestCopays: {
    PrimaryCareLower: 0,
    PrimaryCareUpper: 0,
    SpecialistLower: 0,
    SpecialistUpper: 0,
    InpatientHospitalLower: 0,
    InpatientHospitalUpper: 0,
    OutpatientSurgeryLower: 0,
    OutpatientSurgeryUpper: 0,
    UrgentCareLower: 0,
    UrgentCareUpper: 0,
    MentalHealthUpper: 0,
    MentalHealthLower: 0,
    TeleMedicineUpper: 0,
    TeleMedicineLower: 0,
    EmergencyRoomLower: 0,
    EmergencyRoomUpper: 0,
    Tier1Lower: 0,
    Tier1Upper: 0,
    Tier2Lower: 0,
    Tier2Upper: 0,
    Tier3Lower: 0,
    Tier3Upper: 0,
    Tier4Lower: 0,
    Tier4Upper: 0,
    Tier5Lower: 0,
    Tier5Upper: 0,
    Tier6Lower: 0,
    Tier6Upper: 0,
    ActuarailCost: 0,
    BabyLower: 0,
    BabyUpper: 0,
  },
  buyUpNetwork: {
    show: false,
    Name: "",
    PlanType: "",
    EmployeeContributionType: "%",
    HSACompatible: false,
    HSAContributionType: "HSA - No Employer Contribution",
    HideEmployerPremiumContributions: false,
    NetworkInformation: {
      Name: "",
      ProviderSearchURL: "",
      Description: "",
    },
    TotalMonthlyPremiums: {
      EmployeeOnly: 0,
      EmployeeSpouse: 0,
      EmployeeChildren: 0,
      Family: 0,
    },
    EmployeeContributions: {
      EmployeeOnly: 0,
      EmployeeSpouse: 0,
      EmployeeChildren: 0,
      Family: 0,
    },
    HSAContributions: {
      EmployeeOnly: 0,
      EmployeeSpouse: 0,
      EmployeeChildren: 0,
      Family: 0,
      EmployeeOnlyEmployerMonthlyMatch: 0,
      EmployeeSpouseEmployerMonthlyMatch: 0,
      EmployeeChildrenEmployerMonthlyMatch: 0,
      FamilyEmployerMonthlyMatch: 0,
      EmployeeOnlyEmployerMonthlyMatchMax: 0,
      EmployeeSpouseEmployerMonthlyMatchMax: 0,
      EmployeeChildrenEmployerMonthlyMatchMax: 0,
      FamilyEmployerMonthlyMatchMax: 0,
    },
  },
  narrowNetwork: {
    show: false,
    Name: "",
    PlanType: "",
    EmployeeContributionType: "%",
    HSACompatible: false,
    HSAContributionType: "HSA - No Employer Contribution",
    HideEmployerPremiumContributions: false,
    NetworkInformation: {
      Name: "",
      ProviderSearchURL: "",
      Description: "",
    },
    TotalMonthlyPremiums: {
      EmployeeOnly: 0,
      EmployeeSpouse: 0,
      EmployeeChildren: 0,
      Family: 0,
    },
    EmployeeContributions: {
      EmployeeOnly: 0,
      EmployeeSpouse: 0,
      EmployeeChildren: 0,
      Family: 0,
    },
    HSAContributions: {
      EmployeeOnly: 0,
      EmployeeSpouse: 0,
      EmployeeChildren: 0,
      Family: 0,
      EmployeeOnlyEmployerMonthlyMatch: 0,
      EmployeeSpouseEmployerMonthlyMatch: 0,
      EmployeeChildrenEmployerMonthlyMatch: 0,
      FamilyEmployerMonthlyMatch: 0,
      EmployeeOnlyEmployerMonthlyMatchMax: 0,
      EmployeeSpouseEmployerMonthlyMatchMax: 0,
      EmployeeChildrenEmployerMonthlyMatchMax: 0,
      FamilyEmployerMonthlyMatchMax: 0,
    },
  },
};

export const formatDate = (date) => {
  date = new Date(date);

  var monthNames = [
    "January",
    "February",
    "March",
    "April",
    "May",
    "June",
    "July",
    "August",
    "September",
    "October",
    "November",
    "December",
  ];

  var day = date.getUTCDate();
  var monthIndex = date.getUTCMonth();
  var year = date.getUTCFullYear();

  return day + " " + monthNames[monthIndex] + " " + year;
};
