import React, { useMemo } from "react";
import { css } from "emotion";
import { connect } from "react-redux";
import { setBusiness } from "../redux/actions/business";
import { setAuthBusinessGod } from "../redux/actions/auth";
import CircularProgress from "@material-ui/core/CircularProgress";
import { toast } from "react-toastify";
import { useAuth0 } from "../Auth/react-auth0-wrapper";
import { includes } from "lodash";
import { Typography } from "@material-ui/core";
import { compose } from "redux";
import { useHistory, withRouter } from "react-router-dom";
import { CompanySelect } from "./company-select";

var jwtDecode = require("jwt-decode");

export const ChooseBiz = (props) => {
  const history = useHistory();

  const { loading, isAuthenticated, tokenGlobal } = useAuth0();

  const isBriteUser = useMemo(() => {
    if (!loading && isAuthenticated) {
      let decoded = jwtDecode(tokenGlobal);
      if (decoded.permissions && includes(decoded.permissions, "superuser")) {
        return true;
      }
    }
    return false;
  }, [loading, isAuthenticated]);

  const selectBusiness = async ({ option }) => {
    if (!props.hasUnsavedCourseChanges) {
      history.push(`/${option.ID}/courses`);
    } else {
      toast.error("Could not get businesses");
      toast.info("Cannot switch account. You have unsaved changes!");
    }
  };

  if (loading) {
    return (
      <div
        className={css`
          margin: 0 auto;
        `}
      >
        <CircularProgress />
      </div>
    );
  }

  return (
    <div
      className={css`
        display: flex;
        justify-content: center;
      `}
    >
      <div
        className={css`
          font-size: 12px;
          display: flex;
          justify-content: center;
          align-items: center;
          flex-direction: column;
          padding-top: 10%;
          max-width: 500px;
          width: 100%;
        `}
      >
        <Typography variant="h5">God Mode</Typography>
        {isBriteUser && <CompanySelect selectBusiness={selectBusiness} />}
      </div>
    </div>
  );
};

export const ChooseBusiness = compose(
  withRouter,
  connect(
    (state) => ({
      loading: state.business.loading,
      allBusinesses: state.business.allBusinesses,
      hasUnsavedCourseChanges: state.courses.hasUnsavedCourseChanges,
    }),
    (dispatch) => {
      return {
        setBusiness: (option) => {
          dispatch(setBusiness(option));
        },
        setAuthBusinessGod: (option) => {
          dispatch(setAuthBusinessGod(option));
        },
      };
    }
  )
)(ChooseBiz);
