import { useEffect, useMemo, useRef, useState } from "react";
import { useSearchParams } from "../../shared/use-search-params";
import {
  getLatestTrackedCell,
  mediaModifiers,
  normalizePercent,
} from "./media-utils";
import { utils } from "xlsx";

export const useMediaFocuser = (media, state) => {
  const isInitialRender = useRef(true);
  const [alert, setAlert] = useState("");

  const { updateParams } = useSearchParams();

  const changeToFile = (mediaId) => {
    updateParams({
      mediaId,
    });
    setAlert("");
  };

  const changeToSheet = (sheet) => {
    setAlert("");
    mediaModifiers.setActiveSheet(media, sheet);
  };

  const latestHistory = useMemo(
    () =>
      getLatestTrackedCell({
        data: {
          fieldHistory: media?.historyQuery?.data,
          productId: state?.productId,
          property: media?.state?.currentPropertyChain,
        },
      }),
    [media?.state?.currentPropertyChain]
  );

  const updateActive = () => {
    // There are 3 possible sources of focus: ai-suggestions, field-history,
    // and selected suggestion (from autofill)
    const aiSuggestion = state?.aiSuggestions?.query?.data?.find(
      ({ property_chain, status }) =>
        status === "completed" &&
        property_chain === media?.state?.currentPropertyChain
    );
    if (
      isInitialRender.current &&
      Object.keys(state?.suggestion || {})?.length
    ) {
      // If there is a selected suggestion, focus on it
      const source = state?.suggestion?.data;
      const location = getFocusLocation("suggestion", source);
      mediaModifiers.setFocus(media, {
        source,
        location,
        sourceType: "suggestion",
      });
    } else if (aiSuggestion && !latestHistory) {
      // If there is an AI suggestion, no field history (mapped to the selected property),
      // and no selected suggestion, focus on the AI suggestion
      const source = aiSuggestion;
      const location = getFocusLocation("suggestion", source);

      mediaModifiers.setFocus(media, {
        source,
        location,
        sourceType: "suggestion",
      });
    } else if (!!latestHistory) {
      // Lastly, if there is a field history (mapped to the selected property), focus on it
      const source = latestHistory;
      if (source) {
        const location = getFocusLocation("field-history", source);
        mediaModifiers.setFocus(media, {
          source,
          location,
          sourceType: "field-history",
        });
      } else {
        mediaModifiers.setFocus(media, {});
      }
    } else {
      // Otherwise, remove focus completely
      mediaModifiers.setFocus(media, {});
    }
  };

  useEffect(() => {
    if (
      !media?.historyQuery?.isLoading &&
      !!media?.state?.currentPropertyChain
    ) {
      updateActive();
    }
  }, [media?.historyQuery?.data?.length, media?.state?.currentPropertyChain]);

  useEffect(() => {
    const focus = media?.state?.focus;
    if (focus?.action === "set" && !media?.state?.isParsingWorksheet) {
      if (
        !!focus?.location?.fileId &&
        focus?.location?.fileId !== media?.state?.mediaId
      ) {
        // If the focus is on a different file, change to that file
        if (isInitialRender?.current) {
          changeToFile(focus?.location?.fileId);
        } else {
          setAlert(`change-file`);
        }
      } else if (
        focus?.location?.type !== "pdf" &&
        focus?.location?.sheet &&
        focus?.location?.sheet !== media?.state?.activeSheet
      ) {
        // If the focus is on a different sheet, change to that sheet
        changeToSheet(focus?.location?.sheet);
      }
      isInitialRender.current = false;
    }
  }, [media?.state?.focus?.updatedAt]);

  return {
    alert,
    setAlert,
    changeToSheet,
    changeToFile,
  };
};

// Manage complicated location data for focus.
// sourceType: "suggestion" or "field-history"
// source: the suggestion or field history object
const getFocusLocation = (sourceType, source) => {
  if (sourceType === "field-history") {
    const type = source?.source_metadata?.ref_file_type || "xlsx";
    if (type === "xlsx") {
      const cellRef = utils.encode_cell({
        r: source?.source_metadata?.ref_cell_row - 1,
        c: source?.source_metadata?.ref_cell_col - 1,
      });
      return {
        type,
        cellRef,
        fileId: source?.source_metadata?.ref_file_id,
        sheet: source?.source_metadata?.ref_sheet,
      };
    } else if (type === "pdf") {
      return {
        type: "pdf",
        value: source?.value,
        propertyChain: source?.property_chain,
        locationOnPage: {
          top:
            normalizePercent(source?.source_metadata?.ref_position_top) + "%",
          left:
            normalizePercent(source?.source_metadata?.ref_position_left) + "%",
          height:
            normalizePercent(source?.source_metadata?.ref_position_height) +
            "%",
          width:
            normalizePercent(source?.source_metadata?.ref_position_width) + "%",
        },
        pageNumber: source?.source_metadata?.ref_page_number + "",
        fileId: source?.source_metadata?.ref_file_id,
        type,
      };
    }
  } else if (sourceType === "suggestion" && source) {
    const type = source?.file_type;
    if (type === "xlsx" || type === "csv") {
      const cellRef = utils.encode_cell({
        c: source.suggested_column - 1,
        r: source.suggested_row - 1,
      });

      return {
        cellRef,
        type,
        sheet: source?.tab_name,
        fileId: source?.original_file_id,
      };
    }
  }
};
