import { getGenericQueryProps, getUrl } from ".";

const url = "/v1/notifications/me/subscriptions";

export const knockSubscriptions = {
  url,
  key: "knock-subscriptions",
  getResourceOptions: (resourceProps) => {
    const resourceUrl = getUrl(url, resourceProps);
    return {
      url: resourceUrl,
      queryProps: {
        initialData: [],
        ...getGenericQueryProps(resourceUrl, resourceProps),
      },
    };
  },
  utils: {
    getUnsubscribeDelete: (userId, subscription) => ({
      url: `v1/notifications/${subscription?.ResourceType}/${subscription?.ResourceID}/subscriptions/${userId}`,
      optimistic: (old) => ({
        ...old,
        Data: old?.Data?.filter(
          (item) => item.ResourceID !== subscription?.ResourceID
        ),
      }),
    }),
  },
};
