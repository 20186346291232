import React, { useMemo, useCallback, useState, useEffect } from "react";
import { css } from "emotion";
import { Page } from "../shared/Page/Page";
import Select from "@material-ui/core/Select";
import { makeStyles } from "@material-ui/core/styles";
import InputLabel from "@material-ui/core/InputLabel";
import MenuItem from "@material-ui/core/MenuItem";
import { Layer, Box, Text } from "grommet";
import DeleteIcon from "@material-ui/icons/Delete";
import IconButton from "@material-ui/core/IconButton";
import SendIcon from "@material-ui/icons/Send";
import Chip from "@material-ui/core/Chip";
import PollIcon from "@material-ui/icons/Poll";

import Tooltip from "@material-ui/core/Tooltip";
import ButtonUI from "@material-ui/core/Button";

import FormControl from "@material-ui/core/FormControl";
import Button from "@material-ui/core/Button";
import AddIcon from "@material-ui/icons/Add";

import CircularProgress from "@material-ui/core/CircularProgress";
import { CustomAxios } from "../redux/axios/axios";
import TextField from "@material-ui/core/TextField";
import { sortBy } from "lodash";
import { toast } from "react-toastify";
import { SurveyResults } from "./SurveyResults";
import { Copy } from "../shared/components";

const defaultNewSurvey = {
  Type: "survey",
  Name: "",
  Views: 0,
  IsPublished: false,
  Pages: [
    {
      Type: "decision",
      Content: null,
      Name: "Benefits Explorer Survey",
      Order: 1,
      FrontendID: "_" + Math.random().toString(36).substr(2, 9),
      InsurancePlans: [],
      Question: null,
      IsLocked: false,
      TemplateID: "00000000-0000-0000-0000-000000000000",
    },
  ],
};

const useStyles = makeStyles((theme) => ({
  formControl: {
    minWidth: 120,
  },
}));

export const Surveys = (props) => {
  const classes = useStyles();
  const [loading, setLoading] = useState(false);
  const [addSurveyOpen, setAddSurveyOpen] = useState(false);
  const [deleteSurveyID, setDeleteSurveyID] = useState("");
  const [surveys, setSurveys] = useState([]);
  const [newSurvey, setAddNewSurvey] = useState(defaultNewSurvey);
  const [sharableLinkModal, setSharableLinkModal] = useState("");
  const [copied, setCopied] = useState(false);
  const [vanityURL, setVanityURL] = useState("");
  const [showResultsFromSurvey, setShowResultsFromSurvey] = useState("");

  useEffect(() => {
    getSurveys();
  }, []);

  const getSurveys = async () => {
    try {
      setLoading(true);
      const response = await CustomAxios.get(`/v1/course`);

      let courses = response.data;

      courses = sortBy(courses, (o) => o.Name.toLowerCase());

      courses = courses.filter((course) => course.Type === "survey");
      setSurveys(courses);

      setLoading(false);
    } catch (e) {
      setLoading(false);
      toast.error("Could not retrieve surveys");
    }
  };

  const createVanityUrl = async (survey, url) => {
    const response = await CustomAxios.put(
      `/v1/course/${survey.ID}/vanityurl`,
      { URL: url }
    );

    return response;
  };

  const getSharableLink = async (survey) => {
    setSharableLinkModal(survey.ID);
    if (survey.VanityURL) {
      setVanityURL(survey.VanityURL);
    } else {
      let random = Math.floor(Math.random() * (999 - 100 + 1) + 100);
      let url = survey.Name;
      url = url.toLowerCase();
      url = url.replace(/\s/g, "-");
      url = `${url}-${random}`;

      try {
        let response = createVanityUrl(survey, url);
        setVanityURL(url);
      } catch (e) {
        try {
          let url = survey.Name;
          url = url.toLowerCase();
          url = url.replace(/\s/g, "-");
          url = `${url}-${random + 1}`;

          let response = createVanityUrl(survey, url);
          setVanityURL(url);
        } catch (error) {
          toast.error("Could not get url to send survey. Try again.");
        }
      }
    }
  };

  const createSurvey = async () => {
    setAddSurveyOpen(false);
    try {
      setLoading(true);
      const response = await CustomAxios.post(`/v1/course`, newSurvey);

      setAddNewSurvey(defaultNewSurvey);

      getSurveys();
    } catch (e) {
      setLoading(false);
      toast.error("Could not create survey");
    }
  };

  const deleteSurvey = async (id) => {
    try {
      setLoading(true);
      const response = await CustomAxios.delete(`/v1/course/${id}`);
      getSurveys();
    } catch (e) {
      setLoading(false);
      toast.error("Could not delete survey");
    }
  };

  if (loading) {
    return (
      <div
        className={css`
          display: flex;
          justify-content: center;
          margin: 48px;
        `}
      >
        <CircularProgress />
      </div>
    );
  }

  return (
    <Page>
      <div
        className={css`
          display: flex;
          justify-content: space-between;
          align-items: center;
          margin-bottom: 24px;
        `}
      >
        <div
          className={css`
            font-weight: bold;
            font-size: 34px;
            line-height: 42px;
            color: #25282d;
            margin-right: 12px;
            margin-left: 8px;
          `}
        >
          Surveys
        </div>

        <Button
          color="primary"
          variant="contained"
          startIcon={<AddIcon />}
          onClick={() => {
            setAddSurveyOpen(true);
          }}
        >
          Add Survey
        </Button>
      </div>

      {surveys.length === 0 && (
        <div
          className={css`
            margin: 14px;
          `}
        >
          No surveys created yet
        </div>
      )}

      <div
        className={css`
          display: flex;
          justify-content: flex-start;
          flex-wrap: wrap;
        `}
      >
        {surveys.map((survey) => {
          return (
            <Box
              responsive={false}
              elevation="small"
              margin="medium"
              width="230px"
              key={survey.ID}
            >
              <div
                className={css`
                  margin: 8px;
                  margin-top: 14px;
                `}
              >
                <Chip size="small" label="Benefits Explorer Survey" />
              </div>
              <div
                className={css`
                  padding: 10px;
                  overflow: auto;
                  height: 64px;
                `}
                title={survey.Name}
              >
                <Text size="small" weight="500">
                  {survey.Name}
                </Text>
              </div>

              <div
                className={css`
                  display: flex;
                  border-top: 1px solid #eee;
                  justify-content: space-between;
                  padding-left: 8px;
                  padding-top: 2px;
                  padding-bottom: 2px;
                `}
              >
                <Tooltip title="View shareable survey link" placement="top">
                  <ButtonUI
                    startIcon={<SendIcon />}
                    aria-label="View shareable survey link"
                    color="primary"
                    onClick={() => {
                      getSharableLink(survey);
                    }}
                  >
                    Send
                  </ButtonUI>
                </Tooltip>

                <div>
                  <Tooltip title="Results" placement="top">
                    <IconButton
                      aria-label="Results"
                      color="default"
                      onClick={() => {
                        setShowResultsFromSurvey(survey.ID);
                      }}
                    >
                      <PollIcon />
                    </IconButton>
                  </Tooltip>
                  <Tooltip title="Delete" placement="top">
                    <IconButton
                      aria-label="Delete"
                      color="default"
                      onClick={() => {
                        setDeleteSurveyID(survey.ID);
                      }}
                    >
                      <DeleteIcon />
                    </IconButton>
                  </Tooltip>
                </div>
              </div>
            </Box>
          );
        })}
      </div>

      {!!deleteSurveyID && (
        <Layer
          margin="small"
          modal={true}
          onEsc={() => setDeleteSurveyID("")}
          onClickOutside={() => setDeleteSurveyID("")}
        >
          <div
            className={css`
              font-weight: 400;
              font-size: 22px;
              line-height: 28px;
              color: #797979;
              margin: 20px;
              margin-bottom: 5px;
            `}
          >
            Are you sure you want to delete this survey?
          </div>
          <Box gap="medium" margin="20px;">
            <div
              className={css`
                display: flex;
                justify-content: center;
                align-items: center;
              `}
            >
              <div
                className={css`
                  margin-right: 24px;
                `}
              >
                <Button
                  onClick={() => {
                    setDeleteSurveyID("");
                  }}
                >
                  Cancel
                </Button>
              </div>
              <Button
                variant="contained"
                onClick={() => {
                  deleteSurvey(deleteSurveyID);
                  setDeleteSurveyID("");
                }}
              >
                Delete
              </Button>
            </div>
          </Box>
        </Layer>
      )}

      {!!showResultsFromSurvey && (
        <Layer
          margin="small"
          className={css`
            overflow: auto;
          `}
          modal={true}
          onEsc={() => setShowResultsFromSurvey("")}
          onClickOutside={() => setShowResultsFromSurvey("")}
        >
          <div
            className={css`
              min-width: 400px;
            `}
          >
            <div
              className={css`
                font-weight: 400;
                font-size: 22px;
                line-height: 28px;
                color: #797979;
                margin: 20px;
                margin-bottom: 5px;
              `}
            >
              Results
            </div>
            <SurveyResults surveyId={showResultsFromSurvey} />
            <Box gap="medium" margin="20px;">
              <div
                className={css`
                  display: flex;
                  justify-content: center;
                  align-items: center;
                `}
              >
                <div
                  className={css`
                    margin-right: 24px;
                  `}
                >
                  <Button
                    variant="contained"
                    onClick={() => {
                      setShowResultsFromSurvey("");
                    }}
                  >
                    Close
                  </Button>
                </div>
              </div>
            </Box>
          </div>
        </Layer>
      )}

      {!!sharableLinkModal && (
        <Layer
          margin="small"
          modal={true}
          onEsc={() => {
            setSharableLinkModal("");
            setCopied(false);
            setVanityURL("");
            getSurveys();
          }}
          onClickOutside={() => {
            setSharableLinkModal("");
            setCopied(false);
            setVanityURL("");
            getSurveys();
          }}
        >
          <div>
            <div
              className={css`
                font-weight: 400;
                font-size: 22px;
                line-height: 28px;
                color: #797979;
                margin: 20px;
                margin-bottom: 5px;
              `}
            >
              Send Survey
            </div>
            <Box gap="medium" margin="20px;">
              <Box
                responsive
                pad="large"
                elavation="small"
                align="center"
                background="white"
                round
                gap="small"
              >
                {vanityURL && (
                  <React.Fragment>
                    <Copy value={`https://britehr.app/survey/${vanityURL}`}>
                      <div>
                        <Text weight="500">
                          {" "}
                          https://britehr.app/survey/{vanityURL}
                        </Text>
                      </div>
                    </Copy>

                    <div
                      className={css`
                        margin: 14px;
                        font-size: 12px;
                      `}
                    >
                      Share the link above with anyone to take the survey
                    </div>
                  </React.Fragment>
                )}

                {!vanityURL && <CircularProgress />}
              </Box>

              <Button
                variant="contained"
                onClick={() => {
                  setSharableLinkModal("");
                  setCopied(false);
                  setVanityURL("");
                  getSurveys();
                }}
              >
                Close
              </Button>
            </Box>
          </div>
        </Layer>
      )}

      {addSurveyOpen && (
        <Layer
          margin="small"
          onEsc={() => setAddSurveyOpen(false)}
          onClickOutside={() => setAddSurveyOpen(false)}
        >
          <div
            className={css`
              font-weight: 400;
              font-size: 22px;
              line-height: 28px;
              color: #797979;
              margin: 20px;
              margin-bottom: 5px;
            `}
          >
            Add Survey
          </div>
          <Box gap="medium" margin="20px;">
            <TextField
              id="survey-name"
              label="Survey Name"
              variant="outlined"
              value={newSurvey.Name}
              onChange={(e) => {
                setAddNewSurvey({ ...newSurvey, Name: e.target.value });
              }}
            />

            <FormControl variant="outlined" className={classes.formControl}>
              <InputLabel id="type-label">Survey Type</InputLabel>
              <Select
                labelId="type-label"
                id="type-label-select"
                value={newSurvey.Type}
                onChange={(e) => {
                  setAddNewSurvey({ ...newSurvey, Type: e.target.value });
                }}
                label="Survey Type"
              >
                <MenuItem value={"survey"}>Benefits Explorer Survey</MenuItem>
              </Select>
            </FormControl>

            <Button
              color="primary"
              disabled={!newSurvey.Name}
              variant="contained"
              onClick={() => {
                createSurvey();
              }}
            >
              Create Survey
            </Button>
          </Box>
        </Layer>
      )}
    </Page>
  );
};
