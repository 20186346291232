import React, { useEffect, useReducer } from "react";
import { useSearchParams } from "../../shared/use-search-params";

export const initialState = {
  componentToolbar: true,
  pagesToolbar: true,
};

const reducer = (state, action) => {
  switch (action.type) {
    case "RESET": {
      return initialState;
    }

    case "SET": {
      return {
        ...state,
        [action.key]: action.payload,
      };
    }

    case "set-properties": {
      return {
        ...state,
        ...action.payload,
      };
    }

    default:
      return state;
  }
};

export const tagSelector = (data) => {
  return data?.reduce((prev, item) => {
    return (
      item?.mergeTags?.reduce((p, tag) => {
        return [...p, tag];
      }, prev) || []
    );
  }, []);
};

export const EditorDetailsContext = React.createContext();

export const EditorDetailsProvider = (props) => {
  const [editorDetails, dispatch] = useReducer(reducer, initialState);

  const { params, removeParam } = useSearchParams();

  const setProperties = (updates) => {
    dispatch({
      type: "set-properties",
      payload: updates,
    });
  };

  useEffect(() => {
    if (params?.mode === "collaborating" && !!params?.routingTo) {
      const [routeToType, commentId, componentId, commentHighlightId = ""] =
        params?.routingTo?.split(",");
      if (routeToType === "comment") {
        setTimeout(() => {
          dispatch({
            type: "SET",
            key: "commenting",
            payload: {
              componentId,
              commentId,
              highlightId: commentHighlightId,
              selectType: "comment",
              selectedAt: new Date().toISOString(),
            },
          });
          removeParam("routingTo");
        }, 1800);
      }
    }
  }, [params?.mode, params.routingTo]);

  return (
    <EditorDetailsContext.Provider
      value={{ editorDetails, dispatch, setProperties }}
    >
      {props.children}
    </EditorDetailsContext.Provider>
  );
};

// `routingTo` creates a shareable link with extra (but temporary) context.
// `routingTo` is removed after it's used.
