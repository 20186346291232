import { useContext } from "react";
import { StoreContext } from "./provider";

export const useStore = (selectorFn = null) => {
  const value = useContext(StoreContext);

  const { store, dispatchStore } = value;

  const data = selectorFn === null ? store : selectorFn(store);

  const updateStore = (key, value) =>
    // SHALLOW OBJECT UPDATES AT key LEVEL
    dispatchStore({ type: "UPDATE", payload: value, key });

  const setStore = (key, value) =>
    dispatchStore({ type: "SET", payload: value, key });

  const setProperties = (updates) => {
    dispatchStore({
      type: "SET-PROPERTIES",
      payload: updates,
    });
  };

  const resetStore = () => {
    dispatchStore({ type: "RESET" });
  };

  return {
    data,
    updateStore,
    setStore,
    resetStore,
    setProperties,
  };
};

export const getIsParent = (selectedBusiness) => {
  return (
    (selectedBusiness?.Type === "multi" ||
      selectedBusiness?.Type === "carrier") &&
    selectedBusiness?.ID === selectedBusiness?.ParentID
  );
};
