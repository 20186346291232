export const theme = {
  accordion: {
    heading: { level: "6" },
    icons: {
      color: "white",
    },
    border: undefined,
  },
  global: {
    colors: {
      brand: "#3A4684", //purple
      "accent-1": "#70C8B6", //green
      "accent-2": "#F26C6D", //pink
      "accent-3": "#FCF081", //yellow
      "accent-4": "#C2E8F6", //blue
    },
    font: {
      family: "Montserrat",
      size: "14px",
      height: "20px",
    },
  },
};

export const statesMap = {
  AL: "Alabama",
  AK: "Alaska",
  AS: "American Samoa",
  AZ: "Arizona",
  AR: "Arkansas",
  CA: "California",
  CO: "Colorado",
  CT: "Connecticut",
  DE: "Delaware",
  DC: "District Of Columbia",
  FM: "Federated States Of Micronesia",
  FL: "Florida",
  GA: "Georgia",
  GU: "Guam",
  HI: "Hawaii",
  ID: "Idaho",
  IL: "Illinois",
  IN: "Indiana",
  IA: "Iowa",
  KS: "Kansas",
  KY: "Kentucky",
  LA: "Louisiana",
  ME: "Maine",
  MH: "Marshall Islands",
  MD: "Maryland",
  MA: "Massachusetts",
  MI: "Michigan",
  MN: "Minnesota",
  MS: "Mississippi",
  MO: "Missouri",
  MT: "Montana",
  NE: "Nebraska",
  NV: "Nevada",
  NH: "New Hampshire",
  NJ: "New Jersey",
  NM: "New Mexico",
  NY: "New York",
  NC: "North Carolina",
  ND: "North Dakota",
  MP: "Northern Mariana Islands",
  OH: "Ohio",
  OK: "Oklahoma",
  OR: "Oregon",
  PW: "Palau",
  PA: "Pennsylvania",
  PR: "Puerto Rico",
  RI: "Rhode Island",
  SC: "South Carolina",
  SD: "South Dakota",
  TN: "Tennessee",
  TX: "Texas",
  UT: "Utah",
  VT: "Vermont",
  VI: "Virgin Islands",
  VA: "Virginia",
  WA: "Washington",
  WV: "West Virginia",
  WI: "Wisconsin",
  WY: "Wyoming",
};

export const industries = [
  {
    name: "Accounting",
  },
  {
    name: "Airlines/Aviation",
  },
  {
    name: "Alternative Dispute Resolution",
  },
  {
    name: "Alternative Medicine",
  },
  {
    name: "Animation",
  },
  {
    name: "Apparel/Fashion",
  },
  {
    name: "Architecture/Planning",
  },
  {
    name: "Arts/Crafts",
  },
  {
    name: "Automotive",
  },
  {
    name: "Aviation/Aerospace",
  },
  {
    name: "Banking/Mortgage",
  },
  {
    name: "Biotechnology/Greentech",
  },
  {
    name: "Broadcast Media",
  },
  {
    name: "Building Materials",
  },
  {
    name: "Business Supplies/Equipment",
  },
  {
    name: "Capital Markets/Hedge Fund/Private Equity",
  },
  {
    name: "Chemicals",
  },
  {
    name: "Civic/Social Organization",
  },
  {
    name: "Civil Engineering",
  },
  {
    name: "Commercial Real Estate",
  },
  {
    name: "Computer Games",
  },
  {
    name: "Computer Hardware",
  },
  {
    name: "Computer Networking",
  },
  {
    name: "Computer Software/Engineering",
  },
  {
    name: "Computer/Network Security",
  },
  {
    name: "Construction",
  },
  {
    name: "Consumer Electronics",
  },
  {
    name: "Consumer Goods",
  },
  {
    name: "Consumer Services",
  },
  {
    name: "Cosmetics",
  },
  {
    name: "Dairy",
  },
  {
    name: "Defense/Space",
  },
  {
    name: "Design",
  },
  {
    name: "E-Learning",
  },
  {
    name: "Education Management",
  },
  {
    name: "Electrical/Electronic Manufacturing",
  },
  {
    name: "Entertainment/Movie Production",
  },
  {
    name: "Environmental Services",
  },
  {
    name: "Events Services",
  },
  {
    name: "Executive Office",
  },
  {
    name: "Facilities Services",
  },
  {
    name: "Farming",
  },
  {
    name: "Financial Services",
  },
  {
    name: "Fine Art",
  },
  {
    name: "Fishery",
  },
  {
    name: "Food Production",
  },
  {
    name: "Food/Beverages",
  },
  {
    name: "Fundraising",
  },
  {
    name: "Furniture",
  },
  {
    name: "Gambling/Casinos",
  },
  {
    name: "Glass/Ceramics/Concrete",
  },
  {
    name: "Government Administration",
  },
  {
    name: "Government Relations",
  },
  {
    name: "Graphic Design/Web Design",
  },
  {
    name: "Health/Fitness",
  },
  {
    name: "Higher Education/Acadamia",
  },
  {
    name: "Hospital/Health Care",
  },
  {
    name: "Hospitality",
  },
  {
    name: "Human Resources/HR",
  },
  {
    name: "Import/Export",
  },
  {
    name: "Individual/Family Services",
  },
  {
    name: "Industrial Automation",
  },
  {
    name: "Information Services",
  },
  {
    name: "Information Technology/IT",
  },
  {
    name: "Insurance",
  },
  {
    name: "International Affairs",
  },
  {
    name: "International Trade/Development",
  },
  {
    name: "Internet",
  },
  {
    name: "Investment Banking/Venture",
  },
  {
    name: "Investment Management/Hedge Fund/Private Equity",
  },
  {
    name: "Judiciary",
  },
  {
    name: "Law Enforcement",
  },
  {
    name: "Law Practice/Law Firms",
  },
  {
    name: "Legal Services",
  },
  {
    name: "Legislative Office",
  },
  {
    name: "Leisure/Travel",
  },
  {
    name: "Library",
  },
  {
    name: "Logistics/Procurement",
  },
  {
    name: "Luxury Goods/Jewelry",
  },
  {
    name: "Machinery",
  },
  {
    name: "Management Consulting",
  },
  {
    name: "Maritime",
  },
  {
    name: "Market Research",
  },
  {
    name: "Marketing/Advertising/Sales",
  },
  {
    name: "Mechanical or Industrial Engineering",
  },
  {
    name: "Media Production",
  },
  {
    name: "Medical Equipment",
  },
  {
    name: "Medical Practice",
  },
  {
    name: "Mental Health Care",
  },
  {
    name: "Military Industry",
  },
  {
    name: "Mining/Metals",
  },
  {
    name: "Motion Pictures/Film",
  },
  {
    name: "Museums/Institutions",
  },
  {
    name: "Music",
  },
  {
    name: "Nanotechnology",
  },
  {
    name: "Newspapers/Journalism",
  },
  {
    name: "Non-Profit/Volunteering",
  },
  {
    name: "Oil/Energy/Solar/Greentech",
  },
  {
    name: "Online Publishing",
  },
  {
    name: "Other Industry",
  },
  {
    name: "Outsourcing/Offshoring",
  },
  {
    name: "Package/Freight Delivery",
  },
  {
    name: "Packaging/Containers",
  },
  {
    name: "Paper/Forest Products",
  },
  {
    name: "Performing Arts",
  },
  {
    name: "Pharmaceuticals",
  },
  {
    name: "Philanthropy",
  },
  {
    name: "Photography",
  },
  {
    name: "Plastics",
  },
  {
    name: "Political Organization",
  },
  {
    name: "Primary/Secondary Education",
  },
  {
    name: "Printing",
  },
  {
    name: "Professional Training",
  },
  {
    name: "Program Development",
  },
  {
    name: "Public Relations/PR",
  },
  {
    name: "Public Safety",
  },
  {
    name: "Publishing Industry",
  },
  {
    name: "Railroad Manufacture",
  },
  {
    name: "Ranching",
  },
  {
    name: "Real Estate/Mortgage",
  },
  {
    name: "Recreational Facilities/Services",
  },
  {
    name: "Religious Institutions",
  },
  {
    name: "Renewables/Environment",
  },
  {
    name: "Research Industry",
  },
  {
    name: "Restaurants",
  },
  {
    name: "Retail Industry",
  },
  {
    name: "Security/Investigations",
  },
  {
    name: "Semiconductors",
  },
  {
    name: "Shipbuilding",
  },
  {
    name: "Sporting Goods",
  },
  {
    name: "Sports",
  },
  {
    name: "Staffing/Recruiting",
  },
  {
    name: "Supermarkets",
  },
  {
    name: "Telecommunications",
  },
  {
    name: "Textiles",
  },
  {
    name: "Think Tanks",
  },
  {
    name: "Tobacco",
  },
  {
    name: "Translation/Localization",
  },
  {
    name: "Transportation",
  },
  {
    name: "Utilities",
  },
  {
    name: "Venture Capital/VC",
  },
  {
    name: "Veterinary",
  },
  {
    name: "Warehousing",
  },
  {
    name: "Wholesale",
  },
  {
    name: "Wine/Spirits",
  },
  {
    name: "Wireless",
  },
  {
    name: "Writing/Editing",
  },
];
export const states = [
  { country: "US", id: "AL", label: "Alabama" },
  { country: "US", id: "AK", label: "Alaska" },
  { country: "US", id: "AZ", label: "Arizona" },
  { country: "US", id: "AR", label: "Arkansas" },
  { country: "US", id: "CA", label: "California" },
  { country: "US", id: "CO", label: "Colorado" },
  { country: "US", id: "CT", label: "Connecticut" },
  { country: "US", id: "DE", label: "Delaware" },
  { country: "US", id: "DC", label: "District of Columbia" },
  { country: "US", id: "FL", label: "Florida" },
  { country: "US", id: "GA", label: "Georgia" },
  { country: "US", id: "HI", label: "Hawaii" },
  { country: "US", id: "ID", label: "Idaho" },
  { country: "US", id: "IL", label: "Illinois" },
  { country: "US", id: "IN", label: "Indiana" },
  { country: "US", id: "IA", label: "Iowa" },
  { country: "US", id: "KS", label: "Kansas" },
  { country: "US", id: "KY", label: "Kentucky" },
  { country: "US", id: "LA", label: "Louisiana" },
  { country: "US", id: "ME", label: "Maine" },
  { country: "US", id: "MD", label: "Maryland" },
  { country: "US", id: "MA", label: "Massachusetts" },
  { country: "US", id: "MI", label: "Michigan" },
  { country: "US", id: "MN", label: "Minnesota" },
  { country: "US", id: "MS", label: "Mississippi" },
  { country: "US", id: "MO", label: "Missouri" },
  { country: "US", id: "MT", label: "Montana" },
  { country: "US", id: "NE", label: "Nebraska" },
  { country: "US", id: "NV", label: "Nevada" },
  { country: "US", id: "NH", label: "New Hampshire" },
  { country: "US", id: "NJ", label: "New Jersey" },
  { country: "US", id: "NM", label: "New Mexico" },
  { country: "US", id: "NY", label: "New York" },
  { country: "US", id: "NC", label: "North Carolina" },
  { country: "US", id: "ND", label: "North Dakota" },
  { country: "US", id: "OH", label: "Ohio" },
  { country: "US", id: "OK", label: "Oklahoma" },
  { country: "US", id: "OR", label: "Oregon" },
  { country: "US", id: "PA", label: "Pennsylvania" },
  { country: "US", id: "PR", label: "Puerto Rico" },
  { country: "US", id: "RI", label: "Rhode Island" },
  { country: "US", id: "SC", label: "South Carolina" },
  { country: "US", id: "SD", label: "South Dakota" },
  { country: "US", id: "TN", label: "Tennessee" },
  { country: "US", id: "TX", label: "Texas" },
  { country: "US", id: "UT", label: "Utah" },
  { country: "US", id: "VT", label: "Vermont" },
  { country: "US", id: "VA", label: "Virginia" },
  { country: "US", id: "WA", label: "Washington" },
  { country: "US", id: "WV", label: "West Virginia" },
  { country: "US", id: "WI", label: "Wisconsin" },
  { country: "US", id: "WY", label: "Wyoming" },
];

export const extendedStates = [
  { country: "US", id: "ALL", label: "Select All" },
  { country: "US", id: "AL", label: "Alabama" },
  { country: "US", id: "AK", label: "Alaska" },
  { country: "US", id: "AZ", label: "Arizona" },
  { country: "US", id: "AR", label: "Arkansas" },
  { country: "US", id: "CA", label: "California" },
  { country: "US", id: "CO", label: "Colorado" },
  { country: "US", id: "CT", label: "Connecticut" },
  { country: "US", id: "DE", label: "Delaware" },
  { country: "US", id: "DC", label: "District of Columbia" },
  { country: "US", id: "FL", label: "Florida" },
  { country: "US", id: "GA", label: "Georgia" },
  { country: "US", id: "HI", label: "Hawaii" },
  { country: "US", id: "ID", label: "Idaho" },
  { country: "US", id: "IL", label: "Illinois" },
  { country: "US", id: "IN", label: "Indiana" },
  { country: "US", id: "IA", label: "Iowa" },
  { country: "US", id: "KS", label: "Kansas" },
  { country: "US", id: "KY", label: "Kentucky" },
  { country: "US", id: "LA", label: "Louisiana" },
  { country: "US", id: "ME", label: "Maine" },
  { country: "US", id: "MD", label: "Maryland" },
  { country: "US", id: "MA", label: "Massachusetts" },
  { country: "US", id: "MI", label: "Michigan" },
  { country: "US", id: "MN", label: "Minnesota" },
  { country: "US", id: "MS", label: "Mississippi" },
  { country: "US", id: "MO", label: "Missouri" },
  { country: "US", id: "MT", label: "Montana" },
  { country: "US", id: "NE", label: "Nebraska" },
  { country: "US", id: "NV", label: "Nevada" },
  { country: "US", id: "NH", label: "New Hampshire" },
  { country: "US", id: "NJ", label: "New Jersey" },
  { country: "US", id: "NM", label: "New Mexico" },
  { country: "US", id: "NY", label: "New York" },
  { country: "US", id: "NC", label: "North Carolina" },
  { country: "US", id: "ND", label: "North Dakota" },
  { country: "US", id: "OH", label: "Ohio" },
  { country: "US", id: "OK", label: "Oklahoma" },
  { country: "US", id: "OR", label: "Oregon" },
  { country: "US", id: "PA", label: "Pennsylvania" },
  { country: "US", id: "PR", label: "Puerto Rico" },
  { country: "US", id: "RI", label: "Rhode Island" },
  { country: "US", id: "SC", label: "South Carolina" },
  { country: "US", id: "SD", label: "South Dakota" },
  { country: "US", id: "TN", label: "Tennessee" },
  { country: "US", id: "TX", label: "Texas" },
  { country: "US", id: "UT", label: "Utah" },
  { country: "US", id: "VT", label: "Vermont" },
  { country: "US", id: "VA", label: "Virginia" },
  { country: "US", id: "WA", label: "Washington" },
  { country: "US", id: "WV", label: "West Virginia" },
  { country: "US", id: "WI", label: "Wisconsin" },
  { country: "US", id: "WY", label: "Wyoming" },
];

export const INTRO_DECISION_PAGE = 0;
export const STATE_QUESTION_PAGE = 1;
export const ENROLLING_QUESTION_PAGE = 2;
export const EXPLAIN_PERSONAL_FIRST_PAGE = 3;
export const PERSONAL_DOCTER_VISITS_QUESTION_PAGE = 4;
export const PERSONAL_MEDS_QUESTION_PAGE = 5;
export const PERSONAL_URGENT_CARE_QUESTION_PAGE = 6;
export const PERSONAL_BABY_QUESTION_PAGE = 7;
export const PERSONAL_SURGERY_QUESTION_PAGE = 8;
export const PERSONAL_MEDICAL_CONDITIONS_QUESTION_PAGE = 9;
export const EXPLAIN_DEPENDANT_QUESTIONS_PAGE = 10;
export const DEPENDANT_DOCTER_VISITS_QUESTION_PAGE = 11;
export const DEPENDANT_MEDS_QUESTION_PAGE = 12;
export const DEPENDANT_URGENT_CARE_QUESTION_PAGE = 13;
export const DEPENDANT_BABY_QUESTION_PAGE = 14;
export const DEPENDANT_SURGERY_QUESTION_PAGE = 15;
export const DEPENDANT_MEDICAL_CONDITIONS_QUESTION_PAGE = 16;
export const DISCLAIMER_PAGE = 17;
export const MEDICAL_PLAN_REC = 18;
export const TAX_FREE_REC = 19;
export const CORE_BENEFITS_REC = 20;
export const ADDITIONAL_BENEFITS_REC = 21;
export const EMAIL_REC = 22;
