import { useState } from "react";
import { makeStyles } from "@material-ui/core/styles";
import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableContainer from "@material-ui/core/TableContainer";
import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";
import TableSortLabel from "@material-ui/core/TableSortLabel";
import { headCells, stableSort, getComparator } from "./helpers";
import { CaretDown, CaretUp } from "phosphor-react";
import { Button } from "@material-ui/core";
import { css } from "emotion";

const useStyles = makeStyles((theme) => ({
  root: {
    width: "100%",
  },

  table: {
    minWidth: 750,
  },

  visuallyHidden: {
    border: 0,
    clip: "rect(0 0 0 0)",
    height: 1,
    margin: -1,
    overflow: "hidden",
    padding: 0,
    position: "absolute",
    top: 20,
    width: 1,
  },
}));

export const EnhancedTableHead = (props) => {
  const { classes, order, orderBy, onRequestSort } = props;
  const createSortHandler = (property) => (event) => {
    onRequestSort(event, property);
  };

  return (
    <TableHead>
      <TableRow>
        {headCells.map((headCell) => (
          <TableCell
            key={headCell.id}
            align={"left"}
            padding={headCell.disablePadding ? "none" : "normal"}
            sortDirection={orderBy === headCell.id ? order : false}
          >
            <TableSortLabel
              hideSortIcon={
                headCell.id === "average" || headCell.id === "total"
              }
              active={orderBy === headCell.id}
              direction={orderBy === headCell.id ? order : "asc"}
              onClick={createSortHandler(headCell.id)}
            >
              <span
                className={css`
                  color: #25282d;
                  font-feature-settings: "clig" off, "liga" off;

                  font-family: Roboto;
                  font-size: 14px;
                  font-style: normal;
                  font-weight: 700;
                  line-height: 24px;
                  letter-spacing: 0.15px;
                `}
              >
                {headCell.label}
              </span>
              {orderBy === headCell.id ? (
                <span className={classes.visuallyHidden}>
                  {order === "desc" ? "sorted descending" : "sorted ascending"}
                </span>
              ) : null}
            </TableSortLabel>
          </TableCell>
        ))}
      </TableRow>
    </TableHead>
  );
};

export const EnhancedTable = (props) => {
  const classes = useStyles();
  const [order, setOrder] = useState("asc");
  const [orderBy, setOrderBy] = useState("page");
  const [showAll, setShowAll] = useState(false);

  const handleRequestSort = (event, property) => {
    if (property === "average" || property === "total") {
      return;
    }
    const isAsc = orderBy === property && order === "asc";
    setOrder(isAsc ? "desc" : "asc");
    setOrderBy(property);
  };

  return (
    <div className={classes.root}>
      {props.rows.length !== 0 && (
        <TableContainer>
          <Table
            className={classes.table}
            aria-labelledby="tableTitle"
            size={"medium"}
            aria-label="enhanced table"
          >
            <EnhancedTableHead
              classes={classes}
              order={order}
              orderBy={orderBy}
              onRequestSort={handleRequestSort}
              rowCount={props.rows.length}
            />
            <TableBody>
              {stableSort(props.rows, getComparator(order, orderBy)).map(
                (row, index) => {
                  if (!showAll) {
                    if (index > 4) {
                      return null;
                    }
                  }
                  return (
                    <TableRow tabIndex={-1} key={row.page}>
                      <TableCell
                        align="left"
                        style={{
                          paddingTop: "30px",
                          paddingBottom: "30px",
                          color: "#25282D",
                          fontFamily: "Roboto",
                          fontSize: "16px",
                          fontWeight: 400,
                        }}
                      >
                        {row.page}
                      </TableCell>
                      <TableCell
                        align="left"
                        style={{
                          paddingTop: "30px",
                          paddingBottom: "30px",
                          color: "#25282D",
                          fontFamily: "Roboto",
                          fontSize: "16px",
                          fontWeight: 400,
                        }}
                      >
                        {row.pageName}
                      </TableCell>
                      <TableCell
                        align="left"
                        style={{
                          paddingTop: "30px",
                          paddingBottom: "30px",
                          color: "#25282D",
                          fontFamily: "Roboto",
                          fontSize: "16px",
                          fontWeight: 400,
                        }}
                      >
                        {row.visits}
                      </TableCell>
                      <TableCell
                        align="left"
                        style={{
                          paddingTop: "30px",
                          paddingBottom: "30px",
                          color: "#25282D",
                          fontFamily: "Roboto",
                          fontSize: "16px",
                          fontWeight: 400,
                        }}
                      >
                        {row.unique}
                      </TableCell>
                      <TableCell
                        align="left"
                        style={{
                          paddingTop: "30px",
                          paddingBottom: "30px",
                          color: "#25282D",
                          fontFamily: "Roboto",
                          fontSize: "16px",
                          fontWeight: 400,
                        }}
                      >
                        {row.average}
                      </TableCell>
                      <TableCell
                        align="left"
                        style={{
                          paddingTop: "30px",
                          paddingBottom: "30px",
                          color: "#25282D",
                          fontFamily: "Roboto",
                          fontSize: "16px",
                          fontWeight: 400,
                        }}
                      >
                        {row.total}
                      </TableCell>
                    </TableRow>
                  );
                }
              )}
            </TableBody>
          </Table>
        </TableContainer>
      )}

      {props.rows.length > 5 && (
        <div
          className={css`
            display: flex;
            justify-content: center;
            margin-top: 18px;
          `}
        >
          <Button
            size="large"
            onClick={() => {
              setShowAll(!showAll);
            }}
            endIcon={!showAll ? <CaretDown size={24} /> : <CaretUp size={24} />}
          >
            {showAll ? "View Less" : "View All"}
          </Button>
        </div>
      )}
    </div>
  );
};
