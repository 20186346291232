import { useCallback, useEffect, useRef, useState } from "react";
import { HistoryEditor, withHistory } from "slate-history";
import { useFocused, withReact } from "slate-react";
import { createEditor, Editor, Transforms } from "slate";
import {
  convertToHtml,
  getActiveColor,
  textEditorShortKeys,
  toggleBlock,
  toggleInline,
  updateColor,
  updateContent,
} from "./slate-utils";
import { elementMap, listSchema } from "./slate-config";
import { defaultComponents } from "../../constants";
import { isEqual } from "lodash";
import { DefaultElement, Leaf } from "./slate-components";
import {
  ListsEditor,
  withLists,
  onKeyDown as slateList_onKeyDown,
} from "@prezly/slate-lists";
import { useEditorResource } from "../../use-editor-resource";
import { editorUtils, modifyContent } from "../../provider/utils";

const inlineElements = ["link", "smart-field"];

function withInlines(editor) {
  const { isInline } = editor;
  editor.isInline = (element) =>
    inlineElements.includes(element.type) || isInline(element);
  return editor;
}

export const useSlateEditor = (props) => {
  const isInitial = useRef(true);
  const textContext = useRef({});
  const { editor, location, element } = props;
  const { slateContent = defaultComponents?.textV2?.slateContent } = element;

  const hasFocus = useFocused();
  const [version, setVersion] = useState(editor?.state?.versionId);
  const { data: liveSmartFields } = useEditorResource("liveSmartFields");
  const { data: styles } = useEditorResource("styles");

  const [slateEditor] = useState(() =>
    withLists(listSchema)(withInlines(withReact(withHistory(createEditor()))))
  );

  const color =
    element?.attributes?.style?.color ||
    styles?.text?.[element?.type]?.color ||
    "";

  const style = {
    ...styles?.text?.[element?.type],
    color,
  };

  const renderElement = useCallback((slateProps) => {
    const Element = elementMap?.[slateProps.element.type] || DefaultElement;
    return <Element {...slateProps} />;
  }, []);

  const renderLeaf = useCallback((slateProps) => {
    return <Leaf {...slateProps} />;
  }, []);

  const onKeyDown = (event) => {
    if (event.key === "Enter") {
      if (ListsEditor.isAtList(slateEditor)) {
        const color = getActiveColor(slateEditor);
        textContext.current.lastListColor = color;
      }
    } else if (event.key === "ArrowLeft") {
      event.stopPropagation();
    } else if (event.key === "ArrowRight") {
      event.stopPropagation();
    } else if (event.ctrlKey || event.metaKey) {
      if (event.key === "z") {
        event.stopPropagation();
        if (event.shiftKey) {
          HistoryEditor.redo(slateEditor);
        } else {
          HistoryEditor.undo(slateEditor);
        }
      } else if (event.key in textEditorShortKeys) {
        event.preventDefault();
        const { type, key } = textEditorShortKeys[event.key];
        if (type === "inline") {
          toggleInline(slateEditor, key);
        }
      }
    } else if (event.key === "Backspace" || event.key === "Delete") {
      const [block] = Editor.nodes(slateEditor, {
        match: (n) =>
          !Editor.isBlock(slateEditor, n) && n.type === "smart-field",
      });
      const [, blockPath] = block || [];
      if (block?.[0]?.type === "smart-field") {
        event.preventDefault();
        event.stopPropagation();
        Transforms.delete(slateEditor, {
          at: blockPath,
        });
      } else if (ListsEditor.isAtStartOfListItem(slateEditor)) {
        event.preventDefault();
        ListsEditor.decreaseDepth(slateEditor);
      }
    }
    slateList_onKeyDown(slateEditor, event);
  };

  const syncEditorState = (override = false) => {
    const hasChanges = !isEqual(slateEditor.children, slateContent);
    if (hasChanges || override) {
      updateContent(slateEditor, slateContent);
    }
  };

  useEffect(() => {
    if (version !== editor?.state?.versionId && !props?.isSelected) {
      setVersion(editor?.state?.versionId);
      syncEditorState();
    }
  }, [editor?.state?.versionId]);

  useEffect(() => {
    if (!props?.isSelected) {
      syncEditorState(true);
    }
  }, [editor?.state?.syncRenderState]);

  const handleSave = () => {
    const content = convertToHtml(slateEditor.children, { liveSmartFields });
    modifyContent.merge(editor, props?.location, {
      slateContent: slateEditor.children,
      content,
    });
  };

  useEffect(() => {
    if (!isInitial.current && !props?.isSelected && !hasFocus) {
      handleSave();
    } else {
      isInitial.current = false;
    }
  }, [props?.isSelected]);

  const onChange = (slateContent) => {
    const anchor = slateEditor?.selection?.anchor || null;
    if (anchor !== null) {
      const { text = "" } = slateContent[anchor?.path?.[0]]?.children?.[
        anchor?.path?.[1]
      ] || { text: "" };
      if (
        anchor?.offset === 2 &&
        anchor?.path[1] === 0 &&
        text.startsWith("- ")
      ) {
        const at = {
          ...slateEditor.selection,
          anchor: { ...anchor, offset: 0 },
        };
        Transforms.delete(slateEditor, { at });
        toggleBlock(slateEditor, "unordered-list");
      } else if (
        anchor.offset === 3 &&
        anchor.path[1] === 0 &&
        text.startsWith("1. ")
      ) {
        const at = {
          ...slateEditor.selection,
          anchor: { ...anchor, offset: 0 },
        };
        Transforms.delete(slateEditor, { at });
        toggleBlock(slateEditor, "ordered-list");
      }
    }
    textContext.current.blockPathString = "";
  };

  const onKeyUp = () => {
    if (textContext?.current?.lastListColor) {
      updateColor(slateEditor, "color", textContext?.current?.lastListColor);
      textContext.current.lastListColor = "";
    }
  };

  const editorProps = {
    onKeyDown,
    onKeyUp,
    onBlur: handleSave,
    renderElement,
    renderLeaf,
    style,
    readOnly: !props?.isSelected,
    placeholder: "Write something...",
    scrollSelectionIntoView: () => {},
  };

  const slateProps = {
    editor: slateEditor,
    initialValue: slateContent,
    onChange,
  };

  return {
    slateProps,
    editorProps,
    handleSave,
  };
};
