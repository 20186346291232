import { MenuItem } from "@material-ui/core";
import { css } from "emotion";
import { Minus, Plus } from "phosphor-react";
import { useMemo, useState } from "react";
import { Div, Select } from "../../shared/components";
import { flex } from "../../shared/shared-styles";
import { colors } from "../../shared/styles";
import { FieldInput, getStoredValue } from "./field-input";
import { getFieldProps } from "./field-utils";
import { LayoutContainer, LayoutSelect } from "./layout-container";

export const valueTypeStyle = (isSelected) => css`
  ${flex("center")}
  height: 40px;
  width: 40px;
  outline: 1px solid ${colors.gray[300]};
  ${isSelected && `outline: 1px solid black;`}
  border-radius: 8px;
  background-color: white;
  :hover {
    background-color: ${colors.gray[100]};
  }
  margin-right: 4px;
  padding: 0 2px;
  cursor: pointer;
`;

export const AdvancedInputField = ({ state, field, config }) => {
  const { product } = state;

  const { PropertyChain } = field;
  const [stateSyncedAt, setStateSyncedAt] = useState("");

  const fieldProps = getFieldProps(state, field, config);
  const { value, updateProperty } = fieldProps;

  const getFieldLayout = (type, key) => ({
    Type: type,
    PropertyChain: `${PropertyChain}.${key}`,
    productId: product?.ID,
    key,
  });

  const handlePasteValue = (fieldLayout, data) => {
    try {
      const splits = data.split(" ");
      const filtered = splits.filter((item) => /\d/.test(item));
      const values = filtered;
      const stringCheck = values.join("");
      let updates = value;

      if (stringCheck.includes("/") || values.length > 1) {
        const specialCopay = values[0].replace(/[^0-9.]/g, "");
        updates.ADOrCopay = "COPAY + %";
        updates.SpecialCopay = getStoredValue(fieldLayout, specialCopay);
        updates.Value = getStoredValue(fieldLayout, values[1]);
      }

      if (values.length === 1) {
        const dd = getStoredValue(fieldLayout, values[0]);
        updates.Value = dd;
      }

      const dollarIndex = values.at(-1)?.indexOf("$");
      const percentIndex = values.at(-1)?.indexOf("%");

      if (percentIndex > -1 || dollarIndex > -1) {
        if (percentIndex > dollarIndex) {
          updates.PercentOrDollar = "%";
        } else {
          updates.PercentOrDollar = "$";
        }
      }

      updateProperty(PropertyChain, updates);
      setStateSyncedAt(new Date().toISOString());
    } catch (err) {
      console.warn(err);
    }
  };

  const updateType = (e) => {
    const val = e.target.value;

    let updates = {};

    if (val === "COPAY") {
      updates = { PercentOrDollar: "$", ADOrCopay: "COPAY" };
    } else if (val === "COINSURANCE") {
      updates = { PercentOrDollar: "%", ADOrCopay: "COPAY" };
    } else if (val === "COPAY-RANGE") {
      updates = { MaxValue: 0, ADOrCopay: "COPAY", PercentOrDollar: "$" };
    } else if (val === "COPAY + %") {
      updates = { PercentOrDollar: "%" };
    } else if (val === "100% Covered") {
      updates = { Value: "", PercentOrDollar: "" };
    } else if (val === "Not Covered") {
      updates = { Value: "", PercentOrDollar: "" };
    }

    updateProperty(PropertyChain, {
      ...value,
      MaxValue: null,
      ADOrCopay: e.target.value,
      ...updates,
    });
  };

  const isCopayPlusPercentage = value?.ADOrCopay === "COPAY + %";
  const hasPercentOrDollar =
    !isCopayPlusPercentage && value?.ADOrCopay !== "COPAY";
  const isCopayRange = value?.ADOrCopay === "COPAY" && value?.MaxValue !== null;
  const isStaticValue =
    value?.ADOrCopay === "100% Covered" || value?.ADOrCopay === "Not Covered";

  const getProps = (key) => {
    let Type = "dollar";
    if (key === "Value") {
      Type =
        value.PercentOrDollar === "$"
          ? "dollar"
          : value.PercentOrDollar === "%"
          ? "percent"
          : "dollar";
    } else if (key === "SpecialCopay") {
      Type = "dollar";
    }

    const ppp = {
      value: value[key] || "",
      updateProperty,
      handlePasteValue,
      stateSyncedAt,
      field: getFieldLayout(Type, key),
      config: {
        storeValueAs: "number",
      },
    };
    return ppp;
  };

  const fieldType = useMemo(() => {
    if (value?.ADOrCopay === "COPAY" && value?.MaxValue !== null) {
      return "COPAY-RANGE";
    } else if (value?.ADOrCopay === "COPAY" && value?.PercentOrDollar === "%") {
      return "COINSURANCE";
    } else if (value?.ADOrCopay === "COPAY" && value?.PercentOrDollar === "$") {
      return "COPAY";
    }
    return value?.ADOrCopay;
  }, [value]);

  return (
    <Div
      css={css`
        width: 100%;
      `}
    >
      <LayoutContainer {...{ state, field, config }}>
        <Div
          className={css`
            width: calc(100% - 8px);
          `}
        >
          <Select
            value={fieldType || "AD"}
            onChange={updateType}
            css={`
              width: calc(100% - 8px);
              ${isStaticValue
                ? `
                margin: 8px 0;
              `
                : `
                border-bottom: 1px solid ${colors.gray[300]};
                border-bottom-left-radius: 0;
                border-bottom-right-radius: 0;
              `}
              margin: 0 8px;
              .MuiOutlinedInput-notchedOutline {
                :active,
                :hover {
                  outline: none;
                  border: none;
                }
                border: none;
              }
            `}
          >
            <MenuItem value="AD">After Deductible</MenuItem>
            <MenuItem value="COPAY">Copay</MenuItem>
            <MenuItem value="COPAY-RANGE">Copay Range</MenuItem>
            <MenuItem value="COINSURANCE">Coinsurance</MenuItem>
            <MenuItem value="COPAY + %">Copay + Percentage</MenuItem>
            <MenuItem value="100% Covered">100% Covered</MenuItem>
            <MenuItem value="Not Covered">Not Covered</MenuItem>
          </Select>
          {isStaticValue ? null : (
            <Div
              className="layout-item"
              css={css`
                width: 100%;
                ${flex("space-between")}
                ${isCopayPlusPercentage
                  ? `
            `
                  : ""}
                border: 1px solid ${colors.gray[300]};
                border-radius: 8px;
                :hover {
                  border: 1px solid ${colors.black};
                }
              `}
            >
              {isCopayPlusPercentage ? (
                <>
                  <FieldInput
                    {...getProps("SpecialCopay")}
                    disabled={!isCopayPlusPercentage}
                  />
                  <Div
                    css={css`
                      border-left: 1px solid ${colors.gray[300]};
                      border-right: 1px solid ${colors.gray[300]};
                      padding: 0 8px;
                      margin: 0 8px;
                      ${flex("center")}
                      svg {
                        min-width: 24px;
                      }
                    `}
                  >
                    <Plus size={24} />
                  </Div>
                </>
              ) : null}
              <FieldInput {...getProps("Value")} />
              {isCopayRange ? (
                <>
                  <Div
                    css={css`
                      border-left: 1px solid ${colors.gray[300]};
                      border-right: 1px solid ${colors.gray[300]};
                      padding: 0 8px;
                      margin: 0 8px;
                      ${flex("center")}
                      svg {
                        min-width: 24px;
                      }
                    `}
                  >
                    <Minus size={24} />
                  </Div>
                  <FieldInput {...getProps("MaxValue")} />
                </>
              ) : hasPercentOrDollar ? (
                <LayoutSelect
                  value={value?.PercentOrDollar}
                  onChange={(e) =>
                    updateProperty(
                      `${PropertyChain}.PercentOrDollar`,
                      e.target.value
                    )
                  }
                >
                  <MenuItem value="$">Dollar</MenuItem>
                  <MenuItem value="%">Percent</MenuItem>
                </LayoutSelect>
              ) : null}
            </Div>
          )}
        </Div>
      </LayoutContainer>
    </Div>
  );
};
