import { css } from 'emotion';
import { Button, Div, Text } from "../../../../shared/components";
import { colors } from "../../../../shared/styles";
import { AlphaPicker, CustomPicker, HuePicker } from 'react-color'
import { EditableInput, Saturation } from "react-color/lib/components/common";
import { colorButton } from './color-picker';
import { flex, px } from '../../../../shared/shared-styles';

export const ColorCreatorModal = CustomPicker(({ designStyleColors, ...rest }) => {

  const handleAlphaChange = (color) => {
    rest.onChange({ ...color }, color.rgb.a);
  }

  const props = { ...rest, onChange: (color) => rest.onChange(color, null) }
  const handleRGBChange = (change) => rest.onChange({ ...rest.rgb, ...change }, null);

  return (
    <Div
      onMouseDown={(e) => e.stopPropagation()}
      onClick={(e) => e.stopPropagation()}
    >
      <Div css={css`${flex('jcc ais')}`}>
        <Div className={css`
          position: relative;
          width: 200px;
          height: 200px;
          border-radius: 4px;
          overflow: hidden;
        `}
        >
          <Saturation {...props} />
        </Div>
        <Div css={css`margin-left: ${px.md};`}>
          <Text>HEX</Text>
          <EditableInput
            label="hex"
            value={rest.hex}
            onChange={props.onChange}
            style={{ label: { display: 'none' }, input: { border: `1px solid ${colors.gray[300]}`, padding: '8px', borderRadius: '8px', width: '100%' } }}
          />

          <Div css={css`${flex('jcsb aic')} margin: ${px.sm} 0;`}>
            <Div>
              <Text>R</Text>
              <EditableInput
                label="r"
                value={rest.rgb.r}
                onChange={handleRGBChange}
                style={{ label: { display: 'none' }, input: { border: `1px solid ${colors.gray[300]}`, padding: '8px', borderRadius: '8px', width: '60px' } }}
              />
            </Div>
            <Div>
              <Text>G</Text>
              <EditableInput
                label="g"
                value={rest.rgb.g}
                onChange={handleRGBChange}
                style={{ label: { display: 'none' }, input: { border: `1px solid ${colors.gray[300]}`, padding: '8px', borderRadius: '8px', width: '60px' } }}
              />
            </Div>
            <Div>
              <Text>B</Text>
              <EditableInput
                label="b"
                value={rest.rgb.b}
                onChange={handleRGBChange}
                style={{ label: { display: 'none' }, input: { border: `1px solid ${colors.gray[300]}`, padding: '8px', borderRadius: '8px', width: '60px' } }}
              />
            </Div>
          </Div>

          <Div css={css`${flex('jcsb aic')} margin: ${px.md} 0;`}>
            <Div>
              <Div className={css`position: relative; width: 150px; border-radius: 0; overflow: hidden;`}>
                <HuePicker {...props} width="150px" />
              </Div>
              <Div
                className={css`margin-top: ${px.md}; position: relative; width: 150px; border-radius: 0; overflow: hidden;`}
              >
                <AlphaPicker {...props} onChange={handleAlphaChange} width="150px" />
              </Div>
            </Div>
            <Button
              styles="naked mr"
              className={colorButton(rest.color)}
            />
          </Div>

        </Div>

      </Div>

    </Div>
  );
});