import { css } from "emotion";
import { X, Trash } from "phosphor-react";
import { useState } from "react";
import { toast } from "react-toastify";
import {
  Button,
  Div,
  Loader,
  Modal,
  Text,
  ConfirmationModal,
} from "../shared/components";
import { flex } from "../shared/shared-styles";
import { colors } from "../shared/styles";
import { CustomAxios } from "../redux/axios/axios";
import { PRODUCT_HEADERS } from "../benefit-package/constants";
import { productUtils } from "./configs/product-utils";

const productsWithBuyups = Object.keys(PRODUCT_HEADERS);

const networkMedicalMap = {
  core: "Core Medical",
  buyup: "Buy Up Network",
  narrow: "Narrow Network",
};

const networkMapAll = {
  core: "Core",
  buyup: "Buy Up",
};

const emptyNetworkAction = ["", ""]; // [modalType, networkKey]

export const getNetworkMap = (type) =>
  type === "insurance_plan" ? networkMedicalMap : networkMapAll;

export const MultiNetwork = ({ state }) => {
  const { product, network, hasChanges, refetch, isLoading, setLoadState } =
    state;

  const core = network.getProductByCategory("core");
  const [networkAction, setNetworkAction] = useState(emptyNetworkAction);

  if (!productsWithBuyups.includes(product?.Type)) {
    return null;
  }

  const networkMap = getNetworkMap(core?.Type);

  const handleNetwork = (key) => {
    if (product?.Details?.MultiCarrierID) {
      setNetworkAction(["error"]);
    } else {
      setLoadState(true);
      const value = network?.activeCategories?.[key];
      if (hasChanges) {
        toast.warn(`Save changes before switching networks.`);
      } else if (!value) {
        setNetworkAction(["add-network", key]);
      } else {
        network.setCategoryByProduct(key);
      }
      setLoadState(false);
    }
  };

  const handleCreate = async () => {
    try {
      setLoadState(true);
      setNetworkAction(emptyNetworkAction);
      await productUtils.createNetwork(state, networkAction[1]);
      network.setCategoryByProduct(networkAction[1]);
      toast.success(`Successfully creating ${networkMap[networkAction[1]]}`);
    } catch (err) {
      console.warn(err);
    } finally {
      setLoadState(false);
    }
  };

  const handleDelete = async () => {
    try {
      setLoadState(true);
      const product = network.getProductByCategory(networkAction[1]);
      setNetworkAction(emptyNetworkAction);
      await CustomAxios.delete(`/v1/plans/${product?.ID}`);
      if (Object.keys(network.activeCategories).length === 2) {
        await CustomAxios.put(`/v2/product/${core.ID}`, {
          ...core,
          MultiNetworkID: null,
          MultiNetworkCategory: "",
        });
      }
      network.setCategoryByProduct("core");

      await refetch();

      toast.success("Network deleted!");
    } catch (e) {
      toast.error("Network did not delete!");
    } finally {
      setLoadState(false);
    }
  };

  const networkActionProductName =
    networkAction[0] === "delete-network"
      ? network.getProductByCategory(networkAction[1])?.Details?.NetworkName ||
        networkMap[networkAction[1]]
      : {};

  return (
    <Div
      css={css`
        width: 100%;
        margin-bottom: 24px;
        margin-top: 24px;
      `}
    >
      <Div
        css={css`
          ${flex("space-evenly")} width: 100%;
          margin: 8px 0;
          border-bottom: 1px solid ${colors.gray[300]};
        `}
      >
        {Object.entries(networkMap).map(([key, value]) => (
          <Div
            key={key}
            styles={network?.category === key ? "tab-active" : "tab-inactive"}
            className={css`
              color: ${!!network?.activeCategories?.[key]
                ? colors.black
                : colors.gray[400]};
              height: 56px;
            `}
            onClick={() => handleNetwork(key)}
          >
            <span
              className={css`
                flex-grow: 1;
              `}
            >
              {value}
            </span>
            {key !== "core" && network?.activeCategories?.[key] ? (
              <Button
                onClick={(e) => {
                  e.stopPropagation();
                  setNetworkAction(["delete-network", key]);
                  // handleDelete(e, network?.activeCategories?.[key])
                }}
                styles="icon sm"
                hoverLabel={"Delete Network"}
                disabled={!network?.activeCategories?.[key]}
              >
                <Trash
                  color={
                    !!network?.activeCategories?.[key]
                      ? colors.black
                      : colors.gray[400]
                  }
                />
              </Button>
            ) : null}
          </Div>
        ))}
      </Div>
      {networkAction[0] === "add-network" && (
        <Modal
          display={networkAction[0] === "add-network"}
          onClose={() => setNetworkAction(emptyNetworkAction)}
        >
          <Div
            css={css`
              width: 500px;
            `}
          >
            <Div
              css={css`
                ${flex("space-between")} margin: 16px 32px;
              `}
            >
              <Text h2>{networkMap[networkAction[1]]}</Text>
              <Button
                styles="icon"
                onClick={() => setNetworkAction(emptyNetworkAction)}
              >
                <X />
              </Button>
            </Div>
            <Text
              className={css`
                margin: 0 32px;
              `}
              label
            >
              You haven't added a {networkMap[networkAction[1]]} yet.
            </Text>
            <Div
              css={css`
                ${flex("right")} margin: 32px;
              `}
            >
              <Button
                className={css`
                  margin-right: 8px;
                `}
                styles="secondary"
                onClick={() => setNetworkAction(emptyNetworkAction)}
              >
                Close
              </Button>
              <Button
                disabled={isLoading}
                onClick={handleCreate}
                className={css`
                  position: relative;
                `}
              >
                <Loader
                  type="three-dots"
                  isLoading={isLoading}
                  className={css`
                    ${flex("center")} background-color: ${colors.gray[300]};
                    padding-bottom: 16px;
                  `}
                />
                Add {networkMap[networkAction[1]]}
              </Button>
            </Div>
          </Div>
        </Modal>
      )}

      <Modal
        display={networkAction[0] === "delete-network"}
        onClose={() => setNetworkAction(emptyNetworkAction)}
      >
        <Div
          css={css`
            width: 500px;
            padding: 24px;
          `}
        >
          <Div
            css={css`
              ${flex("space-between")}
            `}
          >
            <Text h2>Delete Network</Text>
            <Button styles="icon">
              <X />
            </Button>
          </Div>
          <Div>
            <Text
              label
              css={`
                padding: 8px;
                margin: 16px 0;
                margin-bottom: 24px;
                border-radius: 8px;
                background-color: ${colors.gray[200]};
              `}
            >
              {networkActionProductName}
            </Text>
            <Text css={``}>
              Are you sure you want to delete this Netwrok? This action cannot
              be undone.
            </Text>
          </Div>
          <Div
            css={css`
              ${flex("right")}
            `}
          >
            <Button
              secondary
              css={`
                margin-right: 16px;
              `}
              onClick={() => setNetworkAction(emptyNetworkAction)}
            >
              Cancel
            </Button>
            <Button
              onClick={handleDelete}
              css={`
                background-color: ${colors.red[100]};
              `}
            >
              Delete
            </Button>
          </Div>
        </Div>
      </Modal>

      <ConfirmationModal
        title="Adding Network Error"
        subtitle="A network cannot be added with multiple carriers."
        display={networkAction === "error"}
        onClose={() => setNetworkAction(emptyNetworkAction)}
        onConfirm={() => setNetworkAction(emptyNetworkAction)}
        confirmText="Ok"
      />
    </Div>
  );
};
