import { useState } from "react";

export const useInput = (defaultValue, options = {}) => {
  const { valueFn = null } = options;
  const [value, setValue] = useState(defaultValue);
  const onChange = (event) => {
    let value = event.target.value;
    if (valueFn) {
      value = valueFn(value);
    }
    setValue(value);
  }
  return [
    {
      value,
      onChange
    },
    setValue
  ];
}