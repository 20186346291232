import { MenuItem } from "@material-ui/core";
import { Button, Modal, Select, Text, Toggle } from ".";
import { Box } from "./box";
import { useForm } from "../use-form";
import { useStore } from "../../store-provider/use-store";
import { colors } from "../styles";
import { businessData, useResource } from "../../react-query";
import { useMemo, useState } from "react";
import { Alert } from "./Alert";

// The share body is dissimilar between different resources
// getFormValues should return an object as following:
// { id: '', name: '', shareScope: '', businessId: '' }
//
export const ShareResourceModal = ({
  title,
  onSubmit,
  getFormValues,
  modalProps,
}) => {
  const {
    data: { parentBusiness },
  } = useStore();
  const initialFormValues = getFormValues(modalProps.data);

  const isInitialResourceFromParent =
    parentBusiness?.ID === initialFormValues?.businessId;

  const form = useForm(initialFormValues, [modalProps.isOpen]);
  const [disclaimer, setDisclaimer] = useState(false);

  const businessResource = useResource(businessData, {
    params: {
      businessId: form?.values?.businessId,
    },
  });

  const handleSubmit = () => {
    modalProps?.hide();
    // onSubmit(CHANGES, OLD_VALUES)
    onSubmit(form.values, modalProps.data);
  };
  const toggleAccessFromChild = () => {
    if (form?.values?.businessId === parentBusiness?.ID) {
      form.merge({
        businessId: initialFormValues?.businessId,
        shareScope: "",
      });
    } else {
      form.merge({
        businessId: parentBusiness?.ID,
        shareScope: "inherited",
      });
    }
  };

  const accessText = useMemo(() => {
    if (
      form?.values?.businessId !== parentBusiness?.ID ||
      form?.values?.shareScope === "business"
    ) {
      return `Only "${businessResource?.data?.Name}" can access this resource.`;
    } else if (form?.values?.shareScope === "inherited") {
      return "Everyone in your organization can access this resource.";
    }
  }, [businessResource?.query?.dataUpdatedAt, form?.values?.shareScope]);

  return (
    <Modal display={modalProps?.isOpen} onClose={modalProps?.hide}>
      <Modal.Paper>
        <Modal.Header title={title} onClose={modalProps?.hide} />
        {disclaimer ? (
          <Modal.Body>
            <Alert
              type="info"
              description="Making this template public will move the original template to the parent account."
            />
          </Modal.Body>
        ) : (
          <Modal.Body>
            {isInitialResourceFromParent ? (
              <Select {...form?.getProperty("shareScope")}>
                <MenuItem value="business">Private</MenuItem>
                <MenuItem value="inherited">Public</MenuItem>
              </Select>
            ) : (
              <Box flex="space-between start">
                <Box>
                  <Text
                    css={`
                      margin-bottom: 16px;
                    `}
                    label
                  >
                    Public Access
                  </Text>
                  <Text>
                    Public templates can be accessed from any company within
                    Brite.
                  </Text>
                </Box>
                <Toggle
                  checked={form?.values?.businessId === parentBusiness?.ID}
                  onChange={toggleAccessFromChild}
                />
              </Box>
            )}
            <Box
              css={`
                ${!isInitialResourceFromParent
                  ? `border-top: 1px solid ${colors.gray[300]};`
                  : ""}
                padding: 16px 0;
                margin-top: 16px;
              `}
            >
              <Text label>Access</Text>
              <Box
                css={`
                  margin: 16px 0;
                `}
              >
                <Text label bold>
                  {businessResource?.data?.Name}
                </Text>
                <Text>{accessText}</Text>
              </Box>
            </Box>
          </Modal.Body>
        )}
        <Modal.Actions>
          <Button onClick={modalProps?.hide} secondary>
            Cancel
          </Button>
          <Button
            primary
            onClick={
              !isInitialResourceFromParent && !disclaimer
                ? () => setDisclaimer(true)
                : handleSubmit
            }
            disabled={!form?.hasChanges}
          >
            {disclaimer ? "OK" : "Save"}
          </Button>
        </Modal.Actions>
      </Modal.Paper>
    </Modal>
  );
};
