import { cloneDeep } from "lodash";
import { useEffect } from "react";
import { useDeepCompareMemo } from "use-deep-compare";
import { Div } from "../../shared/components";
import {
  getNewDefaultRow,
  getLayoutSelection,
  removeItem,
  cleanUpRows,
  modifyContent,
  editorUtils,
} from "../provider/utils";
import { getComponent } from "../utils";
import { Tile } from "./tile";

const DragTileMemoized = ({ editor }) => {
  const {
    state: { drag, layout },
  } = editor;

  const { isDragging = false } = drag || {};

  const handleDrop = () => {
    const { position = "", location = [] } = drag?.destination || {};
    if (drag?.action && drag?.action !== "multi-move" && position) {
      let [rowIdx, colIdx, cIdx = null] = location;
      let rows = [...layout?.rows];
      let nextLocation = [];

      let item;

      if (drag?.action === "move") {
        if (
          rowIdx >= drag?.source?.location[0] &&
          drag?.source?.totalColumns === 1
        ) {
          // Adjust the rowIdx because the source row is going to be removed
          rowIdx -= 1;
        }

        if (
          drag?.source?.totalColumns > 1 &&
          colIdx > drag?.source?.location[1] &&
          rowIdx === drag?.source?.location[0]
        ) {
          // Adjust the colIdx because the source column is going to be removed
          colIdx -= 1;
        }

        if (
          drag?.source?.location?.length === 3 &&
          drag?.source?.totalColumns > 1 &&
          cIdx > drag?.source?.position === "top" &&
          cIdx > drag?.source?.location?.[2] &&
          colIdx === drag?.source?.location[1] &&
          rowIdx === drag?.source?.location[0]
        ) {
          // Adjust the cIdx because the source list item is going to be removed
          cIdx -= 1;
        }

        const { width, ...nextItem } = drag?.source?.data || {};
        item = nextItem;
        rows = removeItem(layout, drag?.source?.location);
      } else if (drag?.action === "create") {
        item = getComponent(drag?.type);
      }

      if (
        position === "row-length" ||
        position === "row-start" ||
        position === "row"
      ) {
        // Handle row level drops
        const row =
          drag?.source?.totalColumns === 1
            ? getLayoutSelection(layout, [drag?.source?.location[0]])
            : getNewDefaultRow(item);

        if (position === "row") {
          rows?.splice(rowIdx + 1, 0, row);
          nextLocation = [rowIdx + 1, 0];
        } else if (position === "row-length") {
          rows.push(row);
          nextLocation = [rows?.length - 1, 0];
        } else if (position === "row-start") {
          rows.unshift(row);
          nextLocation = [0, 0];
        }
      } else if (location?.length) {
        // Handle column level drops
        if (position === "right" || position === "left") {
          const index = position === "left" ? colIdx : colIdx + 1;
          const row = { ...rows?.[rowIdx] };
          let columns = row?.columns;
          if (columns.length === index) {
            columns.push(item);
          } else {
            columns.splice(index, 0, item);
          }

          columns = columns.map(({ width, ...item }) => item);
          rows.splice(rowIdx, 1, { ...row, columns });
          nextLocation = [rowIdx, index];
        } else if (position === "top" || position === "bottom") {
          // Handle list level drops
          const row = { ...rows?.[rowIdx] };
          let columns = [...row?.columns];
          const column = row?.columns?.[colIdx] || {};
          if (column?.type !== "list") {
            const { width = null, ...columnItem } = cloneDeep(column);
            const list =
              position === "top" ? [item, columnItem] : [columnItem, item];
            let nextColumn = { type: "list", list };
            if (width !== null) {
              nextColumn = { ...nextColumn, width };
            }
            columns.splice(colIdx, 1, nextColumn);
            nextLocation =
              position === "top" ? [rowIdx, colIdx, 0] : [rowIdx, colIdx, 1];
          } else {
            const index = position === "top" ? cIdx : cIdx + 1;
            let { list } = column;
            list.splice(index, 0, item);
            columns.splice(colIdx, 1, { ...column, list });
            nextLocation = [rowIdx, colIdx, index];
          }

          if (columns.length === 1 && columns?.[0]?.type === "list") {
            rows.splice(rowIdx, 1, {
              ...row,
              columns: [columns?.[0]?.list?.[0]],
            });
            if (columns?.[0]?.list?.length > 1) {
              const newList = columns?.[0]?.list.slice(1);
              const newRows = newList.map((item) => getNewDefaultRow(item));
              rows.splice(rowIdx + 1, 0, ...newRows);
            }
          } else {
            rows.splice(rowIdx, 1, { ...row, columns });
          }
        }
      }

      if (drag?.action === "create") {
        setTimeout(() => {
          editorUtils?.setProperty(editor, "selection", nextLocation);
        }, 0);
      } else {
        editorUtils?.setProperty(editor, "selection", null);
      }

      rows = cleanUpRows(rows);
      modifyContent?.set(editor, [], rows, { syncRenderState: true });
    } else if (drag?.action === "multi-move" && position) {
      // Handle moving multi selected groups
      let [rowIdx] = drag?.destination?.location;
      let rows = [...layout.rows];
      const { start, end } = drag?.source;
      const count = end - start + 1;
      if (rowIdx > end) {
        rowIdx -= count;
      }
      const copiedRows = rows.filter((_, idx) => idx >= start && idx <= end);
      rows = rows.filter((_, idx) => !(idx >= start && idx <= end));
      if (position === "row") {
        rows?.splice(rowIdx + 1, 0, ...copiedRows);
      } else if (position === "row-length") {
        rows = [...rows, ...copiedRows];
      } else if (position === "row-start") {
        rows = [...copiedRows, ...rows];
      }
      rows = cleanUpRows(rows);
      modifyContent?.set(editor, [], rows, {
        resetSelection: true,
        syncRenderState: true,
      });
    }
  };

  useEffect(() => {
    if (drag?.isDragging) {
      handleDrop();
    }
  }, [drag?.destination?.location]);

  useEffect(() => {
    if (isDragging) {
      editorUtils?.setProperty(editor, "multiSelect", null);
      document.body.style.cursor = "grabbing";
    } else {
      document.body.style.cursor = "default";
    }
  }, [isDragging]);

  return (
    <Div
      style={{
        position: "fixed",
        top: 0,
        bottom: 0,
        right: 0,
        left: 0,
        pointerEvents: "none",
        zIndex: 1000000,
      }}
    >
      {isDragging && <Tile drag={drag} />}
    </Div>
  );
};

export const DragTile = (props) => {
  const Component = useDeepCompareMemo(() => {
    return <DragTileMemoized {...props} />;
  }, [props?.editor?.state?.drag]);
  return Component;
};
