import _ from "lodash";
import React, { useEffect } from "react";
import { useDispatch } from "react-redux";
import { Route, useLocation, matchPath } from "react-router-dom";
import { useAuth0 } from "../Auth/react-auth0-wrapper";
import { setAuthBusinessGod } from "../redux/actions/auth";
import { setBusiness } from "../redux/actions/business";
import { CustomAxios } from "../redux/axios/axios";
import { useStore } from "../store-provider/use-store";
import { UnAuthedLoader } from "./UnAuthedLoader";

const extractPromiseAll = async (list) => {
  const promises = await Promise.all(list.map((url) => CustomAxios.get(url)));
  return promises.map((item) => (item.data));
}

export const PrivateRoute = ({ path, children, ...rest }) => {

  const dispatch = useDispatch();

  const { data: { user, selectedBusiness } } = useStore();
  const { ID: userId = '' } = user;
  const { ID: businessId = '' } = selectedBusiness;

  const { data: { isBriteUser } } = useStore();

  const {
    isAuthenticated,
    loginWithRedirect,
  } = useAuth0();

  const location = useLocation();
  const match = matchPath(location.pathname, { path });

  const getCleanPath = (businessId) => {

    try {
      const params = { ...match.params, businessId };
      return Object.entries(params).reduce((prev, [key, item]) => {
        return prev.replace(`:${key}`, item);
      }, match.path);
    } catch (err) {
      console.warn(err);
      return `${businessId}/courses`;
    }

  }

  const handleNonBriteUser = async (id) => {

    const { data: { Parent, Companies } } = await CustomAxios.get(`v2/business/user/${userId}`);
    const currentBusiness = [Parent, ...(Companies || [])].find(({ ID }) => (ID === id)) || Parent;
    const path = getCleanPath(currentBusiness.ID);

    dispatch(setBusiness({
      ...currentBusiness,
      companies: Companies,
      GodMode: true,
      path,
    }));

    dispatch(setAuthBusinessGod({
      ...Parent,
      companies: Companies
    }));

  }

  const handleBriteUser = async (id) => {
    let { data } = await CustomAxios.get(`v2/business/${id}`);
    const [companies, parent] = await extractPromiseAll([
      `/v2/business/${data.ParentID}/children`,
      `v2/business/${data.ParentID}`
    ]);

    const path = getCleanPath(data.ID);
    dispatch(setBusiness({
      ...data,
      companies,
      GodMode: true,
      path
    }));

    dispatch(setAuthBusinessGod({ ...parent, companies }));

  }

  const handleBusinessChange = async (id) => {
    try {
      if (!isBriteUser) {
        handleNonBriteUser(id);
      } else {
        handleBriteUser(id);
      }

    } catch (err) {
      console.warn(err);
    }
  }

  useEffect(() => {
    if (
      match.params.businessId !== businessId &&
      isBriteUser !== null
    ) {
      handleBusinessChange(match.params.businessId);
    }
  }, [
    match.params.businessId,
    businessId,
    isBriteUser
  ]);

  useEffect(() => {
    const fn = async () => {
      if (!isAuthenticated) {
        await loginWithRedirect({
          redirect_uri: window.location.origin,
          appState: { targetUrl: location.pathname },
        });
      }
    };
    fn();
  }, [isAuthenticated, loginWithRedirect, location.pathname]);

  return isAuthenticated
    ? <Route path={path} children={children} exact {...rest} />
    : <Route path={path} children={<UnAuthedLoader />} exact {...rest} />;
}