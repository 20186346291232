import { css } from "emotion";
import { Plus } from "phosphor-react";
import { forwardRef, memo, useMemo, useState } from "react";
import { Button, Div, DropMenu, Text } from "../../../../shared/components";
import { colors } from "../../../../shared/styles";
import { useEditor } from "../../../provider/use-editor-store";
import { useStateSync } from "../../../../shared/use-state-sync";
import { useSearchParams } from "../../../../shared/use-search-params";
import { ColorCreator } from "./color-creator";
import { Checkboard } from "react-color/lib/components/common";
import { Tooltip } from "@material-ui/core";
import { flex, px } from "../../../../shared/shared-styles";
import { useEditorResource } from "../../../use-editor-resource";

const transparentColor = "rgba(255, 255, 255, 0)";

export const colorButton = (color, highlightColor) => css`
  transition: background-color 0.3s ease;
  position: relative;
  overflow: hidden;
  cursor: pointer;
  min-width: 32px;
  width: 32px;
  height: 32px;
  background-color: ${color};
  outline: 1px solid #00000022;
  ${highlightColor
    ? `outline: 4px solid ${colors.gray[300]};`
    : `outline: 1px solid #00000022;`}
  border-radius: 50%;
  padding: 8px;
  margin: 8px;
  :hover {
    outline: 4px solid ${colors.gray[300]};
  }
  > div {
    position: absolute;
    top: -8px;
    bottom: -8px;
    left: -8px;
    right: -8px;
    transition: background-color 0.3s ease;
    background-color: ${color};
  }
`;

const plusButton = css`
  border: 1px solid ${colors.gray[400]};
  border-radius: 50%;
  width: 32px;
  height: 32px;
  padding: 4px;
  margin-right: 1px;
  margin-left: 2px;
  pointer-events: none;
`;

const ColorButton = forwardRef(
  ({ color, isCurrentColor = false, style = {}, name = "", ...rest }, ref) => {
    return (
      <Button
        {...rest}
        ref={ref}
        onMouseDown={(e) => {
          e.preventDefault();
        }}
        className={colorButton(color, isCurrentColor)}
        style={style}
      >
        <>
          <Checkboard />
          <Div />
        </>
      </Button>
    );
  }
);

const colorDisplayName = {
  "accent-color-0": "Accent Color 1",
  "accent-color-1": "Accent Color 2",
  "accent-color-2": "Accent Color 3",
  "accent-color-3": "Accent Color 4",
  "accent-color-4": "Accent Color 5",
  "background-color": "Background Color",
  "button-background-color": "Button Background Color",
  "text-body": "Body",
  "text-h1": "Heading 1",
  "text-h2": "Heading 2",
  "text-h3": "Heading 3",
  "text-h4": "Heading 4",
  "text-subtitle": "Subtitle",
};

export const ColorPicker = memo(
  ({ item, title, updateColor, currentColor }) => {
    const {
      state: { course, saveCourseRecord, editorType },
    } = useEditor();
    const { data: styles } = useEditorResource("styles");
    const { updateParams } = useSearchParams();

    const previewColor = useMemo(() => {
      const current = currentColor?.replace("var(--", "")?.replace(")", "");
      if (current in styles?.general?.colorPalette) {
        return styles?.general?.colorPalette[current];
      }
      return currentColor;
    }, [currentColor, styles?.general?.colorPalette]);

    const [colorPresets, setColorPresets] = useStateSync(
      course?.ColorPickerPresets || [],
      [course?.ColorPickerPresets?.toString()]
    );
    const [displayCreator, setDisplayCreator] = useState(false);

    const addCourseColor = (color) => {
      let colors = course?.ColorPickerPresets || [];
      colors.push(color);
      saveCourseRecord({ ColorPickerPresets: colors });
      setColorPresets(colors);
    };

    const getColorRows = (colors, adjustFirstRow = false) => {
      let list = [];
      let row = [];
      const rowLength = 5;
      for (const color of colors) {
        row.push(color);
        if (
          row.length === rowLength ||
          (list.length === 0 && row.length === rowLength - 1 && adjustFirstRow)
        ) {
          list.push(row);
          row = [];
        }
      }
      if (row.length) {
        list.push(row);
      }
      return list;
    };

    const courseColorsRows = useMemo(
      () => getColorRows(colorPresets || [], true),
      [colorPresets?.toString()]
    );

    const { colorRows, colorCodes } = useMemo(() => {
      const colorList = Object.entries(styles?.general?.colorPalette || {}).map(
        ([name, value]) => ({ name, value })
      );

      const componentList = colorList.reduce((prev, color) => {
        const { name } = color;
        if (
          name.includes(item?.component) &&
          (!item?.type || name.includes(item?.type))
        ) {
          return [...prev, color];
        }
        return prev;
      }, []);

      const colors = [...componentList, ...colorList];
      const list = getColorRows(colors);
      const colorCodes = Object.values(styles?.general?.colorPalette || {});

      return { colorList, colorRows: list, colorCodes };
    }, [styles?.general?.colorPalette, item?.component, item?.type]);

    return (
      <Div
        className={css`
          position: relative;
        `}
        onClick={(e) => {
          e.stopPropagation();
          e.preventDefault();
        }}
      >
        <DropMenu
          title={title}
          displayMenu={!displayCreator}
          onMouseDown={(e) => e.preventDefault()}
          transformOrigin={{ vertical: -36, horizontal: "center" }}
          button={
            <ColorButton
              onMouseDown={(e) => {
                e.preventDefault();
              }}
              color={previewColor}
              style={{
                margin: 0,
                minWidth: "24px",
                width: "24px",
                height: "24px",
              }}
            />
          }
        >
          <Div
            css={css`
              padding: 0 ${px.md};
              width: 280px;
            `}
          >
            <Div
              css={css`
                ${flex("jcsb aic")}
              `}
            >
              <Text styles="label bold">Design Style Colors</Text>
              <Button
                styles="link"
                onClick={() => updateParams({ courseModal: "design-styles" })}
              >
                Edit
              </Button>
            </Div>
            {colorRows?.map((row, idx) => (
              <Div
                css={css`
                  ${flex("aic wrap")}
                  overflow-x: hidden;
                `}
                key={idx}
              >
                {row?.map(({ value, name }) => (
                  <Tooltip title={colorDisplayName[name]}>
                    <ColorButton
                      key={name}
                      color={value}
                      onClick={() => updateColor(`var(--${name})`)}
                      isCurrentColor={
                        currentColor === `var(--${name})` ||
                        currentColor === name
                      }
                    />
                  </Tooltip>
                ))}
                {idx === colorRows?.length - 1 && (
                  <Tooltip title="Transparent">
                    <ColorButton
                      color={transparentColor}
                      onClick={() => updateColor(transparentColor)}
                      isCurrentColor={currentColor === transparentColor}
                    />
                  </Tooltip>
                )}
              </Div>
            ))}

            {editorType === "page" && (
              <>
                <Text styles="label bold mv">Guide Colors</Text>
                {!!courseColorsRows?.length ? (
                  courseColorsRows?.map((row, rowIdx) => (
                    <Div
                      css={css`
                        ${flex("aic")}
                      `}
                      key={rowIdx}
                    >
                      {row?.map((color, idx) => (
                        <Div
                          key={color + idx}
                          css={css`
                            ${flex("aic")}
                          `}
                        >
                          {idx === 0 && rowIdx === 0 && (
                            <Button
                              styles="naked pointer"
                              onClick={(e) => {
                                e.stopPropagation();
                                setDisplayCreator(true);
                              }}
                            >
                              <Plus className={plusButton} size={16} />
                            </Button>
                          )}
                          <ColorButton
                            key={color + idx}
                            color={color}
                            onClick={() => updateColor(color)}
                            isCurrentColor={currentColor?.includes(color)}
                          />
                        </Div>
                      ))}
                    </Div>
                  ))
                ) : (
                  <Button
                    styles="naked pointer"
                    onClick={(e) => {
                      e.stopPropagation();
                      setDisplayCreator(true);
                    }}
                  >
                    <Plus className={plusButton} size={16} />
                  </Button>
                )}
              </>
            )}
          </Div>
        </DropMenu>

        <ColorCreator
          display={displayCreator}
          onClose={() => setDisplayCreator(false)}
          addCourseColor={addCourseColor}
          designStyleColors={colorCodes}
        />
      </Div>
    );
  },
  (prev, next) => {
    return (
      prev.currentColor === next.currentColor &&
      prev.updateColor === next.updateColor
    );
  }
);

ColorPicker.tranparentColor = transparentColor;
