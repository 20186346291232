import { MenuItem } from "@material-ui/core";
import { Button, Div, DropMenu, Text, Slider } from "../../shared/components";
import { ImagesModal } from "./menu-components/images-modal";
import { Alignment } from "./menu-components/alignment";
import {
  LinkSimple,
  PencilSimple,
  SquaresFour,
  UploadSimple,
} from "phosphor-react";
import { UploadImage } from "./menu-components/upload-image";
import { css } from "emotion";
import { useStateSync } from "../../shared/use-state-sync";
import { useDebounce } from "../../shared/use-debounce";
import { ColorPicker } from "./menu-components";
import { ImageCropper } from "./menu-components/image-cropper";
import { flex, px } from "../../shared/shared-styles";
import { ImageSettings } from "./menu-components/image-settings";
import { useEditorResource } from "../use-editor-resource";
import { modifyContent } from "../provider/utils";
import { ActionsModal } from "./menu-components/actions-modal";

export const ImageToolbar = ({ editor, location, element, toolbar }) => {
  const { data: styles } = useEditorResource("styles");

  const onSet = (changes) => modifyContent.set(editor, location, changes);
  const onUpdate = (updates) => modifyContent.merge(editor, location, updates);

  const combinedStyles = {
    ...styles.image,
    ...(element?.attributes?.style || {}),
  };

  const rawWidth = combinedStyles?.width || "";
  const [width, setWidth] = useStateSync(
    Number(rawWidth.replace(/\D/g, "") || 0),
    [rawWidth]
  );

  const updateContainerStyle = (style) => {
    onUpdate({
      container: {
        attributes: { style },
      },
    });
  };

  const updateWidth = useDebounce((value) => {
    onUpdate({ attributes: { style: { width: `${value}%` } } });
  }, 25);

  const changeWidth = (value) => {
    setWidth(value);
    updateWidth(value);
  };

  const updateImage = (type, attributes) => {
    if (type === "logos") {
      const style = {
        borderTopLeftRadius: "0",
        borderTopRightRadius: "0",
        borderBottomLeftRadius: "0",
        borderBottomRightRadius: "0",
      };
      onUpdate({
        attributes: {
          ...attributes,
          style,
        },
        container: { attributes: { style } },
      });
    } else {
      onUpdate({
        attributes,
      });
    }
  };

  const removeEdits = () => {
    const { crop, ...rest } = element;
    onSet(rest);
  };

  return (
    <Div onMouseDown={(e) => e.stopPropagation()}>
      <Div
        css={css`
          ${flex("jcl aic")}
        `}
      >
        <DropMenu
          button={
            <Button styles="secondary">
              {element?.attributes?.src ? "Replace" : "Upload"} Image
            </Button>
          }
        >
          <MenuItem onClick={() => toolbar.setModal("image-upload")}>
            <UploadSimple size={24} />
            <Text styles="label padl-sm">Upload Image</Text>
          </MenuItem>
          <MenuItem onClick={() => toolbar.setModal("image-library")}>
            <SquaresFour size={24} />
            <Text styles="label padl-sm">Image Library</Text>
          </MenuItem>
          <MenuItem onClick={() => toolbar.setModal("image-settings")}>
            <LinkSimple size={24} />
            <Text styles="label padl-sm">Image Link</Text>
          </MenuItem>
        </DropMenu>
        <Alignment onUpdate={onUpdate} item={element} hideVertical={true} />
        <Button
          hoverLabel="Link"
          styles={`${
            element?.link || element?.action ? "icon-active" : "icon"
          } sm`}
          onClick={() => toolbar.setModal("image-actions")}
        >
          <LinkSimple size={24} />
        </Button>
        <Button
          hoverLabel="Edit"
          styles="icon sm mh-xs"
          onClick={() => toolbar.setModal("image-edit")}
          disabled={!element?.attributes?.src}
        >
          <PencilSimple size={24} />
        </Button>
        <Div
          css={css`
            margin-left: 8px;
          `}
        />
        <ColorPicker
          item={element}
          title="Background Color"
          updateColor={(backgroundColor) =>
            updateContainerStyle({ backgroundColor })
          }
          currentColor={
            element?.container?.attributes?.style?.backgroundColor ||
            "rgba(0, 0, 0, 0);"
          }
        />
        <Div
          css={css`
            margin-right: 8px;
          `}
        />
        <Slider
          aria-label="Width"
          min={0}
          max={100}
          step={1}
          value={width || 0}
          size="8px"
          style={{ width: "120px" }}
          valueLabelDisplay="auto"
          onChange={(e, value) => changeWidth(value)}
        />
      </Div>
      <UploadImage
        display={toolbar?.modal === "image-upload"}
        onClose={() => toolbar.setModal("")}
        updateImage={updateImage}
      />
      <ImagesModal
        display={toolbar?.modal === "image-library"}
        onClose={() => toolbar.setModal("")}
        updateImage={updateImage}
      />
      <ImageSettings
        display={toolbar?.modal === "image-settings"}
        onClose={() => toolbar.setModal("")}
        onUpdate={onUpdate}
        item={element}
      />
      <ImageCropper
        display={toolbar?.modal === "image-edit"}
        onClose={() => toolbar.setModal("")}
        src={element?.attributes?.src || ""}
        currentCrop={element?.crop}
        onUpdate={onUpdate}
        removeEdits={removeEdits}
      />
      <ActionsModal
        hideBtnText={true}
        allowClear={true}
        display={toolbar?.modal === "image-actions"}
        onClose={() => toolbar.setModal("")}
        onUpdate={onUpdate}
      />
    </Div>
  );
};
