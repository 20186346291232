import React from "react";

import { useStore } from "../store-provider/use-store";

import { CompanySettings } from "./CompanySettings";
import { BenefitsSettings } from "./BenefitsSettings";
import { Box } from "../shared/components/box";

export const BusinessDetails = () => {
  const {
    data: { selectedBusiness },
    setStore,
  } = useStore();

  return (
    <Box page>
      <CompanySettings {...selectedBusiness} setStore={setStore} />

      <BenefitsSettings {...selectedBusiness} />
    </Box>
  );
};
