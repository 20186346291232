import React, { useState, useEffect, useRef } from "react";
import { css } from "emotion";
import { Layer, Image } from "grommet";
import { connect } from "react-redux";
import { useAuth0 } from "../Auth/react-auth0-wrapper";
import { createBusiness } from "../redux/actions/auth";
import logo from "../images/brite-logo-b.png";
import { isEmpty } from "lodash";
import Button from "@material-ui/core/Button";

import Radio from "@material-ui/core/Radio";
import RadioGroup from "@material-ui/core/RadioGroup";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import FormControl from "@material-ui/core/FormControl";
import Accordion from "@material-ui/core/Accordion";
import AccordionSummary from "@material-ui/core/AccordionSummary";
import AccordionDetails from "@material-ui/core/AccordionDetails";
import Typography from "@material-ui/core/Typography";
import ExpandMoreIcon from "@material-ui/icons/ExpandMore";
import Tooltip from "@material-ui/core/Tooltip";
import HelpOutlineIcon from "@material-ui/icons/HelpOutline";
import IconButton from "@material-ui/core/IconButton";
import TextField from "@material-ui/core/TextField";
import Select from "@material-ui/core/Select";
import OutlinedInput from "@material-ui/core/OutlinedInput";
import InputLabel from "@material-ui/core/InputLabel";
import MenuItem from "@material-ui/core/MenuItem";
import Checkbox from "@material-ui/core/Checkbox";
import { states, industries } from "../constants";

import { makeStyles } from "@material-ui/core/styles";
import CircularProgress from "@material-ui/core/CircularProgress";

const useStyles = makeStyles((theme) => ({
  formControl: {
    minWidth: 300,
  },
}));

export const CollectBusinessInfoModal = (props) => {
  const classes = useStyles();

  const myRef = useRef(null);

  const executeScroll = () => myRef.current.scrollIntoView();
  const { user, setShowBusinessPopup, logout } = useAuth0();

  const [name, setName] = useState("");
  const [type, setType] = useState("");
  const [agree, setAgree] = useState(false);
  const [error, setError] = useState({});
  const [industry, setIndustry] = useState("");
  const [companyState, setCompanyState] = useState("");
  const [employeeCount, setEmployeeCount] = useState(0);
  const [websiteURL, setWebsiteURL] = useState("");
  const [firstName, setFirstName] = useState("");
  const [lastName, setLastName] = useState("");
  const [emailAddress, setEmailAddress] = useState("");

  useEffect(() => {
    if (user) {
      if (user.given_name) {
        setFirstName(user.given_name);
      }
      if (user.family_name) {
        setLastName(user.family_name);
      }
      if (user.email) {
        setEmailAddress(user.email);
      }
    }
  }, [user]);

  const restartSetup = () => {
    setType("");
    setError({});
  };

  const submit = () => {
    executeScroll();
    let errors = {};
    if (!name) {
      errors.name = true;
    }
    if (!agree) {
      errors.agree = true;
    }
    if (type === "single" && !industry) {
      errors.industry = true;
    }
    if (!companyState) {
      errors.companyState = true;
    }
    if (employeeCount <= 0) {
      errors.employeeCount = true;
    }
    if (!websiteURL) {
      errors.websiteURL = true;
    }
    if (!firstName) {
      errors.firstName = true;
    }
    if (!lastName) {
      errors.lastName = true;
    }
    setError(errors);

    if (isEmpty(errors)) {
      props.createBusiness({
        Name: name,
        IsActive: false,
        Type: type,
        LogoURL: "",
        Industry: industry,
        Domain: websiteURL,
        EmployeeCount: employeeCount,
        State: companyState,
        PaymentSource: type,
        user: { firstName, lastName, emailAddress },
        showBusinessPopup: setShowBusinessPopup,
      });
    }
  };

  const clearFieldError = (field) => {
    let errors = { ...error };
    if (errors[field]) {
      delete errors[field];
    }
    setError(errors);
  };

  const getHeaderText = () => {
    let text = "Setup your account";
    if (type === "multi") {
      text = "Setup broker account";
    } else if (type === "single") {
      text = "Setup employer account";
    } else if (type === "carrier") {
      text = "Setup vendor account";
    }

    if (props.loading) {
      text = "Setting up your account...";
    }
    return text;
  };

  return (
    <React.Fragment>
      {props.showBusinessPopup && (
        <Layer
          margin={{ left: "5px", top: "5px", right: "5px", bottom: "5px" }}
          full
          className={css`
            font-weight: 400;
            color: #222021;
            background: white;
            overflow: auto;
          `}
        >
          <div
            ref={myRef}
            className={css`
              display: flex;
              justify-content: space-between;
              margin: 12px;
            `}
          >
            <Image
              src={logo}
              className={css`
                width: 83px;
                height: 35px;
              `}
            />

            <Button
              variant="outlined"
              onClick={() => {
                logout({ returnTo: window.location.origin });
              }}
            >
              Logout
            </Button>
          </div>

          <React.Fragment>
            <div
              className={css`
                font-weight: 400;
                font-size: 30px;
                text-align: center;
                line-height: 28px;
                margin: 20px;
                margin-top: 50px;
                color: #222021;
                margin-bottom: 5px;
              `}
            >
              {getHeaderText()}
            </div>
            <div
              className={css`
                padding: 12px;
                margin: 0 auto;
              `}
            ></div>
          </React.Fragment>

          {props.loading && (
            <div
              className={css`
                margin: 5px auto;
              `}
            >
              <CircularProgress />
            </div>
          )}

          {!props.loading && (
            <React.Fragment>
              {type === "" && (
                <React.Fragment>
                  <div
                    className={css`
                      font-weight: 400;
                      font-size: 20px;
                      text-align: center;
                      line-height: 28px;

                      color: #222021;
                      margin-bottom: 5px;
                    `}
                  >
                    Let's get to know you
                  </div>

                  <div
                    className={css`
                      display: flex;
                      justify-content: center;
                      margin: 24px;
                    `}
                  >
                    <FormControl component="fieldset">
                      <RadioGroup
                        aria-label="account type"
                        name="type"
                        value={type}
                        onChange={(e) => {
                          setType(e.target.value);
                        }}
                      >
                        <FormControlLabel
                          value="multi"
                          control={<Radio color="default" />}
                          label={
                            <div
                              className={css`
                                display: flex;
                                align-items: center;
                              `}
                            >
                              <div>I'm a broker</div>{" "}
                              <Tooltip title="Pick this if you are a benefit brokerage company. Manage benefits for all of the companies in your portfolio.">
                                <IconButton aria-label="help">
                                  <HelpOutlineIcon />
                                </IconButton>
                              </Tooltip>
                            </div>
                          }
                        />
                        <FormControlLabel
                          value="single"
                          control={<Radio color="default" />}
                          label={
                            <div
                              className={css`
                                display: flex;
                                align-items: center;
                              `}
                            >
                              <div>I'm an employer</div>{" "}
                              <Tooltip title="Pick this if you are signing up an individual company.">
                                <IconButton aria-label="help">
                                  <HelpOutlineIcon />
                                </IconButton>
                              </Tooltip>
                            </div>
                          }
                        />
                        <FormControlLabel
                          value="carrier"
                          control={<Radio color="default" />}
                          label={
                            <div
                              className={css`
                                display: flex;
                                align-items: center;
                              `}
                            >
                              <div>I'm a benefit provider/vendor</div>{" "}
                              <Tooltip title="Pick this to manage how your products are presented to brokers, employers, and employees on the Brite platform.">
                                <IconButton aria-label="help">
                                  <HelpOutlineIcon />
                                </IconButton>
                              </Tooltip>
                            </div>
                          }
                        />
                        <FormControlLabel
                          value="other"
                          control={<Radio color="default" />}
                          label="My company is already using Brite"
                        />
                      </RadioGroup>
                    </FormControl>
                  </div>

                  <div
                    className={css`
                      margin: 14px auto;
                      font-size: 12px;
                      max-width: 300px;
                      margin-top: 42px;
                    `}
                  >
                    <span
                      className={css`
                        font-weight: bold;
                      `}
                    >
                      Questions?
                    </span>
                    <br />
                    Reach out to our sales team at sales@britebenefits.com
                  </div>
                </React.Fragment>
              )}

              {type === "multi" && (
                <div
                  className={css`
                    display: flex;
                    justify-content: center;
                    flex-wrap: wrap-reverse;
                    margin: 14px;
                    margin-top: 0px;
                  `}
                >
                  <div>
                    {!isEmpty(error) && (
                      <div
                        className={css`
                          color: red;
                          margin-top: 8px;
                          max-width: 300px;
                        `}
                      >
                        {error.agree ? "Must agree to Terms of Use. " : ""}
                        All fields required.
                      </div>
                    )}
                    <div
                      className={css`
                        margin: 12px;
                        font-weight: bold;
                      `}
                    >
                      Company Information
                    </div>
                    <div
                      className={css`
                        margin: 14px;
                      `}
                    >
                      <TextField
                        error={error.name}
                        className={classes.formControl}
                        label="Company Name"
                        variant="outlined"
                        value={name}
                        onChange={(e) => {
                          clearFieldError("name");
                          setName(e.target.value);
                        }}
                      />
                    </div>

                    <div
                      className={css`
                        margin: 14px;
                      `}
                    >
                      <FormControl
                        variant="outlined"
                        className={classes.formControl}
                        error={error.companyState}
                      >
                        <InputLabel id="state">State</InputLabel>
                        <Select
                          labelId="state"
                          id="select-state"
                          value={companyState}
                          onChange={(e) => {
                            clearFieldError("companyState");
                            setCompanyState(e.target.value);
                          }}
                          input={<OutlinedInput label="State" />}
                        >
                          {states.map((state) => (
                            <MenuItem key={state.id} value={state.id}>
                              {state.label}
                            </MenuItem>
                          ))}
                        </Select>
                      </FormControl>
                    </div>
                    <div
                      className={css`
                        margin: 14px;
                      `}
                    >
                      <TextField
                        className={classes.formControl}
                        error={error.websiteURL}
                        label="Website URL"
                        variant="outlined"
                        value={websiteURL}
                        onChange={(e) => {
                          clearFieldError("websiteURL");
                          setWebsiteURL(e.target.value);
                        }}
                      />
                    </div>

                    <div
                      className={css`
                        margin: 14px;
                      `}
                    >
                      <TextField
                        className={classes.formControl}
                        label="# of Companies in Portfolio"
                        error={error.employeeCount}
                        type="number"
                        variant="outlined"
                        onChange={(e) => {
                          clearFieldError("employeeCount");
                          setEmployeeCount(Number(e.target.value));
                        }}
                        value={employeeCount}
                      />
                    </div>

                    <div
                      className={css`
                        margin: 12px;
                        font-weight: bold;
                      `}
                    >
                      Your Information
                    </div>
                    <div
                      className={css`
                        margin: 14px;
                      `}
                    >
                      <TextField
                        className={classes.formControl}
                        label="First Name"
                        error={error.firstName}
                        variant="outlined"
                        value={firstName}
                        onChange={(e) => {
                          clearFieldError("firstName");
                          setFirstName(e.target.value);
                        }}
                      />
                    </div>

                    <div
                      className={css`
                        margin: 14px;
                      `}
                    >
                      <TextField
                        className={classes.formControl}
                        error={error.lastName}
                        label="Last Name"
                        variant="outlined"
                        value={lastName}
                        onChange={(e) => {
                          clearFieldError("lastName");
                          setLastName(e.target.value);
                        }}
                      />
                    </div>

                    <div
                      className={css`
                        margin: 14px;
                      `}
                    >
                      <TextField
                        className={classes.formControl}
                        label="Email"
                        variant="outlined"
                        value={emailAddress}
                        disabled
                      />
                    </div>

                    <div
                      className={css`
                        margin: 28px;
                        max-width: 300px;
                      `}
                    >
                      <FormControlLabel
                        control={
                          <Checkbox
                            checked={agree}
                            color="default"
                            onChange={(e) => {
                              if (e.target.checked) {
                                clearFieldError("agree");
                              }

                              setAgree(e.target.checked);
                            }}
                            name="agree"
                          />
                        }
                        label={
                          <span>
                            I agree to the{" "}
                            <a
                              className={css`
                                color: blue;
                                text-decoration: none;
                                &:hover {
                                  color: black;
                                  text-decoration: none;
                                }
                                &:visited {
                                  color: blue;
                                  text-decoration: none;
                                }
                              `}
                              href="https://share.hsforms.com/18ZIPbaK4QQGU3xaJ_0N2MA3ihp9"
                              target="_blank"
                            >
                              Terms of Use.{" "}
                            </a>
                            I understand the{" "}
                            <a
                              className={css`
                                color: blue;
                                text-decoration: none;
                                &:hover {
                                  color: black;
                                  text-decoration: none;
                                }
                                &:visited {
                                  color: blue;
                                  text-decoration: none;
                                }
                              `}
                              href="https://share.hsforms.com/1UQbu6cvVR5-AJ09KpbxSjA3ihp9"
                              target="_blank"
                            >
                              Privacy Policy
                            </a>
                            .
                          </span>
                        }
                      />
                    </div>
                    <div
                      className={css`
                        margin: 14px;
                        max-width: 300px;
                      `}
                    >
                      <Button
                        className={classes.formControl}
                        variant="contained"
                        color="primary"
                        onClick={() => {
                          submit();
                        }}
                      >
                        Submit
                      </Button>
                    </div>
                  </div>
                  <div>
                    <div
                      className={css`
                        font-weight: bold;
                        margin: 14px;
                      `}
                    >
                      Sign up now - Pay later
                    </div>
                    <div
                      className={css`
                        margin: 14px;
                        font-size: 12px;
                        max-width: 300px;
                      `}
                    >
                      Get started with a free trial. No credit card required to
                      sign up.
                    </div>

                    <div
                      className={css`
                        margin: 14px;
                        font-size: 12px;
                        max-width: 300px;
                      `}
                    >
                      <span
                        className={css`
                          font-weight: bold;
                        `}
                      >
                        Still have questions?
                      </span>
                      <br />
                      Reach out to our sales team at sales@britebenefits.com
                    </div>
                  </div>
                </div>
              )}

              {type === "carrier" && (
                <React.Fragment>
                  <div
                    className={css`
                      font-weight: 400;
                      font-size: 20px;
                      text-align: center;
                      line-height: 28px;
                      margin: 40px auto;
                      margin-top: 0px;
                      color: #222021;
                      max-width: 500px;
                    `}
                  >
                    Manage how your products are presented to brokers,
                    employers, and employees on the Brite platform.
                  </div>

                  <div
                    className={css`
                      font-weight: bold;
                      font-size: 14px;
                      text-align: center;
                    `}
                  >
                    Coming soon! Contact us to learn more by emailing
                    sales@britebenefits.com
                  </div>
                </React.Fragment>
              )}

              {type === "single" && (
                <div
                  className={css`
                    display: flex;
                    justify-content: center;
                    flex-wrap: wrap-reverse;
                    margin: 14px;
                    margin-top: 0px;
                  `}
                >
                  <div>
                    {!isEmpty(error) && (
                      <div
                        className={css`
                          color: red;
                          margin-top: 8px;
                          max-width: 300px;
                        `}
                      >
                        {error.agree ? "Must agree to Terms of Use. " : ""}
                        All fields required.
                      </div>
                    )}
                    <div
                      className={css`
                        margin: 12px;
                        font-weight: bold;
                      `}
                    >
                      Company Information
                    </div>
                    <div
                      className={css`
                        margin: 14px;
                      `}
                    >
                      <TextField
                        error={error.name}
                        className={classes.formControl}
                        label="Company Name"
                        variant="outlined"
                        value={name}
                        onChange={(e) => {
                          clearFieldError("name");
                          setName(e.target.value);
                        }}
                      />
                    </div>

                    <div
                      className={css`
                        margin: 14px;
                      `}
                    >
                      <FormControl
                        variant="outlined"
                        className={classes.formControl}
                        error={error.companyState}
                      >
                        <InputLabel id="state">State</InputLabel>
                        <Select
                          labelId="state"
                          id="select-state"
                          value={companyState}
                          onChange={(e) => {
                            clearFieldError("companyState");
                            setCompanyState(e.target.value);
                          }}
                          input={<OutlinedInput label="State" />}
                        >
                          {states.map((state) => (
                            <MenuItem key={state.id} value={state.id}>
                              {state.label}
                            </MenuItem>
                          ))}
                        </Select>
                      </FormControl>
                    </div>
                    <div
                      className={css`
                        margin: 14px;
                      `}
                    >
                      <TextField
                        className={classes.formControl}
                        error={error.websiteURL}
                        label="Website URL"
                        variant="outlined"
                        value={websiteURL}
                        onChange={(e) => {
                          clearFieldError("websiteURL");
                          setWebsiteURL(e.target.value);
                        }}
                      />
                    </div>

                    <div
                      className={css`
                        margin: 14px;
                      `}
                    >
                      <TextField
                        className={classes.formControl}
                        label="# of Employees"
                        error={error.employeeCount}
                        type="number"
                        variant="outlined"
                        onChange={(e) => {
                          clearFieldError("employeeCount");
                          setEmployeeCount(Number(e.target.value));
                        }}
                        value={employeeCount}
                      />
                    </div>
                    <div
                      className={css`
                        margin: 14px;
                      `}
                    >
                      <FormControl
                        variant="outlined"
                        className={classes.formControl}
                        error={error.industry}
                      >
                        <InputLabel id="industry">Industry</InputLabel>
                        <Select
                          labelId="industry"
                          id="select-industry"
                          value={industry}
                          onChange={(e) => {
                            clearFieldError("industry");
                            setIndustry(e.target.value);
                          }}
                          input={<OutlinedInput label="Industry" />}
                        >
                          {industries.map((industry) => (
                            <MenuItem key={industry.name} value={industry.name}>
                              {industry.name}
                            </MenuItem>
                          ))}
                        </Select>
                      </FormControl>
                    </div>

                    <div
                      className={css`
                        margin: 12px;
                        font-weight: bold;
                      `}
                    >
                      Your Information
                    </div>
                    <div
                      className={css`
                        margin: 14px;
                      `}
                    >
                      <TextField
                        className={classes.formControl}
                        label="First Name"
                        error={error.firstName}
                        variant="outlined"
                        value={firstName}
                        onChange={(e) => {
                          clearFieldError("firstName");
                          setFirstName(e.target.value);
                        }}
                      />
                    </div>

                    <div
                      className={css`
                        margin: 14px;
                      `}
                    >
                      <TextField
                        className={classes.formControl}
                        error={error.lastName}
                        label="Last Name"
                        variant="outlined"
                        value={lastName}
                        onChange={(e) => {
                          clearFieldError("lastName");
                          setLastName(e.target.value);
                        }}
                      />
                    </div>

                    <div
                      className={css`
                        margin: 14px;
                      `}
                    >
                      <TextField
                        className={classes.formControl}
                        label="Email"
                        variant="outlined"
                        value={emailAddress}
                        disabled
                      />
                    </div>

                    <div
                      className={css`
                        margin: 28px;
                        max-width: 300px;
                      `}
                    >
                      <FormControlLabel
                        control={
                          <Checkbox
                            checked={agree}
                            color="default"
                            onChange={(e) => {
                              if (e.target.checked) {
                                clearFieldError("agree");
                              }

                              setAgree(e.target.checked);
                            }}
                            name="agree"
                          />
                        }
                        label={
                          <span>
                            I agree to the{" "}
                            <a
                              className={css`
                                color: blue;
                                text-decoration: none;
                                &:hover {
                                  color: black;
                                  text-decoration: none;
                                }
                                &:visited {
                                  color: blue;
                                  text-decoration: none;
                                }
                              `}
                              href="https://share.hsforms.com/18ZIPbaK4QQGU3xaJ_0N2MA3ihp9"
                              target="_blank"
                            >
                              Terms of Use{" "}
                            </a>
                            I understand the{" "}
                            <a
                              className={css`
                                color: blue;
                                text-decoration: none;
                                &:hover {
                                  color: black;
                                  text-decoration: none;
                                }
                                &:visited {
                                  color: blue;
                                  text-decoration: none;
                                }
                              `}
                              href="https://share.hsforms.com/1UQbu6cvVR5-AJ09KpbxSjA3ihp9"
                              target="_blank"
                            >
                              Privacy Policy
                            </a>
                            .
                          </span>
                        }
                      />
                    </div>
                    <div
                      className={css`
                        margin: 14px;
                        max-width: 300px;
                      `}
                    >
                      <Button
                        className={classes.formControl}
                        variant="contained"
                        color="primary"
                        onClick={() => {
                          submit();
                        }}
                      >
                        Submit
                      </Button>
                    </div>
                  </div>
                  <div>
                    <div
                      className={css`
                        font-weight: bold;
                        margin: 14px;
                      `}
                    >
                      Pricing
                    </div>
                    <div
                      className={css`
                        margin: 14px;
                        font-size: 12px;
                        max-width: 300px;
                      `}
                    >
                      Get started with a free trial. No credit card required to
                      sign up.
                    </div>

                    <div
                      className={css`
                        margin: 14px;
                        font-size: 12px;
                        max-width: 300px;
                      `}
                    >
                      <span
                        className={css`
                          font-weight: bold;
                        `}
                      >
                        Still have questions?
                      </span>
                      <br />
                      Reach out to our sales team at sales@britebenefits.com
                    </div>
                  </div>
                </div>
              )}

              {type === "other" && (
                <div
                  className={css`
                    text-align: center;
                    margin: 24px;
                  `}
                >
                  <div
                    className={css`
                      font-weight: bold;
                      margin-bottom: 24px;
                    `}
                  >
                    The email address that you signed up with is not associated
                    with any company that is registered with Brite.
                  </div>

                  <div
                    className={css`
                      font-size: 18px;
                      line-height: 24px;
                      margin-bottom: 24px;
                    `}
                  >
                    How can I add my user to an existing company on Brite?
                  </div>
                  <div
                    className={css`
                      max-width: 500px;
                      margin: 0 auto;
                    `}
                  >
                    <Accordion>
                      <AccordionSummary
                        expandIcon={<ExpandMoreIcon />}
                        aria-controls="option one"
                        id="option1"
                      >
                        <Typography>
                          Option 1: Contact the admin on your company's account
                        </Typography>
                      </AccordionSummary>
                      <AccordionDetails>
                        <Typography>
                          The admin can add you as a user on the company's
                          account. Make sure they use the same email address
                          that you signed up with. <br />
                          <br /> You should be able to login successfully now.
                        </Typography>
                      </AccordionDetails>
                    </Accordion>

                    <Accordion>
                      <AccordionSummary
                        expandIcon={<ExpandMoreIcon />}
                        aria-controls="option two"
                        id="option2"
                      >
                        <Typography>Option 2: Contact us</Typography>
                      </AccordionSummary>
                      <AccordionDetails>
                        <Typography>
                          Reach out to our support team at support@britebenefits.com
                        </Typography>
                      </AccordionDetails>
                    </Accordion>
                  </div>
                </div>
              )}

              {type && (
                <div
                  className={css`
                    margin-top: 40px;
                    margin-bottom: 40px;
                    display: flex;
                    justify-content: center;
                  `}
                >
                  <Button
                    variant="outlined"
                    onClick={() => {
                      restartSetup();
                      executeScroll();
                    }}
                  >
                    Restart Setup
                  </Button>
                </div>
              )}
            </React.Fragment>
          )}
        </Layer>
      )}
    </React.Fragment>
  );
};

export const CollectBusinessInfoModalContainer = connect(
  (state, ownProps) => ({
    showBusinessPopup: ownProps.showBusinessPopup,
    loading: state.auth.loading,
  }),
  (dispatch) => {
    return {
      createBusiness: (business) => {
        dispatch(createBusiness(business));
      },
    };
  }
)(CollectBusinessInfoModal);
