import { Tooltip } from "@material-ui/core";
import { css } from "emotion";
import { X } from "phosphor-react";
import { useMemo, useState } from "react";
import { Button, Div, Input, Text, Icon } from "../../shared/components";
import { flex, shadows } from "../../shared/shared-styles";
import { colors } from "../../shared/styles";
import { useOutsideClick } from "../../shared/use-outside-click";
import { useEditorResource } from "../use-editor-resource";
import { addSmartFieldToCache, SmartFields } from "./smart-fields";

import { ReactComponent as SmartText } from "../../images/svg/smart-text.svg";
import { ReactComponent as SmartImage } from "../../images/smart-image.svg";

export const SmartFieldInput = ({
  value,
  inputProps,
  smartFieldDisplay,
  handleSmartField,
  removeSmartField,
  nameFilter,
  css: cssStyle = "",
  name = "Text",
}) => {
  const isLinkSmartField = value?.startsWith("{{") && value?.endsWith("}}");
  const { data: smartFields, cache } = useEditorResource("liveSmartFields");
  const smartField = useMemo(() => {
    if (isLinkSmartField && smartFields?.[value]?.Success) {
      const display = smartFieldDisplay || smartFields[value]?.Value || value;
      return {
        display,
        hoverLabel: smartFields[value]?.Value || value,
      };
    }
    return {
      display: smartFieldDisplay || value,
    };
  }, [isLinkSmartField, value, smartFieldDisplay, smartFields]);

  const handleSmartFieldAdd = (item) => {
    addSmartFieldToCache(cache, item);
    handleSmartField(item);
  };

  return isLinkSmartField ? (
    <Div
      css={css`
        ${flex("space-between")} width: 100%;
        border-radius: 8px;
        padding: 8px;
        border: 1px solid ${colors.gray[300]};
        ${cssStyle}
      `}
    >
      <Tooltip position="top" title={smartField?.hoverLabel || ""}>
        <Text
          label
          bold
          ellipsis
          css={`
            border-radius: 30px;
            ${!!smartField?.hoverLabel
              ? `
                background-color: ${colors.gray[200]};
              `
              : `
              background-color: ${colors.red[200]};
              `};
            padding: 4px 8px;
          `}
        >
          {smartField?.display}
        </Text>
      </Tooltip>
      <SmartButtons
        handleSmartField={handleSmartFieldAdd}
        removeSmartField={removeSmartField}
        isLinkSmartField={isLinkSmartField}
        nameFilter={nameFilter}
        name={name}
      />
    </Div>
  ) : (
    <Input
      className={css`
        width: 100%;
        ${cssStyle}
      `}
      endIcon={
        <SmartButtons
          handleSmartField={handleSmartFieldAdd}
          removeSmartField={removeSmartField}
          isLinkSmartField={isLinkSmartField}
          nameFilter={nameFilter}
          name={name}
        />
      }
      {...inputProps}
    />
  );
};

const SmartButtons = ({
  handleSmartField,
  removeSmartField,
  isLinkSmartField,
  nameFilter,
  name = "Text",
}) => {
  const [display, setDisplay] = useState(false);
  const ref = useOutsideClick(() => setDisplay(false));

  return (
    <Div
      css={css`
        position: relative;
        ${flex("right")}
        padding-right: 8px;
      `}
      ref={ref}
    >
      {isLinkSmartField ? (
        <Button
          styles="icon sm"
          hoverLabel={`Remove Smart ${name}`}
          onClick={removeSmartField}
        >
          <X color={"#9AA7B5"} size="24" />
        </Button>
      ) : (
        <Button
          styles="icon sm"
          hoverLabel={`Smart ${name}`}
          onClick={() => setDisplay(!display)}
        >
          {name === "Image" ? (
            <SmartImage />
          ) : (
            <Icon SVG={SmartText} size={24} />
          )}
        </Button>
      )}

      {display ? (
        <Div
          css={css`
            position: absolute;
            top: 100%;
            right: 0;
            width: 400px;
            border-radius: 16px;
            z-index: 1000;
            background-color: white;
            padding: 16px;
            ${shadows.lg}
          `}
        >
          <SmartFields
            handleSmartField={handleSmartField}
            nameFilter={nameFilter}
            name={name}
          />
        </Div>
      ) : null}
    </Div>
  );
};
