import { CustomAxios } from "../redux/axios/axios";
import { Button, Text, Modal } from "../shared/components";

export const EnableTextingModal = (modalProps) => {
  const enableTexting = async () => {
    try {
      await CustomAxios.put(`v1/featureflag`, {
        FlagName: "text-messaging-new",
        FlagValue: true,
      });
      window?.ChurnZero?.push([
        "setAttribute",
        "account",
        "integration-text-messaging",
        true,
        1,
      ]);
      window.location.reload();
    } catch (err) {
      console.warn(err);
      throw err;
    }
  };

  return (
    <Modal {...modalProps}>
      <Modal.Paper>
        <Modal.Header
          title="Turn on Texting Feature"
          onClose={modalProps.onClose}
        />
        <Modal.Body>
          <Text>
            Texting is currently being offered as a beta product for 30 days.
          </Text>
        </Modal.Body>
        <Modal.Actions>
          <Button onClick={() => enableTexting()} variant="outlined">
            Turn On
          </Button>
        </Modal.Actions>
      </Modal.Paper>
    </Modal>
  );
};
