import { Collapse } from "@material-ui/core";
import { css, cx } from "emotion";
import { useState } from "react";
import { animation, container, flex } from "../shared-styles";
import { useOutsideClick } from "../use-outside-click";
import { Div } from "./div";
import { Text } from "./text";
import { useBounds } from "../use-bounds";
import { Box } from "./box";

export const Dropdown = ({
  button,
  css: cssString,
  children,
  listWidth,
  left = 0,
  right = 0,
  outsideClickProps = {},
}) => {
  const [open, setOpen] = useState(false);
  const ref = useOutsideClick(() => setOpen(false), outsideClickProps);

  const buttonBounds = useBounds(ref, [open]);
  const boundsHeight = buttonBounds.top > 100 + window.innerHeight * 0.4;
  const position = boundsHeight ? "bottom" : "top";

  return (
    <Div
      css={css`
        position: relative;
        ${flex("space-between")}
        background-color: white;
        ${open ? "" : container.hover}
        padding: 8px;
        border-radius: 8px;
        ${cssString}
      `}
      ref={ref}
      onClick={() => setOpen(!open)}
    >
      {button({ open })}
      {open ? (
        <Collapse in={true}>
          <Box
            scrollbar
            className={cx(
              css`
                ${container.box}
                ${animation("fadeIn", ".2s ease")}
                  position: absolute;
                ${position}: calc(100% + 8px);
                left: ${left};
                right: ${right};
                padding: 0;
                padding-bottom: 8px;
                max-height: 40vh;
                overflow: auto;
                overflow-x: hidden;
                z-index: 10000;
                min-width: ${listWidth};
                border-radius: 8px;
                ::-webkit-scrollbar-track {
                  border-radius: 50%;
                }
              `,
              "dropdown-list"
            )}
          >
            {children}
          </Box>
        </Collapse>
      ) : null}
    </Div>
  );
};

Dropdown.Item = ({ children, onClick, css: cssStyle, className }) => {
  return (
    <Div
      css={cx(
        css`
          padding: 8px;
          ${container.hover}
        `,
        css`
          ${cssStyle}
        `
      )}
      className={className}
      onClick={onClick}
    >
      <Text label>{children}</Text>
    </Div>
  );
};
