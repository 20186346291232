import { ListsEditor } from "@prezly/slate-lists";
import { Editor, Element, Node, Path, Range, Transforms } from "slate";
import { ReactEditor } from "slate-react";
import { Element as DOMElement, Text } from "domhandler";
import {
  htmlToSlate,
  htmlToSlateConfig,
  slateToDomConfig,
  slateToHtml,
} from "slate-serializers";
import { buildCss } from "../../utils";
import { markStyleMap } from "./slate-config";
import { defaultComponents } from "../../constants";

// {

//   color: ({ node }) => {
//     const text = new Text(node.text);
//     return new DOMElement("span", { style: `color: ${node.color}` }, [
//       text,
//     ]);
//   },
//   superscript: ({ node }) => {
//     const text = new Text(node.text);
//     const style = buildCss(markStyleMap.superscript().style);
//     return new DOMElement("span", { style }, [text]);
//   },
//   subscript: ({ node }) => {
//     const text = new Text(node.text);
//     const style = buildCss(markStyleMap.subscript().style);
//     return new DOMElement("span", { style }, [text]);
//   },
// },

export const getMarkedStyles = (node, convertToString = true) => {
  const defaultValue = convertToString ? "" : {};
  return Object.keys(node).reduce((prev, key) => {
    const styleObject = markStyleMap?.[key]?.style(node) || {};
    if (convertToString) {
      const style = buildCss(styleObject);
      return prev + style;
    } else {
      return {
        ...prev,
        ...styleObject,
      };
    }
  }, defaultValue);
};

const markTransforms = Object.entries(markStyleMap).reduce(
  (prev, [key, value]) => {
    if (value.isTransformer) {
      return {
        ...prev,
        [key]: ({ node }) => {
          const text = new Text(node.text);
          const styleObject = markStyleMap[key].style(node);
          const style = buildCss(styleObject);
          return new DOMElement("span", { style }, [text]);
        },
      };
    }
    return prev;
  },
  {}
);

const handleElement = ({ children = [], node }, attributes, element = "p") => {
  let nextChildren = children;
  if (node?.children?.length === 1 && node.children[0]?.text === "") {
    const br = new DOMElement("br", {}, []);
    nextChildren.push(br);
  }
  return new DOMElement(element, attributes, nextChildren);
};

const buildHtmlLink = ({ node, children = [] }) => {
  const target = node.target || "_blank";

  let attributes = {
    href: node.href,
    target,
  };

  if (node?.linkType === "page") {
    attributes = {
      href: `${node?.linkText}`,
      target,
    };
  }

  return new DOMElement("a", attributes, children);
};

const getPosthogAttributes = (node) => {
  let attributes = {
    "data-ph-capture-attribute-source": "smart-text",
    "data-ph-capture-attribute-smart-text": node?.value,
  };

  const productDataSource = node?.dataSourceChain?.find(
    ({ Type }) => Type === "product"
  );
  if (productDataSource?.IdentifierTag) {
    attributes["data-ph-capture-attribute-product-id"] =
      productDataSource?.IdentifierTag;
  }
  return attributes;
};

export const convertToHtml = (slateData, { liveSmartFields = {} } = {}) => {
  return slateToHtml(slateData, {
    ...slateToDomConfig,
    markTransforms,
    elementMap: {
      ...slateToDomConfig?.elementMap,
      "unordered-list": "ul",
      "list-item-text": "p",
      "ordered-list": "ol",
      "list-item": "li",
    },
    elementTransforms: {
      ...slateToDomConfig?.elementTransforms,
      p: (props) => handleElement(props, {}),
      right: (props) => handleElement(props, { style: "text-align: right;" }),
      center: (props) => handleElement(props, { style: "text-align: center;" }),
      left: (props) => handleElement(props, { style: "text-align: left;" }),
      link: buildHtmlLink,
      "list-item": (props) => {
        const firstChildColor =
          props?.node?.children?.[0]?.children?.[0]?.color;
        const attributes = firstChildColor
          ? { style: `color: ${firstChildColor};` }
          : {};
        return handleElement(props, attributes, "li");
      },
      "smart-field": ({ node }) => {
        const [child] = node?.children;
        const style = getMarkedStyles(child);
        const text = new Text(node?.value);
        const posthogAttributes = getPosthogAttributes(node);

        const smartFieldSpan = new DOMElement(
          "span",
          { style, ...posthogAttributes },
          [text]
        );

        try {
          // Check if smart-field is a link
          const smartField = liveSmartFields?.[node?.value];
          new URL(smartField?.Value);
          return buildHtmlLink({
            node: {
              href: node?.value,
              target: "_blank",
              linkType: "url",
              linkText: "",
            },
            children: [smartFieldSpan],
          });
        } catch {
          // ***** All smart-fields that are NOT links go here:
          return smartFieldSpan;
        }
      },
    },
    convertLineBreakToBr: true,
  });
};

export const getActiveColor = (editor) => {
  const marks = Editor.marks(editor);
  return marks?.color || "";
};

const handleChild = (node, fn) => {
  let data = fn(node);
  if (node?.children?.length) {
    let children = [];
    for (const item of node?.children) {
      const next = handleChild(item, fn);
      children.push(next);
    }
    data = { ...data, children };
  }
  return data;
};

export const iterateSlate = (editor, fn) => {
  const { children } = editor;
  let next = [];
  for (const child of children) {
    const item = handleChild(child, fn);
    next.push(item);
  }
  return next;
};

export const removeAllMarks = (slateEditor, mark) => {
  const slateContent = iterateSlate(slateEditor, (data = {}) => {
    const marks = Object.entries(data)?.filter(([key]) => key !== mark);
    return Object.fromEntries(marks);
  });
  updateContent(slateEditor, slateContent);
};

export const updateColor = (editor, property, colorMark) => {
  const marks = Editor.marks(editor);
  const colorMarks = Object.keys(marks || {}).filter((key) => key === property);
  for (const mark of colorMarks) {
    Editor.removeMark(editor, mark);
  }
  Editor.addMark(editor, property, colorMark);
};

export const isBlockActive = (editor, key) => {
  const [match] = Editor.nodes(editor, {
    match: (n) => n.type === key,
  });
  return !!match;
};

export const getBlock = (editor, matchFn) => {
  const [match] = Editor.nodes(editor, {
    match: matchFn,
  });
  return match;
};

export const isLinkActive = (editor) => {
  const [match] = Editor.nodes(editor, {
    match: (n) => !Editor.isBlock(editor, n) && n.type === "link",
  });
  return !!match;
};

export const isInlineActive = (editor, key) => {
  const marks = Editor.marks(editor);
  return marks ? marks?.[key] === true : false;
};

export const toggleInline = (editor, key, options = {}) => {
  const { toggleOn = false } = options;
  const isActive = isInlineActive(editor, key);
  if (isActive && !toggleOn) {
    Editor.removeMark(editor, key);
  } else {
    Editor.addMark(editor, key, true);
  }
};

const LIST_TYPES = ["ordered-list", "unordered-list"];

export const toggleBlock = (editor, key) => {
  const isActive = isBlockActive(editor, key);
  const isList = LIST_TYPES.includes(key);

  if (!isList) {
    const type = isActive ? "p" : key;
    Transforms.setNodes(
      editor,
      { type },
      { match: (n) => Element.isElement(n) && Editor.isBlock(editor, n) }
    );
  } else {
    if (isActive) {
      ListsEditor.unwrapList(editor);
    } else {
      ListsEditor.wrapInList(editor, key);
    }
  }
};

export const updateContent = (editor, slateNodes) => {
  Transforms.delete(editor, {
    at: {
      anchor: Editor.start(editor, []),
      focus: Editor.end(editor, []),
    },
  });

  if (editor?.children?.length) {
    Transforms.removeNodes(editor, { at: [0] });
  }

  Transforms.insertNodes(editor, slateNodes);
};

const createParagraphNode = (children) => ({
  type: "p",
  children,
});

export const removeLink = (editor, opts = {}) => {
  Transforms.unwrapNodes(editor, {
    ...opts,
    match: (n) =>
      !Editor.isEditor(n) && Element.isElement(n) && n.type === "link",
  });
};

export const insertNode = (editor, node, options = {}) => {
  const { wrapSelection = true } = options;
  const { selection } = editor;
  ReactEditor.focus(editor);

  if (!!selection) {
    const [parentNode, parentPath] = Editor.parent(
      editor,
      selection?.focus?.path
    );

    if (parentNode?.type === node.type && node.type === "link") {
      removeLink(editor);
    }

    if (editor.isVoid(parentNode)) {
      // Insert the new link after the void node
      Transforms.insertNodes(editor, createParagraphNode([node]), {
        at: Path.next(parentPath),
        select: true,
      });
    } else if (Range.isCollapsed(selection)) {
      // Insert the new link in our last known location
      Transforms.insertNodes(editor, node, { select: true });
    } else {
      if (wrapSelection) {
        // Wrap the currently selected range of text into a Link
        Transforms.wrapNodes(editor, node, { split: true });
        // Remove the highlight and move the cursor to the end of the highlight
        Transforms.collapse(editor, { edge: "end" });
      }
    }
  } else {
    // Insert the new link node at the bottom of the Editor when selection
    // is falsey
    Transforms.insertNodes(editor, createParagraphNode([node]));
  }
};

const kebabize = (str) =>
  str.replace(
    /[A-Z]+(?![a-z])|[A-Z]/g,
    ($, ofs) => (ofs ? "-" : "") + $.toLowerCase()
  );

const hasStyleAttribute = (style, property, value) => {
  const style_lc = style.toLowerCase();
  const property_lc = kebabize(property);
  const value_lc = value?.toLowerCase();
  return style_lc.includes(property_lc) && style_lc.includes(value_lc);
};

const convertMarks = (el) => {
  let marks = {};
  const { attributes = [] } = el;
  for (const attr of attributes) {
    if (attr.name === "style") {
      if (hasStyleAttribute(attr.value, "text-decoration", "line-through")) {
        marks = { ...marks, strikethrough: true };
      }

      if (
        hasStyleAttribute(attr.value, "font-size", ".83em") &&
        hasStyleAttribute(attr.value, "vertical-align", "super")
      ) {
        marks = { ...marks, superscript: true };
      }

      if (
        hasStyleAttribute(attr.value, "font-size", ".83em") &&
        hasStyleAttribute(attr.value, "vertical-align", "sub")
      ) {
        marks = { ...marks, subscript: true };
      }

      if (attr.value === "background-color:rgba(0, 0, 0, .1)") {
        marks = { ...marks, highlight: true };
      }

      if (attr.value.startsWith("color:")) {
        const color = attr?.value?.replace("color:", "");
        marks = { ...marks, color };
      }
    }
  }
  return marks;
};

export const draftToSlate = (html) => {
  const config = {
    ...htmlToSlateConfig,
    elementTags: {
      ...htmlToSlateConfig.elementTags,
      li: () => ({ type: "list-item" }),
      ol: () => ({ type: "ordered-list" }),
      ul: () => ({ type: "unordered-list" }),
    },
    textTags: {
      ...htmlToSlateConfig.textTags,
      a: htmlToSlateConfig.elementTags.a,
      span: convertMarks,
    },
  };
  const htmlContent = htmlToSlate(html, config)?.reduce((prev, item) => {
    const { align = "", children } = item;
    let type = align === "left" ? "p" : align || item?.type;
    if (item?.type === "unordered-list" || item?.type === "ordered-list") {
      type = item?.type;
    }

    if (item?.type === "p") {
      const nextChildren = children?.filter(({ text }) => text !== "\n");
      if (!nextChildren?.length) {
        return [...prev, { type: "p", children: [{ text: "" }] }];
      }
    }
    return [
      ...prev,
      {
        type,
        children,
      },
    ];
  }, []);
  let slateContent = [];
  for (const node of htmlContent) {
    let children = [];
    for (const child of node?.children) {
      if (child.type === "link") {
        const { type, url: href = "", newTab, ...rest } = child;
        const linkChildren = [...(child.children || []), { ...rest }];
        children.push({
          href,
          type: "link",
          children: linkChildren,
          linkText: href,
          target: newTab ? "_blank" : "_self",
        });
      } else if (child?.type || "text" in child) {
        children.push(child);
      }
    }
    slateContent.push({ ...node, type: node?.type || "p", children });
  }

  if (!slateContent?.length) {
    slateContent = [...defaultComponents?.textV2?.slateContent];
  }

  return slateContent;
};

export const findNodePath = (editor, fn) => {
  const nodes = Array.from(Node.elements(editor));
  let path = null;
  for (const node of nodes) {
    const [data = {}, location] = node;
    const isValid = fn(data);
    if (isValid) {
      path = location;
      break;
    }
    if (data?.children) {
      for (let idx = 0; idx < data?.children; idx++) {
        const child = data?.children[idx];
        const isValid = fn(child);
        if (isValid) {
          path = [child, [...location, idx]];
          break;
        }
      }
    }
  }
  return path;
};

export const getSaveContent = () => {};

export const textEditorShortKeys = {
  b: {
    type: "inline",
    key: "bold",
  },
  i: {
    type: "inline",
    key: "italic",
  },
  u: {
    type: "inline",
    key: "underline",
  },
};
