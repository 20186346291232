import { css } from "emotion";
import { useRef } from "react"

function toFixed(num, fixed = 4) {
  var re = new RegExp('^-?\\d+(?:\.\\d{0,' + (fixed || -1) + '})?');
  return num.toString().match(re)[0];
}

const calculatedCrop = ({ crop, width: containerWidth, image }) => {
  
  if (!image?.naturalWidth) {
    return '';
  }
  
  const pixelWidth = toFixed(crop?.width) * image?.naturalWidth;
  const pixelHeight = toFixed(crop?.height) * image?.naturalHeight;
  const aspectRatio = toFixed(pixelWidth) / toFixed(pixelHeight);
  const scaleW = image?.naturalWidth / (image?.naturalWidth * (toFixed(crop?.width) / 100));
  const scaleH = image?.naturalHeight / (image?.naturalHeight * (toFixed(crop?.height) / 100));
  const width = scaleW * 100;
  const cropX = scaleW * toFixed(crop?.x);
  const cropY = (scaleH) * toFixed(crop?.y);
  return css`
    position: relative;
    width: ${containerWidth};
    aspect-ratio: ${aspectRatio};
    ${crop.circularCrop ? `border-radius: 50%;` : ''}
    overflow: hidden;

    img {
      position: absolute;
      width: ${width}%;
      top: -${cropY}%;
      left: -${cropX}%;
      transform: scale(${crop?.scale}) rotate(${crop?.rotate}deg);
    }
  `;

}

export const CroppedImage = ({ crop, style, imageProps, width }) => {

  const ref = useRef();
  const image = ref.current;

  const cropStyle = {
    ...style,
    ...(crop?.circularCrop ? {
      borderTopLeftRadius: '50%',
      borderTopRightRadius: '50%',
      borderBottomLeftRadius: '50%',
      borderBottomRightRadius: '50%',
    } : {})
  }
  
  return (
    <div className={calculatedCrop({ width, crop, image })} style={cropStyle}>
      <img {...imageProps} ref={ref} />
    </div>
  );

}