import { CircularProgress } from "@material-ui/core";
import { css } from "emotion";
import { X } from "phosphor-react";
import { useCallback, useState } from "react";
import { useDropzone } from "react-dropzone";
import { toast } from "react-toastify";
import { fileTypesMap } from "../../../Documents/FullscreenUploader";
import { CustomAxios } from "../../../redux/axios/axios";
import { Button, Div, Modal, Text } from "../../../shared/components";
import { flex, px } from "../../../shared/shared-styles";
import { colors } from "../../../shared/styles";
import { useEditorResource } from "../../use-editor-resource";

const container = css`
  width: 500px;
  padding: 32px;
`;

export const UploadImage = ({ updateImage, ...modalProps }) => {
  const [loadingDocuments, setLoadingDocuments] = useState(false);

  const { refetch } = useEditorResource("documents", {
    enabled: modalProps.display,
  });

  const onDrop = useCallback(async (acceptedFiles) => {
    if (acceptedFiles.length > 0) {
      setLoadingDocuments(true);
      const url = `/v2/media/${
        acceptedFiles[0].name
          ? encodeURIComponent(acceptedFiles[0].name)
          : "no-name"
      }/${
        acceptedFiles[0].type
          ? encodeURIComponent(acceptedFiles[0].type.replace("/", "."))
          : "image.png"
      }`;
      const formData = new FormData();
      formData.append("file", acceptedFiles[0]);
      const config = {
        headers: {
          "content-type": "multipart/form-data",
        },
      };

      try {
        const { data } = await CustomAxios.post(url, formData, config);
        updateImage("images", {
          alt: data.Name,
          src: data.PublicURL,
          "data-img-id": data.ID,
        });
        refetch();
        modalProps.onClose();
      } catch (err) {
        toast.error("Error uploading document. Try again.");
      } finally {
        setLoadingDocuments(false);
      }
    } else {
      toast.error("Invalid file or too many files");
    }
  }, []);

  const { getRootProps, getInputProps, isDragActive } = useDropzone({
    onDrop,
    accept: fileTypesMap.images,
    noClick: false,
    noDragEventsBubbling: true,
  });

  return (
    <Modal {...modalProps}>
      <Div className={container}>
        <Div
          css={css`
            ${flex("jcsb aic")}
          `}
        >
          <Text styles="h2">Upload Image</Text>
          <Button styles="icon" onClick={() => modalProps.onClose()}>
            <X />
          </Button>
        </Div>
        <Div
          {...getRootProps({
            className: css`
              ${flex("jcc aic")}
              margin: ${px.md} 0;
              border: 1px dashed ${colors.gray[400]};
              border-radius: 8px;
              height: 200px;
              cursor: pointer;
              ${isDragActive &&
              `
                border: 1px solid ${colors.black};
                background-color: ${colors.gray[300]};
              `}
            `,
          })}
        >
          {loadingDocuments ? (
            <CircularProgress />
          ) : (
            <Text styles="label center">
              Drop a new image here, or click to select a file
            </Text>
          )}
          <input {...getInputProps()} />
        </Div>
      </Div>
    </Modal>
  );
};
