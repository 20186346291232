import {
  forwardRef,
  useCallback,
  useEffect,
  useMemo,
  useRef,
  useState,
} from "react";
import { useParams } from "react-router";
import { useQueryAPI } from "../../../react-query";
import { CustomAxios } from "../../../redux/axios/axios";
import { useStore } from "../../../store-provider/use-store";
import { template } from "lodash";
import { useQuery } from "react-query";
import { formatDocumentsForEditor } from "../editor-utils";
import { useSelector } from "react-redux";
import EmailEditor from "react-email-editor";
import { Div } from "../../../shared/components";
import { css } from "emotion";
import { Skeleton } from "@material-ui/lab";

const productItemsTemplate = template(`
<div style="display:flex; justify-content: space-between; align-items: center; flex-wrap: wrap; max-height: 300px; overflow: auto;">
<% _.forEach(products, function(item) { %>
    <div style="padding: 12px; cursor: pointer;" id="logo-item" data-uuid='<%= item.url %>'>
      <img src="<%= item.url %>" style="height: auto; max-width: 120px;" />
    </div> 
<% }); %>
</div>
`);

const loadContainer = css`
  display: flex;
  position: absolute;
  z-index: 100;
  width: 100%;
  height: 100%;
  background-color: white;
`;

export const Editor = forwardRef(
  ({ page, theme, pageLease = {}, setSaveStatus, refreshTheme }, editorRef) => {
    const [loading, setLoading] = useState(true);
    const containerRef = useRef();
    const { courseId } = useParams();

    const {
      data: { selectedBusiness, parentBusiness },
    } = useStore();

    const { data: tags } = useQueryAPI({
      url: `v1/pages/${page?.ID}/tags`,
      enabled: !!page?.ID,
      retry: 1,
    });

    useEffect(() => {
      if (!tags || !window.unlayer) {
        return;
      }
      window.unlayer.setMergeTags(tags.MergeTags);
    }, [tags]);

    const { data: logos } = useQueryAPI({
      url: `v1/carriers`,
      select: (data) => data.map((logo) => ({ url: logo.LogoURL })),
      retry: 1,
    });

    const { data: documents } = useQuery({
      queryFn: () =>
        formatDocumentsForEditor(selectedBusiness?.ID, parentBusiness?.ID),
      enabled: !!selectedBusiness?.ID && !!parentBusiness?.ID,
    });

    const headingDefaultProperties = useMemo(() => {
      if (!theme || !theme?.Theme?.Heading) {
        return {};
      }
      let overrides = {};
      if (theme?.Theme?.Heading?.FontOverride?.Label) {
        overrides.fontFamily = {
          label: theme?.Theme.Heading.FontOverride.Label,
          value: theme?.Theme.Heading.FontOverride.Value,
        };
      }
      if (theme?.Theme?.Heading?.FontColorOverride) {
        overrides.color = { value: theme?.Theme.Heading.FontColorOverride };
      }
      return overrides;
    });

    const onLoad = useCallback(() => {
      setLoading(true);
      if (window.unlayer && page.Content) {
        window.unlayer.loadDesign(page.Content);
        setSaveStatus("initial");
      }
    }, [page?.Content, setSaveStatus, setLoading]);

    const onDesignLoad = useCallback(() => {
      // set body values if theme was provided (other defaults from the theme get set in the initialization options)
      if (theme) {
        window.unlayer.setBodyValues({
          textColor: theme.Theme.Body.FontColor,
          backgroundColor: theme.Theme.Body.BackgroundColor,
          fontFamily: {
            label: theme.Theme.Body.Font.Label,
            value: theme.Theme.Body.Font.Value,
          },
        });
      }
      setTimeout(() => setLoading(false), 320);
    }, [theme, window.unlayer, setLoading]);

    const designTagsConfig =
      tags?.designTagsDelimiter?.length === 2
        ? { delimiter: tags?.designTagsDelimiter }
        : null;

    const colorPicker = theme?.Theme?.colorPickerPresets
      ? { presets: theme?.Theme?.colorPickerPresets }
      : {};

    const timer = useRef(null);

    useEffect(() => {
      setLoading(true);
      if (timer.current) {
        clearInterval(timer.current);
      }
      timer.current = setInterval(() => setLoading(false), 3000);
    }, [page?.ID]);

    const token = localStorage.getItem("authHeader");

    return (
      <Div
        ref={containerRef}
        className={css`
          position: relative;
          width: 100%;
          height: calc(100% - 16px);
          flex-grow: 1;
        `}
      >
        {(pageLease.loading || loading || !page?.ID) && (
          <Div className={loadContainer}>
            <Skeleton
              variant="rect"
              style={{
                minWidth: "calc(80% - 8px)",
                borderRadius: "8px",
                height: "100%",
              }}
            />
            <Skeleton
              variant="rect"
              style={{
                width: "calc(20% - 20px)",
                marginLeft: "12px",
                borderRadius: "8px",
                height: "100%",
              }}
            />
          </Div>
        )}
        {!!page?.ID && (
          <EmailEditor
            ref={editorRef}
            options={{
              displayMode: "web",
              designTagsConfig,
              designTags: tags?.DesignTags,
              mergeTags: tags?.MergeTags,
              mergeTagsConfig: {
                autocompleteTriggerChar: "@",
              },
              user: {
                id: parentBusiness?.ID,
              },
              editor: {
                autoSelectOnDrop: true,
                confirmOnDelete: false,
              },
              features: {
                undoRedo: false,
                textEditor: {
                  cleanPaste: true,
                  tables: true,
                },
                colorPicker,
              },
              customCSS: `${CustomAxios.baseURL()}public/v1/course/${courseId}/theme-css?pageID=${
                page?.ID
              }&x=${refreshTheme}`,
              customJS: [
                `
                      const productItemsTemplate = ${productItemsTemplate};
                      const editorTemplate = '<div style="padding-bottom: 24px;"><input style="padding: 10px;" placeholder="Search" type="text" id="search-bar" /><button id="search-btn" style="border: 1px solid transparent; margin-left: 12px; border-radius: 0.25rem; padding: 11px; background-color: #25282D; color: #fff; cursor: pointer;">Search</button></div><div id="logo-list"></div>';
    
    
                      unlayer.registerPropertyEditor({
                        name: "logo_library",
                        layout: "bottom",
                        Widget: unlayer.createWidget({
                          render(value, updateValue, data) {
                            return editorTemplate;
                          },
                          mount(node, value, updateValue, data) {
                            const list = document.querySelector("#logo-list");
                            list.innerHTML = productItemsTemplate({products: data.logos});
    
    
                            var selectButton = document.querySelector('#logo-list');
                            if (!selectButton) return;
                            selectButton.onclick = function (e) {
                            
                              if (e.target.id === 'logo-item') {
                                
                                const selectedProduct = data.logos.find(
                                  (item) => item.url === e.target.dataset.uuid
                                );
                                console.log(selectedProduct)
                                updateValue({ logoLibrary: selectedProduct });
                              } else {
                              
                                const parent = e.target.parentElement;
                                if (parent && parent.id !== 'logo-item') return;
                                const selectedProduct = data.logos.find(
                                  (item) => item.url === parent.dataset.uuid
                                );
                                console.log(selectedProduct)
                                updateValue({ logoLibrary: selectedProduct });
                              }
                            
                            
                            }
    
                            var searchBar = document.querySelector("#search-bar");
                            var searchButton = document.querySelector("#search-btn");
                            searchButton.onclick = function (e) {
                              const list = document.querySelector("#logo-list");
                              let filteredItem;
                              let productsListHtml;
                              if (list && data && data.logos) {
                                
                                if (searchBar.value === '') {
                                  productsListHtml = productItemsTemplate({
                                    products: data.logos,
                                  });
                                } else {
                                  filteredItem = data.logos.filter((item) =>
                                    item.url
                                      .toLowerCase()
                                      .includes(searchBar.value.toLowerCase())
                                  );
                                  productsListHtml = productItemsTemplate({
                                    products: filteredItem,
                                  });
                                }
                                list.innerHTML = productsListHtml;
                              }
                            };
                          },
                        }),
                      });
                      
                      unlayer.registerTool({
                        name: "logo",
                        label: "Logo",
                        icon: "fa-image",
                        supportedDisplayModes: ["web", "email"],
                        options: {
                          logoLibrary: {
                            title: "Choose a Logo",
                            position: 1,
                            options: {
                              logoLibrary: {
                                label: "Add logo from library",
                                defaultValue: {logoLibrary: {url: ''}},
                                widget: "logo_library",
                              },
                            },
                          },
    
                          size: {
                            title: "Image Size",
                            position: 1,
                            options: {
                              width: {
                                label: "Width (use % or px values.. ie '120px' or '50%')",
                                defaultValue: "",
                                widget: "text",
                              },
                            },
                          },
                      
                          alignment: {
                            title: "Alignment",
                            position: 2,
                            options: {
                              alignment: {
                                label: "Text Alignment",
                                defaultValue: "left",
                                widget: "alignment", // built_in property editor
                              },
                            },
                          },
                        },
                        values: {},
                        transformer: (values, source) => {
                          const { name, value, data } = source;
    
                          let alignment;
    
                          if(values.alignment === "left") {
                            alignment = 'flex-start';
                          } else if(values.alignment === "center") {
                            alignment = 'center';
                          } else if(values.alignment === "right" || values.alignment === "justify") {
                            alignment = 'flex-end';
                          } else {
                            alignment = 'flex-start';
                          }
                        
                          let newValues = { ...values, alignment};
                      
                          
                          return newValues;
                        },
                        renderer: {
                          Viewer: unlayer.createViewer({
                            render(values) {
                              return "<div style='display: flex; justify-content:"+values.alignment+";'><img src='" + values.logoLibrary.logoLibrary.url + "' style='width: "+values.width+"; max-width: 100%; height: auto;' /> </div>";
                            },
                          }),
                          exporters: {
                            web: function (values) {
                              return "<div style='display: flex; justify-content:"+values.alignment+";'><img src='" + values.logoLibrary.logoLibrary.url + "' style='width: "+values.width+"; max-width: 100%; height: auto;' /> </div>";
                            },
                            email: function (values) {
                              return "<div style='display: flex; justify-content:"+values.alignment+";'><img src='" + values.logoLibrary.logoLibrary.url + "' style='width: "+values.width+"; max-width: 100%; height: auto;' /> </div>";
                            },
                          },
                          head: {
                            css: function (values) {},
                            js: function (values) {},
                          },
                        },
                      });`,
                window.location.protocol +
                  "//" +
                  window.location.host +
                  "/custom.js",
              ],
            }}
            tools={{
              heading: {
                properties: headingDefaultProperties,
              },
              "custom#video_tool": {
                enabled: true,
              },
              "custom#logo": {
                enabled: true,
                data: {
                  logos,
                },
                properties: {
                  logoLibrary: {
                    editor: {
                      data: {
                        logos,
                      },
                    },
                  },
                },
              },

              "custom#document": {
                enabled: true,
                properties: {
                  // document is the name of our property
                  document: {
                    editor: {
                      data: {
                        token,
                        options: documents,
                        businessID: selectedBusiness?.ID,
                        parentID: parentBusiness?.ID,
                      },
                    },
                  },
                },
              },
              "custom#embed_website": {
                enabled: true,
              },
            }}
            key={page?.FrontendID + refreshTheme}
            projectId={2747}
            minHeight={`${containerRef?.current?.offsetHeight - 8}px`}
            onLoad={onLoad}
            onDesignLoad={onDesignLoad}
          />
        )}
      </Div>
    );
  }
);
