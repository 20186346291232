import { useEffect, useState } from "react";
import { useDebounce } from "./use-debounce";
import { useEvent } from "./use-event";

export const extractDOMRect = (element) => {
  const bounds = element?.getBoundingClientRect?.();
  const {
    top = 0,
    left = 0,
    right = 0,
    bottom = 0,
    x = 0,
    y = 0,
    height = 0,
    width = 0,
  } = bounds || {};
  return {
    top,
    left,
    right,
    bottom,
    x,
    y,
    height,
    width,
    lastUpdate: new Date().toISOString(),
    element,
  };
};

export const useBounds = (ref, deps = [], delay = 200, options = {}) => {
  const { disabled = false, disableScroll = false } = options;
  const [bounds, setBounds] = useState({});

  const getBounds = (updateType) => {
    if (!disabled) {
      const bounds = extractDOMRect(ref?.current);
      setBounds({ ...bounds, updateType });
    }
  };

  const delayed_getBounds = useDebounce(getBounds, delay);
  useEvent(window, "resize", () => delayed_getBounds("resize"), null);
  useEvent(
    window,
    "scroll",
    () => {
      if (!disableScroll) {
        delayed_getBounds("scroll");
      }
    },
    true
  );

  useEffect(() => {
    getBounds("default");
  }, [...deps, ref?.current]);

  return bounds;
};
