import { useDropzone } from "react-dropzone";
import { fileTypesMap } from "../../Documents/FullscreenUploader";
import { useCallback } from "react";
import { toast } from "react-toastify";
import { CustomAxios } from "../../redux/axios/axios";
import { media } from "../../react-query/resources/media";
import { useRequests } from "../../react-query/use-resource";
import { history } from "../../history";
import { useParams } from "react-router";

function delay(t, val) {
  return new Promise((resolve) => setTimeout(resolve, t, val));
}

export const useUploadDoc = ({ setLoading, benefitPkg }) => {
  const { businessId } = useParams();
  const requests = useRequests();

  const createPackageAndUploadMedia = useCallback(
    async (files) => {
      try {
        setLoading(true);
        //Create Benefit Package
        const response = await requests.post({
          url: "/v1/benefitspackage",
          body: benefitPkg,
        });

        //Upload Media
        const packageId = response?.data?.ID;
        const promises = await Promise.allSettled(
          files.map((file) => {
            const postMeta = media?.utils?.getPost(file);
            const uploadUrl = `${postMeta?.url}?is_private=true&related_type=benefits_package&related_id=${packageId}`;
            return requests.post({
              ...postMeta,
              url: uploadUrl,
            });
          })
        );

        // Filter out non-xlsx & failed requests
        const list = promises
          .filter((item) => {
            const [type = ""] =
              Object.entries(fileTypesMap || {}).find(
                ([_, filetype]) => filetype === item?.value?.data?.ContentType
              ) || [];
            const fileType = type === "xlsx" || type === "pdf";
            return item?.status === "fulfilled" && fileType;
          })
          ?.map((item) => item?.value?.data);

        //Initialize Mapping files to BP products
        if (list?.length) {
          for (const item of list) {
            const mediaId = item?.ID;
            const url = `/v1/benefitspackage/${packageId}/imports/${mediaId}`;
            CustomAxios.post(url);
          }
        }

        // Give BE time to process files (sometimes it's just TOO FAST)
        await delay(1000);
        history.push(`/${businessId}/packages/${packageId}`);
      } catch (err) {
        console.warn(err);
        toast.error("Failed to successfully create benefit package.");
      } finally {
        setLoading(false);
      }
    },
    [benefitPkg?.Name]
  );

  const handleMedia = useCallback(
    (acceptedFiles) => {
      if (acceptedFiles.length > 0) {
        const files = acceptedFiles?.map((item) =>
          media.utils.extractFileObject(item)
        );
        createPackageAndUploadMedia(files);
      } else {
        toast.error("Invalid file or too many files");
      }
    },
    [benefitPkg?.Name]
  );

  const dropzone = useDropzone({
    onDrop: handleMedia,
    accept: [fileTypesMap.xlsx, fileTypesMap.xlsm, fileTypesMap.pdf],
    noDragEventsBubbling: true,
  });

  return {
    ...dropzone,
  };
};
