import { css, cx } from "emotion";
import { Editor } from "draft-js";
import { blockStyles } from "./text-utils";
import { draftStyles } from "./draft-styles";
import { forwardRef, useMemo } from "react";
import { useEditorResource } from "../../use-editor-resource";

export const Text = forwardRef(
  ({ element, isSelected, textEditorProps }, ref) => {
    const { type, attributes } = element;
    const { editorReady, editorProps, handlers, className } = textEditorProps;

    const { data: styles } = useEditorResource("styles");

    const combinedStyles = {
      ...styles?.text?.[type],
      ...(attributes?.style || {}),
    };
    const editorBlockStyles = useMemo(
      () =>
        Object.entries(blockStyles).reduce(
          (prev, [key, value]) => prev + `.${key} { ${value} }`,
          ""
        ),
      []
    );

    return (
      <div
        className={cx(
          css`
            width: 100%;
            overflow: hidden;
            p {
              line-height: 1em;
            }
            ol,
            ul {
              line-height: 1.5em;
              margin: 0;
            }
            pre {
              ${editorBlockStyles}
              ${isSelected && `cursor: text;`}
              line-height: 1.2em;
            }
            ${draftStyles}
          `,
          className
        )}
        onClick={() => ref.current.focus()}
      >
        <pre onClick={() => handlers.setReadOnly(false)} style={combinedStyles}>
          {editorReady && (
            <Editor
              ref={ref}
              placeholder={!isSelected || editorProps.readOnly ? "Text" : ""}
              {...editorProps}
            />
          )}
        </pre>
      </div>
    );
  }
);
