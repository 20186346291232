import { css } from "emotion";
import {
  ArrowLeft,
  CalendarBlank,
  CurrencyDollarSimple,
  File,
} from "phosphor-react";
import { useEffect, useMemo, useRef, useState } from "react";
import { useParams } from "react-router";
import { history } from "../history";
import {
  benefitsPackage,
  benefitsPackageProductsList,
  useQueryAPI,
  useRequests,
  useResource,
} from "../react-query";
import { Button, Div, Modal, Text } from "../shared/components";
import { container, flex, shadows } from "../shared/shared-styles";
import { colors } from "../shared/styles";
import { useSearchParams } from "../shared/use-search-params";
import { UploadMedia } from "./auto-paster-v2/upload-media";
import { GenerateGuide } from "./generate-guide/generate-guide";
import { ProductList } from "./product-list";
import { ACLWrapper } from "../shared/components/acl-wrapper";
import { Box } from "../shared/components/box";
import { Dropdown } from "../shared/components/dropdown";
import { Tooltip } from "@material-ui/core";
import { extractNumbers } from "./auto-paster-v2/paste-utils";
import Dates from "../shared/components/Dates";
import { addDays, format } from "date-fns";
import { textTheme } from "../shared/components/text";
import { useStateSync } from "../shared/use-state-sync";

export const intervalMap = {
  12: "Monthly",
  52: "Weekly",
  24: "Semi-Monthly",
  26: "Bi-Weekly",
};

export const PackagePage = () => {
  const textareaRef = useRef();
  const params = useParams();
  const { updateParams } = useSearchParams();

  const [modal, setModal] = useState("");
  const [displayCourseGeneration, setDisplayCourseGeneration] = useState(null);

  const benefitsPackageResource = useResource(benefitsPackage);
  const benefitPackageRequests = useRequests(benefitsPackageResource);
  const { data: pkg } = benefitsPackageResource?.query;

  const [pkgName, setPkgName] = useStateSync(pkg?.Name || "", [pkg?.Name]);

  const PremiumIntervalsPerYear = pkg?.PremiumIntervalsPerYear || 0;
  const resource = useResource(benefitsPackageProductsList);

  const {
    data: media,
    isLoading: isLoadingMedia,
    refetch: refetchMedia,
    cache: cacheMedia,
  } = useQueryAPI({
    url: `v2/media?related_id=${params.packageId}&include_private=true`,
    enabled: !!params.packageId,
    defaultValue: [],
  });

  useEffect(() => {
    if (
      !resource.query.isFetching &&
      !isLoadingMedia &&
      !media?.length &&
      !resource.query.data
    ) {
      setModal("upload-media");
    }
  }, [resource.query.isFetching, isLoadingMedia]);

  useEffect(() => {
    if (displayCourseGeneration) {
      updateParams({ packageId: params.packageId });
    }
  }, [displayCourseGeneration]);

  const productListRequests = useRequests(resource);

  const reviewProduct = (product) => {
    const putProduct = benefitsPackageProductsList.utils.getPutProduct(
      product?.ID,
      {
        ...product,
        ReviewedAt: new Date().toISOString(),
      }
    );
    productListRequests.put(putProduct);
  };

  const savePkg = (updates) => {
    const postPackage = benefitsPackage.utils.getPut(pkg?.ID, {
      ...pkg,
      ...updates,
    });
    benefitPackageRequests.put(postPackage);
  };

  const saveEnrollmentDates = ({ start, end }) => {
    const startDate = start.toISOString();
    const endDate = end.toISOString();
    savePkg({ EnrollmentStartDate: startDate, EnrollmentEndDate: endDate });
  };

  const savePlanDates = ({ start, end }) => {
    const startDate = start.toISOString();
    const endDate = end.toISOString();
    savePkg({ PlanStartDate: startDate, PlanEndDate: endDate });
  };

  const routeToProduct = (product) => {
    if (!product?.ReviewedAt) {
      reviewProduct(product);
    }
    history.push(
      `/${params.businessId}/packages/${params.packageId}/${product.Type}/${product.ID}`
    );
  };

  const enrollmentLabel = useMemo(() => {
    if (pkg?.EnrollmentStartDate && pkg?.EnrollmentEndDate) {
      return `${format(
        Dates.getDateFromISO(pkg?.EnrollmentStartDate),
        "MMM dd, yyyy"
      )} - ${format(
        Dates.getDateFromISO(pkg?.EnrollmentEndDate),
        "MMM dd, yyyy"
      )}`;
    }
    return "Set Enrollment Dates";
  }, [pkg?.EnrollmentStartDate, pkg?.EnrollmentEndDate]);

  const planLabel = useMemo(() => {
    if (pkg?.PlanStartDate && pkg?.PlanEndDate) {
      return `${format(
        Dates.getDateFromISO(pkg?.PlanStartDate),
        "MMM dd, yyyy"
      )} - ${format(Dates.getDateFromISO(pkg?.PlanEndDate), "MMM dd, yyyy")}`;
    }
    return "Set Plan Dates";
  }, [pkg?.PlanStartDate, pkg?.PlanEndDate]);

  useEffect(() => {
    textareaRef.current.style.height = "auto";
    textareaRef.current.style.height = `${textareaRef.current.scrollHeight}px`;
  }, [pkgName]);

  return (
    <Box page>
      <Div
        css={css`
          ${flex("space-between start")}
        `}
      >
        <Div
          css={css`
            ${flex("ai-center start")}
            flex-grow: 1;
          `}
        >
          <Button
            styles="icon"
            onClick={() => history.replace(`/${params.businessId}/packages`)}
          >
            <ArrowLeft />
          </Button>
          <textarea
            ref={textareaRef}
            value={pkgName}
            onChange={(e) => setPkgName(e.target.value)}
            onBlur={() => savePkg({ Name: pkgName })}
            rows={1}
            className={css`
              ${pkgName?.length > 50 ? textTheme.h2 : textTheme.h1}
              resize: none;
              overflow: hidden;
              outline: none;
              border: none;
              padding: 8px;
              margin: -8px;
              margin-left: 16px;
              border: 1px solid transparent;
              border-radius: 8px;
              min-width: 200px;
              flex-grow: 1;
              margin-right: 16px;
              align-items: top;
              :focus {
                border: 1px solid ${colors.gray[100]};
              }
              :not(:focus) {
                ${container.hover}
              }
            `}
          />
        </Div>
        <ACLWrapper
          acls={["write:benefits_package"]}
          resourceId={params.packageId}
        >
          <Div
            css={css`
              ${flex("ai-center")}
            `}
          >
            <Button
              styles="secondary mr"
              onClick={() => setDisplayCourseGeneration(true)}
            >
              Generate Guide
            </Button>
            <Button
              onClick={() =>
                history.push(
                  `/${params.businessId}/packages/${params.packageId}/benefit-type`
                )
              }
            >
              Add Benefit
            </Button>
          </Div>
        </ACLWrapper>
      </Div>
      <Box
        flex="left"
        css={`
          margin: 32px 0;
          button {
            padding: 8px 16px;
            margin-right: 16px;
            color: ${colors.gray[500]};
            font-weight: bold;
            :hover {
              background-color: ${colors.gray[200]};
            }
          }
        `}
      >
        <Tooltip
          title={`${media?.length} Document${media?.length === 1 ? "" : "s"}`}
          placement="top"
          enterDelay={0}
        >
          <Button light onClick={() => setModal("upload-media")}>
            <File size={24} color={colors.gray[500]} /> Documents
          </Button>
        </Tooltip>
        <Dropdown
          css={`
            padding: 0;
            :hover {
              background: none;
            }
          `}
          listWidth="250px"
          button={({ open }) => (
            <Tooltip
              enterDelay={0}
              placement="top"
              title={
                (PremiumIntervalsPerYear === 0
                  ? "Company Kit"
                  : intervalMap?.[PremiumIntervalsPerYear]) || "Company Kit"
              }
            >
              <Button light>
                <CurrencyDollarSimple size={24} color={colors.gray[500]} /> Pay
                Period
              </Button>
            </Tooltip>
          )}
        >
          <Box
            css={`
              padding: 8px;
              p {
                padding: 8px;
                border-radius: 8px;
                ${container.hover}
              }
            `}
            onClick={(e) => e.stopPropagation()}
          >
            <Text
              label
              css={
                PremiumIntervalsPerYear === 0
                  ? `background-color: ${colors.gray[100]}; :hover { ${shadows.sm}}`
                  : ""
              }
              onClick={() => savePkg({ PremiumIntervalsPerYear: 0 })}
            >
              Company Kit
            </Text>
            {Object.entries(intervalMap)?.map(([key, label]) => (
              <Text
                label
                onClick={() =>
                  savePkg({ PremiumIntervalsPerYear: extractNumbers(key, 12) })
                }
                css={
                  PremiumIntervalsPerYear === extractNumbers(key, 12)
                    ? `background-color: ${colors.gray[100]}; :hover { ${shadows.sm}}`
                    : ""
                }
              >
                {label}
              </Text>
            ))}
          </Box>
        </Dropdown>
        <Tooltip title={enrollmentLabel} placement="top" enterDelay={0}>
          <Button light onClick={() => setModal("enrollment")}>
            <CalendarBlank size={24} color={colors.gray[500]} /> Enrollment
            Dates
          </Button>
        </Tooltip>
        <Tooltip title={planLabel} placement="top" enterDelay={0}>
          <Button light onClick={() => setModal("plan")}>
            <CalendarBlank size={24} color={colors.gray[500]} /> Plan Dates
          </Button>
        </Tooltip>
      </Box>
      <Div
        css={css`
          ${flex("left")} width: 100%;
        `}
      >
        <ProductList
          productListResource={resource}
          packageId={params.packageId}
          businessId={params.businessId}
          selectProduct={routeToProduct}
        />
      </Div>
      <GenerateGuide
        display={displayCourseGeneration}
        onClose={() => setDisplayCourseGeneration(null)}
        startStep="design-style"
      />
      {modal === "enrollment" ? (
        <Modal display={true} onClose={() => setModal("")}>
          <Modal.Paper width="700px">
            <Modal.Header
              title="Enrollment Dates"
              onClose={() => setModal("")}
            />
            <Modal.Body>
              <Dates.Range
                start={Dates.getDateFromISO(pkg?.EnrollmentStartDate)}
                end={Dates.getDateFromISO(
                  pkg?.EnrollmentEndDate,
                  addDays(new Date(), 365)
                )}
                setRange={saveEnrollmentDates}
                autoSetEnd={(start) => addDays(start, 365)}
              />
            </Modal.Body>
          </Modal.Paper>
        </Modal>
      ) : null}
      {modal === "plan" ? (
        <Modal display={true} onClose={() => setModal("")}>
          <Modal.Paper width="700px">
            <Modal.Header title="Plan Dates" onClose={() => setModal("")} />
            <Modal.Body>
              <Dates.Range
                start={Dates.getDateFromISO(pkg?.PlantStartDate)}
                end={Dates.getDateFromISO(
                  pkg?.PlantStartDate,
                  addDays(new Date(), 365)
                )}
                setRange={savePlanDates}
                autoSetEnd={(start) => addDays(start, 365)}
              />
            </Modal.Body>
          </Modal.Paper>
        </Modal>
      ) : null}
      {modal === "upload-media" ? (
        <UploadMedia
          media={media}
          cacheMedia={cacheMedia}
          onClose={() => setModal("")}
          packageId={params.packageId}
          refetch={() => {
            resource.query.refetch();
            refetchMedia();
          }}
        />
      ) : null}
    </Box>
  );
};
