import { MenuItem, Radio } from "@material-ui/core";
import { css } from "emotion";
import { extendedStates } from "../../constants";
import { surestPlanType } from "../../Content/decision-tool-constants";
import { Select, Div, Text } from "../../shared/components";
import { AdvancedInputField } from "./advanced-input-field";
import { Cost } from "./cost";
import { FieldInput } from "./field-input";
import { DrugDeductible } from "./drug-deductible";
import { SurestInputField } from "./surest-input-field";
import ListItemText from "@material-ui/core/ListItemText";
import MUISelect from "@material-ui/core/Select";
import Checkbox from "@material-ui/core/Checkbox";
import { flex } from "../../shared/shared-styles";
import { LayoutContainer, LayoutSelect } from "./layout-container";
import { useAcls } from "../../shared/use-acls";
import { WRITE_BENEFITS_PACKAGE } from "../../shared/acl-constants";

import { useMemo } from "react";

import { colors } from "../../shared/styles";
import { Carriers } from "./carriers";
import { needsAttention } from "../../react-query/resources/needs-attention";
import { Flag } from "../flags/flag";
import { ApplicableStates } from "./applicable-states";

export const planTypeOptions = ["PPO", "HDHP", "EPO", "HMO", surestPlanType];
export const acaPlanTypeOptionsDisplay = ["PPO", "HDHP", "EPO", "HMO"];

export const FieldTypes = (props) => {
  const { state, fieldProps } = props;

  const { product, flags } = state;
  const { value, updateProperty, field, config = {} } = fieldProps;
  const { PropertyChain, Type } = field ?? {};

  const flag = needsAttention.utils.getFlagByPropertyChain(
    flags,
    field?.PropertyChain
  );

  const canEdit = useAcls([WRITE_BENEFITS_PACKAGE]);
  config.disabled = config.disabled || !canEdit;

  const handleApplicableStates = (e) => {
    // Remove unused values

    let list = e.target.value.filter((item) => item && item !== "{}");

    if (list.includes("select all")) {
      if (value.length === extendedStates.length - 1) {
        list = ["ALL"];
      } else {
        list = extendedStates
          .filter((item) => item.id !== "ALL")
          .map((s) => s.id);
      }
    } else {
      // Handle ALL as a selection
      const hasAll = value.includes("ALL");
      if (list.length > 1 && hasAll) {
        list = list.filter((item) => item !== "ALL");
      } else if (!hasAll && list.includes("ALL")) {
        list = ["ALL"];
      }
    }

    updateProperty(PropertyChain, list);
  };

  const stateSyncedAt = useMemo(() => {
    new Date().toISOString();
  }, [value]);

  const fieldInputProps = {
    ...fieldProps,
    field: { ...field, productId: product?.ID },
    config,
    stateSyncedAt,
  };
  const layoutProps = {
    state,
    field,
    config,
  };

  const CustomFormField = useMemo(
    () => config?.customFormField,
    [stateSyncedAt, field]
  );

  return (
    <>
      <Div
        css={css`
          ${flex("left grow")}
        `}
      >
        {config?.customFormField ? (
          <CustomFormField state={state} fieldProps={fieldProps} />
        ) : Type === "text-input" || Type === "link" ? (
          // ************************* TEXT INPUT
          <LayoutContainer {...layoutProps}>
            <FieldInput {...fieldInputProps} />
          </LayoutContainer>
        ) : Type === "text-area" ? (
          // ************************* TEXT AREA
          <LayoutContainer {...layoutProps}>
            <FieldInput {...fieldInputProps} rows={3} />
          </LayoutContainer>
        ) : Type === "number" ? (
          // ************************* TEXT NUMBER
          <LayoutContainer {...layoutProps}>
            <FieldInput {...fieldInputProps} type="number" />
          </LayoutContainer>
        ) : Type === "date" ? (
          // ************************* DATE
          <LayoutContainer {...layoutProps}>
            <FieldInput {...fieldInputProps} />
          </LayoutContainer>
        ) : Type === "dollar" ? (
          // ************************* DOLLAR
          <LayoutContainer {...layoutProps}>
            <FieldInput {...fieldInputProps} />
          </LayoutContainer>
        ) : Type === "multipleOfSalary" ? (
          <LayoutContainer {...layoutProps}>
            <Div
              css={css`
                ${flex("space-between")}
              `}
            >
              <FieldInput {...fieldInputProps} />
              <Div
                css={css`
                  border-left: 1px solid ${colors.gray[300]};
                  padding: 0 16px;
                  margin-left: auto;
                  p {
                    min-width: max-content;
                  }
                `}
              >
                <Text label>X Salary</Text>
              </Div>
            </Div>
          </LayoutContainer>
        ) : Type === "percent" || Type === "percentOfEmployeeElectedAmount" ? (
          // ************************* PERCENT
          <LayoutContainer {...layoutProps}>
            <FieldInput {...fieldInputProps} />
          </LayoutContainer>
        ) : Type === "toggle" ? (
          // ************************* TOGGLE
          <LayoutContainer {...layoutProps} hideBorder={true}>
            <Div
              css={css`
                ${flex("space-between")}
                width: 100%;
                > div {
                  ${flex("space-between")}
                  border-radius: 8px;
                  padding: 2px 16px;
                  padding-right: 6px;
                  flex-grow: 1;
                  cursor: pointer;
                  * {
                    pointer-events: none;
                  }
                }
              `}
            >
              <Div
                css={css`
                  margin-right: 8px;

                  ${value
                    ? `
                    outline: 1px solid ${colors.black};
                    border: 1px solid ${colors.black};
                  `
                    : `border: 1px solid ${colors.gray[300]};`}
                `}
                onClick={() =>
                  !config?.disabled ? updateProperty(PropertyChain, true) : null
                }
              >
                <Text label>Yes</Text>
                <Radio
                  color="primary"
                  checked={value}
                  disabled={config?.disabled}
                />
              </Div>
              <Div
                css={css`
                  margin-left: 8px;
                  ${!value
                    ? `
                  border: 1px solid transparent;
                  outline: 2px solid ${colors.black};
                `
                    : `border: 1px solid ${colors.gray[300]};`}
                `}
                onClick={() => updateProperty(PropertyChain, false)}
              >
                <Text label>No</Text>
                <Radio
                  color="primary"
                  checked={!value}
                  disabled={config?.disabled}
                />
              </Div>
            </Div>
          </LayoutContainer>
        ) : Type === "prescriptionDrugTier" ? (
          // ************************* Prescription Drug Tier Drop-Down
          <LayoutContainer {...layoutProps} hideBorder={true}>
            <Select
              className={css`
                width: 100%;
              `}
              disabled={config?.disabled}
              value={value}
              onChange={(e) => updateProperty(PropertyChain, e.target.value)}
              tabIndex="-1"
            >
              <MenuItem value="3 Tier Structure">3 Tier Structure</MenuItem>
              <MenuItem value="4 Tier Structure">4 Tier Structure</MenuItem>
              <MenuItem value="5 Tier Structure">5 Tier Structure</MenuItem>
              <MenuItem value="6 Tier Structure">6 Tier Structure</MenuItem>
            </Select>
          </LayoutContainer>
        ) : Type === "prescriptionDrugDeductible" ? (
          // ************************* Prescription Drug Deductible Drop-Down
          <DrugDeductible state={state} field={field} config={config} />
        ) : Type === "medicalPlanTypes" ? (
          // ************************* Medical Plan Types
          <LayoutContainer {...layoutProps} hideBorder={true}>
            <Select
              className={css`
                width: 100%;
              `}
              disabled={config?.disabled}
              value={value}
              onChange={(e) => updateProperty(PropertyChain, e.target.value)}
              tabIndex="-1"
            >
              {value.startsWith("aca")
                ? acaPlanTypeOptionsDisplay.map((item) => (
                    <MenuItem
                      key={item}
                      value={"acaplans-" + item.toLowerCase()}
                    >
                      {item + " (ACA)"}
                    </MenuItem>
                  ))
                : planTypeOptions.map((item) => (
                    <MenuItem key={item} value={item.toLowerCase()}>
                      {item}
                    </MenuItem>
                  ))}
            </Select>
          </LayoutContainer>
        ) : Type === "dentalPlanTypes" ? (
          // ************************* Dental Plan Types
          <LayoutContainer {...layoutProps} hideBorder={true}>
            <Select
              className={css`
                width: 100%;
              `}
              disabled={config?.disabled}
              value={value || ""}
              onChange={(e) => updateProperty(PropertyChain, e.target.value)}
              tabIndex="-1"
            >
              <MenuItem value="">None</MenuItem>
              <MenuItem value="PPO">PPO</MenuItem>
              <MenuItem value="HMO">HMO</MenuItem>
            </Select>
          </LayoutContainer>
        ) : Type === "states" ? (
          // ************************* Applicable States
          <LayoutContainer {...layoutProps} hideBorder={true}>
            <ApplicableStates
              value={value}
              onUpdate={(value) => updateProperty("ApplicableStates", value)}
            />
          </LayoutContainer>
        ) : Type === "visionFrequency" ? (
          <LayoutContainer {...layoutProps}>
            <div
              className={css`
                display: flex;
              `}
            >
              <FieldInput
                updateProperty={updateProperty}
                field={{ ...field, productId: product?.ID }}
                config={config}
                value={value.inputValue}
              />
              <LayoutSelect
                value={value.frequencyValue}
                onChange={(e) => {
                  updateProperty(
                    fieldInputProps.field.frequency.PropertyChain,
                    e.target.value
                  );
                }}
                tabIndex="-1"
              >
                <MenuItem value={"12 months"}>12 months</MenuItem>
                <MenuItem value={"24 months"}>24 months</MenuItem>
              </LayoutSelect>
            </div>
          </LayoutContainer>
        ) : Type === "advancedInputField" ? (
          // ************************* Professional Services Fields
          <AdvancedInputField state={state} field={field} config={config} />
        ) : Type === "surestInputField" ? (
          // ************************* Professional Services Fields SUREST
          <SurestInputField
            state={state}
            field={field}
            config={config}
            updateProperty={updateProperty}
          />
        ) : Type === "cost" ? (
          // ************************* Premiums & Contributions
          // !product?.Details?.PlanType?.startsWith("aca") ?
          <Cost state={state} field={field} config={config} />
        ) : Type === "carrier" ? (
          <Div
            css={css`
              width: calc(100% - 40px);
            `}
          >
            <Carriers state={state} field={field} config={config} />
          </Div>
        ) : null}
      </Div>
      <Flag flag={flag} />
    </>
  );
};
