import { createAction, handleActions } from "redux-actions";
import { takeEvery, all, call, put, select } from "redux-saga/effects";
import { history } from "../../history";

import { updateAuthBusinessInfo } from "../actions/auth";

import { CustomAxios } from "../axios/axios";
import { toast } from "react-toastify";
import { sortBy } from "lodash";
import posthog from "../../lib/analytics/posthog";

export const getBusiness = createAction("GET_BUSINESS");
export const getBusinessSuccess = createAction("GET_BUSINESS_SUCCESS");
export const getBusinessFailure = createAction("GET_BUSINESS_FAILURE");

export const setBusiness = createAction("SET_BUSINESS");

export const updateBusiness = createAction("UPDATE_BUSINESS");
export const updateBusinessSuccess = createAction("UPDATE_BUSINESS_SUCCESS");
export const updateBusinessFailure = createAction("UPDATE_BUSINESS_FAILURE");

export const transformBusinessList = (response) => {
  let business = "data" in response ? response.data : response;

  let businessList = [];
  for (let biz of business) {
    let createBiz = biz.Parent;
    createBiz.companies = biz.Companies;
    businessList.push(createBiz);
  }

  businessList = sortBy(businessList, [
    (company) => {
      return company.Name.toLowerCase();
    },
  ]);
  return businessList;
};

const setBusinessSaga = function* (store) {
  const handleSetBusiness = function* (action) {
    CustomAxios.setHeader("BusinessID", action.payload.ID);
    if (action.payload.GodMode) {
      CustomAxios.setHeader("ParentID", action.payload.ID);
    }

    posthog.register({
      currentBusinessID: action.payload.ID,
      currentBusinessName: action.payload.Name,
      selfService: action.payload.ID === store.getState().auth.businessId, // if working on assigned business or not (deprecated in favor of serviceType property)
      serviceType:
        action.payload.ID === store.getState().auth.businessId
          ? "self-service"
          : store.getState().auth.simplifiedType,
    });

    // deprecated, it makes more sense to just register this rather than set it on the person
    posthog.setPersonProperties({
      currentBusinessID: action.payload.ID,
      currentBusinessName: action.payload.Name,
    });

    if (window.location.pathname.includes("builder")) {
      history.push(`/${action.payload.ID}/courses`);
    }
  };

  yield takeEvery(setBusiness.toString(), handleSetBusiness);
};

const businessSagaGet = function* () {
  const handleGetBusiness = function* () {
    try {
      let state = yield select();

      const response = yield call(
        CustomAxios.get,
        `/v2/business/${state.business.ID}`
      );

      let business = response.data;

      if (state.business.Type === "carrier") {
        try {
          const res = yield call(
            CustomAxios.get,
            `/v1/carrier/${state.business.ID}/details`
          );

          let carrier = res.data;

          const categoryRes = yield call(
            CustomAxios.get,
            `/v1/business/${state.business.ID}/categories`
          );

          let categories = categoryRes.data;
          for (let category of categories) {
            category.Name = category.CategoryName;
          }

          business.categories = categories;
          business.carrier = carrier;
        } catch (e) {
          toast.error("Could not load carrier details/categories.");
        }
      }

      yield put(getBusinessSuccess(business));
    } catch (error) {
      yield put(getBusinessFailure());
      toast.error("Could not load company information");
    }
  };

  yield takeEvery(getBusiness.toString(), handleGetBusiness);
};

const businessSagaUpdate = function* () {
  const handleUpdateBusiness = function* (action) {
    try {
      let updateBusiness = {
        EmployeeCount: Number(action.payload.EmployeeCount),
        ID: action.payload.ID,
        Name: action.payload.Name,
        IsActive: action.payload.IsActive,
        Type: action.payload.Type,
        Domain: action.payload.Domain,
        State: action.payload.State,
        Industry: action.payload.Industry,
        LogoURL: action.payload.LogoURL,
        PaymentSource: action.payload.PaymentSource,
        ParentID: action.payload.ParentID,
        BrokerContactName: action.payload.BrokerContactName,
        BrokerContactEmail: action.payload.BrokerContactEmail,
        BrokerContactPhone: action.payload.BrokerContactPhone,
        BrokerContactTitle: action.payload.BrokerContactTitle,
        HRContactName: action.payload.HRContactName,
        HRContactEmail: action.payload.HRContactEmail,
        HRContactPhone: action.payload.HRContactPhone,
        HRContactTitle: action.payload.HRContactTitle,
      };

      const response = yield call(
        CustomAxios.put,
        `/v2/business/${action.payload.ID}`,
        updateBusiness
      );

      let business = response.data;

      if (action.payload.Type === "carrier") {
        const res = yield call(
          CustomAxios.put,
          `/v1/carrier/${action.payload.ID}/details`,
          {
            CarrierID: action.payload.ID,
            Description: action.payload.Description,
          }
        );

        let categoryIds = action.payload.Categories.map((category) => {
          return category.ID;
        });

        const resCategories = yield call(
          CustomAxios.put,
          `/v1/business/${action.payload.ID}/categories`,
          {
            CategoryIDs: categoryIds,
          }
        );

        business.carrier = { Description: action.payload.Description };
        business.categories = action.payload.Categories;
      }
      console.log("parent", action.payload.ParentID);
      console.log("usiness.ID", business.ID);

      if (action.payload.ParentID === business.ID) {
        yield put(updateAuthBusinessInfo(business));
      }

      yield put(updateBusinessSuccess(business));
      if (action.payload.callback) {
        action.payload.callback();
      } else {
        toast.success("Company successfully updated");
      }
    } catch (error) {
      console.log(error);
      yield put(updateBusinessFailure());
      toast.error("Could not update company information");
    }
  };

  yield takeEvery(updateBusiness.toString(), handleUpdateBusiness);
};

const businessReducerDefaultState = {
  EmployeeCount: 0,
  ID: "",
  Name: "",
  IsActive: false,
  Type: "single",
  LogoURL: "",
  companies: [],
  loading: false,
  allBusinesses: [],
  Domain: "",
  State: "",
  Industry: "",
  PaymentSource: "none",
  carrier: {},
  categories: [],
  CourseTheme: {},
};

export const businessReducer = handleActions(
  {
    [setBusiness]: (state, action) => {
      return Object.assign({}, state, action.payload);
    },
    [getBusinessSuccess]: (state, action) => {
      return Object.assign({}, state, { loading: false }, action.payload);
    },
    [getBusiness]: (state, action) => {
      return Object.assign({}, state, { loading: true });
    },
    [getBusinessFailure]: (state, action) => {
      return Object.assign({}, state, {
        loading: false,
        Domain: "",
        State: "",
        Industry: "",
        PaymentSource: "none",
        EmployeeCount: 0,
        Type: "single",
        ID: "",
        Name: "",
        LogoURL: "",
        companies: [],
        IsActive: false,
        carrier: {},
        categories: [],
        CourseTheme: {},
      });
    },
    [updateBusinessSuccess]: (state, action) => {
      return Object.assign({}, state, { loading: false }, action.payload);
    },
    [updateBusinessFailure]: (state, action) => {
      return Object.assign({}, state, { loading: false });
    },
    [updateBusiness]: (state, action) => {
      return Object.assign({}, state, { loading: true });
    },
  },
  businessReducerDefaultState
);

export const businessSaga = function* (store) {
  yield all([businessSagaGet(), businessSagaUpdate(), setBusinessSaga(store)]);
};
