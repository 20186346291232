import { css } from "emotion";
import { X } from "phosphor-react";
import { Button, Div, Input, Modal, Text } from "../../../shared/components";
import { flex, px } from "../../../shared/shared-styles";
import { useForm } from "../../../shared/use-form";
import { SmartFieldInput } from "../../utility-components/smart-field-input";

export const ImageSettings = ({ item, onUpdate, ...modalProps }) => {
  const { attributes = {}, smartFieldDisplay = "" } = item;
  const { values, setValues, getProperty, hasChanges } = useForm(
    {
      ...(attributes || {}),
      smartFieldDisplay,
    },
    [attributes, smartFieldDisplay]
  );

  const handleSaveSrc = () => {
    const { src, alt, smartFieldDisplay = "" } = values;
    const attributes = { src, alt };
    onUpdate({ attributes, smartFieldDisplay });
    modalProps.onClose();
  };

  const handleSmartField = ({ data }) => {
    setValues({ ...values, src: data?.value, smartFieldDisplay: data?.name });
  };

  const removeSmartField = () => {
    setValues({ ...values, src: "" });
  };

  return (
    <Modal {...modalProps}>
      <Div
        className={css`
          padding: ${px.xl};
          width: 500px;
        `}
      >
        <Div
          css={css`
            ${flex("jcsb aic")} padding-bottom: ${px.md};
          `}
        >
          <Text styles="h2">Image Link</Text>
          <Button styles="icon" onClick={modalProps.onClose}>
            <X />
          </Button>
        </Div>

        <Text>Image Source</Text>
        <SmartFieldInput
          value={values?.src}
          smartFieldDisplay={values?.smartFieldDisplay}
          handleSmartField={handleSmartField}
          removeSmartField={removeSmartField}
          inputProps={getProperty("src")}
          name="Image"
        />

        <Text>Alternate Text</Text>
        <Input
          styles="mb"
          className={css`
            width: 100%;
          `}
          {...getProperty("alt")}
        />

        <Div
          css={css`
            ${flex("jcr aic")} padding-top: ${px.md};
          `}
        >
          <Button styles="secondary mr" onClick={modalProps.onClose}>
            Cancel
          </Button>
          <Button disabled={!hasChanges} onClick={handleSaveSrc}>
            Save
          </Button>
        </Div>
      </Div>
    </Modal>
  );
};
