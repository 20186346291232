// EMPLOYEE-VIEW
import { useFeatureFlagPayload } from "posthog-js/react";
import { useMemo } from "react";
import { toast } from "react-toastify";
import { useQueryAPI } from "../../../../react-query/query-api";
import { CustomAxios } from "../../../../redux/axios/axios";

export const intervalMap = {
  12: "Monthly",
  52: "Weekly",
  24: "Semi-Monthly",
  26: "Bi-Weekly",
};

export const contributionLabelDefaults = {
  employee: "Employee",
  spouse: "Spouse",
  child: "Child",
  children: "Children",
  family: "Family",
};

export const deprecatedContributionLabelDefaults = {
  employee: "Employee Only",
  spouse: "Employee + Spouse",
  children: "Employee + Children",
  family: "Family",
};

export const contributionKeyMap = {
  EmployeeOnly: "employee",
  EmployeeSpouse: "spouse",
  EmployeeChild: "child",
  EmployeeChildren: "children",
  Family: "family",
};

export const contributionKeys = [
  "EmployeeOnly",
  "EmployeeSpouse",
  "EmployeeChild",
  "EmployeeChildren",
  "Family",
];

export const tierStructures = [
  ["EmployeeOnly", "Family"],
  ["EmployeeOnly", "EmployeeSpouse", "Family"],
  ["EmployeeOnly", "EmployeeSpouse", "EmployeeChildren", "Family"],
  [
    "EmployeeOnly",
    "EmployeeSpouse",
    "EmployeeChild",
    "EmployeeChildren",
    "Family",
  ],
];

export const useDisplaySettings = (businessId, packageId = null) => {
  const costTiersFF = useFeatureFlagPayload("tier-structure");

  const pkg = useQueryAPI({
    url: `v1/benefitspackage/${packageId}`,
    enabled: !!packageId,
  });

  const { data, ...rest } = useQueryAPI({
    url: `/public/v1/bp/display_settings?id=eq.${businessId}`,
    params: {
      axios: {
        headers: {
          "Accept-Profile": "brite_public",
        },
      },
    },
    defaultValue: [],
  });

  const getLabel = (value, key) => {
    const labels = costTiersFF?.value
      ? contributionLabelDefaults
      : deprecatedContributionLabelDefaults;
    return value || labels[key];
  };

  const displaySettings = useMemo(() => {
    const settings = data?.[0] || {};

    const employee = getLabel(settings?.custom_label_employee, "employee");
    const children = getLabel(
      settings?.custom_label_employee_children,
      "children"
    );
    const spouse = getLabel(settings?.custom_label_employee_spouse, "spouse");
    const child = getLabel(settings?.custom_label_child, "child");
    const family = getLabel(settings?.custom_label_family, "family");

    const labels = {
      employee,
      children,
      spouse,
      family,
      child,
    };

    const premiumInterval =
      pkg?.data?.PremiumIntervalsPerYear ||
      settings?.premium_intervals_per_year ||
      12;

    return {
      ...labels,
      premiumInterval,
    };
  }, [data?.length, rest?.dataUpdatedAt, pkg?.dataUpdatedAt]);

  const saveDisplaySettings = async (updates) => {
    const body = {
      id: businessId,
      custom_label_employee_children: updates?.children ?? null,
      custom_label_employee_spouse: updates?.spouse ?? null,
      custom_label_employee: updates?.employee ?? null,
      custom_label_family: updates?.family ?? null,
      custom_label_child: updates?.child ?? null,
    };
    try {
      await CustomAxios.patch(
        `/v1/bp/display_settings?id=eq.${businessId}`,
        body,
        {
          headers: { "Content-Profile": "brite_public" },
        }
      );
    } catch (err) {
      rest?.refetch();
      console.log(err);
      toast.error("Error saving");
    }
  };

  return { ...rest, displaySettings, saveDisplaySettings };
};

export const getTierStructure = ({ displaySettings, cost, featureFlag }) => {
  const keys = featureFlag
    ? cost?.TotalMonthlyPremiums?.Tiers || tierStructures[2]
    : tierStructures[2];
  const tierStructure = keys?.length ? keys?.length : 4;

  if (!featureFlag) {
    return {
      keys,
      tierStructure,
      labels: {
        EmployeeOnly: displaySettings?.employee,
        EmployeeSpouse: displaySettings?.spouse,
        EmployeeChildren: displaySettings?.children,
        Family: displaySettings?.family,
      },
    };
  } else {
    const tierLabels = getStructureByList(displaySettings, tierStructure - 2);
    const labels = keys?.reduce(
      (prev, item, idx) => ({
        ...prev,
        [item]: tierLabels[idx],
      }),
      {}
    );
    return {
      keys,
      tierStructure,
      labels,
    };
  }
};

const getStructureByList = (displaySettings, index) => {
  const tierStructures = [
    // Tier 2
    [displaySettings?.employee, `${displaySettings?.employee} + 1 or more`],
    // Tier 3
    [
      displaySettings?.employee,
      `${displaySettings?.employee} + 1`,
      `${displaySettings?.employee} + 2 or more`,
    ],
    // Tier 4
    [
      displaySettings?.employee,
      `${displaySettings?.employee} + ${displaySettings?.spouse}`,
      `${displaySettings?.employee} + ${displaySettings?.children}`,
      displaySettings?.family,
    ],
    // Tier 5
    [
      displaySettings?.employee,
      `${displaySettings?.employee} + ${displaySettings?.spouse}`,
      `${displaySettings?.employee} + ${displaySettings?.child}`,
      `${displaySettings?.employee} + ${displaySettings?.children}`,
      displaySettings?.family,
    ],
  ];
  return tierStructures[index];
};
