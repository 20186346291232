import { useState } from "react";
import { useQueryAPI } from "../../react-query";

export const defaultBaseCourse = "Default Brite Course";

export const useBaseCourses = () => {
  const [current, setCurrent] = useState(null);

  const baseCoursesQuery = useQueryAPI({
    url: `v1/course-catalog`,
    defaultValue: [],
    retry: 1,
  });

  return {
    current,
    setCurrent,
    ...baseCoursesQuery,
  };
};
