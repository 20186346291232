import { set } from "lodash";
import { extendedStates, statesMap } from "../../constants";
import { productModifier } from "../../products/configs/product-utils";
import { getStoredValue } from "../../products/field-components/field-input";
import { planTypeOptions } from "../../products/field-components/field-types";

export const extractNumbers = (string, fallback = 0) => {
  try {
    return Number(string.replace(/[^0-9.]/g, ""));
  } catch (err) {
    console.log(err);
    return fallback;
  }
};

const getPercentOrCurrency = (value) => {
  const dollarIndex = value?.indexOf("$");
  const percentIndex = value?.indexOf("%");
  if (percentIndex > -1 || dollarIndex > -1) {
    if (percentIndex > dollarIndex) {
      return "%";
    } else {
      return "$";
    }
  }
  return "";
};

// All these values should be lower-case
const afterDeductibleParseStrings = [
  "after deductible",
  "ad",
  "deductible",
  "ded. then",
];

const coveredParseStrings = ["covered"];

const advancedInputField = (data, fieldProps) => {
  try {
    const advancedConfig = {
      ...fieldProps.config,
      storeValueAs: "number",
    };

    const splits = data.split(" ");
    const filtered = splits.filter((item) => /\d/.test(item));
    const values = filtered;
    const stringCheck = values.join("");
    let updates = getStoredValue(
      fieldProps?.field,
      advancedConfig,
      fieldProps?.value
    );

    if (stringCheck.includes("/") || values.length > 1) {
      updates.ADOrCopay = "COPAY + %";
      const dollarValue =
        values.find((item) => item.includes("$")) || values[0];
      const specialCopay = extractNumbers(dollarValue);

      updates.SpecialCopay = getStoredValue(
        fieldProps?.field,
        advancedConfig,
        specialCopay
      );

      const percentValue =
        values.find((item) => item.includes("%")) || values[1];
      const value = extractNumbers(percentValue);

      const field = {
        ...fieldProps?.field,
        Type: "percent",
      };
      updates.Value = getStoredValue(field, advancedConfig, value);
    } else if (
      afterDeductibleParseStrings.some((item) =>
        data?.toLowerCase()?.includes(item)
      )
    ) {
      updates.ADOrCopay = "AD";
    } else if (
      coveredParseStrings.some((item) => data?.toLowerCase()?.includes(item))
    ) {
      if (data?.toLowerCase()?.includes("not")) {
        updates.ADOrCopay = "Not Covered";
      } else {
        updates.ADOrCopay = "100% Covered";
      }
    } else {
      updates.ADOrCopay = "COPAY";
    }

    if (updates.ADOrCopay === "COPAY + %") {
      updates.PercentOrDollar = "%";
    } else {
      const percentOrDollar = getPercentOrCurrency(values.at(-1));
      if (percentOrDollar) {
        updates.PercentOrDollar = percentOrDollar;
      }
    }

    if (values.length === 1) {
      const field = {
        ...fieldProps?.field,
        Type: updates.PercentOrDollar === "%" ? "percent" : "dollar",
      };
      const next = getStoredValue(field, advancedConfig, values[0]);
      updates.Value = next;
    }
    fieldProps.updateProperty(fieldProps?.field?.PropertyChain, updates);
  } catch (err) {
    console.warn(err);
  }
};

const prescriptionDrugTier = (data, fieldProps) => {
  const append = "Tier Structure";
  const tierNumber = extractNumbers(data, 3);
  let next;
  if (tierNumber > 2 && tierNumber < 7) {
    next = `${tierNumber} ${append}`;
  } else {
    next = "3 Tier Structure";
  }
  fieldProps.updateProperty(fieldProps?.field?.PropertyChain, next);
};

const prescriptionDrugDeductible = (data, fieldProps) => {
  if (data.toLowerCase() === "none") {
    const next = {
      Value: -2,
      MaxValue: null,
      PercentOrDollar: "",
      ADOrCopay: "",
      SpecialCopay: 0,
    };
    fieldProps.updateProperty(fieldProps?.field?.PropertyChain, next);
  } else {
    let next = {
      ...fieldProps?.value,
      Value: data,
    };

    const percentOrDollar = getPercentOrCurrency(data);
    if (percentOrDollar) {
      next.PercentOrDollar = percentOrDollar;
    }

    next.Value = extractNumbers(data, 0);
    fieldProps.updateProperty(fieldProps?.field?.PropertyChain, next);
  }
};

export const date = (data, fieldProps) => {
  try {
    const nextDate = new Date(data).toISOString();
    fieldProps.updateProperty(fieldProps?.field?.PropertyChain, nextDate);
  } catch (err) {
    console.log(err);
  }
};

export const medicalPlanTypes = (data, fieldProps) => {
  const { bestMatch } = findBestMatch(data, planTypeOptions);
  fieldProps.updateProperty(
    fieldProps?.field?.PropertyChain,
    bestMatch.target.toLowerCase()
  );
};

export const states = (data, fieldProps) => {
  const shortenedStates = extendedStates.map(({ id }) => id);
  const shortMatch = findBestMatch(data, shortenedStates);
  if (shortMatch.bestMatch.target === "ALL") {
    fieldProps.updateProperty(
      fieldProps?.field?.PropertyChain,
      shortenedStates
    );
  } else {
    const list = shortMatch.ratings
      .filter(({ rating }) => rating > 0)
      .map(({ target }) => target);

    fieldProps.updateProperty(fieldProps?.field?.PropertyChain, list);
  }
};

const carrier = (data, fieldProps, meta) => {
  meta?.setSearchTerm(data);
};

const surestInputField = (data, fieldProps) => {
  try {
    const field = {
      Type: "dollar",
    };
    const config = {
      storeValueAs: "number",
    };

    const splits = data.split(" ");
    const filtered = splits.filter((item) => /\d/.test(item));

    let lower,
      upper = "";
    if (filtered?.length > 1) {
      lower = getStoredValue(field, config, filtered[0]);
      upper = getStoredValue(field, config, filtered[1]);
    } else {
      lower = getStoredValue(field, config, filtered[0]);
    }

    const [property1, property2, property3] =
      fieldProps?.field?.PropertyChain?.split(".");

    fieldProps.updateProperty(`${property1}.${property2}`, {
      [`${property3}Lower`]: lower,
      [`${property3}Upper`]: upper,
    });
  } catch (err) {
    console.warn(err);
  }
};

const contributionToggle = (data, fieldProps) => {};

const toggle = (data, fieldProps, meta) => {
  const trueValues = ["yes", "true", "y", "t"];
  if (trueValues.includes(data.toLowerCase())) {
    fieldProps.updateProperty(fieldProps?.field?.PropertyChain, true);
  } else {
    fieldProps.updateProperty(fieldProps?.field?.PropertyChain, false);
  }
};

// *******************************************************************
// ** AUTO-PASTE-TYPES -> based on the product-layout field types:
// ** These are used to format the values copied from the spreadsheet
// ** so we can successfully apply them to the various fields.
// *******************************************************************
export const autoPasteTypes = {
  date,
  states,
  medicalPlanTypes,
  advancedInputField,
  surestInputField,
  prescriptionDrugTier,
  prescriptionDrugDeductible,
  carrier,
  contributionToggle,
  toggle,
};

const monthlyContributions = (state, data, fieldProps) => {
  const percentOrDollar = getPercentOrCurrency(data);
  let Cost = { ...state?.product?.Cost };
  const key = fieldProps?.field?.PropertyChain?.replace("Cost.", "");
  const value = extractNumbers(data);
  Cost = set(Cost, key, value);
  if (percentOrDollar) {
    Cost.Contributions.ContributionType = percentOrDollar;
  }
  productModifier.mergeProduct(state, { Cost });
};

export const autoPastePropertyChains = {
  "Cost.Contributions.MonthlyContributions.EmployeeOnly": monthlyContributions,
  "Cost.Contributions.MonthlyContributions.EmployeeChild": monthlyContributions,
  "Cost.Contributions.MonthlyContributions.EmployeeChildren":
    monthlyContributions,
  "Cost.Contributions.MonthlyContributions.EmployeeSpouse":
    monthlyContributions,
  "Cost.Contributions.MonthlyContributions.Family": monthlyContributions,
};

export const disabledTypes = {
  // states: "Select applicable states from the drop down.",
};

export const compareTwoStrings = (first = "", second = "") => {
  first = first.replace(/\s+/g, "");
  second = second.replace(/\s+/g, "");

  if (first === second) return 1; // identical or empty
  if (first.length < 2 || second.length < 2) return 0; // if either is a 0-letter or 1-letter string

  let firstBigrams = new Map();
  for (let i = 0; i < first.length - 1; i++) {
    const bigram = first.substring(i, i + 2);
    const count = firstBigrams.has(bigram) ? firstBigrams.get(bigram) + 1 : 1;

    firstBigrams.set(bigram, count);
  }

  let intersectionSize = 0;
  for (let i = 0; i < second.length - 1; i++) {
    const bigram = second.substring(i, i + 2);
    const count = firstBigrams.has(bigram) ? firstBigrams.get(bigram) : 0;

    if (count > 0) {
      firstBigrams.set(bigram, count - 1);
      intersectionSize++;
    }
  }

  return (2.0 * intersectionSize) / (first.length + second.length - 2);
};

export const findBestMatch = (mainString, targetStrings) => {
  const ratings = [];
  let bestMatchIndex = 0;

  for (let i = 0; i < targetStrings.length; i++) {
    const currentTargetString = targetStrings[i];
    const currentRating = compareTwoStrings(mainString, currentTargetString);
    ratings.push({ target: currentTargetString, rating: currentRating });
    if (currentRating > ratings[bestMatchIndex].rating) {
      bestMatchIndex = i;
    }
  }

  const bestMatch = ratings[bestMatchIndex];

  return {
    ratings: ratings,
    bestMatch: bestMatch,
    bestMatchIndex: bestMatchIndex,
  };
};
