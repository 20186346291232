import { css } from "emotion";
import { useEditorResource } from "../use-editor-resource";
import { useMemo } from "react";

const editorStyles = css`
  max-width: 100%;
  button {
    max-width: 100%;
    display: inline-block;
    outline: none;
    border: none;
    background: none;
    appearance: none;
    text-align: center;
    padding: 0;
    margin: 0;
    overflow: hidden;
    resize: none;
  }
`;

export const Button = ({ element }) => {
  const { content, attributes } = element;
  const { data: styles } = useEditorResource("styles");

  const combinedStyles = {
    ...styles?.button,
    ...(attributes?.style || {}),
  };

  const { data: smartFields } = useEditorResource("liveSmartFields");

  const isSmartField = content?.startsWith("{{") && content?.endsWith("}}");

  const buttonContent = useMemo(() => {
    if (
      isSmartField &&
      smartFields?.[content]?.Success &&
      smartFields?.[content]?.Value
    ) {
      return smartFields?.[content]?.Value;
    }
    return content;
  }, [element, isSmartField, content]);

  return (
    <>
      <div className={editorStyles} data-component={true}>
        <button
          className={css`
            pointer-events: none;
          `}
          style={combinedStyles}
        >
          {buttonContent}
        </button>
      </div>
    </>
  );
};
