import { utils, SSF } from "xlsx";
import { css } from "emotion";
import { getTierStructure } from "../../BriteEditor/editor-components/benefits/plan-comparisons/use-display-settings";

export const CSVToArray = (text) => {
  let p = "",
    row = [""],
    ret = [row],
    i = 0,
    r = 0,
    s = !0,
    l;
  for (l of text) {
    if ('"' === l) {
      if (s && l === p) row[i] += l;
      s = !s;
    } else if ("," === l && s) l = row[++i] = "";
    else if ("\n" === l && s) {
      if ("\r" === p) row[i] = row[i].slice(0, -1);
      row = ret[++r] = [(l = "")];
      i = 0;
    } else row[i] += l;
    p = l;
  }
  return ret;
};

export const htmlDecode = (input) => {
  var doc = new DOMParser().parseFromString(input, "text/html");
  return doc.documentElement.textContent;
};

export const parseWorksheet = (workbook, activeSheet) => {
  if (!activeSheet) {
    return [];
  }
  try {
    const worksheet = workbook.Sheets[activeSheet];
    const lastCell = Object.keys(worksheet || {}).reduce(
      (prev, cellRef) => {
        if (!cellRef.startsWith("!")) {
          const ref = utils.decode_cell(cellRef);
          return { c: Math.max(prev.c, ref.c), r: Math.max(prev.r, ref.r) };
        }
        return prev;
      },
      { c: 0, r: 0 }
    );
    const lastRef = utils.encode_cell(lastCell);
    const range = utils.decode_range(`A1:${lastRef}`);

    // ************************************
    // ** MERGED CELLS
    // ************************************
    const merges =
      worksheet?.["!merges"]?.reduce((prev, item) => {
        const minCol = Math.min(item.s.c, item.e.c);
        const minRow = Math.min(item.s.r, item.e.r);
        const difCol = Math.abs(item.s.c - item.e.c);
        const difRow = Math.abs(item.s.r - item.e.r);
        const ref = utils.encode_cell({ c: minCol, r: minRow });

        let hidden = {};
        for (let R = item.s.r; R <= item.e.r; ++R) {
          for (let C = item.s.c; C <= item.e.c; ++C) {
            const coordinates = { c: C, r: R };
            const ref = utils.encode_cell(coordinates);
            hidden = {
              ...hidden,
              [ref]: { c: difCol * -1, r: difRow * -1, isHidden: true },
            };
          }
        }

        return {
          ...prev,
          ...hidden,
          [ref]: { c: difCol, r: difRow, isHidden: false },
        };
      }, {}) || {};

    // ************************************
    // ** Build Sheet rows/columns
    // ************************************

    let columnHeaders = [{ columnWidth: 100, ref: "" }];

    for (let C = range.s.c; C <= range.e.c; ++C) {
      const columnWidth = getColumnWidth(worksheet?.["!cols"], C);
      const cellRef = utils.encode_cell({ c: C, r: 0 });
      const ref = cellRef.replace("1", "");
      columnHeaders.push({ columnWidth, ref });
    }

    let rows = [];
    for (let R = range.s.r; R <= range.e.r + 10; R++) {
      rows.push([
        {
          columnWidth: 100,
          height: ROW_HEIGHT,
          index: R + 1,
        },
      ]);
      for (let C = range.s.c; C <= range.e.c; C++) {
        const coordinates = { c: C, r: R };
        const ref = utils.encode_cell(coordinates);
        rows[R].push(
          getCell({
            columnHeaders,
            worksheet,
            merges,
            ref,
          })
        );
      }
    }

    return [columnHeaders, ...rows];
  } catch (err) {
    console.log(err);
    throw err;
  }
};

export const getColumnWidth = (columns, index) => {
  return Math.max(columns?.[index]?.wpx || 150, 150) * 1.2;
};

export const ROW_HEIGHT = 56;

export const getCell = ({ worksheet, ref, merges, columnHeaders }) => {
  const coordinates = utils.decode_cell(ref);
  const cellData = worksheet?.[ref] || {};
  const merge = merges?.[ref] || {};
  const isMerged = ref in merges;
  const columnWidth = columnHeaders[coordinates.c + 1]?.columnWidth;
  let width = columnWidth;
  if (isMerged && !merges?.[ref]?.isHidden) {
    const merge = merges[ref];
    for (let idx = 1; idx <= merge.c; idx++) {
      const nextWidth = columnHeaders[coordinates.c + 1 + idx]?.columnWidth;
      width += nextWidth;
    }
  }

  let value = cellData?.w;
  if (cellData && cellData.t === "n" && cellData.z) {
    // count decimal places in cellData.v;
    let decimalPlaces = (cellData.v + "").split(".")[1]?.length || 0;
    if (decimalPlaces > 0) {
      decimalPlaces = Math.min(decimalPlaces, 8);
      // increase precision of cellData.z to match number of decimal places
      const precision = "0." + "0".repeat(decimalPlaces);
      if (!cellData.z.includes(precision)) {
        const fmt = cellData.z.replaceAll(/0\.?0*/g, precision);
        value = SSF.format(fmt, cellData.v);
      }
    }
  }

  const cell = {
    ref,
    merge,
    width,
    isMerged,
    columnWidth,
    rowIdx: coordinates.r,
    value: value || "",
    style: cellData?.s || {},
  };

  return {
    ...cell,
    style: extractStyles(cell),
  };
};

export const extractStyles = (cell) => {
  const height = cell?.isMerged
    ? ROW_HEIGHT * (cell?.merge?.r + 1)
    : ROW_HEIGHT;
  const dimensions = `
    min-width: ${cell?.width}px;
    max-width: ${cell?.width}px;
    height: ${height}px;
    ${cell?.isMerged ? "z-index: 10;" : ""}
  `;
  const fill = "";
  // TODO: Do we want to add in background colors?
  // const fill = Object.entries(cell?.style?.fill || {}).reduce(
  //   (prev, [key, value]) => {
  //     if (key === "fgColor" && value?.rgb) {
  //       const rgb = value?.rgb?.slice(2);
  //       return prev + `background-color: #${rgb};`;
  //     }
  //     return prev;
  //   },
  //   ``
  // );

  const alignment = Object.entries(cell?.style?.alignment || {})?.reduce(
    (prev, [key, value]) => {
      if (key === "vertical") {
        if (value === "center") {
          return prev + `align-items: center;`;
        } else if (value === "top") {
          return prev + `align-items: start;`;
        } else if (value === "bottom") {
          return prev + `align-items: end;`;
        }
      } else if (key === "horizontal") {
        if (value === "center") {
          return prev + `justify-content: center;`;
        } else if (value === "left") {
          return prev + `justify-content: left;`;
        } else if (value === "right") {
          return prev + `justify-content: right;`;
        }
      }
      return prev;
    },
    `display: flex;`
  );

  const font = Object.entries(cell?.style?.font || {}).reduce(
    (prev, [key, value]) => {
      if (key === "bold") {
        return prev + `font-weight: ${value ? "bold" : "normal"};`;
      } else if (key === "italic") {
        return prev + `font-style: ${value ? "italic" : "normal"};`;
      } else if (key === "underline") {
        return prev + `text-decoration: ${value ? "underline" : "none"};`;
      } else if (key === "strike") {
        return prev + `text-decoration: ${value ? "strikethough" : "none"};`;
      }
      //  else if (key === "sz") {
      //   return prev + `font-size: ${value}px;`;
      // }
      //  else if (key === "color" && value?.rgb) {
      //   const rgb = value?.rgb?.slice(2);
      //   return prev + `color: #${rgb};`;
      // }
      return prev;
    },
    ``
  );

  const cssStyle = css`
    ${dimensions + fill}
    ${alignment}
    p {
      color: black;
      ${font}
    }
  `;

  return cssStyle;
};

export const expandCostDetails = ({
  cost,
  displaySettings,
  field,
  config,
  index,
  featureFlag,
}) => {
  let lastIdx = index;

  const tiers = getTierStructure({
    displaySettings,
    featureFlag,
    cost,
  });

  const contributionType = cost?.Contributions?.ContributionType || "$";
  const contributionFieldType =
    contributionType === "$"
      ? "dollar"
      : contributionType === "%"
      ? "percent"
      : "";

  let fields = [];

  if (featureFlag) {
    fields.push({
      field: {
        Type: "auto-fill-tier-structure",
        DisplayValue: "Tier Rate Structure",
        PropertyChain: `Cost.TotalMonthlyPremiums.Tiers`,
      },
      config,
      index: lastIdx,
    });
  }

  // ****************************
  // ** Total Monthly Premiums **
  // ****************************
  for (const key of tiers?.keys) {
    lastIdx++;
    fields.push({
      field: {
        Type: "dollar",
        State: field?.State,
        DisplayValue: `Total Monthly Premiums - ${
          tiers?.labels?.[key] || "Family"
        }`,
        PropertyChain: `Cost.TotalMonthlyPremiums.${key || "Family"}`,
      },
      config: {
        ...config,
        storeValueAs: "number",
        roundUp: false,
      },
      index: lastIdx,
    });
  }

  // ****************************
  // ** Employer Contributions **
  // ****************************
  for (const key of tiers?.keys) {
    lastIdx++;
    fields.push({
      field: {
        autoFillType: "contributionToggle",
        State: field?.State,
        Type: contributionFieldType,
        DisplayValue: `Monthly Contributions - ${
          tiers?.labels?.[key] || "Family"
        }`,
        PropertyChain: `Cost.Contributions.MonthlyContributions.${
          key || "Family"
        }`,
      },
      config: {
        ...config,
        roundUp: false,
        storeValueAs: "number",
      },
      index: lastIdx,
    });
  }
  return fields;
};

// DisplayValue;
// PropertyChain;
// State;
// Type;

export const getLatestTrackedCell = (action) => {
  const { data = {} } = action;
  const history = data?.fieldHistory || [];

  if (!history.length) {
    return "";
  }

  // Find product changes
  const currentProductHistory = history?.filter(
    (item) =>
      item.product_id === data?.productId &&
      item?.property_chain === data?.property
  );

  // AKA anything OTHER than imported history
  const hasUserChanges = !currentProductHistory.every((item) =>
    item?.source.includes("file_importer")
  );

  let latest;
  if (hasUserChanges) {
    latest = currentProductHistory?.find(
      (item) => !item?.source?.includes("file_importer")
    );
  } else if (currentProductHistory?.length) {
    latest = currentProductHistory?.[0];
  }
  return latest;
};

export const normalizePercent = (percent) => {
  // if it's not a number type do nothing
  if (typeof percent !== "number") {
    return percent;
  }

  if (percent < 1) {
    percent = percent * 100;
  }

  return percent;
};

export const getSuggestionLocation = (suggestion) => ({
  top: normalizePercent(suggestion.suggested_area_percent_from_top) + "%",
  left: normalizePercent(suggestion.suggested_area_percent_from_left) + "%",
  height: normalizePercent(suggestion.suggested_area_percent_of_height) + "%",
  width: normalizePercent(suggestion.suggested_area_percent_of_width) + "%",
});

// **********************************
// ** MEDIA MODIFIERS
// **********************************

export const mediaModifiers = {
  setCurrentPropertyChain: (media, property) => {
    const { dispatch } = media;
    dispatch({
      type: "set-current-property-chain",
      property,
    });
  },
  refreshSheet: (media) => {
    const { dispatch } = media;
    dispatch({ type: "parsing-workbook" });
    setTimeout(() => {
      dispatch({ type: "refresh-sheet" });
    }, 200);
  },
  selectCell: (media, cell, shouldSetNextFieldIdx = false, cellRef = null) => {
    const { dispatch } = media;
    dispatch({
      shouldSetNextFieldIdx,
      type: "select-cell",
      cell,
      cellRef,
    });
  },
  selectText: (media, textSelection, shouldSetNextFieldIdx = false) => {
    const { dispatch } = media;

    dispatch({
      shouldSetNextFieldIdx,
      type: "select-text",
      textSelection,
    });
  },
  setFieldIdx: (media, index) => {
    const { dispatch } = media;
    dispatch({
      type: "set-field-index",
      index,
    });
  },
  setActiveSheet: (media, activeSheet) => {
    const { dispatch } = media;
    dispatch({
      type: "set-active-sheet",
      activeSheet,
    });
  },
  turnOnOverlay: (media, message) => {
    const { dispatch } = media;
    dispatch({
      type: "set-overlay",
      value: { display: true, message },
    });
  },
  turnOffOverlay: (media) => {
    const { dispatch } = media;
    dispatch({
      type: "set-overlay",
      value: { display: false, message: "" },
    });
  },
  resetFieldHistory: (media) => {
    const { dispatch } = media;
    dispatch({
      type: "reset-field-history",
    });
  },
  setMaxFieldIndex: (media, maxFieldIdx) => {
    const { dispatch } = media;
    dispatch({
      type: "set-max-field-index",
      maxFieldIdx,
    });
  },
  setComplete: (media, value) => {
    const { dispatch } = media;
    dispatch({
      type: "set-complete",
      value,
    });
  },
  resetProduct: (media) => {
    const { dispatch } = media;
    dispatch({ type: "reset-product" });
  },
  setFocusState: (media, value) => {
    const { dispatch } = media;
    dispatch({ type: "set-focus-state", value });
  },
  setFieldHistory: (media, data, options) => {
    const { dispatch } = media;
    dispatch({ type: "set-field-history", data, options });
  },
  setFocus: (media, focus = {}) => {
    const { dispatch } = media;
    dispatch({
      type: "set-focus",
      focus,
    });
  },
  mergeFocus: (media, focus = {}) => {
    const { dispatch } = media;
    dispatch({
      type: "merge-focus",
      focus,
    });
  },
  setScrollToLocation: (media, scrollToLocation) => {
    const { dispatch } = media;
    dispatch({
      type: "set-scroll-to-location",
      scrollToLocation,
    });
  },
  setSearchResults: (media, searchResults) => {
    const { dispatch } = media;
    dispatch({
      type: "set-search-results",
      searchResults,
    });
  },
  acceptSuggestion: (media, suggestion, shouldSetNextFieldIdx = false) => {
    const { dispatch } = media;
    if (
      media?.state?.fileType === "xlsx" &&
      !!suggestion?.suggested_column &&
      !!suggestion?.suggested_row
    ) {
      const cellRef = utils.encode_cell({
        c: suggestion.suggested_column - 1,
        r: suggestion.suggested_row - 1,
      });
      dispatch({
        shouldSetNextFieldIdx,
        type: "select-cell",
        cell: null,
        cellRef,
      });
    } else if (media?.state?.fileType === "pdf") {
      dispatch({
        shouldSetNextFieldIdx,
        type: "select-text",
        textSelection: {
          value: suggestion.suggested_value,
          locationOnPage: getSuggestionLocation(suggestion),
          pageNumber: suggestion.suggested_area_page_number + "",
        },
      });
    }
  },
};

export const decode = (value) => {
  try {
    const decodedValue = utils.decode_cell(value);
    return decodedValue;
  } catch (err) {
    return { c: null, r: null };
  }
};
