import { css, cx } from "emotion";
import { useState } from "react";
import { toast } from "react-toastify";
import { FontLinks } from "../BriteEditor/utility-components/font-links";
import { CustomAxios } from "../redux/axios/axios";
import { Button, Div, Text } from "../shared/components";
import { flex, px } from "../shared/shared-styles";
import { colors, mediaQueryFn } from "../shared/styles";
import { useStore } from "../store-provider/use-store";
import {
  buttonContainer,
  colorContainer,
  leftPanelStyle,
  lineContainer,
} from "./design-styles";
import { fontTypesMap, previewFontStyle } from "./utils";

const itemContainer = (selected, type = "") => css`
  ${flex("space-between wrap")}
  margin: ${px.md} 0;
  :hover {
    background-color: ${colors?.gray[100]};
  }
  border: 1px solid ${colors.gray[300]};
  border-radius: 8px;
  padding: 24px 16px;
  cursor: pointer;
  width: 100%;
  ${mediaQueryFn("less-than", "900px")} {
    ${type === "wrap" ? `> p { width: 100%; padding-bottom: 8px; }` : ""}
  }
  ${selected &&
  `
    border: 1px solid ${colors.black};
    outline: 1px solid ${colors.black};
    background-color: ${colors?.gray[100]};
  `}
`;

export const DesignStylesViewer = (props) => {
  const { designStyleProps, course = {} } = props;

  const { current, fontURLs, selected, setSelected } = designStyleProps;

  const {
    data: { isBriteUser },
  } = useStore();

  const [loading, setLoading] = useState(false);

  return (
    <Div
      className={css`
        height: calc(100vh - 100px);
        overflow-y: auto;
        width: 100%;
        padding: 0 32px;
        ::-webkit-scrollbar {
          display: none;
        }
      `}
    >
      <Div css={leftPanelStyle}>
        <FontLinks fontURLs={fontURLs} />

        <Text styles="h2 mv">Colors</Text>
        <Div
          css={cx(
            css`
              ${flex("jcsb aic")} margin: ${px.md} 0;
            `,
            itemContainer(selected === "accent")
          )}
          onClick={() => setSelected("accent")}
        >
          <Text styles="label">Accent Color</Text>
          <span
            className={colorContainer(current?.Theme?.Body?.AccentColor)}
          ></span>
        </Div>
        <Div
          css={cx(
            css`
              ${flex("jcsb aic")} margin: ${px.md} 0;
            `,
            itemContainer(selected === "backgroundColor")
          )}
          onClick={() => setSelected("backgroundColor")}
        >
          <Text styles="label">Background Color</Text>
          <span
            className={colorContainer(current?.Theme?.Body?.BackgroundColor)}
          ></span>
        </Div>

        <Text styles="h2 mv padt-xl">Fonts</Text>
        {Object.entries(fontTypesMap)?.map(([key, props]) => (
          <Div
            css={cx(
              css`
                ${flex("jcsb aic")} margin: ${px.md} 0;
              `,
              itemContainer(selected === props?.property, "wrap")
            )}
            key={key}
            onClick={() => setSelected(props?.property)}
          >
            <Text styles="label">{props?.display}</Text>
            <span className={previewFontStyle(current?.Theme, props)}>
              {props?.preview}
            </span>
          </Div>
        ))}

        <Text styles="h2 mv padt-xl">Button</Text>
        <Div
          className={cx(
            css`
              ${flex("aic jcsb")} margin: ${px.md} 0;
            `,
            itemContainer(selected === "button")
          )}
          onClick={() => setSelected("button")}
        >
          <Text styles="label">Button Style</Text>
          <button className={buttonContainer(current?.Theme)}>Button</button>
        </Div>

        <Text styles="h2 mv padt-xl">Line</Text>
        <Div
          className={cx(
            css`
              ${flex("aic jcsb")} margin: ${px.md} 0;
            `,
            itemContainer(selected === "line")
          )}
          onClick={() => setSelected("line")}
        >
          <Text styles="label">Line Style</Text>
          <div
            className={css`
              width: 50%;
            `}
          >
            <div className={lineContainer(current?.Theme)} />
          </div>
        </Div>
      </Div>
    </Div>
  );
};
