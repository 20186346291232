import { getGenericQueryProps, getUrl } from ".";

const url = "/v1/benefitspackage/:packageId";

export const benefitsPackage = {
  url,
  key: "benefits-package",
  getResourceOptions: (resourceProps) => {
    const resourceUrl = getUrl(url, resourceProps);
    return {
      url: resourceUrl,
      queryProps: {
        initialData: [],
        ...getGenericQueryProps(resourceUrl, resourceProps),
      },
    };
  },
  utils: {
    getPut: (id, body) => ({
      url: `/v1/benefitspackage/${id}`,
      body,
      optimistic: () => body,
    }),
  },
};
