import { createAction, handleActions } from "redux-actions";
import { takeEvery, all, call, put } from "redux-saga/effects";

import { CustomAxios } from "../axios/axios";
import { groupBy } from "lodash";
import { toast } from "react-toastify";

export const getAnalytics = createAction("GET_ANALYTICS");
export const getAnalyticsSuccess = createAction("GET_ANALYTICS_SUCCESS");
export const getAnalyticsFailure = createAction("GET_ANALYTICS_FAILURE");

const getAnalyticsSaga = function* () {
  const handleGetAnalytics = function* (action) {
    try {
      const response = yield call(
        CustomAxios.get,
        `/v1/analytics/${
          action.payload.id
        }?startDate=${action.payload.startDate.format()}&endDate=${action.payload.endDate.format()}`
      );
      const feedbackResponse = yield call(
        CustomAxios.get,
        `/v1/analytics/${
          action.payload.id
        }/feedback?startDate=${action.payload.startDate.format()}&endDate=${action.payload.endDate.format()}`
      );
      const feedbackCommentsResponse = yield call(
        CustomAxios.get,
        `/v1/analytics/${
          action.payload.id
        }/feedback/comments?startDate=${action.payload.startDate.format()}&endDate=${action.payload.endDate.format()}`
      );
      let feedbackComments = feedbackCommentsResponse.data;
      let feedbackData = feedbackResponse.data;
      let data = response.data;
      if (!data.Activity) {
        data.Activity = [];
      }
      if (!data.Questions) {
        data.Questions = [];
      }

      if (!data.Recommendations) {
        data.Recommendations = [];
      }
      if (!data.Buyups) {
        data.Buyups = [];
      }
      console.log("dat a", data);

      let comments = [];

      if (feedbackComments) {
        comments = groupBy(feedbackComments, function (comment) {
          return comment.NpsNumber;
        });
      }
      let index = 0;

      if (!feedbackData) {
        data.Feedback = [];
      } else {
        let newFeedback = [];
        feedbackData = feedbackData.sort((a, b) => a.NpsNumber < b.NpsNumber);
        for (let i = 1; i < 6; i++) {
          if (!feedbackData[index] || i !== feedbackData[index].NpsNumber) {
            newFeedback.push({
              NpsNumber: i,
              Count: 0,
              Comments: comments[i] || [],
            });
          } else {
            newFeedback.push({
              ...feedbackData[index],
              Comments: comments[i] || [],
            });
            index = index + 1;
          }
        }

        let total = newFeedback.reduce((partialSum, b) => {
          return partialSum + b.Count;
        }, 0);

        for (let feedbackObj of newFeedback) {
          feedbackObj.percentage = Math.floor(
            (feedbackObj.Count / total) * 100
          );
          feedbackObj.width = (feedbackObj.percentage / 100) * 600;
        }
        let colors = ["#25282D", "#36414C", "#66737F", "#9AA7B5", "#D1DAE3"];
        let colorIndex = 0;
        newFeedback = newFeedback.sort((a, b) => b.percentage - a.percentage);
        newFeedback[0].color = colors[colorIndex];

        if (newFeedback[0].percentage > newFeedback[1].percentage) {
          colorIndex = colorIndex + 1;
        }
        newFeedback[1].color = colors[colorIndex];
        if (newFeedback[1].percentage > newFeedback[2].percentage) {
          colorIndex = colorIndex + 1;
        }
        newFeedback[2].color = colors[colorIndex];
        if (newFeedback[2].percentage > newFeedback[3].percentage) {
          colorIndex = colorIndex + 1;
        }
        newFeedback[3].color = colors[colorIndex];
        if (newFeedback[3].percentage > newFeedback[4].percentage) {
          colorIndex = colorIndex + 1;
        }
        newFeedback[4].color = colors[colorIndex];

        data.Feedback = newFeedback;
        console.log("newFeedback", newFeedback);
      }

      yield put(getAnalyticsSuccess(data));
    } catch (error) {
      console.warn(error);
      yield put(getAnalyticsFailure());
      toast.error("Could not load analytics.");
    }
  };

  yield takeEvery(getAnalytics.toString(), handleGetAnalytics);
};

const analyticsReducerDefaultState = {
  data: {
    Feedback: [],
    Buyups: [],
    Activity: [],
    Questions: [],
    Recommendations: [],
    Time: {
      TotalTime: 0,
    },
  },
  loading: false,
};

export const analyticsReducer = handleActions(
  {
    [getAnalytics]: (state, action) => {
      return Object.assign({}, state, { loading: true });
    },
    [getAnalyticsSuccess]: (state, action) => {
      return Object.assign({}, state, { loading: false, data: action.payload });
    },
    [getAnalyticsFailure]: (state, action) => {
      return Object.assign({}, state, analyticsReducerDefaultState);
    },
  },
  analyticsReducerDefaultState
);

export const analyticsSaga = function* () {
  yield all([getAnalyticsSaga()]);
};
