import { css } from "emotion";

export const mediaQueryFn = (type, px) => {
  const value =
    type === "less-than" ? "max" : type === "greater-than" ? "min" : "";
  return `@media only screen and (${value}-width: ${px})`;
};

export const mediaQueries = {
  xs: `@media only screen and (max-width: 380px)`,
  sm: `@media only screen and (max-width: 600px)`,
  lg: `@media only screen and (min-width: 600px)`,
};

export const colors = {
  black: "#25282D",

  purple: "#6D44FB",
  lightPurple: "#B8ADE2",
  lighterPurple: "#E2DAFE",

  briteGradient:
    "linear-gradient(270deg, #62A7E5 0%, #A949F5 50%, #C05194 100%)",

  red: { [100]: "#EB4E3D", [200]: "#FBDCD8" },
  orange: { [100]: "#F8A74C" },
  yellow: { [100]: "#FCD75C" },
  green: { [100]: "#47EB75", [200]: "#32AA4E", [300]: "#D8F4DF" },
  gray: {
    [600]: "#36414C",
    [500]: "#66737F",
    [400]: "#9AA7B5",
    [300]: "#D1DAE3",
    [200]: "#E8EDF3",
    [100]: "#F5F7FA",
  },
  lite: {
    purple: "#A085FC",
    orchid: "#DA66E8",
    razzle: "#FB59E1",
    radical: "#FF719C",
    rage: "#FF9586",
    tango: "#FFAF71",
    peel: "#FFC559",
  },
};

export const buildStyles = (styleMap, styles) => {
  const list = styles.split(" ");
  return list.reduce((prev, tag) => {
    if (tag in styleMap) {
      return prev + styleMap[tag];
    } else if (tag in tagMap) {
      return prev + tagMap[tag];
    } else return prev;
  }, "");
};

export const tagMap = {
  "hide-sm": ``,
  "hide-lg": ``,
  log: `border: 2px solid red;`,
  clickable: `
    cursor: pointer;
    :hover { background-color: ${colors.gray[200]}; }`,
  pointer: `cursor: pointer;`,
  "hide-scrollbar": `::-webkit-scrollbar { display: none; }`,
  "full-height": `height: 100%;`,
  "full-width": `width: 100%;`,
  oa: `overflow: auto;`,
  ov: `overflow: visible;`,
  fg: `flex-grow: 1;`,
  "pos:a": `position: absolute;`,
  "pos:f": `position: fixed;`,
  "pos:s": `position: sticky;`,
  "pos:r": `position: relative;`,
  "margin-xs": `margin: 4px;`,
  mb: `margin-bottom: 16px;`,
  mt: `margin-top: 16px;`,
  ml: `margin-left: 16px;`,
  mr: `margin-right: 16px;`,
  mh: `
        margin-right: 16px;
        margin-left: 16px;`,
  mha: `
        margin-right: auto;
        margin-left: auto;`,
  mv: `
        margin-top: 16px;
        margin-bottom: 16px;`,
  mva: `
        margin-top: 16px;
        margin-bottom: 16px;`,
  margin: `margin: 16px;`,
  ma: `margin: auto;`,
  "mb-sm": `margin-bottom: 8px;`,
  "mt-sm": `margin-top: 8px;`,
  "ml-sm": `margin-left: 8px;`,
  "mr-sm": `margin-right: 8px;`,
  "mh-sm": `
        margin-right: 8px;
        margin-left: 8px;`,
  "mv-sm": `
        margin-top: 8px;
        margin-bottom: 8px;`,
  "margin-sm": `margin: 8px;`,
  "mb-lg": `margin-bottom: 24px;`,
  "mt-lg": `margin-top: 24px;`,
  "ml-lg": `margin-left: 24px;`,
  "mr-lg": `margin-right: 24px;`,
  "mh-lg": `
        margin-right: 24px;
        margin-left: 24px;`,
  "mv-lg": `
        margin-top: 24px;
        margin-bottom: 24px;`,
  "margin-lg": `margin: 24px;`,
  "mb-xl": `margin-bottom: 32px;`,
  "mt-xl": `margin-top: 32px;`,
  "ml-xl": `margin-left: 32px;`,
  "mr-xl": `margin-right: 32px;`,
  "mh-xl": `
        margin-right: 32px;
        margin-left: 32px;`,
  "mv-xl": `
        margin-top: 32px;
        margin-bottom: 32px;`,
  "margin-xl": `margin: 32px;`,
  mn: `margin: 0;`,

  "pad-xs": `padding: 4px;`,
  "pad-sm": `padding: 8px;`,
  pad: `padding: 16px;`,
  "pad-lg": `padding: 24px;`,
  "pad-xl": `padding: 32px;`,
  padn: `padding: 0;`,
  padb: `padding-bottom: 16px;`,
  padt: `padding-top: 16px;`,
  padl: `padding-left: 16px;`,
  padr: `padding-right: 16px;`,
  padh: `
        padding-right: 16px;
        padding-left: 16px;`,
  padv: `
        padding-top: 16px;
        padding-bottom: 16px;`,
  "padb-sm": `padding-bottom: 8px;`,
  "padt-sm": `padding-top: 8px;`,
  "padl-sm": `padding-left: 8px;`,
  "padr-sm": `padding-right: 8px;`,
  "padh-sm": `
        padding-right: 8px;
        padding-left: 8px;`,
  "padv-sm": `
        padding-top: 8px;
        padding-bottom: 8px;`,
  "padh-xs": `
        padding-right: 4px;
        padding-left: 4px;`,
  "padv-xs": `
        padding-top: 4px;
        padding-bottom: 4px;`,
  "padb-lg": `padding-bottom: 24px;`,
  "padt-lg": `padding-top: 24px;`,
  "padl-lg": `padding-left: 24px;`,
  "padr-lg": `padding-right: 24px;`,
  "padh-lg": `
        padding-right: 24px;
        padding-left: 24px;`,
  "padv-lg": `
        padding-top: 24px;
        padding-bottom: 24px;`,
  "padb-xl": `padding-bottom: 32px;`,
  "padt-xl": `padding-top: 32px;`,
  "padl-xl": `padding-left: 32px;`,
  "padr-xl": `padding-right: 32px;`,
  "padh-xl": `
        padding-right: 32px;
        padding-left: 32px;`,
  "padv-xl": `
        padding-top: 32px;
        padding-bottom: 32px;`,
  flex: `display: flex;`,
  wrap: `flex-wrap: wrap;`,
  "fd:c": `flex-direction: column;`,
  "jc:sb": `
          display: flex;
          justify-content: space-between;`,
  "jc:sa": `
          display: flex;
          justify-content: space-around;`,
  "jc:se": `
          display: flex;
          justify-content: space-evenly;`,
  "jc:c": `
          display: flex;
          justify-content: center;`,
  "jc:r": `
          display: flex;
          justify-content: right;`,
  "jc:l": `
          display: flex;
          justify-content: left;`,
  "ai:c": `
          display: flex;
          align-items: center;`,
  "ai:s": `
          display: flex;
          align-items: start;`,
  "ai:e": `
          display: flex;
          align-items: end;`,
  radius: `border-radius: 8px;`,
  "max-image-size": `
          max-width: 150px;
          max-height: 40px;`,
  border: `
          border: 1px solid #9aa7b5;
          border-radius: 8px;
        `,
  "alt-list": `
    border-bottom: 1px solid #d1dae3;
    > div {
      padding: 16px 8px;
      :nth-child(odd) {
        background-color: #f5f7fa;
      }
    }
  `,
  center: `text-align: center;`,
  right: `text-align: right;`,
  left: `text-align: left;`,
  bold: `font-weight: bold;`,
};

const createStyles = (styles) =>
  styles.reduce(
    (prev, item) =>
      Object.entries(item.styles).reduce(
        (p, [key, value]) => ({
          ...p,
          [key]: `
        ${p[key] || ""}
        ${value}
        override-css`,
        }),
        prev
      ),
    {}
  );

const themeValues = createStyles([
  {
    elements: [],
    styles: {
      ...tagMap,
      "light-border": `
        border: 1px solid #9aa7b5;
        border-radius: 8px;
      `,
      "c-black": `color: #25282D;`,
      "bc-black": `background-color: #25282D;`,
    },
  },
  {
    elements: ["div"],
    styles: {
      box: `
          padding: 8px 16px;
          border-radius: 8px;
          background-color: white;
          filter: drop-shadow(2px 2px 4px rgba(0, 0, 0, .3));`,
      header: `
          display: flex;
          justify-content: space-between;
          align-items: center;
          margin-bottom: 16px;
          h1 {
            font-weight: bold;
            font-size: 34px;
            line-height: 42px;
            color: #25282d;
            margin: 0;
            padding: 0;
            margin-right: 12px;
            margin-left: 8px;
          }`,
    },
  },
  {
    elements: ["p"],
    styles: {
      h1: `
        font-family: 'Roboto';
        font-style: normal;
        font-weight: 700;
        font-size: 34px;
        line-height: 48px;
        letter-spacing: 0.25px;
        color: #25282D;      
      `,
      h2: `
        font-family: 'Roboto';
        font-style: normal;
        font-weight: 700;
        font-size: 24px;
        line-height: 32px;
        color: #25282D;
      `,
      h3: `
        font-family: 'Roboto';
        font-style: normal;
        font-weight: 400;
        font-size: 24px;
        line-height: 32px;
        color: #25282D;
      `,
      h4: `
        font-family: 'Roboto';
        font-style: normal;
        font-weight: 700;
        font-size: 18px;
        line-height: 24px;
        letter-spacing: 0.15px;
        color: #25282D;
      `,
      label: `
        font-family: 'Roboto';
        font-style: normal;
        font-weight: 400;
        font-size: 16px;
        line-height: 24px;
        letter-spacing: 0.15px;
        color: #25282D;
      `,
      body: `      
        font-family: 'Roboto';
        font-style: normal;
        font-weight: 400;
        font-size: 16px;
        line-height: 24px;
        letter-spacing: 0.15px;
        color: #66737F;
      `,
      caption: `
        font-family: 'Roboto';
        font-style: normal;
        font-weight: 400;
        font-size: 14px;
        line-height: 24px;
        letter-spacing: 0.15px;
        color: #66737F;
      `,
      title: `
          color: #25282D;
          font-size: 20px;`,
      xl: `
          margin: 8px 0;
          color: #25282D;
          font-size: 22px;`,
      lg: `
          margin: 8px 0;
          color: #25282D;
          font-size: 18px;`,
      md: `
          margin: 8px 0;
          color: #25282D;
          font-size: 16px;`,
      sm: `
          margin: 8px 0;
          color: #25282D;
          font-size: 14px;`,
      xs: `
          margin: 8px 0;
          color: #25282D;
          font-size: 12px;`,
      light: `color: #66737F;`,
      lighter: `color: #9aa7b5;`,
      bold: `font-weight: bold;`,
      center: `text-align: center;`,
      right: `text-align: right;`,
      left: `text-align: left;`,
      uppercase: `text-transform: uppercase;`,
      header: `
          color: #25282D;
          font-size: 24px;
          font-weight: bold;`,
    },
  },
  {
    elements: ["img"],
    styles: {
      "max-image-size": `
          max-width: 150px;
          max-height: 50px;`,
    },
  },
  {
    elements: ["button"],
    styles: {
      "btn-lg": `padding: 24px;`,
      "btn-md": `padding: 16px;`,
      btn: `
        background-color: #25282D;
        color: white;
        padding: 6px 16px;
        font-size: 0.875rem;
        min-width: 64px;
        box-sizing: border-box;
        transition: background-color 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms,box-shadow 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms,border 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;
        font-family: Montserrat,Roboto,sans-serif;
        font-weight: 700;
        line-height: 1.75;
        padding-top: 8px;
        border-radius: 8px;
        padding-bottom: 8px;
        text-transform: uppercase;
        border: none;
        cursor: pointer;
      `,
      text: `
        color: #25282D;
        background: none;
        padding: 6px 16px;
        font-size: 0.875rem;
        min-width: 64px;
        transition: background-color 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms,box-shadow 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms,border 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;
        font-family: Montserrat,Roboto,sans-serif;
        font-weight: 700;
        line-height: 1.75;
        padding-top: 8px;
        border-radius: 8px;
        padding-bottom: 8px;
        text-transform: uppercase;
        border: none;
        :hover { background-color: #F5F7FA; }
        cursor: pointer;
      `,
      icon: `
        border-radius: 50%;
        width: 48px;
        height: 48px;
        display: flex;
        justify-content: center;
        align-items: center;
        font-size: 32px;
        border: none;
        background: none;
        :hover { background-color: #F5F7FA; }
        cursor: pointer;
      `,
      "no-style": `
        border: none;
        background: none;
        outline: none;
        font-size: 1em;
        text-align: center;
      `,
    },
  },
]);

export const styles = (tags, cssValue = "") => {
  const tagList = tags.split(" ");
  const tagCss = tagList.reduce((p, tag) => {
    if (tag in tagMap) {
      return p + tagMap[tag];
    }
    return p;
  }, "");
  return css`
    ${tagCss} ${cssValue}
  `;
};

export const tags = (tags, cssValue = "") => {
  const className = styles(tags, cssValue);
  return { className };
};
