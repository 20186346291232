import React, { useState, useEffect, useRef } from "react";
import { useLocation, matchPath } from "react-router-dom";
import { BuilderContainer } from "./Builder";
import { CustomAxios } from "../../redux/axios/axios";
import CircularProgress from "@material-ui/core/CircularProgress";
import { css } from "emotion";
import { ReadOnlyBuilderContainer } from "./ReadOnlyBuilder";
import { useStore } from "../../store-provider/use-store";
import { useAcls } from "../../shared/use-acls";
import { WRITE_COURSE } from "../../shared/acl-constants";
import errorHandler from "../../lib/stackdriver/errorHandler";
import { toast } from "react-toastify";

function useInterval(callback, delay) {
  const savedCallback = useRef();

  // Remember the latest callback.
  useEffect(() => {
    savedCallback.current = callback;
  }, [callback]);

  // Set up the interval.
  useEffect(() => {
    function tick() {
      savedCallback.current();
    }
    if (delay !== null) {
      let id = setInterval(tick, delay);
      return () => clearInterval(id);
    }
  }, [delay]);
}

export const LeasedWrapper = (props) => {
  const location = useLocation();
  const match = matchPath(location.pathname, {
    path: "/:businessId/courses/:id/builder",
  });
  const canWriteCourse = useAcls([WRITE_COURSE], match.params.id);

  const {
    data: { selectedBusiness, parentBusiness },
  } = useStore();
  const { ID: businessId } = selectedBusiness;

  const [loading, setLoading] = useState(false);
  const [leaseExpiration, setLeaseExpiration] = useState({});
  // alreadyLeasedByUser is the email address of the user who currently has this course leased. Defaults to "Another user" if userID is not found.
  const [alreadyLeasedByUser, setAlreadyLeasedByUser] = useState("");

  const path = `/v1/lease/course/${match.params.id}`;

  const createLease = async () => {
    try {
      setLoading(true);
      const resp = await CustomAxios.put(path);
      setLeaseExpiration(new Date(resp.data.ExpiresAt));
      setAlreadyLeasedByUser("");
      setLoading(false);
    } catch (e) {
      if (e.response && e.response.data && e.response.data.UserID) {
        setAlreadyLeasedByUser(await getEmail(e.response.data.UserID));
      } else {
        console.log("Error getting lease:", e);
        errorHandler.report(
          `Unexpected error occurred when attempting to create lease: ${e}`
        );
        // if lease is expired, let's just show a warning. This could lead to conflicting changes from users but I think it's a better approach while we're figuring out what's going on
        if (leaseExpiration < new Date()) {
          toast.error(
            "Unable to get lease, another user may be editing this guide or you may not be able to save changes."
          );
        }
      }
      setLoading(false);
    }
  };

  const updateLease = async () => {
    try {
      const resp = await CustomAxios.put(path);
      setLeaseExpiration(new Date(resp.data.ExpiresAt));
      setAlreadyLeasedByUser("");
    } catch (e) {
      if (e.response && e.response.data && e.response.data.UserID) {
        setAlreadyLeasedByUser(await getEmail(e.response.data.UserID));
      } else {
        console.log("Error getting lease:", e);
        errorHandler.report(
          `Unexpected error occurred when attempting to create lease: ${e}`
        );
        // if lease is expired, let's just show a warning. This could lead to conflicting changes from users but I think it's a better approach while we're figuring out what's going on
        if (leaseExpiration < new Date()) {
          toast.error(
            "Unable to get lease, another user may be editing this guide or you may not be able to save changes."
          );
        }
      }
    }
  };

  const deleteLease = async () => {
    try {
      let result = await CustomAxios.delete(path);
    } catch (e) {
      console.log("delete lease err", e);
    }
  };

  useEffect(() => {
    createLease();
    return () => {
      if (!alreadyLeasedByUser) {
        deleteLease();
      }
    };
  }, []);

  useInterval(() => {
    updateLease();
  }, 20000);

  if (loading) {
    return (
      <div
        className={css`
          display: flex;
          justify-content: center;
          width: 100%;
          padding: 24px;
        `}
      >
        {" "}
        <CircularProgress />
      </div>
    );
  }

  const getEmail = async (userID) => {
    try {
      var result = await CustomAxios.get(`/v1/users/${userID}`);
      return result.data.Email;
    } catch (e) {
      // Default to "Another user" if unable to retrieve the user by id.
      return "Another user";
    }
  };

  const propsPlus = {
    ...props,
    businessId,
    parentBusiness: parentBusiness,
  };

  if (canWriteCourse && !alreadyLeasedByUser) {
    return <BuilderContainer {...propsPlus} />;
  } else {
    return (
      <ReadOnlyBuilderContainer
        canWriteCourse={canWriteCourse}
        alreadyLeasedByUser={alreadyLeasedByUser}
        {...propsPlus}
      />
    );
  }
};
