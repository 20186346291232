import { Input, Text } from "../../shared/components";
import { extendedStates } from "../../constants";
import { Box } from "../../shared/components/box";
import { colors } from "../../shared/styles";
import { container, flex } from "../../shared/shared-styles";
import { CaretDown, CaretUp, Check, MagnifyingGlass } from "phosphor-react";
import { Dropdown } from "../../shared/components/dropdown";
import { useMemo, useState } from "react";

export const ApplicableStates = ({ value, onUpdate, css: cssString = "" }) => {
  const [filter, setFilter] = useState("");

  const states = useMemo(() => {
    if (!filter) {
      return extendedStates;
    } else {
      return extendedStates.filter(
        (state) =>
          state.label.toLowerCase().includes(filter.toLowerCase()) ||
          state.id.toLowerCase().includes(filter.toLowerCase())
      );
    }
  }, [filter]);

  const hasAllSelected = value.includes("ALL");

  const handleApplicableStates = (e, state) => {
    e.stopPropagation();

    let list = [...value];
    if (state?.id === "ALL" && hasAllSelected) {
      list = [];
    } else if (state?.id === "ALL") {
      list = ["ALL"];
    } else if (hasAllSelected) {
      list = extendedStates
        .map(({ id }) => id)
        .filter((id) => id !== "ALL" && id !== state?.id);
    } else if (list.includes(state?.id)) {
      list = list.filter((item) => item !== state?.id);
    } else {
      list = [...list, state?.id].sort();
    }
    // Legacy products may still have this as ApplicableStates POOP
    list = list.filter((item) => item !== "{}");
    onUpdate(list);
  };

  const DropdownButton = ({ open }) => (
    <Box
      css={`
        width: 100%;
        ${flex("space-between")}
        svg {
          min-width: 24px;
          min-height: 24px;
        }
      `}
    >
      {value?.length ? (
        <Text
          ellipsis
          css={`
            flex-grow: 1;
          `}
        >
          {hasAllSelected ? "All States" : value?.join(", ")}
        </Text>
      ) : null}
      {open ? <CaretUp /> : <CaretDown />}
    </Box>
  );

  return (
    <Box
      css={`
        width: 100%;
        ${cssString}
      `}
    >
      <Dropdown
        css={`
          border: 1px solid ${colors.gray[300]};
          padding: 8px;
          user-select: none;
        `}
        button={DropdownButton}
      >
        <Box onCleanup={() => setFilter("")}>
          <Input
            onClick={(e) => e.stopPropagation()}
            value={filter}
            onChange={(e) => setFilter(e.target.value)}
            placeholder="Filter"
            startIcon={<MagnifyingGlass />}
            css={`
              margin: 8px;
            `}
          />
          {states?.map((item) => (
            <>
              <Box
                flex="left"
                css={`
                  width: calc(100% - 16px);
                  padding: 8px;
                  margin: 0 8px;
                  border-radius: 8px;
                  ${container.hover}
                `}
                onClick={(e) => handleApplicableStates(e, item)}
              >
                <Box
                  flex="center"
                  css={`
                    border: 2px solid ${colors.gray[300]};
                    width: 24px;
                    height: 24px;
                    border-radius: 8px;
                    margin-right: 16px;
                    ${hasAllSelected || value.includes(item?.id)
                      ? `
                    background-color: ${colors.black};   
                    border-color: ${colors.black};
                  `
                      : ""}
                  `}
                >
                  {hasAllSelected || value.includes(item?.id) ? (
                    <Check size={24} color="white" />
                  ) : null}
                </Box>

                <Text label>{item?.label}</Text>
              </Box>
              {item?.id === "ALL" ? (
                <Box
                  css={`
                    border-bottom: 1px solid ${colors.gray[300]};
                    width: 100%;
                    height: 2px;
                    margin: 4px 0;
                  `}
                />
              ) : null}
            </>
          ))}
        </Box>
      </Dropdown>
    </Box>
  );
};
