import { getGenericQueryProps, getUrl } from ".";

const url = "/v2/benefitspackage/:packageId/product";

export const benefitsPackageProductsList = {
  url,
  key: "benefits-package-products-list",
  getResourceOptions: (resourceProps) => {
    const resourceUrl = getUrl(url, resourceProps);
    return {
      url: resourceUrl,
      queryProps: {
        initialData: [],
        ...getGenericQueryProps(resourceUrl, resourceProps),
      },
    };
  },
  utils: {
    getPutProduct: (id, body) => ({
      url: `/v2/product/${id}`,
      body,
      optimistic: (old) =>
        old.map((item) => (item.ID === body.ID ? body : item)),
    }),
  },
};
