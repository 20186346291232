import { sortBy, groupBy } from "lodash";
import { toast } from "react-toastify";
import { CustomAxios } from "../redux/axios/axios";

export const fetchCourses = async () => {
  try {
    const response = await CustomAxios.get(`/v1/course`);
    let courses = response.data;
    courses = courses.filter((course) => course.Type !== "survey");
    courses = courses.filter((course) => course.Type !== "vendor");
    // courses = courses.filter((course) => course.);
    courses = sortBy(courses, (o) => o.Name.toLowerCase());
    return courses;
  } catch (error) {
    toast.error("Courses failed to load");
    return [];
  }
};

export const fetchTextingHistory = async () => {
  try {
    let courses = await fetchCourses();
    CustomAxios.setHeader("Accept-Profile", "brite");
    const response = await CustomAxios.get(
      `/v1/bp/twilio_message?order=date_created.desc` // order this way so that the most recent send is used as the date.
    );
    let textingHis = response.data;
    var groups = groupBy(textingHis, function (text) {
      return text.course_id;
    });

    const keys = Object.keys(groups);
    for (const key of keys) {
      for (let course of courses) {
        if (course.ID === key) {
          groups[key].course = course;
        }
      }
      let delivered = 0;

      let sending = 0;
      for (let textMessage of groups[key]) {
        if (textMessage.status === "delivered") {
          delivered = delivered + 1;
        } else {
          sending = sending + 1;
        }
      }
      groups[key].deliveredCount = delivered;
      groups[key].sendingCount = sending;
      if (delivered > 0) {
        groups[key].percentage = (delivered * 100) / groups[key].length;
      } else {
        groups[key].percentage = 0;
      }
    }

    return groups;
  } catch (error) {
    toast.error("Texting history failed to load");
    return [];
  }
};
