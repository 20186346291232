import { useEffect, useRef, useState } from "react";
import { css } from "emotion";
import {
  Button,
  Div,
  Modal,
  Select,
  Slider,
  Text,
} from "../../../shared/components";
import { colors } from "../../../shared/styles";
import ReactCrop from "react-image-crop";
import "react-image-crop/dist/ReactCrop.css";
import { MenuItem, Radio, Tooltip } from "@material-ui/core";
import { useStateSync } from "../../../shared/use-state-sync";
import { flex, px } from "../../../shared/shared-styles";
import { useEvent } from "../../../shared/use-event";

const aspectRatios = {
  none: { label: "None", value: undefined },
  square: { label: "Square 1:1", value: 1 },
  normal: { label: "Normal 4:3", value: 4 / 3 },
  wide: { label: "Wide 16:9", value: 16 / 9 },
  cinema: { label: "Cinema 21:9", value: 21 / 9 },
};

export const ImageCropper = ({
  src,
  currentCrop,
  removeEdits,
  onUpdate,
  ...modalProps
}) => {
  const {
    circularCrop: currentCircularCrop = false,
    scale: currentScale = 1,
    rotate: currentRotate = 0,
    aspectRatioValue: currentAspectRatio = "square",
    ...rest
  } = currentCrop || {};

  const ref = useRef();
  const currentRest = Object.values(rest || {});
  const [crop, setCrop, isCropSynced] = useStateSync(
    () => (currentRest?.length ? rest : undefined),
    [currentRest.toString(), modalProps?.display]
  );
  const [circularCrop, setCircularCrop, isCircularCropSynced] = useStateSync(
    currentCircularCrop,
    [currentCircularCrop, modalProps?.display]
  );
  const [rotate, setRotate, isRotateSynced] = useStateSync(currentRotate, [
    currentRotate,
    modalProps?.display,
  ]);
  const [scale, setScale, isScaleSynced] = useStateSync(currentScale, [
    currentScale,
    modalProps?.display,
  ]);
  const [aspectRatioValue, setAspectRatioValue] = useStateSync(
    currentAspectRatio,
    [currentAspectRatio, modalProps?.display]
  );
  const [aspect, setAspect] = useStateSync(
    () =>
      circularCrop ? 1 : aspectRatios?.[aspectRatioValue]?.value || undefined,
    [aspectRatioValue, modalProps?.display]
  );

  const areEditsSynced =
    isCropSynced && isCircularCropSynced && isRotateSynced && isScaleSynced;

  const saveCrop = () => {
    if (!crop) {
      removeEdits();
    } else {
      onUpdate({
        crop: {
          ...crop,
          scale,
          rotate,
          circularCrop,
          aspectRatioValue,
        },
      });
    }
    modalProps.onClose();
  };

  const updateCircularToggle = (value) => {
    setCircularCrop(value);
    setAspectRatioValue("square");
    setCrop(undefined);
  };

  const resetEdits = () => {
    setCrop(undefined);
    setAspectRatioValue("none");
    setCircularCrop(false);
    setRotate(0);
    setScale(1);
  };

  return (
    <Modal {...modalProps} full>
      <Div
        css={css`
          width: 100vw;
        `}
      >
        <Div
          css={css`
            border-bottom: 1px solid ${colors.gray[300]};
          `}
        >
          <Div
            css={css`
              ${flex("space-between")} padding: ${px.xl};
              height: 100px;
              z-index: 1000;
              background-color: white;
            `}
          >
            <Text styles="h2">Edit Image</Text>

            <Div
              css={css`
                ${flex("aic")}
              `}
            >
              <Button
                styles="secondary"
                className={css`
                  width: calc(100% - 32px);
                `}
                onClick={resetEdits}
                disabled={!crop}
              >
                Remove Edits
              </Button>
              <Button styles="secondary mh" onClick={modalProps.onClose}>
                Cancel
              </Button>
              <Button disabled={areEditsSynced} onClick={saveCrop}>
                Save
              </Button>
            </Div>
          </Div>
          <Div
            css={css`
              padding: 16px 32px;
              position: relative;
              height: 80px;
              ${flex("left")} background-color: white;
            `}
          >
            <Div
              css={css`
                ${flex("left")} background-color: ${colors.gray[100]};
                border-radius: 8px;
                margin-right: 32px;
              `}
            >
              <Button
                tab-active={!circularCrop}
                tab-inactive={!!circularCrop}
                onClick={() => updateCircularToggle(false)}
              >
                Rectangle
              </Button>
              <Button
                tab-active={!!circularCrop}
                tab-inactive={!circularCrop}
                onClick={() => updateCircularToggle(true)}
              >
                Circle
              </Button>
            </Div>

            <Tooltip
              title={!crop ? "Add a selection to the image to use zoom" : ""}
            >
              <Div
                css={css`
                  padding: ${px.md} 0;
                `}
              >
                <Text styles="label">Zoom</Text>
                <Slider
                  min={0}
                  max={1}
                  step={0.01}
                  size="8px"
                  value={scale}
                  onChange={(e, value) => setScale(value)}
                  style={{ width: "200px" }}
                  valueLabelDisplay="auto"
                  disabled={!crop}
                />
              </Div>
            </Tooltip>

            <Tooltip
              title={!crop ? "Add a selection to the image to use rotate" : ""}
            >
              <Div
                css={css`
                  padding: ${px.md} 0;
                `}
              >
                <Text styles="label">Rotate</Text>
                <Slider
                  min={-180}
                  max={180}
                  step={1}
                  size="8px"
                  value={rotate}
                  onChange={(e, value) => setRotate(value)}
                  style={{ width: "200px" }}
                  valueLabelDisplay="auto"
                  disabled={!crop}
                />
              </Div>
            </Tooltip>

            <Div
              css={css`
                ${flex("left")} margin: 0 32px;
              `}
            >
              <Text
                css={`
                  margin-right: 16px;
                `}
              >
                Aspect Ratio
              </Text>
              <Select
                value={aspectRatioValue}
                onChange={(e) => {
                  setAspectRatioValue(e.target.value);
                  setCrop(undefined);
                }}
                css={`
                  width: 200px;
                `}
                disabled={circularCrop}
              >
                {Object.entries(aspectRatios).map(([key, { label }]) => (
                  <MenuItem key={key} value={key}>
                    {label}
                  </MenuItem>
                ))}
              </Select>
            </Div>
          </Div>
        </Div>

        <Div>
          <Div
            css={css`
              ${flex("center")} width: 100vw;
              height: calc(100vh - 200px);
              overflow: visible;
              .ReactCrop__child-wrapper {
                max-width: calc(100vw);
                max-height: calc(100vh - 250px);
              }
            `}
          >
            <ReactCrop
              style={{
                overflow: "hidden",
                backgroundColor: colors.gray[300],
              }}
              crop={crop}
              onChange={(c, cp) => setCrop(cp)}
              onDragEnd={() => {
                if (crop.height === 0 && crop.width === 0) {
                  resetEdits();
                }
              }}
              circularCrop={circularCrop}
              aspect={aspect}
              ruleOfThirds
            >
              <img
                src={src}
                ref={ref}
                style={{
                  transform: `scale(${scale}) rotate(${rotate}deg)`,
                  width: "100%",
                  height: "100%",
                  objectFit: "contain",
                }}
              />
            </ReactCrop>
          </Div>
        </Div>
      </Div>
    </Modal>
  );
};
