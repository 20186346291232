import { css } from "emotion";
import { history } from "../../history";
import { useQueryAPI } from "../../react-query";
import { Button, Div, Modal, Text } from "../../shared/components";
import { flex } from "../../shared/shared-styles";
import { colors } from "../../shared/styles";
import { useSearchParams } from "../../shared/use-search-params";
import { mediaModifiers } from "./media-utils";
import HandsClapping from "./hands-clapping.png";

export const AutoPasteComplete = ({ media, state }) => {
  const { removeParam } = useSearchParams();

  const { data } = useQueryAPI({
    url: `v1/benefitspackage/${state.packageId}/product`,
    defaultValue: [],
  });

  const currentProductIndex = data?.findIndex(
    ({ ID }) => ID === state?.product?.ID
  );
  const nextIndex =
    currentProductIndex === data?.length - 1
      ? 0
      : Math.max(0, Math.min(data?.length - 1, currentProductIndex + 1));
  const nextProduct = data?.[nextIndex];

  const handleNext = () => {
    history.push(
      `/${state.businessId}/packages/${state?.packageId}/${nextProduct?.Type}/${nextProduct?.ID}?mediaId=${media?.state?.mediaId}`
    );
    mediaModifiers.resetProduct(media);
  };
  const allDone = () => removeParam("mediaId");

  return (
    <Modal
      display={media?.state?.isComplete}
      onClose={() => mediaModifiers.setComplete(media, false)}
    >
      <Div
        css={css`
          width: 700px;
          padding: 32px;
          ${flex("center column")}
          img {
            padding: 32px 0;
          }
        `}
      >
        <Text h2>Way to go!</Text>
        <img src={HandsClapping} />
        <Text
          h4
          css={`
            text-align: center;
          `}
        >
          <Text
            h4
            as="span"
            css={css`
              min-width: max-content;
            `}
          >
            Looks like we got all the details for{" "}
          </Text>
          <Text
            as="span"
            h4
            css={`
              font-weight: bold;
              margin: 16px 0;
              color: ${colors.purple};
            `}
          >
            {state?.product?.ProductName}
          </Text>
        </Text>
        <Text
          css={`
            margin: 16px 0;
            margin-top: 32px;
          `}
        >
          Would you like to keep adding details to your other benefits?
        </Text>
        <Div
          css={css`
            ${flex("space-around")}
            width: 100%;
            margin-top: 32px;
          `}
        >
          <Button
            css={`
              width: 50%;
              margin-right: 8px;
            `}
            onClick={handleNext}
            secondary
          >
            Keep Going
          </Button>
          <Button
            css={`
              width: 50%;
              margin-left: 8px;
            `}
            onClick={allDone}
          >
            Done For Now
          </Button>
        </Div>
      </Div>
    </Modal>
  );
};
