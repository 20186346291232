import React from "react";

import Menu from "@material-ui/core/Menu";
import MenuItem from "@material-ui/core/MenuItem";
import Tooltip from "@material-ui/core/Tooltip";

import { DotsThree } from "phosphor-react";
import { Button } from "../shared/components";

export function FolderOptions(props) {
  const [anchorEl, setAnchorEl] = React.useState(null);

  const handleClick = (event) => {
    event.stopPropagation();
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const showDelete = () => {
    if (props.showDelete) {
      if (props.isVendor && props.isLocked) {
        return false;
      } else {
        return true;
      }
    } else {
      return false;
    }
  };

  return (
    <React.Fragment>
      <Tooltip title="Folder Actions" placement="top">
        <Button
          hoverLabel="Folder Actions"
          onClick={handleClick}
          styles="icon sm"
        >
          <DotsThree />
        </Button>
      </Tooltip>
      <Menu
        id="more-menu"
        anchorEl={anchorEl}
        keepMounted
        open={Boolean(anchorEl)}
        onClose={handleClose}
        onClick={(e) => e.stopPropagation()}
      >
        <MenuItem
          onClick={(e) => {
            props.rename(e);
            handleClose();
          }}
        >
          Rename
        </MenuItem>

        <MenuItem
          onClick={(e) => {
            props.remove(e);
            handleClose();
          }}
        >
          Remove
        </MenuItem>
      </Menu>
    </React.Fragment>
  );
}
