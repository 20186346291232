import React, { useState, useMemo } from "react";
import { Button, Div, Text } from "../shared/components";
import { ProductSection } from "./product-section";
import { css } from "emotion";
import { CustomDetailSectionWrapper } from "./field-components/custom-detail-section-wrapper";
import { flex, px } from "../shared/shared-styles";
import { OptionalDetails } from "./field-components/optional-details";
import { MultiNetwork } from "./multi-network";

import { IncludedPrograms } from "./included-programs";
import { SpendingAccounts } from "./spending-accounts";
import { ProductDevSettings } from "./product-dev-settings";
import {
  getProductNeedsAttention,
  productTypeConfigs,
} from "./configs/form.config";
import { useStore } from "../store-provider/use-store";
import { Cpu } from "phosphor-react";
import { productUtils } from "./configs/product-utils";
import { ConditionPercentages } from "./condition-percentages";

export const ProductForm = ({ state, openSections = false }) => {
  const {
    data: { devMode },
  } = useStore();
  const { product, layout, network } = state;

  const sectionTypes = useMemo(() => {
    return layout?.Layout?.Sections?.reduce(
      (prev, item) => {
        if (item?.Type) {
          return { ...prev, [item?.Type]: true };
        }
        return prev;
      },
      {
        disclaimers: false,
        "custom-question": false,
        "custom-details": false,
      }
    );
  });

  const [modal, setModal] = useState("");
  const needsAttention = getProductNeedsAttention(product);

  const productConfig = productTypeConfigs?.[product?.Type]?.() || {};

  const sections = useMemo(() => {
    const sections = layout?.Layout?.Sections || [];
    if (product?.Type === "insurance_plan") {
      let next = sections.slice(1);

      if (next.length >= 3) {
        //simply re-order the sections.
        [next[1], next[2]] = [next[2], next[1]];
        // NOTE: THIS IS NOT WHERE WE SHOULD RE-ORDER SECTIONS
        // TODO: Reorder in the product-layout
      }

      if (network?.category !== "core") {
        next = next.filter(
          ({ DisplayValue }) => DisplayValue !== "Plan Details"
        );
      }

      return next;
    }
    return sections;
  });

  return (
    <>
      <Div
        css={css`
          width: 100%;
          min-width: 600px;
          max-width: 900px;
          padding-top: ${px.xl};
          padding-bottom: 200px;
          margin: 0 ${px.xl};
        `}
      >
        {devMode ? (
          <Div
            css={css`
              ${flex("left")}
              button {
                margin-right: 16px;
              }
            `}
          >
            <Button styles="icon" onClick={() => setModal("dev-settings")}>
              <Cpu />
            </Button>
            <Text h4>Dev Settings</Text>
          </Div>
        ) : null}
        {product?.Type === "insurance_plan" &&
          !!layout?.Layout?.Sections?.length && (
            <ProductSection
              index={0}
              needsAttention={needsAttention}
              section={layout?.Layout?.Sections?.[0]}
              // Buyups & Narrow networks for the "Medical Details" section
              // share the same state as the core so we have this special
              // util here to point all state in this section to
              // the CORE product:
              state={productUtils.pointStateToCore(state)}
              // *************************************
              openSections={openSections}
            />
          )}

        <MultiNetwork state={state} />

        {!false && // TODO: product
          sections?.map((section, idx) => {
            if (section?.isSectionDisabled) {
              return null;
            }
            const index = idx + (product?.Type === "insurance_plan" ? 1 : 0);
            return (
              <ProductSection
                key={idx}
                index={index}
                state={state}
                section={section}
                needsAttention={needsAttention}
                openSections={openSections}
              />
            );
          })}

        {product?.Type === "critical_illness" && (
          <ConditionPercentages state={state} />
        )}

        {product?.Type === "insurance_plan" && network.category === "core" && (
          <SpendingAccounts state={state} />
        )}

        {product?.Type === "insurance_plan" && network.category === "core" && (
          <IncludedPrograms state={state} />
        )}

        {!sectionTypes?.["custom-details"] ? (
          <CustomDetailSectionWrapper state={state} />
        ) : null}

        {!productConfig?.hideOptionalDetails &&
        !sectionTypes?.["disclaimers"] ? (
          <OptionalDetails state={state} />
        ) : null}
      </Div>

      <ProductDevSettings
        display={modal === "dev-settings" && devMode}
        onClose={() => setModal("")}
        state={state}
      />
    </>
  );
};
