import { combineReducers } from "redux";
import { authReducer } from "./actions/auth";
import { coursesReducer } from "./actions/courses";
import { businessReducer } from "./actions/business";
import { analyticsReducer } from "./actions/analytics";

export const rootReducer = combineReducers({
  auth: authReducer,
  courses: coursesReducer,
  business: businessReducer,
  analytics: analyticsReducer,
});
