import React from 'react';
import { isToday } from 'date-fns';
import { css, cx } from 'emotion';

export const NewTag = ({ className, createdAt }) => (
  !createdAt || isToday(new Date(createdAt)) ? (
    <span
      className={cx(css`
        background-color: #25282D;
        color: white;
        font-weight: bold;
        border-radius: 16px;
        padding: 2px 8px;
        font-size: .7em;
        margin-right: 8px;
      `, className)}
    >
      New
    </span>
  ) : null
)