import { InputAdornment, TextField, Tooltip } from "@material-ui/core";
import { css } from "emotion";
import { useEffect } from "react";
import { Div } from "../../shared/components";
import { flex } from "../../shared/shared-styles";
import { useDebounceValue } from "../../shared/use-debounce-value";
import { useStateSync } from "../../shared/use-state-sync";
import { modifyContent } from "../provider/utils";
import { SmartFieldInput } from "../utility-components/smart-field-input";
import { formatUrl } from "./text-toolbar";

export const WebsiteToolbar = ({ editor, element, location }) => {
  const onUpdate = (updates) => modifyContent.merge(editor, location, updates);
  const [linkText, setLinkText, isLinkTextSynced] = useStateSync(
    element?.linkText,
    [element?.linkText]
  );
  const [debounce_value] = useDebounceValue(linkText, 500);

  const height = (element?.attributes?.height || "").replace(/\D/g, "") || 0;

  const handleSmartField = ({ data }) => {
    onUpdate({
      attributes: { src: data?.value },
      linkText: data?.name,
    });
  };

  const removeSmartField = () => {
    onUpdate({
      attributes: { src: "" },
      linkText: "",
    });
  };

  const handleLinkText = (e) => {
    const [src] = formatUrl(linkText);
    onUpdate({ attributes: { src }, linkText });
  };

  useEffect(() => {
    if (!isLinkTextSynced) {
      handleLinkText();
    }
  }, [debounce_value]);

  return (
    <Div
      css={css`
        ${flex("left")}
      `}
    >
      <Tooltip title="Website URL" placement="top">
        <SmartFieldInput
          css={`
            min-width: 300px;
            height: 40px;
            margin-right: 16px;
          `}
          nameFilter="URL"
          value={element?.attributes?.src}
          smartFieldDisplay={linkText || ""}
          handleSmartField={handleSmartField}
          removeSmartField={removeSmartField}
          inputProps={{
            value: linkText,
            placeholder: "Website URL",
            onChange: (e) => setLinkText(e.target.value),
          }}
        />
      </Tooltip>

      <Tooltip title="Height" placement="top">
        <TextField
          type="number"
          InputProps={{ endAdornment: <InputAdornment>px</InputAdornment> }}
          style={{
            width: "150px",
            height: "40px",
            padding: 0,
            margin: 0,
            backgroundColor: "white",
          }}
          inputProps={{ style: { padding: "11px", margin: 0 } }}
          variant="outlined"
          value={height}
          onChange={(e) =>
            onUpdate({
              attributes: { height: `${Number(e.target.value)}px` },
            })
          }
        />
      </Tooltip>
    </Div>
  );
};
