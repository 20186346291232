import { format } from "date-fns";
import { cloneDeep, get, merge, set } from "lodash";
import { productModifier, productUtils } from "../configs/product-utils";
import {
  multiply,
  divide,
  bignumber,
  subtract,
  format as mathJSFormat,
} from "mathjs";

export const formatCurrency = (value, decimals = 2) => {
  try {
    const formatValue = value.toLocaleString("en-US", {
      style: "decimal",
      maximumFractionDigits: 12,
      minimumFractionDigits: decimals,
    });
    return formatValue === "NaN" ? "0" : formatValue;
  } catch {
    return "0";
  }
};

const getDisplayNumberValue = (value, options = {}) => {
  const { decimals } = options;
  let next;
  try {
    next = typeof value === "string" ? value.replace(/[^0-9.]/g, "") : value;
  } catch {
    next = decimals ? "0.00" : "0";
  }
  return formatCurrency(parseFloat(next || "0"), decimals);
};

export const formatUrl = (text) => {
  let url = text;
  if (!/^https?:\/\//i.test(url) && !/^http?:\/\//i.test(url)) {
    url = "https://" + url;
  }
  try {
    const data = new URL(url);
    return [data.href, true];
  } catch (err) {
    return ["", false];
  }
};

export const displayFormatMap = {
  date: (value) => {
    try {
      const [day] = value.split("T");
      const date = day.replace(/-/g, "/");
      const nextDate = new Date(date);
      return format(nextDate, "yyyy-MM-dd");
    } catch (err) {
      console.warn(err);
      return "";
    }
  },
  link: (value) => {
    const [link, isValid] = formatUrl(value);
    return isValid ? link : value;
  },
  dollar: getDisplayNumberValue,
  percent: getDisplayNumberValue,
  advancedInputField: getDisplayNumberValue,
  surestInputField: getDisplayNumberValue,
};

export const saveFormatMap = {
  date: (value) => {
    try {
      return new Date(value).toISOString();
    } catch (err) {
      console.warn(err);
      return value;
    }
  },
  dollar: (value, options = {}) => {
    const { isNumber } = options;
    let next = value;
    if (isNumber && typeof value === "string") {
      next = value.replace(/[^0-9.]/g, "");
    }
    return isNumber ? parseFloat(next) : next;
  },
  percent: (value, options = {}) => {
    const { isNumber } = options;
    let next = value;
    if (isNumber && typeof value === "string") {
      next = value.replace(/[^0-9.]/g, "");
    }
    return isNumber ? parseFloat(next) : next;
  },
  link: (value) => {
    const [link, isValid] = formatUrl(value);
    return isValid ? link : value;
  },
};

export const currencyFormatterCents = new Intl.NumberFormat("en-US", {
  style: "currency",
  currency: "USD",
  maximumFractionDigits: 2,
});

export const getProductPrice = ({
  monthlyPremium = null,
  contribution,
  contributionType,
  interval,
}) => {
  const premium = bignumber(Number(monthlyPremium || 0));
  let netCost = multiply(premium, bignumber(12));

  const formatType = contributionType || "$";
  let employerContribution = bignumber(Number(contribution) || 0);

  if (formatType === "%") {
    employerContribution = divide(employerContribution, bignumber(100));
    employerContribution = multiply(employerContribution, netCost);
  } else if (formatType === "$") {
    employerContribution = multiply(employerContribution, bignumber(12));
  }

  netCost = subtract(netCost, employerContribution);
  let intervalCost = divide(netCost, bignumber(interval));
  return currencyFormatterCents.format(
    mathJSFormat(intervalCost, { precision: 13 })
  );
};

export const getProductPremium = ({ cost, key, interval = 12 }) => {
  const monthlyPremium = cost?.TotalMonthlyPremiums?.[key];
  const contribution = cost?.Contributions?.MonthlyContributions?.[key];
  const contributionType = cost?.Contributions?.ContributionType;
  return getProductPrice({
    monthlyPremium,
    contribution,
    contributionType,
    interval,
  });
};

export const getFieldValue = (product, field, defaultValue = "") => {
  let value = null;
  if (!field) {
    return defaultValue;
  }

  if (field.Type === "surestInputField") {
    value = {
      lower: get(product, `${field.PropertyChain}Lower`, defaultValue),
      upper: get(product, `${field.PropertyChain}Upper`, defaultValue),
    };
  } else {
    value = get(product, field.PropertyChain, defaultValue);
  }

  if (!value) {
    value = defaultValue;
  }

  if (field.Type === "advancedInputField") {
    if (typeof value !== "string") {
      if (!value) {
        value = {};
      }
      if (!value.Value) {
        value.Value = 0;
      }
      if (!value.PercentOrDollar) {
        value.PercentOrDollar = "%";
      }
      if (!value.ADOrCopay) {
        value.ADOrCopay = "AD";
      }
    }
  }

  if (field.PropertyChain === "Details.InNetworkPlanDesign.Coinsurance") {
    value *= 100;
  }
  return value;
};

export const getDisplayValue = (value, field, typeOverride = null) => {
  let nextValue =
    field.Type === "percent" || field.Type === "dollar" ? value || 0 : value;
  const type = typeOverride === null ? field.Type : typeOverride;
  if (type in displayFormatMap) {
    nextValue = displayFormatMap[type](value, { decimals: 0 });
  }
  if (type === "dollar" || type === "surestInputField") {
    nextValue = "$" + nextValue;
  } else if (type === "percent" || type === "percentOfEmployeeElectedAmount") {
    nextValue += "%";
  }
  return nextValue;
};

export const getFieldProps = (state, field, config) => {
  const { layout, product, network, fieldsObject } = state;
  const { sectionIdx = null, fieldIdx = null } =
    fieldsObject[field?.PropertyChain] || {};

  const hasIndices = sectionIdx !== null && fieldIdx !== null;

  const nextConfig = hasIndices ? config : { ...config, hideEditLayout: true };
  const value = getFieldValue(product, field);
  return {
    field,

    config: nextConfig,

    value,

    updateLayout: (updates) => {
      if (hasIndices) {
        let next = cloneDeep(layout?.Layout);
        const path = `Sections[${sectionIdx}].Fields[${fieldIdx}]`;
        const item = get(layout?.Layout, path);
        const value = merge({}, item, updates);
        next = set(next, path, value);
        productModifier.setLayout(state, {
          ...layout,
          Layout: next,
        });
      }
    },

    updateProperty: (property, update, options = {}) => {
      if (property === "Details.InNetworkPlanDesign.Coinsurance") {
        const numberValue = parseFloat(update) || 0;
        update = numberValue / 100;
      }
      productUtils.formTrigger(state, update, {
        field: { ...field, PropertyChain: property },
        config,
        oldValue: value,
      });
    },
  };
};
