import React, { useEffect, useMemo, useState } from "react";
import { css } from "emotion";
import { colors } from "../../shared/styles";
import { useForm } from "../../shared/use-form";
import {
  Button,
  Div,
  Input,
  Modal,
  Select,
  Text,
} from "../../shared/components";
import { container, flex } from "../../shared/shared-styles";
import { states } from "../../constants";
import { textTheme } from "../../shared/components/text";
import { CustomAxios } from "../../redux/axios/axios";
import {
  eachQuarterOfInterval,
  format,
  getMonth,
  getQuarter,
  getYear,
} from "date-fns";
import { toast } from "react-toastify";
import { BriteLoader } from "../../shared/components/brite-loader";
import { useStateSync } from "../../shared/use-state-sync";
import { CheckCircle, MagnifyingGlass, X } from "phosphor-react";

const zywaveInfoBaseURL = "https://www.zywave.com/small-group-rates/";

export const ImportAcaPlan = ({
  selectedPlans,
  addSelectedPlan,
  removePlanFromSelected,
}) => {
  const { quarters, startIdx } = useMemo(() => {
    const currYear = getYear(new Date());
    const currMonth = getMonth(new Date());
    let startMonth = 1;
    let endMonth = 11;
    let startYear = currYear;
    let endYear = currYear;
    let startIdx = 2;
    if (currMonth <= 6) {
      startYear = currYear - 1;
      startMonth = 7;
    } else {
      startIdx = 0;
      endYear = currYear + 1;
      endMonth = 5;
    }
    const quarterIntervals = eachQuarterOfInterval({
      start: new Date(startYear, startMonth, 0),
      end: new Date(endYear, endMonth, 15),
    });
    return { quarters: quarterIntervals, startIdx };
  }, []);

  const [modal, setModal] = useState("");

  const { getProperty, setValues, values } = useForm({
    zipCode: "",
    state: "",
    quarter: quarters[startIdx],
    search: "",
  });

  const [plans, setPlans] = useStateSync(null, [
    values?.zipCode,
    values?.state,
    values?.quarter,
  ]);

  const handleSearch = async () => {
    try {
      setValues({ ...values, isLoading: true });
      const effectiveDate = format(values?.quarter, "yyyy-MM-dd");
      let loadedPlans = [];
      let skip = 0;
      // TODO: we should lazy load this so we can have infinite scroll--right now we're tapped out at 2000 plans
      for (let i = 0; i < 20; i++) {
        let response = await CustomAxios.get(
          `v1/zywave/plans?zipCode=${values?.zipCode}&state=${values?.state}&effectiveDate=${effectiveDate}&limit=100&skip=${skip}`
        );
        const newPlans = response.data.Data || [];
        loadedPlans = loadedPlans.concat(newPlans);
        if (!response.data.Next) {
          break;
        }
        skip += 100;
      }
      setPlans(loadedPlans);
    } catch (err) {
      console.warn(err);
      toast?.error(err);
    } finally {
      setValues({ ...values, isLoading: false });
    }
  };

  const lookupState = async () => {
    try {
      const resp = await CustomAxios.get(
        `/public/v1/zywave/areas?zipCode=${values?.zipCode}`
      );
      if (resp.data.Data.length > 0) {
        const stateAbbreviation = resp.data.Data[0].SubdivisionCode;
        const state = states.find((s) => s.id === stateAbbreviation);
        if (state.id) {
          setValues({ ...values, state: state.id });
        }
      }
    } catch (error) {
      console.log(
        "error getting state for zip code, will continue without it:",
        error
      );
    }
  };

  useEffect(() => {
    if (values?.zipCode && values?.zipCode?.length > 4) {
      lookupState();
    }
  }, [values?.zipCode]);

  const separatePlans = (plans) => {
    return plans?.reduce((prev, plan) => {
      const property =
        !plan.AgeBandedPremium && !plan.CompositePremium
          ? "disabledPlans"
          : "importablePlans";
      return {
        ...prev,
        [property]: [...(prev?.[property] || []), plan],
      };
    }, {});
  };

  const { importablePlans, disabledPlans } = useMemo(() => {
    let list = [...(plans || [])];
    if (!!values?.search) {
      const searchValue = values?.search?.toLowerCase();
      list = plans?.filter(
        (plan) =>
          plan?.PlanName?.toLowerCase()?.includes(searchValue) ||
          plan?.CarrierName?.toLowerCase()?.includes(searchValue)
      );
    }
    return separatePlans(list);
  }, [values?.search, plans?.length]);

  const selectedPlanIds = useMemo(() => {
    return selectedPlans?.map(({ ID }) => ID);
  }, [selectedPlans?.length]);

  const PlanListItem = ({ plan, disabled = false }) => {
    return (
      <Div
        key={plan?.ID}
        css={css`
          ${flex("space-between")}
          ${disabled ? "opacity: .5;" : container?.hover}
                      border-radius: 8px;
          border: 1px solid ${colors.gray[300]};
          padding: 16px 24px;
          margin-bottom: 16px;
          ${selectedPlanIds?.includes(plan?.ID)
            ? `
                        background-color: ${colors.gray[100]};
                        outline: 2px solid ${colors.black};
                      `
            : ``}
        `}
        onClick={
          disabled
            ? null
            : () =>
                selectedPlanIds?.includes(plan?.ID)
                  ? removePlanFromSelected(plan)
                  : addSelectedPlan(plan)
        }
      >
        <Div
          css={css`
            flex-grow: 1;
          `}
        >
          <Text label>{plan?.PlanName}</Text>
          <Text
            css={`
              font-size: 0.9em;
            `}
          >
            {plan?.CarrierName
              ? `${plan.CarrierName} - ${plan?.InsuranceType}`
              : ""}
          </Text>
        </Div>
        {selectedPlanIds?.includes(plan?.ID) ? (
          <CheckCircle size={24} weight="fill" />
        ) : null}
      </Div>
    );
  };

  const moreInfoUrl = `${zywaveInfoBaseURL}?q=${getYear(
    values?.quarter
  )}_${getQuarter(values?.quarter)}`;

  return (
    <Div
      css={css`
        width: 840px;
        margin: 32px 0;
        > div {
          flex-grow: 1;
        }
      `}
    >
      <Text h2>Find ACA Plans</Text>
      <Div
        css={css`
          ${flex("space-between end")}
          margin-top: 24px;
          padding: 24px 0;
          border-bottom: 1px solid ${colors.gray[300]};
        `}
      >
        <Div>
          <Text label bold>
            Zip Code
          </Text>
          <Input
            {...getProperty("zipCode")}
            css={`
              min-width: 200px;
            `}
          />
        </Div>

        <Div>
          <Text label bold>
            State
          </Text>
          <Select
            css={`
              min-width: 200px;
            `}
            {...getProperty("state")}
          >
            {states?.map((item) => (
              <option
                value={item?.id}
                className={css`
                  ${textTheme?.label}
                  ${container.hover}
                  padding: 8px;
                `}
              >
                {item?.label}
              </option>
            ))}
          </Select>
        </Div>

        <Div>
          <Text label bold>
            Effective Quarter
          </Text>
          <Select
            css={`
              min-width: 200px;
            `}
            {...getProperty("quarter")}
          >
            {quarters?.map((item) => (
              <option
                value={item}
                className={css`
                  ${textTheme?.label}
                  ${container.hover}
                padding: 8px;
                `}
              >
                {format(item, "yyyy") + " Q" + format(item, "Q")}
              </option>
            ))}
          </Select>
        </Div>
        <Div>
          <Button
            css={`
              min-width: 200px;
              margin-bottom: 2px;
            `}
            disabled={!values?.zipCode && !values?.state && !values?.quarter}
            onClick={handleSearch}
          >
            Search
          </Button>
        </Div>
      </Div>
      <Div
        css={css`
          margin: 32px 0;
        `}
      >
        {values?.isLoading ? (
          <BriteLoader
            overlay={false}
            css={`
              ${flex("center")}
            `}
          />
        ) : plans === null ? (
          <Text center> No results yet.</Text>
        ) : plans?.length ? (
          <Div>
            <Input
              styles="search"
              startIcon={<MagnifyingGlass />}
              placeholder="Search carrier or plan name"
              {...getProperty("search")}
            />
            <Div
              css={css`
                ${flex("space-between")}
              `}
            >
              <Text
                h2
                css={`
                  margin: 32px 0;
                `}
              >
                {plans?.length} plan{plans?.length === 1 ? "" : "s"} found
              </Text>
              <Text
                onClick={() => setModal("more-info")}
                css={`
                  cursor: pointer;
                `}
              >
                Don't see your plan?
              </Text>
            </Div>
            <Div>
              {!importablePlans?.length &&
              !disabledPlans?.length &&
              plans?.length ? (
                <Text>
                  Couldn't find any plans with your search '{values?.search}'
                </Text>
              ) : (
                <>
                  {importablePlans?.map((plan) => (
                    <PlanListItem plan={plan} />
                  ))}
                  {disabledPlans?.length ? (
                    <Text label bold>
                      Premium not available - Can't import
                    </Text>
                  ) : null}
                  {disabledPlans?.map((plan) => (
                    <PlanListItem plan={plan} disabled={true} />
                  ))}
                </>
              )}
            </Div>
          </Div>
        ) : (
          <Text center> Couldn't find any plans.</Text>
        )}
      </Div>
      <Modal display={modal === "more-info"} onClose={() => setModal("")}>
        <Div
          css={css`
            width: 500px;
          `}
        >
          <Div
            css={css`
              ${flex("space-between")} padding: 16px;
            `}
          >
            <Text h2>Don't See Your Plan</Text>
            <Button styles="icon" onClick={() => setModal("")}>
              <X />
            </Button>
          </Div>
          <Text
            css={`
              padding: 16px;
            `}
          >
            The plan you are looking for might not be in the tool. You can learn
            more by at Zywave.
          </Text>
          <Div
            css={css`
              padding: 16px;
              ${flex("right")}
            `}
          >
            <Button
              secondary
              css={`
                margin-right: 16px;
              `}
              onClick={() => setModal("")}
            >
              Cancel
            </Button>
            <Button onClick={() => window.open(moreInfoUrl, "_blank")}>
              Learn More
            </Button>
          </Div>
        </Div>
      </Modal>
    </Div>
  );
};
