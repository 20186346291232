import { getGenericQueryProps, getUrl } from ".";

const url = "/v1/notifications/:objectType/:objectId/subscriptions";

export const knockSubscriptionByTypeList = {
  url,
  key: "knock-subscription-by-type-list",
  getResourceOptions: (resourceProps) => {
    const resourceUrl = getUrl(url, resourceProps);
    return {
      url: resourceUrl,
      queryProps: {
        initialData: [],
        ...getGenericQueryProps(resourceUrl, resourceProps),
      },
    };
  },
  utils: {
    getDelete: (userId, params) => ({
      url: `/v1/notifications/${params?.objectType}/${params?.objectId}/subscriptions/${userId}`,
      optimistic: (old) => {
        const nextData = old?.Data?.filter((item) => item?.ID !== userId);
        return { ...old, Data: nextData };
      },
      refetch: false,
    }),
    getPost: (userId, params) => ({
      url: `/v1/notifications/${params?.objectType}/${params?.objectId}/subscriptions/${userId}`,
      optimistic: (old) => {
        const nextData = [...old?.Data, { ID: userId }];
        return { ...old, Data: nextData };
      },
      refetch: false,
    }),
  },
};
