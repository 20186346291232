import { EditorState, Modifier, RichUtils } from "draft-js";
import { useCallback, useMemo } from "react";

export const useEntity = ({ state, setState } = { state: {} }) => {
  const getActiveEntity = () => {
    const contentState = state?.getCurrentContent();
    const startKey = state?.getSelection().getStartKey();
    const startOffset = state?.getSelection().getStartOffset();
    const block = contentState?.getBlockForKey(startKey);
    const key = block.getEntityAt(startOffset);
    if (key) {
      const entity = contentState?.getEntity(key);
      const type = entity.getType();
      const data = entity.getData();
      const range = getEntityRange(block, key);
      return { key, type, data, entity, range };
    }
    return { key, type: "", entity: null };
  };

  const toggleLink = useCallback(
    (entity, data = {}, type = "MUTABLE") => {
      const contentState = state?.getCurrentContent();
      const contentStateWithEntity = contentState?.createEntity(
        entity,
        type,
        data
      );
      const entityKey = contentStateWithEntity.getLastCreatedEntityKey();
      let nextEditorState = EditorState?.set(state, {
        currentContent: contentStateWithEntity,
      });
      nextEditorState = RichUtils.toggleLink(
        nextEditorState,
        nextEditorState?.getSelection(),
        entityKey
      );
      setState(nextEditorState);
    },
    [state, setState]
  );

  const addEntity = useCallback(
    ({ entity, text = "\u00AD", data = {}, type = "MUTABLE" }) => {
      let selectionState = state?.getSelection();
      let contentState = state?.getCurrentContent();
      const contentStateWithEntity = contentState?.createEntity(
        entity,
        type,
        data
      );
      const entityKey = contentStateWithEntity.getLastCreatedEntityKey();
      contentState = Modifier.insertText(
        contentStateWithEntity,
        selectionState,
        text,
        null,
        entityKey
      );
      contentState = Modifier.insertText(
        contentState,
        selectionState,
        "\u2063"
      );
      let nextEditorState = EditorState?.push(
        state,
        contentState,
        "apply-entity"
      );
      nextEditorState = EditorState?.forceSelection(
        nextEditorState,
        selectionState
      );
      setState(nextEditorState);
    },
    [state, setState]
  );

  const getEntityRange = (block, entityKey) => {
    let entityRange = {};
    block.findEntityRanges(
      (value) => value.get("entity") === entityKey,
      (start, end) => {
        entityRange = {
          start,
          end,
          text: block.get("text").slice(start, end),
        };
      }
    );
    return entityRange;
  };

  const activeEntity = useMemo(() => {
    return getActiveEntity();
  }, [state]);

  const selectActiveEntity = useCallback(() => {
    if (activeEntity.entity) {
      const selectionState = state?.getSelection();
      const newSelectionState = selectionState?.merge({
        anchorOffset: activeEntity.range.start,
        focusOffset: activeEntity.range.end,
      });
      const nextState = EditorState?.forceSelection(state, newSelectionState);
      setState(nextState);
      return newSelectionState;
    }
    return null;
  }, [state]);

  const updateEntity = useCallback(
    (key, data) => {
      const contentState = state?.getCurrentContent();
      contentState?.replaceEntityData(key, data);
    },
    [state]
  );

  return {
    addEntity,
    toggleLink,
    updateEntity,
    selectActiveEntity,
    activeEntity,
  };
};
