import { css } from "emotion";
import { BookmarkSimple } from "phosphor-react";
import { Text } from "../../shared/components";
import { flex } from "../../shared/shared-styles";
import { colors } from "../../shared/styles";
import { useSearchParams } from "../../shared/use-search-params";

export const Flag = ({ flag, property }) => {
  const { params, updateParams, removeParam } = useSearchParams();

  const toggleFlag = (e) => {
    e.stopPropagation();
    updateParams({
      editFlagId: flag?.id || "",
      flagProperty: flag?.metadata?.property_chain,
    });
  };

  if (!flag) {
    return null;
  }
  return (
    <div
      onClick={toggleFlag}
      className={css`
        ${flex("left")}
        padding: 0 4px;
        width: calc(100% - 40px);
        cursor: pointer;
        border-radius: 8px;
        transition: background-color 0.2s ease;
        user-select: none;
        svg {
          margin-right: 4px;
        }
        :hover {
          background-color: ${colors.lighterPurple};
          .no-hover {
            display: none;
          }
        }
        :not(:hover) {
          .hover {
            display: none;
          }
        }
      `}
    >
      <BookmarkSimple
        size={16}
        weight="fill"
        className="hover"
        color={colors.purple}
      />
      <BookmarkSimple size={16} className="no-hover" color={colors.purple} />
      <Text
        css={`
          color: ${colors.purple};
          font-size: 0.9em;
          flex-grow: 1;
        `}
        ellipsis
      >
        {flag?.note}
      </Text>
    </div>
  );
};
