import React, { useState, useEffect } from "react";
import { css } from "emotion";
import { useQueryAPI } from "../react-query";
import { fetchTextingHistory } from "./course-requests";
import CircularProgress from "@material-ui/core/CircularProgress";
import { isEmpty } from "lodash";
import { format } from "date-fns";
import { PaperPlaneRight, Check } from "phosphor-react";
import LinearProgress from "@material-ui/core/LinearProgress";
import { TextingHistoryDetails } from "./TextingHistoryDetails";
import MessageEmpty from "../Messaging/message-empty.png";

export const TextingHistory = () => {
  const {
    data: textingHistory,
    refetch,
    cache,
    isLoading,
  } = useQueryAPI({
    urlKey: "v1/bp/twilio_message?order=date_created.desc",
    params: {
      axios: {
        headers: {
          "Accept-Profile": "brite",
        },
      },
    },
    queryFn: fetchTextingHistory,
    defaultValue: [],
  });

  const [course, setCourse] = useState("");

  useEffect(() => {
    refetch();
  }, []);

  if (isLoading) {
    return (
      <div
        className={css`
          margin: 60px auto;
          display: flex;
          justify-content: center;
        `}
      >
        <CircularProgress />
      </div>
    );
  }
  return (
    <div
      className={css`
        margin-top: 40px;
      `}
    >
      {isEmpty(textingHistory) && (
        <div
          className={css`
            max-width: 700px;
            margin: 0 auto;
          `}
        >
          <div
            className={css`
              display: flex;
              justify-content: center;
              margin: 56px;
            `}
          >
            <img src={MessageEmpty} width="408px" height="231px" />
          </div>
          <div
            className={css`
              font-family: "Roboto";
              font-style: normal;
              font-weight: 700;
              font-size: 24px;
              line-height: 32px;
              /* identical to box height, or 133% */

              text-align: center;

              color: #25282d;
              margin-bottom: 16px;
            `}
          >
            You’re all ready to start messaging
          </div>
          <div
            className={css`
              font-family: "Roboto";
              font-style: normal;
              font-weight: 400;
              font-size: 16px;
              line-height: 24px;
              /* or 150% */

              text-align: center;
              letter-spacing: 0.15px;

              color: #66737f;
            `}
          >
            Brite messaging allows you to text your employee's direct links to
            your Guides. You also have the option to integrate with Slack and
            Microsoft Teams for a frictionless experience.
          </div>
        </div>
      )}

      {!isEmpty(textingHistory) && !course && (
        <>
          {Object.keys(textingHistory).map((key) => {
            let textingInfo = textingHistory[key];

            if (!textingInfo.course) {
              return null;
            }

            return (
              <div
                key={key}
                onClick={() => setCourse(textingInfo)}
                className={css`
                  display: flex;
                  justify-content: space-between;
                  align-items: center;

                  box-shadow: 0px 2px 8px rgba(37, 40, 45, 0.25);
                  border-radius: 8px;
                  padding: 24px;

                  margin-bottom: 24px;
                  margin-left: 14px;
                  margin-right: 14px;

                  cursor: pointer;
                  &:hover {
                    opacity: 0.7;
                  }
                `}
              >
                <div
                  className={css`
                    width: 339px;
                  `}
                >
                  <div
                    className={css`
                      font-weight: 700;
                      font-size: 18px;
                      line-height: 24px;
                      /* identical to box height, or 133% */

                      letter-spacing: 0.15px;

                      color: #25282d;
                      margin-bottom: 8px;
                    `}
                  >
                    {textingInfo.course.Name}
                  </div>
                  <div
                    className={css`
                      margin-bottom: 8px;
                      white-space: nowrap;
                      overflow: hidden;
                      text-overflow: ellipsis;
                      font-weight: 400;
                      font-size: 16px;
                      line-height: 24px;
                      /* identical to box height, or 150% */

                      letter-spacing: 0.15px;

                      color: #66737f;
                    `}
                  >
                    Message: {textingInfo[0].body}
                  </div>
                  <div
                    className={css`
                      white-space: nowrap;
                      overflow: hidden;
                      text-overflow: ellipsis;
                      font-weight: 400;
                      font-size: 16px;
                      line-height: 24px;
                      /* identical to box height, or 150% */

                      letter-spacing: 0.15px;

                      color: #66737f;
                    `}
                  >
                    Last Sent:{" "}
                    {format(
                      new Date(textingInfo[0].date_created),
                      "MMM dd yyyy "
                    )}
                  </div>
                </div>

                <div>
                  {textingInfo.course.LogoURL && (
                    <img
                      className={css`
                        max-height: 80px;
                        max-width: 250px;
                      `}
                      src={textingInfo.course.LogoURL}
                    />
                  )}
                </div>

                <div
                  className={css`
                    background: #f5f7fa;
                    border-radius: 8px;
                    width: 300px;
                    padding: 18px;
                    font-weight: 400;
                    font-size: 16px;
                    line-height: 24px;
                    /* identical to box height, or 150% */

                    letter-spacing: 0.15px;

                    color: #25282d;
                  `}
                >
                  <div
                    className={css`
                      display: flex;
                      align-items: center;
                    `}
                  >
                    {textingInfo.percentage !== 100 && (
                      <>
                        {" "}
                        <PaperPlaneRight size={24} />{" "}
                        <div
                          className={css`
                            margin-left: 16px;
                            margin-right: 24px;
                          `}
                        >
                          Sending
                        </div>
                      </>
                    )}
                    {textingInfo.percentage === 100 && (
                      <>
                        <Check size={24} />{" "}
                        <div
                          className={css`
                            margin-left: 16px;
                            margin-right: 24px;
                          `}
                        >
                          Complete
                        </div>
                      </>
                    )}
                    <div
                      className={css`
                        margin-right: 40px;
                      `}
                    >
                      {Math.round(textingInfo.percentage)}%
                    </div>
                    <div> {textingInfo.deliveredCount}</div>
                  </div>
                  {textingInfo.percentage !== 100 && (
                    <div
                      className={css`
                        margin-top: 6px;
                      `}
                    >
                      <LinearProgress
                        variant="determinate"
                        value={textingInfo.percentage}
                      />
                    </div>
                  )}
                </div>
              </div>
            );
          })}
        </>
      )}

      {course && (
        <TextingHistoryDetails
          parentCourse={course}
          parentSetCourse={setCourse}
          refetch={refetch}
        />
      )}
    </div>
  );
};
