import React from "react";
import {
  Box,
  Button,
  Layer,
  RadioButtonGroup,
  Select,
  Text,
  TextInput,
} from "grommet";
import ButtonUI from "@material-ui/core/Button";
import { Eye, Question, SpinnerGap, Trash } from "phosphor-react";
import { css } from "emotion";
import { useState } from "react";
import {
  CircularProgress,
  IconButton,
  TextField,
  Tooltip,
} from "@material-ui/core";
import { VendorLibraryAddPageImport } from "../../../Courses/VendorLibraryAddPageImport";
import { CustomAxios } from "../../../redux/axios/axios";
import { toast } from "react-toastify";
import Axios from "axios";
import { updateCourseChanges } from "../../../redux/actions/courses";
import { useDispatch } from "react-redux";
import { isEmpty } from "lodash";
import posthog from "posthog-js";

export const CreateNewPage = ({
  display,
  isVendor,
  course,
  refetch,
  close,
}) => {
  const dispatch = useDispatch();
  const [newPageName, setNewPageName] = useState("");
  const [newPageType, setNewPageType] = useState("Build Your Own");
  const [newPageOption, setNewPageOption] = useState("Build from Scratch");
  const [filterTemplates, setFilterTemplates] = useState("");
  const [vendorLibraryModal, setVendorLibraryModal] = useState(false);
  const [pageTemplates, setPageTemplates] = useState([]);
  const [getPageTemplatesLoading, setGetPageTemplatesLoading] = useState(false);
  const [selectedPageTemplate, setSelectedPageTemplate] = useState({});
  const [loadingPreviewTemplateImageURL, setLoadingPreviewTemplateImageURL] =
    useState(false);
  const [previewTemplateImageURL, setPreviewTemplateImageURL] = useState("");
  const [previewTemplateImageModal, setPreviewTemplateImageModal] =
    useState(false);
  const [loadingNew, setLoadingNew] = useState(false);

  const updateCourse = (data) => dispatch(updateCourseChanges(data));

  const fetchPageTemplate = async (pageOption = "Import a Page Template") => {
    try {
      let response = await CustomAxios.get(`/v1/templates`);
      let pageTemplate = response.data || [];

      setNewPageOption(pageOption);
      setPageTemplates(pageTemplate);
      setGetPageTemplatesLoading(false);
    } catch (e) {
      setNewPageOption(pageOption);
      setPageTemplates([]);
      setGetPageTemplatesLoading(false);
      toast.error("Could not fetch page templates");
    }
  };

  const previewTemplate = async (template) => {
    setLoadingPreviewTemplateImageURL(true);
    setPreviewTemplateImageURL("");
    setPreviewTemplateImageModal(true);
    try {
      const response = await Axios.post(
        "https://api.unlayer.com/v2/export/image",
        { displayMode: "web", design: template.Content },
        {
          headers: {
            Authorization:
              "Basic THZyN3VQNGpmTzhxMWhZS1dRN2oyVnVrc3pZckl5NmFNYWJ3UTEyZ1JIVEk0MmJNNndOQndLZWZCb1A0eWlEbzo=",
          },
        }
      );

      window.open(response.data.data.url, "_blank");
      setLoadingPreviewTemplateImageURL(false);
      setPreviewTemplateImageURL(response.data.data.url);
      setPreviewTemplateImageModal(false);
    } catch (e) {
      setLoadingPreviewTemplateImageURL(false);
      setPreviewTemplateImageURL("");
      setPreviewTemplateImageModal(false);
    }
  };

  const closePreviewImage = () => {
    setLoadingPreviewTemplateImageURL(false);
    setPreviewTemplateImageURL("");
    setPreviewTemplateImageModal(false);
  };

  const handlePageOption = async (event) => {
    let pageOption = event.target.value;
    setGetPageTemplatesLoading(true);
    setNewPageOption(pageOption);

    if (pageOption === "Import a Page Template") {
      await fetchPageTemplate(pageOption);
    } else {
      setNewPageOption(pageOption);
      setPageTemplates([]);
      setGetPageTemplatesLoading(false);
    }
  };

  const saveNewPage = () => {
    setLoadingNew(true);
    let type = "";
    let content = null;
    if (newPageType === "Build Your Own") {
      type = "regular";
    }
    if (newPageType === "Brite Editor") {
      type = "brite-editor";
    }
    if (newPageType === "Decision Tool") {
      type = "decision";
    }
    if (newPageType === "Multiple Choice") {
      type = "question";
    }

    let actionType = "new_page";
    if (newPageOption !== "Build from Scratch" && selectedPageTemplate.ID) {
      actionType = "import_page_template";
      if (!isEmpty(selectedPageTemplate.Content)) {
        content = selectedPageTemplate.Content;
        type = selectedPageTemplate.Type || "regular";
      }
    }
    posthog.capture("CourseEdit", {
      actionType: "brite:" + actionType + ":" + type,
    });

    const newPages = [
      {
        Type: type,
        Name: newPageName,
        Content: content,
        FrontendID: "_" + Math.random().toString(36).substr(2, 9),
        InsurancePlans: [],
        Question: null,
        IsLocked: false,
        TemplateID: "00000000-0000-0000-0000-000000000000",
      },
    ];

    save(false, course, newPages);
  };

  const save = async (shouldExit, updatedCourse, pages) => {
    try {
      setLoadingNew(true);
      await Promise.all(
        pages.map((page) => {
          delete page.Order;
          if (page.Type === "regular" || page.Type === "brite-editor") {
            delete page.InsurancePlans;
            delete page.Question;
          } else if (page.Type === "decision") {
            delete page.Content;
            delete page.Question;
          } else {
            delete page.Content;
            delete page.HTMLContent;
            delete page.InsurancePlans;
          }
          return CustomAxios.post(`v2/course/${course.ID}/pages`, {
            ...page,
            CourseID: course.ID,
          });
        })
      );
    } catch (err) {
      console.log("Error creating page...");
    } finally {
      await refetch();
      setNewPageName("");
      setNewPageType("Build Your Own");
      setNewPageOption("Build from Scratch");
      setSelectedPageTemplate({});
      setLoadingNew(false);
      close();
    }
  };

  const deleteTemplate = async (template) => {
    try {
      await CustomAxios.delete(`v1/templates/${template.ID}`);
      toast.success(`Successfully deleted "${template.Name}"`);
      fetchPageTemplate();
    } catch (err) {
      console.warn(err);
      toast.error(`Couldn't delete ${template.Name}, try again...`);
    }
  };

  const pageTypeOptions = [
    "Build Your Own",
    "Brite Editor",
    "Decision Tool",
    "Multiple Choice",
  ];

  if (!display) {
    return null;
  }

  return (
    <>
      {!vendorLibraryModal ? (
        <Layer margin="small" modal={true} onEsc={close} onClickOutside={close}>
          <Box gap="small" margin="20px;" overflow="auto" width="medium">
            {!isVendor && (
              <div
                className={css`
                  display: flex;
                  justify-content: center;
                  flex-direction: column;
                `}
              >
                <ButtonUI
                  variant="contained"
                  color="primary"
                  onClick={() => setVendorLibraryModal(true)}
                >
                  Import From Template Library
                </ButtonUI>
                <div
                  className={css`
                    text-align: center;
                    margin: 18px;
                  `}
                >
                  --- Or ---
                </div>
              </div>
            )}

            <div
              className={css`
                margin-bottom: -7px;
              `}
            >
              <Text
                weight="bold"
                size={"14px"}
                className={css`
                  margin-right: 24px;
                `}
              >
                Page Name{" "}
              </Text>
            </div>
            <TextInput
              placeholder="New page name"
              className={css`
                height: 40px;
                font-weight: 400;
              `}
              value={newPageName}
              onChange={(e) => setNewPageName(e.target.value)}
            />

            <div
              className={css`
                margin-bottom: -7px;
              `}
            >
              <Text weight="bold" size={"14px"}>
                Type{" "}
                <a data-tip data-for="happyFace">
                  {" "}
                  <Question size={16} />
                </a>
              </Text>
            </div>
            <Select
              options={isVendor ? ["Build Your Own"] : pageTypeOptions}
              value={newPageType}
              onChange={({ option }) => setNewPageType(option)}
            />

            {newPageType === "Build Your Own" && (
              <div>
                <RadioButtonGroup
                  name="doc"
                  options={["Build from Scratch", "Import a Page Template"]}
                  value={newPageOption}
                  onChange={handlePageOption}
                />

                {newPageOption === "Import a Page Template" && (
                  <div
                    className={css`
                      margin-top: 12px;
                      margin-bottom: 12px;
                      max-height: 300px;
                      overflow: auto;
                      border: 1px solid #ccc;
                      padding: 12px;
                      border-radius: 10px;
                    `}
                  >
                    <TextField
                      id="search-template"
                      label="Search Template"
                      fullWidth
                      variant="outlined"
                      value={filterTemplates}
                      onChange={(e) => setFilterTemplates(e.target.value)}
                    />

                    {getPageTemplatesLoading && (
                      <div
                        className={css`
                          display: flex;
                          justify-content: center;
                        `}
                      >
                        <CircularProgress />
                      </div>
                    )}
                    {!getPageTemplatesLoading && pageTemplates.length === 0 && (
                      <div>No templates available</div>
                    )}
                    {pageTemplates
                      .filter((t) =>
                        t.Name.toLowerCase().includes(
                          filterTemplates.toLowerCase()
                        )
                      )
                      .sort((a, b) => a.Name.localeCompare(b.Name))
                      .map((template) => {
                        return (
                          <React.Fragment key={template.ID}>
                            <div
                              onClick={() => setSelectedPageTemplate(template)}
                              className={css`
                                display: flex;
                                align-items: center;
                                margin-top: 8px;
                                padding: 8px;
                                ${selectedPageTemplate.ID === template.ID
                                  ? "background-color: #eeeeee;"
                                  : ""}
                              `}
                            >
                              <div
                                className={css`
                                  margin-right: 12px;
                                  cursor: pointer;
                                  margin-top: 3px;
                                `}
                              >
                                {loadingPreviewTemplateImageURL && (
                                  <SpinnerGap size={24}>
                                    <animate
                                      attributeName="opacity"
                                      values="0;1;0"
                                      dur="4s"
                                      repeatCount="indefinite"
                                    ></animate>
                                    <animateTransform
                                      attributeName="transform"
                                      attributeType="XML"
                                      type="rotate"
                                      dur="5s"
                                      from="0 0 0"
                                      to="360 0 0"
                                      repeatCount="indefinite"
                                    ></animateTransform>{" "}
                                  </SpinnerGap>
                                )}
                                {!loadingPreviewTemplateImageURL && (
                                  <Eye
                                    size={20}
                                    onClick={(e) => {
                                      e.stopPropagation();
                                      previewTemplate(template);
                                    }}
                                  />
                                )}
                              </div>
                              <Button
                                onClick={(e) => {
                                  e.stopPropagation();
                                  setSelectedPageTemplate(template);
                                }}
                                plain
                                className={css`
                                  color: #25282d;
                                  display: block;
                                  flex-grow: 1;
                                `}
                              >
                                {template.Name}
                              </Button>
                              <Tooltip title="Delete" placement="top">
                                <IconButton
                                  onClick={() => deleteTemplate(template)}
                                >
                                  <Trash />
                                </IconButton>
                              </Tooltip>
                            </div>
                          </React.Fragment>
                        );
                      })}
                  </div>
                )}
              </div>
            )}

            <div
              className={css`
                display: flex;
                justify-content: space-between;
                margin-top: 30px;
              `}
            >
              <Button label="Cancel" onClick={close} />
              <Button
                className={css`
                  color: white;
                `}
                primary
                label="Add"
                disabled={!newPageName || loadingNew}
                onClick={() => {
                  saveNewPage();
                }}
              />
            </div>
          </Box>
        </Layer>
      ) : (
        <Layer
          margin="xsmall"
          modal={true}
          onEsc={() => setVendorLibraryModal((v) => !v)}
          onClickOutside={() => setVendorLibraryModal((v) => !v)}
        >
          <div
            className={css`
              min-width: 900px;
              overflow: auto;
            `}
          >
            <div
              className={css`
                font-weight: 400;
                font-size: 22px;
                line-height: 28px;
                color: #797979;
                margin: 20px;
                margin-bottom: 5px;
              `}
            >
              Import pages from Template Library
            </div>
            <Box gap="medium" margin="20px;">
              <VendorLibraryAddPageImport
                selectedCourseID={course.ID}
                course={course}
                close={() => {
                  setVendorLibraryModal((v) => !v);
                  close();
                }}
                save={save}
                updateCourseChanges={updateCourse}
              />
              <div
                className={css`
                  display: flex;
                  justify-content: center;
                `}
              >
                <Button
                  label="Cancel"
                  onClick={() => {
                    setVendorLibraryModal((v) => !v);
                  }}
                />
              </div>
            </Box>
          </div>
        </Layer>
      )}
    </>
  );
};
