import { CustomAxios } from "../../redux/axios/axios";
import { cloneDeep } from "lodash";

export { media } from "./media";
export { guides } from "./guides";
export { guide } from "./guide";
export { aiSuggestions } from "./ai-suggestions";
export { fieldHistory } from "./field-history";
export { needsAttention } from "./needs-attention";
export { knockSubscriptionByTypeList as listNotifications } from "./knock-subscription-by-type-list";
export { benefitsPackage } from "./benefits-package";
export { benefitsPackageProductsList } from "./benefits-package-products-list";
export { businessData } from "./business-data";

export const getResourcePacket = (resourceObject, resourceProps = {}) => {
  try {
    if (!("getResourceOptions" in resourceObject)) {
      throw `!!! resourceObject needs a getResourceOptions function !!!`;
    }
    return resourceObject?.getResourceOptions?.(resourceProps) || {};
  } catch (err) {
    console.log(err);
    console.error(`ADD 'getPackage' TO resourceObject: ${resourceObject.key}`);
    return {};
  }
};

// Returns:
//  - queryFn REACT-QUERY
//  - queryKey REACT-QUERY
//  - update axios params here
//  - props pass in
export const getGenericQueryProps = (url, resourceProps, axios = {}) => {
  const { params, businessId, ...rest } = resourceProps;
  return {
    queryFn: async () => {
      const axiosOptions = {
        ...axios,
        ...(resourceProps?.axios || {}),
      };
      const { data } = await CustomAxios.get(url, axiosOptions);
      return data;
    },
    ...rest,
    queryKey: [
      resourceProps?.businessId,
      url,
      ...(resourceProps?.queryKey || []),
    ],
  };
};

export const extractSearchQuery = (searchQuery, isPostgrest) => {
  return Object.entries(searchQuery || {})
    .reduce((prev, [key, value]) => {
      if (!isPostgrest) {
        if (value?.includes?.(",")) {
          const split = value.split(",");
          const queries = split.map((item) => `${key}=${item}`);
          return [...prev, ...queries];
        }
      }
      return [...prev, `${key}=${value}`];
    }, [])
    .join("&");
};

// Get URL + inject path params + inject search query
export const getUrl = (url = "", resourceProps = {}) => {
  const { params = {}, search = {} } = resourceProps;

  // Check if the URL is a Postgrest URL. If it is, we need to handle the search query differently.
  const isPostgrest = url.split("/").includes("bp");

  const optionEntries = params ? Object.entries(params || {}) : [];
  const urlWithPathParams = optionEntries?.reduce(
    (prev, [key, value]) => prev?.replace(`:${key}`, value),
    url
  );
  const searchStart = urlWithPathParams?.includes("?") ? "&" : "?";

  const searchQuery = searchStart + extractSearchQuery(search, isPostgrest);

  return urlWithPathParams + searchQuery;
};

// This handles network requests and manages different options:
// - Optimistic Updates
// - Refetching the cached data
// - Handling errors properly
// NOTE: Use this in the `methods` function in each resource file.
export const genericRequest = async (
  axiosFn,
  optimisticFn,
  resource,
  options
) => {
  const opts = { refetch: true, ...options };
  const old = cloneDeep(resource?.query?.data);
  try {
    if (optimisticFn) {
      const optimisticData = optimisticFn ? optimisticFn(old) : old;
      resource?.setData?.(optimisticData);
    }
    return await axiosFn();
  } catch (err) {
    resource?.setData?.(old);
    throw err;
  } finally {
    if (opts?.refetch) {
      resource?.query?.refetch?.();
    }
  }
};
