import React, { useEffect } from "react";
import { connect } from "react-redux";
import { getBusiness } from "../redux/actions/business";
import { css } from "emotion";
import CircularProgress from "@material-ui/core/CircularProgress";
import Tooltip from "@material-ui/core/Tooltip";
import IconButton from "@material-ui/core/IconButton";
import KeyboardArrowDownIcon from "@material-ui/icons/KeyboardArrowDown";

import Alert from "@material-ui/lab/Alert";

const CarrierView = (props) => {
  useEffect(() => {
    props.getBusiness();
  }, []);

  if (props.loading) {
    return (
      <div
        className={css`
          margin: 0 auto;
        `}
      >
        <CircularProgress />
      </div>
    );
  }

  const hasLogo = () => {
    if (props.LogoURL) {
      return true;
    }
    return false;
  };

  const getLogo = () => {
    return props.LogoURL;
  };

  return (
    <div>
      <div
        className={css`
          font-weight: bold;
          font-size: 18px;
          margin: 15px;
        `}
      >
        Employee Recommendation Card
      </div>

      {!hasLogo() && (
        <Alert severity="error">
          Your company has no logo! Upload a logo in the Settings tab above to
          view it on the employee recommendation card.
        </Alert>
      )}

      <div
        className={css`
          border-radius: 10px;
          box-shadow: 0px 0px 8px -5px rgba(82, 82, 82, 1);
          margin: 15px;
          width: 450px;
        `}
      >
        <div
          className={css`
            position: relative;
          `}
        >
          <div
            className={css`
              display: flex;
              justify-content: space-between;
              padding: 8px;
              align-items: center;
            `}
          >
            <div
              className={css`
                display: flex;
                flex-direction: column;
                align-items: flex-start;
                max-width: 55%;
                min-width: 40%;
              `}
            >
              {hasLogo() && (
                <div
                  className={css`
                    padding: 8px;
                  `}
                >
                  <img
                    src={getLogo()}
                    className={css`
                      max-height: 40px;
                      max-width: 100px;
                    `}
                  />
                </div>
              )}

              <div
                className={css`
                  font-weight: bold;
                  padding: 8px;
                  padding-top: 0px;
                  color: #000;
                  font-size: 14px;
                  text-align: left;
                  font-family: Roboto, Montserrat, sans-serif !important;
                `}
              >
                Name of the product
              </div>
            </div>

            <div
              className={css`
                display: flex;
                align-items: center;
                margin-left: 8px;
              `}
            >
              <div
                className={css`
                  margin-right: 4px;
                `}
              >
                <div
                  className={css`
                    text-align: right;
                    font-size: 12px;
                    font-family: Roboto, Montserrat, sans-serif !important;
                  `}
                >
                  {" "}
                  Description of the product here.
                </div>
              </div>
            </div>
          </div>

          <div
            className={css`
              margin-top: -16px;
              margin-bottom: -8px;
              align-items: center;
              justify-content: center;
              display: flex;
            `}
          >
            <Tooltip placement="top" title={"Learn more"}>
              <IconButton aria-label="Learn more">
                <KeyboardArrowDownIcon />
              </IconButton>
            </Tooltip>
          </div>
        </div>
      </div>
    </div>
  );
};

export const CarrierPreview = connect(
  (state) => ({
    loading: state.business.loading,
    Description: state.business.carrier.Description || "",
    Categories: state.business.categories || [],
  }),
  (dispatch, ownProps) => ({
    getBusiness: () => dispatch(getBusiness()),
  })
)(CarrierView);
