import { css } from "emotion";
import { X } from "phosphor-react";
import { useState } from "react";
import { toast } from "react-toastify";
import { Button, Div, Modal, Text, Toggle } from "../../../shared/components";
import { flex } from "../../../shared/shared-styles";
import DTImage from "./dt-graphics.png";

export const DecisionTool = ({
  course,
  page,
  savePage,
  updateCourse,
  pageLease,
}) => {
  const [modal, setModal] = useState("");

  const handleNewHire = (event) => {
    if (pageLease.hasLease) {
      updateCourse({
        Type: event.target.checked ? "newhire" : "openenrollment",
      });
    } else {
      toast.error(`${pageLease.leasedToEmail} is editing this page.`);
    }
  };

  const handleViewTotalsMonthly = (event) => {
    if (pageLease.hasLease) {
      updateCourse({
        ViewTotalsMonthly: event.target.checked,
      });
    } else {
      toast.error(`${pageLease.leasedToEmail} is editing this page.`);
    }
  };
  const toggleComparison = async (event) => {
    await savePage({
      Content: {
        ...page?.Content,
        decisionTool: {
          ...page?.Content?.decisionTool,
          displayComparisonModal: event.target.checked,
        },
      },
    });
  };

  const routeToCourse = () => {
    const pathParam = course.VanityURL || course.ID;
    window.open(
      `https://britehr.app/${pathParam}/${page?.ID}?lt=preview`,
      "_blank"
    );
  };

  return (
    <Div
      css={css`
        ${flex("center column")}
        height: 100%;
        p {
          max-width: 700px;
        }
      `}
    >
      <img src={DTImage} />
      <Text
        h2
        css={`
          span {
            background: linear-gradient(
              270deg,
              #62a7e5 30.66%,
              #a949f5 37.58%,
              #c05194 44.65%
            );
            background-clip: text;
            -webkit-background-clip: text;
            -webkit-text-fill-color: transparent;
            font-feature-settings: "clig" off, "liga" off;
          }
          margin: 32px 0;
        `}
      >
        Brite Decision <span>Support</span>
      </Text>
      <Text label center>
        This is where the decision support tool will be located in this guide.
        You can change the page order by dragging the page up or down
      </Text>
      <Div
        css={css`
          ${flex("center")}
        `}
      >
        <Button
          secondary
          css={`
            margin-right: 16px;
          `}
          onClick={() => setModal("settings")}
        >
          Settings
        </Button>
        <Button
          onClick={routeToCourse}
          secondary
          css={`
            margin: 32px 0;
          `}
        >
          Preview
        </Button>
      </Div>
      <Modal display={modal === "settings"} onClose={() => setModal("")}>
        <Div
          css={css`
            width: 500px;
            padding: 8px;
          `}
        >
          <Div
            css={css`
              ${flex("space-between")} padding: 16px;
            `}
          >
            <Text h2>Decision Tool Settings</Text>
            <Button styles="icon" onClick={() => setModal("")}>
              <X />
            </Button>
          </Div>
          <Div
            css={css`
              padding: 0 16px;
            `}
          >
            <Div
              css={css`
                ${flex("space-between")}
              `}
            >
              <Text>Applies to new hires only</Text>
              <Toggle
                checked={course?.Type === "newhire"}
                onChange={handleNewHire}
              />
            </Div>
            <Div
              css={css`
                ${flex("space-between")}
              `}
            >
              <Text>Display plan costs as monthly costs</Text>
              <Toggle
                checked={course?.ViewTotalsMonthly}
                onChange={handleViewTotalsMonthly}
              />
            </Div>
            <Div
              css={css`
                ${flex("space-between")}
              `}
            >
              <Text>Display plan comparisons</Text>
              <Toggle
                checked={page?.Content?.decisionTool?.displayComparisonModal}
                onChange={toggleComparison}
              />
            </Div>
          </Div>
          <Div
            css={css`
              ${flex("right")}
              padding: 16px;
            `}
          >
            <Button
              secondary
              css={`
                margin-right: 16px;
              `}
              onClick={() => setModal("")}
            >
              Cancel
            </Button>
            <Button
              css={`
                min-width: 100px;
              `}
              onClick={() => setModal("")}
            >
              OK
            </Button>
          </Div>
        </Div>
      </Modal>
    </Div>
  );
};
