import { css } from "emotion";
import {
  ArrowLeft,
  CaretRight,
  Check,
  Info,
  Warning,
  WarningCircle,
} from "phosphor-react";
import { useEffect, useMemo, useState } from "react";
import { Button, Div, Modal, Text } from "../../shared/components";
import { container, flex, scrollbar } from "../../shared/shared-styles";
import { colors } from "../../shared/styles";
import { useSearchParams } from "../../shared/use-search-params";
import { useQueryAPI } from "../../react-query";
import { useEditorResource } from "../use-editor-resource";
import { PRODUCT_HEADERS } from "../../benefit-package/constants";
import { BriteLoader } from "../../shared/components/brite-loader";
import { useStateSync } from "../../shared/use-state-sync";
import { Radio, Tooltip } from "@material-ui/core";
import { useParams } from "react-router";
import { history } from "../../history";

export const smartFieldDisplayMap = {
  "benefits-package": "Benefits Package",
};

const productDisplayMap = { ...PRODUCT_HEADERS, custom: "Custom" };

const getProductUrl = ({ productType, productId, packageId, firstMatch }) => {
  let refQuery = "direct-ref";
  if (firstMatch) {
    refQuery = "first-match";
  }
  return `/v1/product/types/${productType}/tags?productID=${productId}&benefitsPackageID=${packageId}&use-product-layout=true&${refQuery}=true`;
};

export const addSmartFieldToCache = (cache, data) => {
  const next = {
    ...(cache?.data?.Data || {}),
    [data.value]: { Value: data.sample, Success: true },
  };
  cache.setData({ Data: { ...next } });
};

export const SmartFields = ({
  handleSmartField,
  nameFilter,
  handleRemove,
  activeSmartField = null,
  name = "Text",
}) => {
  const [selection, setSelection] = useState([]);

  const [activeField, setActiveField] = useState();

  const [modal, setModal] = useState("");

  const [isActive, setIsActive] = useStateSync(
    activeSmartField ? true : false,
    [activeSmartField]
  );

  const {
    params: { packageId = "" },
  } = useSearchParams();

  const { businessId } = useParams();

  useEffect(() => {
    setActiveField(null);
  }, [selection.length]);

  const { data: smartFields } = useEditorResource("smartFields");
  const { data: liveSmartFields } = useEditorResource("liveSmartFields");
  const evaluatedSmartField = liveSmartFields?.[activeSmartField?.value] || {};

  const { data: products } = useEditorResource("products", {
    select: (data) => {
      return data.map((item) => ({
        type: "product",
        hasNested: true,
        name: item.ProductName || productDisplayMap[item?.Type],
        //
        ID: item?.ID,
        Type: item?.Type,
        MultiNetworkCategory: item?.MultiNetworkCategory,
      }));
    },
  });

  const productFieldsEnabled =
    !!selection?.[1]?.productType && !!selection?.[2]?.ID && !!packageId;

  const productFieldQuery = useQueryAPI({
    url: getProductUrl({
      productType: selection?.[1]?.productType || "",
      productId: selection?.[2]?.ID || "",
      packageId,
      firstMatch: selection?.[2]?.firstMatch,
    }),
    enabled: productFieldsEnabled,
  });

  const getDataSourceChain = (selection) => {
    let dataSources = [];
    for (const level of selection) {
      if ("DataSource" in level) {
        dataSources?.push(level?.DataSource);
      } else if (level?.type === "product") {
        dataSources?.push(productFieldQuery?.data?.DataSource);
      }
    }
    return dataSources;
  };

  const addTag = () => {
    const dataSourceChain = getDataSourceChain(selection);
    handleSmartField({
      dataSourceChain,
      data: activeField,
      replace: replacingSmartText,
    });
    setIsActive(true);
  };

  const handleClick = (item) => {
    if (item?.mergeTags?.length || item?.hasNested) {
      setSelection((s) => [...(s || []), item]);
    } else {
      setActiveField(item);
    }
  };

  const getProductTypeList = (productType) => {
    const availableTypes = products.map(({ Type }) => Type);
    return Object.entries(productDisplayMap)
      .filter(([type]) => availableTypes.includes(type))
      .map(([type, value]) => ({
        type: "product-type",
        productType: type,
        hasNested: true,
        name: value,
      }));
  };

  const getList = () => {
    let fields = smartFields?.filter((item) => item?.name);
    if (!selection?.length) {
      return fields;
    } else {
      let list = [...fields];
      const level = selection.at(-1);
      if (level.type === "package") {
        list = getProductTypeList();
      } else if (level.type === "product-type") {
        list = products.filter(({ Type }) => Type === level.productType);
        if (list && list.length) {
          list.push({
            type: "product",
            hasNested: true,
            name: "First Match",
            ID: list[0].ID,
            Type: list[0].Type,
            firstMatch: true,
          });
        }
      } else if (level.type === "product") {
        list = productFieldQuery?.data?.mergeTags || [];
      } else if ("mergeTags" in level) {
        list = level.mergeTags;
      }

      list = cleanFields(list);
      list = filtered(list);
      return list;
    }
  };

  function cleanFields(smartFields) {
    let filteredTags = [];
    for (let i = 0; i < smartFields.length; i++) {
      // copy rather than referencing the original.
      let smartField = { ...smartFields[i] };
      if (smartField.name) {
        filteredTags.push(smartField);
      }
    }

    return filteredTags;
  }

  //Filter the List of Smart Text by the nameFilter aka URL/Email/Phone
  function filtered(smartFields) {
    if (!nameFilter) {
      return smartFields;
    }

    let filteredTags = [];
    for (let i = 0; i < smartFields.length; i++) {
      // copy rather than referencing the original.
      let smartField = { ...smartFields[i] };
      if (smartField.mergeTags) {
        // This is a folder
        smartField.mergeTags = filtered(smartField.mergeTags, nameFilter);
        if (smartField.mergeTags.length > 0) {
          filteredTags.push(smartField);
        }
      } else if (smartField.hasNested) {
        //This is a product also a folder, and we don't know the subfields until later.
        filteredTags.push(smartField);
      } else if (
        smartField.name?.toLowerCase().includes(nameFilter?.toLowerCase())
      ) {
        // This is a smart text within a folder

        filteredTags.push(smartField);
      }
    }

    return filteredTags;
  }

  const smartFieldEditableType = useMemo(() => {
    const product = activeSmartField?.dataSourceChain?.find(
      (item) => item?.Type === "product"
    );
    if (packageId && product) {
      return product;
    }
    const companyKit = activeSmartField?.dataSourceChain?.find(
      (item) => item?.Type === "business"
    );
    if (companyKit) {
      return companyKit;
    }
    return "";
  }, [activeSmartField?.frontendId]);

  const handleEdit = () => {
    if (smartFieldEditableType?.Type === "business") {
      history.push(`/${businessId}/business-details`);
    } else {
      const product = products?.find(
        ({ ID }) => ID === smartFieldEditableType?.Identifier
      );
      history.push(
        `/${businessId}/packages/${packageId}/${product?.Type}/${product?.ID}`
      );
    }
  };

  const list = useMemo(() => {
    const list = getList();
    return list.sort((a, b) => {
      const y = a?.mergeTags?.length || a?.hasNested;
      const x = b?.mergeTags?.length || b?.hasNested;
      return x === y ? 0 : x ? -1 : 1;
    });
  }, [
    smartFields,
    selection.length,
    selection.toString(),
    productFieldQuery?.data?.length,
    productFieldQuery?.isLoading && productFieldsEnabled,
    nameFilter,
  ]);

  const isSmartFieldActive = activeSmartField !== null;
  const replacingSmartText = !isActive && isSmartFieldActive;

  const isSuccess =
    !("Success" in evaluatedSmartField) || evaluatedSmartField?.Success;
  const isEmpty = "Value" in evaluatedSmartField && !evaluatedSmartField?.Value;

  return (
    <Div
      css={css`
        width: 100%;
      `}
    >
      <Div
        css={css`
          ${flex("left")}
          padding: 16px 0;
          ${!isActive
            ? `border-bottom: 1px solid ${colors.gray[300]};`
            : "padding-bottom: 0;"}
        `}
      >
        {selection?.length && !isActive ? (
          <Button
            styles="icon sm"
            onMouseDown={(e) => e.preventDefault()}
            onClick={() => {
              setSelection((s) => {
                const nextSelection = [...s];
                nextSelection.pop();
                return nextSelection;
              });
            }}
            css={`
              margin-right: 8px;
            `}
          >
            <ArrowLeft />
          </Button>
        ) : null}
        <Text
          h4
          css={`
            flex-grow: 1;
          `}
        >
          {!!selection?.length && !isActive
            ? selection
                .slice(-1)
                ?.map(({ name = "" }) => smartFieldDisplayMap[name] || name)
                .join(" > ")
            : `Smart ${name}`}
        </Text>
        <Button styles="icon sm" onClick={() => setModal("info")}>
          <Info color={colors.gray[500]} />
        </Button>
      </Div>
      {isActive ? (
        <Div>
          <Div
            css={css`
              padding: 8px;
              border-radius: 8px;
              background-color: ${colors.gray[100]};
              margin: 16px 0;
              ${flex("space-between")}
            `}
          >
            <Text label>{activeSmartField?.name}</Text>
            {isSuccess ? (
              isEmpty ? (
                <Tooltip title="This smart text is syncing but the source is empty.">
                  <Warning color={colors.yellow[100]} size={24} />
                </Tooltip>
              ) : (
                <Check color={colors.purple} size={24} />
              )
            ) : (
              <Tooltip title="This smart text is broken.">
                <WarningCircle color={colors.red[100]} size={24} />
              </Tooltip>
            )}
          </Div>
          <Div
            css={css`
              ${flex("right")}
            `}
          >
            <Button red onClick={handleRemove}>
              Remove
            </Button>
            {isSuccess && !!smartFieldEditableType ? (
              <Button
                secondary
                css={`
                  margin-left: 16px;
                `}
                onClick={handleEdit}
              >
                Edit{" "}
                {smartFieldEditableType?.Type === "business"
                  ? "Company Kit"
                  : smartFieldEditableType?.Type === "product"
                  ? "Benefit"
                  : ""}
              </Button>
            ) : null}
            {isActive ? (
              <Button
                css={`
                  margin-left: 16px;
                `}
                onClick={() => setIsActive(false)}
              >
                Change
              </Button>
            ) : null}
          </Div>
        </Div>
      ) : null}

      {!isActive ? (
        <>
          <Div
            key={selection.toString()}
            css={css`
              height: 180px;
              overflow-y: auto;
              position: relative;
              padding-top: 8px;
              padding-right: 8px;
              padding-bottom: 8px;
              ${scrollbar.style}
            `}
          >
            <BriteLoader
              isLoading={productFieldQuery?.isLoading && productFieldsEnabled}
              size={64}
              weight={12}
            />
            {list?.map((item) => (
              <Div
                css={css`
                  ${container.hover}
                  padding: 8px 16px;
                  border-radius: 8px;
                `}
                onClick={() => handleClick(item)}
                onMouseDown={(e) => e.preventDefault()}
              >
                <Div
                  key={item?.value + item?.name + selection.toString()}
                  css={css`
                    ${flex("space-between")}
                  `}
                >
                  <Div
                    css={css`
                      ${flex("left")}
                      .radio {
                        margin: 0;
                        padding: 0;
                        margin-right: 16px;
                        color: linear-gradient(
                          318deg,
                          #62a7e5 -50%,
                          #a949f5 47.76%,
                          #c05194 145.51%
                        );
                      }
                    `}
                  >
                    {!(!!item?.mergeTags?.length || item?.hasNested) ? (
                      <Radio
                        className="radio"
                        checked={activeField?.value === item?.value}
                      />
                    ) : null}
                    <Text label>
                      {item?.name}{" "}
                      {item?.MultiNetworkCategory ? (
                        <span style={{ padding: "8px", opacity: 0.5 }}>
                          {item?.MultiNetworkCategory}
                        </span>
                      ) : null}{" "}
                    </Text>
                  </Div>
                  {!!item?.mergeTags?.length || item?.hasNested ? (
                    <CaretRight size={24} />
                  ) : null}
                </Div>
              </Div>
            ))}
            {packageId && !selection?.length ? (
              <Div
                css={css`
                  ${container.hover}
                  ${flex("space-between")}
                padding: 8px 16px;
                  border-radius: 8px;
                `}
                onClick={() =>
                  setSelection([{ name: "benefits-package", type: "package" }])
                }
                onMouseDown={(e) => e.preventDefault()}
              >
                <Text label>Benefits Package</Text>
                <CaretRight size={24} />
              </Div>
            ) : null}
          </Div>
          {selection?.length ? (
            <Div
              css={css`
                ${flex("right")}
                border-top: 1px solid ${colors.gray[300]};
                padding-top: 16px;
                button {
                  flex-grow: 1;
                }
              `}
            >
              <Button
                secondary
                onClick={() => {
                  setSelection([]);
                  setActiveField(null);
                }}
              >
                Cancel
              </Button>
              <Button
                css={`
                  margin-left: 16px;
                `}
                disabled={!activeField}
                onClick={addTag}
              >
                {replacingSmartText ? "Change" : "Add"}
              </Button>
            </Div>
          ) : null}
        </>
      ) : null}

      <Modal display={modal === "info"} onClose={() => setModal("")}>
        <Div
          css={css`
            border-radius: 16px;
            border: 2px solid ${colors.purple};
            padding: 24px;
            width: 500px;
          `}
        >
          <Text
            h4
            css={`
              padding: 8px 0;
            `}
          >
            What is Smart {name}
          </Text>
          <Text>
            Smart-Text is text that appears in a guide that is syncing with the
            information you've added to Brite. For example, it could be details
            from a medical plan or HR contact info from your Company Kit. If you
            need to make any changes to this information, don't worry - the
            updates will automatically show up in all of your guides.
          </Text>
        </Div>
      </Modal>
    </Div>
  );
};
