import React from "react";

import Menu from "@material-ui/core/Menu";
import MoreHorizIcon from "@material-ui/icons/MoreHoriz";
import MenuItem from "@material-ui/core/MenuItem";
import Tooltip from "@material-ui/core/Tooltip";
import IconButton from "@material-ui/core/IconButton";

export function PageOptions(props) {
  const [anchorEl, setAnchorEl] = React.useState(null);

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const showDelete = () => {
    if (props.showDelete) {
      if (props.isVendor && props.isLocked) {
        return false;
      } else {
        return true;
      }
    } else {
      return false;
    }
  };

  return (
    <React.Fragment>
      <Tooltip title="Page Actions" placement="top">
        <IconButton
          aria-label="Page Actions"
          color="default"
          onClick={handleClick}
        >
          <MoreHorizIcon style={{ color: "white" }} />
        </IconButton>
      </Tooltip>
      <Menu
        id="more-menu"
        anchorEl={anchorEl}
        keepMounted
        open={Boolean(anchorEl)}
        onClose={handleClose}
      >
        <MenuItem
          onClick={(e) => {
            props.rename(e);
            handleClose();
          }}
        >
          Rename
        </MenuItem>
        {props.pageType === "regular" && (
          <MenuItem
            onClick={(e) => {
              props.duplicate(e);
              handleClose();
            }}
          >
            Duplicate
          </MenuItem>
        )}

        {showDelete() && (
          <MenuItem
            onClick={(e) => {
              props.delete(e);
              handleClose();
            }}
          >
            Delete
          </MenuItem>
        )}

        {props.showLock && !props.isLocked && (
          <MenuItem
            onClick={(e) => {
              props.lock(e);
              handleClose();
            }}
          >
            Lock Page
          </MenuItem>
        )}
      </Menu>
    </React.Fragment>
  );
}
