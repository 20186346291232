import { useQueryAPI } from "../react-query";
import { CustomAxios } from "../redux/axios/axios";
import { useStore } from "../store-provider/use-store";

const dedupeUserList = (users, members) => {
  const userList = users.map(({ UserID }) => UserID);
  let dedupe = userList;
  return members.filter(({ UserID }) => {
    if (!dedupe.includes(UserID)) {
      dedupe.push(UserID);
      return true;
    }
    return false;
  });
};

export const fetchMembers = async (userId, resourceIds) => {
  let users = [];
  for (let businessId of resourceIds) {
    const header = { headers: { BusinessID: businessId } };
    try {
      const {
        data: { Data: members },
      } = await CustomAxios.get(`v2/business/${businessId}/users`, header);
      const filtered = dedupeUserList([{ UserID: userId }, ...users], members);
      const promises = await Promise.allSettled(
        filtered.map((item) =>
          CustomAxios.get(`v1/users/${item.UserID}`, header)
        )
      );
      for (let idx = 0; idx < promises.length; idx++) {
        if (promises[idx].status === "fulfilled") {
          const member = filtered[idx];
          const user = promises[idx].value.data;
          users.push({ ...member, user });
        }
      }
    } catch (err) {
      console.warn(err);
      return [];
    }
  }
  return users.sort((a, b) => a.user.Email.localeCompare(b.user.Email));
};

export const useMembers = (reactQueryOptions = {}) => {
  const {
    data: { user, acls, parentBusiness },
  } = useStore();
  const resourceIds = Object.keys(acls).filter(
    (key) =>
      acls[key]?.Acls?.includes("write:business_access") ||
      acls[key]?.Acls?.includes("read:business_access")
  );
  const queryResponse = useQueryAPI({
    urlKey: `business-members/${user.ID}`,
    queryFn: () => fetchMembers(user.ID, resourceIds),
    enabled: !!user.ID && !!resourceIds.length && !!parentBusiness.ID,
    defaultValue: [],
    ...reactQueryOptions,
  });

  return queryResponse;
};
