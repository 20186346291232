import { modifyContent } from "../../provider/utils";
import { convertToHtml } from "../text-v2/slate-utils";
import { useBriteSlate } from "./useBriteSlate";

export const useBodySlate = ({ editor, element, location }, resources) => {
  const bodyStyle = {
    ...resources?.styles?.text?.body,
    color:
      element?.body?.attributes?.style?.color ||
      resources?.styles?.text?.body?.color ||
      "",
  };

  const body = useBriteSlate({
    slate: element?.data?.body?.slate,
    content: element?.data?.body?.content,
    onUpdate: () => {
      const content = convertToHtml(body?.slateProps?.editor?.children);
      modifyContent.merge(editor, location, {
        data: {
          body: {
            slate: body?.slateProps?.editor?.children,
            content,
          },
        },
      });
    },
  });

  return {
    ...body,
    editorProps: {
      ...body.editorProps,
      style: bodyStyle,
    },
  };
};
