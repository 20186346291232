import { useAcls } from "../use-acls";

export const ACLWrapper = ({
  children,
  acls = [],
  resourceId = null,
  alternateChildren = null,
}) => {
  const hasAcl = useAcls(acls, resourceId);
  if (!hasAcl) {
    return alternateChildren;
  }
  return children;
};
