import React, { useEffect, useState } from "react";
import { css } from "emotion";
import { Box, Button, IconButton, Modal, TextareaAutosize, Typography } from "@material-ui/core";
import CloseIcon from "@material-ui/icons/Close"
import briteLogo from "./images/brite-logo.png";
import { CustomAxios } from "./redux/axios/axios";
import { useStore } from "./store-provider/use-store";

const modalContainer = css`
  display: flex;
  justify-content: center;
`;

const boxStyle = css`
  position: relative;
  display: flex;
  flex-direction: column;
  justify-content: center;
  width: 100%;
  max-width: 500px;
  background: white;
  padding: 32px 0;
  margin-top: 10%;
  margin-bottom: auto;
  border-radius: 8px;
  textarea {
    font-family: Roboto, Montserrat, sans-serif !important;
    padding: 8px;
    resize: none;
  }
  .nps-question {
    font-weight: bold;
    padding: 0;
    padding-left: 8px;
    padding-right: 40px;
  }
  .submit-button {
    padding: 100px;
  }
  > div {
    display: flex; justify-content: center;
    :last-child {
      justify-content: flex-end;
      margin-top: 24px;
      padding-right: 48px;
    }
  }
`;

const logoStyle = css`
  margin: 0;
  margin-left: 32px;
  margin-right: 8px;
  width: 40px;
  height: 39px;
`;

const npsButton = (isSelected) => css`
  display: inline-block;
  padding: 0;
  p {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 32px;
    height: 32px;
    border-radius: 50%;
    margin: -8px;
    ${isSelected && `
        background-color: black;
        color: white;
        font-weight: bold;
    `}
  }
`;

const urlShowSurvey = 'v1/nps/user';
const urlPostSurvey = 'public/v1/nps';
const npsOptions = Array.from(Array(10).keys());

export const NPSModal = () => {

  const { data: { selectedBusiness, user } } = useStore();

  const businessID = selectedBusiness.ID || '';
  const userID = user.ID || '';

  const [npsScore, setNpsScore] = useState(0);
  const [display, setDisplay] = useState(false);
  const [feedback, setFeedback] = useState('');

  const fetchNpsStatus = async () => {
    try {
      const { data } = await CustomAxios.post(`${urlShowSurvey}/${userID}`);
      setDisplay(data.ShowSurvey);
    } catch (err) {
      setDisplay(false);
      console.warn(err);
    }
  }

  useEffect(() => {
    if (userID) {
      fetchNpsStatus();
    }
  }, [userID]);

  const handleSubmit = async () => {
    setDisplay(false);
    await CustomAxios.post(urlPostSurvey, {
      NpsNumber: npsScore,
      BusinessID: businessID,
      ParentID: businessID,
      Comment: feedback,
      SurveyType: "admin"
    });
  }

  return (
    <Modal
      open={display}
      onClose={handleSubmit}
      className={modalContainer}
    >
      <Box className={boxStyle}>

        <IconButton style={{ position: 'absolute', top: '8px', right: '8px' }}>
          <CloseIcon fontSize="large" />
        </IconButton>

        <div className={css`align-items: center; padding: 32px; padding-bottom: 24px;`}>
          <img
            src={briteLogo}
            className={logoStyle}
            alt="Brite Logo"
            title="Brite Logo"
          />
          <Typography
            className="nps-question"
            variant="body1"
          >
            How likely is it that you would recommend Brite to a friend or colleague?
          </Typography>
        </div>

        <div className={css`padding: 8px 0;`}>
          {npsOptions.map((item) => (
            <IconButton
              key={item}
              onClick={() => setNpsScore(item + 1)}
              className={npsButton(item + 1 === npsScore)}
            >
              <Typography key={item}>{item + 1}</Typography>
            </IconButton>
          ))}
        </div>

        <TextareaAutosize
          style={{ margin: '8px auto', border: '1px solid', borderRadius: '8px', width: '400px' }}
          variant="outlined"
          placeholder="Have feedback?"
          value={feedback}
          onChange={(e) => setFeedback(e.target.value)}
          rowsMin={3}
          rowsMax={5}
        />

        <div className={css`width: 100%;`}>
          <Button
            variant="outline"
            color="primary"
            style={{ marginLeft: 'auto', marginRight: '8px' }}
            onClick={handleSubmit}
          >
            Close
          </Button>
          <Button
            variant="contained"
            color="primary"
            onClick={handleSubmit}
          >
            Submit
          </Button>
        </div>

      </Box>
    </Modal>
  );

}