import React, { useState } from "react";
import { css } from "emotion";
import CircularProgress from "@material-ui/core/CircularProgress";
import { makeStyles } from "@material-ui/core/styles";
import MenuItem from "@material-ui/core/MenuItem";
import { ColorPicker } from "material-ui-color";
import FormControl from "@material-ui/core/FormControl";
import Select from "@material-ui/core/Select";
import { colors } from "../shared/styles";
import { Button } from "../shared/components/button";

const useStyles = makeStyles((theme) => ({
  formControl: {
    margin: theme.spacing(1),
    minWidth: 120,
  },
  selectEmpty: {
    marginTop: theme.spacing(2),
  },
  input: {
    minWidth: 300,
  },
  list: {
    width: "100%",
    maxWidth: 764,
  },
  listItem: {
    border: "1px solid black",
  },
  nested: {
    paddingLeft: theme.spacing(4),
    backgroundColor: "#f7f7f7",
  },
}));

export const fonts = [
  {
    Value: "'Alfa Slab One'",
    Label: "Alfa Slab One",
    URL: "https://fonts.googleapis.com/css2?family=Alfa+Slab+One&display=swap",
  },
  {
    Value: "'Alice'",
    Label: "Alice",
    URL: "https://fonts.googleapis.com/css2?family=Alice&display=swap",
  },
  {
    Value: "andale mono,times",
    Label: "Andale Mono",
    URL: "",
  },
  {
    Value: "arial,helvetica,sans-serif",
    Label: "Arial",
    URL: "",
  },
  {
    Value: "arial black,avant garde,arial",
    Label: "Arial Black",
    URL: "",
  },

  {
    Value: "'Assistant'",
    Label: "Assistant",
    URL:
      "https://fonts.googleapis.com/css2?family=Assistant:wght@400;700&display=swap",
  },

  {
    Label: "Barlow Semi Condensed",
    Value: "'Barlow Semi Condensed'",
    URL:
      "https://fonts.googleapis.com/css2?family=Barlow+Semi+Condensed:wght@300;400;500&display=swap",
  },
  {
    Label: "Benton Sans Bold",
    Value: "'Benton Sans Bold'",
    URL: "https://app.be-brite.com/bentonsans.css",
  },
  {
    Value: "book antiqua,palatino",
    Label: "Book Antiqua",
    URL: "",
  },
  {
    Value: "'Bree Serif'",
    Label: "Bree Serif",
    URL: "https://fonts.googleapis.com/css2?family=Bree+Serif&display=swap",
  },
  {
    Value: "'Cabin',sans-serif",
    Label: "Cabin",
    URL: "https://fonts.googleapis.com/css?family=Cabin:400,700",
  },
  {
    Value: "comic sans ms,sans-serif",
    Label: "Comic Sans MS",
    URL: "",
  },
  {
    Value: "courier new,courier",
    Label: "Courier New",
    URL: "",
  },
  {
    Value: "'Crimson Pro',serif",
    Label: "Crimson Pro",
    URL:
      "https://fonts.googleapis.com/css2?family=Crimson+Pro:wght@400;700&display=swap",
  },

  {
    Value: "'Crimson Text',serif",
    Label: "Crimson Text",
    URL: "https://fonts.googleapis.com/css?family=Crimson+Text:400,700",
  },

  {
    Value: "'Domine',serif",
    Label: "Domine",
    URL: "https://fonts.googleapis.com/css2?family=Domine&display=swap",
  },
  {
    Label: "DM Sans",
    Value: "'DM Sans', sans-serif",
    URL:
      "https://fonts.googleapis.com/css2?family=DM+Sans:wght@400;500;700&display=swap",
  },
  {
    Label: "Effra Heavy",
    Value: "effra-heavy",
    URL: "https://use.typekit.net/xnf6aih.css",
  },
  {
    Label: "Effra Light",
    Value: "'effra'",
    URL: "https://use.typekit.net/xnf6aih.css",
  },
  {
    Label: "Fraunces",
    Value: "'Fraunces', serif",
    URL:
      "https://fonts.googleapis.com/css2?family=Fraunces:wght@900&display=swap",
  },
  {
    Value: "georgia,palatino",
    Label: "Georgia",
    URL: "",
  },
  {
    Label: "Gotham Black",
    Value: "'Gotham Black'",
    URL:
      "https://storage.googleapis.com/brite-carrier-logos/logos/Gotham/gotham.css",
  },
  {
    Label: "Gotham Bold",
    Value: "'Gotham'",
    URL:
      "https://storage.googleapis.com/brite-carrier-logos/logos/Gotham/gotham.css",
  },
  {
    Label: "Gotham Book",
    Value: "'Gotham Book'",
    URL:
      "https://storage.googleapis.com/brite-carrier-logos/logos/Gotham/gotham.css",
  },
  {
    Label: "Gotham Light",
    Value: "'Gotham Light'",
    URL:
      "https://storage.googleapis.com/brite-carrier-logos/logos/Gotham/gotham.css",
  },
  {
    Label: "Gotham Medium",
    Value: "'Gotham Medium'",
    URL:
      "https://storage.googleapis.com/brite-carrier-logos/logos/Gotham/gotham.css",
  },
  {
    Label: "Gotham Narrow",
    Value: "'Gotham Narrow'",
    URL:
      "https://storage.googleapis.com/brite-carrier-logos/logos/Gotham/gotham.css",
  },
  {
    Label: "Gotham Narrow Book",
    Value: "'Gotham Narrow Book'",
    URL:
      "https://storage.googleapis.com/brite-carrier-logos/logos/Gotham/gotham.css",
  },
  {
    Label: "Gotham Narrow Light",
    Value: "'Gotham Narrow Light'",
    URL:
      "https://storage.googleapis.com/brite-carrier-logos/logos/Gotham/gotham.css",
  },
  {
    Label: "Gotham Narrow Medium",
    Value: "'Gotham Narrow Medium'",
    URL:
      "https://storage.googleapis.com/brite-carrier-logos/logos/Gotham/gotham.css",
  },
  {
    Label: "Gotham Thin",
    Value: "'Gotham Thin'",
    URL:
      "https://storage.googleapis.com/brite-carrier-logos/logos/Gotham/gotham.css",
  },
  {
    Label: "Gotham Ultra",
    Value: "'Gotham Ultra'",
    URL:
      "https://storage.googleapis.com/brite-carrier-logos/logos/Gotham/gotham.css",
  },
  {
    Label: "Gotham XLight",
    Value: "'Gotham XLight'",
    URL:
      "https://storage.googleapis.com/brite-carrier-logos/logos/Gotham/gotham.css",
  },
  {
    Label: "Graduate",
    Value: "'Graduate'",
    URL: "https://fonts.googleapis.com/css2?family=Graduate&display=swap",
  },
  {
    Label: "Graphik",
    Value: "'Graphik'",
    URL: "https://storage.googleapis.com/brite-carrier-logos/logos/fonts.css",
  },
  {
    Value: "helvetica,sans-serif",
    Label: "Helvetica",
    URL: "",
  },
  {
    Value: "'Helvetica Neue'",
    Label: "Helvetica Neue",
    URL:
      "https://storage.googleapis.com/brite-carrier-logos/fonts/helvetica.css",
  },
  {
    Value: "impact,chicago",
    Label: "Impact",
    URL: "",
  },
  {
    Value: "'Inter'",
    Label: "Inter",
    URL: "https://fonts.googleapis.com/css2?family=Inter&display=swap",
  },
  {
    Value: "'Irish Grover'",
    Label: "Irish Grover",
    URL: "https://fonts.googleapis.com/css2?family=Irish+Grover&display=swap",
  },
  {
    Value: "'Kanit'",
    Label: "Kanit",
    URL: "https://fonts.googleapis.com/css2?family=Kanit",
  },
  {
    Value: "'Karla'",
    Label: "Karla",
    URL: "https://fonts.googleapis.com/css2?family=Karla&display=swap",
  },
  {
    Label: "lato",
    Value: "'lato'",
    URL: "https://use.typekit.net/xnf6aih.css",
  },
  {
    Value: "'Lato',sans-serif",
    Label: "Lato",
    URL: "https://fonts.googleapis.com/css?family=Lato:400,700",
  },

  {
    Value: "'League Spartan'",
    Label: "League Spartan",
    URL:
      "https://fonts.googleapis.com/css2?family=League+Spartan:wght@400;700&display=swap",
  },

  {
    Value: "'Loved by the King',cursive",
    Label: "Loved by the King",
    URL:
      "https://fonts.googleapis.com/css2?family=Loved+by+the+King&display=swap",
  },
  {
    Value: "'Lobster Two',cursive",
    Label: "Lobster Two",
    URL: "https://fonts.googleapis.com/css?family=Lobster+Two:400,700",
  },
  {
    Label: "Lora",
    Value: "'Lora',serif",
    URL:
      "https://fonts.googleapis.com/css2?family=Lora:wght@400;700&display=swap",
  },
  {
    Label: "Merriweather",
    Value: "'Merriweather'",
    URL:
      "https://fonts.googleapis.com/css2?family=Merriweather:wght@400;900&display=swap",
  },
  {
    Value: "'Montserrat',sans-serif",
    Label: "Montserrat",
    URL: "https://fonts.googleapis.com/css?family=Montserrat:400,700",
  },

  {
    Value: "'Mulish'",
    Label: "Mulish",
    URL:
      "https://fonts.googleapis.com/css2?family=Mulish:wght@400;700&display=swap",
  },

  {
    Value: "'Nunito Sans',sans-serif",
    Label: "Nunito Sans",
    URL:
      "https://fonts.googleapis.com/css2?family=Nunito+Sans:wght@400;700&display=swap",
  },
  {
    Value: "'Old Standard TT',serif",
    Label: "Old Standard TT",
    URL: "https://fonts.googleapis.com/css?family=Old+Standard+TT:400,700",
  },
  {
    Value: "'Open Sans',sans-serif",
    Label: "Open Sans",
    URL: "https://fonts.googleapis.com/css?family=Open+Sans:400,700",
  },
  {
    Label: "Oswald",
    Value: "'Oswald', sans-serif",
    URL: "https://fonts.googleapis.com/css2?family=Oswald&display=swap",
  },
  {
    Label: "Oxygen",
    Value: "'Oxygen', sans-serif",
    URL: "https://fonts.googleapis.com/css2?family=Oxygen&display=swap",
  },

  {
    Value: "'Pacifico',cursive",
    Label: "Pacifico",
    URL: "https://fonts.googleapis.com/css?family=Pacifico",
  },
  {
    Value: "'Playfair Display',serif",
    Label: "Playfair Display",
    URL: "https://fonts.googleapis.com/css?family=Playfair+Display:400,700",
  },

  {
    Label: "Poppins Normal",
    Value: "'poppins', sans-serif",
    URL: "https://fonts.googleapis.com/css2?family=Poppins&display=swap",
  },
  {
    Label: "Poppins",
    Value: "'poppins'",
    URL:
      "https://fonts.googleapis.com/css2?family=Poppins:wght@700&display=swap",
  },
  {
    Label: "PT Sans",
    Value: "'PT Sans'",
    URL:
      "https://fonts.googleapis.com/css2?family=PT+Sans:wght@400;700&display=swap",
  },
  {
    Label: "Proxima Nova",
    Value: "'proxima-nova'",
    URL: "https://use.typekit.net/xnf6aih.css",
  },

  {
    Label: "Public Sans",
    Value: "'Public Sans'",
    URL: "https://fonts.googleapis.com/css2?family=Public+Sans&display=swap",
  },

  {
    Label: "Quicksand",
    Value: "'Quicksand'",
    URL:
      "https://fonts.googleapis.com/css2?family=Quicksand:wght@400;700&display=swap",
  },
  {
    Value: "'Raleway',sans-serif",
    Label: "Raleway",
    URL: "https://fonts.googleapis.com/css?family=Raleway:400,700",
  },
  {
    Label: "Roboto",
    Value: "'Roboto', sans-serif",
    URL:
      "https://fonts.googleapis.com/css2?family=Roboto:wght@400;500;700;&display=swap",
  },
  {
    Value: "'Rubik',sans-serif",
    Label: "Rubik",
    URL: "https://fonts.googleapis.com/css?family=Rubik:400,700",
  },
  {
    Label: "Source Sans Pro",
    Value: "'Source Sans Pro', sans-serif",
    URL:
      "https://fonts.googleapis.com/css2?family=Source+Sans+Pro:wght@400;700;900&display=swap",
  },
  {
    Value: "'Source Sans Pro',sans-serif",
    Label: "Source Sans Pro",
    URL: "https://fonts.googleapis.com/css?family=Source+Sans+Pro:400,700",
  },
  {
    Value: "symbol",
    Label: "Symbol",
    URL: "",
  },
  {
    Value: "tahoma,arial,helvetica,sans-serif",
    Label: "Tahoma",
    URL: "",
  },
  {
    Value: "terminal,monaco",
    Label: "Terminal",
    URL: "",
  },
  {
    Value: "times new roman,times",
    Label: "Times New Roman",
    URL: "",
  },
  {
    Label: "Titillium Web",
    Value: "'Titillium Web', sans-serif",
    URL:
      "https://fonts.googleapis.com/css2?family=Titillium+Web:wght@200;400;600&display=swap",
  },
  {
    Value: "trebuchet ms,geneva",
    Label: "Trebuchet MS",
    URL: "",
  },
  {
    Value: "verdana,geneva",
    Label: "Verdana",
    URL: "",
  },
  {
    Label: "Zen Old Mincho",
    Value: "'Zen Old Mincho', serif",
    URL:
      "https://fonts.googleapis.com/css2?family=Zen+Old+Mincho:wght@900&display=swap",
  },
];

const fontSizes = [
  8,
  10,
  12,
  14,
  16,
  18,
  20,
  22,
  24,
  26,
  28,
  30,
  32,
  34,
  36,
  38,
  40,
  44,
  48,
  72,
];

const fontTypesMap = {
  bodyFont: {
    property: "Body",
    display: "Body",
    fallbackSize: 14,
    fontProperty: "Font",
  },
  headingFont1: {
    property: "Heading",
    display: "Header 1",
    fallbackSize: 22,
    fontProperty: "FontOverride",
  },
  headingFont2: {
    property: "Heading2",
    display: "Header 2",
    fallbackSize: 20,
    fontProperty: "FontOverride",
  },
  headingFont3: {
    property: "Heading3",
    display: "Header 3",
    fallbackSize: 18,
    fontProperty: "FontOverride",
  },
  headingFont4: {
    property: "Heading4",
    display: "Header 4",
    fallbackSize: 16,
    fontProperty: "FontOverride",
  },
};

export const Theme = ({ theme, setTheme }) => {
  const classes = useStyles();

  const [tab, setTab] = useState("colors");

  if (!theme) {
    return (
      <div
        className={css`
          display: flex;
          justify-content: center;
          margin: 48px;
        `}
      >
        <CircularProgress />
      </div>
    );
  }

  const Font = ({ property, fontProperty, display, fallbackSize }) => {
    const fallbackHeader = theme?.Heading?.FontOverride?.Value || null;

    return (
      <div>
        <div
          className={css`
            display: flex;
            justify-content: space-between;
            align-items: center;
            margin: 0 12px;
          `}
        >
          <div className={css``}>{display} Font</div>
          <div>
            <FormControl variant="outlined" className={classes.formControl}>
              <Select
                style={{ width: "200px", height: "40px" }}
                labelId="font"
                id="font-type"
                value={
                  theme?.[property]?.[fontProperty]?.Value || fallbackHeader
                }
                onChange={(e) => {
                  let label = "";
                  let fontUrl = "";
                  for (let font of fonts) {
                    if (font.Value === e.target.value) {
                      label = font.Label;
                      fontUrl = font.URL;
                    }
                  }
                  if (
                    property !== "Body" &&
                    e.target.value === fallbackHeader
                  ) {
                    setTheme({
                      ...theme,
                      [property]: {
                        ...(theme[property] || {}),
                        [fontProperty]: null,
                      },
                    });
                  } else {
                    setTheme({
                      ...theme,
                      [property]: {
                        ...(theme[property] || {}),
                        [fontProperty]: {
                          Value: e.target.value,
                          Label: label,
                          URL: fontUrl,
                        },
                      },
                    });
                  }
                }}
              >
                {fonts.map((font) => {
                  return (
                    <MenuItem key={font.Value} value={font.Value}>
                      {font.Label}
                    </MenuItem>
                  );
                })}
              </Select>
            </FormControl>
          </div>
        </div>
        <div
          className={css`
            display: flex;
            justify-content: space-between;
            align-items: center;
            margin: 0 12px;
          `}
        >
          <div className={css``}>{display} Size</div>
          <div>
            <FormControl variant="outlined" className={classes.formControl}>
              <Select
                style={{ width: "200px", height: "40px" }}
                labelId="font"
                id="font-type"
                value={theme?.[property]?.FontSize || fallbackSize}
                onChange={(e) => {
                  const FontSize =
                    e.target.value === fallbackSize ? null : e.target.value;
                  setTheme({
                    ...theme,
                    [property]: {
                      ...(theme[property] || {}),
                      FontSize,
                    },
                  });
                }}
              >
                {fontSizes.map((fontSize) => {
                  return (
                    <MenuItem key={fontSize} value={fontSize}>
                      {`${fontSize}px`}
                    </MenuItem>
                  );
                })}
              </Select>
            </FormControl>
          </div>
        </div>
      </div>
    );
  };

  return (
    <div className="theme-container">
      <div
        className={css`
          display: flex;
          justify-content: center;
          align-items: center;
          border-bottom: 1px solid ${colors.gray[400]};
        `}
      >
        <Button
          styles={tab === "colors" ? "tab-active" : "tab-inactive"}
          className={css`
            width: 50%;
          `}
          onClick={() => setTab("colors")}
        >
          Colors
        </Button>
        <Button
          styles={tab === "fonts" ? "tab-active" : "tab-inactive"}
          className={css`
            width: 50%;
          `}
          onClick={() => setTab("fonts")}
        >
          Fonts
        </Button>
      </div>
      {tab === "colors" && (
        <div>
          <div
            className={css`
              display: flex;
              justify-content: space-between;
              align-items: center;
              margin: 12px;
            `}
          >
            <div className={css``}>Body Color</div>
            <ColorPicker
              disablePlainColor={true} // this makes it so we always send the color's hex code
              disableAlpha={true}
              value={theme.Body.FontColor}
              onChange={(val) => {
                setTheme({
                  ...theme,
                  Body: {
                    ...theme.Body,
                    FontColor: val,
                  },
                });
              }}
            />
          </div>
          <div
            className={css`
              display: flex;
              justify-content: space-between;
              align-items: center;
              margin: 12px;
            `}
          >
            <div className={css``}>Page Background Color</div>
            <ColorPicker
              disablePlainColor={true}
              disableAlpha={true}
              value={theme.Body.BackgroundColor}
              onChange={(val) => {
                setTheme({
                  ...theme,
                  Body: {
                    ...theme.Body,
                    BackgroundColor: val,
                  },
                });
              }}
            />
          </div>
          <div
            className={css`
              display: flex;
              justify-content: space-between;
              align-items: center;
              margin: 12px;
            `}
          >
            <div className={css``}>Accent Color</div>
            <ColorPicker
              disablePlainColor={true}
              disableAlpha={true}
              value={theme.Body.AccentColor}
              onChange={(val) => {
                setTheme({
                  ...theme,
                  Body: {
                    ...theme.Body,
                    AccentColor: val,
                  },
                });
              }}
            />
          </div>
          <div
            className={css`
              display: flex;
              justify-content: space-between;
              align-items: center;
              margin: 12px;
            `}
          >
            <div className={css``}>Button Color</div>
            <ColorPicker
              disablePlainColor={true}
              disableAlpha={true}
              value={theme.Body.ButtonColor}
              onChange={(val) => {
                setTheme({
                  ...theme,
                  Body: {
                    ...theme.Body,
                    ButtonColor: val,
                  },
                });
              }}
            />
          </div>
          <div
            className={css`
              display: flex;
              justify-content: space-between;
              align-items: center;
              margin: 12px;
            `}
          >
            <div className={css``}>Header 1 Color</div>
            <ColorPicker
              disablePlainColor={true}
              disableAlpha={true}
              value={theme.Heading.FontColorOverride}
              onChange={(val) => {
                console.log(val);
                setTheme({
                  ...theme,
                  Heading: {
                    ...theme.Heading,
                    FontColorOverride: val,
                  },
                });
              }}
            />
          </div>
          <div
            className={css`
              display: flex;
              justify-content: space-between;
              align-items: center;
              margin: 12px;
            `}
          >
            <div className={css``}>Header 2 Color</div>
            <ColorPicker
              disablePlainColor={true}
              disableAlpha={true}
              value={
                theme?.Heading2?.FontColorOverride ||
                theme.Heading.FontColorOverride
              }
              onChange={(val) => {
                setTheme({
                  ...theme,
                  Heading2: {
                    ...theme.Heading2,
                    FontColorOverride: val,
                  },
                });
              }}
            />
          </div>
          <div
            className={css`
              display: flex;
              justify-content: space-between;
              align-items: center;
              margin: 12px;
            `}
          >
            <div className={css``}>Header 3 Color</div>
            <ColorPicker
              disablePlainColor={true}
              disableAlpha={true}
              value={
                theme?.Heading3?.FontColorOverride ||
                theme.Heading.FontColorOverride
              }
              onChange={(val) => {
                setTheme({
                  ...theme,
                  Heading3: {
                    ...theme.Heading3,
                    FontColorOverride: val,
                  },
                });
              }}
            />
          </div>
          <div
            className={css`
              display: flex;
              justify-content: space-between;
              align-items: center;
              margin: 12px;
            `}
          >
            <div className={css``}>Header 4 Color</div>
            <ColorPicker
              disablePlainColor={true}
              disableAlpha={true}
              value={
                theme?.Heading4?.FontColorOverride ||
                theme.Heading.FontColorOverride
              }
              onChange={(val) => {
                setTheme({
                  ...theme,
                  Heading4: {
                    ...theme.Heading4,
                    FontColorOverride: val,
                  },
                });
              }}
            />
          </div>
        </div>
      )}

      {tab === "fonts" && (
        <div>
          {Object.entries(fontTypesMap).map(([key, props]) => (
            <Font {...props} key={key} />
          ))}
        </div>
      )}
    </div>
  );
};
