import React, { useEffect, useState } from "react";
import { Auth0Provider } from "./Auth/react-auth0-wrapper";
import { AUTH_CONFIG } from "./Auth/auth0-variables";
import { AppRouter } from "./Router/AppRouter";
import { RedirectToCourseViewer } from "./redirect-to-course-viewer";
import { css } from "emotion";
import { ErrorBoundary } from "react-error-boundary";
import { ErrorFallback } from "./error-fallback";
import { KnockProvider } from "@knocklabs/react";
import { useStore } from "./store-provider/use-store";
import { CustomAxios } from "./redux/axios/axios";

const onRedirectCallback = (appState) => {
  window.history.replaceState(
    {},
    document.title,
    appState && appState.targetUrl
      ? appState.targetUrl
      : window.location.pathname
  );
};

// old employee course view url is structured like https://app.be-brite.com/view/{course-id}
export function AppDecider(props) {
  let path = window.location.pathname;
  if (path.includes("view")) {
    return (
      <div
        className={css`
          background: white;
          height: 100%;
        `}
      >
        <div
          className={css`
            position: relative;
            height: 100%;
            display: flex;
            justify-content: center;
            margin: 40px;
            font-weight: bold;
          `}
        >
          <RedirectToCourseViewer />
        </div>
      </div>
    );
  } else {
    return (
      <ErrorBoundary FallbackComponent={ErrorFallback}>
        <Auth0Provider
          store={props.store}
          audience="http://api.be-brite.com"
          domain={AUTH_CONFIG.domain}
          client_id={AUTH_CONFIG.clientId}
          redirect_uri={AUTH_CONFIG.callbackUrl}
          onRedirectCallback={onRedirectCallback}
          useRefreshTokens={true}
        >
          <BriteKnockProvider>
            <AppRouter />
          </BriteKnockProvider>
        </Auth0Provider>
      </ErrorBoundary>
    );
  }
}

const BriteKnockProvider = ({ children }) => {
  const {
    data: { user },
  } = useStore();

  const [token, setToken] = useState("");

  const fetchKnockToken = async () => {
    try {
      const response = await CustomAxios.get("/v1/notifications/token");
      setToken(response?.data?.token);
    } catch (err) {
      setToken("");
    }
  };

  useEffect(() => {
    if (user?.ID) {
      fetchKnockToken();
    }
  }, [user?.ID]);

  return (
    <KnockProvider
      apiKey={window._env_.KNOCK_API_KEY}
      userId={user?.ID}
      userToken={token}
      onUserTokenExpiring={fetchKnockToken}
    >
      {children}
    </KnockProvider>
  );
};
