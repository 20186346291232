import { Box } from "../../shared/components/box";
import { UserAvatar } from "../../shared/components/UserAvatar";

export const ActorIcons = ({ actors, size = 48 }) => {
  const getUserFromActor = (actor) => {
    const nameList = actor?.name?.split(" ");
    const FirstName = nameList[0];
    const LastName = nameList[1];
    return {
      FirstName,
      LastName,
      Preferences: actor?.Preferences,
      Email: actor?.email,
    };
  };

  return (
    <Box
      flex="left"
      css={`
        position: relative;
        width: ${size + actors?.length * 16 - 15}px;
        height: ${size}px;
      `}
    >
      {actors?.map((item, idx) => (
        <Box
          flex="center"
          css={`
            position: absolute;
            top: 0;
            left: ${idx * 16}px;
          `}
        >
          <UserAvatar size={size} user={getUserFromActor(item)} />
        </Box>
      ))}
    </Box>
  );
};

export function stringToMutedColorHex(str) {
  if (!str) {
    return "#000000";
  }
  // Generate a hash from the string
  let hash = 0;
  for (let i = 0; i < str.length; i++) {
    hash = str.charCodeAt(i) + ((hash << 5) - hash);
  }

  // Convert hash to an RGB color
  let color = "#";
  for (let i = 0; i < 3; i++) {
    const value = (hash >> (i * 8)) & 0xff;
    color += ("00" + value.toString(16)).substr(-2);
  }

  // Convert hex color to RGB
  const rgb = /^#?([a-f\d]{2})([a-f\d]{2})([a-f\d]{2})$/i.exec(color);
  const r = parseInt(rgb[1], 16);
  const g = parseInt(rgb[2], 16);
  const b = parseInt(rgb[3], 16);

  const average = (r + g + b) / 3;

  // Calculate muted color
  const mutedR = Math.floor((r + average) / 2);
  const mutedG = Math.floor((g + average) / 2);
  const mutedB = Math.floor((b + average) / 2);

  // Convert muted RGB color to hex
  const toHex = (value) => {
    const hex = value.toString(16);
    return hex.length === 1 ? "0" + hex : hex;
  };

  const mutedColorHex = `#${toHex(mutedR)}${toHex(mutedG)}${toHex(mutedB)}`;

  return mutedColorHex;
}
