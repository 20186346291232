import { useState } from "react";
import { toast } from "react-toastify";
import { CustomAxios } from "../redux/axios/axios";
import { Button, Input, Modal, Text } from "../shared/components";

const DEFAULT_FOLDER_NAME = "Untitled Folder";

export const NewFolderModal = ({ display, hideNewFolder, fetchFolders }) => {
  const [newFolderName, setNewFolderName] = useState(DEFAULT_FOLDER_NAME);

  const createNewFolder = async () => {
    try {
      hideNewFolder();
      await CustomAxios.post("/v1/course/folder", { Name: newFolderName });
      fetchFolders();
    } catch (e) {
      hideNewFolder();
      toast.error("Error creating folder.");
    }
  };

  const modalProps = {
    display,
    onClose: () => hideNewFolder(),
  };

  return (
    <Modal {...modalProps}>
      <Modal.Paper>
        <Modal.Header title="New Folder" onClose={modalProps.onClose} />
        <Modal.Body>
          <Text
            label
            css={`
              font-size: 14px;
              font-weight: 700;
              margin-bottom: 4px;
            `}
          >
            Folder Name
          </Text>
          <Input
            onChange={(event) => setNewFolderName(event.target.value)}
            onFocus={(event) => {
              // Select the whole default value since we want them to overwrite it all.
              event.target.select();
            }}
            value={newFolderName}
          />
        </Modal.Body>
        <Modal.Actions>
          <Button secondary onClick={() => hideNewFolder()}>
            Cancel
          </Button>
          <Button
            primary
            disabled={!newFolderName}
            onClick={() => createNewFolder()}
          >
            Create
          </Button>
        </Modal.Actions>
      </Modal.Paper>
    </Modal>
  );
};
