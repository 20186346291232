import { MenuItem } from "@material-ui/core";
import { css, cx } from "emotion";
import {
  CurrencyDollarSimple,
  DotsThreeVertical,
  EyeSlash,
  Percent,
} from "phosphor-react";
import {
  getTierStructure,
  tierStructures,
  useDisplaySettings,
} from "../../BriteEditor/editor-components/benefits/plan-comparisons/use-display-settings";
import { Button, Div, DropMenu, Select, Text } from "../../shared/components";
import { flex, px } from "../../shared/shared-styles";
import { colors, mediaQueryFn } from "../../shared/styles";
import { FieldInput, getStoredValue } from "./field-input";
import { fieldContainerStyle } from "./field";
import { defaultCost } from "../product-layout-constants";
import { set } from "lodash";
import { useState } from "react";
import { getFieldProps } from "./field-utils";
import { EditLayoutButton } from "./layout-container";
import { productModifier } from "../configs/product-utils";
import { useFeatureFlagPayload } from "posthog-js/react";
import { useAcls } from "../../shared/use-acls";
import { WRITE_BENEFITS_PACKAGE } from "../../shared/acl-constants";
import { Flag } from "../flags/flag";
import { needsAttention } from "../../react-query/resources/needs-attention";

const tabContainer = (selected) => css`
  display: flex;
  justify-content: center;
  align-items: center;
  border: 1px solid ${colors.gray[400]};
  border-radius: 8px;
  cursor: pointer;
  padding: 8px 16px;
  width: calc(50% - 4px);
  box-sizing: border-box;
  :hover {
    ${selected && `border: 1px solid ${colors.black};`}
  }
  ${selected &&
  `
  padding: 7px 15px;
  background-color: #F5F7FA;
  border: 2px solid black;
`}
`;

export const Cost = ({ state, field, config }) => {
  const costTiersFF = useFeatureFlagPayload("tier-structure");
  const { product, layout, businessId, packageId } = state;

  const premiumsSetExternally = product?.Details?.PlanType?.startsWith("aca");

  const { value, updateProperty, updateLayout } = getFieldProps(
    state,
    field,
    (config = {})
  );
  const canEdit = useAcls([WRITE_BENEFITS_PACKAGE]);
  config.disabled = config.disabled || !canEdit;

  const [stateSyncedAt, setStateSyncedAt] = useState("");

  const hideEmployerContributions = layout?.HideEmployerPremiumContributions;
  const cost = value || defaultCost;
  const { displaySettings } = useDisplaySettings(businessId, packageId);
  const tiers = getTierStructure({
    featureFlag: costTiersFF?.value,
    cost: product?.Cost,
    displaySettings,
  });
  const contributionType = cost?.Contributions?.ContributionType || "$";
  const contributionFieldType =
    contributionType === "$"
      ? "dollar"
      : contributionType === "%"
      ? "percent"
      : "";

  const handlePasteValue = (fieldLayout, value) => {
    try {
      let updates = { ...cost };
      const property = fieldLayout?.PropertyChain?.replace("Cost.", "");
      const nextValue = getStoredValue(fieldLayout, value);
      set(updates, property, nextValue);

      const dollarIndex = value?.indexOf("$");
      const percentIndex = value?.indexOf("%");

      if (percentIndex > dollarIndex) {
        set(updates, "Contributions.ContributionType", "%");
      } else if (dollarIndex > percentIndex) {
        set(updates, "Contributions.ContributionType", "$");
      }

      updateProperty("Cost", updates);
      setStateSyncedAt(new Date().toISOString());
    } catch (err) {
      console.log(err);
    }
  };

  const updateHideEmployerContributions = (value) => {
    productModifier.setLayout(state, (productLayout) => {
      return {
        ...productLayout,
        HideEmployerPremiumContributions: value,
      };
    });
  };

  const setTierIndex = (idx) => {
    productModifier?.setProduct(state, (p) => ({
      ...p,
      Cost: {
        ...p?.Cost,
        Contributions: {
          ...p?.Cost?.Contributions,
          MonthlyContributions: {
            ...p?.Cost?.Contributions?.MonthlyContributions,
            Tiers: [...tierStructures[idx - 2]],
          },
        },
        TotalMonthlyPremiums: {
          ...p?.Cost?.TotalMonthlyPremiums,
          Tiers: [...tierStructures[idx - 2]],
        },
      },
    }));
  };

  const getFlag = (property) => {
    return needsAttention.utils.getFlagByPropertyChain(state?.flags, property);
  };

  return (
    <Div
      css={css`
        width: 100%;
      `}
    >
      {costTiersFF?.value ? (
        <Div
          css={css`
            ${flex("space-between")}
          `}
        >
          <Text label>Tier Rate Structure</Text>
          <Select
            value={tiers?.tierStructure}
            disabled={config?.disabled}
            onChange={(e) => setTierIndex(e.target.value)}
            css={`
              width: 50%;
            `}
          >
            {Array.apply(null, Array(4))?.map((_, index) => (
              <MenuItem value={index + 2}>
                {index + 2} Tier{index === 2 ? " (Standard)" : ""}
              </MenuItem>
            ))}
          </Select>
        </Div>
      ) : null}

      {!premiumsSetExternally && (
        <>
          <Div
            css={css`
              ${flex("space-between")}
            `}
          >
            <Text
              styles="h4"
              className={css`
                margin: ${px.lg} 0;
                margin-top: ${px.xl};
              `}
            >
              Total Monthly Premiums
            </Text>
            <EditLayoutButton
              field={field}
              updateLayout={updateLayout}
              hidden={["highlight", "edit", "flag"]}
            />
          </Div>
          {tiers?.keys?.map((tier, idx) => (
            <Div css={cx(fieldContainerStyle())} key={tier}>
              <Text styles="label" className="label">
                {tiers?.labels?.[tier]}
              </Text>
              <div
                className={css`
                  width: 50%;
                  margin-right: -8px;
                `}
              >
                <div
                  className={css`
                    ${flex("right")}
                    .input-field {
                      flex-grow: 1;
                    }
                  `}
                >
                  <FieldInput
                    className="input-field"
                    field={{
                      Type: "dollar",
                      PropertyChain: `Cost.TotalMonthlyPremiums.${tier}`,
                      productId: product?.ID,
                    }}
                    config={{
                      storeValueAs: "number",
                      roundUp: false,
                    }}
                    value={cost?.TotalMonthlyPremiums?.[tier]}
                    updateProperty={updateProperty}
                  />
                  <EditLayoutButton
                    state={state}
                    field={{
                      ...field,
                      PropertyChain: `Cost.TotalMonthlyPremiums.${tier}`,
                    }}
                    updateLayout={updateLayout}
                    hidden={["highlight", "show", "hide"]}
                  />
                </div>
                <Flag flag={getFlag(`Cost.TotalMonthlyPremiums.${tier}`)} />
              </div>
            </Div>
          ))}
        </>
      )}
      <Div
        css={css`
          ${flex("space-between")}
        `}
      >
        <Text
          styles="h4"
          className={css`
            margin: ${px.lg} 0;
            margin-top: ${px.xl};
          `}
        >
          Employer Contributions
        </Text>

        <DropMenu
          anchorOrigin={{ horizontal: "right" }}
          transformOrigin={{ horizontal: 290 }}
          button={
            <Button styles="icon sm">
              {hideEmployerContributions ? (
                <EyeSlash size={24} />
              ) : (
                <DotsThreeVertical size={24} />
              )}
            </Button>
          }
        >
          <MenuItem onClick={() => updateHideEmployerContributions(false)}>
            Show Employer Contributions
          </MenuItem>
          <MenuItem onClick={() => updateHideEmployerContributions(true)}>
            Hide Employer Contributions
          </MenuItem>
        </DropMenu>
      </Div>

      <Div css={fieldContainerStyle()}>
        <Text styles="label">Contribution Type</Text>
        <Div
          className={css`
            ${flex("space-between")} width: 50%;
            ${mediaQueryFn("less-than", "1000px")} {
              width: 100%;
            }
          `}
        >
          <Div
            className={tabContainer(contributionType === "$")}
            disabled={config?.disabled}
            onClick={() =>
              !config?.disabled &&
              updateProperty(`Cost.Contributions.ContributionType`, "$")
            }
            tabIndex="-1"
          >
            <CurrencyDollarSimple
              color={contributionType === "$" ? "black" : "currentColor"}
              tabIndex="-1"
            />
            <Text
              color={contributionType === "$" ? colors.black : "initial"}
              tabIndex="-1"
            >
              Dollars
            </Text>
          </Div>
          <Div
            tabIndex="-1"
            className={tabContainer(contributionType === "%")}
            disabled={config?.disabled}
            onClick={() =>
              !config?.disabled &&
              updateProperty(`Cost.Contributions.ContributionType`, "%")
            }
          >
            <Percent
              tabIndex="-1"
              color={contributionType === "%" ? "black" : "currentColor"}
            />
            <Text
              color={contributionType === "%" ? colors.black : "initial"}
              tabIndex="-1"
            >
              Percent
            </Text>
          </Div>
        </Div>
      </Div>

      {tiers?.keys?.map((tier) => (
        <Div
          css={cx(
            fieldContainerStyle(),
            css`
              .input-field {
                width: 50%;
              }
            `
          )}
          key={tier}
        >
          <Text styles="label" className="label">
            {tiers?.labels?.[tier]}
          </Text>
          <div
            className={css`
              width: 50%;
              margin-right: -8px;
            `}
          >
            <div
              className={css`
                ${flex("right")}
                .input-field {
                  flex-grow: 1;
                }
              `}
            >
              <FieldInput
                className="input-field"
                field={{
                  PropertyChain: `Cost.Contributions.MonthlyContributions.${tier}`,
                  Type: contributionFieldType,
                  productId: product?.ID,
                }}
                config={{
                  storeValueAs: "number",
                  roundUp: false,
                }}
                stateSyncedAt={stateSyncedAt}
                handlePasteValue={handlePasteValue}
                value={cost?.Contributions?.MonthlyContributions?.[tier]}
                updateProperty={updateProperty}
              />
              <EditLayoutButton
                state={state}
                field={{
                  ...field,
                  State: hideEmployerContributions ? "hide" : "show",
                  PropertyChain: `Cost.Contributions.MonthlyContributions.${tier}`,
                }}
                updateLayout={updateLayout}
                hidden={["highlight", "show", "hide"]}
              />
            </div>
            <Flag
              flag={getFlag(`Cost.Contributions.MonthlyContributions.${tier}`)}
            />
          </div>
        </Div>
      ))}
    </Div>
  );
};
