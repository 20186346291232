import { css } from "emotion";
import { MagnifyingGlass, Package } from "phosphor-react";
import { useMemo, useState } from "react";
import { useQueryAPI } from "../../react-query/query-api";
import { Div, Input, NewTag, Text } from "../../shared/components";
import { container, flex, scrollbar } from "../../shared/shared-styles";
import { colors } from "../../shared/styles";
import { benefitProductsSelector } from "../benefit-packages-page";

export const SelectBenefitPackage = ({ packageId, setPackageId }) => {
  const packageQuery = useQueryAPI({
    url: `v1/benefitspackage`,
    select: benefitProductsSelector,
  });
  const { data } = packageQuery;

  const [search, setSearch] = useState("");
  const [modal, setModal] = useState("");

  const list = useMemo(() => {
    if (!search) {
      return data;
    }
    return data?.filter(({ Name }) =>
      Name?.toLowerCase().includes(search?.toLowerCase())
    );
  }, [data, search]);

  return (
    <>
      <Div
        css={css`
          width: 100%;
        `}
      >
        <Text
          h2
          css={`
            text-align: center;
          `}
        >
          Select a Benefit Package to sync with your guide
        </Text>
        <Div
          css={css`
            ${flex("left")}
            margin-top: 56px;
            margin-bottom: 24px;
          `}
        >
          <Input
            startIcon={<MagnifyingGlass />}
            placeholder="Search for a Benefit Package"
            css={`
              flex-grow: 1;
              margin-right: 16px;
            `}
            value={search}
            onChange={(e) => setSearch(e.target.value)}
          />
        </Div>

        <Div
          css={css`
            height: calc(100vh - 340px);
            overflow: auto;
            padding: 0 8px;
            ${scrollbar.hide}
          `}
        >
          {list?.map((item) => (
            <Div
              css={css`
                width: 100%;
                border: 1px solid ${colors.gray[300]};
                ${item?.ID === packageId
                  ? `outline: 2px solid ${colors.black}; background-color: ${colors.gray[100]};`
                  : ``}
                border-radius: 8px;
                padding: 12px;
                margin: 24px 0;
                ${flex("space-between")}
                ${container.hover}
              `}
              onClick={() => setPackageId(item?.ID)}
            >
              <Div
                css={css`
                  ${flex("left")}
                `}
              >
                <Div
                  css={css`
                    width: 56px;
                    height: 56px;
                    border-radius: 8px;
                    margin-right: 16px;
                    ${item?.ID === packageId
                      ? `
                      background-color: ${colors.black};
                      color: white;
                    `
                      : `
                      color: ${colors.gray[500]};
                      border: 1px solid ${colors.gray[300]};
                    `}

                    ${flex("center")}
                  `}
                >
                  <Package
                    color={item?.ID === packageId ? "#FFFFFF" : colors.black}
                  />
                </Div>
                <Text h4>{item?.Name}</Text>
              </Div>
              <Div
                css={css`
                  ${flex("right")}
                `}
              >
                <NewTag createdAt={item?.CreatedAt} />
              </Div>
            </Div>
          ))}
        </Div>
      </Div>
    </>
  );
};
