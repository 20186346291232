import { useContext, useEffect, useMemo, useRef, useState } from "react";
import { css } from "emotion";
import { Div } from "../../shared/components/index.js";
import { colors } from "../../shared/styles.js";
import {
  Image,
  Space,
  Button,
  Document,
  Video,
  Website,
  Benefits,
  OptimizedText,
  ContentBlock,
} from "../editor-components/index.js";
import { editorUtils, modifyContent } from "../provider/utils.js";
import { Toolbar, ContextMenu } from "../toolbar-menus/index.js";
import { disableDragComponents } from "../brite-editor.js";
import { DevBlock } from "../editor-components/dev-block.js";
import { TextV2 } from "../editor-components/text-v2/text-v2.js";
import { useBounds } from "../../shared/use-bounds.js";
import { EditorDetailsContext } from "../provider/editor-detail-provider.js";
import { DevProfiler } from "../../shared/components/dev-profiler.js";
import { useStore } from "../../store-provider/use-store.js";
import { useSearchParams } from "../../shared/use-search-params.js";
import uuid from "uuid";
import { Tooltip } from "@material-ui/core";
import { animation, flex } from "../../shared/shared-styles.js";
import { useFeatureFlagPayload } from "posthog-js/react";
import { Chat } from "phosphor-react";
import { CreateComment } from "../toolbar-menus/create-comment.js";
import { useDeepCompareMemo } from "use-deep-compare";
import { PlaceholderElement } from "../editor-components/PlaceholderElement";
import { Accordion } from "../editor-components/accordion/Accordion.jsx";

const containerStyles = (
  isSelected,
  isDragging,
  isDragSource,
  devMode,
  comments
) => css`
  scroll-margin-top: 10vh;
  position: relative;
  width: 100%;
  border-radius: 8px;
  height: auto;
  cursor: pointer;
  transition: all 0.25s ease;
  display: flex;
  align-items: stretch;
  height: 100%;
  transition: outline 0.5s ease;

  ${isSelected && devMode ? `margin-top: -2px; margin-bottom: 2px;` : ``}

  .selected-outline {
    top: 0px;
    left: 0px;
    right: 0px;
    bottom: 0px;
    border-radius: 8px;
    position: absolute;
    outline: 1px solid transparent;
    ${isDragging &&
    `
      pointer-events: none;
      border-radius: 8px;
      outline: 1px dashed ${colors.gray[300]};
      transition: outline 1s ease;
    `}

    ${!isDragging &&
    `
      :hover {
        object-fit: cover;
        border-radius: 8px;
        outline: 1px solid ${colors.gray[300]};
        outline-offset: -2px;
      }
  `}
    
    ${isSelected
      ? `
      pointer-events: none;
      transition: box-shadow .1s ease;
      ${
        devMode
          ? `outline: 2px solid ${colors.gray[200]};`
          : `outline: 2px solid ${colors.purple};`
      }
      box-shadow: 1px 1px 12px rgba(0, 0, 0, .1);
      outline-offset: -2px;
      opacity: 100%;
      z-index: 100;
    `
      : `
      pointer-events: auto;
    `}

    ${comments?.mode === "collaborating"
      ? `
          outline-offset: -2px;
          ${
            comments?.componentCommentCount
              ? `outline: 1px solid transparent;`
              : ""
          }
          ${
            !!comments?.commenting?.componentId &&
            comments?.element?.id === comments?.commenting?.componentId
              ? comments?.commenting?.selectType === "component"
                ? `outline: 4px solid ${colors.purple}; :hover { outline: 4px solid ${colors.purple}; }`
                : comments?.commenting?.selectType === "comment"
                ? `outline: 4px solid ${colors.purple}; :hover { outline: 4px solid ${colors.purple}; }`
                : ""
              : `:hover { outline: 1px solid black; }`
          }
        `
      : ``}
  }

  ${isDragSource &&
  `
    opacity: 60%;
    filter: saturate(0);
    border-radius: 8px;
    outline: 2px solid ${colors.black};
    outline-offset: -2px;
    transition: none;
  `};
`;

const componentContainer = css`
  display: flex;
  width: 100%;
  height: 100%;
  overflow: hidden;
  object-fit: none;
`;

const hideComponentToolbarFn = {
  text: () => true,
  textV2: () => true,
  "content-block": () => true,
  benefits: (props) => !props?.element?.data?.id,
  network: (props) => !props?.element?.data?.id,
};

const elements = {
  image: Image,
  space: Space,
  line: Space,
  button: Button,
  text: OptimizedText,
  textV2: TextV2,
  document: Document,
  video: Video,
  website: Website,
  benefits: Benefits,
  pricing: Benefits,
  network: Benefits,
  accordion: Accordion,
  "content-block": ContentBlock,
  "dev-block": DevBlock,
};

export const Component = ({ editor, element, location, component }) => {
  const { params, updateParams } = useSearchParams();

  const {
    dispatch,
    editorDetails: { commentByComponent, commenting },
  } = useContext(EditorDetailsContext);

  const collaborationFF = useFeatureFlagPayload("editor-collaboration")?.value;

  const [rowIdx, colIdx] = location;

  const {
    data: { devMode },
  } = useStore();

  const {
    state: {
      disableTileDrag = false,
      selection = [],
      drag = {},
      viewMode,
      multiSelect,
    },
    shiftKeyIsPressed,
  } = editor;

  const selectedElement = editorUtils.getSelection(editor);
  const isSelected = location.every((n, idx) => n === selection?.[idx]);

  const [contextMenu, setContextMenu] = useState({});

  const componentRef = useRef();
  const componentBounds = useBounds(
    componentRef,
    [component.totalColumns, isSelected],
    200,
    { disabled: !isSelected }
  );

  useEffect(() => {
    if (
      commenting?.selectType === "comment" &&
      commenting?.componentId === element.id
    ) {
      componentRef?.current?.scrollIntoView({ behavior: "instant" });
    }
  }, [commenting?.commentId, commenting?.componentId, commenting?.selectType]);

  useEffect(() => {
    if (isSelected) {
      dispatch({
        payload: componentBounds,
        type: "SET",
        key: "componentBounds",
      });
    }
  }, [componentBounds?.lastUpdate, isSelected]);

  useEffect(() => {
    if (
      !isSelected &&
      (!multiSelect?.isSelecting || multiSelect?.hoverIndices?.[1] !== colIdx)
    ) {
      setContextMenu({});
    }
  }, [
    multiSelect?.isSelecting,
    multiSelect?.hoverIndices?.toString(),
    isSelected,
    viewMode,
  ]);

  const addComment = () => {
    let componentId = element.id;
    // not all components have a componentId added to them
    // so we need to add them here to connect the comment.
    if (!componentId) {
      componentId = uuid.v4();
      modifyContent?.merge(editor, location, { id: componentId });
    }

    const commentId = commentByComponent?.[componentId]?.length
      ? commentByComponent?.[componentId]?.[0]
      : "";

    dispatch({
      type: "SET",
      key: "commenting",
      payload: {
        componentId,
        commentId,
        selectType: "component",
        selectedAt: new Date().toISOString(),
      },
    });
  };

  const updateSelected = (event) => {
    if (params?.mode !== "collaborating") {
      if (!shiftKeyIsPressed) {
        event?.stopPropagation();
      }
      if (!editorUtils?.isLocationAtSelection(editor, location)) {
        editorUtils?.setProperty(editor, "selection", location, {
          resetSelection: true,
        });
      }
    } else {
      event?.stopPropagation();
      addComment();
    }
  };

  const handleContext = (event) => {
    event.preventDefault();
    event.stopPropagation();
    if (
      multiSelect?.isSelecting &&
      rowIdx >= multiSelect?.start &&
      rowIdx <= multiSelect?.end
    ) {
      editorUtils?.setProperty(editor, "multiSelect", {
        ...multiSelect,
        hoverIndices: location,
      });
    } else {
      updateSelected();
    }
    const x = event.clientX;
    const y = event.clientY;
    setContextMenu({ x, y, display: true });
  };

  const startDrag = (event) => {
    event.preventDefault();
    if (
      !disableTileDrag &&
      !multiSelect?.isSelecting &&
      (!selection?.length ||
        !disableDragComponents.includes(selectedElement?.component))
    ) {
      editorUtils?.setProperty(editor, "drag", {
        type: element.component,
        action: "move",
        source: {
          data: { ...element },
          location: location,
          totalColumns: component.totalColumns,
        },
        isDragging: true,
      });
    }
  };

  const isDragSource = useMemo(() => {
    if (drag?.isDragging) {
      return location?.every(
        (value, idx) => value === drag?.source?.location?.[idx]
      );
    }
    return false;
  }, [drag?.isDragging]);

  const props = {
    editor,
    element,
    location,
    //
    isSelected,
  };

  const componentCommentCount = useMemo(() => {
    if (!collaborationFF || !element?.id) {
      return 0;
    }
    return commentByComponent?.[element?.id]?.length;
  }, [collaborationFF, element, commentByComponent]);

  const Component = useDeepCompareMemo(() => {
    if (element?.component in elements) {
      return elements[element.component];
    } else {
      return PlaceholderElement;
    }
  }, [element]);

  const hideToolbar =
    // Toolbar options require a component type
    !element.component ||
    // Some Component toolbar options are conditionally displayed
    hideComponentToolbarFn?.[element.component]?.(props) ||
    // This is a double check to make sure everything is in sync
    // and doesn't display incorrect toolbar options
    element.component !== selectedElement?.component;

  const isCommenting =
    params?.mode === "collaborating" &&
    commenting?.selectType === "component" &&
    commenting?.componentId === element?.id;

  return (
    <>
      <DevProfiler id={location?.toString() + ":" + element?.component}>
        {contextMenu?.display &&
          (selection?.length || multiSelect?.isSelecting) && (
            <ContextMenu
              editor={editor}
              element={element}
              location={location}
              contextMenu={contextMenu}
              setContextMenu={setContextMenu}
              totalColumns={component.totalColumns}
            />
          )}
        <Div
          draggable
          className={containerStyles(
            isSelected,
            drag?.isDragging,
            isDragSource,
            devMode,
            {
              element,
              commenting,
              mode: params.mode,
              componentCommentCount,
            }
          )}
          onContextMenu={handleContext}
          onClick={updateSelected}
          onDragStart={startDrag}
          data-component={true}
          ref={componentRef}
        >
          {params?.mode === "collaborating" && componentCommentCount > 0 ? (
            <Tooltip title={`${componentCommentCount} Comments`}>
              <Div
                css={css`
                  position: absolute;
                  top: 8px;
                  right: 8px;
                  border-radius: 50%;
                  ${flex("center")}
                  cursor: pointer;
                  z-index: 100;
                  background-color: ${colors.purple};
                  border-radius: 30px;
                  min-width: 24px;
                  height: 24px;
                  ${animation("gelatine", ".3s ease")}
                `}
                onClick={() => updateParams({ mode: "collaborating" })}
              >
                <Chat size={16} color="white" weight="fill" />
              </Div>
            </Tooltip>
          ) : null}

          <Div
            className={componentContainer}
            style={element.container?.attributes?.style || {}}
          >
            {element.component ? (
              <Component {...props} />
            ) : (
              <Div
                className={css`
                  height: 150px;
                  background-color: ${colors.gray[300]};
                  border-radius: 8px;
                `}
              />
            )}
          </Div>
          {isCommenting &&
          !commentByComponent?.[commenting?.componentId]?.length ? (
            <CreateComment />
          ) : null}
          {!disableTileDrag && !hideToolbar && !isCommenting && isSelected ? (
            <Toolbar {...props} />
          ) : null}
          <div className="selected-outline" />
        </Div>
      </DevProfiler>
    </>
  );
};
