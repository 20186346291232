import React, { Component } from "react";
import {
  Button,
  Text,
  TextInput,
  Layer,
  Box,
  Select,
  RadioButtonGroup,
} from "grommet";
import TextField from "@material-ui/core/TextField";
import LockIcon from "@material-ui/icons/Lock";
import { css } from "emotion";
import { Editor } from "../Editor";
import { DecisionToolBuilderContainer } from "../DecisionToolBuilder";
import { MultipleChoiceBuilderContainer } from "../MultipleChoiceBuilder";
import { DragDropContext, Droppable, Draggable } from "react-beautiful-dnd";
import { connect } from "react-redux";
import ButtonUI from "@material-ui/core/Button";
import { PageOptions } from "./PageOptions";
import Tooltip from "@material-ui/core/Tooltip";
import { ThemeModal } from "./ThemeModal";
import {
  getCourse,
  updateCoursePagesOrder,
  updateCourse,
  updateCourseChanges,
  deletePage,
  updateUnsavedChanges,
  updateUnsavedChangesOff,
} from "../../redux/actions/courses";
import "./Builder.css";
import { cloneDeep, isEmpty } from "lodash";
import { toast } from "react-toastify";
import axios from "axios";
import CircularProgress from "@material-ui/core/CircularProgress";
import { CustomAxios } from "../../redux/axios/axios";
import { ImportedPageEditor } from "../ImportedPageEditor";
import { VendorLibraryAddPageImport } from "../../Courses/VendorLibraryAddPageImport";
import posthog from "posthog-js";
import { Checkbox, IconButton } from "@material-ui/core";
import { withRouter, matchPath } from "react-router-dom";
import { compose } from "redux";
import { history } from "../../history";
import {
  GearSix,
  Trash,
  ArrowLeft,
  Table,
  Palette,
  SpinnerGap,
  Plus,
  Question,
  Eye,
} from "phosphor-react";
import { CourseSettings } from "../../Courses/CourseSettings";
import { v4 as uuidv4 } from "uuid";
import { ConfirmationModal } from "../../shared/components/confirmation-modal";
import { OptInModal } from "./opt-in-modal";
import { StoreContext } from "../../store-provider/provider";
import { Dot } from "../../shared/components/dot";
import { CollectGuideType } from "../collect-guide-type";

class Builder extends Component {
  static contextType = StoreContext;

  constructor(props) {
    super(props);
    this.match = matchPath(props.location.pathname, {
      path: "/:businessId/courses/:id/builder",
    });
    this.state = {
      currentModal: "",
      showFeedbackPage: Boolean(props.course.ShowFeedbackPage),
      renamePageModal: false,
      renamePage: null,
      selectedPage: {
        FrontendID: "",
        Type: "regular",
        IsLocked: false,
        TemplateID: "00000000-0000-0000-0000-000000000000",
      },
      viewHeight: "200px",
      editTitle: false,
      modalOpen: false,
      newPageName: "",
      newPageType: "Build Your Own",
      newPageOption: "Build from Scratch",
      tooltip: "",
      duplicatePageModal: false,
      duplicatePage: null,
      savingPageTemplate: false,
      pageTemplates: [],
      getPageTemplatesLoading: false,
      selectedPageTemplate: {},
      savePageAsTemplateModal: false,
      pageTemplateName: "",
      loadingPreviewTemplateImageURL: false,
      previewTemplateImageURL: "",
      previewTemplateImageModal: false,
      deletePageModalOpen: false,
      pageToDelete: null,
      importFromVendorLibraryModal: false,
      theme: null,
      filterTemplates: "",
      exitModal: false,
      autoSaveOptInModal: true,
      displayNotification: true,
    };

    this.onDragEnd = this.onDragEnd.bind(this);
  }

  componentWillMount() {
    this.vh();
  }

  componentDidMount() {
    this.props.getCourse(this.match.params.id);
    window.addEventListener("resize", this.vh);
  }

  componentDidUpdate(prevProps) {
    if (this.props.hasUnsavedCourseChanges) {
      window.onbeforeunload = () => true;
    } else {
      window.onbeforeunload = undefined;
    }

    if (
      this.props.course.ShowFeedbackPage !== prevProps.course.ShowFeedbackPage
    ) {
      this.setState({
        showFeedbackPage: Boolean(this.props.course.ShowFeedbackPage),
      });
    }

    // after the course is loaded, select the first page
    if (this.props.courseLoaded !== prevProps.courseLoaded) {
      if (this.props.courseLoaded) {
        // set course name on events now that we have it
        posthog.register({
          courseID: this.match.params.id,
          courseName: this.props.course.Name,
          courseMode: "read-write",
          courseEditSessionId: uuidv4(),
          courseGeneratedById: this.props.course.GeneratedBy,
          courseThemeId: this.props.course.ThemeID, // TODO: if this gets changed while the editor is opening we need to register this
          courseIsPublished: this.props.course.IsPublished,
        });
        this.setState({ selectedPage: this.props.course.Pages[0] });
        this.fetchTheme(this.props.course.ThemeID);
      }
    }

    if (this.props.saving !== prevProps.saving) {
      if (!this.props.saving) {
        this.setState((prevState) => {
          let index = 0;
          for (let i = 0; i < this.props.course.Pages.length; i++) {
            if (
              this.props.course.Pages[i].FrontendID ===
              prevState.selectedPage.FrontendID
            ) {
              index = i;
            }
          }
          return {
            selectedPage: this.props.course.Pages[index],
          };
        });
      }
    }
  }

  componentWillUnmount() {
    window.removeEventListener("resize", this.vh);
    posthog.unregister("courseID");
    posthog.unregister("courseName");
    posthog.unregister("courseMode");
    posthog.unregister("courseEditSessionId");
    posthog.unregister("courseGeneratedById");
    posthog.unregister("courseThemeId");
    posthog.unregister("courseIsPublished");

    this.props.updateUnsavedChangesOff();
  }

  closeDeletePageModal = () => {
    this.setState({ deletePageModalOpen: false, pageToDelete: null });
  };

  openDeletePageModal = (page) => {
    this.setState({ deletePageModalOpen: true, pageToDelete: page });
  };

  getItemStyle = (isDragging, draggableStyle, page) => {
    let backgroundColor =
      this.state.selectedPage.FrontendID === page.FrontendID
        ? "#25282D"
        : "#555555";
    return {
      // some basic styles to make the items look a bit nicer
      userSelect: "none",
      padding: "8px 16px",
      margin: `0 0 ${8}px 0`,

      // change background colour if dragging
      background: backgroundColor,
      borderRadius: "5px",
      color: "white",

      // styles we need to apply on draggables
      ...draggableStyle,
    };
  };

  getListStyle = (isDraggingOver) => {
    return {
      background: isDraggingOver ? "#F8F8F8" : "#F2F2F2",
      overflow: "auto",
      height: parseFloat(this.state.viewHeight) - 120,
    };
  };

  onDragEnd(result) {
    // dropped outside the list
    if (!result.destination) {
      return;
    }

    if (
      result.destination.droppableId === result.source.droppableId &&
      result.destination.index === result.source.index
    ) {
      return;
    }

    this.props.updateCoursePagesOrder({ result });
  }

  vh = () => {
    var h = Math.max(
      document.documentElement.clientHeight,
      window.innerHeight || 0
    );
    let subNum = 140;

    if (
      this.props.parentBusiness &&
      this.props.parentBusiness.Type !== "multi" &&
      this.props.parentBusiness.Type !== "carrier"
    ) {
      subNum = 100;
    }
    this.setState({
      viewHeight: `${h - subNum}px`,
    });
  };

  toggleModal = () => {
    this.setState((prevState) => ({
      modalOpen: !prevState.modalOpen,
      newPageName: "",
      newPageType: "Build Your Own",
      newPageOption: "Build from Scratch",
      selectedPageTemplate: {},
    }));
  };

  toggleModalDuplicate = () => {
    this.setState((prevState) => ({
      duplicatePageModal: !prevState.duplicatePageModal,
      newPageName: "",
      duplicatePage: null,
    }));
  };

  toggleVendorLibraryModal = () => {
    this.setState((prevState) => ({
      importFromVendorLibraryModal: !prevState.importFromVendorLibraryModal,
    }));
  };

  openDuplicateModal = (page) => {
    this.setState((prevState) => ({
      duplicatePageModal: true,
      newPageName: "",
      duplicatePage: page,
    }));
  };

  openRenameModal = (page) => {
    this.setState((prevState) => ({
      renamePageModal: true,
      newPageName: page.Name,
      renamePage: page,
    }));
  };

  toggleRenameModal = () => {
    this.setState((prevState) => ({
      renamePageModal: !prevState.renamePageModal,
      newPageName: "",
      renamePage: null,
    }));
  };

  toggleSavePageAsTemplateModal = () => {
    this.setState((prevState) => ({
      savePageAsTemplateModal: !prevState.savePageAsTemplateModal,
      pageTemplateName: "",
    }));
  };

  openSavePageAsTemplateModal = () => {
    this.setState((prevState) => ({
      savePageAsTemplateModal: true,
      pageTemplateName: "",
    }));
  };

  selectPage = (e, page) => {
    // don't run if you are dragging. Just clicking
    if (!e.defaultPrevented) {
      if (this.showEditor()) {
        window.unlayer.exportHtml((data) => {
          const { design, html } = data;
          if (design && html) {
            // save the page design in redux store only
            var course = cloneDeep(this.props.course);
            for (let coursePage of course.Pages) {
              if (
                coursePage.FrontendID === this.state.selectedPage.FrontendID
              ) {
                coursePage.Content = design;
                coursePage.HTMLContent = html;
              }
            }
            this.props.updateCourseChanges(course);
          }

          this.setState({ selectedPage: page });
        });
      } else {
        this.setState({ selectedPage: page });
      }
    }
  };

  addPage = () => {
    this.toggleModal();
  };

  saveNewPage = () => {
    let type = "";
    let content = null;
    if (this.state.newPageType === "Build Your Own") {
      type = "regular";
    }
    if (this.state.newPageType === "Decision Tool") {
      type = "decision";
    }
    if (this.state.newPageType === "Multiple Choice") {
      type = "question";
    }

    let actionType = "new_page";
    if (
      this.state.newPageOption !== "Build from Scratch" &&
      this.state.selectedPageTemplate.ID
    ) {
      actionType = "import_page_template";
      if (!isEmpty(this.state.selectedPageTemplate.Content)) {
        content = this.state.selectedPageTemplate.Content;
      }
    }
    posthog.capture("CourseEdit", {
      actionType: "brite:" + actionType + ":" + type,
    });
    var course = cloneDeep(this.props.course);

    course.ShowFeedbackPage = this.state.showFeedbackPage;
    course.Pages.push({
      Type: type,
      Name: this.state.newPageName,
      Content: content,
      Order: 1,
      FrontendID: "_" + Math.random().toString(36).substr(2, 9),
      InsurancePlans: [],
      Question: null,
      IsLocked: false,
      TemplateID: "00000000-0000-0000-0000-000000000000",
    });
    this.props.updateCourseChanges(course);
    this.setState((prevState) => ({
      modalOpen: false,
      newPageName: "",
      newPageType: "Build Your Own",
      newPageOption: "Build from Scratch",
      selectedPageTemplate: {},
    }));
    if (type === "decision") {
      this.save(false, course);
    }
  };

  duplicatePage = () => {
    var course = cloneDeep(this.props.course);
    let page = this.state.duplicatePage;
    posthog.capture("CourseEdit", {
      actionType: "brite:" + "duplicate_page" + ":" + page.Type,
    });
    let newPage = {
      Order: 1,
      FrontendID: "_" + Math.random().toString(36).substr(2, 9),
      IsLocked: this.state.selectedPage.IsLocked,
      TemplateID: this.state.selectedPage.TemplateID,
    };
    newPage.Name = this.state.newPageName;
    if (page.Type === "regular") {
      newPage.Type = "regular";
      if (
        this.state.selectedPage.FrontendID === page.FrontendID &&
        this.showEditor()
      ) {
        window.unlayer.exportHtml((data) => {
          const { design, html } = data;
          newPage.Content = design;
          newPage.HTMLContent = html;
          course.Pages.push(newPage);
          this.props.updateCourseChanges(course);
          this.setState((prevState) => ({
            duplicatePageModal: false,
            newPageName: "",
            duplicatePage: null,
          }));
        });
      } else {
        newPage.Content = page.Content;
        newPage.HTMLContent = page.HTMLContent;
        course.Pages.push(newPage);

        this.props.updateCourseChanges(course);
        this.setState((prevState) => ({
          duplicatePageModal: false,
          newPageName: "",
          duplicatePage: null,
        }));
      }
    }
  };

  renamePage = (page) => {
    posthog.capture("CourseEdit", {
      actionType: "brite:rename_page:" + page.Type,
    });
    var course = cloneDeep(this.props.course);

    for (let i = 0; i < course.Pages.length; i++) {
      if (course.Pages[i].FrontendID === page.FrontendID) {
        course.Pages[i].Name = this.state.newPageName;
      }
    }

    this.props.updateCourseChanges(course);
    this.toggleRenameModal();
  };

  toggleLockPage = (page) => {
    var course = cloneDeep(this.props.course);

    for (let i = 0; i < course.Pages.length; i++) {
      if (course.Pages[i].FrontendID === page.FrontendID) {
        course.Pages[i].IsLocked = !course.Pages[i].IsLocked;
      }
    }

    this.props.updateCourseChanges(course);
  };

  deletePage = (page) => {
    posthog.capture("CourseEdit", {
      actionType: "brite:delete_page:" + page.Type,
    });
    var course = cloneDeep(this.props.course);
    let index = 0;
    for (let i = 0; i < course.Pages.length; i++) {
      if (course.Pages[i].FrontendID === page.FrontendID) {
        index = i;
      }
    }

    if (course.Pages[index].ID) {
      this.props.deletePage(course.Pages[index].ID);
    }
    course.Pages.splice(index, 1);
    this.props.updateCourseChanges(course);
    this.setState({ selectedPage: course.Pages[0] });
  };

  savePageAsTemplate = async () => {
    if (this.state.selectedPage.Type === "regular" && this.showEditor()) {
      this.setState({
        savingPageTemplate: true,
        savePageAsTemplateModal: false,
      });
      window.unlayer.exportHtml(async (data) => {
        const { design, html } = data;
        let body = {
          Name: this.state.pageTemplateName,
          Content: design,
          BusinessID: this.props.businessId,
        };

        try {
          let response = await CustomAxios.post(`/v1/templates`, body);
          toast.success("Successfully saved page as template!");
          this.setState({ savingPageTemplate: true, pageTemplateName: "" });
        } catch (e) {
          this.setState({ savingPageTemplate: true, pageTemplateName: "" });
          toast.error("Could not save page as a template");
        }
      });
    }
  };

  save = (shouldExit, updatedCourse) => {
    posthog.capture("CourseSave", {
      shouldExit: shouldExit,
      // TODO: what else to add here? number of pages?
    });

    let course = cloneDeep(this.props.course);
    if (updatedCourse) {
      course = updatedCourse;
    }

    course.ShowFeedbackPage = this.state.showFeedbackPage;
    if (this.state.selectedPage.Type === "regular" && this.showEditor()) {
      window.unlayer.exportHtml((data) => {
        const { design, html } = data;
        for (let pages of course.Pages) {
          if (
            pages.FrontendID === this.state.selectedPage.FrontendID &&
            design &&
            html
          ) {
            pages.Content = design;
            pages.HTMLContent = html;
          }
          if (pages.Type === "regular") {
            delete pages.InsurancePlans;
            delete pages.Question;
          } else if (pages.Type === "decision") {
            delete pages.Content;
            delete pages.Question;
          } else {
            delete pages.Content;
            delete pages.HTMLContent;
            delete pages.InsurancePlans;
          }
        }
        this.props.updateCourse({ course, shouldExit });
      });
    } else {
      for (let pages of course.Pages) {
        if (pages.Type === "regular") {
          delete pages.InsurancePlans;
          delete pages.Question;
        } else if (pages.Type === "decision") {
          delete pages.Content;
          delete pages.Question;
        } else {
          delete pages.Content;
          delete pages.HTMLContent;
          delete pages.InsurancePlans;
        }
      }
      this.props.updateCourse({ course, shouldExit });
    }
  };

  updateCourseName(event) {
    posthog.capture("CourseEdit", {
      actionType: "brite:update_course_name",
    });
    var course = cloneDeep(this.props.course);
    course.Name = event.target.value;
    this.props.updateCourseChanges(course);
  }

  setNewPageName(event) {
    this.setState({ newPageName: event.target.value });
  }

  setNewPageType(type) {
    this.setState({ newPageType: type });
  }

  setShowFeedbackPage(event) {
    posthog.capture("CourseEdit", {
      actionType: "brite:update_show_feedback_page",
    });
    this.setState({ showFeedbackPage: event.target.checked });
  }

  previewTemplate = async (template) => {
    this.setState({
      loadingPreviewTemplateImageURL: true,
      previewTemplateImageURL: "",
      previewTemplateImageModal: true,
    });
    try {
      const response = await axios.post(
        "https://api.unlayer.com/v2/export/image",
        { displayMode: "web", design: template.Content },
        {
          headers: {
            Authorization:
              "Basic THZyN3VQNGpmTzhxMWhZS1dRN2oyVnVrc3pZckl5NmFNYWJ3UTEyZ1JIVEk0MmJNNndOQndLZWZCb1A0eWlEbzo=",
          },
        }
      );

      window.open(response.data.data.url, "_blank");

      this.setState({
        loadingPreviewTemplateImageURL: false,
        previewTemplateImageURL: response.data.data.url,
        previewTemplateImageModal: false,
      });
    } catch (e) {
      this.setState({
        loadingPreviewTemplateImageURL: false,
        previewTemplateImageURL: "",
        previewTemplateImageModal: false,
      });
    }
  };

  closePreviewImage = () => {
    this.setState({
      loadingPreviewTemplateImageURL: false,
      previewTemplateImageURL: "",
      previewTemplateImageModal: false,
    });
  };

  fetchPageTemplate = async (pageOption = "Import a Page Template") => {
    try {
      let response = await CustomAxios.get(`/v1/templates`);
      let pageTemplate = response.data || [];

      this.setState({
        newPageOption: pageOption,
        pageTemplates: pageTemplate,
        getPageTemplatesLoading: false,
      });
    } catch (e) {
      this.setState({
        newPageOption: pageOption,
        pageTemplates: [],
        getPageTemplatesLoading: false,
      });

      toast.error("Could not fetch page templates");
    }
  };

  fetchTheme = async (themeId) => {
    try {
      const resp = await CustomAxios.get(`/v1/themes/${themeId}`);
      this.setState({
        theme: resp.data.Theme,
      });
    } catch (e) {
      console.log("error loading theme");
    }
  };

  setPageOption = async (event) => {
    let pageOption = event.target.value;
    this.setState({ getPageTemplatesLoading: true, newPageOption: pageOption });

    if (pageOption === "Import a Page Template") {
      await this.fetchPageTemplate(pageOption);
    } else {
      this.setState({
        newPageOption: pageOption,
        pageTemplates: [],
        getPageTemplatesLoading: false,
      });
    }
  };

  showEditor = () => {
    if (this.state.currentModal === "styles") {
      return false;
    }
    if (this.state.selectedPage.Type === "regular") {
      if (
        this.props.location.search &&
        this.props.location.search.toLowerCase().includes("vendor")
      ) {
        return true;
      } else if (!this.state.selectedPage.IsLocked) {
        return true;
      } else {
        return false;
      }
    } else {
      return false;
    }
  };

  showImportedPage = () => {
    if (this.state.selectedPage.Type === "regular") {
      if (
        !this.props.location.search.toLowerCase().includes("vendor") &&
        this.state.selectedPage.IsLocked
      ) {
        return true;
      } else {
        return false;
      }
    } else {
      return false;
    }
  };

  deleteTemplate = async (template) => {
    try {
      await CustomAxios.delete(`v1/templates/${template.ID}`);
      toast.success(`Successfully deleted "${template.Name}"`);
      this.fetchPageTemplate();
    } catch (err) {
      console.warn(err);
      toast.error(`Couldn't delete ${template.Name}, try again...`);
    }
  };

  handleExit = () => {
    if (this.props.hasUnsavedCourseChanges) {
      this.setState({ exitModal: true });
    } else {
      history.goBack();
    }
  };

  render() {
    const isAdmin = this.context.store?.selectedAcl?.RoleID === "admin";
    const disabledSaveButton =
      this.props.saving ||
      !(
        this.props.hasUnsavedCourseChanges ||
        this.state.showFeedbackPage !==
          Boolean(this.props.course.ShowFeedbackPage)
      );
    if (this.props.loading) {
      return (
        <div
          className={css`
            display: flex;
            justify-content: center;
            margin-top: 80px;
          `}
        >
          <CircularProgress />
        </div>
      );
    }
    return (
      <Layer full modal={true}>
        {this.props.loading ? (
          <div
            className={css`
              display: flex;
              justify-content: center;
              margin-top: 80px;
            `}
          >
            <CircularProgress />
          </div>
        ) : (
          <div
            className={css`
              padding-top: 16px;
              padding-bottom: 60px;
              overflow: auto;
            `}
          >
            <div
              className={css`
                display: flex;
                justify-content: space-between;
                padding-bottom: 20px;
              `}
            >
              <div
                className={css`
                  font-weight: 400;
                  font-size: 26px;
                  line-height: 28px;
                  color: #25282d;
                  display: flex;
                  justify-content: center;
                  align-items: center;
                  margin: 0 16px;
                `}
              >
                <Tooltip title="Back" placement="bottom">
                  <IconButton
                    onClick={this.handleExit}
                    disabled={this.props.saving}
                  >
                    <ArrowLeft />
                  </IconButton>
                </Tooltip>
                {this.state.editTitle ? (
                  <TextInput
                    className={css`
                      height: 40px;
                      font-weight: 400;
                    `}
                    onBlur={() => {
                      this.setState({ editTitle: false });
                    }}
                    autoFocus
                    ref={this.ref}
                    value={this.props.course.Name}
                    onChange={(e) => {
                      this.updateCourseName(e);
                    }}
                  />
                ) : (
                  <Tooltip title="Rename" placement="top">
                    <span
                      className={css`
                        max-width: 400px;
                        margin: 0 16px;
                        &:hover {
                          cursor: text;
                          border: 1px solid #d1dae3;
                        }
                      `}
                      onClick={() => {
                        this.setState({ editTitle: true });
                      }}
                    >
                      {this.props.course.Name}
                    </span>
                  </Tooltip>
                )}
              </div>

              <CollectGuideType
                course={this.props.course}
                refetchCourse={() => this.props.getCourse(this.match.params.id)}
              />

              <div
                className={css`
                  display: flex;
                  justify-content: space-between;
                  padding-bottom: 20px;
                `}
              >
                <div
                  className={css`
                    margin-right: 12px;
                  `}
                >
                  <ButtonUI
                    onClick={() => this.setState({ currentModal: "styles" })}
                    startIcon={<Palette />}
                  >
                    Design Styles
                  </ButtonUI>
                </div>
                <div
                  className={css`
                    margin-right: 12px;
                  `}
                >
                  <ButtonUI
                    disabled={
                      this.props.savingPageTemplate ||
                      this.state.selectedPage.Type !== "regular" ||
                      this.state.selectedPage.IsLocked
                    }
                    onClick={() => {
                      this.openSavePageAsTemplateModal();
                    }}
                    startIcon={<Table />}
                  >
                    {this.props.savingPageTemplate
                      ? "Saving..."
                      : "Save Template"}
                  </ButtonUI>
                </div>
                <div>
                  <Dot
                    display={this.state.displayNotification && isAdmin}
                    spacing="1"
                  >
                    <ButtonUI
                      onClick={() =>
                        this.setState({
                          currentModal: "settings",
                          displayNotification: false,
                        })
                      }
                      startIcon={<GearSix />}
                    >
                      Settings
                    </ButtonUI>
                  </Dot>
                </div>
              </div>

              <div
                className={css`
                  display: flex;
                  align-items: center;
                `}
              >
                <div
                  className={css`
                    margin-right: 18px;
                  `}
                >
                  <ButtonUI
                    style={{ padding: "8px 16px" }}
                    disabled={
                      this.props.saving || !this.props.hasUnsavedCourseChanges
                    }
                    onClick={this.handleExit}
                    color="primary"
                  >
                    Discard
                  </ButtonUI>
                </div>
                <div
                  className={css`
                    margin-right: 20px;
                  `}
                >
                  <ButtonUI
                    disabled={disabledSaveButton}
                    variant="contained"
                    onClick={() => this.save(false)}
                    color="primary"
                  >
                    {this.props.saving ? "Saving..." : "Save Course"}
                  </ButtonUI>
                </div>
              </div>
            </div>
            <div
              className={css`
                display: flex;
                margin: 10px;
                margin-top: 0px;
              `}
            >
              <div
                id="pages"
                className={css`
                  display: flex;
                  margin: 10px;
                  margin-top: 0px;
                `}
              >
                <div
                  className={css`
                    width: 180px;
                    flex: 1;
                    background-color: #f2f2f2;
                    padding: 12px;
                    border-radius: 5px;
                  `}
                >
                  <div
                    ref={(ref) => {
                      this.question1 = ref;
                    }}
                    className={css`
                      display: flex;
                      justify-content: space-between;
                      align-items: center;
                      margin-bottom: 20px;
                    `}
                  >
                    <Text size="large" color="dark-2">
                      Pages
                    </Text>
                    <Button
                      icon={<Plus />}
                      onClick={() => {
                        this.addPage();
                      }}
                    />
                  </div>
                  <DragDropContext onDragEnd={this.onDragEnd}>
                    <Droppable droppableId="droppable">
                      {(provided, snapshot) => (
                        <div
                          {...provided.droppableProps}
                          ref={provided.innerRef}
                          style={this.getListStyle(snapshot.isDraggingOver)}
                        >
                          {this.props.course.Pages.map((item, index) => (
                            <Draggable
                              key={item.FrontendID}
                              draggableId={item.FrontendID}
                              index={index}
                            >
                              {(provided, snapshot) => (
                                <React.Fragment>
                                  <div
                                    onClick={(e) => {
                                      this.selectPage(e, item);
                                    }}
                                    className={css`
                                      &:hover {
                                        box-shadow: 0px 0px 5px 0px
                                          rgba(0, 0, 0, 0.75);
                                      }
                                      display: flex;
                                      align-items: center;
                                      justify-content: space-between;
                                    `}
                                    ref={provided.innerRef}
                                    {...provided.draggableProps}
                                    {...provided.dragHandleProps}
                                    style={this.getItemStyle(
                                      snapshot.isDragging,
                                      provided.draggableProps.style,
                                      item
                                    )}
                                  >
                                    <div
                                      className={css`
                                        white-space: nowrap;
                                        width: 100px;
                                        overflow: hidden;
                                        text-overflow: ellipsis;
                                      `}
                                    >
                                      <Tooltip
                                        title={item.Name || ""}
                                        placement="top"
                                      >
                                        <span>{item.Name}</span>
                                      </Tooltip>
                                      {item.Type === "regular" && (
                                        <div
                                          className={css`
                                            font-size: 9px;
                                          `}
                                        >
                                          {item.TemplateID ===
                                          "00000000-0000-0000-0000-000000000000"
                                            ? "Build Your Own"
                                            : "Vendor Imported"}
                                        </div>
                                      )}
                                      {item.Type === "decision" && (
                                        <div
                                          className={css`
                                            font-size: 9px;
                                          `}
                                        >
                                          Decision Tool
                                        </div>
                                      )}
                                      {item.Type === "question" && (
                                        <div
                                          className={css`
                                            font-size: 9px;
                                          `}
                                        >
                                          Multiple Choice
                                        </div>
                                      )}
                                    </div>

                                    <div
                                      className={css`
                                        display: flex;
                                        align-items: center;
                                        justify-content: flex-end;
                                      `}
                                    >
                                      <PageOptions
                                        showDelete={
                                          this.props.course.Pages.length > 1
                                        }
                                        isVendor={
                                          this.props.location.search &&
                                          this.props.location.search
                                            .toLowerCase()
                                            .includes("vendor")
                                        }
                                        showLock={
                                          item.Type === "regular" &&
                                          this.props.location.search &&
                                          this.props.location.search
                                            .toLowerCase()
                                            .includes("vendor")
                                        }
                                        lock={(e) => {
                                          e.preventDefault();
                                          this.toggleLockPage(item);
                                        }}
                                        isLocked={item.IsLocked}
                                        pageType={item.Type}
                                        duplicate={(e) => {
                                          e.preventDefault();
                                          this.openDuplicateModal(item);
                                        }}
                                        delete={(e) => {
                                          e.preventDefault();
                                          this.openDeletePageModal(item);
                                        }}
                                        rename={(e) => {
                                          e.preventDefault();
                                          this.openRenameModal(item);
                                        }}
                                      />
                                      {item.IsLocked && (
                                        <div
                                          className={css`
                                            margin-left: -8px;
                                            margin-right: -13px;
                                          `}
                                        >
                                          <Tooltip
                                            title="Page is locked."
                                            placement="top"
                                          >
                                            <LockIcon
                                              fontSize={"small"}
                                              style={{ color: "white" }}
                                            />
                                          </Tooltip>
                                        </div>
                                      )}
                                    </div>
                                  </div>
                                </React.Fragment>
                              )}
                            </Draggable>
                          ))}
                          <div
                            className={css`
                              ${snapshot.isDraggingOver && `display: none;`}
                              background-color: white;
                              userselect: none;
                              padding: 8px 16px;
                              margin: 0 0 8px 0;
                              border-radius: 5px;
                            `}
                          >
                            <Tooltip
                              title="Gather feedback at the end of the guide."
                              placement="top"
                            >
                              <div
                                className={css`
                                  display: flex;
                                  align-items: center;
                                  justify-content: space-between;
                                  margin: 0;
                                  padding: 0;
                                `}
                              >
                                <div>
                                  <Text size="small">Feedback</Text>
                                  <br />
                                  <span
                                    className={css`
                                      font-size: 9px;
                                    `}
                                  >
                                    Survey
                                  </span>
                                </div>
                                <Checkbox
                                  checked={this.state.showFeedbackPage}
                                  onChange={(e) => this.setShowFeedbackPage(e)}
                                  color="primary"
                                />
                              </div>
                            </Tooltip>
                          </div>
                          {provided.placeholder}
                        </div>
                      )}
                    </Droppable>
                  </DragDropContext>
                  <Text size="xsmall">* Drag to reorder pages</Text>
                </div>
              </div>

              {this.showEditor() && (
                <Editor
                  updateUnsavedChanges={this.props.updateUnsavedChanges}
                  viewHeight={this.state.viewHeight}
                  selectedPage={this.state.selectedPage}
                  parentBusiness={this.props.parentBusiness}
                  courseTheme={this.state.theme}
                  businessId={this.props.businessId}
                  course={this.props.course}
                />
              )}

              {this.showImportedPage() && (
                <ImportedPageEditor
                  updateCourseChanges={this.props.updateCourseChanges}
                  course={this.props.course}
                  selectedPage={this.state.selectedPage}
                  viewHeight={this.state.viewHeight}
                />
              )}

              {this.state.selectedPage.Type === "decision" && (
                <DecisionToolBuilderContainer
                  match={this.match}
                  updateCourseChanges={this.props.updateCourseChanges}
                  course={this.props.course}
                  selectedPage={this.state.selectedPage}
                  viewHeight={this.state.viewHeight}
                />
              )}

              {this.state.selectedPage.Type === "question" && (
                <MultipleChoiceBuilderContainer
                  updateCourseChanges={this.props.updateCourseChanges}
                  course={this.props.course}
                  selectedPage={this.state.selectedPage}
                  viewHeight={this.state.viewHeight}
                />
              )}
            </div>

            {this.state.modalOpen && (
              <Layer
                margin="small"
                modal={true}
                onEsc={() => this.toggleModal()}
                onClickOutside={() => this.toggleModal()}
              >
                <Box gap="small" margin="20px;" overflow="auto" width="medium">
                  {!this.props.location.search
                    .toLowerCase()
                    .includes("vendor") && (
                    <div
                      className={css`
                        display: flex;
                        justify-content: center;
                        flex-direction: column;
                      `}
                    >
                      <ButtonUI
                        variant="contained"
                        color="primary"
                        onClick={() => {
                          this.toggleVendorLibraryModal();
                        }}
                      >
                        Import From Template Library
                      </ButtonUI>
                      <div
                        className={css`
                          text-align: center;
                          margin: 18px;
                        `}
                      >
                        --- Or ---
                      </div>
                    </div>
                  )}

                  <div
                    className={css`
                      margin-bottom: -7px;
                    `}
                  >
                    <Text
                      weight="bold"
                      size={"14px"}
                      className={css`
                        margin-right: 24px;
                      `}
                    >
                      Page Name{" "}
                    </Text>
                  </div>
                  <TextInput
                    placeholder="New page name"
                    className={css`
                      height: 40px;
                      font-weight: 400;
                    `}
                    value={this.state.newPageName}
                    onChange={(e) => {
                      this.setNewPageName(e);
                    }}
                  />

                  <div
                    className={css`
                      margin-bottom: -7px;
                    `}
                  >
                    <Text weight="bold" size={"14px"}>
                      Type{" "}
                      <a data-tip data-for="happyFace">
                        {" "}
                        <Question size={16} />
                      </a>
                    </Text>
                  </div>
                  <Select
                    options={
                      this.props.location.search &&
                      this.props.location.search
                        .toLowerCase()
                        .includes("vendor")
                        ? ["Build Your Own"]
                        : ["Build Your Own", "Decision Tool", "Multiple Choice"]
                    }
                    value={this.state.newPageType}
                    onChange={({ option }) => this.setNewPageType(option)}
                  />

                  {this.state.newPageType === "Build Your Own" && (
                    <div>
                      <RadioButtonGroup
                        name="doc"
                        options={[
                          "Build from Scratch",
                          "Import a Page Template",
                        ]}
                        value={this.state.newPageOption}
                        onChange={(event) => this.setPageOption(event)}
                      />

                      {this.state.newPageOption ===
                        "Import a Page Template" && (
                        <div
                          className={css`
                            margin-top: 12px;
                            margin-bottom: 12px;
                            max-height: 300px;
                            overflow: auto;
                            border: 1px solid #ccc;
                            padding: 12px;
                            border-radius: 10px;
                          `}
                        >
                          <TextField
                            id="search-template"
                            label="Search Template"
                            fullWidth
                            variant="outlined"
                            value={this.state.filterTemplates}
                            onChange={(e) => {
                              this.setState({
                                filterTemplates: e.target.value,
                              });
                            }}
                          />

                          {this.state.getPageTemplatesLoading && (
                            <div
                              className={css`
                                display: flex;
                                justify-content: center;
                              `}
                            >
                              <CircularProgress />
                            </div>
                          )}
                          {!this.state.getPageTemplatesLoading &&
                            this.state.pageTemplates.length === 0 && (
                              <div>No templates available</div>
                            )}
                          {this.state.pageTemplates
                            .filter((t) =>
                              t.Name.toLowerCase().includes(
                                this.state.filterTemplates.toLowerCase()
                              )
                            )
                            .sort((a, b) => a.Name.localeCompare(b.Name))
                            .map((template) => {
                              return (
                                <React.Fragment key={template.ID}>
                                  <div
                                    onClick={() => {
                                      this.setState({
                                        selectedPageTemplate: template,
                                      });
                                    }}
                                    className={css`
                                      display: flex;
                                      align-items: center;
                                      margin-top: 8px;
                                      padding: 8px;
                                      ${this.state.selectedPageTemplate.ID ===
                                      template.ID
                                        ? "background-color: #eeeeee;"
                                        : ""}
                                    `}
                                  >
                                    <div
                                      className={css`
                                        margin-right: 12px;
                                        cursor: pointer;
                                        margin-top: 3px;
                                      `}
                                    >
                                      {this.state
                                        .loadingPreviewTemplateImageURL && (
                                        <SpinnerGap size={24}>
                                          <animate
                                            attributeName="opacity"
                                            values="0;1;0"
                                            dur="4s"
                                            repeatCount="indefinite"
                                          ></animate>
                                          <animateTransform
                                            attributeName="transform"
                                            attributeType="XML"
                                            type="rotate"
                                            dur="5s"
                                            from="0 0 0"
                                            to="360 0 0"
                                            repeatCount="indefinite"
                                          ></animateTransform>{" "}
                                        </SpinnerGap>
                                      )}
                                      {!this.state
                                        .loadingPreviewTemplateImageURL && (
                                        <Eye
                                          size={20}
                                          onClick={(e) => {
                                            e.stopPropagation();
                                            this.previewTemplate(template);
                                          }}
                                        />
                                      )}
                                    </div>
                                    <Button
                                      onClick={(e) => {
                                        e.stopPropagation();
                                        this.setState({
                                          selectedPageTemplate: template,
                                        });
                                      }}
                                      plain
                                      className={css`
                                        color: #25282d;
                                        display: block;
                                        flex-grow: 1;
                                      `}
                                    >
                                      {template.Name}
                                    </Button>
                                    <Tooltip title="Delete" placement="top">
                                      <IconButton
                                        onClick={() =>
                                          this.deleteTemplate(template)
                                        }
                                      >
                                        <Trash />
                                      </IconButton>
                                    </Tooltip>
                                  </div>
                                </React.Fragment>
                              );
                            })}
                        </div>
                      )}
                    </div>
                  )}

                  <div
                    className={css`
                      display: flex;
                      justify-content: space-between;
                      margin-top: 30px;
                    `}
                  >
                    <Button
                      label="Cancel"
                      onClick={() => {
                        this.toggleModal();
                      }}
                    />
                    <Button
                      className={css`
                        color: white;
                      `}
                      primary
                      label="Add"
                      disabled={!this.state.newPageName}
                      onClick={() => {
                        this.saveNewPage();
                      }}
                    />
                  </div>
                </Box>
              </Layer>
            )}

            {this.state.duplicatePageModal && (
              <Layer
                margin="small"
                modal={true}
                onEsc={() => this.toggleModalDuplicate()}
                onClickOutside={() => this.toggleModalDuplicate()}
              >
                <div
                  className={css`
                    font-weight: 400;
                    font-size: 22px;
                    line-height: 28px;
                    color: #797979;
                    margin: 20px;
                    margin-bottom: 5px;
                  `}
                >
                  Duplicate Page
                </div>
                <Box gap="medium" margin="20px;">
                  <Text weight="bold">New Page Name </Text>
                  <TextInput
                    placeholder="Enter new page name"
                    className={css`
                      height: 40px;
                      font-weight: 400;
                    `}
                    value={this.state.newPageName}
                    onChange={(e) => {
                      this.setNewPageName(e);
                    }}
                  />
                  <div
                    className={css`
                      display: flex;
                      justify-content: space-between;
                    `}
                  >
                    <Button
                      className={css`
                        margin-right: 20px;
                      `}
                      label="Cancel"
                      onClick={() => {
                        this.toggleModalDuplicate();
                      }}
                    />
                    <Button
                      className={css`
                        color: white;
                      `}
                      primary
                      label="Duplicate"
                      disabled={!this.state.newPageName}
                      onClick={() => {
                        this.duplicatePage();
                      }}
                    />
                  </div>
                </Box>
              </Layer>
            )}

            {this.state.importFromVendorLibraryModal && (
              <Layer
                margin="xsmall"
                modal={true}
                onEsc={() => this.toggleVendorLibraryModal()}
                onClickOutside={() => this.toggleVendorLibraryModal()}
              >
                <div
                  className={css`
                    min-width: 900px;
                    overflow: auto;
                  `}
                >
                  <div
                    className={css`
                      font-weight: 400;
                      font-size: 22px;
                      line-height: 28px;
                      color: #797979;
                      margin: 20px;
                      margin-bottom: 5px;
                    `}
                  >
                    Import pages from Template Library
                  </div>
                  <Box gap="medium" margin="20px;">
                    <VendorLibraryAddPageImport
                      selectedCourseID={this.match.params.id}
                      course={this.props.course}
                      close={() => {
                        this.toggleVendorLibraryModal();
                        this.toggleModal();
                      }}
                      save={this.save}
                      updateCourseChanges={this.props.updateCourseChanges}
                    />
                    <div
                      className={css`
                        display: flex;
                        justify-content: center;
                      `}
                    >
                      <Button
                        label="Cancel"
                        onClick={() => {
                          this.toggleVendorLibraryModal();
                        }}
                      />
                    </div>
                  </Box>
                </div>
              </Layer>
            )}

            {this.state.renamePageModal && (
              <Layer
                margin="small"
                modal={true}
                onEsc={() => this.toggleRenameModal()}
                onClickOutside={() => this.toggleRenameModal()}
              >
                <div
                  className={css`
                    font-weight: 400;
                    font-size: 22px;
                    line-height: 28px;
                    color: #797979;
                    margin: 20px;
                    margin-bottom: 5px;
                  `}
                >
                  Rename Page
                </div>
                <Box gap="medium" margin="20px;">
                  <TextInput
                    placeholder="Enter new page name"
                    className={css`
                      height: 40px;
                      font-weight: 400;
                    `}
                    value={this.state.newPageName}
                    onChange={(e) => {
                      this.setNewPageName(e);
                    }}
                  />
                  <div
                    className={css`
                      display: flex;
                      justify-content: space-between;
                    `}
                  >
                    <Button
                      className={css`
                        margin-right: 20px;
                      `}
                      label="Cancel"
                      onClick={() => {
                        this.toggleRenameModal();
                      }}
                    />
                    <Button
                      className={css`
                        color: white;
                      `}
                      primary
                      label="Submit"
                      disabled={!this.state.newPageName}
                      onClick={() => {
                        this.renamePage(this.state.renamePage);
                      }}
                    />
                  </div>
                </Box>
              </Layer>
            )}

            {this.state.savePageAsTemplateModal && (
              <Layer
                margin="small"
                modal={true}
                onEsc={() => this.toggleSavePageAsTemplateModal()}
                onClickOutside={() => this.toggleSavePageAsTemplateModal()}
              >
                <div
                  className={css`
                    font-weight: 400;
                    font-size: 22px;
                    line-height: 28px;
                    color: #797979;
                    margin: 20px;
                    margin-bottom: 5px;
                  `}
                >
                  Save Page as Template
                </div>
                <Box gap="medium" margin="20px;">
                  <Text weight="bold">Template Name </Text>
                  <TextInput
                    placeholder="Enter new template name"
                    className={css`
                      height: 40px;
                      font-weight: 400;
                    `}
                    value={this.state.pageTemplateName}
                    onChange={(e) => {
                      this.setState({ pageTemplateName: e.target.value });
                    }}
                  />
                  <div
                    className={css`
                      display: flex;
                      justify-content: space-between;
                    `}
                  >
                    <Button
                      className={css`
                        margin-right: 20px;
                      `}
                      label="Cancel"
                      onClick={() => {
                        this.toggleSavePageAsTemplateModal();
                      }}
                    />
                    <Button
                      className={css`
                        color: white;
                      `}
                      primary
                      label="Create Template"
                      disabled={!this.state.pageTemplateName}
                      onClick={() => {
                        this.savePageAsTemplate();
                      }}
                    />
                  </div>
                </Box>
              </Layer>
            )}

            {this.state.deletePageModalOpen && (
              <Layer
                margin="small"
                modal={true}
                onEsc={() => this.closeDeletePageModal()}
                onClickOutside={() => this.closeDeletePageModal()}
              >
                <div
                  className={css`
                    font-weight: 400;
                    font-size: 22px;
                    line-height: 28px;
                    color: #797979;
                    margin: 20px;
                    margin-bottom: 5px;
                  `}
                >
                  Are you sure you want to delete this page?
                </div>
                <Box gap="medium" margin="20px;">
                  <div
                    className={css`
                      display: flex;
                      justify-content: center;
                      align-items: center;
                    `}
                  >
                    <Button
                      className={css`
                        margin-right: 20px;
                      `}
                      label="Cancel"
                      onClick={() => {
                        this.closeDeletePageModal();
                      }}
                    />
                    <Button
                      className={css`
                        color: white;
                      `}
                      primary
                      label="Yes"
                      onClick={() => {
                        this.deletePage(this.state.pageToDelete);
                        this.closeDeletePageModal();
                      }}
                    />
                  </div>
                </Box>
              </Layer>
            )}

            {this.state.currentModal === "settings" && (
              <Layer
                className={css`
                  height: 100vh;
                `}
                margin={{
                  left: "0px",
                  top: "0px",
                  right: "0px",
                  bottom: "0px",
                }}
                modal={true}
                full
                onEsc={() => this.setState({ currentModal: "" })}
                onClickOutside={() => this.setState({ currentModal: "" })}
              >
                <CourseSettings
                  course={this.props.course}
                  updateCourseChanges={this.props.updateCourseChanges}
                  close={() => this.setState({ currentModal: "" })}
                  page={this.state.selectedPage}
                />
              </Layer>
            )}

            {this.state.currentModal === "styles" && (
              <Layer
                className={css`
                  height: 100vh;
                  overflow: auto;
                `}
                margin={{
                  left: "0px",
                  top: "0px",
                  right: "0px",
                  bottom: "0px",
                }}
                modal={true}
                full
                onEsc={() => this.setState({ currentModal: "" })}
                onClickOutside={() => this.setState({ currentModal: "" })}
              >
                <ThemeModal
                  setTheme={(theme) => {
                    this.setState({
                      theme,
                    });
                  }}
                  updateCourseChanges={this.props.updateCourseChanges}
                  course={this.props.course}
                  themeID={this.props.course.ThemeID}
                  close={() => this.setState({ currentModal: "" })}
                />
              </Layer>
            )}
          </div>
        )}

        <OptInModal
          display={this.state.autoSaveOptInModal}
          onClose={() => this.setState({ autoSaveOptInModal: false })}
          onConfirm={() =>
            history.push(
              `${this.props.businessId}/courses/${this.props.course.ID}`
            )
          }
          featureFlag="course-saving-v2"
          title="New Auto Saving Feature!"
          description="Hey, Brite is rolling out auto save for guides to make sure none of your hard work goes to waste. You can turn this feature on now or go to guide settings to turn it on later."
          confirmButtonText="Turn on Auto Save"
          cancelButtonText="Skip"
        />

        <ConfirmationModal
          display={this.state.exitModal}
          title="You have unsaved changes! Would you like to save before leaving?"
          onConfirm={() => this.save(true)}
          onClose={() => history.push(`/${this.props.businessId}/courses`)}
          onExit={() => this.setState({ exitModal: false })}
          closeText="Discard"
          confirmText="Save Changes"
        />
      </Layer>
    );
  }
}

export const BuilderContainer = compose(
  withRouter,
  connect(
    (state, ownProps) => ({
      course: state.courses.course,
      loading: state.courses.loading,
      courseLoaded: state.courses.courseLoaded,
      saving: state.courses.saving,
      hasUnsavedCourseChanges: state.courses.hasUnsavedCourseChanges,
    }),
    (dispatch) => {
      return {
        updateUnsavedChangesOff: () => {
          dispatch(updateUnsavedChangesOff());
        },
        updateUnsavedChanges: () => {
          dispatch(updateUnsavedChanges());
        },
        getCourse: (id) => {
          dispatch(getCourse(id));
        },
        deletePage: (id) => {
          dispatch(deletePage(id));
        },
        updateCourse: (course) => {
          dispatch(updateCourse(course));
        },
        updateCourseChanges: (course) => {
          dispatch(updateCourseChanges(course));
        },
        updateCoursePagesOrder: (result) => {
          dispatch(updateCoursePagesOrder(result));
        },
      };
    }
  )
)(Builder);
