import React, { useState, useEffect }  from "react";
import { css } from "emotion";
import { Div, Text } from "../../../shared/components";
import { CircularProgress } from "@material-ui/core";
import { CustomAxios } from "../../../redux/axios/axios";
import { flex, margin, px, sx } from "../../../shared/shared-styles";

export const LockedPage = ({ page, course, hideVendorMessage = true }) => {
  const [themeCSS, setThemeCSS] = useState("");
  const [themeCSSLoaded, setThemeCSSLoaded] = useState(false);

  const loadTheme = async () => {
    if (!themeCSSLoaded) {
      // get theme from API
      const resp = await CustomAxios.get(`/public/v1/course/${course.ID}/theme-css?pageID=${page.ID}`)
      setThemeCSS(resp.data);
      setThemeCSSLoaded(true);
    }
  };

  useEffect(() => {
    loadTheme();
  });

  return page?.ID && themeCSSLoaded ? (
    <div
      key={page?.ID}
      id="builder"
      className={css`
          border: 1px solid #dedede;
          border-radius: 5px;
          margin-left: 20px;
          display: flex;
          flex-direction: column;
          height: 100%;
          max-height: calc(100vh - 132px);
          flex: 1;
          overflow: auto;
        `}
    >
      <div>
        {hideVendorMessage && (
          <Div css={css`${flex('jcsb aic')} margin: ${px.md};`}>
            <Text styles="h3">Vendor Imported Page</Text>
            <Text styles="label">{page?.IsLocked ? 'Page is locked and cannot be edited.' : 'Page is not locked.'}</Text>
          </Div>
        )}
        
        <div className="themeable-content" dangerouslySetInnerHTML={{ __html: `<style type="text/css">${themeCSS}</style><div>${page?.HTMLContent}<div>`}} />
      </div>
    </div>
  ) : (
    <Div css={css`${flex('jcc aic')} height: 100%; width: 100%; padding: 8px;`}>
      <CircularProgress />
    </Div>
  );
};
