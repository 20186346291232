import { format } from "date-fns";
import { css } from "emotion";
import { Flag, Info, Ticket, X } from "phosphor-react";
import { useEffect, useState } from "react";
import { toast } from "react-toastify";
import { CustomAxios } from "../redux/axios/axios";
import { Div, Button, Modal, Text, Toggle, Input } from "../shared/components";
import { container, flex } from "../shared/shared-styles";
import { colors } from "../shared/styles";
import { useStore } from "../store-provider/use-store";
import posthog from "posthog-js";
import { useForm } from "../shared/use-form";
import { Tooltip } from "@material-ui/core";

export const SuperSettings = ({ display, onClose }) => {
  const {
    data: { devMode },
  } = useStore();

  const [modal, setModal] = useState("");
  const [ticket, setTicket] = useState({});

  const flagForm = useForm(
    {
      FlagName: "",
      FlagDescription: "",
      FlagDefaultValue: false,
      FlagDisplayAdmin: true,
    },
    [display]
  );

  const [flags, setFlags] = useState({});

  const updateFlags = async (activeFlags) => {
    let flags = {};
    const sortedFlags = activeFlags.sort();
    for (const item of sortedFlags) {
      const flag = posthog.getFeatureFlagPayload(item);
      flags[item] = flag;
    }
    setFlags(flags);
  };

  useEffect(() => {
    posthog.onFeatureFlags(updateFlags);
  }, []);

  const toggleFlag = async (flag, item) => {
    const oldFlags = { ...flags };
    try {
      setFlags({
        ...flags,
        [flag]: {
          ...item,
          value: !item?.value,
        },
      });
      await CustomAxios.put(`v1/featureflag`, {
        FlagName: flag,
        FlagValue: !item?.value,
      });
    } catch (err) {
      setFlags(oldFlags);
      toast.error(`Failed to update feature.`);
      console.warn(err);
      throw err;
    } finally {
      posthog.reloadFeatureFlags();
    }
  };

  const getTicket = async () => {
    if (modal === "ticket") {
      const copiedText = await navigator.clipboard.readText();
      if (
        typeof copiedText === "string" &&
        copiedText.includes("timestamp") &&
        copiedText?.includes("pathname")
      ) {
        const parsed = JSON.parse(copiedText);
        if ("timestamp" in parsed && "pathname" in parsed) {
          try {
            const url = new URL(parsed?.pathname);
            const nextUrl = `http://localhost:3000${url?.pathname}`;
            parsed.localPathname = nextUrl;
          } catch {
          } finally {
            setTicket(parsed);
          }
        }
      }
    }
  };

  const createFlag = async () => {
    try {
      CustomAxios.post(`v1/featureflag`, flagForm?.values);
      flagForm?.resetForm();
    } catch (err) {
      toast?.error("Error while creating flag.");
      console.log(err);
    } finally {
      setModal("");
    }
  };

  useEffect(() => {
    getTicket();
  }, [modal]);

  const getFlagName = (flag) => {
    const split = flag?.split("-");
    const name = split
      ?.map((item) => item?.charAt(0).toUpperCase() + item?.slice(1))
      ?.join(" ");
    return name;
  };

  return (
    <Modal full display={true} onClose={onClose}>
      <Div
        css={css`
          width: 100vw;
          height: 100vh;
          box-sizing: border-box;
        `}
      >
        <Div
          css={css`
            ${flex("space-between")}
            padding: 32px;
            height: 100px;
            width: 100vw;
            border-bottom: 1px solid ${colors.gray[300]};
          `}
        >
          <Text h1>Super Settings</Text>
          <Div
            css={css`
              ${flex("right")}
            `}
          >
            {devMode ? (
              <Div
                css={css`
                  ${flex("right")}
                `}
              >
                <Button
                  styles="icon"
                  onClick={() => setModal("ticket")}
                  hoverLabel="View Ticket"
                >
                  <Ticket />
                </Button>
                <Button
                  styles="icon"
                  onClick={() => setModal("create-flag")}
                  hoverLabel="Create Feature Flag"
                >
                  <Flag />
                </Button>
              </Div>
            ) : null}
            <Button styles="icon" onClick={onClose}>
              <X />
            </Button>
          </Div>
        </Div>

        <Div
          css={css`
            width: 100vw;
            height: calc(100vh - 100px);
            overflow: auto;

            margin: 32px;
            padding-bottom: 60px;
          `}
        >
          <Text
            h2
            css={`
              margin: 16px 32px;
            `}
          >
            Feature Flags
          </Text>
          <Div
            css={css`
              width: 100%;
              margin: 16px;
              box-sizing: border-box;
              ${flex("left wrap")}
            `}
          >
            {Object.entries(flags)?.map(([flag, item]) =>
              item?.displayAdmin ? (
                <Div
                  css={css`
                    ${flex("space-between center")}
                    ${container.hover}
                    border-radius: 8px;
                    padding: 8px 16px;
                    width: calc(50% - 64px);
                    width: 400px;
                    border-radius: 8px;
                    border: 1px solid ${colors.gray[200]};
                    margin: 8px;
                  `}
                  key={flag}
                  onClick={() => toggleFlag(flag, item)}
                >
                  <div
                    className={css`
                      ${flex("left")}
                      flex-grow: 1;
                    `}
                  >
                    <Text
                      label
                      bold={item?.value}
                      ellipsis
                      css={`
                        flex-grow: 1;
                      `}
                    >
                      {getFlagName(flag)}
                    </Text>
                    {item?.description ? (
                      <Tooltip title={item?.description || ""} placement="top">
                        <Info size={24} />
                      </Tooltip>
                    ) : null}
                  </div>

                  <Toggle checked={item?.value || false} />
                </Div>
              ) : null
            )}
          </Div>
        </Div>
      </Div>

      <Modal display={modal === "create-flag"} onClose={() => setModal("")}>
        <Div
          css={css`
            width: 500px;
            padding: 32px;
          `}
        >
          <Div
            css={css`
              ${flex("space-between")}
            `}
          >
            <Text h2>Create Feature Flag</Text>
            <Button styles="icon" onClick={() => setModal("")}>
              <X />
            </Button>
          </Div>
          <Div
            css={css`
              ${flex("space-between start")}
              padding: 8px 0;
              margin-top: 16px;
            `}
          >
            <Text label>Name</Text>
            <div
              className={css`
                width: 50%;
              `}
            >
              <Input
                {...flagForm?.getProperty("FlagName", {
                  transformValue: (value) => {
                    const data = value?.toLowerCase()?.replace(" ", "-");
                    return data;
                  },
                })}
              />
              <Text
                css={`
                  min-height: 16px;
                  padding-top: 4px;
                `}
              >
                {getFlagName(flagForm?.values?.FlagName)}
              </Text>
            </div>
          </Div>
          <Div
            css={css`
              ${flex("space-between")}
              padding: 8px 0;
            `}
          >
            <Text label>Description</Text>
            <Input
              css={`
                width: 50%;
              `}
              {...flagForm?.getProperty("FlagDescription")}
              rows={2}
            />
          </Div>
          <Div
            css={css`
              ${flex("space-between")}
              padding: 8px 0;
            `}
          >
            <Text label>Default Value</Text>
            <Toggle
              {...flagForm?.getProperty("FlagDefaultValue", { checkbox: true })}
            />
          </Div>
          <Div
            css={css`
              ${flex("space-between")}
              padding: 8px 0;
            `}
          >
            <Text label>Display in Admin</Text>
            <Toggle
              {...flagForm?.getProperty("FlagDisplayAdmin", { checkbox: true })}
            />
          </Div>
          <Div
            css={css`
              ${flex("right")}
              margin-top: 16px;
            `}
          >
            <Button
              secondary
              css={`
                margin-right: 16px;
              `}
              onClick={() => setModal("")}
            >
              Cancel
            </Button>
            <Button onClick={createFlag}>Create Flag</Button>
          </Div>
        </Div>
      </Modal>

      <Modal display={modal === "ticket"} onClose={() => setModal("")}>
        <Div
          css={css`
            width: 500px;
            padding: 32px;
          `}
        >
          {"timestamp" in ticket ? (
            <>
              <Text label bold>
                {ticket?.timestamp
                  ? format(new Date(ticket?.timestamp), "MM/dd/yyyy")
                  : ""}
              </Text>
              <Div
                css={css`
                  ${flex("space-around")} border-radius: 8px;
                  background-color: ${colors.gray[100]};
                  padding: 32px;
                  margin: 32px 0;
                `}
              >
                <a href={ticket?.localPathname} target="_blank">
                  <Button link>Local Link</Button>
                </a>
                <a href={ticket?.pathname} target="_blank">
                  <Button link>Production Link</Button>
                </a>
              </Div>

              <Div
                css={css`
                  max-height: 40vh;
                  overflow-y: auto;
                `}
              >
                {ticket?.requestErrors?.map((item) => (
                  <Div
                    css={css`
                      padding: 16px;
                    `}
                  >
                    <Div
                      css={css`
                        ${flex("left")}
                      `}
                    >
                      <Text
                        label
                        bold
                        css={css`
                          margin: 16px 0;
                          padding-right: 16px;
                        `}
                      >
                        {item?.method}
                      </Text>
                      <Div
                        css={css`
                          padding-left: 16px;
                          border-left: 1px solid ${colors.gray[300]};
                        `}
                      >
                        <Text label>{item?.message}</Text>
                        <Text>{item?.url}</Text>
                      </Div>
                    </Div>
                  </Div>
                ))}
              </Div>
            </>
          ) : (
            <Text>
              Copy ticket browser context JSON then close and re-open this
              modal.
            </Text>
          )}
        </Div>
      </Modal>
    </Modal>
  );
};
