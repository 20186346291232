import { Toolbar } from "../../toolbar-menus";
import { Text } from "./text";
import { useTextEditor } from "./use-text-editor";

export const OptimizedText = (props) => {
  const { ref, ...textEditorProps } = useTextEditor(props);

  return (
    <>
      <Text {...props} textEditorProps={textEditorProps} ref={ref} />
      {props?.isSelected && textEditorProps?.editorReady && (
        <Toolbar
          {...props}
          textUtils={{
            ...textEditorProps?.handlers,
            state: textEditorProps?.editorProps?.editorState,
          }}
        />
      )}
    </>
  );
};
