import { groupBy, merge } from "lodash";
import { toast } from "react-toastify";
import uuid from "uuid";
import { newPlan } from "../../Content/decision-tool-constants";
import { groupMultiNetworkPlans } from "../../redux/actions/courses";
import { CustomAxios } from "../../redux/axios/axios";
import {
  ADDITIONAL_TYPES,
  CORE_TYPES,
  MEDICAL_TYPES,
  PROGRAMS,
} from "../constants";

const emptyState = {
  medical: [],
  "pre-tax": [],
  core: [],
  additional: [],
  custom: [],
};

export const categorizeProducts = ({ products }) => {
  if (products && products.length) {
    return products.reduce((prev, item) => {
      const { Type } = item;
      let category = "";
      if (CORE_TYPES.includes(Type)) {
        category = "core";
      } else if (ADDITIONAL_TYPES.includes(Type)) {
        category = "additional";
      } else if (Type === "custom") {
        category = "custom";
      } else if (MEDICAL_TYPES.includes(Type)) {
        category = "medical";
      } else {
        category = "pre-tax";
      }
      return { ...prev, [category]: [...(prev[category] || []), item] };
    }, emptyState);
  }
  return emptyState;
};

export const fetchProductsForPackage = async (packageId) => {
  let obj = {};
  try {
    const response = await CustomAxios.get(
      `/v1/benefitspackage/${packageId}/product`
    );

    if (response && response.data && response.data.length > 0) {
      obj.products = response.data;
      obj.unfilteredProducts = response.data;
      let insurancePlans = [];
      let stopFetchingMedical = false;
      // TODO: This is called any time a benefits package page loads, or a plan is saved, so very frequently.
      // Could speed up the user experience by making these requests in parallel (for larger benefit packages it takes at least a few seconds to iterate over all these).
      for (let product of response.data) {
        if (product.Type === "insurance_plan" && !stopFetchingMedical) {
          try {
            const insurancePlanResponse = await CustomAxios.get(
              `/v1/plans/${product.ID}`
            );
            if (insurancePlanResponse && insurancePlanResponse.data) {
              if (
                insurancePlanResponse.data.PlanType.toLowerCase().includes(
                  "ichra"
                )
              ) {
                stopFetchingMedical = true;
                insurancePlans.push({
                  Name: "Ichra Plans",
                  MultiNetworkID: "",
                  ichraplans: true,
                });
              } else {
                insurancePlans.push(insurancePlanResponse.data);
              }
            }
          } catch (e) {
            console.log("could not get insurance plan data");
          }
        }
      }

      for (let plan of insurancePlans) {
        if (!plan.ichraplans) {
          if (plan.InNetworkPlanDesign.EmbeddedDeductibleOOPM) {
            plan.InNetworkPlanDesign.EmbeddedDeductibleOOPM = "Yes";
          } else {
            plan.InNetworkPlanDesign.EmbeddedDeductibleOOPM = "No";
          }
          plan.InNetworkPlanDesign.Coinsurance =
            plan.InNetworkPlanDesign.Coinsurance * 100.0;

          if (plan.EffectiveDate === "0001-01-01T00:00:00Z") {
            plan.EffectiveDate = null;
          }
        }
      }

      let grouped = groupBy(insurancePlans, "MultiNetworkID");
      obj.products = obj.products.concat(groupMultiNetworkPlans(grouped));

      obj.products = obj.products
        .filter((prod) => prod.Type !== "insurance_plan" || prod.grouped) // filter out insurance_plans that were fetched with the benefits package because otherwise we will have duplicates
        .map((prod) => {
          if (prod.Title || !prod.Name) {
            return prod;
          }
          prod.Title = `Medical Plan: ${prod.Name}`;
          return prod;
        })
        .sort((a, b) => (a.Title < b.Title ? -1 : 1));
    } else {
      obj.products = [];
    }
    obj.products = obj.products.map((product) =>
      merge(product, product.CardTemplate)
    );

    let groupedBuyUpProducts = groupBy(obj.products, "MultiNetworkID");

    let products = [];

    for (const key of Object.keys(groupedBuyUpProducts)) {
      let groupedProducts = groupedBuyUpProducts[key];
      if (key === "" || !key || key === "null" || groupedProducts.length < 2) {
        for (let product of groupedProducts) {
          products.push(product);
        }
      } else {
        let corePlan;
        let buyupPlan;
        for (let product of groupedProducts) {
          if (product.MultiNetworkCategory === "core") {
            corePlan = product;
          } else {
            buyupPlan = product;
          }
        }

        if (corePlan) {
          corePlan.buyup = buyupPlan;
          products.push(corePlan);
        } else {
          for (let product of groupedProducts) {
            products.push(product);
          }
        }
      }
    }

    obj.products = products;
    obj.categorized = categorizeProducts(obj);

    return obj;
  } catch (e) {
    obj.products = [];
    console.log(e);
    return obj;
  }
};

export const deleteProduct = async (packageId, product) => {
  if (product.ichraplans) {
    for (let p of product.unfilteredProducts) {
      if (p.Type === "insurance_plan") {
        await CustomAxios.delete(
          `/v1/benefitspackage/${packageId}/product/${product.ID}`
        );
      }
    }
  } else {
    await CustomAxios.delete(
      `/v1/benefitspackage/${packageId}/product/${product.ID}`
    );

    if (product.buyUpNetwork && product.buyUpNetwork.ID) {
      CustomAxios.delete(
        `/v1/benefitspackage/${packageId}/product/${product.buyUpNetwork.ID}`
      );
    }
    if (product.buyup && product.buyup.ID) {
      CustomAxios.delete(
        `/v1/benefitspackage/${packageId}/product/${product.buyup.ID}`
      );
    }

    if (product.narrowNetwork && product.narrowNetwork.ID) {
      await CustomAxios.delete(
        `/v1/benefitspackage/${packageId}/product/${product.narrowNetwork.ID}`
      );
    }
  }
};

export const getRawData = (productType) => {
  if (productType === "cancer_buyup") {
    return {
      AntiNausea: "",
      WaitingPeriod: "",
      RadiationChemo: "",
      CancerScreening: "",
      BoneMarrowStemCell: "",
      HospitalConfinement: "",
      PreExistingConditions: "",
      ExpermimentalTreatment: "",
      InitialDiagnosisBenefit: "",
      Disclaimer: "",
    };
  }

  if (productType === "vision_buyup") {
    return {
      Frames: "",
      EyeExams: "",
      PlanName: "",
      Disclaimer: "",
      EmployeeCost: false,
      ContactLenses: "",
      LensesBenefit: "",
    };
  }
  if (productType === "critical_illness") {
    return {
      LumpSumCashBenefit: "",
      ReoccurrenceBenefit: "",
      ConditionPercentages: [{ Name: "", Percentage: "" }],
      PreExistingConditions: "",
      Disclaimer: "",
    };
  }
  if (productType === "dental_buyup") {
    return {
      BasicServices: "",
      MajorServices: "",
      PreventativeServices: "",
      AnnualMaximum: "",
      Disclaimer: "",
    };
  }
  if (productType === "fsa_buyup") {
    return {
      Enabled: true,
      Disclaimer: "",
    };
  }
  if (productType === "long_term_disability") {
    return {
      BenefitAmountValue: "",
      BenefitAmountUpToMonthly: "",
      WaitingPeriod: "",
      BenefitDuration: "",
      PreExistingConditions: "",
      Disclaimer: "",
    };
  }
  if (productType === "dependant_care_fsa_buyup") {
    return {
      Enabled: true,
      Disclaimer: "",
    };
  }

  if (productType === "hsa_buyup") {
    return {
      Enabled: true,
      Disclaimer: "",
    };
  }
  if (productType === "hospital_indemnity") {
    return {
      AdmissionBenefit: "",
      ConfinementBenefitValue: "",
      ConfinementBenefitDaysUpTo: "",
      IntensiveCareBenefitValue: "",
      IntensiveCareBenefitDaysUpTo: "",
      PreExistingConditions: "",
      Disclaimer: "",
    };
  }
  if (productType === "healthcaregov") {
    return {
      CardText:
        "Based on your responses, you may not qualify for the available plans offered.",
      SecondaryText:
        "You do have options! Please click the link below to discuss your options with an advisor.",
      AdvisorLink: "",
      AdvisorPhone: "",
      Enabled: true,
      Disclaimer: "",
      Title: "Healthcare.gov Individual Plan",
    };
  }
  if (productType === "medicare_recommendation") {
    return {
      CardText: "You are eligible for Medicare! Speak to an advisor today!",
      SecondaryText:
        "Click the link below to speak with an advisor about Medicare and how it can benefit you and your loved ones.",
      AdvisorLink: "",
      AdvisorPhone: "",
      Enabled: true,
      Disclaimer: "",
      Title: "Medicare",
    };
  }
  if (productType === "reference_based_pricing") {
    return {
      CardText: "None",
      SecondaryText: "None",
      AdvisorLink: "",
      AdvisorPhone: "",
      Enabled: true,
      Disclaimer: "",
      Title: "Reference-Based Pricing",
    };
  }
  if (productType === "short_term_disability") {
    return {
      BenefitAmountValue: "",
      BenefitAmountUpToWeekly: "",
      WaitingPeriodIllness: "",
      WaitingPeriodSickness: "",
      BenefitDuration: "",
      PreExistingConditions: "",
      Disclaimer: "",
    };
  }
  if (productType === "accident_plan") {
    return {
      ERVisit: "",
      Xrays: "",
      Dislocations: "",
      Fractures: "",
      HospitalConfinementValue: "",
      HospitalConfinementDaysUpTo: "",
      InitialHospitalConfinement: "",
      OnJobIncluded: false,
      Disclaimer: "",
    };
  }

  if (productType === "supplimental_life") {
    return {
      SupplementalLifeCoverageAvailable: true, // always true until basic life is added, then this can be toggled off
      ADDIncluded: false,
      TotalLifeInsuranceBenefit: 0,
      TotalLifeInsuranceBenefitString: "",
      GuaranteeIssueAmount: 0,
      SpouseCoverage: 0,
      SpouseDependantCoverage: false,
      DependentCoverage: 0,
      Disclaimer: "",
    };
  }

  if (productType === "basic_life_v2") {
    return {
      EmployeeBenefit: "",
      ADDIncluded: false,
      SpouseDependantCoverage: false,
      SpouseBenefit: "",
      DependantBenefit: "",
    };
  }

  if (productType === "supplemental_life_v2") {
    return {
      EmployeeGuaranteeIssue: "",
      EmployeeMaximumCoverage: "",
      ADDIncluded: false,
      SpouseDependantCoverage: false,
      SpouseGuaranteeIssue: "",
      SpouseMaximumCoverage: "",
      DependantGuaranteeIssue: "",
      DependantMaximumCoverage: "",
    };
  }

  if (productType === "limited_fsa_buyup") {
    return {
      Enabled: true,
      Disclaimer: "",
    };
  }
  if (productType === "dental_ortho_buyup") {
    return {
      PreventativeServices: "",
      BasicServices: "",
      MajorServices: "",
      OrthodonticsLifetimeMax: "",
      Children: "",
      Adult: "",
      Disclaimer: "",
    };
  }

  return {};
};

export const initPlan = (currentPlan) => {
  let plan = { ...currentPlan };
  if (
    plan.PrescriptionDrugs.Tier4.ADOrCopay === "AD" &&
    plan.PrescriptionDrugs.Tier4.PercentOrDollar === "%" &&
    plan.PrescriptionDrugs.Tier4.Value === 101
  ) {
    plan.PrescriptionDrugs.Tier4.ADOrCopay = "N/A";
  }
  plan.PrescriptionDrugs.MedicalDeductibleApplies.ADOrCopay = "Flat Rate";
  if (plan.PrescriptionDrugs.MedicalDeductibleApplies.Value === -1) {
    plan.PrescriptionDrugs.MedicalDeductibleApplies.ADOrCopay =
      "Medical Deductible Applies";
  }
  if (plan.PrescriptionDrugs.MedicalDeductibleApplies.Value === -2) {
    plan.PrescriptionDrugs.MedicalDeductibleApplies.ADOrCopay = "None";
  }

  if (!plan.PrescriptionDrugs.PrescriptionDrugTier) {
    plan.PrescriptionDrugs.PrescriptionDrugTier = "4 Tier Structure";
  }

  plan.teleMedicine = {
    PlanID: plan.ID || "",
    TitleDescriptions: [
      {
        Title: "",
        Description: "",
      },
    ],
    CarrierName: "",
    CarrierID: "00000000-0000-0000-0000-000000000000",
  };

  return plan;
};

export const getNewPlan = () => initPlan(newPlan);

const cleanMedicalData = (benefit) => {
  if (benefit.PrescriptionDrugs.Tier4.ADOrCopay === "N/A") {
    benefit.PrescriptionDrugs.Tier4.ADOrCopay = "AD";
    benefit.PrescriptionDrugs.Tier4.PercentOrDollar = "%";
    benefit.PrescriptionDrugs.Tier4.Value = 101;
  }

  if (
    benefit.PrescriptionDrugs.MedicalDeductibleApplies.ADOrCopay ===
    "Medical Deductible Applies"
  ) {
    benefit.PrescriptionDrugs.MedicalDeductibleApplies.ADOrCopay = "AD";
    benefit.PrescriptionDrugs.MedicalDeductibleApplies.PercentOrDollar = "$";
    benefit.PrescriptionDrugs.MedicalDeductibleApplies.Value = -1;
  }

  if (benefit.PrescriptionDrugs.MedicalDeductibleApplies.ADOrCopay === "None") {
    benefit.PrescriptionDrugs.MedicalDeductibleApplies.ADOrCopay = "AD";
    benefit.PrescriptionDrugs.MedicalDeductibleApplies.PercentOrDollar = "$";
    benefit.PrescriptionDrugs.MedicalDeductibleApplies.Value = -2;
  }

  if (
    benefit.PrescriptionDrugs.MedicalDeductibleApplies.ADOrCopay === "Flat Rate"
  ) {
    benefit.PrescriptionDrugs.MedicalDeductibleApplies.ADOrCopay = "AD";
    benefit.PrescriptionDrugs.MedicalDeductibleApplies.PercentOrDollar = "$";
  }

  if (typeof benefit.InNetworkPlanDesign.EmbeddedDeductibleOOPM !== "boolean") {
    if (benefit.InNetworkPlanDesign.EmbeddedDeductibleOOPM === "No") {
      benefit.InNetworkPlanDesign.EmbeddedDeductibleOOPM = false;
    } else {
      benefit.InNetworkPlanDesign.EmbeddedDeductibleOOPM = true;
    }
  }

  benefit.PageID = "00000000-0000-0000-0000-000000000000";

  return benefit;
};

export const reconcileSharedValues = (core, auxillary) => {
  const filteredCore = Object.fromEntries(
    Object.entries(core).filter(
      ([key]) => key.charAt(0) === key.charAt(0).toUpperCase()
    )
  );

  return {
    ...filteredCore,
    ID: auxillary.ID,
    ApplicableZipCodes: auxillary.ApplicableZipCodes,
    HSACompatible: auxillary.HSACompatible,
    HSAContributions: { ...auxillary.HSAContributions },
    NetworkInformation: { ...auxillary.NetworkInformation },
    TotalMonthlyPremiums: { ...auxillary.TotalMonthlyPremiums },
    EmployeeContributions: { ...auxillary.EmployeeContributions },
    EmployeeContributionType: auxillary.EmployeeContributionType,
    HSAContributionType: auxillary.HSAContributionType,
    HideEmployerPremiumContributions:
      auxillary.HideEmployerPremiumContributions,
    MultiNetworkCategory: auxillary.MultiNetworkCategory,
  };
};

export const submitMedicalPlan = async (data, props) => {
  const benefit = { ...data };
  benefit.BusinessID = props.businessId;
  benefit.ReviewedAt = new Date().toISOString();

  let plans = [];
  let { buyUpNetwork = {}, narrowNetwork = {} } = benefit;

  if (buyUpNetwork.show || narrowNetwork.show) {
    benefit.MultiNetworkCategory = "core";
    if (!benefit.MultiNetworkID) {
      benefit.MultiNetworkID = uuid.v4();
    }
  } else {
    benefit.MultiNetworkCategory = "default";
    benefit.MultiNetworkID = "";
  }

  if (buyUpNetwork.show) {
    plans.push(buyUpNetwork);
  } else if (buyUpNetwork.ID) {
    try {
      await CustomAxios.delete(`/v1/plans/${buyUpNetwork.ID}`);
    } catch (e) {
      console.log("did not delete buy up plan");
    }
  }

  if (narrowNetwork.show) {
    plans.push(narrowNetwork);
  } else if (narrowNetwork.ID) {
    try {
      await CustomAxios.delete(`/v1/plans/${benefit.narrowNetwork.ID}`);
    } catch (e) {
      console.log("did not delete narrow plan");
    }
  }
  delete benefit.buyUpNetwork;
  delete benefit.narrowNetwork;
  plans.push(benefit);

  let corePlanId = "";

  for (let plan of plans) {
    plan = cleanMedicalData(plan);
    if (
      plan.MultiNetworkCategory !== "core" &&
      plan.MultiNetworkCategory !== "default"
    ) {
      plan = reconcileSharedValues(benefit, plan);
    }
    plan.Type = "insurance_plan";

    if (plan.ID && plan.ID !== "00000000-0000-0000-0000-000000000000") {
      try {
        let resp = await CustomAxios.put(`/v1/plans/${plan.ID}`, plan);
        if (
          plan.MultiNetworkCategory === "core" ||
          plan.MultiNetworkCategory === "default"
        ) {
          corePlanId = resp.data.ID;
        }
      } catch (e) {
        console.log("did not save plan");
      }
    } else {
      try {
        let resp = await CustomAxios.post(`/v1/plans`, plan);
        // Once the plan has been posted successfully, persist the MultiNetworkID so that when buyup and narrow networks are saved separately, they share the network_id.
        data.MultiNetworkID = resp.data.MultiNetworkID;
        if (
          plan.MultiNetworkCategory === "core" ||
          plan.MultiNetworkCategory === "default"
        ) {
          corePlanId = resp.data.ID;
        } else if (resp.data.MultiNetworkCategory === "narrow") {
          data.narrowNetwork = { ...resp.data, show: true };
        } else if (resp.data.MultiNetworkCategory === "buyup") {
          data.buyUpNetwork = { ...resp.data, show: true };
        }
        await CustomAxios.put(
          `/v1/benefitspackage/${props.packageId}/product/${resp.data.ID}`
        );
      } catch (e) {
        console.log("did not create plan");
      }
    }
  }

  const { includedPrograms = {} } = benefit;
  const programList = Object.values(includedPrograms);

  for (const program of programList) {
    if (program.show) {
      const { show, ...programData } = program;
      if (programData.ID) {
        try {
          const response = await CustomAxios.put(
            `v1/plans/${corePlanId}/programs/${programData.ID}`,
            programData
          );
          data.includedPrograms[programData.ProgramType] = {
            show: true,
            ...response.data,
          };
        } catch (err) {
          console.warn(err);
          toast.error(`Failed to save ${PROGRAMS[programData.ProgramType]}`);
        }
      } else {
        try {
          const response = await CustomAxios.post(
            `v1/plans/${corePlanId}/programs`,
            {
              PlanID: corePlanId,
              BusinessID: props.businessId,
              ...programData,
            }
          );
          data.includedPrograms[programData.ProgramType] = {
            show: true,
            ...response.data,
          };
        } catch (err) {
          console.warn(err);
          toast.error(`Failed to save ${PROGRAMS[programData.ProgramType]}`);
        }
      }
    } else if (program.ID) {
      // If there is a program is currently set, but the user has toggled it off (!program.show) then we need to delete it from the plan.
      try {
        await CustomAxios.delete(
          `v1/plans/${corePlanId}/programs/${program.ID}`
        );
      } catch (err) {
        console.warn(err);
        toast.error(`Failed to delete ${PROGRAMS[program.ProgramType]}`);
      }
    }
  }

  return corePlanId;
};

export const createBuyUpProduct = async (packageId, coreProduct) => {
  let id = uuid.v4();

  let emptyBenefit = {
    ProviderID: "00000000-0000-0000-0000-000000000000",
    ProviderName: "",
    Title: "",
    Subtitle: "",
    Description: "",
    TitleDescriptions: [{ Title: "", Description: "" }],
    Disclaimer: "",
    BusinessID: "",
    LogoURL: "",
    CallToActionText: "",
    CallToActionLink: "",
    Type: "",
    IsBuyupAvailable: false,
    ApplicableStates: [],
    RawData: {},
    HideEmployerPremiumContributions: false,
  };

  const RawData = getRawData(coreProduct.Type);
  let benefit = {
    ...emptyBenefit,
    Type: coreProduct.Type,
    RawData,
  };

  const cardTemplate = {
    TitleDescriptions: benefit.TitleDescriptions,
    Disclaimer: benefit.Disclaimer,
    LogoURL: benefit.LogoURL,
    CallToActionLink: benefit.CallToActionLink || "",
    CallToActionText: benefit.CallToActionText || "",
    RawData: benefit.RawData || {},
  };
  const product = {
    BusinessID: coreProduct.BusinessID,
    ProviderID: benefit.ProviderID,
    ProviderName: benefit.ProviderName,
    Title: benefit.Title,
    Subtitle: benefit.Subtitle,
    Description: benefit.Description,
    CardTemplate: cardTemplate,
    Type: coreProduct.Type,
    ApplicableStates: benefit.ApplicableStates,
    IsBuyupAvailable: benefit.IsBuyupAvailable,
    MultiNetworkID: id,
    MultiNetworkCategory: "buyup",
    HideEmployerPremiumContributions:
      benefit.HideEmployerPremiumContributions || false,
  };

  coreProduct.MultiNetworkID = id;
  coreProduct.MultiNetworkCategory = "core";
  const { data } = await CustomAxios.post(`/v1/product`, product);

  //Associate benefit to package
  await CustomAxios.put(`/v1/benefitspackage/${packageId}/product/${data.ID}`);

  await updateProduct(coreProduct, coreProduct.BusinessID);

  return data.ID;
};

export const createProduct = async (benefit, { businessId, packageId }) => {
  const cardTemplate = {
    TitleDescriptions: benefit.TitleDescriptions,
    Disclaimer: benefit.Disclaimer,
    LogoURL: benefit.LogoURL,
    CallToActionLink: benefit.CallToActionLink || "",
    CallToActionText: benefit.CallToActionText || "",
    RawData: benefit.RawData || {},
  };
  const product = {
    BusinessID: businessId,
    ProviderID: benefit.ProviderID,
    ProviderName: benefit.ProviderName,
    Title: benefit.Title,
    Subtitle: benefit.Subtitle,
    Description: benefit.Description,
    CardTemplate: cardTemplate,
    Type: benefit.Type,
    ApplicableStates: benefit.ApplicableStates,
    IsBuyupAvailable: benefit.IsBuyupAvailable,
    HideEmployerPremiumContributions:
      benefit.HideEmployerPremiumContributions || false,
    ReviewedAt: new Date().toISOString(),
  };

  const { data } = await CustomAxios.post(`/v2/product`, product);

  //Associate benefit to package
  await CustomAxios.put(`/v1/benefitspackage/${packageId}/product/${data.ID}`);

  return data.ID;
};

export const updateProduct = async (benefit, businessId) => {
  const cardTemplate = {
    TitleDescriptions: benefit.TitleDescriptions,
    Disclaimer: benefit.Disclaimer,
    LogoURL: benefit.LogoURL,
    CallToActionLink: benefit.CallToActionLink || "",
    CallToActionText: benefit.CallToActionText || "",
    RawData: benefit.RawData || {},
  };
  let addMulti = benefit.MultiNetworkCategory && benefit.MultiNetworkID;
  const product = {
    BusinessID: businessId,
    ProviderID: benefit.ProviderID,
    ProviderName: benefit.ProviderName,
    Title: benefit.Title,
    Subtitle: benefit.Subtitle,
    Description: benefit.Description,
    CardTemplate: cardTemplate,
    Type: benefit.Type,
    ApplicableStates: benefit.ApplicableStates,
    IsBuyupAvailable: benefit.IsBuyupAvailable,
    HideEmployerPremiumContributions: benefit.HideEmployerPremiumContributions,
  };

  if (benefit.ProductName) {
    product.ProductName = benefit.ProductName;
  }

  if (benefit.Cost) {
    product.Cost = benefit.Cost;
  }

  if (addMulti) {
    product.MultiNetworkCategory = benefit.MultiNetworkCategory;
    product.MultiNetworkID = benefit.MultiNetworkID;
  }

  await CustomAxios.put(`/v1/product/${benefit.ID}`, product);

  if (benefit.Type === "custom") {
    const question = { ...benefit.Question };

    if (question.ID) {
      try {
        const result = await CustomAxios.put(
          `/v1/decisiontool/questions/${question.ID}`,
          question
        );
        if (result.data.ID) {
          // benefit.Question must be updated, otherwise question.ID will remain blank and subsequent calls to Save Plan can re-create the question.
          benefit.Question = result.data;
        }
      } catch (err) {
        console.warn(err);
      }
    } else {
      const payload = {
        BusinessID: businessId,
        ProductID: benefit.ID,
        ...question,
      };
      try {
        const result = await CustomAxios.post(
          `/v1/decisiontool/questions`,
          payload
        );
        if (result.data.ID) {
          // benefit.Question must be updated, otherwise question.ID will remain blank and subsequent calls to Save Plan can re-create the question.
          benefit.Question = result.data;
        }
      } catch (err) {
        console.warn(err);
      }
    }
  }
};

export const importACAPlan = async (planSelection) => {
  try {
    let response = await CustomAxios.post(`/v1/plans`, planSelection);

    let plan = response.data;

    return plan;
  } catch (e) {
    console.error(e);
    toast.error("Error importing plan");
  }
};

export const isProductFilledOut = (benefit) => {
  const productType = benefit.Type;
  if (
    productType !== "insurance_plan" &&
    benefit.ProviderID === "00000000-0000-0000-0000-000000000000" &&
    !benefit.ProviderName
  ) {
    return false;
  }
  if (productType === "custom") {
    if (benefit.Title && benefit.Title !== "Your Custom Benefit") {
      return true;
    } else {
      return false;
    }
  }
  if (productType === "cancer_buyup") {
    if (benefit.RawData.RadiationChemo) {
      return true;
    } else {
      return false;
    }
  }

  if (productType === "vision_buyup") {
    if (benefit.RawData.PlanName) {
      return true;
    } else {
      return false;
    }
  }
  if (productType === "critical_illness") {
    if (benefit.RawData.LumpSumCashBenefit) {
      return true;
    } else {
      return false;
    }
  }
  if (productType === "dental_buyup") {
    if (benefit.RawData.AnnualMaximum) {
      return true;
    } else {
      return false;
    }
  }
  if (productType === "fsa_buyup") {
    return true;
  }
  if (productType === "long_term_disability") {
    if (benefit.RawData.BenefitAmountValue) {
      return true;
    } else {
      return false;
    }
  }
  if (productType === "dependant_care_fsa_buyup") {
    return true;
  }

  if (productType === "hsa_buyup") {
    return true;
  }
  if (productType === "hospital_indemnity") {
    if (benefit.RawData.AdmissionBenefit) {
      return true;
    } else {
      return false;
    }
  }
  if (productType === "healthcaregov") {
    if (benefit.RawData.AdvisorLink) {
      return true;
    } else {
      return false;
    }
  }
  if (productType === "medicare_recommendation") {
    if (benefit.RawData.AdvisorLink) {
      return true;
    } else {
      return false;
    }
  }
  if (productType === "reference_based_pricing") {
    if (benefit.RawData.AdvisorLink) {
      return true;
    } else {
      return false;
    }
  }
  if (productType === "short_term_disability") {
    if (benefit.RawData.BenefitAmountValue) {
      return true;
    } else {
      return false;
    }
  }
  if (productType === "accident_plan") {
    if (benefit.RawData.ERVisit) {
      return true;
    } else {
      return false;
    }
  }

  if (productType === "supplimental_life") {
    if (
      benefit.RawData.SupplementalLifeCoverageAvailable &&
      benefit.RawData.EmployerPaidCoverage
    ) {
      if (
        benefit.RawData.EmployerPaidCoverageAmount &&
        benefit.RawData.TotalLifeInsuranceBenefitString
      ) {
        return true;
      } else {
        return false;
      }
    }

    if (benefit.RawData.EmployerPaidCoverage) {
      if (benefit.RawData.EmployerPaidCoverageAmount) {
        return true;
      } else {
        return false;
      }
    }

    if (benefit.RawData.SupplementalLifeCoverageAvailable) {
      if (benefit.RawData.TotalLifeInsuranceBenefitString) {
        return true;
      } else {
        return false;
      }
    }
    return false;
  }

  if (productType === "limited_fsa_buyup") {
    return true;
  }
  if (productType === "dental_ortho_buyup") {
    if (benefit.RawData.PreventativeServices) {
      return true;
    } else {
      return false;
    }
  }

  return true;
};

export const auxillaryDefault = {
  show: false,
  Name: "",
  PlanType: "",
  ApplicableStates: [],
  ApplicableZipCodes: [],
  EmployeeContributionType: "%",
  HSACompatible: false,
  HSAContributionType: "HSA - No Employer Contribution",
  HideEmployerPremiumContributions: false,
  NetworkInformation: {
    Name: "",
    ProviderSearchURL: "",
    Description: "",
  },
  TotalMonthlyPremiums: {
    EmployeeOnly: 0,
    EmployeeSpouse: 0,
    EmployeeChildren: 0,
    Family: 0,
  },
  EmployeeContributions: {
    EmployeeOnly: 0,
    EmployeeSpouse: 0,
    EmployeeChildren: 0,
    Family: 0,
  },
  HSAContributions: {
    EmployeeOnly: 0,
    EmployeeSpouse: 0,
    EmployeeChildren: 0,
    Family: 0,
    EmployeeOnlyEmployerMonthlyMatch: 0,
    EmployeeSpouseEmployerMonthlyMatch: 0,
    EmployeeChildrenEmployerMonthlyMatch: 0,
    FamilyEmployerMonthlyMatch: 0,
    EmployeeOnlyEmployerMonthlyMatchMax: 0,
    EmployeeSpouseEmployerMonthlyMatchMax: 0,
    EmployeeChildrenEmployerMonthlyMatchMax: 0,
    FamilyEmployerMonthlyMatchMax: 0,
  },
};
