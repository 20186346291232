import { css } from "emotion";
import { set } from "lodash";
import { useEffect, useRef, useState } from "react";
import { Text } from "../../shared/components";
import { colors } from "../../shared/styles";
import { useEvent } from "../../shared/use-event";
import { editorUtils, getLocationPath, modifyContent } from "../provider/utils";
import { useEditor } from "../provider/use-editor-store";

const container = (dragging) => css`
  position: absolute;
  top: 0;
  bottom: 0;
  right: -3px;
  width: 6px;
  background-color: transparent;
  z-index: 150;
  .handle {
    pointer-events: none;
    display: none;
  }
  ${dragging
    ? `
    width: 64px;
    right: -32px;
    z-index: 200;
    .handle {
      display: inline;
      position: absolute;
      top: 0;
      bottom: 0;
      right: calc(50% - 2px);
      width: 5px;
      border-radius: 8px;
      border: 1px solid ${colors.purple};
      background-color: ${colors.purple};
      pointer-events: auto;
    }
  `
    : `
    :hover {
      ::after {
        content: '';
        position: absolute;
        top: 2px;
        right: 3px;
        left: 3px;
        bottom: 2px;
        border: 1px solid ${colors.purple};
        z-index: 10000000000;
      }
    }
  `}
  cursor: col-resize;
  pointer-events: auto;

  .hover {
    opacity: 0;
    position: absolute;
    top: calc(50% - 24px);
    color: ${colors.black};
    filter: drop-shadow(2px 2px 4px rgba(0, 0, 0, 0.3));
    font-weight: bold;
    background-color: ${colors.gray[200]};
    padding: 8px;
    border-radius: 8px;
    pointer-events: none;
    animation: 1s fadeinmenu 300ms;
    animation-fill-mode: forwards;
    z-index: 1000;
  }
  .left {
    right: calc(50% + 24px);
  }
  .right {
    left: calc(50% + 24px);
  }
`;

export const getWidthValue = (column, length) =>
  "width" in column ? Number(column.width) : 1 / length;
const roundWidth = (value, roundTo = "floor") =>
  roundTo
    ? Number((Math[roundTo](value * 100) / 100).toFixed(3))
    : Number(value.toFixed(3));

export const ColumnResizer = ({
  rowWidth,
  columns,
  rowIdx,
  index,
  resizeIndex,
  setResizeIndex,
}) => {
  const editor = useEditor();
  const {
    state: { drag, multiSelect, layout },
  } = editor;
  const [dragging, setDragging] = useState(false);

  const layoutWidths = columns.map((item) =>
    "width" in item ? Number(item.width) : null
  );
  const [widthList, setWidthList] = useState(layoutWidths);

  const ref = useRef(null);

  useEffect(() => {
    if (widthList.toString() !== layoutWidths.toString()) {
      if (multiSelect?.isSelecting) {
        let nextLayout = { ...layout };
        for (
          let rowIdx = multiSelect?.start;
          rowIdx <= multiSelect?.end;
          rowIdx++
        ) {
          const key = getLocationPath([rowIdx]);
          const row = nextLayout?.rows?.[rowIdx];
          if (row?.columns?.length === widthList.length) {
            for (let index = 0; index < widthList.length; index++) {
              const path = `${key}.columns.[${index}].width`;
              const safeWidth = Math.max(
                0.01,
                Math.min(1, widthList[index])
              ).toString();
              set(nextLayout, path, safeWidth);
            }
          }
        }
        modifyContent?.set(editor, [], nextLayout?.rows);
      } else {
        const key = getLocationPath([rowIdx]);
        let nextLayout = { ...layout };
        for (let index = 0; index < widthList.length; index++) {
          const path = `${key}.columns.[${index}].width`;
          const safeWidth = Math.max(
            0.01,
            Math.min(1, widthList[index])
          ).toString();
          set(nextLayout, path, safeWidth);
        }
        modifyContent?.set(editor, [], nextLayout?.rows);
      }
    }
  }, [widthList.toString()]);

  const handleMouseUp = () => {
    setDragging(false);
    setResizeIndex(null);
    if (editor?.state?.disableTileDrag) {
      editorUtils.setProperty(editor, "disableTileDrag", false);
    }
  };

  const handleMouseMove = (event) => {
    if (dragging && event.buttons !== 0) {
      event.stopPropagation();
      try {
        let widths = [...layoutWidths];
        const rect = ref?.current?.getBoundingClientRect?.();
        const rectWidth = Math.round(event.clientX - rect?.x);
        const value = rectWidth / rowWidth;
        const newWidth = roundWidth(value, "ceil");

        if (widths.includes(null)) {
          const totalWidths = widths.reduce(
            (prev, item) => (item !== null ? prev + item : prev),
            0
          );
          const totalNull = widths.reduce(
            (prev, item) => (item !== null ? prev + 1 : prev),
            0
          );
          const totalDiff = roundWidth(
            (1 - totalWidths) / (widths.length - totalNull),
            "ceil"
          );
          widths = widths.map((item) => (item === null ? totalDiff : item));
        }

        if (widths[index] !== newWidth) {
          const oldWidth = widths[index];
          widths.splice(index, 1, newWidth);
          const difference = roundWidth(oldWidth - widths[index], "");
          const nextWidth = roundWidth(
            Number(widths[index + 1]) + Number(difference),
            ""
          );
          widths.splice(index + 1, 1, nextWidth);
          let validWidths = widths.map((item) => Math.max(Number(item), 0.01));
          const widthsTotal = validWidths.reduce((p, v) => p + v, 0);
          const maxWidth = 1;
          if (widthsTotal !== maxWidth) {
            const adapter = widthsTotal > maxWidth ? -0.001 : 0.001;
            let idx = 0,
              dispersedCount = roundWidth(widthsTotal - 1);
            while (dispersedCount > 0.001) {
              dispersedCount -= 0.001;
              validWidths.splice(idx, 1, validWidths[idx] + adapter);
              idx = idx === validWidths?.length - 1 ? 0 : idx + 1;
            }
          }
          setWidthList(validWidths);
        }
      } catch (err) {
        console.log(err);
      }
    }
  };

  useEvent(window, "mousemove", handleMouseMove);
  useEvent(window, "mouseup", handleMouseUp, true);

  const leftWidth = (
    (layoutWidths[index] === null ? 1 / columns.length : layoutWidths[index]) *
    100
  ).toFixed(0);
  const rightWidth = (
    (layoutWidths[index + 1] === null
      ? 1 / columns.length
      : layoutWidths[index + 1]) * 100
  ).toFixed(0);

  if (drag?.isDragging) {
    return null;
  }

  return (
    <div
      ref={ref}
      className={css`
        position: absolute;
        top: 0;
        left: 0;
        right: 0;
        bottom: 0;
        pointer-events: none;
        background-color: transparent;
      `}
      onClick={(e) => e.stopPropagation()}
      onMouseLeave={() => {
        if (index !== resizeIndex) {
          setDragging(false);
        }
      }}
    >
      <div
        className={container(dragging)}
        onDragStart={(e) => e.stopPropagation()}
        onMouseLeave={() => {
          if (!dragging) {
            setResizeIndex(null);
          }
        }}
        onMouseOver={() => {
          setResizeIndex(index);
        }}
        onMouseDown={() => {
          if (resizeIndex !== null && !editor?.state?.disableTileDrag) {
            editorUtils.setProperty(editor, "disableTileDrag", true);
            setDragging(true);
          }
        }}
      >
        {index !== columns.length - 1 &&
          (resizeIndex === index || dragging) && (
            <>
              <Text className="hover left">{leftWidth}%</Text>
              <Text className="hover right">{rightWidth}%</Text>
            </>
          )}
        <div
          className="handle"
          onMouseLeave={(event) => {
            if (!dragging || event.buttons === 0) {
              setResizeIndex(null);
              setDragging(false);
            }
          }}
        />
      </div>
    </div>
  );
};
