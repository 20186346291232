import { useMemo, useState } from "react";
import { useDebounceValue } from "../shared/use-debounce-value";
import { useInfiniteScrollPagination } from "../shared/use-pagination";

export const useTemplates = ({ businessId, parentBusinessId }) => {
  const [tab, setTab] = useState("my-templates");
  const [filter, setFilter] = useState("all");
  const [search, setSearch] = useState("");
  const [carrierId, setCarrierId] = useState("");

  const [searchValue] = useDebounceValue(search, [search]);

  const query = useMemo(() => {
    if (tab === "brite-templates") {
      return {
        with_share_scope: "global",
        business_id: "9ac52e0d-99ca-4502-b85d-e9193dfc4b4c",
      };
    } else if (tab === "my-templates") {
      const with_share_scope =
        filter === "all"
          ? "business,inherited"
          : filter === "created"
          ? "business"
          : filter === "shared"
          ? "inherited"
          : "";
      return { with_share_scope };
    } else if (tab === "carrier-templates" && carrierId) {
      return {
        // TODO: Add carrier fiflter here
        // Maybe
        with_share_scope: "global",
        business_id: carrierId,
      };
    }
    return {
      with_share_scope: "global", // default
    };
  }, [tab, filter, businessId, carrierId, searchValue]);

  const paginatedQuery = useInfiniteScrollPagination({
    // TODO: Add search
    // `/v2/templates?search=${searchValue}`
    url: `/v2/templates`,
    params: { query: { ...query, limit: "20", search: searchValue } },
  });

  return {
    paginatedQuery,
    tab,
    filter,
    search,
    carrierId,
    handlers: {
      setTab,
      setFilter,
      setCarrierId,
      setSearch,
    },
  };
};
