import { useMemo, useState } from "react";
import { CompanySelect } from "../Sidebar/company-select";
import { Button, Modal, Text } from "../shared/components";
import { useForm } from "../shared/use-form";
import { useStore } from "../store-provider/use-store";

export const DuplicateStylesModal = ({ handleDuplicate, ...modalProps }) => {
  const {
    data: { selectedBusiness, companies, acls },
  } = useStore();

  const [isLoading, setIsLoading] = useState(false);

  const form = useForm({ selectedBusiness }, [
    selectedBusiness?.ID,
    modalProps?.display,
  ]);

  const accessibleCompanies = useMemo(() => {
    const list = companies.filter(({ ID, ParentID }) => {
      const id = ID in acls ? ID : ParentID;
      return acls[id].Acls.includes("write:course");
    });
    return list;
  }, [companies.length]);

  const duplicateBusiness = async (business) => {
    setIsLoading(true);
    await handleDuplicate(business);
    setIsLoading(false);
    modalProps.onClose();
  };

  return (
    <Modal {...modalProps}>
      <Modal.Paper>
        <Modal.Header
          title="Duplicate Design Styles"
          onClose={modalProps?.onClose}
        />
        <Modal.Body>
          <Text label>Save duplicate to</Text>
          <CompanySelect
            selectedBusiness={form?.values?.selectedBusiness}
            selectBusiness={({ option: selectedBusiness }) =>
              form?.setValues({ ...form?.values, selectedBusiness })
            }
            defaultBusinessList={accessibleCompanies}
            css={`
              height: 48px;
              padding: 0 8px;
              width: 65%;
              font-size: 0.8em;
            `}
            listHeight="30vh"
          />
        </Modal.Body>
        <Modal.Actions>
          <Button secondary onClick={modalProps?.onClose}>
            Cancel
          </Button>
          <Button
            onClick={() => duplicateBusiness(form?.values?.selectedBusiness)}
            disabled={isLoading}
          >
            Duplicate
          </Button>
        </Modal.Actions>
      </Modal.Paper>
    </Modal>
  );
};
