import { css } from "emotion";
import { X } from "phosphor-react";
import { Button, Div, Modal, Text } from "../../shared/components";
import { flex } from "../../shared/shared-styles";
import { useFeatureFlag } from "../../shared/use-feature-flag";

export const OptInModal = ({
  title,
  description,
  featureFlag,
  confirmButtonText,
  cancelButtonText,
  onConfirm,
  ...modalProps
}) => {
  const flag = useFeatureFlag(featureFlag);

  const handleFlag = async () => {
    try {
      await flag.toggleFlag();
      onConfirm();
    } catch {} // catch is handled in toggleFlag fn
  };

  const handleSkip = () => {
    flag.setUserHasViewed();
    modalProps.onClose();
  };

  return (
    <Modal
      onClose={modalProps.onClose}
      display={modalProps.display && flag.showOptIn}
    >
      <Div
        className={css`
          width: 700px;
          margin: 32px;
        `}
      >
        <Div
          css={css`
            ${flex("jcsb aic")}
          `}
        >
          <Text styles="h2 mn">{title}</Text>
          <Button styles="icon" onClick={modalProps.onClose}>
            <X />
          </Button>
        </Div>
        <Div
          css={css`
            margin: 0;
          `}
        >
          <Text styles="padv">{description}</Text>
          <Div
            css={css`
              ${flex("jcr aic")}
            `}
          >
            <Button styles="secondary mr" onClick={handleSkip}>
              {cancelButtonText}
            </Button>
            <Button onClick={handleFlag}>{confirmButtonText}</Button>
          </Div>
        </Div>
      </Div>
    </Modal>
  );
};
