import { css } from "emotion";
import { useSelected, useSlate } from "slate-react";
import { colors } from "../../styles";
import { useContext, useEffect } from "react";
import { SlateTextboxContext } from "./SlateCommentProvider";
import { Editor, Node } from "slate";
import { removeNode } from "./utils";

export const Mention = (props) => {
  const editor = useSlate();
  const { state, actions } = useContext(SlateTextboxContext);
  const selected = useSelected();
  const text = Node.string(props?.element);

  const resetMentionSearch = () => {
    actions?.merge({
      id: "",
      type: "",
      searchValue: "",
      isSelected: false,
    });
  };

  useEffect(() => {
    if (selected && !state?.isSelected && !props?.element?.data?.user?.UserID) {
      actions?.merge({
        id: props?.element?.data?.mentionId,
        type: "mention",
        searchValue: "",
        isSelected: true,
      });
    } else if (!selected && state?.id === props?.element?.data?.mentionId) {
      resetMentionSearch();
    }
    return resetMentionSearch;
  }, [selected]);

  useEffect(() => {
    if (selected && state?.isSelected && !props?.element?.data?.user?.UserID) {
      actions?.merge({ searchValue: text });
    }
  }, [text]);

  return (
    <span
      className={mentionStyle}
      content-editable={!props?.element?.data?.user?.UserID}
    >
      {props.children}
    </span>
  );
};

const mentionStyle = css`
  color: ${colors.purple};
`;
