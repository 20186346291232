import { MenuItem } from "@material-ui/core";
import { css, cx } from "emotion";
import { DotsThreeVertical, Palette } from "phosphor-react";
import { useState } from "react";
import { CustomAxios } from "../redux/axios/axios";
import {
  Button,
  ConfirmationModal,
  Div,
  DropMenu,
  NewTag,
  Text,
} from "../shared/components";
import { container, flex, px, sx } from "../shared/shared-styles";
import { colors } from "../shared/styles";
import { leftPanelStyle } from "./design-styles";
import { NewDesignStylesModal } from "./new-design-styles-modal";
import { BriteLoader } from "../shared/components/brite-loader";
import { DuplicateStylesModal } from "./duplicate-styles-modal";
import { toast } from "react-toastify";

export const designIconBox = (selected) => css`
  padding: 4px 8px;
  padding-top: 6px;
  margin-right: 16px;
  margin-left: 8px;
  border-radius: 8px;
  ${selected
    ? `
    border: 1px solid transparent;
    background-color: ${colors.purple};
    color: white;
  `
    : `
    border: 1px solid ${colors.gray[300]};
  `}
`;

export const DesignStylesList = ({
  designStyleProps,
  themeId,
  setThemeId = null,
  course = {},
  isPage = false,
}) => {
  const { setCurrent, designStylesQuery, createNewDesignStyle } =
    designStyleProps || {};

  const [modal, setModal] = useState(null);
  const [displayNewModal, setDisplayNewModal] = useState(false);

  const applyStyles = (event, id) => {
    event.stopPropagation();
    setThemeId(id);
  };

  const handleDelete = async () => {
    const id = modal?.item?.ID;
    const list = [...designStylesQuery.cache.data];
    try {
      const nextData = list.filter(({ ID }) => ID !== id);
      designStylesQuery.cache.setData(nextData);
      await CustomAxios.delete(`/v1/themes/${id}`);
    } catch (err) {
      designStylesQuery.cache.setData(list);
    }
  };

  const handleDuplicate = async (business) => {
    try {
      const url = `v1/themes/${modal?.item?.ID}/copies/${business?.ID}`;
      await CustomAxios.post(url);
      toast.success(`Successfully duplicated to ${business?.Name}`);
    } catch (err) {
      console.warn(err);
      toast.error(`Failed to duplicate`);
    } finally {
      designStylesQuery?.refetch();
    }
  };

  const hasTheme =
    !!themeId && themeId !== "00000000-0000-0000-0000-000000000000";

  return (
    <Div
      className={css`
        flex-grow: 1;
        height: calc(100vh - 100px);
        overflow-y: auto;
        width: 100%;
        min-width: 500px;
      `}
    >
      <Div
        css={cx(
          css`
            padding: ${px.md} 0;
          `,
          leftPanelStyle
        )}
      >
        <Div
          css={css`
            margin-bottom: ${px.xl};
          `}
        >
          <Div
            css={css`
              ${flex("jcsb aic")} margin-top: ${px.md};
              padding-top: ${px.md};
            `}
          >
            <Text styles="h2">Your Design Style Templates</Text>
            {!isPage && (
              <Button
                styles="secondary"
                onClick={() => setDisplayNewModal(true)}
              >
                Add Design Style
              </Button>
            )}
          </Div>
          {!designStylesQuery?.data?.length ? (
            <Div
              css={css`
                ${flex("left")} margin-top: ${px.xl};
                background-color: ${colors.gray[100]};
                padding: 16px;
                border-radius: 8px;
                svg {
                  margin-right: 16px;
                }
              `}
            >
              <Palette size={64} />
              <Div>
                <Text styles="h2">Uh Oh!</Text>
                <Text styles="label">
                  Looks like you don't have any Design Style templates setup.{" "}
                  <br />
                  Click the + to create a new template.
                </Text>
              </Div>
            </Div>
          ) : (
            <Text>Select a Design Style to attach it to your guide</Text>
          )}
        </Div>
        {designStylesQuery?.data.map((item) => (
          <Div
            css={css`
              ${flex("jcsb aic")}
              ${container.box}
              padding: 16px;
              ${hasTheme && item?.ID === themeId
                ? `outline: 2px solid ${colors.purple}; background-color: ${colors.gray[200]};`
                : ""}
              margin: 24px 4px;
              :hover {
                background-color: ${colors.gray[100]};
                cursor: pointer;
              }
            `}
            key={item?.ID}
            onClick={(event) =>
              applyStyles(event, item?.ID === themeId ? null : item?.ID)
            }
          >
            <Div
              css={css`
                ${flex("jcsb aic")} width: 100%;
              `}
            >
              <Div
                css={css`
                  ${flex("aic")}
                `}
              >
                <Div
                  className={designIconBox(hasTheme && item?.ID === themeId)}
                >
                  <Palette
                    color={
                      hasTheme && item?.ID === themeId
                        ? "#FFFFFF"
                        : colors.black
                    }
                  />
                </Div>
                <NewTag createdAt={item?.CreatedAt} />
                <Text
                  styles="h3 textWrap"
                  className={css`
                    flex-grow: 1;
                    max-width: 80%;
                  `}
                >
                  {item?.Name}
                </Text>
              </Div>

              <Div
                css={css`
                  ${flex("aic")}
                `}
              >
                {isPage ? null : (
                  <Button
                    styles="secondary"
                    css={`
                      margin-right: 8px;
                    `}
                    onClick={(e) => {
                      e.stopPropagation();
                      setCurrent(item);
                    }}
                  >
                    Edit
                  </Button>
                )}

                {item?.ID ? (
                  <DropMenu
                    button={
                      <Button styles="icon sm">
                        <DotsThreeVertical />
                      </Button>
                    }
                  >
                    <MenuItem
                      onClick={() => setModal({ type: "duplicate", item })}
                    >
                      Duplicate
                    </MenuItem>
                    <MenuItem
                      onClick={() => setModal({ type: "delete", item })}
                    >
                      Delete
                    </MenuItem>
                  </DropMenu>
                ) : (
                  <BriteLoader size={24} weight={8} overlay={false} />
                )}
              </Div>
            </Div>
          </Div>
        ))}
      </Div>

      <ConfirmationModal
        title="Are you sure you want to delete this design style?"
        display={modal?.type === "delete"}
        onClose={() => setModal(null)}
        onConfirm={handleDelete}
        isDeleteAlert
        confirmText="Delete Design Style"
      />

      <NewDesignStylesModal
        courseName={course?.Name}
        createNewDesignStyle={createNewDesignStyle}
        display={displayNewModal}
        onClose={() => setDisplayNewModal(false)}
      />

      <DuplicateStylesModal
        loading={modal?.isLoading}
        display={modal?.type === "duplicate"}
        onClose={() => setModal(null)}
        handleDuplicate={handleDuplicate}
      />
    </Div>
  );
};
