
export const FormatPhoneNumber = (str) => {
    //Filter to only numbers (and leading +)
    let cleaned = ('' + str).replace(/[^\d+]/g, '');

    //Check if the input is correct
    let match = cleaned.match(/^(1)?(\d{3})(\d{3})(\d{4})$/);
    
    if (match) {
      let intlCode = (match[1] ? '+1 ' : '')
      return [intlCode, '(', match[2], ') ', match[3], '-', match[4]].join('')
    }
    
    // If we couldn't format (maybe non-us number), still return the digits and +
    return cleaned;
  }