import { TextField, Tooltip } from "@material-ui/core";
import { css } from "emotion";
import { CornersIn, CornersOut, X } from "phosphor-react";
import { useState } from "react";
import { Button, Div, Text, Input } from "../../shared/components";
import { flex, px } from "../../shared/shared-styles";
import { colors } from "../../shared/styles";
import { editorUtils, modifyContent } from "../provider/utils";
import { Alignment } from "./menu-components/alignment";
import { useStore } from "../../store-provider/use-store";

export const settingsAvailable = {
  image: [
    "verticalAlign",
    "padding",
    "borderRadius-container",
    "borderRadius-component",
  ],
  text: ["verticalAlign", "borderRadius", "padding"],
  textV2: ["verticalAlign", "borderRadius", "padding"],
  video: ["borderRadius"],
  button: ["verticalAlign", "padding"],
  line: ["verticalAlign", "padding"],
  space: ["padding", "borderRadius", "verticalAlign"],
  document: ["verticalAlign", "padding"],
};

export const AdvancedToolbar = ({ editor, element, location }) => {
  const {
    data: { isBriteUser },
  } = useStore();

  const onUpdate = (updates) => modifyContent.merge(editor, location, updates);

  const [displayAdvanced, setDisplayAdvanced] = useState("");

  const getNumber = (value, type = "container") => {
    if (type === "container" || type === "all") {
      const style = element?.container?.attributes?.style;
      const v = style?.[value]?.replace(/\D/g, "") || 0;
      return v;
    } else if (type === "component") {
      const style = element?.attributes?.style;
      const v = style?.[value]?.replace(/\D/g, "") || 0;
      return v;
    }
  };

  const updateContainerStyle = (property, value) => {
    const updates = {
      container: { attributes: { style: { [property]: value } } },
    };
    onUpdate(updates);
  };

  const updatePadding = (key, value) => {
    updateContainerStyle(`padding${key}`, `${value}px`);
  };

  const settings = settingsAvailable?.[element?.component] || [];

  if (!settings?.length) {
    return null;
  }

  const BorderRadius = ({ label = "Corners", type = "all" }) => {
    const updateRadius = (style) => {
      let updates = {};
      if (type === "all") {
        updates = {
          container: { attributes: { style } },
          attributes: { style },
        };
      } else if (type === "container") {
        updates = {
          container: { attributes: { style } },
        };
      } else if (type === "component") {
        updates = {
          attributes: { style },
        };
      }
      onUpdate(updates);
    };

    const handleRadius = (key) => {
      const radius = getNumber(key, type);
      const nextRadius = radius !== 0 && radius !== "0" ? "0" : "8px";
      const corners = { [key]: nextRadius };
      updateRadius(corners);
    };

    const handleAllCorners = () => {
      const radius = getNumber("borderTopLeftRadius", type);
      const nextRadius = radius !== 0 && radius !== "0" ? "0" : "8px";
      updateRadius({
        borderTopLeftRadius: nextRadius,
        borderTopRightRadius: nextRadius,
        borderBottomLeftRadius: nextRadius,
        borderBottomRightRadius: nextRadius,
      });
    };

    return (
      <Div
        css={css`
          ${flex("space-between")} margin: 0 2px;
          padding: ${px.md};
          border-top: 1px solid ${colors.gray[200]};
        `}
      >
        <Text label capitalize>
          {label}
        </Text>
        <Div
          css={css`
            ${flex("right")}
          `}
        >
          <Button
            styles="icon"
            css={`
              margin-right: 16px;
            `}
            onClick={handleAllCorners}
            hoverLabel="Toggle All Corners"
          >
            {getNumber("borderTopLeftRadius", type) > 0 ? (
              <CornersOut weight="fill" />
            ) : (
              <CornersIn weight="fill" />
            )}
          </Button>
          <Div>
            <Div
              css={css`
                ${flex("aic")}
              `}
            >
              <Button
                styles="icon sm"
                onClick={() => handleRadius("borderTopLeftRadius")}
              >
                <div
                  className={css`
                    width: 16px;
                    height: 16px;
                    padding: 8px;
                    border-top-left-radius: ${2 *
                    getNumber("borderTopLeftRadius", type)}px;
                    border-top: 3px solid ${colors.black};
                    border-left: 3px solid ${colors.black};
                  `}
                />
              </Button>
              <Button
                styles="icon sm"
                onClick={() => handleRadius("borderTopRightRadius")}
              >
                <div
                  className={css`
                    width: 16px;
                    height: 16px;
                    padding: 8px;
                    border-top-right-radius: ${2 *
                    getNumber("borderTopRightRadius", type)}px;
                    border-top: 3px solid ${colors.black};
                    border-right: 3px solid ${colors.black};
                  `}
                />
              </Button>
            </Div>
            <Div
              css={css`
                ${flex("aic")}
              `}
            >
              <Button
                styles="icon sm"
                onClick={() => handleRadius("borderBottomLeftRadius")}
              >
                <div
                  className={css`
                    width: 16px;
                    height: 16px;
                    padding: 8px;
                    border-bottom-left-radius: ${2 *
                    getNumber("borderBottomLeftRadius", type)}px;
                    border-bottom: 3px solid ${colors.black};
                    border-left: 3px solid ${colors.black};
                  `}
                />
              </Button>
              <Button
                styles="icon sm"
                onClick={() => handleRadius("borderBottomRightRadius")}
              >
                <div
                  className={css`
                    width: 16px;
                    height: 16px;
                    padding: 8px;
                    border-bottom-right-radius: ${2 *
                    getNumber("borderBottomRightRadius", type)}px;
                    border-bottom: 3px solid ${colors.black};
                    border-right: 3px solid ${colors.black};
                  `}
                />
              </Button>
            </Div>
          </Div>
        </Div>
      </Div>
    );
  };

  return (
    <>
      {!!displayAdvanced ? (
        <>
          {displayAdvanced === "css" && (
            <>
              <TextField
                multiline
                rows={4}
                style={{
                  width: "100%",
                  padding: 0,
                  backgroundColor: "white",
                  marginRight: "8px",
                }}
                inputProps={{ style: { padding: 0 } }}
                variant="outlined"
                value={element.rawCss}
                onChange={(e) => onUpdate({ rawCss: e.target.value })}
              />
              <Tooltip title="Close" placement="top">
                <Button
                  styles="mt icon-active sm"
                  className={css`
                    margin-left: auto;
                  `}
                  onClick={() => setDisplayAdvanced("")}
                >
                  <X />
                </Button>
              </Tooltip>
            </>
          )}
        </>
      ) : (
        <Div onMouseDown={(e) => e.stopPropagation()}>
          <Text styles="h4 padv">Advanced Options</Text>
          <Div
            css={css`
              ${flex("jcsb aic")}
            `}
          >
            <Div
              css={css`
                width: 100%;
              `}
            >
              {settings.includes("verticalAlign") && (
                <Div
                  css={css`
                    ${flex("space-between")} margin: 0 2px;
                    padding: ${px.md};
                    border-top: 1px solid ${colors.gray[200]};
                  `}
                >
                  <Text label>Vertical Alignment</Text>
                  <Alignment
                    item={element}
                    onUpdate={onUpdate}
                    hideHorizontal={true}
                  />
                </Div>
              )}
              {settings.includes("borderRadius") && <BorderRadius type="all" />}
              {settings.includes("borderRadius-component") && (
                <BorderRadius
                  label={`${element?.component} Corners`}
                  type="component"
                />
              )}
              {settings.includes("borderRadius-container") && (
                <BorderRadius type="container" />
              )}
              {settings.includes("padding") && (
                <Div
                  css={css`
                    ${flex("space-between")} margin: 0 2px;
                    padding: ${px.md};
                    border-top: 1px solid ${colors.gray[200]};
                  `}
                >
                  <Text label>Padding</Text>
                  <Div>
                    <Div
                      css={css`
                        ${flex("aic")}
                      `}
                    >
                      <Div
                        css={css`
                          margin: 8px;
                        `}
                      >
                        <Text>Top</Text>
                        <Input
                          type="number"
                          css={`
                            width: 100px;
                            p {
                              margin: 0 8px;
                            }
                          `}
                          endIcon={<p>px</p>}
                          value={getNumber("paddingTop")}
                          onChange={(e) =>
                            updatePadding("Top", Number(e.target.value))
                          }
                        />
                      </Div>
                      <Div
                        css={css`
                          margin: 8px;
                        `}
                      >
                        <Text>Bottom</Text>
                        <Input
                          type="number"
                          css={`
                            width: 100px;
                            p {
                              margin: 0 8px;
                            }
                          `}
                          endIcon={<p>px</p>}
                          value={getNumber("paddingBottom")}
                          onChange={(e) =>
                            updatePadding("Bottom", Number(e.target.value))
                          }
                        />
                      </Div>
                    </Div>
                    <Div
                      css={css`
                        ${flex("aic")}
                      `}
                    >
                      <Div
                        css={css`
                          margin: 8px;
                        `}
                      >
                        <Text>Left</Text>
                        <Input
                          type="number"
                          css={`
                            width: 100px;
                            p {
                              margin: 0 8px;
                            }
                          `}
                          endIcon={<p>px</p>}
                          value={getNumber("paddingLeft")}
                          onChange={(e) =>
                            updatePadding("Left", Number(e.target.value))
                          }
                        />
                      </Div>
                      <Div
                        css={css`
                          margin: 8px;
                        `}
                      >
                        <Text>Right</Text>
                        <Input
                          type="number"
                          css={`
                            width: 100px;
                            p {
                              margin: 0 8px;
                            }
                          `}
                          endIcon={<p>px</p>}
                          value={getNumber("paddingRight")}
                          onChange={(e) =>
                            updatePadding("Right", Number(e.target.value))
                          }
                        />
                      </Div>
                    </Div>
                  </Div>
                </Div>
              )}
              {element?.component === "textV2" && isBriteUser ? (
                <Div
                  css={css`
                    ${flex("space-between")}
                    width: 100%;
                    padding: 16px;
                  `}
                >
                  <Text label>Fix Bullets</Text>
                  <Button
                    secondary
                    onClick={() =>
                      editorUtils.setProperty(editor, "selection", null)
                    }
                  >
                    Fix
                  </Button>
                </Div>
              ) : null}
            </Div>
          </Div>
        </Div>
      )}
    </>
  );
};
