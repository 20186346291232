import { css, cx } from "emotion";
import { noop } from "lodash";
import React, { forwardRef } from "react";
import { flex } from "../shared-styles";
import { buildStyles, colors } from "../styles";
import { buttonStyles } from "./button";

const divStyles = {
  box: `
    padding: 8px 16px;
    border-radius: 8px;
    background-color: white;
    filter: drop-shadow(2px 2px 4px rgba(0, 0, 0, .2));`,
  header: `
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 16px;
    h1 {
      font-weight: bold;
      font-size: 34px;
      line-height: 42px;
      color: #25282d;
      margin: 0;
      padding: 0;
      margin-right: 12px;
      margin-left: 8px;
    }`,
  alternatingList: `
    .alternating-item {
      :nth-child(even) {
        background-color: ${colors.gray[100]};
      }
    }
  `,

  alternatingListOdd: `
  .alternating-item {
    :nth-child(odd) {
      background-color: ${colors.gray[100]};
    }
  }
`,
  "tab-active": buttonStyles["tab-active"],
  "tab-inactive": buttonStyles["tab-inactive"],

  field: `
    ${flex("space-between")}
    padding: 16px 0;
    input { width: 50%; }
  `,
};

export const Div = forwardRef(
  (
    {
      children,
      styles = "",
      className = "",
      css: cssStyles = "",
      isForm = false,
      onSubmit = noop,
      flex: flexStyle = "",
      hoverLabel = "",
      ...rest
    },
    ref
  ) => {
    const flexStyles = flexStyle ? flex(flexStyle) : "";

    const combinedStyles = cx(
      css`
        ${buildStyles(divStyles, styles)}
      `,
      className,
      cssStyles,
      flexStyles
    );
    const props = { className: combinedStyles, ...rest, ref };
    const handleSubmit = (event) => {
      event.preventDefault();
      onSubmit(event);
    };

    if (isForm) {
      return (
        <form onSubmit={handleSubmit} {...props}>
          {children}
        </form>
      );
    }
    return <div {...props}>{children}</div>;
  }
);
