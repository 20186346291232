import { css, cx } from "emotion";
import { Div } from "./div";
import { Icon } from "./icon";
import { ReactComponent as BriteSpinner } from "./brite-spinner.svg";
import { flex } from "../shared-styles";

export const BriteLoader = ({
  isLoading = true,
  overlay = true,
  size = 100,
  weight = null,
  duration = 1,
  css: cssStyle = "",
  color = "#6D44FB",
  children = null,
}) => {
  const strokeWidth = weight ?? Math.min(size * 0.08, 8);
  const totalSteps = 8;
  const count = Array.from(new Array(totalSteps).keys());
  const delay = duration / totalSteps;
  const stepDegree = 360 / totalSteps;

  if (!isLoading) {
    return null;
  }

  const Spinner = (
    <Div
      className="spinner"
      css={css`
        position: relative;
        width: ${size}px;
        height: ${size}px;

        @keyframes spin {
          0% {
            opacity: 1;
          }
          60% {
            opacity: 0.5;
          }
          100% {
            opacity: 0;
          }
        }

        svg {
          position: absolute;
          top: 0;
          animation: ${duration}s ease infinite spin;
        }

        ${count.map((index) => {
          return `
            .spinner_${index + 1} {
              transform: rotate(${stepDegree * index + 1}deg);
              animation-delay: ${-1 * delay * (8 - index + 1)}s;
            }
          `;
        })}
      `}
    >
      <Icon
        SVG={BriteSpinner}
        size={size}
        weight={strokeWidth}
        className="spinner_0"
        color={color}
      />
      {count.map((index) => (
        <Icon
          key={index}
          SVG={BriteSpinner}
          size={size}
          weight={strokeWidth}
          className={`spinner_${index + 1}`}
          color={color}
        />
      ))}
    </Div>
  );

  return (
    <Div
      css={cx(
        overlay
          ? css`
              position: absolute;
              top: 0;
              right: 0;
              left: 0;
              bottom: 0;
              z-index: 1500;
              background-color: white;
              opacity: 1;
              transition: opacity 0.5s ease;
              ${flex("center column")}
              ${isLoading
                ? `pointer-events: auto;`
                : `opacity: 0; pointer-events: none;`}
            `
          : "",
        css`
          ${cssStyle}
        `
      )}
    >
      {Spinner}
      {children}
    </Div>
  );
};
