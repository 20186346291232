import { css } from "emotion";
import { CaretDown, CaretUp, X } from "phosphor-react";
import { useMemo, useState } from "react";
import { useQueryAPI } from "../react-query";
import { Button, Div, Input, Text } from "../shared/components";
import { Tabs } from "../shared/components/tabs";
import { container, flex } from "../shared/shared-styles";
import { colors } from "../shared/styles";
import { useOutsideClick } from "../shared/use-outside-click";

export const handleCarriers = (data) => {
  const filteredData = data.filter(({ Name }) => Name);
  return filteredData;
};

export const TemplateFilters = ({
  handlers,
  carrierId,
  tab,
  filter,
  businessId,
  parentBusinessId,
}) => {
  const { data: carriers = [], dataUpdatedAt } = useQueryAPI({
    url: `/v2/templates/businesses`,
    defaultValue: [],
    select: handleCarriers,
  });

  const [carrierSearch, setCarrierSearch] = useState("");
  const [showCarrierList, setShowCarrierList] = useState(false);
  const outsideListClickRef = useOutsideClick(() => setShowCarrierList(false));

  const handleCarrierSelect = (item) => {
    console.log(item);
    setCarrierSearch(item?.Name);
    handlers.setCarrierId(item?.BusinessID);
    setShowCarrierList(false);
  };

  const list = useMemo(() => {
    const list = carriers.filter((item) => {
      return item.Count > 0;
    });
    if (!carrierSearch) {
      return list;
    }
    return list.filter((item) =>
      item?.Name?.toLowerCase()?.includes(carrierSearch?.toLowerCase())
    );
  }, [dataUpdatedAt, carrierSearch]);

  const handleCarrierSearchChange = (e) => {
    setCarrierSearch(e.target.value);
    setShowCarrierList(true);
  };

  const resetCarrierSearch = () => {
    setCarrierSearch("");
    setShowCarrierList(true);
    handlers.setCarrierId("");
  };

  return (
    <>
      <Div
        css={css`
          border-bottom: 1px solid ${colors.gray[300]};
          margin: 16px 0;
        `}
      >
        <Tabs
          tabs={{
            "my-templates": "My Templates",
            "brite-templates": "Brite Templates",
            "carrier-templates": "Carrier Templates",
          }}
          current={tab}
          setCurrent={(tab) => handlers.setTab(tab)}
        />
      </Div>

      {tab === "my-templates" ? (
        businessId !== parentBusinessId && (
          <Div
            css={css`
              ${flex("left")} margin: 16px 0;
            `}
          >
            <Button
              pill-active={filter === "all"}
              pill-inactive={filter !== "all"}
              onClick={() => handlers?.setFilter("all")}
              css={`
                margin: 8px 0;
                margin-right: 8px;
              `}
            >
              All
            </Button>
            <Button
              pill-active={filter === "created"}
              pill-inactive={filter !== "created"}
              onClick={() => handlers?.setFilter("created")}
              css={`
                margin: 8px 0;
                margin-right: 8px;
              `}
            >
              Created
            </Button>

            <Button
              pill-active={filter === "shared"}
              pill-inactive={filter !== "shared"}
              onClick={() => handlers?.setFilter("shared")}
              css={`
                margin: 8px 0;
                margin-right: 8px;
              `}
            >
              Shared with me
            </Button>
          </Div>
        )
      ) : tab === "carrier-templates" ? (
        <Div
          css={css`
            position: relative;
            width: 300px;
            margin: 16px 0;
          `}
          ref={outsideListClickRef}
        >
          <Input
            css={`
              width: 300px;
            `}
            placeholder="Select a carrier"
            value={carrierSearch}
            onChange={handleCarrierSearchChange}
            onClick={() => setShowCarrierList(true)}
            endIcon={
              <>
                {carrierSearch || carrierId ? (
                  <Button styles="icon sm" onClick={resetCarrierSearch}>
                    <X color={colors.gray[500]} />
                  </Button>
                ) : null}
                <Button
                  styles="icon sm"
                  onClick={() => setShowCarrierList(!showCarrierList)}
                >
                  {showCarrierList ? <CaretUp /> : <CaretDown />}
                </Button>
              </>
            }
          />
          {showCarrierList ? (
            <Div
              css={css`
                position: absolute;
                top: calc(100% + 4px);
                left: 0;
                right: 0;
                max-height: 300px;
                z-index: 100;
                overflow: auto;
                ${container.box}
                padding: 8px;
              `}
            >
              {list?.map((item) => (
                <Text
                  label
                  onClick={() => handleCarrierSelect(item)}
                  css={`
                    ${container.hover}
                    padding: 8px;
                    border-radius: 8px;
                  `}
                >
                  {item?.Name}
                </Text>
              ))}
            </Div>
          ) : null}
        </Div>
      ) : null}
    </>
  );
};
