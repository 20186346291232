import { applyMiddleware, compose, createStore } from 'redux';
import createSagaMiddleware from 'redux-saga';
import { rootSaga } from './root-saga';
import { rootReducer } from './root-reducer';

export const configureStore = () => {
  const sagaMiddleware = createSagaMiddleware();
  const middleware = [sagaMiddleware];
  let composeEnhancers = compose;
  if (process.env.NODE_ENV === `development`) {
    // uncomment these lines if you want redux actions logged in the console
    // const { logger } = require(`redux-logger`);
    // middleware.push(logger);
    composeEnhancers = (window).__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ || compose;
  }
  const configuredStore = createStore(
    rootReducer,
    composeEnhancers(applyMiddleware(...middleware))
  );
  sagaMiddleware.run(rootSaga, configuredStore);
  return configuredStore;
};
export const store = configureStore();
