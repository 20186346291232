import React from "react";
import { css } from "emotion";
import { X } from "phosphor-react";
import { Button } from "./button";
import { Div } from "./div";
import { flex } from "../shared-styles";
import { Modal } from "./modal";
import { Text } from "./text";
import { colors } from "../styles";

export const ConfirmationModal = ({
  display,
  title = "Are you sure?",
  subtitle = "",
  onConfirm,
  onClose,
  onExit = onClose, // This is in case the onClose button is also doing things before closing rather than just exiting
  closeText = "Close",
  confirmText = "Confirm",
  isDeleteAlert = false,
}) => {
  const handleConfirm = () => {
    onConfirm();
    onExit();
  };

  return (
    <Modal display={display} onClose={onExit}>
      <Div
        css={css`
          width: 500px;
          padding: 32px;
        `}
      >
        <Div
          css={css`
            ${flex("space-between start")} padding-bottom: 32px;
            align-items: center;
          `}
        >
          <Text h2>{title}</Text>
          <Button styles="icon" onClick={onExit}>
            <X />
          </Button>
        </Div>
        {subtitle && (
          <div
            className={css`
              color: #66737f;
              margin-bottom: 32px;
              font-family: Roboto;
              font-size: 16px;
              font-style: normal;
              font-weight: 400;
              line-height: 24px;
              letter-spacing: 0.15px;
            `}
          >
            {subtitle}
          </div>
        )}
        <Div
          css={css`
            ${flex("right")}
          `}
        >
          <Button onClick={onClose} styles="secondary mr">
            {closeText}
          </Button>
          <Button
            onClick={handleConfirm}
            css={isDeleteAlert ? `background-color: ${colors.red[100]};` : ``}
          >
            {confirmText}
          </Button>
        </Div>
      </Div>
    </Modal>
  );
};
