import { css } from "emotion";
import { Div, Text } from "../../shared/components";
import { flex } from "../../shared/shared-styles";
import { colors } from "../../shared/styles";
import { FieldComponent } from "../field-components/field-component";
import { getFieldValue } from "../field-components/field-utils";

export const summarySectionConfigs = {
  defaultConfig: () => ({
    "Network Information": {
      hideSection: true,
    },
    "Medical Plan Details": {
      hideSection: true,
    },
  }),
};

export const summaryFieldConfigs = {
  defaultConfig: ({ product }) => {},
  custom: () => ({
    Description: {
      hideField: true,
    },
  }),
  insurance_plan: ({ product }) => ({
    "Label.InNetworkPlanDetails": {
      aboveTheFold: true,
    },
    "Details.InNetworkPlanDesign.SingleDeductible": {
      aboveTheFold: true,
    },
    "Details.InNetworkPlanDesign.Deductible": {
      aboveTheFold: true,
    },
    "Details.InNetworkPlanDesign.SingleOOPM": {
      aboveTheFold: true,
    },
    "Details.InNetworkPlanDesign.OOPM": {
      aboveTheFold: true,
    },
    "Details.InNetworkPlanDesign.Coinsurance": {
      aboveTheFold: true,
    },
    "Details.InNetworkPlanDesign.SingleEmbeddedDeductibleOOPM": {
      hideField: true,
    },
    "Details.InNetworkPrescriptionDrugs.PrescriptionDrugTier": {
      hideField: true,
    },
  }),

  vision_buyup: ({ product, fieldsObject }) => {
    return {
      "Details.EyeExamsFrequency": {
        hideField: true,
      },
      "Details.EyeExams": {
        components: [
          ({ field }) => (
            <VisionFrequency
              field={field}
              product={product}
              fieldsObject={fieldsObject}
              frequencyPropertyChain="Details.EyeExamsFrequency"
            />
          ),
        ],
      },
      "Details.LensesFrequency": {
        hideField: true,
      },
      "Details.LensesBenefit": {
        components: [
          ({ field }) => (
            <VisionFrequency
              field={field}
              product={product}
              fieldsObject={fieldsObject}
              frequencyPropertyChain="Details.LensesFrequency"
            />
          ),
        ],
      },
      "Details.ContactLensesFrequency": {
        hideField: true,
      },
      "Details.ContactLenses": {
        components: [
          ({ field }) => (
            <VisionFrequency
              field={field}
              product={product}
              fieldsObject={fieldsObject}
              frequencyPropertyChain="Details.ContactLensesFrequency"
            />
          ),
        ],
      },
      "Details.FramesFrequency": {
        hideField: true,
      },
      "Details.Frames": {
        components: [
          ({ field }) => (
            <VisionFrequency
              field={field}
              product={product}
              fieldsObject={fieldsObject}
              frequencyPropertyChain="Details.FramesFrequency"
            />
          ),
        ],
      },
      "Details.EmployeeCost": {
        hideField: true,
      },
    };
  },
  basic_life_v2: () => {
    return {
      "Details.ADDIncluded": {
        hideField: true,
      },
      "Details.SpouseDependantCoverage": {
        hideField: true,
      },
    };
  },
  supplemental_life_v2: () => {
    return {
      "Details.ADDIncluded": {
        hideField: true,
      },
      "Details.SpouseDependantCoverage": {
        hideField: true,
      },
    };
  },
  dental_buyup: () => {
    return {
      "Details.IncludesOrtho": {
        hideField: true,
      },
    };
  },
  hospital_indemnity: () => {
    return {
      "Label.DailyHospitalConfinement": {
        hideField: true,
      },
      "Label.DailyIntensiveCareBenefit": {
        hideField: true,
      },
    };
  },
  supplimental_life: ({ product }) => {
    return {
      "Details.EmployerPaidCoverage": {
        hideField: true,
      },
      "Details.EmployerPaidCoverageAmount": {
        components: [
          () => (
            <Div
              css={css`
                border: 1px solid ${colors.gray[200]};
                border-radius: 8px;
                background-color: white;
                padding: 16px;
                margin: 0 -8px;
              `}
            >
              <Text label bold>
                Group Life Insurance
              </Text>
              <Text>
                Your employer covers life insurance at no cost to you.
              </Text>
            </Div>
          ),
          ({ field }) => <GroupLife product={product} field={field} />,
        ],
      },
      "Details.SupplementalLifeCoverageAvailable": {
        components: [() => <SupplementalLife />],
      },
      "Details.SpouseDependantCoverage": {
        hideField: true,
      },
    };
  },
};

const GroupLife = ({ product, field }) => {
  return (
    <Div
      css={css`
        ${flex("space-between start")}
      `}
    >
      <Text label>{field?.RecDisplayValue}</Text>
      <FieldComponent field={field} product={product} />
    </Div>
  );
};

const SupplementalLife = () => {
  return (
    <Div
      css={css`
        border: 1px solid ${colors.gray[300]};
        border-radius: 8px;
        padding: 16px;
        margin: 0 -8px;
        background-color: white;
      `}
    >
      <Text label bold>
        Supplemental Life Insurance
      </Text>
      <Text>
        There is additional life insurance you should consider purchasing.
      </Text>
    </Div>
  );
};

const VisionFrequency = ({
  product,
  field,
  fieldsObject,
  frequencyPropertyChain,
}) => {
  const frequencyField = fieldsObject?.[frequencyPropertyChain];
  const frequencyValue = getFieldValue(product, frequencyField);

  return (
    <Div
      css={css`
        ${flex("space-between start")}
      `}
    >
      <Div>
        <Text label>{field?.RecDisplayValue || field?.DisplayValue}</Text>
        <Text
          css={`
            font-size: 0.9em;
          `}
        >
          Every {frequencyValue ? frequencyValue : "12 months"}
        </Text>
      </Div>
      <FieldComponent field={field} product={product} />
    </Div>
  );
};
