import { createAction, handleActions } from "redux-actions";
import { takeEvery, all, call, put, select } from "redux-saga/effects";
import { setBusiness } from "../actions/business";

import { CustomAxios } from "../axios/axios";
import { toast } from "react-toastify";
import posthog from "../../lib/analytics/posthog";
import { history } from "../../history";

export const loginSuccess = createAction("LOGIN_SUCCESS");
export const loginFailure = createAction("LOGIN_FAILURE");
export const loginRedux = createAction("LOGIN");
export const logoutRedux = createAction("LOGOUT");

export const createBusiness = createAction("CREATE_BUSINESS");
export const createBusinessSuccess = createAction("CREATE_BUSINESS_SUCCESS");
export const createBusinessFailure = createAction("CREATE_BUSINESS_FAILURE");

export const updateAuthBusinessInfo = createAction("UPDATE_AUTH_BUSINESS_INFO");

export const setAuthBusinessGod = createAction("SET_AUTH_BUSINESS_GOD");

export const createBusinessRelationship = createAction(
  "CREATE_BUSINESS_RELATIONSHIP"
);
export const createBusinessRelationshipSuccess = createAction(
  "CREATE_BUSINESS_RELATIONSHIP_SUCCESS"
);
export const createBusinessRelationshipFailure = createAction(
  "CREATE_BUSINESS_RELATIONSHIP_FAILURE"
);

export const deleteBusinessRelationship = createAction(
  "DELETE_BUSINESS_RELATIONSHIP"
);
export const deleteBusinessRelationshipSuccess = createAction(
  "DELETE_BUSINESS_RELATIONSHIP_SUCCESS"
);

const createBusinessRelationshipSaga = function* () {
  const handleCreateBusinessRelationship = function* (action) {
    try {
      let {
        Name,
        EmployeeCount,
        websiteURL,
        companyState,
        industry,
        firstName,
        lastName,
        email,
        inviteUser,
        userId,
      } = action.payload;

      let newBusiness = {
        EmployeeCount: Number(EmployeeCount),
        Name: Name,
        IsActive: false,
        Type: "single",
        LogoURL: "",
        Domain: websiteURL,
        State: companyState,
        Industry: industry,
        PaymentSource: "multi",
      };

      let state = yield select();

      let response = yield call(CustomAxios.post, `/v2/business`, newBusiness);

      let businessResp = response.data;

      let userNew = {
        FirstName: firstName || "",
        LastName: lastName || "",
        Email: email,
        Type: "singleAdmin",
        Business: {
          ID: businessResp.ID,
        },
      };

      const createBusinessRelationshipResponse = yield call(
        CustomAxios.post,
        `/v2/business/relationship`,
        {
          ParentID: state.auth.business.ID,
          ChildID: businessResp.ID,
        }
      );

      try {
        if (inviteUser) {
          const createUserResponse = yield call(
            CustomAxios.post,
            `/v1/users/${email}`,
            userNew,
            {
              headers: {
                BusinessID: businessResp.ID,
              },
            }
          );
        } else {
          const createUserResponse = yield call(
            CustomAxios.post,
            `/v1/users`,
            userNew,
            {
              headers: {
                BusinessID: businessResp.ID,
              },
            }
          );
        }
      } catch (e) {
        console.log("did not create user");
        if (e.response && e.response.status === 409) {
          toast.error(
            "Could not add user to new company because they are assigned to a different business"
          );
        }
      }

      let bizResponse = yield call(
        CustomAxios.get,
        `v2/business/user/${state.auth.userId}`
      );
      let multiBiz = bizResponse.data;
      let createBiz = multiBiz.Parent;
      createBiz.companies = multiBiz.Companies;
      let biz = createBiz;

      yield put(createBusinessRelationshipSuccess(biz));
    } catch (error) {
      yield put(createBusinessRelationshipFailure());
      toast.error("Could not create company");
    }
  };

  yield takeEvery(
    createBusinessRelationship.toString(),
    handleCreateBusinessRelationship
  );
};

const deleteBusinessRelationshipSaga = function* () {
  const handleDeleteBusinessRelationship = function* (action) {
    try {
      let state = yield select();

      let deleteBusiness = {
        ParentID: state.auth.business.ID,
        ChildID: action.payload,
      };

      let response = yield call(
        CustomAxios.delete,
        `/v2/business/${action.payload}`,
        { data: deleteBusiness }
      );

      let responseRelationship = yield call(
        CustomAxios.delete,
        `/v2/business/relationship`,
        { data: deleteBusiness }
      );

      let bizResponse = yield call(
        CustomAxios.get,
        `v2/business/user/${state.auth.userId}`
      );
      let multiBiz = bizResponse.data;
      let createBiz = multiBiz.Parent;
      createBiz.companies = multiBiz.Companies;
      let biz = createBiz;

      yield put(deleteBusinessRelationshipSuccess(biz));
    } catch (error) {
      toast.error("Could not delete company");
    }
  };

  yield takeEvery(
    deleteBusinessRelationship.toString(),
    handleDeleteBusinessRelationship
  );
};

const createBusinessSaga = function* (store) {
  const handlecreateBusiness = function* (action) {
    try {
      let {
        Name,
        EmployeeCount,
        user,
        showBusinessPopup,
        Type,
        IsActive,
        LogoURL,
        Industry,
        Domain,
        State,
        PaymentSource,
      } = action.payload;

      let business = {
        Name,
        EmployeeCount: parseInt(EmployeeCount),
        IsActive,
        Type: Type || "single",
        LogoURL,
        Industry,
        Domain,
        State,
        PaymentSource,
      };

      const response = yield call(
        CustomAxios.post,
        `/public/v1/business`,
        business
      );

      let businessResp = response.data;

      CustomAxios.setHeader("BusinessID", businessResp.ID);
      CustomAxios.setHeader("ParentID", businessResp.ID);

      let userNew = {
        FirstName: user.firstName || "",
        LastName: user.lastName || "",
        Email: user.emailAddress,
        Type: Type + "Admin",
        Business: {
          ID: businessResp.ID,
        },
      };

      const createUserResponse = yield call(
        CustomAxios.post,
        `/public/v1/users`,
        userNew
      );

      let userResp = createUserResponse.data;

      let businessId = "";
      if (userResp && userResp.Business) {
        businessId = userResp.Business.ID;
      }

      const termsResponse = yield call(CustomAxios.post, `/v1/terms`, {
        BusinessID: businessId,
        UserID: userResp.ID,
      });

      yield put(
        createBusinessSuccess({
          business: businessResp,
          businessId,
          userId: userResp.ID,
          isAccountActive: businessResp.IsActive,
          simplifiedType: userNew.Email.endsWith("@britebenefits.com")
            ? "brite"
            : userNew.Type.replace("Admin", ""),
        })
      );
      yield put(setBusiness(businessResp));
      showBusinessPopup(false);
      window.location.reload();
    } catch (error) {
      yield put(createBusinessFailure());
      toast.error("Failed to create business");
    }
  };

  yield takeEvery(createBusiness.toString(), handlecreateBusiness);
};

const loginSaga = function* (store) {
  const handleLogin = function* (action) {
    try {
      CustomAxios.setHeader(
        "Authorization",
        `Bearer ${action.payload.accessToken}`
      );
      localStorage.setItem(
        "authHeader",
        `Bearer ${action.payload.accessToken}`
      );
      CustomAxios.setInterceptors(store, action.payload.auth0FromHook);

      // send email to backend to get business id
      const response = yield call(
        CustomAxios.get,
        `/public/v1/users/${action.payload.email}`
      );

      let user = response.data;
      let businessId = "";
      // if user is found
      if (user && user.Business) {
        CustomAxios.setHeader("BusinessID", user.Business.ID);
        CustomAxios.setHeader("ParentID", user.Business.ID);
        businessId = user.Business.ID;
      }

      posthog.identify(user.ID, {
        assignedBusiness: user.Business.ID,
        email: user.Email,
        userType: user.Type.replace("Admin", ""),
        isAdmin: user.Type.includes("Admin"),
      });

      // if user is coming back let's check to see if we should show them some info about what happened while they were gone
      if (user.MostRecentPreviousLogin) {
        const since = new Date(user.MostRecentPreviousLogin);
        const daysSinceLastLogin = Math.floor(
          (new Date() - since) / 1000 / 60 / 60 / 24
        );

        // last login must be at least greater than 1 day ago so we don't accidentally spam users
        if (daysSinceLastLogin > 1) {
          try {
            const recentAnalyticsResp = yield call(
              CustomAxios.get,
              `/v1/analytics/recent?since=${user.MostRecentPreviousLogin}`
            );

            const recentAnalytics = recentAnalyticsResp.data;
            // only show recent analytics if there were at least 500 views since last login or we averaged greater than 50 views per day
            if (
              recentAnalytics.CourseViews &&
              (recentAnalytics.CourseViews.Total > 500 ||
                recentAnalytics.CourseViews.Total / daysSinceLastLogin > 50)
            ) {
              const acrossCompaniesMsg =
                recentAnalytics.CourseViews.CompanyCount > 1
                  ? `, across ${recentAnalytics.CourseViews.CompanyCount.toLocaleString()} different companies,`
                  : "";
              const sinceMsg = ` since you last logged in on ${since.toLocaleDateString()}`;
              posthog.capture("Showed Recent Analytics", {
                recentAnalytics: recentAnalytics,
              });
              toast.info(
                `Welcome back! We wanted to tell you that your guides were clicked ${recentAnalytics.CourseViews.Total.toLocaleString()} times${acrossCompaniesMsg}${sinceMsg}.`
              );
            }
          } catch (e) {
            console.log(
              "error getting recent analytics--will continue with login anyways",
              e
            );
          }
        }
      }

      let bizResponse;
      let biz;

      if (
        user.Type === "multi" ||
        user.Type === "multiAdmin" ||
        user.Type === "carrier" ||
        user.Type === "carrierAdmin"
      ) {
        bizResponse = yield call(
          CustomAxios.get,
          `/v2/business/user/${user.ID}`
        );
        let multiBiz = bizResponse.data;
        let createBiz = multiBiz.Parent;
        createBiz.companies = multiBiz.Companies;
        biz = createBiz;
      } else {
        bizResponse = yield call(CustomAxios.get, `/v2/business/${businessId}`);
        biz = bizResponse.data;
      }

      if (biz) {
        posthog.setPersonProperties({
          assignedBusinessName: biz.Name,
        });
      }

      if (user.Type === "singleAdmin") {
        //check to see if they have signed terms yet
        try {
          let termResponse = yield call(CustomAxios.get, `/v1/terms`);

          action.payload.setShowTermsPopup(false);
        } catch (e) {
          action.payload.setShowTermsPopup(true);
        }
      }

      yield put(
        loginSuccess({
          business: biz,
          businessId,
          userId: user.ID,
          isAccountActive: biz.IsActive,
          simplifiedType: user.Email.endsWith("@britebenefits.com")
            ? "brite"
            : user.Type.replace("Admin", ""), // remove suffix Admin from singleAdmin, etc
        })
      );

      const path = history.location.pathname;
      if (path === "/callback" || path === "/" || !path) {
        yield put(setBusiness(biz));
      }
      action.payload.setShowBusinessPopup(false);
      action.payload.setLoading(false);
    } catch (error) {
      yield put(loginFailure());
      action.payload.setLoading(false);
      action.payload.setShowBusinessPopup(true);
    }
  };

  yield takeEvery(loginRedux.toString(), handleLogin);
};

const logoutSaga = function* () {
  // eslint-disable-next-line
  const handleLogout = function* () {
    posthog.reset();
    CustomAxios.setHeader("Authorization", undefined);
    CustomAxios.setHeader("BusinessID", undefined);
    CustomAxios.setHeader("ParentID", undefined);
  };

  yield takeEvery(logoutRedux.toString(), handleLogout);
};

const authReducerDefaultState = {
  businessId: "",
  business: {},
  loading: false,
  userId: "",
  isAccountActive: false,
  simplifiedType: "",
  loadingAddCompany: false,
};

export const authReducer = handleActions(
  {
    [updateAuthBusinessInfo]: (state, action) => {
      console.log("state", state);
      console.log("action", action);
      return Object.assign({}, state, {
        business: {
          ...action.payload,
        },
      });
    },
    [setAuthBusinessGod]: (state, action) => {
      return Object.assign({}, state, {
        business: action.payload,
        businessId: action.payload.ID,
      });
    },
    [deleteBusinessRelationship]: (state) => {
      return Object.assign({}, state, { loadingDeleteCompany: true });
    },
    [deleteBusinessRelationshipSuccess]: (state, action) => {
      return Object.assign({}, state, {
        business: action.payload,
        loadingDeleteCompany: false,
      });
    },
    [createBusinessRelationshipSuccess]: (state, action) => {
      return Object.assign({}, state, {
        business: action.payload,
        loadingAddCompany: false,
      });
    },
    [createBusinessRelationship]: (state, action) => {
      return Object.assign({}, state, { loadingAddCompany: true });
    },

    [createBusinessRelationshipFailure]: (state, action) => {
      return Object.assign({}, state, { loadingAddCompany: false });
    },
    [loginSuccess]: (state, action) => {
      return Object.assign({}, state, {
        ...action.payload,
      });
    },
    [createBusinessSuccess]: (state, action) => {
      return Object.assign({}, state, {
        business: action.payload.business,
        businessId: action.payload.businessId,
        isAccountActive: action.payload.isAccountActive,
        simplifiedType: action.payload.simplifiedType,
        userId: action.payload.userId,
        loading: false,
      });
    },
    [createBusinessFailure]: (state, action) => {
      return Object.assign({}, state, {
        businessId: "",
        userId: "",
        loading: false,
        isAccountActive: false,
        simplifiedType: "",
        business: {},
      });
    },
    [createBusiness]: (state, action) => {
      return Object.assign({}, state, {
        businessId: "",
        userId: "",
        loading: true,
        isAccountActive: false,
        simplifiedType: "",
        business: {},
      });
    },
    [logoutRedux]: () => authReducerDefaultState,
    [loginFailure]: () => authReducerDefaultState,
  },
  authReducerDefaultState
);

export const authSaga = function* (store) {
  yield all([
    loginSaga(store),
    logoutSaga(),
    createBusinessSaga(),
    createBusinessRelationshipSaga(),
    deleteBusinessRelationshipSaga(),
  ]);
};
