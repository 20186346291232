import { cloneDeep, isEqual, merge } from "lodash";
import { useEffect, useMemo, useReducer, useState } from "react";
import {
  getResourceOptions,
  cacheKey,
} from "../../BriteEditor/use-editor-resource";
import { useQueryAPI, useResource } from "../../react-query";
import { getFieldsObject } from "./config-utils";
import { migrateSurestProduct, productValidators } from "./product-utils";
import { aiSuggestions } from "../../react-query/resources/ai-suggestions";
import { fieldHistory } from "../../react-query/resources/field-history";
import { needsAttention } from "../../react-query/resources/needs-attention";
import { differenceInMilliseconds } from "date-fns";

const getProduct = (state) => {
  const { productList, selectedProductId } = state;
  if (!productList?.length) {
    return {};
  }
  return productList.find(({ ID }) => selectedProductId === ID) || {};
};

export const useProductData = ({
  // *************** REQUIRED
  productId,
  packageId,
  businessId,
  // *************** FIELDS
  productType = "",
  editorType = "page",
  devMode = false,
}) => {
  // ************************************************************
  // **** REDUCER
  // ************************************************************

  const [state, dispatch] = useReducer(productReducer, {
    selectedProductId: productId,
    update: { type: "" },
    includedPrograms: {},
    customQuestion: {},
    productList: [],
    layout: {},
    suggestion: {},
  });

  const [loadState, setLoadState] = useState(false);
  const [initialLoadComplete, setInitialLoadComplete] = useState("");

  // ************************************************************
  // **** Product
  // ************************************************************

  const setSelectedProductId = (id) =>
    dispatch({
      type: "set-selected-product-id",
      id,
    });

  const productsQuery = useQueryAPI(
    {
      ...getResourceOptions({ packageId }).products,
      cacheKey,
      defaultValue: {},
      retry: 0,
      onMount: (data) => {
        dispatch({
          type: "set-fetched-product-list",
          data,
        });
      },
    },
    [state.selectedProductId]
  );

  const selectedProduct = useMemo(() => {
    if (editorType === "page" && !devMode) {
      return getProduct(state);
    } else {
      return { Type: productType };
    }
  });

  const typeCount = state?.productList?.filter(
    ({ Type }) => Type === selectedProduct?.Type
  )?.length;

  useEffect(() => {
    setSelectedProductId(productId);
  }, [productId]);

  // ************************************************************
  // **** Multi-Network
  // ************************************************************
  const category = selectedProduct?.MultiNetworkCategory || "core";

  const activeCategories = useMemo(() => {
    return state?.productList?.reduce((prev, item) => {
      if (item?.MultiNetworkID === selectedProduct?.MultiNetworkID) {
        return {
          ...prev,
          [item?.MultiNetworkCategory || "core"]: item?.ID,
        };
      }
      return prev;
    }, {});
  }, [state.productList, selectedProduct?.ID, category]);

  const setCategoryByProduct = (category) => {
    if (activeCategories[category]) {
      setSelectedProductId(activeCategories[category]);
    }
  };

  const getProductByCategory = (category) => {
    const isCurrentProductCore =
      category === "core" &&
      (!selectedProduct?.MultiNetworkCategory ||
        selectedProduct?.MultiNetworkCategory === "core");
    const isCurrentCategory =
      selectedProduct?.MultiNetworkCategory === category;

    if (!category || isCurrentProductCore || isCurrentCategory) {
      return selectedProduct;
    }
    return state?.productList?.find(
      ({ MultiNetworkCategory, MultiNetworkID }) =>
        MultiNetworkID === selectedProduct?.MultiNetworkID &&
        MultiNetworkCategory === category
    );
  };

  // ************************************************************
  // **** Layout
  // ************************************************************

  const layoutUrl = packageId
    ? `v1/product-layout/product/${selectedProduct?.ID}`
    : `v1/product-layout/${productType}`;
  const layoutQuery = useQueryAPI(
    {
      url: layoutUrl,
      enabled: !!selectedProduct?.ID || !!productType,
      defaultValue: { Layout: { Sections: [] } },
      retry: 0,
      onSuccess: (data) => {
        dispatch({
          type: "set-fetched-layout",
          data,
        });
      },
    },
    [selectedProduct?.ID]
  );

  // ************************************************************
  // **** Custom Questions
  // ************************************************************

  const customQuestionQuery = useQueryAPI({
    url: `/v1/product/${selectedProduct?.ID}/decisiontool/question`,
    enabled: selectedProduct?.Type === "custom" && !!selectedProduct?.ID,
    defaultValue: {},
    retry: 0,
    cacheTime: 0,
    onError: () => {
      if (selectedProduct?.Type === "custom" && !!selectedProduct?.ID) {
        const Responses = Array(3)?.fill({
          Text: "",
          ShouldRecommendProduct: false,
        });
        dispatch({
          type: "set-fetched-custom-question",
          data: { Responses },
        });
      }
    },
    onSuccess: (data) => {
      dispatch({
        type: "set-fetched-custom-question",
        data,
      });
    },
  });

  // ************************************************************
  // **** Included Programs
  // ************************************************************

  const includedProgramsEnabled =
    !!selectedProduct?.ID &&
    selectedProduct?.Type === "insurance_plan" &&
    (!selectedProduct?.MultiNetworkCategory ||
      selectedProduct?.MultiNetworkCategory === "core");

  const includedProgramsQuery = useQueryAPI({
    url: `/v1/plans/${selectedProduct?.ID}/programs`,
    defaultValue: {},
    retry: 0,
    cacheTime: 0,
    enabled: includedProgramsEnabled,
    defaultValue: {},
    select: (data) => {
      const programList = data || [];
      return programList.reduce((prev, item) => {
        return {
          ...prev,
          [item.ProgramType]: item,
        };
      }, {});
    },
    onSuccess: (data) =>
      dispatch({
        type: "set-fetched-included-programs",
        data,
      }),
  });

  const getResetProgramData = (type) => {
    return (
      includedProgramsQuery?.data?.[type] || {
        ProgramType: type,
        TitleDescriptions: [],
        CarrierID: null,
        CarrierName: null,
      }
    );
  };

  const getIncludedProgramsQueryData = () => {
    return includedProgramsQuery?.data;
  };

  // ************************************************************
  // **** AI Suggestions
  // ************************************************************

  const aiSuggestionsResource = useResource(aiSuggestions, {
    search: {
      product_id: `eq.${selectedProduct?.ID}`,
      product_field_history_id: "is.null",
    },
    enabled: !!selectedProduct?.ID,
    cacheTime: 0,
  });

  const fieldHistoryResource = useResource(fieldHistory, {
    search: { product_id: `eq.${selectedProduct?.ID}` },
    enabled: !!selectedProduct?.ID,
    cacheTime: 0,
  });

  // ************************************************************
  // **** Flags
  // ************************************************************

  const flags = useResource(needsAttention, {
    search: {
      resource_id: `eq.${selectedProduct?.ID}`,
      business_id: `eq.${businessId}`,
    },
    cacheTime: 0,
    enabled: !!selectedProduct?.ID && !!businessId,
    select: (data) => data.filter(({ resolved }) => !resolved),
  });

  // ************************************************************
  // **** Fetch + Cache Carriers
  // ************************************************************

  useQueryAPI({
    url: `/v1/carriers`,
    defaultValue: [],
  });

  // ************************************************************
  // **** Fields Object -> TODO: product
  // ************************************************************

  const fieldsObject = useMemo(
    () => getFieldsObject(state?.layout?.Layout?.Sections),
    [state?.layout?.Layout?.Sections]
  );

  // ************************************************************
  // **** Aggregated Properties
  // ************************************************************

  const refetch = () => {
    return Promise.allSettled([
      productsQuery?.refetch(),
      layoutQuery?.refetch(),
      customQuestionQuery?.refetch(),
      includedProgramsQuery?.refetch(),
      flags?.query?.refetch(),
    ]);
  };

  const isLoadingResources =
    productsQuery?.isLoading ||
    aiSuggestionsResource?.query?.isLoading ||
    flags?.query?.isLoading ||
    layoutQuery?.isLoading ||
    customQuestionQuery?.isLoading ||
    includedProgramsQuery?.isLoading;

  const isLoading = isLoadingResources || loadState || !initialLoadComplete;

  const allSettled = useMemo(() =>
    [
      productsQuery?.isSettled,
      layoutQuery?.isSettled,
      customQuestionQuery?.isSettled,
      includedProgramsQuery?.isSettled,
    ].every((value) => value)
  );

  useEffect(() => {
    const layoutIsLoaded =
      state?.update?.type === "set-fetched-layout" && !!initialLoadComplete;

    if (layoutIsLoaded) {
      const difference = differenceInMilliseconds(
        new Date(state?.update?.modifiedAt),
        new Date(initialLoadComplete)
      );
      if (difference < 2000) {
        migrateSurestProduct(state?.layout, dispatch);
      }
    }
  }, [state.update.modifiedAt]);

  useEffect(() => {
    if (allSettled && !initialLoadComplete) {
      setInitialLoadComplete(new Date().toISOString());
    }
  }, [allSettled]);

  // ************************************************************
  // **** Changes + Validators
  // ************************************************************

  const { isValid, isValidList, hasChanges, hasChangesList } = useMemo(() => {
    // Handle Changes here
    const hasChangesList = {
      product: !isEqual(productsQuery?.data, state?.productList),
      layout: !isEqual(layoutQuery?.data, state.layout),
      customQuestion: !isEqual(customQuestionQuery?.data, state.customQuestion),
      includedPrograms:
        includedProgramsEnabled &&
        !isEqual(includedProgramsQuery?.data, state.includedPrograms),
    };
    const hasChanges = Object.values(hasChangesList)?.some((value) => value);

    // Handle Validations here
    const isValidList = productValidators(state);
    const isValid = Object.values(isValidList)?.every((value) => value);

    return {
      hasChangesList,
      hasChanges,
      isValid,
      isValidList,
    };
  }, [
    state.update.modifiedAt,
    state.selectedProductId,
    includedProgramsEnabled,
  ]);

  // ************************************************************
  // **** Return Object
  // ************************************************************

  return {
    product: selectedProduct,
    network: {
      category,
      setCategoryByProduct,
      getProductByCategory,
      activeCategories,
    },

    // state
    layout: state?.layout,
    customQuestion: state?.customQuestion,
    includedPrograms: state?.includedPrograms,
    productList: state?.productList,
    update: state?.update,
    suggestion: state?.suggestion,
    save: state?.save,
    // ******

    // resources
    aiSuggestions: aiSuggestionsResource,
    flags,
    fieldHistory: fieldHistoryResource,
    // ******

    // META DETAILS
    productId: state?.selectedProductId,
    hasChangesList,
    fieldsObject,
    isValidList,
    businessId,
    hasChanges,
    packageId,
    isLoading,
    typeCount,
    isValid,
    searchTerm: state?.searchTerm,

    // METHODS
    getResetProgramData,
    getIncludedProgramsQueryData,
    setLoadState,
    dispatch,
    refetch,
  };
};

// ************************************************************
// **** Products Reducer
// ************************************************************

const productReducer = (nextState, action) => {
  const state = {
    ...nextState,
    update: { type: action.type, modifiedAt: new Date().toISOString() },
  };
  switch (action.type) {
    case "set-selected-product-id": {
      return {
        ...state,
        selectedProductId: action.id,
      };
    }

    case "set-fetched-product-list": {
      const productList = cloneDeep(action.data);
      return { ...state, productList };
    }

    case "set-fetched-layout": {
      const layout = cloneDeep(action.data);
      return { ...state, layout };
    }

    case "set-fetched-custom-question": {
      const customQuestion = cloneDeep(action.data);
      return { ...state, customQuestion };
    }

    case "set-fetched-included-programs": {
      const includedPrograms = cloneDeep(action.data);
      return { ...state, includedPrograms };
    }

    case "set-product": {
      const { data } = action;
      const productList = state?.productList?.map((item) =>
        data?.ID === item?.ID ? data : item
      );
      return { ...state, productList };
    }

    case "merge-product": {
      const { updates } = action;
      const productList = state?.productList?.map((item) => {
        const data = merge({}, item, updates);
        return state?.selectedProductId === item?.ID ? data : item;
      });
      return { ...state, productList };
    }

    case "set-layout": {
      const { data } = action;
      return { ...state, layout: data };
    }

    case "set-custom-question": {
      const { data } = action;
      return { ...state, customQuestion: data };
    }

    case "set-included-programs": {
      const { data } = action;
      return { ...state, includedPrograms: data };
    }

    case "select-suggestion": {
      const { data, source } = action;
      return {
        ...state,
        suggestion: {
          data,
          source,
          updatedAt: new Date().toISOString(),
        },
      };
    }

    case "reset-suggestion": {
      return {
        ...state,
        suggestion: {},
      };
    }

    case "set-selected-suggestions-status": {
      return {
        ...state,
        suggestion: {
          ...state?.suggestion,
          status: action?.data,
          updatedAt: new Date().toISOString(),
        },
      };
    }

    case "set-search-term": {
      return {
        ...state,
        searchTerm: action?.data,
      };
    }

    case "initiate-save": {
      return {
        ...state,
        save: {
          updatedAt: new Date().toISOString(),
          status: "save",
        },
      };
    }

    default:
      return { ...state };
  }
};
