import React, { Component } from "react";
import { css } from "emotion";
import EmailEditor from "react-email-editor";
import CircularProgress from "@material-ui/core/CircularProgress";
import { CustomAxios } from "../redux/axios/axios";
import _, { template } from "lodash";
import ButtonUI from "@material-ui/core/Button";
import PictureAsPdfIcon from "@material-ui/icons/PictureAsPdf";
import { toast } from "react-toastify";
import posthog from "posthog-js";

const productItemsTemplate = template(`
<div style="display:flex; justify-content: space-between; align-items: center; flex-wrap: wrap; max-height: 300px; overflow: auto;">
<% _.forEach(products, function(item) { %>
    <div style="padding: 12px; cursor: pointer;" id="logo-item" data-uuid='<%= item.url %>'>
      <img src="<%= item.url %>" style="height: auto; max-width: 120px;" />
    </div> 

<% }); %>
</div>
`);

export class Editor extends Component {
  constructor(props) {
    super(props);
    this.state = {
      loading: true,
      documents: [],
      isMounted: false,
      logos: [],
      mergeTags: {},
      designTags: {},
      designTagsDelimiter: null,
      exportingPDF: false,
    };

    this.editorRef = React.createRef();
  }

  componentDidMount() {
    this.getDocumentsAndLogos();
  }
  componentWillUnmount() {
    this.setState({ isMounted: false });
  }

  getDocuments = async () => {
    try {
      let response = await CustomAxios.get("/v2/media");

      let docs = response.data;
      docs.sort(function (a, b) {
        return new Date(b.CreatedAt) - new Date(a.CreatedAt);
      });

      let mappedDocs = docs.map((doc) => {
        return {
          label: doc.Name,
          value: `${doc.Name}&&&${doc.PublicURL}`,
        };
      });

      if (this.props.businessId !== this.props.parentBusiness.ID) {
        let parentResponse = await CustomAxios.get("/v2/media", {
          headers: {
            BusinessID: this.props.parentBusiness.ID,
          },
        });
        let parentDocs = parentResponse.data;
        parentDocs.sort(function (a, b) {
          return new Date(b.CreatedAt) - new Date(a.CreatedAt);
        });

        let mappedParentDocs = parentDocs.map((doc) => {
          return {
            label: doc.Name,
            value: `${doc.Name}&&&${doc.PublicURL}`,
          };
        });
        mappedDocs = mappedParentDocs.concat(mappedDocs);
      }

      return mappedDocs;
    } catch (e) {
      console.log("error getting documents", e);
      return [
        {
          label: "No documents",
          value: "No documents&&&https://getbritehr.com",
        },
      ];
    }
  };

  loadLogos = async () => {
    try {
      let logosResponse = await CustomAxios.get("/v1/carriers");
      let logos = logosResponse.data;
      let mappedLogos = logos.map((logo) => {
        return {
          url: logo.LogoURL,
        };
      });

      return mappedLogos;
    } catch (e) {
      return [];
    }
  };

  getDocumentsAndLogos = async () => {
    this.setState({ isMounted: true }, async () => {
      let mappedDocs = await this.getDocuments();
      await this.loadTags();
      let mappedLogos = await this.loadLogos();

      if (this.state.isMounted) {
        this.setState({
          loading: false,
          documents: mappedDocs,
          logos: mappedLogos,
        });
      }
    });
  };

  loadTags = async () => {
    if (!window.unlayer) {
      return;
    }

    // try to load tags too -- TODO: this shaould probably be in redux or something?
    let mergeTags = {};
    let designTags = {};
    try {
      let tagsResponse = await CustomAxios.get(
        `/v1/pages/${this.props.selectedPage.ID}/tags`
      );
      mergeTags = tagsResponse.data.MergeTags;

      this.setState({
        mergeTags: mergeTags,
        designTags: tagsResponse.data.DesignTags,
        designTagsDelimiter: tagsResponse.data.DesignTagsDelimiter,
      });

      window.unlayer.setMergeTags(mergeTags);
    } catch (e) {
      console.log("Tried to load tags for page but got an error, oh well", e);
    }
  };

  componentWillReceiveProps(nextProps) {
    if (
      nextProps.selectedPage.FrontendID !== this.props.selectedPage.FrontendID
    ) {
      this.onLoad();
    }
  }

  onLoad = () => {
    if (window.unlayer) {
      window.unlayer.loadDesign(this.props.selectedPage.Content);
    }
    if (this.props.selectedPage.ID) {
      this.loadTags();
    }
  };

  exportHtml = () => {
    window.unlayer.exportHtml((data) => {
      const { design, html } = data;
      console.log("exportHtml", html);
      alert("Output HTML has been logged in your developer console.");
    });
  };

  pdfPreview = async () => {
    if (!this.editorRef.current) {
      return;
    }
    this.setState({
      exportingPDF: true,
    });
    let resetState = () => this.setState({ exportingPDF: false });

    this.editorRef.current.exportHtml(async (data) => {
      try {
        let resp = await CustomAxios.post(
          `/v1/pages/${this.props.selectedPage.ID}/pdf`,
          {
            Content: data.design,
          }
        );
        window.open(resp.data.url, "_blank");
      } catch (e) {
        console.log("Error previewing PDF", e);
        toast.error("Error previewing PDF");
      }
      resetState();
    });
  };

  onDesignLoad = (data) => {
    // set body values if theme was provided (other defaults from the theme get set in the initialization options)
    if (this.props.courseTheme) {
      window.unlayer.setBodyValues({
        textColor: this.props.courseTheme.Body.FontColor,
        backgroundColor: this.props.courseTheme.Body.BackgroundColor,
        fontFamily: {
          label: this.props.courseTheme.Body.Font.Label,
          value: this.props.courseTheme.Body.Font.Value,
        },
      });
    }

    window.unlayer.addEventListener("design:updated", (args) => {
      this.props.updateUnsavedChanges();
      posthog.capture("CourseEdit", {
        actionType: "unlayer:" + args.type,
      });
    });
  };

  headingDefaultProperties = (courseTheme) => {
    let defaultOverrides = {};

    if (!courseTheme || !courseTheme.Heading) {
      return defaultOverrides;
    }

    if (
      courseTheme.Heading.FontOverride &&
      courseTheme.Heading.FontOverride.Label
    ) {
      defaultOverrides.fontFamily = {
        value: {
          label: courseTheme.Heading.FontOverride.Label,
          value: courseTheme.Heading.FontOverride.Value,
        },
      };
    }

    if (courseTheme.Heading.FontColorOverride) {
      defaultOverrides.color = {
        value: courseTheme.Heading.FontColorOverride,
      };
    }

    return defaultOverrides;
  };

  colorPickerPresets = (courseTheme) => {
    return courseTheme && courseTheme.ColorPickerPresets
      ? {
          presets: courseTheme.ColorPickerPresets,
        }
      : {};
  };

  render() {
    if (this.state.loading) {
      return (
        <div
          className={css`
            width: 100%;
            display: flex;
            justify-content: center;
            align-items: center;
          `}
        >
          <CircularProgress />
        </div>
      );
    }
    return (
      <>
        <div
          id="builder"
          className={css`
            border: 1px solid #dedede;
            border-radius: 5px;
            margin-left: 20px;
            display: flex;
            flex-direction: column;
            height: ${this.props.viewHeight};
            flex: 1;
          `}
        >
          <div
            className={css`
              flex: 1;
            `}
          >
            <EmailEditor
              ref={this.editorRef}
              options={{
                displayMode: "web",
                designTagsConfig:
                  this.state.designTagsDelimiter &&
                  this.state.designTagsDelimiter.length == 2
                    ? {
                        delimiter: this.state.designTagsDelimiter,
                      }
                    : null,
                designTags: this.state.designTags,
                mergeTags: this.state.mergeTags,
                mergeTagsConfig: {
                  autocompleteTriggerChar: "@",
                },
                user: {
                  id: this.props.parentBusiness.ID,
                },
                editor: {
                  autoSelectOnDrop: true,
                },
                features: {
                  textEditor: {
                    cleanPaste: true,
                    tables: true,
                  },
                  colorPicker: this.colorPickerPresets(this.props.courseTheme),
                },
                customCSS: `${CustomAxios.baseURL()}public/v1/course/${
                  this.props.course.ID
                }/theme-css?pageID=${this.props.selectedPage.ID}`,
                customJS: [
                  `
                  const productItemsTemplate = ${productItemsTemplate};
                  const editorTemplate = '<div style="padding-bottom: 24px;"><input style="padding: 10px;" placeholder="Search" type="text" id="search-bar" /><button id="search-btn" style="border: 1px solid transparent; margin-left: 12px; border-radius: 0.25rem; padding: 11px; background-color: #25282D; color: #fff; cursor: pointer;">Search</button></div><div id="logo-list"></div>';


                  unlayer.registerPropertyEditor({
                    name: "logo_library",
                    layout: "bottom",
                    Widget: unlayer.createWidget({
                      render(value, updateValue, data) {
                        return editorTemplate;
                      },
                      mount(node, value, updateValue, data) {
                        const list = document.querySelector("#logo-list");
                        list.innerHTML = productItemsTemplate({products: data.logos});


                        var selectButton = document.querySelector('#logo-list');
                        if (!selectButton) return;
                        selectButton.onclick = function (e) {
                         
                          if (e.target.id === 'logo-item') {
                            
                            const selectedProduct = data.logos.find(
                              (item) => item.url === e.target.dataset.uuid
                            );
                            console.log(selectedProduct)
                            updateValue({ logoLibrary: selectedProduct });
                          } else {
                           
                            const parent = e.target.parentElement;
                            if (parent && parent.id !== 'logo-item') return;
                            const selectedProduct = data.logos.find(
                              (item) => item.url === parent.dataset.uuid
                            );
                            console.log(selectedProduct)
                            updateValue({ logoLibrary: selectedProduct });
                          }
                         
                         
                        }

                        var searchBar = document.querySelector("#search-bar");
                        var searchButton = document.querySelector("#search-btn");
                        searchButton.onclick = function (e) {
                          const list = document.querySelector("#logo-list");
                          let filteredItem;
                          let productsListHtml;
                          if (list && data && data.logos) {
                            
                            if (searchBar.value === '') {
                              productsListHtml = productItemsTemplate({
                                products: data.logos,
                              });
                            } else {
                              filteredItem = data.logos.filter((item) =>
                                item.url
                                  .toLowerCase()
                                  .includes(searchBar.value.toLowerCase())
                              );
                              productsListHtml = productItemsTemplate({
                                products: filteredItem,
                              });
                            }
                            list.innerHTML = productsListHtml;
                          }
                        };
                      },
                    }),
                  });
                  
                  unlayer.registerTool({
                    name: "logo",
                    label: "Logo",
                    icon: "fa-image",
                    supportedDisplayModes: ["web", "email"],
                    options: {
                      logoLibrary: {
                        title: "Choose a Logo",
                        position: 1,
                        options: {
                          logoLibrary: {
                            label: "Add logo from library",
                            defaultValue: {logoLibrary: {url: ''}},
                            widget: "logo_library",
                          },
                        },
                      },

                      size: {
                        title: "Image Size",
                        position: 1,
                        options: {
                          width: {
                            label: "Width (use % or px values.. ie '120px' or '50%')",
                            defaultValue: "",
                            widget: "text",
                          },
                        },
                      },
                  
                      alignment: {
                        title: "Alignment",
                        position: 2,
                        options: {
                          alignment: {
                            label: "Text Alignment",
                            defaultValue: "left",
                            widget: "alignment", // built_in property editor
                          },
                        },
                      },
                    },
                    values: {},
                    transformer: (values, source) => {
                      const { name, value, data } = source;

                      let alignment;

                      if(values.alignment === "left") {
                        alignment = 'flex-start';
                      } else if(values.alignment === "center") {
                        alignment = 'center';
                      } else if(values.alignment === "right" || values.alignment === "justify") {
                        alignment = 'flex-end';
                      } else {
                        alignment = 'flex-start';
                      }
                     
                      let newValues = { ...values, alignment};
                  
                      
                      return newValues;
                    },
                    renderer: {
                      Viewer: unlayer.createViewer({
                        render(values) {
                          return "<div style='display: flex; justify-content:"+values.alignment+";'><img src='" + values.logoLibrary.logoLibrary.url + "' style='width: "+values.width+"; max-width: 100%; height: auto;' /> </div>";
                        },
                      }),
                      exporters: {
                        web: function (values) {
                          return "<div style='display: flex; justify-content:"+values.alignment+";'><img src='" + values.logoLibrary.logoLibrary.url + "' style='width: "+values.width+"; max-width: 100%; height: auto;' /> </div>";
                        },
                        email: function (values) {
                          return "<div style='display: flex; justify-content:"+values.alignment+";'><img src='" + values.logoLibrary.logoLibrary.url + "' style='width: "+values.width+"; max-width: 100%; height: auto;' /> </div>";
                        },
                      },
                      head: {
                        css: function (values) {},
                        js: function (values) {},
                      },
                    },
                  });`,
                  window.location.protocol +
                    "//" +
                    window.location.host +
                    "/custom.js",
                ],
              }}
              tools={{
                heading: {
                  properties: this.headingDefaultProperties(
                    this.props.courseTheme
                  ),
                },
                "custom#video_tool": {
                  enabled: true,
                },
                "custom#logo": {
                  enabled: true,
                  data: {
                    logos: this.state.logos,
                  },
                  properties: {
                    logoLibrary: {
                      editor: {
                        data: {
                          logos: this.state.logos,
                        },
                      },
                    },
                  },
                },

                "custom#document": {
                  enabled: true,
                  properties: {
                    // document is the name of our property
                    document: {
                      editor: {
                        data: {
                          options: this.state.documents,
                          token: localStorage.getItem("authHeader"),
                          businessID: this.props.businessId,
                          parentID: this.props.parentBusiness.ID,
                        },
                      },
                    },
                  },
                },
                "custom#embed_website": {
                  enabled: true,
                },
              }}
              key={this.props.selectedPage.FrontendID}
              projectId={2747}
              minHeight={this.props.viewHeight}
              onLoad={this.onLoad}
              onDesignLoad={this.onDesignLoad}
            />
          </div>
          <div style={{ marginTop: "5px" }}>
            <ButtonUI
              variant="contained"
              color="default"
              onClick={this.pdfPreview}
              disabled={
                this.state.exportingPDF ||
                this.state.loading ||
                !this.editorRef.current
              }
            >
              <PictureAsPdfIcon /> Preview
            </ButtonUI>
          </div>
        </div>
      </>
    );
  }
}
