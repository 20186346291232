import { css, cx } from "emotion";
import { Cpu, WarningCircle, X, StarFour } from "phosphor-react";
import { Text, Div, Input, Modal, Button } from "../../shared/components";
import { flex } from "../../shared/shared-styles";
import { useStore } from "../../store-provider/use-store";
import { colors } from "../../shared/styles";
import { FieldTypes } from "./field-types";
import { useEffect, useRef, useState } from "react";
import { getFieldProps } from "./field-utils";

import CheckCircleOutlineIcon from "@material-ui/icons/CheckCircleOutline";
import uuid from "uuid";
import { CustomAxios } from "../../redux/axios/axios";
import { productUtils } from "../configs/product-utils";
import { BriteLoader } from "../../shared/components/brite-loader";
import { Tooltip } from "@material-ui/core";

export const fieldContainerStyle = (isActiveField, field) => {
  return css`
    position: relative;
    padding: 8px 16px;
    padding-right: 8px;
    ${flex("space-between start wrap")}
    .label {
      padding: 8px 0;
      min-width: max-content;
    }
    .input-field {
      ${flex("right")}
    }
    ${field?.Type === "advancedInputField"
      ? `
        border-top: 1px solid ${colors.gray[300]};
        padding-top: 16px;
        padding-bottom: 0px;
        margin-top: 16px;
        margin-bottom: 12px;
    
        `
      : ""}
    ${isActiveField
      ? `
        background-color: ${colors.gray[100]};
        border-radius: 16px;
        .label { font-weight: bold; }
      `
      : ""}
  `;
};

const fields = (isCurrentSuggestion) => {
  return css`
    width: 100%;
    ${isCurrentSuggestion
      ? `
      .suggested-field {
        outline: 2px solid ${colors.black};
        border: 1px solid transparent;
        :hover {
          border: 1px solid transparent;
        }
      }
    `
      : ""}
  `;
};

export const Field = (props) => {
  const ref = useRef();
  const { state, field, config = {} } = props;

  const { suggestion, aiSuggestions } = state;

  const { data: store } = useStore();

  const { devMode } = store;
  const activePropertyChain = store?.selectedSpreadsheetId
    ? store?.["active-data-field"]?.name || ""
    : "";

  const { PropertyChain, DisplayValue } = field;
  const fieldProps = getFieldProps(state, field, config);

  const fieldSuggestion =
    aiSuggestions?.query?.data?.find(
      ({ property_chain, status }) =>
        property_chain === field?.PropertyChain &&
        (status === "completed" || status === "pending")
    ) || {};

  const isCurrentSuggestion =
    suggestion?.data?.property_chain === field?.PropertyChain;

  useEffect(() => {
    if (
      isCurrentSuggestion &&
      suggestion?.data?.id &&
      suggestion?.source === "suggestion"
    ) {
      ref.current?.scrollIntoView({ behavior: "smooth" });
    }
  }, [suggestion?.updatedAt, isCurrentSuggestion]);

  useEffect(() => {
    if (suggestion?.status === "selected") {
    }
  }, [suggestion?.status]);

  if (config?.hideField) {
    return null;
  }

  const isActiveField =
    activePropertyChain &&
    PropertyChain &&
    (activePropertyChain === PropertyChain ||
      activePropertyChain.includes(PropertyChain));

  const descriptions = field?.Description?.split("\n");
  const isBlockComponent = ["Cost"].includes(field?.PropertyChain);

  return (
    <>
      <Div
        css={css`
          scroll-margin-top: 30vh;
        `}
        ref={ref}
        onClick={() => {
          if (store?.devMode) {
            console.log(fieldProps);
          }
        }}
      >
        <Div
          css={cx(
            fieldContainerStyle(isActiveField, field),
            css`
              flex-grow: 1;
            `
          )}
        >
          {devMode ? (
            <Button
              styles="icon sm"
              css={`
                margin-top: 4px;
                margin-left: -16px;
                margin-right: 16px;
              `}
              hoverLabel="Edit Field Layout"
            >
              <Cpu />
            </Button>
          ) : null}
          {!isBlockComponent ? (
            <Div
              className="label"
              css={css`
                ${flex("space-between")}
                flex-grow: 1;
                svg {
                  margin-right: 16px;
                }
              `}
            >
              <Text label bold={field?.Type === "label"}>
                {DisplayValue}
              </Text>
              {config?.needsAttention ? (
                <WarningCircle
                  size={24}
                  color={colors.red[100]}
                  className={css`
                    position: absolute;
                    top: 16px;
                    left: -16px;
                  `}
                />
              ) : null}
            </Div>
          ) : null}
          <Div
            css={css`
              position: relative;
              ${flex("space-between")}
              width: 50%;
              ${isBlockComponent ? `width: 100%;` : ""}
            `}
          >
            {!!fieldSuggestion?.id && field?.Type !== "label" ? (
              fieldSuggestion?.status === "completed" ? (
                <Tooltip title="Auto-Fill Suggestion" placement="left">
                  <div
                    className={css`
                      position: absolute;
                      left: -24px;
                      top: calc(50% - 8px);
                      cursor: pointer;
                    `}
                    onClick={() =>
                      productUtils?.selectSuggestion(
                        state,
                        "field",
                        field?.PropertyChain
                      )
                    }
                  >
                    <StarFour color={colors.purple} size={16} weight="fill" />
                  </div>
                </Tooltip>
              ) : (
                <Tooltip title="Searching for a suggestion..." placement="left">
                  <div
                    className={css`
                      position: absolute;
                      left: -32px;
                      top: calc(50% - 12px);
                    `}
                  >
                    <BriteLoader
                      size={24}
                      weight={12}
                      overlay={false}
                      css={`
                        margin-right: 16px;
                      `}
                    />
                  </div>
                </Tooltip>
              )
            ) : null}
            <Div css={fields(isCurrentSuggestion)}>
              <FieldTypes
                state={state}
                fieldProps={fieldProps}
                //
                isBlockComponent={isBlockComponent}
              />
            </Div>
          </Div>
        </Div>

        <Div
          css={css`
            ${descriptions?.length
              ? `margin: 0 16px; margin-bottom: 24px;`
              : ""}
          `}
        >
          {descriptions?.map((item) => (
            <Text
              css={`
                margin-bottom: 8px;
              `}
            >
              {item}
            </Text>
          ))}
        </Div>
      </Div>
    </>
  );
};

export const EditCommentModal = ({
  display,
  field,
  onClose,
  flagged,
  state,
  getAllFlags,
}) => {
  const [note, setNote] = useState(flagged ? flagged.note : "");

  const resolve = async () => {
    try {
      let res = await CustomAxios.patch(
        `/v1/bp/needs_attention?id=eq.${flagged.id}&business_id=eq.${state.businessId}`,
        {
          id: flagged.id,
          resource_id: state.productId,
          resource_type: "product",
          business_id: state.businessId,
          note: note,
          metadata: {
            property_chain: field.PropertyChain,
            benefit_package_id: state.packageId,
          },
          resolved: true,
        },
        {
          headers: {
            "Accept-Profile": "brite",
            "Content-Profile": "brite",
          },
        }
      );
      getAllFlags();
    } catch (e) {
      console.log("unable to resolve needs_attention", e);
    }

    onClose();
  };

  const onSave = async () => {
    try {
      let res = await CustomAxios.patch(
        `/v1/bp/needs_attention?id=eq.${flagged.id}&business_id=eq.${state.businessId}`,
        {
          id: flagged.id,
          resource_id: state.productId,
          resource_type: "product",
          business_id: state.businessId,
          note: note,
          metadata: {
            property_chain: field.PropertyChain,
            benefit_package_id: state.packageId,
          },
        },
        {
          headers: {
            "Accept-Profile": "brite",
            "Content-Profile": "brite",
          },
        }
      );
      getAllFlags();
    } catch (e) {
      console.log("unable to upsert needs_attention", e);
    }

    onClose();
  };

  return (
    <Modal display={display} onClose={onClose}>
      <Div
        css={css`
          width: 500px;
          padding: 32px;
        `}
      >
        <Div
          css={css`
            ${flex("space-between")}
          `}
        >
          <Text h2>Edit Field Flag </Text>
          <Button styles="icon" onClick={onClose}>
            <X />
          </Button>
        </Div>

        <Div
          css={css`
            margin: 16px 0;
          `}
        >
          <Div
            css={css`
              ${flex("space-between")}
              margin: 8px 0;
            `}
          >
            <Text
              className={css`
                color: var(--700, #25282d);
                font-feature-settings: "clig" off, "liga" off;
                font-family: Roboto;
                font-size: 14px;
                font-style: normal;
                font-weight: 700;
                line-height: 24px; /* 171.429% */
                letter-spacing: 0.15px;
              `}
            >
              Flag Description
            </Text>
          </Div>
          <Input
            placeholder="Why'd you flag this field?"
            rows={3}
            value={note}
            onChange={(e) => setNote(e.target.value)}
          />
        </Div>
        <Div
          css={css`
            ${flex("right")}
          `}
        >
          <Button secondary onClick={resolve}>
            <CheckCircleOutlineIcon />
            Resolve
          </Button>
          <Button
            css={`
              margin-left: 16px;
            `}
            onClick={onSave}
          >
            Save
          </Button>
        </Div>
      </Div>
    </Modal>
  );
};
export const AddCommentModal = ({
  display,
  field,
  onClose,
  state,
  getAllFlags,
}) => {
  const [note, setNote] = useState("Needs Attention");
  const onSave = async () => {
    try {
      let res = await CustomAxios.post(
        `/v1/bp/needs_attention?business_id=eq.${state.businessId}`,
        {
          id: uuid.v4(),
          resource_id: state.productId,
          resource_type: "product",
          business_id: state.businessId,
          note: note,
          metadata: {
            property_chain: field.PropertyChain,
            benefit_package_id: state.packageId,
          },
        },
        {
          headers: {
            "Accept-Profile": "brite",
            "Content-Profile": "brite",
            Prefer: "return=representation",
          },
        }
      );
      getAllFlags();
    } catch (e) {
      console.log("unable to add needs_attention", e);
    }
    onClose();
  };

  return (
    <Modal display={display} onClose={onClose}>
      <Div
        css={css`
          width: 500px;
          padding: 32px;
        `}
      >
        <Div
          css={css`
            ${flex("space-between")}
          `}
        >
          <Text h2>Flag Field </Text>
          <Button styles="icon" onClick={onClose}>
            <X />
          </Button>
        </Div>

        <Div
          css={css`
            margin: 16px 0;
          `}
        >
          <Div
            css={css`
              ${flex("space-between")}
              margin: 8px 0;
            `}
          >
            <Text
              className={css`
                color: var(--700, #25282d);
                font-feature-settings: "clig" off, "liga" off;
                font-family: Roboto;
                font-size: 14px;
                font-style: normal;
                font-weight: 700;
                line-height: 24px; /* 171.429% */
                letter-spacing: 0.15px;
              `}
            >
              Flag Description
            </Text>
          </Div>
          <Input
            placeholder="Why'd you flag this field?"
            rows={3}
            value={note}
            onChange={(e) => setNote(e.target.value)}
          />
        </Div>
        <Div
          css={css`
            ${flex("right")}
          `}
        >
          <Button secondary onClick={onClose}>
            Cancel
          </Button>
          <Button
            css={`
              margin-left: 16px;
            `}
            onClick={onSave}
            disabled={!note}
          >
            Submit
          </Button>
        </Div>
      </Div>
    </Modal>
  );
};
