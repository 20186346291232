import React from "react";
import CircularProgress from "@material-ui/core/CircularProgress";
import { css } from "emotion";

export const Callback = () => {
  return (
    <div
      className={css`
        display: "flex";
        justify-content: "center";
        margin-top: 80px;
      `}
    >
      <CircularProgress />
    </div>
  );
};

export default Callback;
