import React, { useState, useEffect } from "react";
import { Div, Modal, Text } from "../shared/components";
import { Button } from "../shared/components/button";
import { flex } from "../shared/shared-styles";

import { css } from "emotion";
import { useAcls } from "../shared/use-acls";
import { CustomAxios } from "../redux/axios/axios";
import { useParams } from "react-router";
import uuid from "uuid";
import { useStore } from "../store-provider/use-store";

export const CollectGuideType = ({ course, refetchCourse }) => {
  const canWrite = useAcls(["write:course"], course.ID);
  const { businessId } = useParams();
  const [type, setType] = useState(course.Type);

  const {
    data: { selectedBusiness },
  } = useStore();

  useEffect(() => {
    setType(course.Type);
  }, [course.Type]);

  const [show, setShow] = useState(false);

  const updateGuideType = async () => {
    try {
      let copy = { ...course };
      copy.Type = type;
      delete copy.Pages;
      const res = await CustomAxios.put(
        `/v1/course/${copy.ID}/properties`,
        copy
      );
      refetchCourse();
    } catch (e) {
      console.error("did not update");
    } finally {
      setShow(false);
    }
  };

  const checkIfPromptAlreadyHappened = async () => {
    try {
      let response = await CustomAxios.get(
        `/v1/bp/prompt?business_id=eq.${businessId}&associated_resource_id=eq.${course.ID}&prompt_type=eq.guidetype`,
        {
          headers: {
            "Accept-Profile": "brite",
          },
        }
      );

      if (!response.data || response.data.length === 0) {
        let res = await CustomAxios.post(
          `/v1/bp/prompt?business_id=eq.${businessId}`,

          {
            id: uuid.v4(),
            associated_resource_id: course.ID,
            resource_type: "guide",
            business_id: businessId,
            prompt_type: "guidetype",
            prompted_at: new Date().toISOString(), // format like: "2023-06-16T22:00:37.375405"
          },
          {
            headers: {
              "Accept-Profile": "brite",
              "Content-Profile": "brite",
            },
          }
        );

        setShow(true);
      } else {
        setShow(false);
      }
    } catch (e) {
      setShow(false);
    }
  };

  useEffect(() => {
    if (course.CreatedAt && course.Type) {
      let q = new Date(course.CreatedAt);
      let m = q.getMonth() + 1;
      let d = q.getDay();
      let y = q.getFullYear();

      let courseCreatedAt = new Date(y, m, d);

      let released = new Date("2023-07-12");

      if (course.Type !== "vendor") {
        if (released > courseCreatedAt) {
          checkIfPromptAlreadyHappened();
        } else {
          setShow(false);
        }
      } else {
        setShow(false);
      }
    }
  }, [course.CreatedAt, selectedBusiness.Type, course.Type]);

  if (!show || !canWrite) {
    return null;
  }

  let modalProps = { display: show, onClose: () => setShow(false) };

  return (
    <Modal {...modalProps}>
      <Div
        css={css`
          width: 500px;
        `}
      >
        <Div
          css={css`
            padding: 32px;
            width: 100%;
            ${flex("space-between")} box-sizing: border-box;
          `}
        >
          <Text h2>Please Set a Guide Type</Text>
        </Div>

        <Div
          css={css`
            position: relative;
            width: 100%;
            height: 400px;
            padding: 32px;
            padding-top: 0px;
            overflow-y: auto;
          `}
        >
          <div
            className={css`
              color: #66737f;
              font-family: Roboto;
              font-size: 16px;
              font-style: normal;
              font-weight: 400;
              line-height: 24px;
              letter-spacing: 0.15px;
            `}
          >
            This helps us customize our tools for you, and give you more
            accurate analytics.
          </div>

          <div
            className={css`
              padding: 24px;
              background: ${type === "openenrollment" ? "#f5f7fa" : "#fff"};
              border-radius: 8px;
              cursor: pointer;
              border: ${type === "openenrollment"
                ? "2px solid #25282D"
                : "1px solid #d1dae3"};
              margin-top: 12px;
              margin-bottom: 12px;
              &:hover {
                background: #f5f7fa;
              }
            `}
            onClick={() => setType("openenrollment")}
          >
            <div
              className={css`
                color: #25282d;
                font-family: Roboto;
                font-size: 16px;
                font-style: normal;
                font-weight: 700;
                line-height: 24px;
                letter-spacing: 0.15px;
                text-transform: capitalize;
              `}
            >
              Open Enrollment
            </div>
          </div>

          <div
            className={css`
              padding: 24px;
              background: ${type === "newhire" ? "#f5f7fa" : "#fff"};
              border-radius: 8px;
              cursor: pointer;
              border: ${type === "newhire"
                ? "2px solid #25282D"
                : "1px solid #d1dae3"};
              margin-top: 12px;
              margin-bottom: 12px;
              &:hover {
                background: #f5f7fa;
              }
            `}
            onClick={() => setType("newhire")}
          >
            <div
              className={css`
                color: #25282d;
                font-family: Roboto;
                font-size: 16px;
                font-style: normal;
                font-weight: 700;
                line-height: 24px;
                letter-spacing: 0.15px;
                text-transform: capitalize;
              `}
            >
              New Hire
            </div>
          </div>

          <div
            className={css`
              padding: 24px;
              background: ${type === "recruiting" ? "#f5f7fa" : "#fff"};
              border-radius: 8px;
              cursor: pointer;
              border: ${type === "recruiting"
                ? "2px solid #25282D"
                : "1px solid #d1dae3"};
              margin-top: 12px;
              margin-bottom: 12px;
              &:hover {
                background: #f5f7fa;
              }
            `}
            onClick={() => setType("recruiting")}
          >
            <div
              className={css`
                color: #25282d;
                font-family: Roboto;
                font-size: 16px;
                font-style: normal;
                font-weight: 700;
                line-height: 24px;
                letter-spacing: 0.15px;
                text-transform: capitalize;
              `}
            >
              Recruiting
            </div>
          </div>

          <div
            className={css`
              padding: 24px;
              background: ${type === "other" ? "#f5f7fa" : "#fff"};
              border-radius: 8px;
              cursor: pointer;
              border: ${type === "other"
                ? "2px solid #25282D"
                : "1px solid #d1dae3"};
              margin-top: 12px;
              margin-bottom: 12px;
              &:hover {
                background: #f5f7fa;
              }
            `}
            onClick={() => setType("other")}
          >
            <div
              className={css`
                color: #25282d;
                font-family: Roboto;
                font-size: 16px;
                font-style: normal;
                font-weight: 700;
                line-height: 24px;
                letter-spacing: 0.15px;
                text-transform: capitalize;
              `}
            >
              Other
            </div>
          </div>
        </Div>
        <Div
          css={css`
            padding: 32px;

            width: 100%;
          `}
        >
          <Div
            css={css`
              ${flex("right")}
            `}
          >
            <Button
              onClick={(e) => {
                updateGuideType();
              }}
              css={`
                position: relative;
              `}
            >
              Done
            </Button>
          </Div>
        </Div>
      </Div>
    </Modal>
  );
};
