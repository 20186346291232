import React, { Component } from "react";
import { withRouter, matchPath } from "react-router-dom";
import { Button, Text } from "grommet";
import LockIcon from "@material-ui/icons/Lock";
import { css } from "emotion";

import { DragDropContext, Droppable, Draggable } from "react-beautiful-dnd";
import { connect } from "react-redux";
import ButtonUI from "@material-ui/core/Button";
import Alert from "@material-ui/lab/Alert";
import Tooltip from "@material-ui/core/Tooltip";
import {
  getCourse,
  updateCoursePagesOrder,
  updateCourse,
  updateCourseChanges,
  deletePage,
  updateUnsavedChanges,
  updateUnsavedChangesOff,
} from "../../redux/actions/courses";

import "./Builder.css";
import CircularProgress from "@material-ui/core/CircularProgress";

import { ImportedPageEditor } from "../ImportedPageEditor";
import posthog from "posthog-js";
import { compose } from "redux";
import { Plus } from "phosphor-react";

class Builder extends Component {
  constructor(props) {
    super(props);
    this.match = matchPath(props.location.pathname, {
      path: "/:businessId/courses/:id/builder",
    });
    this.state = {
      renamePageModal: false,
      renamePage: null,
      selectedPage: {
        FrontendID: "",
        Type: "regular",
        IsLocked: false,
        TemplateID: "00000000-0000-0000-0000-000000000000",
      },
      viewHeight: "200px",
      editTitle: false,
      modalOpen: false,
      newPageName: "",
      newPageType: "Build Your Own",
      newPageOption: "Build from Scratch",
      tooltip: "",
      duplicatePageModal: false,
      duplicatePage: null,
      savingPageTemplate: false,
      pageTemplates: [],
      getPageTemplatesLoading: false,
      selectedPageTemplate: {},
      savePageAsTemplateModal: false,
      pageTemplateName: "",
      loadingPreviewTemplateImageURL: false,
      previewTemplateImageURL: "",
      previewTemplateImageModal: false,
      deletePageModalOpen: false,
      pageToDelete: null,
      importFromVendorLibraryModal: false,
    };

    this.onDragEnd = this.onDragEnd.bind(this);
  }

  componentWillMount() {
    this.vh();
  }

  componentDidMount() {
    this.props.getCourse(this.match.params.id);
    window.addEventListener("resize", this.vh);
  }

  componentDidUpdate(prevProps) {
    // after the course is loaded, select the first page
    if (this.props.courseLoaded !== prevProps.courseLoaded) {
      if (this.props.courseLoaded) {
        // set course name on events now that we have it
        posthog.register({
          courseID: this.match.params.id,
          courseName: this.props.course.Name,
          courseMode: "read-only",
        });
        this.setState({ selectedPage: this.props.course.Pages[0] });
      }
    }

    if (this.props.saving !== prevProps.saving) {
      if (!this.props.saving) {
        this.setState((prevState) => {
          let index = 0;
          for (let i = 0; i < this.props.course.Pages.length; i++) {
            if (
              this.props.course.Pages[i].FrontendID ===
              prevState.selectedPage.FrontendID
            ) {
              index = i;
            }
          }
          return {
            selectedPage: this.props.course.Pages[index],
          };
        });
      }
    }
  }

  componentWillUnmount() {
    window.removeEventListener("resize", this.vh);
    posthog.unregister("courseID");
    posthog.unregister("courseName");
    posthog.unregister("courseMode");
    this.props.updateUnsavedChangesOff();
  }

  getItemStyle = (isDragging, draggableStyle, page) => {
    let backgroundColor =
      this.state.selectedPage.FrontendID === page.FrontendID
        ? "#25282D"
        : "#555555";
    return {
      // some basic styles to make the items look a bit nicer
      userSelect: "none",
      padding: "8px 16px",
      margin: `0 0 ${8}px 0`,

      // change background colour if dragging
      background: backgroundColor,
      borderRadius: "5px",
      color: "white",

      // styles we need to apply on draggables
      ...draggableStyle,
    };
  };

  getListStyle = (isDraggingOver) => {
    return {
      background: isDraggingOver ? "#F8F8F8" : "#F2F2F2",
      overflow: "auto",
      height: parseFloat(this.state.viewHeight) - 120,
    };
  };

  onDragEnd(result) {
    // dropped outside the list
    return;
  }

  vh = () => {
    var h = Math.max(
      document.documentElement.clientHeight,
      window.innerHeight || 0
    );
    let subNum = 140;
    if (
      this.props.authedBusiness.Type !== "multi" &&
      this.props.authedBusiness.Type !== "carrier"
    ) {
      subNum = 100;
    }
    this.setState({
      viewHeight: `${h - subNum}px`,
    });
  };

  selectPage = (e, page) => {
    // don't run if you are dragging. Just clicking
    if (!e.defaultPrevented) {
      this.setState({ selectedPage: page });
    }
  };

  render() {
    if (this.props.loading) {
      return (
        <div
          className={css`
            display: flex;
            justify-content: center;
            margin-top: 80px;
          `}
        >
          <CircularProgress />
        </div>
      );
    }
    return (
      <div
        className={css`
          padding-bottom: 60px;
        `}
      >
        <div
          className={css`
            margin: 24px;
            width: max-content;
          `}
        >
          {!this.props.alreadyLeasedByUser && this.props.canWriteCourse ? (
            <Alert severity="warning">
              Preview only! You only have viewer access to guides.
            </Alert>
          ) : (
            <Alert severity="warning">
              Preview only! {this.props.alreadyLeasedByUser} is currently making
              changes to this guide.
            </Alert>
          )}
        </div>
        <div
          className={css`
            display: flex;
            justify-content: space-between;
            padding-bottom: 20px;
            padding-left: 22px;
            margin-left: -24px;
          `}
        >
          <div
            className={css`
              font-weight: 400;
              font-size: 26px;
              line-height: 28px;
              color: #797979;
              display: flex;
              justify-content: center;
              align-items: center;
            `}
          >
            {this.props.course.Name}
          </div>
          <div
            className={css`
              display: flex;
              align-items: center;
            `}
          >
            <div
              className={css`
                margin-right: 20px;
              `}
            >
              <ButtonUI disabled>Save Page as Template</ButtonUI>
            </div>

            <div
              className={css`
                margin-right: 20px;
              `}
            >
              <ButtonUI disabled variant="contained" color="primary">
                Save
              </ButtonUI>
            </div>
            <div
              className={css`
                margin-right: 20px;
              `}
              id="saveandexit"
            >
              <ButtonUI disabled variant="contained" color="primary">
                Save & Exit
              </ButtonUI>
            </div>
          </div>
        </div>
        <div
          className={css`
            display: flex;
            margin: 10px;
            margin-top: 0px;
            margin-left: -15px;
          `}
        >
          <div
            id="pages"
            className={css`
              display: flex;
              flex-direction: column;
              height: ${this.state.viewHeight};
            `}
          >
            <div
              className={css`
                width: 180px;
                flex: 1;
                background-color: #f2f2f2;
                padding: 12px;
                border-radius: 5px;
              `}
            >
              <div
                ref={(ref) => {
                  this.question1 = ref;
                }}
                className={css`
                  display: flex;
                  justify-content: space-between;
                  align-items: center;
                  margin-bottom: 20px;
                `}
              >
                <Text size="large" color="dark-2">
                  Pages
                </Text>
                <Button disabled icon={<Plus />} />
              </div>
              <DragDropContext onDragEnd={this.onDragEnd}>
                <Droppable droppableId="droppable">
                  {(provided, snapshot) => (
                    <div
                      {...provided.droppableProps}
                      ref={provided.innerRef}
                      style={this.getListStyle(snapshot.isDraggingOver)}
                    >
                      {this.props.course.Pages.map((item, index) => (
                        <Draggable
                          key={item.FrontendID}
                          draggableId={item.FrontendID}
                          index={index}
                        >
                          {(provided, snapshot) => (
                            <React.Fragment>
                              <div
                                onClick={(e) => {
                                  this.selectPage(e, item);
                                }}
                                className={css`
                                  &:hover {
                                    box-shadow: 0px 0px 5px 0px
                                      rgba(0, 0, 0, 0.75);
                                  }
                                  display: flex;
                                  align-items: center;
                                  justify-content: space-between;
                                `}
                                ref={provided.innerRef}
                                {...provided.draggableProps}
                                {...provided.dragHandleProps}
                                style={this.getItemStyle(
                                  snapshot.isDragging,
                                  provided.draggableProps.style,
                                  item
                                )}
                              >
                                <div
                                  className={css`
                                    white-space: nowrap;
                                    width: 100px;
                                    overflow: hidden;
                                    text-overflow: ellipsis;
                                  `}
                                >
                                  <Tooltip
                                    title={item.Name || ""}
                                    placement="top"
                                  >
                                    <span>{item.Name}</span>
                                  </Tooltip>
                                  {item.Type === "regular" && (
                                    <div
                                      className={css`
                                        font-size: 9px;
                                      `}
                                    >
                                      {!item.TemplateID ||
                                      item.TemplateID ===
                                        "00000000-0000-0000-0000-000000000000"
                                        ? "Build Your Own"
                                        : "Vendor Imported"}
                                    </div>
                                  )}
                                  {item.Type === "decision" && (
                                    <div
                                      className={css`
                                        font-size: 9px;
                                      `}
                                    >
                                      Decision Tool
                                    </div>
                                  )}
                                  {item.Type === "question" && (
                                    <div
                                      className={css`
                                        font-size: 9px;
                                      `}
                                    >
                                      Multiple Choice
                                    </div>
                                  )}
                                </div>

                                <div
                                  className={css`
                                    display: flex;
                                    align-items: center;
                                    justify-content: flex-end;
                                  `}
                                >
                                  {item.IsLocked && (
                                    <div
                                      className={css`
                                        margin-left: -8px;
                                        margin-right: -13px;
                                      `}
                                    >
                                      <Tooltip
                                        title="Page is locked."
                                        placement="top"
                                      >
                                        <LockIcon
                                          fontSize={"small"}
                                          style={{ color: "white" }}
                                        />
                                      </Tooltip>
                                    </div>
                                  )}
                                </div>
                              </div>
                            </React.Fragment>
                          )}
                        </Draggable>
                      ))}
                      {provided.placeholder}
                    </div>
                  )}
                </Droppable>
              </DragDropContext>
              <Text size="xsmall">* Drag to reorder pages</Text>
            </div>
          </div>

          {this.state.selectedPage.Type === "regular" && (
            <ImportedPageEditor
              hide={true}
              updateCourseChanges={this.props.updateCourseChanges}
              course={this.props.course}
              selectedPage={this.state.selectedPage}
              viewHeight={this.state.viewHeight}
            />
          )}

          {this.state.selectedPage.Type === "decision" && (
            <div
              className={css`
                border: 1px solid #dedede;
                border-radius: 5px;
                margin-left: 20px;
                display: flex;
                flex-direction: column;
                height: ${this.state.viewHeight};
                flex: 1;
                overflow: auto;
                padding: 20px;
              `}
            >
              Can't view in read only
            </div>
          )}

          {this.state.selectedPage.Type === "question" && (
            <div
              className={css`
                border: 1px solid #dedede;
                border-radius: 5px;
                margin-left: 20px;
                display: flex;
                flex-direction: column;
                height: ${this.state.viewHeight};
                flex: 1;
                overflow: auto;
                padding: 20px;
              `}
            >
              Can't view in read only
            </div>
          )}
        </div>
      </div>
    );
  }
}

export const ReadOnlyBuilderContainer = compose(
  withRouter,
  connect(
    (state, ownProps) => ({
      course: state.courses.course,
      loading: state.courses.loading,
      courseLoaded: state.courses.courseLoaded,
      saving: state.courses.saving,
      hasUnsavedCourseChanges: state.courses.hasUnsavedCourseChanges,
      businessId: state.business.ID,
      activeBusiness: state.business,
      authedBusiness: state.auth.business,
    }),
    (dispatch) => {
      return {
        updateUnsavedChangesOff: () => {
          dispatch(updateUnsavedChangesOff());
        },
        updateUnsavedChanges: () => {
          dispatch(updateUnsavedChanges());
        },
        getCourse: (id) => {
          dispatch(getCourse(id));
        },
        deletePage: (id) => {
          dispatch(deletePage(id));
        },
        updateCourse: (course) => {
          dispatch(updateCourse(course));
        },
        updateCourseChanges: (course) => {
          dispatch(updateCourseChanges(course));
        },
        updateCoursePagesOrder: (result) => {
          dispatch(updateCoursePagesOrder(result));
        },
      };
    }
  )
)(Builder);
