import { MenuItem } from "@material-ui/core";
import { css } from "emotion";
import { Button, Div, Select } from "../../shared/components";
import { flex, sx } from "../../shared/shared-styles";
import { modifyContent } from "../provider/utils";
import { ActionsModal } from "./menu-components/actions-modal";
import { Alignment } from "./menu-components/alignment";

export const ButtonToolbar = ({ editor, element, location, toolbar }) => {
  const onUpdate = (updates) => modifyContent.merge(editor, location, updates);

  const updateButtonSize = (e) => {
    const size = e.target.value;
    let style = {};
    if (size === "large") {
      style = { padding: "10px 40px", fontSize: "18px" };
    } else if (size === "") {
      style = { padding: "8px 20px", fontSize: "16px" };
    } else if (size === "small") {
      style = { padding: "8px 16px", fontSize: "14px" };
    }
    onUpdate({
      buttonSize: e.target.value,
      attributes: { style },
    });
  };

  return (
    <Div
      css={css`
        ${flex("jcl aic")}
      `}
    >
      <Button
        styles="secondary"
        onClick={() => toolbar.setModal("button-options")}
      >
        Button Settings
      </Button>

      <Select
        className={css`
          width: 180px;
          margin-right: 8px;
          margin-left: 8px;
        `}
        value={element?.buttonSize || ""}
        onChange={updateButtonSize}
        displayEmpty
      >
        <MenuItem value="small">Small</MenuItem>
        <MenuItem value="">Medium</MenuItem>
        <MenuItem value="large">Large</MenuItem>
      </Select>

      <Alignment item={element} onUpdate={onUpdate} hideVertical={true} />

      <ActionsModal
        display={toolbar?.modal === "button-options"}
        onClose={() => toolbar.setModal(false)}
        onUpdate={onUpdate}
      />
    </Div>
  );
};
