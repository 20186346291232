import { useEffect, useMemo, useRef, useState } from "react";
import { useParams } from "react-router";
import { useResource } from "../../BriteEditor/use-editor-resource";

export const usePageVersions = (
  { data: page, cache: pageCache },
  { cache: pagesCache }
) => {
  const isInitial = useRef(true);
  const { courseId } = useParams();

  const [currentIdx, setCurrentIdx] = useState(null);

  const query = useResource("pageVersions", {
    courseId,
    pageId: page?.ID,
  });

  const { data, refetch, isFetching } = query;
  const revisions = useMemo(() => {
    try {
      return ("Data" in data ? data?.Data : data) || [];
    } catch {
      return [];
    }
  });

  useEffect(() => {
    if (courseId && page?.ID) {
      setCurrentIdx(null);
    }
  }, [courseId, page?.ID]);

  useEffect(() => {
    if (!isInitial && pageCache?.data?.Name) {
      refetch();
      isInitial.current = false;
    }
  }, [pageCache?.data?.Name]);

  const versionId = currentIdx === null ? null : revisions?.[currentIdx]?.ID;
  const canUndo =
    currentIdx !== null
      ? currentIdx < revisions.length - 1
      : revisions.length > 1;

  const canRedo = currentIdx > 0;

  const updateRevision = (idx) => {
    const revision = revisions[idx];
    const pages = pagesCache.data.map((item) =>
      item.ID === page?.ID ? { ...item, Name: revision.Name } : item
    );
    pagesCache.setData(pages);
    pageCache.setData({
      ...page,
      Name: revision.Name,
      Content: revision.Content,
      HTMLContent: revision.HTMLContent,
    });
    const updatedIdx = idx || null;
    setCurrentIdx(updatedIdx);
    if (pageCache.data === "regular") {
      window.unlayer.loadDesign(revision.Content);
    }
  };

  const undo = () => {
    const nextIdx = Math.min(revisions.length - 1, currentIdx + 1);
    updateRevision(nextIdx);
  };

  const redo = () => {
    if (!isFetching) {
      const nextIdx = Math.max(0, currentIdx - 1);
      updateRevision(nextIdx);
    }
  };

  return {
    query,
    versionId,
    currentIdx,
    setCurrentIdx,
    canUndo,
    canRedo,
    undo,
    redo,
  };
};
