import { merge, set } from "lodash";
import { useReducer } from "react";

export const useBasicReducer = (defaultValue) => {
  const [state, dispatch] = useReducer(reducer, defaultValue);

  const set = (key, value) => dispatch({ type: "set", key, value });
  const update = (key, value) => dispatch({ type: "update", key, value });
  const _merge = (value) => dispatch({ type: "merge", value });

  return [
    state,
    {
      set,
      update,
      merge: _merge,
    },
  ];
};

const reducer = (state, action) => {
  switch (action.type) {
    case "set": {
      return { ...state, [action.key]: action.value };
    }
    case "update": {
      let next = { ...state };
      next = set(next, action.key, action.value);
      return next;
    }
    case "merge": {
      let next = { ...state };
      next = merge({}, next, action.value);
      return next;
    }
    default: {
      return state;
    }
  }
};
