import { css } from "emotion";
import { ClockCounterClockwise, X } from "phosphor-react";
import { useContext, useMemo, useState } from "react";
import { Button, Div, Modal, Text } from "../../shared/components";
import { flex } from "../../shared/shared-styles";
import { colors } from "../../shared/styles";
import { editorUtils } from "../provider/utils";
import { useEditorResource } from "../use-editor-resource";
import { EditorDetailsContext } from "../provider/editor-detail-provider";
import { useMembers } from "../../Dashboard/use-members";
import { useStore } from "../../store-provider/use-store";
import { format } from "date-fns";
import ReactJson from "react-json-view";
import { useStateSync } from "../../shared/use-state-sync";

export const History = ({ editor }) => {
  const selectionString = editor?.state?.selection?.toString();
  const [idx, setIdx] = useState(null);
  const [oldIdx, setOldIdx] = useStateSync(null, [selectionString]);

  const { editorDetails } = useContext(EditorDetailsContext);

  const { data: storeData } = useStore();

  const { data: users } = useMembers({
    select: (data) => {
      return data?.reduce(
        (prev, item) => ({ ...prev, [item?.UserID]: item?.user }),
        { [storeData?.user?.ID]: storeData?.user }
      );
    },
  });

  const pageVersions = useEditorResource("pageVersions");

  const selectedHistory = useMemo(() => {
    if (!editor?.state?.selection?.length) {
      return [];
    }
    return editorUtils.getHistoryAtSelection(editor, {
      history: pageVersions?.data,
      pageId: editorDetails?.pageId,
    });
  }, [selectionString]);

  const selectIndex = (e, idx) => {
    e.stopPropagation();
    setIdx(idx);
    setOldIdx(idx);
  };

  const { last, changes } = useMemo(() => {
    if (idx === null) {
      return {};
    }

    const comp1 = selectedHistory?.[idx]?.change || {};
    const comp2 = selectedHistory?.[idx + 1]?.change || {};

    const changes1 = diff(comp1, comp2);
    const changes = diff(comp2, comp1);

    return { last: changes1, changes };
  }, [idx]);

  return (
    <>
      {!editor?.state?.selection?.length ? (
        <Div
          css={css`
            ${flex("center column")}
            margin: 64px 0;
            p {
              margin-top: 16px;
              text-align: center;
            }
          `}
        >
          <ClockCounterClockwise size={64} color={colors.gray[400]} />
          <Text>Select a component to see it's history.</Text>
        </Div>
      ) : (
        selectedHistory?.map((item, idx) => (
          <Div
            css={css`
              border-radius: 8px;
              border: 1px solid ${colors.gray[300]};
              background-color: white;
              padding: 8px;
              margin: 8px 0;
              width: 260px;
              ${oldIdx === idx
                ? `
                  outline: 2px solid ${colors.purple};
                  border: 1px solid transparent;
                `
                : ""}
              :hover {
                outline: 2px solid ${colors.black};
                border: 1px solid transparent;
                cursor: pointer;
              }
            `}
            onClick={(e) => selectIndex(e, idx)}
          >
            <Text label bold>
              {users?.[item?.version?.CreatedBy]?.FirstName}{" "}
              {users?.[item?.version?.CreatedBy]?.LastName}
            </Text>
            <Text label>{users?.[item?.version?.CreatedBy]?.Email}</Text>
            <Text>
              {item?.version?.CreatedAt
                ? format(
                    new Date(item?.version?.CreatedAt),
                    "MM/dd/yyyy h:mm:ss a"
                  )
                : ""}
            </Text>
          </Div>
        ))
      )}

      <Modal display={idx !== null} onClose={() => setIdx(null)}>
        <Div
          css={css`
            width: 100vw;
            height: 100vh;
            padding: 32px;
          `}
        >
          <Div
            css={css`
              ${flex("right")}
            `}
          ></Div>
          <Div
            css={css`
              ${flex("left")}
              p {
                width: 50%;
              }
              margin-bottom: 32px;
              position: relative;
            `}
          >
            <Text h2>Original</Text>
            <Text h2>Changes</Text>
            <Button
              styles="icon"
              onClick={() => setIdx(null)}
              css={`
                position: absolute;
                top: 0;
                right: 0;
              `}
            >
              <X />
            </Button>
          </Div>
          <Div
            css={css`
              ${flex("left start")}
              overflow-y: auto;
              box-sizing: border-box;
            `}
          >
            <Div
              css={css`
                width: 50%;
                padding: 32px;
                margin-right: 16px;
                border-radius: 8px;
                background-color: ${colors.gray[100]};
                height: 80vh;
                overflow-y: auto;
              `}
            >
              <ReactJson
                name="original"
                src={idx === selectedHistory?.length - 1 ? {} : last}
              />
            </Div>
            <Div
              css={css`
                width: 50%;
                padding: 32px;
                margin-right: 16px;
                border-radius: 8px;
                background-color: ${colors.gray[100]};
                height: 80vh;
                overflow-y: auto;
              `}
            >
              <ReactJson
                name="changes"
                src={
                  idx === selectedHistory?.length - 1
                    ? selectedHistory?.at(-1)?.change
                    : changes
                }
              />
            </Div>
          </Div>
        </Div>
      </Modal>
    </>
  );
};

//http://stackoverflow.com/questions/679915/how-do-i-test-for-an-empty-javascript-object
var isEmptyObject = function (obj) {
  var name;
  for (name in obj) {
    return false;
  }
  return true;
};

//http://stackoverflow.com/questions/8431651/getting-a-diff-of-two-json-objects
var diff = function (obj1, obj2) {
  try {
    var result = {};
    var change;
    for (var key in obj1) {
      if (typeof obj2[key] == "object" && typeof obj1[key] == "object") {
        change = diff(obj1[key], obj2[key]);
        if (isEmptyObject(change) === false) {
          result[key] = change;
        }
      } else if (obj2[key] != obj1[key]) {
        result[key] = obj2[key];
      }
    }
    return result;
  } catch {
    return {};
  }
};
