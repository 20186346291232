import { CircularProgress } from "@material-ui/core";
import { css } from "emotion";
import {
  CheckCircle,
  NumberCircleOne,
  NumberCircleThree,
  NumberCircleTwo,
} from "phosphor-react";
import { useState } from "react";
import { toast } from "react-toastify";
import { BaseCourses } from "./base-courses";
import { useBaseCourses } from "./use-base-courses";
import { history } from "../../history";
import { CustomAxios } from "../../redux/axios/axios";
import { Button, Div, Modal, Text } from "../../shared/components";
import { container, flex } from "../../shared/shared-styles";
import { colors, mediaQueryFn } from "../../shared/styles";
import { useSearchParams } from "../../shared/use-search-params";
import { useStore } from "../../store-provider/use-store";
import RocketShip from "./rocket-ship.png";
import { SelectBenefitPackage } from "./select-benefit-package";
import { SelectDesignStyle } from "./select-design-style";
import { useFeatureFlagPayload } from "posthog-js/react";

const steps = {
  package: ({ packageId }) => ({
    buttonText: "Next",
    isValid: !!packageId,
    next: "design-style",
  }),
  "design-style": ({ hasBaseGuide }) => ({
    buttonText: hasBaseGuide ? "Next" : "Generate",
    isValid: () => true,
    next: hasBaseGuide ? "base-guide" : "generate",
  }),
  "base-guide": () => ({
    buttonText: "Generate",
    isValid: () => true,
    next: "generate",
  }),
  generate: () => ({
    buttonText: "Generating...",
    isValid: () => false,
    next: "",
  }),
};

export const GenerateGuide = ({
  startStep = "package",
  selectedGuideType = "openenrollment",
  currentFolder = null,
  ...modalProps
}) => {
  const { params, setParams, updateParams } = useSearchParams();
  const { packageId, designStyleId } = params;

  const baseGuide = useBaseCourses();
  const courseSavingV2 = useFeatureFlagPayload("course-saving-v2");

  const {
    data: { selectedBusiness },
  } = useStore();
  const businessId = selectedBusiness.ID || "";

  const [step, setStep] = useState(startStep);
  const [removeSmartFields, setRemoveSmartFields] = useState(true);
  const [complete, setComplete] = useState(false);

  const hasBaseGuide = !!baseGuide?.data?.length;
  const stepStatus = steps[step]({ packageId, hasBaseGuide });
  const progressItems = Object.keys(steps);
  const progress = progressItems.findIndex((item) => item === step);

  const closeGenerateGuide = () => {
    modalProps?.onClose();
    setParams({});
  };

  const generateCourse = async () => {
    try {
      const themeData = designStyleId
        ? { ThemeID: designStyleId }
        : { SkipTheme: true };
      const body = {
        ...themeData,
        SkipRender: !removeSmartFields,
        OverrideBaseCourseID: baseGuide.current,
      };
      const response = await CustomAxios.post(
        `/v1/benefitspackage/${packageId}/course`,
        body
      );
      window?.ChurnZero?.push([
        "trackEvent",
        "Guide Created",
        `Name: ${body?.Name}`,
        1,
      ]);
      try {
        const { data } = response;
        await CustomAxios.put(`/v1/course/${data.ID}/properties`, {
          ...data,
          Type: selectedGuideType,
          ViewTotalsMonthly: true,
          CourseFolderID: currentFolder ? currentFolder.ID : null,
        });
      } catch (e) {
        console.error("did not update type");
      }
      setTimeout(() => {
        setComplete(true);
      }, 1000);
      setTimeout(() => {
        if (courseSavingV2?.value === false) {
          // Only route to unlayer builder if course-saving-v2 FF check succeeds and the value is false.
          history.push(`/${businessId}/courses/${response.data.ID}/builder`);
        } else {
          history.push(`/${businessId}/courses/${response.data.ID}`);
        }
      }, 2000);
    } catch (e) {
      toast.error(`Failed to generate your guide!`);
      setStep("package");
    }
  };

  const handleNext = () => {
    setStep(stepStatus?.next);
    if (stepStatus?.next === "generate") {
      generateCourse();
    }
  };

  return (
    <>
      <Modal full {...modalProps}>
        <Div
          css={css`
            position: relative;
            width: 100vw;
            height: 100px;
            padding: 32px;
            border-bottom: 1px solid ${colors.gray[300]};
            ${flex("space-between")}
          `}
        >
          <Text
            h2
            css={`
              white-space: nowrap;
            `}
            onClick={() => {
              setStep("base-guide");
              setComplete(false);
            }}
          >
            Generate Guide
          </Text>
          <Div
            css={css`
              ${mediaQueryFn("less-than", "800px")} {
                .text {
                  display: none;
                }
              }
              flex-grow: 1;
              ${flex("space-around")}
              svg {
                min-width: 32px;
              }
            `}
          >
            <Div
              css={css`
                ${flex("left wrap")}
                border-radius: 8px;
                padding: 8px 16px;
                cursor: default;
                ${step !== "generate" && step !== "package" && packageId
                  ? `${container.hover}`
                  : ""}
              `}
              onClick={() => step !== "generate" && setStep("package")}
            >
              {packageId ? (
                <CheckCircle weight="fill" />
              ) : (
                <NumberCircleOne
                  color={step === "package" ? colors.black : colors.gray[400]}
                />
              )}
              <Text
                label
                bold
                className="text"
                css={`
                  margin-left: 8px;
                `}
                color={step === "package" ? colors.black : colors.gray[400]}
              >
                Benefit Package
              </Text>
            </Div>

            <Div
              css={css`
                ${flex("left wrap")}
                border-radius: 8px;
                padding: 8px 16px;
                cursor: default;
                ${step !== "generate" && packageId ? container.hover : ""}
              `}
              onClick={() =>
                packageId && step !== "generate" && setStep("design-style")
              }
            >
              {designStyleId ? (
                <CheckCircle weight="fill" />
              ) : (
                <NumberCircleTwo
                  color={
                    step === "design-style" ? colors.black : colors.gray[400]
                  }
                />
              )}
              <Text
                label
                bold
                className="text"
                css={`
                  margin-left: 8px;
                `}
                color={
                  step === "design-style" ? colors.black : colors.gray[400]
                }
              >
                Design Style
              </Text>
            </Div>
            {hasBaseGuide ? (
              <Div
                css={css`
                  ${flex("left wrap")}
                  border-radius: 8px;
                  padding: 8px 16px;
                  cursor: default;
                  ${step !== "generate" && designStyleId ? container.hover : ""}
                `}
                onClick={() =>
                  designStyleId && step !== "generate" && setStep("base-guide")
                }
              >
                {step === "base-guide" || step === "generate" ? (
                  <CheckCircle weight="fill" />
                ) : (
                  <NumberCircleThree
                    color={
                      step === "base-guide" ? colors.black : colors.gray[400]
                    }
                  />
                )}
                <Text
                  label
                  bold
                  className="text"
                  css={`
                    margin-left: 8px;
                  `}
                  color={
                    step === "base-guide" ? colors.black : colors.gray[400]
                  }
                >
                  Base Guide
                </Text>
              </Div>
            ) : null}
          </Div>
          <Div
            css={css`
              ${flex("right")}
            `}
          >
            <Button
              css={`
                margin-left: 16px;
              `}
              secondary
              disabled={step === "generate"}
              onClick={closeGenerateGuide}
            >
              Close
            </Button>
            <Button
              css={`
                margin-left: 16px;
                width: 120px;
              `}
              disabled={!stepStatus.isValid || step === "generate"}
              onClick={handleNext}
            >
              {stepStatus.buttonText}
            </Button>
          </Div>

          <Div
            css={css`
              position: absolute;
              bottom: 0;
              left: 0;
              height: 4px;
              background-color: ${colors.black};
              width: ${step === "generate"
                ? 100
                : (progress / (progressItems.length - 1)) * 100}vw;
              transition: width 0.4s ease;
            `}
          />
        </Div>
        <Div
          css={css`
            ${flex("center start")}
            width: 100vw;
            height: calc(100vh - 100px);
            overflow-y: auto;
          `}
        >
          <Div
            css={css`
              position: relative;
              padding-top: 80px;
              width: 700px;
              margin: 32px;
              margin-top: 0;
            `}
          >
            {step === "package" ? (
              <SelectBenefitPackage
                packageId={packageId}
                setPackageId={(packageId) => updateParams({ packageId })}
              />
            ) : step === "design-style" ? (
              <SelectDesignStyle
                designStyleId={designStyleId}
                setDesignStyleId={(designStyleId) =>
                  updateParams({ designStyleId })
                }
              />
            ) : step === "base-guide" ? (
              <BaseCourses
                {...baseGuide}
                removeSmartFields={removeSmartFields}
                setRemoveSmartFields={setRemoveSmartFields}
              />
            ) : step === "generate" ? (
              <Div
                css={css`
                  ${flex("center column")}
                  height: 50vh;
                `}
              >
                <img src={RocketShip} alt="Guide image" />
                <CircularProgress />
                <Text
                  h1
                  css={`
                    margin-top: 16px;
                  `}
                >
                  Generating Your Guide...
                </Text>
                <Text h2 color={colors.gray[500]}>
                  Sit tight while we generate a guide for you
                </Text>
              </Div>
            ) : null}
          </Div>
        </Div>

        {step === "generate" && (
          <Div
            css={css`
              position: fixed;
              top: 0;
              right: 0;
              left: 0;
              bottom: 0;
              z-index: 1000;
              pointer-events: none;
              background-color: transparent;
              transition: 1s all ease;
              ${complete
                ? `
              backdrop-filter: blur(80px);
              background-color: white;
            `
                : ""}
            `}
          />
        )}
      </Modal>
    </>
  );
};
