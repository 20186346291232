import { css } from "emotion";
import { X } from "phosphor-react";
import { useMemo, useState, useEffect } from "react";
import { toast } from "react-toastify";
import { CustomAxios } from "../redux/axios/axios";
import { Button, Div, Input, Modal, Text, Loader } from "../shared/components";
import { flex, px } from "../shared/shared-styles";
import { useStore } from "../store-provider/use-store";
import { colors } from "../shared/styles";

export const EditMember = (modalProps) => {
  const {
    data: { user },
    setStore,
  } = useStore();

  const [firstName, setFirstName] = useState(user?.FirstName || "");
  const [lastName, setLastName] = useState(user?.LastName || "");
  const [loading, setLoading] = useState(false);

  const submitUpdates = async () => {
    try {
      setLoading(true);
      const updates = { ...user, FirstName: firstName, LastName: lastName };
      await CustomAxios.put(`v1/users/${user.ID}`, updates);
      setStore("user", updates);
      modalProps.onClose();
    } catch (err) {
      toast.error(`Failed to update user.`);
    } finally {
      setLoading(false);
    }
  };

  //useEffect to close modal on escape key press
  useEffect(() => {
    const closeOnEscapePressed = (e) => {
      if (e.key === "Escape") {
        modalProps.onClose();
      }
    };
    window.addEventListener("keydown", closeOnEscapePressed);
    return () => window.removeEventListener("keydown", closeOnEscapePressed);
  }, []);

  const closeModal = () => {
    modalProps.onClose();
  };

  const validForm = useMemo(() => {
    return (
      (!firstName && !lastName) ||
      firstName !== user?.FirstName ||
      lastName !== user?.LastName
    );
  }, [firstName, lastName, user?.FirstName, user?.LastName]);

  return (
    <Modal {...modalProps}>
      <Div
        css={css`
          width: 500px;
          padding: 32px;
        `}
        onClick={(e) => e.stopPropagation()}
      >
        <Div
          css={css`
            ${flex("space-between")}
          `}
        >
          <Text styles="h2">Edit User</Text>
          <Button styles="icon" onClick={closeModal}>
            <X />
          </Button>
        </Div>

        <Div
          css={css`
            margin: 16px 0;
            p {
              margin-bottom: 16px;
            }
          `}
        >
          <Text styles="label">Email</Text>
          <Input disabled={true} value={user.Email} />
        </Div>
        <Div
          css={css`
            margin: 16px 0;
            p {
              margin-bottom: 16px;
            }
          `}
        >
          <Text styles="label">First Name</Text>
          <Input state={[firstName, setFirstName]} />
        </Div>
        <Div
          css={css`
            margin: 16px 0;
            p {
              margin-bottom: 16px;
            }
          `}
        >
          <Text styles="label">Last Name</Text>
          <Input state={[lastName, setLastName]} />
        </Div>

        <Div
          css={css`
            ${flex("right")}
          `}
        >
          <Button styles="secondary mr" onClick={closeModal}>
            Cancel
          </Button>

          <Button
            onClick={submitUpdates}
            disabled={!validForm || loading}
            css={`
              position: relative;
            `}
          >
            <Loader
              type="three-dots"
              isLoading={loading}
              className={css`
                ${flex("center start")}
                background-color: ${colors.gray[300]};
              `}
            />
            Update
          </Button>
        </Div>
      </Div>
    </Modal>
  );
};
