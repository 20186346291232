import React from "react";

import { useStore } from "../store-provider/use-store";

import { CompanySmartFields } from "./CompanySmartFields";

export const BusinessSmartFields = () => {
  const {
    data: { selectedBusiness },
  } = useStore();
  return (
    <div>
      <CompanySmartFields {...selectedBusiness} />
    </div>
  );
};
