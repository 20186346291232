import { css } from "emotion";
import { Layer } from "grommet";
import React, { forwardRef } from "react";
import { flex } from "../shared-styles";
import { X } from "phosphor-react";
import { Button } from "./button";
import { Text } from "./text";

export const Modal = forwardRef(
  ({ display, onClose, children, ...rest }, ref) => {
    if (!display) {
      return null;
    }

    return (
      <Layer
        ref={ref}
        onClickOutside={onClose}
        onEsc={onClose}
        modal={true}
        onClick={(e) => e.stopPropagation()}
        {...rest}
        className={css`
          border-radius: 16px;
          ${flex("center start")} ${css`
            ${rest?.className || ""}
          `}
        `}
      >
        {children}
      </Layer>
    );
  }
);

Modal.Paper = ({ width = "500px", children, css: cssString }) => (
  <div
    className={css`
      width: ${width};
      padding: 32px;
      ${cssString}
    `}
  >
    {children}
  </div>
);

Modal.Header = ({ title, onClose, css: cssString }) => {
  const headerTitle =
    typeof title === "string" ? <Text h2>{title}</Text> : title;
  return (
    <div
      className={css`
        width: 100%;
        padding-bottom: 8px;
        ${flex("space-between")}
        ${cssString}
      `}
    >
      {headerTitle}
      <Button styles="icon" onClick={onClose}>
        <X />
      </Button>
    </div>
  );
};

Modal.Body = ({ children, css: cssString }) => (
  <div
    className={css`
      width: 100%;
      padding: 8px 0;
      ${cssString}
    `}
  >
    {children}
  </div>
);

Modal.Actions = ({ children, css: cssString }) => (
  <div
    className={css`
      width: 100%;
      padding-top: 16px;
      ${flex("right")}
      button {
        margin-left: 16px;
      }
      ${cssString}
    `}
  >
    {children}
  </div>
);
