import { useSelector } from "react-redux";
import { toast } from "react-toastify";
import { CustomAxios } from "../redux/axios/axios";
import { useStore } from "../store-provider/use-store";
import { useStateSync } from "./use-state-sync";
import { useFeatureFlagPayload } from "posthog-js/react";

export const useFeatureFlag = (flag, isAdminOnly = true) => {
  const f = useFeatureFlagPayload(flag);
  const { currentValue, isReady } = useSelector((store) => {
    return {
      currentValue: f?.value || false,
      isReady: true,
      store,
    };
  });

  const [flagValue, setFlagValue] = useStateSync(currentValue, [currentValue]);

  const {
    data: { parentBusiness, selectedAcl, isBriteUser },
  } = useStore();
  const localStorageKey = `BRITE-${parentBusiness?.ID}/viewed-flag/${flag}`;

  const isUserRoleValid =
    isBriteUser || (isAdminOnly ? selectedAcl?.RoleID === "admin" : true);
  const userHasViewedFlag = localStorage.getItem(localStorageKey);

  const showOptIn = isUserRoleValid && !userHasViewedFlag;

  const setUserHasViewed = () => {
    localStorage.setItem(localStorageKey, true);
  };

  const toggleFlag = async () => {
    const oldValue = flagValue;
    try {
      setFlagValue(!flagValue);
      await CustomAxios.put(`v1/featureflag`, {
        FlagName: flag,
        FlagValue: !flagValue,
      });
      setUserHasViewed();
    } catch (err) {
      toast.error(`Failed to update feature.`);
      setFlagValue(oldValue);
      console.warn(err);
      throw err;
    }
  };

  return {
    value: flagValue,
    toggleFlag,
    isReady,
    isUserRoleValid,
    showOptIn,
    userHasViewedFlag, // - a check in localStorage to see if the user has already had the opportunity to opt in
    setUserHasViewed, // - Update localStorage for ^^^
  };
};
